import React, {useEffect, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useParams, useNavigate} from 'react-router-dom'
import {getStatementsByVendorId} from '../Core/_requests'
import {formatCurrency, formatCurrencyWithoutSymbol} from '../../../utils/utils'
import {useAuth} from '../../../modules/auth'
import './printstatement.css'

export default function StatementVendor({currency = 'SAR'}) {
  const navigate = useNavigate()
  const id = useParams().id
  const {currentUser} = useAuth()
  const [statements, setStatements] = useState([])
  const [totalPurchase, setTotalPurchase] = useState(0)
  const [totalPayment, setTotalPayment] = useState(0)
  const [dateFilter, setDateFilter] = useState('30')
  const [loading, setLoading] = useState(true)

  const fetchStatements = async () => {
    setLoading(true)
    const res = await getStatementsByVendorId(id, dateFilter, currentUser.organization)
    setStatements(res?.transactions)
    setTotalPurchase(res?.purchaseOrderTotal)
    setTotalPayment(res?.paymentMadeTotal)
    setLoading(false)
  }

  useEffect(() => {
    fetchStatements()
  }, [dateFilter])

  const toggleEmailComposer = () => {
    navigate(`email-composer/${dateFilter}`)
  }

  const printStatement = () => {
    window.print()
  }

  const formatDate = (date) => {
    const d = new Date(date)
    // return date as 30/12/2021
    return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
  }
  return (
    <div className='card'>
      <div className='card-header align-items-center border-0'>
        <div className='d-flex space-x-2'>
          <select
            className='form-select'
            value={dateFilter}
            onChange={(e) => {
              setDateFilter(e.target.value)
            }}
          >
            <option value='1'>Today</option>
            <option value='7'>This Week</option>
            <option value='30'>This Month</option>
            <option value='90'>This Quarter</option>
            <option value='365'>This Year</option>
          </select>
        </div>
        <div className='d-flex space-x-2'>
          <button type='button' onClick={printStatement} className='symbol symbol-45px me-2'>
            <span className='symbol-label'>
              <span class='ki-outline ki-printer fs-2 '></span>
            </span>
          </button>
          <div className='symbol symbol-45px me-2'>
            <span className='symbol-label'>
              <i class='bi bi-file-pdf fs-2 '></i>
            </span>
          </div>

          <div className='symbol symbol-45px me-2'>
            <span className='symbol-label'>
              <i class='bi bi-filetype-xls'></i>
            </span>
          </div>
          <button className='btn btn-primary whitespace-nowrap' onClick={toggleEmailComposer}>
            <i class='bi bi-envelope me-2 fs-2'></i>
            Compose Email
          </button>
        </div>
      </div>
      {loading ? (
        <div className='d-flex justify-content-center align-items-center h-500px'>
          <div className='loader'></div>
        </div>
      ) : (
        <div className='card-body' id='printable'>
          {/* a container at right bottom for showing statement of accounts */}
          <div className='d-flex justify-end'>
            <div className='d-flex flex-column gap-3'>
              <div>
                <h3 className='uppercase'>Statement of Accounts</h3>
              </div>
              {/* flex column */}
              <div>
                <h4 className='bg-dark text-white p-1'>Account Summary</h4>
                <div className='d-flex flex-column justify-content-between'>
                  <div className='d-flex justify-between mb-3'>
                    <p>Billed Amount</p>
                    <h4>{formatCurrency(totalPurchase, currentUser?.baseCurrency)}</h4>
                  </div>
                  <div className='d-flex justify-between'>
                    <p>Payment Made</p>
                    <h4>{formatCurrency(totalPayment, currentUser?.baseCurrency)}</h4>
                  </div>
                  <hr />
                  <div className='d-flex justify-between'>
                    <p>Balance Due</p>
                    <h4>
                      {formatCurrency(totalPurchase - totalPayment, currentUser?.baseCurrency)}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='table-responsive mt-5'>
            <table className='table table-borderless'>
              <thead>
                <tr className='bg-dark text-white'>
                  <th scope='col' className='ps-2'>
                    Date
                  </th>
                  <th scope='col'>Transaction</th>
                  <th scope='col'>Details</th>
                  <th scope='col'>Amount</th>
                  <th scope='col'>Payment</th>
                  <th scope='col'>Balance</th>
                </tr>
              </thead>
              <tbody>
                {statements.map((statement, index) => (
                  <tr key={index}>
                    <td className='ps-2'>{formatDate(statement.createdAt)}</td>
                    {/* <td>{statement?.type === 'purchase' ? 'bill' : statement?.type}</td> */}
                    <td>{statement?.type}</td>
                    <td>{statement?.id}</td>
                    {statement.type !== 'payment made' &&
                    statement.type !== 'partial payment made' ? (
                      <>
                        <td>
                          {statement.debit !== 0
                            ? formatCurrencyWithoutSymbol(statement?.debit)
                            : formatCurrencyWithoutSymbol(statement?.credit)}
                        </td>
                        <td></td>
                        <td>
                          {statement.debit !== 0
                            ? formatCurrencyWithoutSymbol(statement?.debit)
                            : formatCurrencyWithoutSymbol(statement?.credit)}
                        </td>
                      </>
                    ) : (
                      <>
                        <td></td>
                        <td>
                          {statement.debit !== 0
                            ? formatCurrencyWithoutSymbol(statement?.debit)
                            : formatCurrencyWithoutSymbol(statement?.credit)}
                        </td>
                        <td>0</td>
                      </>
                    )}
                  </tr>
                ))}
                {/* row for total balance due */}
                <tr className='border-top'>
                  <td></td>
                  <td></td>
                  <td></td>
                  {/* Balance due with colspan 2 */}
                  <td colSpan='2'>
                    <h5 className='text-center'>Balance Due</h5>
                  </td>
                  <td>
                    <h5>
                      {formatCurrency(totalPurchase - totalPayment, currentUser?.baseCurrency)}
                    </h5>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  )
}
