import {FC, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../redux/store'
import {
  setPnl,
  setExpense,
  setProfit,
  setTotalBusinessSum,
  setSalesPerformanceData,
  setVendorsData,
} from '../../redux/dashboard/mainDashboardSlice'
import {useAuth} from '../../modules/auth'
import {getRevenue, getSalesPerformance, getVendorPerformance} from '../reports/core/requests'
import {getProfitAndLoss} from '../account/core/_requests'
import {ListsWidget26, ListsWidget27, StatisticsWidget7} from '../../../_metronic/partials/widgets'
import EmployeeTable from './EmployeeTable'
import SalesSummaryCard from './SalesSummaryCard'
import VendorSummaryCard from './VendorSummaryCard'
import {Stat1} from '../Inventory/Components/Stat1'

const MarketingDashboard = ({customers, fiscalYear}) => {
  const {currentUser} = useAuth()
  const dispatch = useDispatch()
  const {pnl, expense, profit, totalBusinessSum, fetch} = useSelector(
    (state: RootState) => state.dashboard
  )

  useEffect(() => {
    const fetchData = async () => {
      const [revenueResp, pnlRes] = await Promise.all([
        getRevenue(
          currentUser.organization,
          fiscalYear[currentUser.fiscalYear].start,
          fiscalYear[currentUser.fiscalYear].end
        ),
        getProfitAndLoss(
          currentUser.organization,
          fiscalYear[currentUser.fiscalYear].start,
          fiscalYear[currentUser.fiscalYear].end
        ),
      ])

      dispatch(
        setTotalBusinessSum(
          revenueResp?.[0]?.total?.toLocaleString('en-US', {
            minimumFractionDigits: 2,
          })
        )
      )

      const updatedPnl = pnlRes?.reduce(
        (acc, item) => {
          if (item.type === 'income') {
            acc.gross += item.total
            acc.income += item.total
          } else if (item.type === 'costofgoodssold') {
            acc.gross -= item.total
            acc.goods += item.total
          } else if (item.type === 'expense') {
            acc.operating += item.total
            acc.expense += item.total
          }
          return acc
        },
        {...pnl}
      )

      updatedPnl.net = updatedPnl.gross - updatedPnl.operating

      dispatch(setPnl(updatedPnl))
      dispatch(
        setExpense(
          updatedPnl.expense?.toLocaleString('en-US', {
            minimumFractionDigits: 2,
          })
        )
      )
      dispatch(
        setProfit(
          (updatedPnl.gross - updatedPnl.expense)?.toLocaleString('en-US', {
            minimumFractionDigits: 2,
          })
        )
      )
    }

    const timer = setTimeout(() => {
      if (currentUser.organization && fetch) {
        fetchData()
      }
    }, 500)

    return () => clearTimeout(timer)
  }, [currentUser, fiscalYear, dispatch, pnl])

  return (
    <>
      <div className='row g-5 g-xl-8 mb-xl-2 pe-10'>
        <div className='col-md-4 col-lg-4 col-xl-4 col-xxl-4 mb-md-2'>
          <StatisticsWidget7
            className=''
            header='Overall Revenue'
            title={totalBusinessSum}
            description='Compared to the previous quarter'
            badgeColor='success'
            color='light-success'
            svgIcon=''
            iconColor=''
            borderColor='success'
          />
        </div>
        <div className='col-md-4 col-lg-4 col-xl-4 col-xxl-4 mb-md-2'>
          <StatisticsWidget7
            className=''
            header='Overall Expense'
            title={expense}
            badgeColor='success'
            description='Compared to the previous quarter'
            color='light-danger'
            svgIcon=''
            iconColor=''
            borderColor='danger'
          />
        </div>
        <div className='col-md-4 col-lg-4 col-xl-4 col-xxl-4 mb-md-2'>
          <StatisticsWidget7
            className=''
            header='Company P/L'
            title={profit}
            badgeColor='danger'
            description='Compared to the previous quarter'
            color='light-warning'
            svgIcon=''
            iconColor=''
            borderColor='warning'
          />
        </div>
      </div>
      <div>
        <div></div>
      </div>
    </>
  )
}

const MarketingDashboardWrapper: FC = () => {
  const {currentUser} = useAuth()
  const dispatch = useDispatch()
  const {salesPerformanceData, vendorsData, fetch} = useSelector(
    (state: RootState) => state.dashboard
  )

  const fiscalYear = {
    'January-December': {
      start: new Date(new Date().getFullYear(), 0, 1).toISOString(),
      end: new Date(new Date().getFullYear(), 11, 31).toISOString(),
    },
    'April-March': {
      start: new Date(new Date().getFullYear(), 3, 1).toISOString(),
      end: new Date(new Date().getFullYear(), 2, 31).toISOString(),
    },
    'July-June': {
      start: new Date(new Date().getFullYear(), 6, 1).toISOString(),
      end: new Date(new Date().getFullYear(), 5, 30).toISOString(),
    },
    'October-September': {
      start: new Date(new Date().getFullYear(), 9, 1).toISOString(),
      end: new Date(new Date().getFullYear(), 8, 30).toISOString(),
    },
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [salesData, vendorData] = await Promise.all([
          getSalesPerformance(
            currentUser.organization,
            fiscalYear[currentUser.fiscalYear].start,
            fiscalYear[currentUser.fiscalYear].end
          ),
          getVendorPerformance(
            currentUser.organization,
            fiscalYear[currentUser.fiscalYear].start,
            fiscalYear[currentUser.fiscalYear].end
          ),
        ])

        dispatch(setSalesPerformanceData(salesData))
        dispatch(setVendorsData(vendorData))
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    const timer = setTimeout(() => {
      if (currentUser.organization && fetch) {
        fetchData()
      }
    }, 500)

    return () => clearTimeout(timer)
  }, [currentUser, fiscalYear])

  const top5Customers = salesPerformanceData.filter((sales) => sales.isActivated).slice(0, 6)
  const top5Vendors = vendorsData.filter((vendor) => vendor.isActivated).slice(0, 5)

  return (
    <>
      <div className='app-container container-xxl d-flex flex-stack mb-8'></div>
      <div className='flex flex-col gap-3'>
        <div>
          <MarketingDashboard customers={top5Customers} fiscalYear={fiscalYear} />
          <div className='row g-5 g-xl-10 mb-5 mb-xl-2'>
            <div className='row h-md-100 g-5 g-xl-10 mb-xl-5'>
              <div className='col-md-8 col-lg-12 col-xl-12 col-xxl-8 mb-5'>
                <div className='row mb-6'>
                  <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                    <ListsWidget27 className='' />
                  </div>
                  <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                    <ListsWidget26 className='' />
                  </div>
                </div>
                <div className='row'>
                  <div className=''>
                    <Stat1 />
                  </div>
                </div>
              </div>
              <div className='col-xxl-4 mb-5 mb-xl-4'>
                <SalesSummaryCard customers={top5Customers} />
              </div>
            </div>
          </div>
        </div>
        <div className='row gx-10 w-100'>
          <div className='col-lg-8 py-5'>
            <EmployeeTable tableHeight={600} />
          </div>
          <div className='col-lg-4 py-5'>
            <VendorSummaryCard vendors={top5Vendors} />
          </div>
        </div>
      </div>
    </>
  )
}

export {MarketingDashboardWrapper}
