import {createSlice} from '@reduxjs/toolkit'

interface ProjectsState {
  projects: any[]
  loading: boolean
  fetch: boolean
}

const initialState: ProjectsState = {
  projects: [],
  loading: false,
  fetch: true,
}

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setCRMProjects: (state, action) => {
      state.projects = action.payload
      state.loading = false
      state.fetch = false
    },
    setCRMLoading: (state, action) => {
      state.loading = action.payload
    },
    setCRMProjectsDataFetch: (state) => {
      state.fetch = true
    },
  },
})

export const {setCRMProjects, setCRMLoading, setCRMProjectsDataFetch} = projectsSlice.actions
export default projectsSlice.reducer
