import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {formatCurrency} from '../../../utils/utils'
import {formatDate} from '../../../utils/formatDate'
import {getHRPProjects} from '../core/_requests'
import {useAuth} from '../../../modules/auth'
import {useSelector, useDispatch} from 'react-redux'
import {setHRPProjects} from '../../../redux/hrp/projectsSlice'
import {RootState} from '../../../redux/store'

const HRPTimesheet = () => {
  const {currentUser} = useAuth()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const hrpProjectData = useSelector((state: RootState) => state.hrp.projects.projects)
  const hrpProjectFetch = useSelector((state: RootState) => state.hrp.projects.fetch)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const response = await getHRPProjects(currentUser?.organization)
      dispatch(setHRPProjects(response))
      setLoading(false)
    }
    if (currentUser.organization !== undefined && hrpProjectFetch) fetchData()
  }, [currentUser.organization])

  return (
    <div className='p-6 md:m-4 card card-xxl-stretch'>
      <div className='d-flex justify-between card-label fw-bold fs-3 mb-5'>Time Sheet</div>

      <div className='card-body py-3'>
        {loading ? (
          <div className='d-flex align-items-center justify-center'>
            <div className='loader'></div>
          </div>
        ) : (
          <div className='table-responsive min-h-500px'>
            <table className='table align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-25px'>No.</th>
                  <th className='min-w-200px rounded-start'>Project Name</th>
                  <th className='min-w-125px'>Budget</th>
                  <th className='min-w-125px'>Due Date</th>
                </tr>
              </thead>
              <tbody>
                {hrpProjectData &&
                  hrpProjectData.map((item, i) => (
                    <tr key={i} style={{marginBottom: '10px'}}>
                      <td className='ps-4'>{i + 1}</td>
                      <td>
                        <Link to={`${item._id}`}>
                          <span className='text-dark fw-bold text-hover-primary fs-6 cursor-pointer'>
                            {item?.projectName}
                          </span>
                        </Link>
                      </td>
                      <td>{formatCurrency(item?.budgetAmount)}</td>
                      <td>{formatDate(item?.releaseDate)}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  )
}

export default HRPTimesheet
