import React, {useEffect, useState} from 'react'
import {useAuth} from '../../../modules/auth'
import {useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../../redux/store'
import {Modal, Button, Form} from 'react-bootstrap'
import toast from 'react-hot-toast'
import {getCRMServices, postCRMService} from '../core/_requests'
import {Link} from 'react-router-dom'
import {PF} from '../../../utils/utils'
import {setServices} from '../../../redux/inventory/products/servicesSlice'
import {getItemsByType} from '../../sales/Core/_requests'
import {setCRMServices} from '../../../redux/crm/services/servicesSlice'

const CRMServices = () => {
  const {currentUser} = useAuth()
  const dispatch = useDispatch()
  const [permissions, setPermissions] = useState<any>({})
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedProducts, setSelectedProducts] = useState([])
  const [availableProducts, setAvailableProducts] = useState([])
  const [loading, setLoading] = useState<boolean>(false)
  const servicesList = useSelector((state: RootState) => state.inventory.services.services)
  const productDataList = useSelector((state: RootState) => state.crm.services.services)
  const crmServicesFetch = useSelector((state: RootState) => state.crm.services.fetch)

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const getProductData = async () => {
    try {
      const resp = await getItemsByType('service', currentUser?.organization)
      const data = resp.map((r) => ({
        value: r._id,
        label: r.name,
        price: r.price,
        inWarehouseQuantity: 1,
        type: 'item',
      }))
      setAvailableProducts(data)
      dispatch(setServices(resp))
    } catch (e) {
      console.log(e)
    }
  }

  const fetchProducts = () => {
    getProductData()
  }

  useEffect(() => {
    if (servicesList?.length === 0) fetchProducts()
    else {
      setAvailableProducts(
        servicesList?.map((r) => ({
          value: r._id,
          label: r.name,
          price: r.price,
          inWarehouseQuantity: 1,
          type: 'item',
        }))
      )
    }
  }, [])

  const handleProductSelect = (productId) => {
    setSelectedProducts((prev) =>
      prev.includes(productId) ? prev.filter((id) => id !== productId) : [...prev, productId]
    )
  }

  const fetchData = async () => {
    setLoading(true)
    const response = await getCRMServices(currentUser?.organization)
    dispatch(setCRMServices(response))
    const temp = response.map((product) => product._id)
    setSelectedProducts(temp)
    setLoading(false)
  }

  useEffect(() => {
    if (currentUser.organization !== undefined) {
      if (crmServicesFetch) fetchData()
      else {
        const temp = productDataList.map((service) => service._id)
        setSelectedProducts(temp)
      }
    }
  }, [currentUser.organization])

  const handleSubmit = async () => {
    try {
      if (selectedProducts.length === 0) {
        toast.error('Please select at least one service')
        return
      }
      const data = {
        service: selectedProducts,
        company: currentUser?.company,
        organization: currentUser?.organization,
      }
      await postCRMService(data).then((res) => {
        toast.success('Services added successfully')
        setIsModalOpen(false)
        fetchData()
      })
    } catch (error) {
      console.error('Error submitting projects:', error)
    }
  }

  return (
    <div>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>Services</h3>
        <div className='d-flex flex-wrap gap-2'>
          {(permissions?.items?.create || currentUser?.profileType === 'superadmin') && (
            <Link to='/items/add'>
              <Button variant='success'>Create Service</Button>
            </Link>
          )}
          {(permissions?.crmservices?.update || currentUser?.profileType === 'superadmin') && (
            <Button variant='primary' onClick={() => setIsModalOpen(true)}>
              Add Service
            </Button>
          )}
        </div>
      </div>

      <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Select Services</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div style={{maxHeight: '60vh', overflowY: 'auto'}}>
              {availableProducts?.map((product) => (
                <Form.Group key={product.value} className='mb-3'>
                  <Form.Check
                    type='checkbox'
                    id={`service-${product.value}`}
                    label={product?.label}
                    checked={selectedProducts.includes(product.value)}
                    onChange={() => handleProductSelect(product.value)}
                  />
                </Form.Group>
              ))}
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setIsModalOpen(false)}>
            Close
          </Button>
          <Button variant='primary' onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {loading ? (
        <div className='d-flex align-items-center justify-center'>
          <div className='loader'></div>
        </div>
      ) : (
        <div className='card'>
          <div className='card-body py-3'>
            <div className='table-responsive'>
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted '>
                    <th className='min-w-25px text-center'>Sr. No.</th>
                    <th className='min-w-150px'>Name</th>
                    <th className='min-w-100px'>Price</th>
                    <th className='min-w-100px'>SKU</th>
                  </tr>
                </thead>
                <tbody>
                  {productDataList &&
                    productDataList.map((item, i) => (
                      <tr key={i} className=''>
                        <td className='text-center'>{i + 1}</td>
                        <td className='d-flex align-items-center'>
                          <div className='symbol symbol-50px me-2'>
                            <span className='symbol-label'>
                              <img
                                src={`${PF}${item.thumbnail}`}
                                alt=''
                                className='h-50 align-self-center'
                              />
                            </span>
                          </div>
                          <div className='d-flex justify-content-start flex-column'>
                            <Link to={`/items/${item._id}`}>
                              <span className='text-dark fw-bold text-hover-primary fs-6'>
                                {item.name}
                              </span>
                            </Link>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                              {item.description}
                            </span>
                          </div>
                        </td>
                        <td>{item.price}</td>
                        <td>{item.sku}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default CRMServices
