import axios, {AxiosResponse} from 'axios'
const API_URL = process.env.REACT_APP_THEME_API_URL

const CUSTOMER_SEARCH_URL = `${API_URL}/sales/customer/search`
const VENDOR_SEARCH_URL = `${API_URL}/purchases/vendor/search`
const EMPLOYEE_SEARCH_URL = `${API_URL}/employee/search`

export const searchCustomers = async (name: string, org: string) => {
  try {
    const response = await axios.get(`${CUSTOMER_SEARCH_URL}/${org}?search=${name}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const searchVendors = async (name: string, org: string) => {
  try {
    const response = await axios.get(`${VENDOR_SEARCH_URL}/${org}?search=${name}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const searchEmployees = async (name: string, org: string) => {
  try {
    const response = await axios.get(`${EMPLOYEE_SEARCH_URL}/${org}?search=${name}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}
