// import React, { useEffect, useState } from 'react'
// import { useParams } from 'react-router-dom'
// // import {getInvoicebyids} from '../core/_requests'
// import '../print.css'
// import html2pdf from 'html2pdf.js'
// import { isAccordionItemSelected } from 'react-bootstrap/esm/AccordionContext'
// import { getPurchaseReceiveById } from '../../Core/_requests'

// function PurchaseReceive({ companyLogo }) {

//   const [vat, setVat] = useState(0);
//   const [total, setTotal] = useState(0);
//   const {id} = useParams()
//   const [data, setPurchaseReceiveData] = useState<any>()

//   const getData = async () => {
//     try {
//       const res = await getPurchaseReceiveById(id)
//       setPurchaseReceiveData(res)
//       console.log("res", res)
//     } catch (error) {
//       console.error('Axios error:', error)
//       throw error
//     }
//   }
//   useEffect(() => {
//     getData()
//   }, [])

import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
// import {getInvoicebyids} from '../core/_requests'
import '../print.css'
import {getPaymentMadeById} from '../../Core/_requests'
import {isAccordionItemSelected} from 'react-bootstrap/esm/AccordionContext'

function PaymentMadeMultiLang({companyLogo, color, setColor}) {
  const [vat, setVat] = useState(0)
  const [total, setTotal] = useState(0)
  const [data, setPaymentMadeData] = useState<any>()
  const {id} = useParams()
  const [contactPerson, setContactPerson] = useState<any>()
  const [imageSize, setImageSize] = useState<any>(false)
  const getData = async () => {
    try {
      const res = await getPaymentMadeById(id)
      setPaymentMadeData(res)
      console.log('res', res)
      setColor(res?.company?.procurementColor)
      if(res?.contactPerson !== ''){
        setContactPerson(res?.vendor?.contactPersons?.find((person)=>(
          person?._id === res?.contactPerson
        )) || {})
      } else{
        setContactPerson(res?.vendor?.primaryContact)
      }
    } catch (error) {
      console.error('Axios error:', error)
      throw error
    }
  }
  useEffect(() => {
    getData()
  }, [])

  const handleOnLoad = (e) => {
    const img = e.target
    if(img.naturalWidth > img.naturalHeight){
      setImageSize(true)
    } else{
      setImageSize(false)
    }
  }

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      // day: '2-digit',
      // month: 'short',
      // year: 'numeric',
    })
    return formattedDate
  }

  useEffect(() => {
    if (data?.tax) {
      const tax = (data?.subtotal * data?.tax) / 100
      setVat(tax)
    }
  }, [data])

  const handlePrint = () => {
    window.print() // This will trigger the browser's print functionality
  }
  const PF = 'https://qf-assets.s3.me-central-1.amazonaws.com/'

  return (
    <>
      <div className='app-main flex-column flex-row-fluid ' id='kt_app_main'>
        {/*begin::Content wrapper*/}
        <div className='d-flex flex-column flex-column-fluid'>
          {/*begin::Toolbar*/}
          <div
            id='kt_app_toolbar'
            className='app-toolbar mb-6 '
            // data-kt-sticky="false"
            // data-kt-sticky-name="app-toolbar"
            // data-kt-sticky-offset="{default: 'false', lg: '300px'}"
          ></div>
          {/*end::Toolbar*/}
          {/*begin::Content*/}
          <div className='app-content flex-column-fluid '>
            {/*begin::Content container*/}
            <div id='kt_app_content_container' className='app-container  container-xxl '>
              {/* begin::Invoice 3*/}
              <div id='pdf-content' className='card print-content '>
                {/* begin::Body*/}
                <div className='card-body py-8'>
                  {/* begin::Wrapper*/}
                  <div className='mw-lg-1050px w-100'>
                    {/* begin::Header*/}
                    <div className='d-flex justify-content-between align-items-center flex-column flex-sm-row mb-8'>
                      {/*end::Logo*/}
                      <div className='text-sm-end'>
                        {/*begin::Logo*/}
                        <a href='#' className={`d-block ${imageSize ? 'mw-275px' : 'mw-150px'} ms-sm-auto`}>
                        {companyLogo ? (
                      <img alt='Logo' src={URL.createObjectURL(companyLogo)} className={
                        imageSize ? 'w-100' : 'w-50'
                      }  onLoad={handleOnLoad}/>
                    ) : (
                      <img
                        alt='thumbnail'
                        src={PF + data?.company?.companyLogo}
                        className={imageSize ? 'w-100' : 'w-50'} onLoad={handleOnLoad}
                      />
                    )}
                        </a>
                        {/*end::Logo*/}
                      </div>
                      {/* <h4 className='fw-bold text-gray-700 fs-qx pb-7'>Company Address</h4> */}
                      <h4 className='fw-bold text-gray-800 xl:fs-1 d-flex flex-column pb-7 text-right header-text'>
                      <span
              className='mb-1 text-right fs-1 tracking-wider '
              style={{
                fontFamily: 'Rubik',
                fontOpticalSizing: 'auto',
                fontStyle: 'normal',
                wordSpacing: '10px',
              }}
            >
              {data?.company?.arabicName}
            </span>
                    <span className='mb-1 text-right fs-3 tracking-wider'>
                      {data?.company?.name}
                    </span>
                  <span className='fs-6 text-muted'>
                    VAT:{data?.company?.vat} | C.R : {data?.company?.cr}
                  </span>
                </h4>
                    </div>
                    {/*end::Header*/}
                    <div className='separator'></div>
                    {/*begin::Body*/}

                    <div className='fs-3 mt-5'>
                      تم الدفع
                      <br />
                      Payment Made
                    </div>
                    <div className='pb-2 mt-5'>
                      {/*begin::Wrapper*/}
                      <div className='d-flex flex-column gap-7 gap-md-10'>
                        {/* <div className=" d-flex flex-root justify-content-between gap-7 gap-sm-0 g-5 flex-column  mb-5  fw-bold">
                            <div className="d-flex flex-md-root flex-column mb-8">
                              <span className="text-muted">Purchase Order</span>
                              <span className="fs-6">#{data?.purchaseOrder?.id}</span>
                            </div>
                            <div className="flex-md-root d-flex flex-column mb-8">
                              <span className="text-muted">Date</span>
                              <span className="fs-6">{formatCreatedAt(data?.createdAt)}</span>
                            </div>
                          </div> */}
                        <div className='d-flex justify-content-between flex-column flex-sm-row'>
                          <div className=' mb-5 fw-bold'>
                            <div className='d-flex  flex-column mb-5'>
                              <span className='text-muted'>
                                قسط
                                <br />
                                Payment
                              </span>
                              <span className='fs-7'>{data?.id}</span>
                            </div>
                            <div className='d-flex flex-column mb-5'>
                              <span className='text-muted'>
                                المدفوعة لل
                                <br />
                                Paid To
                              </span>

                              <span className='fs-7 '>{data?.vendor?.companyName}</span>
                            </div>
                            <div className='d-flex flex-md-root flex-column fw-bold mb-5'>
                              <span className='text-muted mb-1'>
                                عنوان
                                <br />
                                Address
                              </span>
                              <span className='fs-7 mw-200px'>
                                {data?.vendor?.billingAddress.addressLine1}{' '}
                                {data?.vendor?.billingAddress.addressLine2}
                                <br />
                                {data?.vendor?.billingAddress.city}{' '}
                                {data?.vendor?.billingAddress.postalCode}
                                <br />
                                {data?.vendor?.billingAddress.country}
                              </span>
                            </div>
                            {/* <div className="d-flex flex-column mb-5">
                              <span className="text-muted">Contact Number</span>

                              <span className="fs-7 text-dark">{data?.vendor?.contactNumbers?.mobilePhone}</span>
                            </div> */}
                          </div>
                          <div className='  mb-5  fw-bold'>
                            <div className='d-flex  flex-column mb-5'>
                              <span className='text-muted'>
                                انتباه
                                <br />
                                Attention
                              </span>
                              <span className='fs-7'>
                                {contactPerson?.salutation}{' '}
                                {contactPerson?.firstName}{' '}
                                {contactPerson?.lastName}
                              </span>
                            </div>
                            <div className=' d-flex flex-column mb-5'>
                              <span className='text-muted'>
                                طريقة الدفع
                                <br />
                                Payment Mode
                              </span>
                              <span className='fs-7'>{data?.paymentMode}</span>
                            </div>
                            <div className=' d-flex flex-column  '>
                              <span className='text-muted'>
                                المدفوعة من خلال
                                <br />
                                Paid Through
                              </span>
                              <span className='fs-7'>{data?.paymentMode}</span>
                            </div>
                            {/* <div className="flex-root d-flex flex-column">
                                                    <span className="text-muted">Shipment ID</span>
                                                    <span className="fs-5">#SHP-0025410</span>
                                                </div> */}
                          </div>
                          <div className='text-end  mb-5  fw-bold'>
                            {/* <div className="d-flex flex-column mb-5">
                              <span className="text-muted">Quotation Date</span>

                              <span className="fs-7">{formatCreatedAt(data?.createdAt)}</span>
                            </div>
                            <div className=" d-flex flex-column mb-5">
                              <span className="text-muted">Quotation Number</span>

                              <span className="fs-7">{data?.id}</span>
                            </div> */}
                            <div className=' d-flex flex-column mb-5 '>
                              <span className='text-muted'>
                                حالة
                                <br />
                                Status
                              </span>
                              <span className='fs-7'>{data?.approval}</span>
                            </div>
                            <div className=' d-flex flex-column  mb-5 '>
                              <span className='text-muted'>
                                تاريخ الدفع
                                <br />
                                Payment Date{' '}
                              </span>

                              <span className='fs-7'>{formatCreatedAt(data?.paymentDate)}</span>
                            </div>
                            <div className=' d-flex flex-column '>
                              <span className='text-muted'>
                                المبلغ المدفوع
                                <br />
                                Amount Paid
                              </span>
                              <span className=''>
                                <span className='fs-5 badge badge-success' 
                                style={{
                                  padding: '0.5rem',
                                }}>
                                  {data?.vendor?.currency}{' '}
                                  {data?.amountPaid.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                  })}{' '}
                                </span>
                              </span>
                            </div>

                            {/* <div className="flex-root d-flex flex-column">
                                                    <span className="text-muted">Shipment ID</span>
                                                    <span className="fs-5">#SHP-0025410</span>
                                                </div> */}
                          </div>
                        </div>

                        {/*end::Order details*/}
                        {/*begin::Billing & shipping*/}
                        {/* <div className="d-flex flex-column flex-root mb-10 gap-7 gap-sm-20 justify-content-between ">
                            <div className="d-flex flex-md-root flex-column fw-bold">
                              <span className="text-muted mb-2">Vendor </span>
                              <span className='text-primary'>
                                {data?.vendor?.displayName}
                              </span>
                              <span className="fs-6">
                                {data?.vendor?.billingAddress.addressLine1}{" "}
                                {data?.vendor?.billingAddress.addressLine2}
                                <br />
                                {data?.vendor?.billingAddress.city}{" "}
                                {data?.vendor?.billingAddress.postalCode}
                                <br />
                                {data?.vendor?.billingAddress.country}

                              </span>
                            </div>
                            
                          </div> */}
                        {/* <div className="flex-column flex-root">
                            <div className="flex-md-root d-flex flex-column mb-8 fw-bold">
                              <span className="text-muted mb-4">Status</span>
                              <span className="">
                                <span className="badge fs-3 card bg-success text-white py-4 px-3">RECEIVE</span>
                              </span>
                            </div>
                          </div> */}

                        {/*end::Billing & shipping*/}
                        {/*begin:Order summary*/}
                        <div className='flex-grow-1'>
                          {/*begin::Table*/}
                          <div className='table-responsive border-bottom border-dashed mb-9'>
                            <table className='table table-bordered m-0'>
                              <thead>
                                <tr className='fs-9 fw-bold ' style={{
                                  backgroundColor: color,
                                }}>
                                  {/* <th className="min-w-50px text-white text-start pb-3 ">
                                    <span>Sr. No.</span>
                                  </th> */}
                                  <th className='min-w-80px text-white text-center pb-3 '>
                                    رقم الفاتوره
                                    <br />
                                    Bill Number
                                  </th>
                                  <th className='min-w-80px text-white text-center pb-3'>
                                    تاريخ الفاتورة
                                    <br />
                                    Bill Date تاريخ الفاتورة
                                  </th>
                                  <th className='min-w-80px text-white text-center pb-3'>
                                    مبلغ الفاتورة
                                    <br />
                                    Bill Amount
                                  </th>
                                  <th className='min-w-80px text-white text-center pb-3'>
                                    مبلغ الدفع
                                    <br />
                                    Payment Amount
                                  </th>
                                </tr>
                              </thead>
                              <tbody className='' style={{height: '250px'}}>
                                {data?.billsData?.map((items, index) => (
                                  <tr
                                    key={index}
                                    className='border fw-semibold text-gray-600 fs-7 text-center'
                                  >
                                    <td>{items.billNo?.id}</td>
                                    <td className=''>{formatCreatedAt(items.date)}</td>
                                    <td>
                                      {' '}
                                      {Number(items.billAmount).toLocaleString('en-US', {
                                        minimumFractionDigits: 2,
                                      })}{' '}
                                      {/* {data?.vendor?.currency} */}
                                    </td>
                                    <td>
                                      {Number(data?.amountPaid).toLocaleString('en-US', {
                                        minimumFractionDigits: 2,
                                      })}{' '}
                                      {/* {data?.vendor?.currency} */}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          {/*end::Table*/}
                          {/*begin::Container*/}

                          {/*end::Container*/}
                        </div>
                        {/*end:Order summary*/}
                      </div>
                      {/*end::Wrapper*/}
                    </div>
                    {/*end::Body*/}
                    {/* begin::Footer*/}

                    {/* end::Footer*/}
                  </div>
                  {/* end::Wrapper*/}
                </div>
                {/* end::Body*/}
                <div id='pdfFooter' className='card-footer text-center p-2 fw-bold'>
                  <div className='bg-secondary -skew-x-12 text-gray-800'>
                    {/* <div className='skew-x-12 py-1'>Tel | Fax Number: {data?.company?.faxNumber} | P.O. Box : {data?.company?.pOBox} | {data?.company?.companyAddress}</div> */}
                    <div className='skew-x-12 py-1'>
                       Mobile :{' '}
                      {data?.company?.mobileNumber} | P.O.
                      Box : {data?.company?.pOBox}{' '}
                    </div>
                  </div>
                  <div className=' -skew-x-12 text-white ' style={{
                    backgroundColor: color,
                  }}>
                    <div className='skew-x-12 py-1'>
                      E-mail : {data?.company?.companyEmail} | Website : {data?.company?.webURL}
                    </div>
                  </div>
                </div>
              </div>
              {/* end::Invoice 1*/}{' '}
            </div>
            {/*end::Content container*/}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Content wrapper*/}
        {/*begin::Footer*/}
        {/*end::Footer*/}{' '}
      </div>
    </>
  )
}

export default PaymentMadeMultiLang
