import React, {useEffect, useRef, useState} from 'react'
import {KTIcon} from '../../../../../_metronic/helpers'
import {
  bulkUploadCustomer,
  deactivateCustomer,
  getCustomerByStatus,
  getCustomerByStatusForAgent,
} from '../../Core/_requests'
import {Link, useNavigate} from 'react-router-dom'
import Search from '../../Components/Search'
import {Filtertype, SearchFilter, initialSearchFilter} from '../../Core/_model'
import Filter from '../../Components/Filter'
import qs from 'qs'
import {useAuth} from '../../../../modules/auth'
import AgentSearch from './AgentSearch'
import {useSelector, useDispatch} from 'react-redux'
import {setCustomerData, setError} from '../../../../redux/sales/customer/customerSlice'
import useExcelImport from '../../../account/components/useExcelImport'
import {Spinner} from 'react-bootstrap'
import {RootState} from '../../../../redux/store'

export const Customer = () => {
  const {currentUser} = useAuth()
  const dispatch = useDispatch()
  const excelfileRef = useRef<HTMLInputElement>(null)
  const [permissions, setPermissions] = useState<any>({})
  // const [customerData, setCustomerData] = useState([])
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [selectedIndex, setSelectedIndex] = useState<any>(null)
  const [customerId, setCustomerId] = useState<string>('')
  const customerData = useSelector((state: RootState) => state.sales.customer.customerData)
  const customerError = useSelector((state: RootState) => state.sales.customer.error)
  const [excelImportLoading, setExcelImportLoading] = useState(false)
  const handleUploadExcel = useExcelImport()

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const navigate = useNavigate()
  let searchFilters: SearchFilter = initialSearchFilter

  const setSearchFilter = (updateValues: {
    search?: string
    agent?: string
    filter?: Partial<Filtertype>
  }): void => {
    searchFilters = {
      ...searchFilters,
      ...(updateValues.search && {search: updateValues.search}),
      ...(updateValues.agent && {agent: updateValues.agent}),
      ...(updateValues.filter && {
        filter: {
          ...searchFilters.filter,
          ...updateValues.filter,
        },
      }),
    }
  }

  const setStatusFilter = (isActive: boolean) => {
    setSearchFilter({
      filter: {
        isActive,
      },
    })
  }

  const handleDeleteToggle = (i) => {
    setSelectedIndex(i)
  }

  const handleDelete = async (i) => {
    console.log(i)
    try {
      await deactivateCustomer(i)
      getCustomers()
    } catch (error) {
      console.error('Error deleting employee:', error)
    }
  }

  const stringifyObject = (obj: SearchFilter): string => {
    const queryString = qs.stringify(
      {
        search: obj.search,
        agent: obj.agent,
        filter_status: obj.filter.isActive,
      },
      {encodeValuesOnly: true, skipNulls: true, delimiter: '&'}
    )

    return queryString
  }

  const getCustomerlist = async () => {
    setSearchFilter({
      search: searchTerm,
    })
    console.log('searchFilter inside:', searchFilters)
    try {
      const queryString = stringifyObject(searchFilters)
      console.log('query org', queryString)

      const res = await getCustomerByStatus(queryString, currentUser?.organization)
      console.log(res)

      const sortedCustomerData = res.sort((a: any, b: any) => {
        const dateA: any = new Date(a.createdAt)
        const dateB: any = new Date(b.createdAt)
        return dateB - dateA
      })

      // setCustomerData(sortedCustomerData)
      dispatch(setCustomerData(sortedCustomerData))
      console.log('customerData', customerData)
    } catch (error) {
      dispatch(setError(error.message))
      console.error('Error fetching Customer list:', error)
    }
  }
  const getCustomerForAgent = async () => {
    setSearchFilter({
      search: searchTerm,
    })

    try {
      const queryString = stringifyObject(searchFilters)
      console.log(queryString)
      const res = await getCustomerByStatusForAgent(queryString, currentUser?._id)
      console.log(res)
      // setCustomerData(res)
      dispatch(setCustomerData(res))

      console.log('customerData', customerData)
    } catch (error) {
      dispatch(setError(error.message))
      console.error('Error fetching Customer list:', error)
    }
  }

  const getCustomers = () => {
    if (permissions?.customers?.admin === false) {
      getCustomerForAgent()
    } else if (permissions?.customers?.admin || currentUser?.profileType === 'superadmin') {
      getCustomerlist()
    }
  }

  useEffect(() => {
    getCustomers()
  }, [searchTerm, currentUser, permissions])

  const handleTrigger = () => {
    getCustomers()
  }

  const handlereset = async () => {
    setSearchFilter(initialSearchFilter)
    getCustomers()
  }

  const handleClick = (e) => {
    e.preventDefault()
    navigate('/sales/customer/addCustomer')
  }

  if (customerError) {
    return <div>error from us</div>
  }

  return (
    <div className='card'>
      <span className=' card-label fw-bold fs-3 ps-15 pt-8 '>Customer List</span>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <div className=' d-flex flex-row  card-title align-items-start'>
          <Search searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
          <Filter
            searchFilters={searchFilters}
            setSearchFilter={setSearchFilter}
            handlereset={handlereset}
            getDocsList={getCustomers}
            setStatusFilter={setStatusFilter}
          />
        </div>
        {(currentUser?.hierarchy === 1 || currentUser?.profileType === 'superadmin') && (
          <div className='!ml-auto !mr-3 card-toolbar'>
            <button
              className='btn btn-danger btn-sm'
              onClick={() => {
                excelfileRef.current.click()
              }}
            >
              {excelImportLoading ? (
                <>
                  <Spinner animation='border' role='status' />
                </>
              ) : (
                'Import Customers'
              )}
            </button>
            <input
              type='file'
              accept='.xlsx, .xls'
              onChange={(e) =>
                handleUploadExcel(e, bulkUploadCustomer, setExcelImportLoading, 'customers')
              }
              ref={excelfileRef}
              className='hidden'
            />
          </div>
        )}
        {(permissions?.customers?.create || currentUser?.profileType === 'superadmin') && (
          <>
            <div className='card-toolbar'>
              <div onClick={handleClick} className='btn btn-sm btn-light-primary'>
                <KTIcon iconName='plus' className='fs-2' />
                New Customer
              </div>
            </div>
          </>
        )}
      </div>
      {/* end::Header */}
      {/* begin::Body */}

      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-200px rounded-start'>Customer Display Name</th>
                <th className='min-w-125px'>Customer Type</th>
                <th className='min-w-125px'>Company Name</th>
                <th className='min-w-150px'>Email</th>
                <th className='min-w-150px'>Currency</th>
                {(permissions?.customers?.update || currentUser?.profileType === 'superadmin') && (
                  <th className='min-w-150px text-center'>Action</th>
                )}

                {/* <th className='min-w-200px text-end rounded-end'></th> */}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {Array.isArray(customerData) &&
                customerData

                  // .filter((customer) => customer.isActivated === searchFilters.filter.isActive)
                  .map((customer, index) => (
                    <tr key={index}>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='symbol symbol-50px me-5'>
                            {/* <img
                          src={toAbsoluteUrl('/media/avatars/300-11.jpg')}
                          className='img-fluid rounded-circle'
                          alt=''
                        /> */}
                          </div>
                          <div className='d-flex justify-content-start flex-column'>
                            <Link
                              to={`/sales/customer/${customer._id}`}
                              className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                            >
                              {customer.displayName}
                            </Link>
                          </div>
                        </div>
                      </td>
                      <td>
                        <Link
                          to={`/sales/customer/${customer._id}`}
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          {customer.customerType}
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/sales/customer/${customer._id}`}
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          {customer.companyName}
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/sales/customer/${customer._id}`}
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          {customer.emailAddress}
                        </Link>
                      </td>
                      <td>
                        <span className='badge badge-light-primary fs-7 fw-semibold'>
                          {customer.currency}
                        </span>
                      </td>
                      {(permissions?.customers?.update ||
                        currentUser?.profileType === 'superadmin') && (
                        <td className='text-center'>
                          <a
                            href='#'
                            className='btn btn-light btn-active-light-primary btn-flex btn-center btn-sm'
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'
                          >
                            Actions
                            <i className='ki-duotone ki-down fs-5 ms-1'></i>
                          </a>

                          <div
                            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4'
                            data-kt-menu='true'
                          >
                            <div className='menu-item px-3'>
                              <Link
                                to={`/sales/customer/edit/${customer._id}`}
                                className='menu-link px-3'
                              >
                                Edit
                              </Link>
                            </div>

                            {(permissions?.customers?.assign ||
                              currentUser?.profileType === 'superadmin') && (
                              <div className='menu-item px-3'>
                                <a
                                  href='#'
                                  data-bs-toggle='modal'
                                  data-bs-target='#kt_modal_4'
                                  className='menu-link px-3'
                                  onClick={() => setCustomerId(customer._id)}
                                >
                                  Assign
                                </a>
                              </div>
                            )}

                            <div className='menu-item px-3'>
                              <button
                                onClick={() => handleDeleteToggle(customer._id)}
                                data-bs-toggle='modal'
                                data-bs-target='#myModal'
                                className='menu-link px-3 hover:w-full'
                                data-kt-users-table-filter='delete_row'
                              >
                                {customer?.isActivated ? 'Deactivate' : 'Activate'}
                              </button>
                            </div>
                          </div>
                        </td>
                      )}
                    </tr>
                  ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      <AgentSearch customerId={customerId} handleTrigger={handleTrigger} />
      {/* begin::Body */}
      <div className='modal' id='myModal'>
        <div className='modal-dialog modal-dialog-centered '>
          <div className='modal-content'>
            {/* <div className='modal-header'>
              <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
            </div> */}

            <div className='modal-body text-center fs-6'>
              Are you sure you want to{' '}
              {customerData.find((customer) => customer._id === selectedIndex)?.isActivated
                ? 'Deactivate'
                : 'Activate'}{' '}
              the {customerData.find((customer) => customer._id === selectedIndex)?.displayName} ?
            </div>

            <div className='modal-footer text-center mx-auto'>
              <button
                type='button'
                className='btn btn-danger '
                data-bs-dismiss='modal'
                onClick={() => handleDelete(selectedIndex)}
              >
                {customerData.find((customer) => customer._id === selectedIndex)?.isActivated
                  ? 'Deactivate'
                  : 'Activate'}
              </button>

              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                id='closeModalButtondelete'
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
