import React, {useEffect, useState} from 'react'
import Select from 'react-select'
// import DatePicker from 'react-datepicker';
// import styled from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css'

// import '~flatpickr/dist/flatpickr.css';
// import Flatpickr from "flatpickr";
import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/themes/material_blue.css' // Choose a theme from the flatpickr themes folder
import 'flatpickr/dist/flatpickr.min.css' // Import the Flatpickr CSS
// import { getAgent, getClient, getClientbyagent, getClientbycompany, getInvoiceid, getProperty, getQuoteid, postquote } from '../core/_requests';
// import { Client } from '../core/_models';

import {useAuth} from '../../../modules/auth/core/Auth'
// import { Agentlist } from '../../agent/components/Agentlist';
import {KTIcon} from '../../../../_metronic/helpers'
import {useNavigate} from 'react-router-dom'
import {getCustomer} from '../../sales/Core/_requests'
import {getVendor} from '../../purchaseOrders/Core/_requests'
import {
  getAccountsForExpense,
  getAccountsForExpensePaidThrough,
  postExpense,
} from '../core/_requests'
function AddExpense() {
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const [customerList, setCustomerList] = useState<any>([])
  const [vendorList, setVendorList] = useState<any>([])
  const [selectedBillFrom, setselectedBillFrom] = useState(null)
  const [selectedBillTo, setselectedBillTo] = useState(null)
  const [selectedItem, setselectedItem] = useState(null)
  const [defaultAgent, setDefaultAgent] = useState()
  const [submitted, setSubmitted] = useState(false)
  const [isEditingNotes, setIsEditingNotes] = useState(false)
  const [expenseAccounts, setExpenseAccounts] = useState({})
  const [paidThroughAccounts, setPaidThroughAccounts] = useState({})
  const [loading, setLoading] = useState(false)

  const [expense, setExpense] = useState({
    date: new Date(),
    expenseAccount: '',
    amount: 0,
    paidThrough: '',
    vendor: '',
    reference: '',
    notes: '',
    customer: '',
  })

  const currencyOptions = [
    {
      value: 'SAR',
      label: 'SAR - United Arab Emirates dirham',
      flag: 'flags/united-arab-emirates.svg',
    },
    {value: 'USD', label: 'USD - USA dollar', flag: 'flags/united-states.svg'},
    {value: 'GBP', label: 'GBP - British pound', flag: 'flags/united-kingdom.svg'},
    {value: 'AUD', label: 'AUD - Australian dollar', flag: 'flags/australia.svg'},
    {value: 'JPY', label: 'JPY - Japanese yen', flag: 'flags/japan.svg'},
    {value: 'SEK', label: 'SEK - Swedish krona', flag: 'flags/sweden.svg'},
    {value: 'CAD', label: 'CAD - Canadian dollar', flag: 'flags/canada.svg'},
    {value: 'CHF', label: 'CHF - Swiss franc', flag: 'flags/switzerland.svg'},
    // Add other options similarly
  ]
  const defaultCurrency = currencyOptions.find((option) => option.value === 'SAR')

  const getCustomerList = async () => {
    try {
      const res = await getCustomer('', currentUser?.organization)
      console.log(res)
      setCustomerList(
        res.map((r) => ({
          value: r?._id,
          label: r?.displayName,
        }))
      )
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  const getVendorList = async () => {
    try {
      const res = await getVendor('', currentUser?.organization)
      console.log(res)
      setVendorList(
        res.map((r) => ({
          value: r?._id,
          label: r?.displayName,
        }))
      )
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  const fetchAccounts = async () => {
    const res = await getAccountsForExpense(currentUser?.organization)
    let data = {
      'Cost of Goods Sold': [],
      Expense: [],
      'Other Current Liability': [],
      'Other Asset': [],
      'Other Current Asset': [],
      'Fixed Asset': [],
    }
    res.forEach((r) => {
      if (r.accountType === 'costofgoodssold') {
        data['Cost of Goods Sold'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'expense') {
        data['Expense'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'othercurrentliability') {
        data['Other Current Liability'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'otherasset') {
        data['Other Asset'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'othercurrentasset') {
        data['Other Current Asset'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'fixedasset') {
        data['Fixed Asset'].push({
          value: r?._id,
          label: r?.accountName,
        })
      }
    })
    // const data =
    // const data = res.map((r) => ({
    //   value: r?._id,
    //   label: r?.accountName,
    // }))
    setExpenseAccounts(data)
    setExpense((prev) => ({
      ...prev,
      expenseAccount: data['Expense'][0]?.value,
    }))

    const resp = await getAccountsForExpensePaidThrough(currentUser?.organization)
    const paidThrough = {
      Cash: [],
      Bank: [],
      'Other Current Liability': [],
      Equity: [],
      'Other Current Asset': [],
      'Fixed Asset': [],
    }
    resp.forEach((r) => {
      if (r.accountType === 'cash') {
        paidThrough['Cash'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'bank') {
        paidThrough['Bank'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'othercurrentliability') {
        paidThrough['Other Current Liability'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'equity') {
        paidThrough['Equity'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'othercurrentasset') {
        paidThrough['Other Current Asset'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'fixedasset') {
        paidThrough['Fixed Asset'].push({
          value: r?._id,
          label: r?.accountName,
        })
      }
    })
    setPaidThroughAccounts(paidThrough)
    setExpense((prev) => ({
      ...prev,
      paidThrough: paidThrough['Cash'][0]?.value,
    }))
  }

  // const getClientListbyagent = async () => {
  //   try {
  //     const res = await getClientbyagent(currentUser?._id);
  //     setCustomerList(
  //       res.map((r) => ({
  //         value: r._id,
  //         label: r.name, // Corrected the typo here from "labe" to "label"
  //       }))
  //     );

  //     console.log(res);
  //   } catch (error) {
  //     console.error('Error fetching client list:', error);
  //   }
  // };

  const handleEditClick = (e, field) => {
    e.preventDefault()
    if (field === 'notes') {
      setIsEditingNotes(!isEditingNotes)
    }
  }

  useEffect(() => {
    // if(currentUser?.profileType === "superadmin"){
    getCustomerList()
    getVendorList()
    fetchAccounts()
    // }else if(currentUser?.profileType === "agent"){
    //   getClientListbyagent()
    //   setExpense((prev)=>({
    //     ...prev,
    //     agent: currentUser?._id
    //   }))
    // }
  }, [])

  const handleChange = (e) => {
    e.preventDefault()
    const {name, value} = e.target
    const rawValue = value.replace(/,/g, '') // Remove commas from the value

    setExpense((prevExpense) => ({
      ...prevExpense,
      [name]: rawValue, // Store the raw value without commas
    }))
  }

  const formatNumber = (num) => {
    if (!num) return ''
    return Number(num).toLocaleString('en-US')
  }

  const handleBlur = (e) => {
    const {name, value} = e.target
    const rawValue = value.replace(/,/g, '') // Ensure raw value is saved
    setExpense((prevExpense) => ({
      ...prevExpense,
      [name]: rawValue,
    }))
  }

  useEffect(() => {
    setDefaultAgent(vendorList.find((option) => option.value === currentUser?._id))
  }, [vendorList])

  const handleBillToChange = (selectedOption) => {
    setselectedBillTo(selectedOption)

    setExpense((prevInvoice) => ({
      ...prevInvoice,
      customer: selectedOption.value,
    }))
  }

  const handleBillFromChange = (selectedOption) => {
    console.log(selectedOption)
    setselectedBillFrom(selectedOption)
    setExpense((prevInvoice) => ({
      ...prevInvoice,
      vendor: selectedOption.value,
    }))
  }

  const handleDateChange = (selectedDate) => {
    setExpense((prevInvoice) => ({
      ...prevInvoice,
      date: selectedDate[0], // Flatpickr returns an array of selected dates
    }))
  }

  const handleSubmit = async () => {
    setLoading(true)
    const data = {
      ...expense,
      vendor: expense.vendor !== '' ? expense.vendor : null,
      customer: expense.customer !== '' ? expense.customer : null,
      company: currentUser?.company,
      organization: currentUser?.organization,
    }
    try {
      await postExpense(data).then((res) => {
        setSubmitted(true)
        console.log(res)
        setLoading(false)
        navigate(`/account/expenses`)
      })
    } catch (error) {
      console.log(error)
    }
  }

  // const getSalesOrderID = async () => {
  //   try {
  //     const res = await getSalesOrderLastId()
  //     setNextNumber(res.lastId + 1)
  //     setPrefix(res.prefix)
  //     console.log(res)
  //   } catch (error) {
  //     console.error('Error fetching client list:', error)
  //   }
  // }

  return (
    <div id='kt_app_content' className='app-content flex-column-fluid'>
      {/* begin::Content container */}
      <div id='kt_app_content_container' className='app-container container-xxl'>
        {/* begin::Layout */}
        <div className='d-flex flex-column flex-lg-row'>
          {/* begin::Content */}
          <div className='flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10'>
            {/* begin::Card */}
            <div className='card'>
              {/* begin::Card body */}
              <div className='card-body p-12'>
                {/* begin::Form */}
                <form action='' id='kt_quote_form'>
                  {/* begin::Wrapper */}
                  <div className='d-flex flex-column align-items-start flex-xxl-row'>
                    {/* begin::Input group */}
                    <div
                      className='d-flex align-items-center flex-equal fw-row me-4 order-2'
                      data-bs-toggle='tooltip'
                      data-bs-trigger='hover'
                      title='Specify expense date'
                    >
                      {/* begin::Date */}
                      <div className='fs-6 fw-bold text-gray-700 text-nowrap'>Date:</div>
                      {/* Date */}
                      {/* begin::Input */}
                      <div className='position-relative d-flex align-items-center w-150px'>
                        {/* begin::Datepicker */}
                        <Flatpickr
                          value={expense.date}
                          onChange={handleDateChange}
                          className='form-control form-control-solid'
                          placeholder='Pick date'
                          options={{dateFormat: 'd-m-Y'}}
                        />
                        {/* Datepicker */}
                        {/* begin::Icon */}
                        {/* <i className='ki-duotone ki-down fs-4 position-absolute ms-4 end-0'></i> */}
                        {/* Icon */}
                      </div>
                      {/* Input */}
                    </div>
                    {/* Input group */}
                    {/* begin::Input group */}
                    <div
                      className='d-flex flex-center flex-equal fw-row text-nowrap order-1 order-xxl-2 me-4'
                      data-bs-toggle='tooltip'
                      data-bs-trigger='hover'
                      title='Enter expense number'
                    >
                      <span className='fs-2x fw-bold text-gray-800'>Expense</span>
                      {/* <input
                        type='text'
                        className='form-control form-control-flush fw-bold text-muted fs-3 w-125px'
                        value={expense.id}
                        placeholder='...'
                        disabled
                      /> */}
                    </div>
                    {/* Input group */}
                    {/* begin::Input group */}
                    <div
                      className='d-flex align-items-center justify-content-end flex-equal order-3 fw-row'
                      data-bs-toggle='tooltip'
                      data-bs-trigger='hover'
                      title='Specify expense due date'
                    ></div>
                    {/* Input group */}
                  </div>
                  {/* Top */}
                  {/* begin::Separator */}
                  <div className='separator separator-dashed my-10'></div>
                  {/* Separator */}
                  {/* begin::Wrapper */}
                  <div className='mb-0'>
                    {/* begin::Row */}

                    <div className='row gx-10 mb-5'>
                      {/* begin::Col */}
                      <div className='col-lg-6'>
                        <label className='form-label required fs-6 fw-bold text-gray-700 mb-3'>
                          Expense Account
                        </label>
                        {/* begin::Input group */}
                        <select
                          className='form-select form-select-solid'
                          value={expense.expenseAccount}
                          name='expenseAccount'
                          onChange={handleChange}
                        >
                          {Object.keys(expenseAccounts).map((key, index) => {
                            return (
                              <optgroup label={key} key={index}>
                                {expenseAccounts[key].map((item) => {
                                  return (
                                    <option value={item?.value} key={item?.value}>
                                      {item?.label}
                                    </option>
                                  )
                                })}
                              </optgroup>
                            )
                          })}
                        </select>
                        {/* Input group */}
                      </div>
                      {/* Col */}
                      {/* begin::Col */}
                      <div className='col-lg-6'>
                        <label className='form-label required fs-6 fw-bold text-gray-700 mb-3'>
                          Paid Through
                        </label>
                        {/* begin::Input group */}
                        <select
                          className='form-select form-select-solid'
                          value={expense.paidThrough}
                          name='paidThrough'
                          onChange={handleChange}
                        >
                          {Object.keys(paidThroughAccounts).map((key, index) => {
                            return (
                              <optgroup label={key} key={index}>
                                {paidThroughAccounts[key].map((item) => {
                                  return (
                                    <option value={item?.value} key={item?.value}>
                                      {item?.label}
                                    </option>
                                  )
                                })}
                              </optgroup>
                            )
                          })}
                        </select>
                      </div>
                      {/* Col */}
                    </div>

                    <div className='row gx-10 mb-5'>
                      {/* begin::Col */}
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>Vendor</label>
                        {/* begin::Input group */}
                        <Select
                          name='vendor'
                          aria-label='Select a Vendor'
                          placeholder='Select Vendor'
                          options={vendorList}
                          defaultValue={{value: currentUser?._id, label: currentUser?.fullName}}
                          isSearchable={true}
                          className='form-control form-control-solid'
                          onChange={handleBillFromChange}
                          value={selectedBillFrom}
                        />
                        {/* Input group */}
                      </div>
                      {/* Col */}
                      {/* begin::Col */}
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                          Customer
                        </label>
                        {/* begin::Input group */}
                        <Select
                          name='customer'
                          aria-label='Select a Customer'
                          placeholder='Select a Customer'
                          options={customerList}
                          isSearchable={true}
                          className='form-control form-control-solid'
                          onChange={handleBillToChange}
                          value={selectedBillTo}
                        />
                      </div>
                      {/* Col */}
                    </div>
                    {/* input for amount */}
                    <div className='row gx-10 mb-5'>
                      {/* begin::Col */}
                      <div className='col-lg-6'>
                        <label className='form-label required fs-6 fw-bold text-gray-700 mb-3'>
                          Amount
                        </label>
                        {/* begin::Input group */}
                        <input
                          type='text'
                          name='amount'
                          className='form-control form-control-solid'
                          placeholder='Enter amount'
                          value={formatNumber(expense.amount)}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {/* Input group */}
                      </div>
                      {/* Col */}
                      {/* begin::Col */}
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                          Reference#
                        </label>
                        {/* begin::Input group */}
                        <input
                          type='text'
                          name='reference'
                          className='form-control form-control-solid'
                          placeholder='Enter reference number'
                          value={expense.reference}
                          onChange={handleChange}
                        />
                      </div>
                      {/* Col */}
                    </div>
                    <div className='mb-0'>
                      <label className='form-label fs-6 fw-bold text-gray-700'>Notes</label>
                      <textarea
                        name='notes'
                        className={`position-relative  form-control form-control-solid mb-0 h-10 ${
                          isEditingNotes ? 'border border-dark-subtle' : ''
                        }`}
                        placeholder='Enter customer notes'
                        value={expense.notes}
                        onChange={handleChange}
                        readOnly={!isEditingNotes}
                      />
                      <button onClick={(e) => handleEditClick(e, 'notes')}>
                        <KTIcon
                          iconName='pencil'
                          className='position-absolute text-black bg-gray-100 right-16 bottom-72 fs-3'
                        />
                      </button>
                    </div>

                    <div className='mb-0'>
                      {/* <!--begin::Row--> */}

                      {/* <!--::Row--> */}
                      {!submitted ? (
                        <button
                          type='button'
                          className='btn btn-primary w-30'
                          id='kt_quote_submit_button'
                          onClick={handleSubmit}
                          disabled={loading}
                        >
                          <i className='ki-duotone ki-triangle fs-3'>
                            <span className='path1'></span>
                            <span className='path2'></span>
                            <span className='path3'></span>
                          </i>
                          {loading ? 'Loading...' : 'Save Expense'}
                        </button>
                      ) : (
                        <button className='btn btn-danger w-100' id='kt_quote_submit_button'>
                          <i className='ki-duotone ki-triangle fs-3'>
                            <span className='path1'></span>
                            <span className='path2'></span>
                            <span className='path3'></span>
                          </i>
                          Print Expense
                        </button>
                      )}
                    </div>
                    {/* Notes */}
                  </div>
                  {/* Wrapper */}
                </form>
                {/* Form */}
              </div>
              {/* Card body */}
            </div>
            {/* Card */}
          </div>
        </div>
        {/* <!--::Layout--> */}
      </div>
      {/* <!--::Content container--> */}
    </div>
  )
}

export default AddExpense
