/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
  EngageWidget10,
  CardsWidgetOverview,
  ChartsWidget1,
  ChartsWidget3,
  ChartsWidget6,
  TablesWidget14,
  CardsWidget10,
  ListsWidget10,
  CardsWidget11,
  CardsWidget12,
  TablesWidget15,
  ListsWidget11,
  ChartsWidget9,
  ChartsWidget10,
  ChartsWidget11,
  CardsWidget13,
} from '../../../_metronic/partials/widgets'
import {} from '../../../_metronic/helpers/icons-config/icons'
import ProjectGanttChart from '../projects/Pages/ProjectGanttChart'
import ProjectReportSubmission from '../projects/components/ProjectReportSubmission'
import {getProjectStatus} from '../projects/core/_requests'
import {useSelector, useDispatch} from 'react-redux'
import {setProjectStatus as setProjectStatusStore} from '../../redux/projects/projectDashboardSlice'
import {RootState} from '../../redux/store'
import {useAuth} from '../../modules/auth'

const PMPage: FC<{
  projectStatus: {
    totalProjects: number
    inProgressCount: number
    overdueCount: number
    pendingCount: number
    completedCount: number
  } | null
}> = ({projectStatus}) => {
  const projectStatusLoading = useSelector(
    (state: RootState) => state.projects.projectDashboard.projectStatusLoading
  )
  return (
    <>
      {/* begin::Row */}
      <div className='fs-1 fw-bold mb-6 px-3'>Projects Dashboard</div>
      <div className='grid g-5 g-xl-10 sm:grid-cols-2 gap-5'>
        {/* begin::Col */}
        <div className=' col-span-1 w-full'>
          <CardsWidget10 />
        </div>
        <div className='col-span-1'>
          {/* <div className='grid sm:grid-cols-2 gap-2 h-[400px]'> */}
          <div className='row gx-10 h-[400px]'>
            {projectStatusLoading ? (
              <div className='d-flex align-items-center justify-center'>
                <div className='loader'></div>
              </div>
            ) : (
              <>
                <div className='row gx-10 pb-3'>
                  <div className='col-lg-6'>
                    <CardsWidget13
                      className='card-stretch'
                      title={'In Progress Projects'}
                      Param1={'Total'}
                      Param1Number={projectStatus?.totalProjects ?? 0}
                      Param2={'In Progress'}
                      Param2Number={projectStatus?.inProgressCount ?? 0}
                    />
                  </div>
                  <div className='col-lg-6'>
                    <CardsWidget13
                      className='card-stretch'
                      title={'Pending Projects'}
                      Param1={'Total'}
                      Param1Number={projectStatus?.totalProjects ?? 0}
                      Param2={'Pending'}
                      Param2Number={projectStatus?.pendingCount ?? 0}
                    />
                  </div>
                </div>
                <div className='row gx-10'>
                  <div className='col-lg-6'>
                    <CardsWidget13
                      className='card-stretch'
                      title={'Completed Projects'}
                      Param1={'Total'}
                      Param1Number={projectStatus?.totalProjects ?? 0}
                      Param2={'Completed'}
                      Param2Number={projectStatus?.completedCount ?? 0}
                    />
                  </div>
                  <div className='col-lg-6'>
                    <CardsWidget13
                      className='card-stretch'
                      title={'Overdue Projects'}
                      Param1={'Total'}
                      Param1Number={projectStatus?.totalProjects ?? 0}
                      Param2={'Overdue'}
                      Param2Number={projectStatus?.overdueCount ?? 0}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className=' py-8 px-3'>
        <span className='fs-1 fw-bold'>Overview</span>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gy-5 gx-xl-6'>
        <div className='col-xxl-5'>
          <TablesWidget15 className='card-xxl-stretch mb-xl-3' />
        </div>
        <div className='col-xxl-7'>
          <div className='card-xxl-stretch mb-5 mb-xxl-8'>
            <ProjectReportSubmission showAddButton={false} showCountColumn={false} />
          </div>
          {/* <TablesWidget14 className='card-xxl-stretch mb-5 mb-xxl-8' /> */}
        </div>
        {/* <div className='col-xl-4'> */}
        {/* <ListsWidget11 className='card-xxl-stretch  mb-xl-8' /> */}
        {/* </div> */}
        <div className='col-xl-3'>{/* <ListsWidget10 className='' /> */}</div>
      </div>

      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gy-5 g-xl-8'>
        <div className='col-xl-7'>
          <div className='card-xl-stretch mb-xl-8'>
            <ProjectGanttChart />
          </div>
        </div>
        <div className='col-xl-5'>
          <ChartsWidget10 className='card-xl-stretch mb-xl-8' />
        </div>
      </div>
      {/* end::Row */}

      <div className='row g-5 gx-xxl-8'>
        <div className='col-xxl-5'>
          {/* <ChartsWidget11 className='card-xxl-stretch mb-xl-3' /> */}
        </div>
        <div className='col-xxl-7'>
          {/* <TablesWidget14 className='card-xxl-stretch mb-5 mb-xxl-8' /> */}
        </div>
      </div>
    </>
  )
}

const PMWrapper: FC = () => {
  const dispatch = useDispatch()
  const {currentUser} = useAuth()
  const intl = useIntl()
  const projectStatusData = useSelector(
    (state: any) => state.projects.projectDashboard.projectStatus
  )
  const [projectStatus, setProjectStatus] = useState<{
    totalProjects: number
    inProgressCount: number
    overdueCount: number
    pendingCount: number
    completedCount: number
  } | null>(null)
  const projectStatusLoading = useSelector(
    (state: any) => state.projects.projectDashboard.projectStatusLoading
  )
  useEffect(() => {
    async function fetchProjectStatus() {
      try {
        const data = await getProjectStatus(currentUser?.organization)
        setProjectStatus(data)
        dispatch(setProjectStatusStore(data))
      } catch (error) {
        console.error('Error fetching project status:', error)
      }
    }
    if (projectStatusLoading && projectStatusData === null) fetchProjectStatus()
    else {
      setProjectStatus(projectStatusData)
    }
  }, [])
  console.log('projectstatusdata', projectStatus)
  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.HOME'})}</PageTitle> */}
      <PMPage projectStatus={projectStatus} />
    </>
  )
}

export {PMWrapper}
