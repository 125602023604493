import React, {useEffect, useState} from 'react'
import {useAuth} from '../../../modules/auth'
import {getAllDepartment} from '../../hrm/core/_requests'
import {Link} from 'react-router-dom'
import {Modal, Button, Form} from 'react-bootstrap'
import toast from 'react-hot-toast'
import {getHRPDepartments, postHRPDepartment} from '../core/_requests'
import {useSelector, useDispatch} from 'react-redux'
import {setDepartments} from '../../../redux/hrp/hrpDepartmentsSlice'
import {RootState} from '../../../redux/store'
import {setEmployeesDataFetch} from '../../../redux/hrp/hrpEmployeesSlice'

const HRPDepartment = () => {
  const {currentUser} = useAuth()
  const [permissions, setPermissions] = useState<any>({})
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedDepartments, setSelectedDepartments] = useState([])
  const [availableDepartments, setAvailableDepartments] = useState([])
  const [loading, setLoading] = useState<boolean>(false)
  const dispatch = useDispatch()
  const hrpDepartmentFetch = useSelector((state: RootState) => state.hrp.departments.fetch)
  const hrpDepartmentData = useSelector((state: RootState) => state.hrp.departments.departments)

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const fetchData = async () => {
    const res = await getAllDepartment(currentUser?.organization)
    setAvailableDepartments(res)
  }

  const fetchHRPDepartments = async () => {
    setLoading(true)
    const res = await getHRPDepartments(currentUser?.organization)
    dispatch(setDepartments(res))
    setSelectedDepartments(res.map((item) => item._id))
    setLoading(false)
  }

  useEffect(() => {
    if (currentUser?.organization) {
      if (hrpDepartmentFetch) fetchHRPDepartments()
      else {
        const temp = hrpDepartmentData.map((item) => item._id)
        setSelectedDepartments(temp)
      }
      fetchData()
    }
  }, [currentUser?.organization])

  const handleDepartmentSelect = (departmentId) => {
    setSelectedDepartments((prev) =>
      prev.includes(departmentId)
        ? prev.filter((id) => id !== departmentId)
        : [...prev, departmentId]
    )
  }

  const handleSubmit = async () => {
    try {
      if (selectedDepartments.length === 0) {
        toast.error('Please select at least one department')
        return
      }
      const data = {
        department: selectedDepartments,
        company: currentUser?.company,
        organization: currentUser?.organization,
      }
      await postHRPDepartment(data).then((res) => {
        toast.success('Departments added successfully')
        setIsModalOpen(false)
        dispatch(setEmployeesDataFetch())
        fetchHRPDepartments()
      })
    } catch (error) {
      console.error('Error submitting departments:', error)
    }
  }

  return (
    <div className='p-6 md:m-4 card card-xxl-stretch'>
      <div className='d-flex justify-between card-label fw-bold fs-3 mb-1s'>
        Departments
        {(permissions?.hrpdepartment?.update || currentUser?.profileType === 'superadmin') && (
          <Button variant='primary' onClick={() => setIsModalOpen(true)}>
            Add Department
          </Button>
        )}
      </div>

      <div className='card-body py-3'>
        {loading ? (
          <div className='d-flex align-items-center justify-center'>
            <div className='loader'></div>
          </div>
        ) : (
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-25px'>No.</th>
                  <th className='min-w-200px rounded-start'>Department</th>
                  <th className='min-w-125px'>No. of Employees</th>
                  <th className='min-w-150px'>No. of Roles</th>
                </tr>
              </thead>
              <tbody>
                {hrpDepartmentData &&
                  hrpDepartmentData.map((item, i) => (
                    <tr key={i} style={{marginBottom: '10px'}}>
                      <td className='ps-4'>{i + 1}</td>
                      <td>
                        <Link to={`/hrm/empDepartment/${item._id}`}>
                          <span className='text-dark fw-bold text-hover-primary fs-6 cursor-pointer'>
                            {item?.department?.name}
                          </span>
                        </Link>
                      </td>
                      <td>{item?.employeeCount}</td>
                      <td>
                        {Array.isArray(item?.department?.roles)
                          ? item?.department?.roles.length
                          : 1}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Select Departments</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div style={{maxHeight: '60vh', overflowY: 'auto'}}>
              {availableDepartments.map((department) => (
                <Form.Group key={department._id} className='mb-3'>
                  <Form.Check
                    type='checkbox'
                    id={`department-${department._id}`}
                    label={department?.name}
                    checked={selectedDepartments.includes(department._id)}
                    onChange={() => handleDepartmentSelect(department._id)}
                  />
                </Form.Group>
              ))}
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setIsModalOpen(false)}>
            Close
          </Button>
          <Button variant='primary' onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default HRPDepartment
