import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {getHRPProjectsByEmployee} from '../core/_requests'
import {useAuth} from '../../../modules/auth'

// Add interfaces
interface Project {
  _id: string
  projectName: string
  budgetAmount: number
  releaseDate?: string
  status: string
}

function HRPEmployeeProject() {
  const {id} = useParams()
  const {currentUser} = useAuth()
  const [projects, setProjects] = useState<Project[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  const fetchProjects = async () => {
    try {
      setIsLoading(true)
      setError(null)
      const response = await getHRPProjectsByEmployee(id, currentUser.organization)
      setProjects(response)
    } catch (err) {
      setError('Failed to fetch projects')
      console.error('Error fetching projects:', err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (id && currentUser?.organization) {
      fetchProjects()
    }
  }, [id, currentUser?.organization])

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })
    return formattedDate
  }

  if (error) return <div className='text-danger'>{error}</div>

  return (
    <div className='card card-flush'>
      <div className='card-body py-3'>
        {isLoading ? (
          <div className='d-flex align-items-center justify-center'>
            <div className='loader'></div>
          </div>
        ) : (
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4 table-row-bordered'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  {/* sr no */}
                  <th className='ps-4 min-w-50px rounded-start'>Sr No</th>
                  <th className='min-w-150px'>Project Name</th>
                  <th className='min-w-150px text-center'>Budget</th>
                  <th className='min-w-150px text-center'>Release Date</th>
                  <th className='min-w-150px text-center'>Status</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {projects.map((project, index) => (
                  <tr key={project._id}>
                    <td>
                      <div className='ps-4 text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {index + 1}
                      </div>
                    </td>
                    <td>
                      <div className='capitalize text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {project.projectName}
                      </div>
                    </td>
                    <td>
                      <div className='text-center text-dark fw-bold text-hover-primary d-block mb-1 fs-6 capitalize'>
                        {project.budgetAmount}
                      </div>
                    </td>
                    <td>
                      <div className='text-center text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {formatCreatedAt(project.releaseDate)}
                      </div>
                    </td>
                    <td>
                      <div className='text-center text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {project.status}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
              {/* end::Table body */}
            </table>
          </div>
        )}
      </div>
    </div>
  )
}

export default HRPEmployeeProject
