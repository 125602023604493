import Flatpickr from 'react-flatpickr'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {
  getBillsByVenodrId,
  getOrderById,
  getPaymentMadeLastId,
  getVendor,
  getVendorByAgentId,
  postPaymentMade,
} from '../Core/_requests'
import {useEffect, useState} from 'react'
import Select from 'react-select'
import {KTIcon} from '../../../../_metronic/helpers'
import {useNavigate, useParams} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'
import {getAccountsForExpensePaidThrough} from '../../account/core/_requests'
import {getNotes, getTerms} from '../../account_settings/core/_request'
import {useDispatch} from 'react-redux'
import {setPaymentMadeDataFetch} from '../../../redux/procurement/paymentMade/paymentMadeSlice'

export const AddPaymentMadeToOrder = () => {
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const params = useParams()
  const dispatch = useDispatch()
  const [contactPersonList, setContactPersonList] = useState([])
  const [contactPerson, setContactPerson] = useState('')
  const [loading, setLoading] = useState(false)
  const [permissions, setPermissions] = useState<any>({})
  const [payment, setPayment] = useState({
    vendorName: '',
    paymentId: '',
    paymentMade: '',
    totalBalance: '',
    paymentdate: new Date(),
    paymentMode: '',
    paidthrough: '',
    reference: '',
    notes: '',
    termsCondition: '',
  })
  const [currency, setCurrency] = useState(currentUser?.baseCurrency)
  const [billList, setBillList] = useState([])
  const [selectedBills, setSelectedBills] = useState([])
  const [billsData, setBillsData] = useState([])
  const [currencyist, setCurrencyList] = useState([
    {
      label: '',
      currency: '',
    },
  ])
  const [selectedVendor, setSelectedVendor] = useState(null)
  const [accountsNameList, setAccountsNameList] = useState({})
  const [paidThrough, setPaidThrough] = useState('')
  const [paymentMode, setPaymentMode] = useState('cash')
  const [isEditingNotes, setIsEditingNotes] = useState(false)
  const [isEditingTerms, setIsEditingTerms] = useState(false)
  const [termsTextareaHeight, setTermsTextareaHeight] = useState(' ')
  const [notesTextareaHeight, setNotesTextareaHeight] = useState(' ')

  //useState for Custom Payment no
  const [showModal, setShowModal] = useState(false)
  const [salesOrderNumber, setSalesOrderNumber] = useState('')
  const [selectYearAndMonth, setSelectYearAndMonth] = useState(new Date())
  const [selectedOption, setSelectedOption] = useState('autoGenerate') // Default selected option
  const [prefix, setPrefix] = useState('')
  const [nextNumber, setNextNumber] = useState('')
  const [customID, setCustomID] = useState('')

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const getOrderDetails = async () => {
    const response = await getOrderById(params.id)
    const res = response[0]
    console.log(res)
    setSelectedVendor({value: res?.vendor?._id, label: res?.vendor?.displayName})
    setContactPersonList(
      res?.vendor?.contactPersons?.map((person) => ({
        value: person?._id,
        label: person?.firstName,
      }))
    )
    setContactPerson(res?.vendor?.contactPersons?.[0]?._id)
    setCurrency(res?.vendor?.currency || 'SAR')
    setPayment((prev) => ({
      ...prev,
      vendor: res?.vendor?._id,
    }))
  }

  useEffect(() => {
    if (params.id !== undefined) getOrderDetails()
  }, [params.id])

  const handleBillChange = (selectedOption) => {
    const data = billsData.filter((bill) =>
      selectedOption.map((item) => item.value).includes(bill._id)
    )
    data.forEach((bill) => {
      bill.payment = 0
    })
    setSelectedBills(data)
  }

  useEffect(() => {
    const totalBalance = selectedBills.reduce((acc, bill) => {
      return acc + parseFloat(bill.total) - parseFloat(bill.payment)
    }, 0)
    const paymentMade = selectedBills.reduce((acc, bill) => {
      return acc + parseFloat(bill.payment)
    }, 0)
    setPayment((prevPayment) => ({
      ...prevPayment,
      totalBalance: totalBalance,
      paymentMade: paymentMade,
    }))
  }, [selectedBills])

  const fetchAccounts = async () => {
    const resp = await getAccountsForExpensePaidThrough(currentUser?.organization)
    const paidThrough = {
      Cash: [],
      Bank: [],
      'Other Current Liability': [],
      Equity: [],
      'Other Current Asset': [],
      'Fixed Asset': [],
    }
    resp.forEach((r) => {
      if (r.accountType === 'cash') {
        paidThrough['Cash'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'bank') {
        paidThrough['Bank'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'othercurrentliability') {
        paidThrough['Other Current Liability'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'equity') {
        paidThrough['Equity'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'othercurrentasset') {
        paidThrough['Other Current Asset'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'fixedasset') {
        paidThrough['Fixed Asset'].push({
          value: r?._id,
          label: r?.accountName,
        })
      }
    })
    setAccountsNameList(paidThrough)
    setPaidThrough(paidThrough['Cash'][0]?.value)
  }

  const handleDateChange = (selectedDate) => {
    setPayment((prevInvoice) => ({
      ...prevInvoice,
      date: selectedDate[0], // Flatpickr returns an array of selected dates
    }))
  }

  const getVendorList = async () => {
    // e.preventDefault()
    try {
      const query = null
      const data = await getVendor(query, currentUser?.organization)
      console.log('Vendor List:', data)
      setCurrencyList(
        data.map((r) => ({
          label: r._id,
          currency: r.currency,
        }))
      )
    } catch (error) {
      console.error('Error fetching vendor list:', error)
    }
  }

  const getVendorListForAgent = async () => {
    try {
      const res = await getVendorByAgentId('', currentUser?._id)
      console.log(res)
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  const getBills = async (vendorId) => {
    try {
      const data = await getBillsByVenodrId(vendorId)
      console.log('Bills data', data)
      setBillsData(data)
      const list = data.map((r) => ({
        value: r._id,
        label: r.id,
      }))
      setBillList(list)
    } catch (error) {
      console.error('Error fetching bills:', error)
    }
  }

  const handleChange = (e) => {
    const {name, value} = e.target
    if (name === 'notes' && value.trim() === '') {
      setPayment((prev) => ({
        ...prev,
        notes: '• ' + value,
      }))
    } else {
      setPayment({...payment, [name]: value})
    }
    if (name === 'termsCondition') {
      setTermsTextareaHeight(`${e.target.scrollHeight}px`)
    } else if (name === 'notes') {
      setNotesTextareaHeight(`${e.target.scrollHeight}px`)
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      setPayment((prev) => ({
        ...prev,
        notes: prev.notes + '\n• ',
      }))

      setNotesTextareaHeight(`${event.target.scrollHeight}px`)
    }
  }

  useEffect(() => {
    if (payment.termsCondition === '') {
      setTermsTextareaHeight('6rem')
    }
  }, [payment.termsCondition])

  useEffect(() => {
    if (payment.notes === '') {
      setNotesTextareaHeight('6rem')
    }
  }, [payment.notes])

  useEffect(() => {
    const fetchPayment = async () => {
      try {
        const PaymentNotes = await getNotes(currentUser?.organization, 'Payment Made')
        const PaymentTerms = await getTerms(currentUser?.organization, 'Payment Made')
        console.log('PaymentTerms', PaymentTerms)

        setPayment({
          ...payment,
          notes: PaymentNotes[0]?.notes || '',
          termsCondition: PaymentTerms?.terms || '',
        })
      } catch (error) {
        console.error('Error fetching delivery notes:', error)
      }
    }
    fetchPayment()
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (selectedVendor === null || selectedVendor === undefined) {
      alert('Please select a vendor')
      return
    }
    if (selectedBills.length === 0) {
      alert('Please select a bill')
      return
    }
    if (Number(payment.totalBalance) < 0) {
      alert('Payment made is greater than the total balance')
      return
    }
    try {
      const year = String(selectYearAndMonth.getFullYear() - 2000).padStart(2, '0')
      const month = String(selectYearAndMonth.getMonth() + 1).padStart(2, '0')
      const dNM = `${prefix}-${year}-${month}-`
      const formattedData = {
        vendor: selectedVendor.value,
        contactPerson: contactPerson,
        paymentDate: payment.paymentdate,
        // paymentMode: selectedPaymentMode.value,
        // paidThrough: selectedPaidThrough.value,
        paymentMode: paymentMode,
        paidThrough: paidThrough,
        billsData: selectedBills.map((bill) => ({
          date: bill.billDate,
          billNo: bill._id,
          purchaseOrder: bill.purchaseOrder?._id,
          billAmount: bill.total,
          dueAmount: parseFloat(bill.total) - parseFloat(bill.payment),
          payment: parseFloat(payment.paymentMade),
        })),
        notes: payment.notes,
        termsCondition: payment.termsCondition,
        amountPaid: parseFloat(payment.paymentMade),
        prefix: dNM,
        customID: customID,
        id: nextNumber,
        approval: permissions?.paymentmade?.enable ? 'accepted' : 'pending',
        company: currentUser?.company,
        organization: currentUser?.organization,
        agent: currentUser?._id,
      }

      setLoading(true)

      console.log('made', formattedData)
      // Send the formatted data using the postPaymentMade method
      const response = await postPaymentMade(formattedData, params.id)

      console.log('Response of Payment made:', response)
      if (response) {
        dispatch(setPaymentMadeDataFetch())
        navigate(`/procurement/paymentMadeList`)
      }
    } catch (error) {
      console.error('Error:', error)
      // Handle error
    }
  }

  const handleEditClick = (e, field) => {
    e.preventDefault()
    if (field === 'notes') {
      setIsEditingNotes(!isEditingNotes)
      setIsEditingTerms(false) // Make sure only one is in edit mode at a time
    } else if (field === 'termsCondition') {
      setIsEditingTerms(!isEditingTerms)
      setIsEditingNotes(false) // Make sure only one is in edit mode at a time
    }
  }

  const formatDate = (date) => {
    // retur date as 29/03/2024
    const d = new Date(date)
    return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
  }

  useEffect(() => {
    if (selectedVendor) {
      getBills(selectedVendor.value)
      setCurrency(
        currencyist.find((r: any) => r?.label === selectedVendor.value)?.currency || 'SAR'
      )
      setSelectedBills([])
    }
  }, [selectedVendor])

  useEffect(() => {
    if (permissions?.paymentmade?.admin === false) {
      getVendorListForAgent()
    } else if (
      permissions?.paymentmade?.admin === true ||
      currentUser?.profileType === 'superadmin'
    ) {
      getVendorList()
    }
  }, [permissions])

  useEffect(() => {
    getPaymentID()
    fetchAccounts()
  }, [])

  //functions for Custom Payment no
  const handleCloseModal = () => {
    setShowModal(false)
    // setCustomID('')
    // setPrefix('')
    // setNextNumber('')
    // getPaymentID()
  }

  const handleSaveChanges = () => {
    let newSalesOrderNumber = ''
    if (selectedOption === 'autoGenerate') {
      setPrefix(prefix)
      console.log(prefix, nextNumber)
      setNextNumber(nextNumber)
      setCustomID('')
      const year = String(selectYearAndMonth.getFullYear() - 2000).padStart(2, '0')
      const month = String(selectYearAndMonth.getMonth() + 1).padStart(2, '0')
      const dNM = `${year}-${month}`
      newSalesOrderNumber = `${prefix}-${dNM}-${nextNumber}`
    } else if (selectedOption === 'manualInput') {
      setCustomID(salesOrderNumber)
      setPrefix('')
      setNextNumber('')
      newSalesOrderNumber = salesOrderNumber
    }
    setSalesOrderNumber(newSalesOrderNumber)
    setShowModal(false)
  }

  const getPaymentID = async () => {
    try {
      const res = await getPaymentMadeLastId(currentUser?.organization)
      console.log('ye hai res', res)
      setNextNumber(res.lastId + 1)
      const lastPrefix = res?.prefix.slice(0, res?.prefix?.trim()?.length - 7)
      setPrefix(lastPrefix)
      setSalesOrderNumber(`${res?.prefix}${res?.lastId + 1}`)
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  useEffect(() => {
    if (customID) {
      setSalesOrderNumber(customID)
    }
  }, [customID])

  return (
    <div id='kt_app_content' className='app-content flex-column-fluid'>
      {/* begin::Content container */}
      <div id='kt_app_content_container' className='app-container container-xxl'>
        {/* begin::Layout */}
        <div className='d-flex flex-column'>
          {/* begin::Content */}
          <div className='flex-lg-row-fluid mb-10 mb-lg-0'>
            {/* begin::Card */}
            <div className='card'>
              {/* begin::Card body */}
              <div className='card-body p-12'>
                {/* begin::Form */}
                <form action='' id='kt_quote_form'>
                  {/* begin::Wrapper */}
                  <div className='d-flex flex-column align-items-start flex-xxl-row'>
                    {/* begin::Input group */}
                    <div
                      className='d-flex align-items-center flex-equal fw-row me-4 order-2'
                      data-bs-toggle='tooltip'
                      data-bs-trigger='hover'
                      title='Specify Payment date'
                    >
                      {/* begin::Date */}
                      <div className='fs-6 fw-bold text-gray-700 text-nowrap'>Date:</div>
                      {/* Date */}
                      {/* begin::Input */}
                      <div className='position-relative d-flex align-items-center w-150px'>
                        {/* begin::Datepicker */}
                        <Flatpickr
                          value={payment.paymentdate}
                          onChange={handleDateChange}
                          className='form-control form-control-solid'
                          placeholder='Pick date'
                          options={{
                            dateFormat: 'd-m-Y',
                          }}
                        />
                        {/* Datepicker */}
                        {/* begin::Icon */}
                        {/* <i className='ki-duotone ki-down fs-4 position-absolute ms-4 end-0'></i> */}
                        {/* Icon */}
                      </div>
                      {/* Input */}
                    </div>
                    {/* Input group */}
                    {/* begin::Input group */}
                    <div
                      className='d-flex flex-center flex-equal fw-row text-nowrap order-1 order-xxl-2 me-4'
                      data-bs-toggle='tooltip'
                      data-bs-trigger='hover'
                      title='Enter Payment number'
                    >
                      <span className='fs-2x fw-bold text-gray-800'>Payment #</span>
                      {/* <input
                        type='text'
                        className='form-control form-control-flush fw-bold text-muted fs-3 w-125px'
                        value={payment.paymentId}
                        placeholder='...'
                        disabled
                      /> */}

                      <input
                        type='text'
                        className='form-control form-control-flush fw-bold text-muted fs-3 w-200px'
                        value={salesOrderNumber}
                        placeholder='...'
                        readOnly
                      />

                      <button
                        className='btn btn-light'
                        onClick={(e) => {
                          e.preventDefault()
                          setShowModal(true)
                        }}
                      >
                        <KTIcon iconName='gear' />
                      </button>

                      <div>
                        {showModal && (
                          <div
                            className='modal'
                            tabIndex={-1}
                            role='dialog'
                            style={{display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)'}}
                          >
                            <div className='modal-dialog modal-dialog-centered' role='document'>
                              <div className='modal-content'>
                                <div className='modal-header'>
                                  <h5 className='modal-title'>Customize Payment No.</h5>
                                  <button
                                    type='button'
                                    className='btn-close'
                                    onClick={handleCloseModal}
                                  ></button>
                                </div>
                                <div className='modal-body'>
                                  {/* Your modal body content goes here */}
                                  <p>How would you like to Payment numbers?</p>
                                  <div className='form-check'>
                                    <input
                                      className='form-check-input'
                                      type='radio'
                                      name='salesOrderOption'
                                      id='autoGenerate'
                                      value='autoGenerate'
                                      checked={selectedOption === 'autoGenerate'}
                                      onChange={() => setSelectedOption('autoGenerate')}
                                    />
                                    <label className='form-check-label' htmlFor='autoGenerate'>
                                      Continue auto-generating Payment numbers
                                    </label>
                                  </div>
                                  <div className='form-check'>
                                    <input
                                      className='form-check-input'
                                      type='radio'
                                      name='salesOrderOption'
                                      id='manualInput'
                                      value='manualInput'
                                      checked={selectedOption === 'manualInput'}
                                      onChange={() => setSelectedOption('manualInput')}
                                    />
                                    <label className='form-check-label' htmlFor='manualInput'>
                                      Enter Payment numbers manually
                                    </label>
                                  </div>

                                  {/* Additional input fields for manual input */}
                                  {selectedOption === 'autoGenerate' && (
                                    <div className='mt-3'>
                                      <div className='mb-3'>
                                        <label className='form-label'>Prefix:</label>
                                        <input
                                          type='text'
                                          className='form-control'
                                          value={prefix}
                                          onChange={(e) => setPrefix(e.target.value)}
                                          placeholder='Enter Prefix'
                                        />
                                      </div>
                                      <div className='mb-3'>
                                        <label className='form-label'>Month & Year:</label>
                                        <br />
                                        <DatePicker
                                          selected={selectYearAndMonth}
                                          onChange={(date) => setSelectYearAndMonth(date)}
                                          dateFormat='yyyy/MM'
                                          showMonthYearPicker
                                          className='form-control'
                                        />
                                      </div>
                                      <div className='mb-3'>
                                        <label className='form-label'>Next Number:</label>
                                        <input
                                          type='number'
                                          className='form-control'
                                          value={nextNumber}
                                          onChange={(e) => setNextNumber(e.target.value)}
                                          placeholder='Enter Next Number'
                                        />
                                      </div>
                                    </div>
                                  )}
                                  {selectedOption === 'manualInput' && (
                                    <div className='mt-3'>
                                      <div className='mb-3'>
                                        <label className='form-label'>PAYMENT NO.:</label>
                                        <input
                                          type='text'
                                          className='form-control'
                                          value={salesOrderNumber}
                                          onChange={(e) => setSalesOrderNumber(e.target.value)}
                                          placeholder='Enter Payment Number'
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div className='modal-footer'>
                                  <button
                                    type='button'
                                    className='btn btn-secondary'
                                    onClick={handleCloseModal}
                                  >
                                    Close
                                  </button>
                                  <button
                                    type='button'
                                    className='btn btn-primary'
                                    onClick={handleSaveChanges}
                                  >
                                    Save changes
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {/* End of modal content */}
                      </div>
                    </div>
                    {/* Input group */}
                    {/* begin::Input group */}
                    <div
                      className='d-flex align-items-center justify-content-end flex-equal order-3 fw-row'
                      data-bs-toggle='tooltip'
                      data-bs-trigger='hover'
                      title='Specify Payment due date'
                    ></div>
                    {/* Input group */}
                  </div>
                  {/* Top */}
                  {/* begin::Separator */}
                  <div className='separator separator-dashed my-10'></div>
                  {/* Separator */}
                  {/* begin::Wrapper */}
                  <div className='mb-0'>
                    {/* begin::Row */}
                    <div className='row gx-10 mb-5'>
                      {/* begin::Col */}

                      {/* Col */}
                      {/* begin::Col */}
                      <div className='col-lg-6'>
                        <label className='form-label required fs-6 fw-bold text-gray-700 mb-3'>
                          Vendor Name
                        </label>
                        {/* begin::Input group */}
                        <input
                          type='text'
                          className='form-control form-control-solid'
                          placeholder='Enter Vendor Name'
                          value={selectedVendor?.label}
                          readOnly
                        />
                      </div>
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                          Contact Person
                        </label>
                        <Select
                          name='contactPerson'
                          aria-label='Select a Contact Person'
                          placeholder='Select a Contact Person'
                          options={contactPersonList}
                          isSearchable={true}
                          className='form-control form-control-solid'
                          onChange={(selectedOption) => setContactPerson(selectedOption.value)}
                          value={contactPersonList.find((person) => person.value === contactPerson)}
                        />
                      </div>
                      {/* Col */}
                    </div>

                    <div className='row gx-10 mb-5'>
                      {/* begin::Col */}

                      {/* Col */}
                      {/* begin::Col */}
                      <div className=''>
                        <div className='w-50'>
                          <label className='form-label required fs-6 fw-bold text-gray-700 mb-3'>
                            Select Bill(s)
                          </label>
                          {/* begin::Input group */}
                          <Select
                            name='vendor'
                            aria-label='Select a Bill'
                            placeholder='Select a Bill'
                            isMulti
                            options={billList}
                            isSearchable={true}
                            className='form-control form-control-solid'
                            onChange={handleBillChange}
                            value={selectedBills.map((bill) => ({
                              value: bill._id,
                              label: bill.id,
                            }))}
                          />
                        </div>
                      </div>
                      {/* Col */}
                    </div>

                    {/* begin::Table wrapper */}
                    <div className='fs-6 fw-bold text-gray-700 mb-3'>Bill Table</div>
                    <div className='table-responsive mb-10'>
                      {/* begin::Table */}
                      <table
                        className='table g-5 gs-0 mb-0 fw-bold text-gray-700'
                        data-kt-element='items'
                      >
                        {/* begin::Table head */}
                        <thead>
                          <tr className='border-bottom fs-7 fw-bold text-gray-700 text-uppercase'>
                            <th className='min-w-70px w-250px'>DATE</th>
                            <th className='min-w-150px w-250px'>BILL#</th>
                            <th className='min-w-50px w-250px'>PO#</th>
                            <th className='min-w-70px w-150px'>BILL AMOUNT</th>
                            <th className='min-w-70px w-250px'>AMOUNT DUE</th>
                            <th className='min-w-70px w-200px'>PAYMENT</th>
                            <th className='min-w-70px w-200px'></th>
                          </tr>
                        </thead>
                        {/* Table head */}
                        {/* begin::Table body */}
                        <tbody>
                          {selectedBills
                            // ?.filter((bill) => bill.status === 'pending')
                            ?.map((bill, index) => (
                              <tr key={bill._id}>
                                <td>{formatDate(bill?.billDate)}</td>
                                <td>{bill?.id}</td>
                                <td>{bill?.orderNo?.purchaseOrder?.id}</td>
                                <td>{bill?.total}</td>
                                <td>
                                  {(parseFloat(bill?.total) - parseFloat(bill?.payment)).toFixed(2)}
                                </td>{' '}
                                {/* Calculate and display Amount Due */}
                                <td>
                                  <input
                                    type='number'
                                    className='form-control form-control-solid'
                                    placeholder='Enter Payment'
                                    min={0}
                                    max={bill.total}
                                    value={bill.payment}
                                    onChange={(e) => {
                                      const value = e.target.value
                                      const data = selectedBills.map((item) => {
                                        if (item._id === bill._id) {
                                          return {
                                            ...item,
                                            payment: value,
                                          }
                                        }
                                        return item
                                      })
                                      setSelectedBills(data)
                                    }}
                                  />
                                </td>
                                {/* pay in full button */}
                                <td>
                                  <button
                                    className='btn btn-sm btn-light-primary'
                                    type='button'
                                    onClick={() => {
                                      const data = selectedBills.map((item) => {
                                        if (item._id === bill._id) {
                                          return {
                                            ...item,
                                            payment: parseFloat(bill.total),
                                          }
                                        }
                                        return item
                                      })
                                      setSelectedBills(data)
                                    }}
                                  >
                                    Pay in Full
                                  </button>
                                </td>
                                {/* Input field for Payment */}
                              </tr>
                            ))}
                        </tbody>

                        {/* Table body */}
                        {/* begin::Table foot */}
                        <tfoot></tfoot>
                        {/* Table foot */}
                      </table>
                    </div>

                    {/* Row */}
                    <div className=''>
                      <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                        Payment Made
                      </label>
                      <div className='relative d-flex align-items-center mb-3'>
                        <div className='absolute bg-secondary fs-3 py-3 px-4 rounded-s-xl'>
                          {currency}
                        </div>
                        <input
                          type='number'
                          className='form-control form-control-solid ps-20'
                          name='amountReceived'
                          placeholder='Amount Received'
                          value={payment.paymentMade}
                          min='0'
                          // max={balance}
                          // onChange={handleChange}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className=''>
                      <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                        Balance Left
                      </label>
                      <div className='relative d-flex align-items-center mb-3'>
                        <input
                          type='number'
                          className='form-control form-control-solid'
                          name='balanceLeft'
                          placeholder='Balance Left'
                          value={payment.totalBalance}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className='row gx-10 mb-5'>
                      {/* begin::Col */}
                      <div className='col-lg-6'>
                        <label className='form-label required fs-6 fw-bold text-gray-700 mb-3'>
                          Payment Mode
                        </label>
                        {/* <Select
                          name='paymentMode'
                          aria-label='Select a Payment Mode'
                          placeholder='Select a Payment Mode'
                          options={paymentModeList}
                          isSearchable={true}
                          className='form-control form-control-solid'
                          onChange={handlePaymentModeChange}
                          value={selectedPaymentMode}
                        /> */}
                        <select
                          className='form-select form-select-solid'
                          value={paymentMode}
                          name='paymentMode'
                          onChange={(e) => setPaymentMode(e.target.value)}
                        >
                          <option value='bankremittance'>Bank Remittance</option>
                          <option value='banktransfer'>Bank Transfer</option>
                          <option value='cash'>Cash</option>
                          <option value='cheque'>Cheque</option>
                          <option value='creditcard'>Credit Card</option>
                        </select>
                      </div>

                      <div className='col-lg-6'>
                        <label className='form-label required fs-6 fw-bold text-gray-700 mb-3'>
                          Paid Through
                        </label>
                        {/* begin::Input group */}
                        {/* <Select
                          name='paidThrough'
                          aria-label='Select a Paid Through Option'
                          placeholder='Select a Paid Through Option'
                          options={paidThroughList}
                          isSearchable={true}
                          className='form-control form-control-solid'
                          onChange={handlePaidThroughChange}
                          value={selectedPaidThrough}
                        /> */}
                        <select
                          className='form-select form-select-solid'
                          value={paidThrough}
                          name='paidThrough'
                          onChange={(e) => setPaidThrough(e.target.value)}
                        >
                          {Object.keys(accountsNameList).map((key, index) => {
                            return (
                              <optgroup label={key} key={index}>
                                {accountsNameList[key].map((item) => {
                                  return (
                                    <option value={item?.value} key={item?.value}>
                                      {item?.label}
                                    </option>
                                  )
                                })}
                              </optgroup>
                            )
                          })}
                        </select>
                      </div>
                    </div>

                    <div className='mb-0 position-relative '>
                      <label className='form-label fs-6 fw-bold text-gray-700'>Notes</label>
                      <textarea
                        name='notes'
                        className={` form-control form-control-solid mb-0 h-10 ${
                          isEditingNotes ? 'border border-dark-subtle' : ''
                        }`}
                        placeholder='Enter Notes'
                        value={payment.notes}
                        onChange={handleChange}
                        readOnly={!isEditingNotes}
                        onKeyDown={handleKeyPress}
                        style={{
                          minHeight: '6rem',
                          height: notesTextareaHeight,
                          resize: 'none',
                        }}
                      />
                      <button onClick={(e) => handleEditClick(e, 'notes')}>
                        <KTIcon
                          iconName='pencil'
                          className='position-absolute text-black bg-gray-100 right-8 top-[3rem] fs-3'
                        />
                      </button>
                    </div>

                    <div className='mb-10 position-relative'>
                      <label className='form-label fs-6 fw-bold text-gray-700'>
                        Terms & Conditions
                      </label>
                      <textarea
                        name='termsCondition'
                        style={{
                          minHeight: '6rem',
                          height: termsTextareaHeight,
                          resize: 'none',
                        }}
                        className={`  form-control form-control-solid mb-0 h-10 ${
                          isEditingTerms ? 'border border-dark-subtle' : ''
                        }`}
                        placeholder='Enter terms and conditions'
                        value={payment.termsCondition}
                        onChange={handleChange}
                        readOnly={!isEditingTerms}
                      />
                      <button onClick={(e) => handleEditClick(e, 'termsCondition')}>
                        <KTIcon
                          iconName='pencil'
                          className='absolute text-black bg-gray-100 right-8 top-[3rem] fs-3'
                        />
                      </button>
                    </div>
                    <button
                      className='btn btn-primary w-30'
                      id='kt_quote_submit_button'
                      disabled={loading}
                      onClick={handleSubmit}
                    >
                      <i className='ki-duotone ki-triangle fs-3'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>
                      SAVE AND SUBMIT
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddPaymentMadeToOrder
