import React, {useEffect, useState} from 'react'
import {getEmployeeLeaveDetailsById} from '../../core/_requests'
import {useParams} from 'react-router-dom'

export default function EmployeeLeaveTab({maxLeaves = 0}) {
  const [data, setData] = useState('')
  const [leavesTaken, setLeavesTaken] = useState(0)
  const [loading, setLoading] = useState(true)
  const id = useParams().id

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getEmployeeLeaveDetailsById(id)
        console.log('response', response)

        let totalLeaves = 0

        const temp = response.map((leave) => {
          const leaveDays = Math.ceil(
            (new Date(leave.endDate).getTime() - new Date(leave.startDate).getTime()) /
              (1000 * 60 * 60 * 24)
          )
          totalLeaves += leaveDays
          return {
            ...leave,
            leaveDays,
          }
        })

        setData(temp)
        setLeavesTaken(totalLeaves)

        setLoading(false)
      } catch {
        console.log('error')
      }
    }
    fetchData()
  }, [])

  const formatCreatedAt = (createdAt) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {})
    return formattedDate
  }

  return (
    <div className='card card-flush'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          {data[0]?.employeeId?.firstName && data[0]?.employeeId?.firstName !== '' ? (
            <span className='card-label fw-bold fs-3 mb-1'>
              Leaves of{' '}
              <span className='capitalize'>
                {data[0]?.employeeId?.firstName} {data[0]?.employeeId?.lastName}
              </span>
            </span>
          ) : (
            <span className='card-label fw-bold fs-3 mb-1'>Leaves</span>
          )}
        </h3>
        <div className='card-toolbar'>
          <span className='badge badge-secondary fs-2 p-3'>
            {maxLeaves - leavesTaken}/{maxLeaves} Leaves left
          </span>
        </div>
      </div>
      <div className='card-body py-3'>
        {loading ? (
          <div className='d-flex align-items-center justify-center'>
            <div className='loader'></div>
          </div>
        ) : (
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4 table-row-bordered'>
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-50px rounded-start'>No</th>
                  <th className='min-w-100px'>Leave Type</th>
                  <th className='min-w-100px'>Start Date</th>
                  <th className='min-w-100px'>End Date</th>
                  <th className='min-w-100px'>Reason</th>
                  <th className='min-w-100px'>No. of days</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(data) &&
                  data.map((employee, index) => (
                    <tr key={index}>
                      <td className='ps-5'>{index + 1}</td>
                      <td>{employee?.leaveType?.leaveType}</td>
                      <td>{formatCreatedAt(employee?.startDate)}</td>
                      <td>{formatCreatedAt(employee?.endDate)}</td>
                      <td>{employee?.reason}</td>
                      {/* calculate dynamically */}
                      <td>{employee?.leaveDays}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  )
}
