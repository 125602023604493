import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  projects: [],
  isLoading: false,
  fetch: true,
}

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setHRPProjects: (state, action) => {
      state.projects = action.payload
      state.isLoading = false
      state.fetch = false
    },
    setHRPProjectsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setHRPProjectsDataFetch: (state) => {
      state.fetch = true
    },
  },
})

export const {setHRPProjects, setHRPProjectsLoading, setHRPProjectsDataFetch} =
  projectsSlice.actions
export default projectsSlice.reducer
