import React, {useEffect, useState} from 'react'
import {useAuth} from '../../../modules/auth'
import Swal from 'sweetalert2'
import {getUnitsByCompany, updateUnitsByCompany, addUnit, removeUnit} from '../Core/_requests'
import {Modal, Button} from 'react-bootstrap'
import {KTIcon} from '../../../../_metronic/helpers'
import {unitsList} from '../../../utils/units'

interface Unit {
  name: string
  activated: boolean
  fixed: boolean
}

const UnitSetting = () => {
  const {currentUser} = useAuth()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<Unit[]>([])
  const [showModal, setShowModal] = useState(false)
  const [newUnitName, setNewUnitName] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      const res = await getUnitsByCompany(currentUser?.company)

      // set fixed true for the res.name found in unitsList
      const fixedUnits = res.map((unit) => ({
        ...unit,
        fixed: unitsList.includes(unit.name),
      }))
      setData(fixedUnits)
    }
    fetchData()
  }, [currentUser])

  const updateFeature = async (index, value) => {
    setLoading(true)
    const reqData = {
      index: index,
      activated: value,
    }
    const res = await updateUnitsByCompany(currentUser?.company, reqData)
    if (res) {
      Swal.fire({
        title: 'Success',
        text: 'Unit updated successfully',
        icon: 'success',
        confirmButtonText: 'Ok',
        timer: 1500,
      })
      setLoading(false)
    }
  }

  const handleAddUnit = async () => {
    const res = await addUnit(currentUser?.company, {unit: {name: newUnitName, activated: true}})
    if (res) {
      Swal.fire({
        title: 'Success',
        text: 'Unit added successfully',
        icon: 'success',
        confirmButtonText: 'Ok',
        timer: 1500,
      })
      setShowModal(false)
      setData([...data, {name: newUnitName, activated: false, fixed: false}])
      setNewUnitName('')
    }
  }

  const handleRemoveUnit = async (index) => {
    const unitToRemove = data[index]

    // Show confirmation dialog
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: `You are about to remove the unit: ${unitToRemove.name}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    })

    if (result.isConfirmed) {
      const res = await removeUnit(currentUser?.company, {name: unitToRemove.name})
      if (res) {
        Swal.fire({
          title: 'Success',
          text: 'Unit removed successfully',
          icon: 'success',
          confirmButtonText: 'Ok',
          timer: 1500,
        })
        setData(data.filter((_, i) => i !== index)) // Remove the unit from the state
      }
    }
  }

  return (
    <div className='card'>
      <div className='card-header d-flex justify-content-between align-items-center'>
        <h4 className='card-title fs-2 fw-bold'>Unit Settings</h4>
        <Button variant='primary' onClick={() => setShowModal(true)}>
          Add Unit
        </Button>
      </div>
      <div className='card-body'>
        <div className='row'>
          <div className='col-12 d-flex flex-column gap-2'>
            {data.map((unit, index) => (
              <div
                className='form-group d-flex align-items-center justify-content-between'
                key={index}
              >
                <div className='form-check'>
                  <input
                    type='checkbox'
                    className='form-check-input'
                    id={unit.name}
                    checked={unit.activated}
                    disabled={loading}
                    onChange={() => {
                      updateFeature(index, !unit.activated)
                      const newData = [...data]
                      newData[index].activated = !unit.activated
                      setData(newData)
                    }}
                  />
                  <label
                    className='form-check-label fs-3 ms-3 me-5 fw-semibold'
                    htmlFor={unit.name}
                  >
                    {unit.name}
                  </label>
                  {!unit.fixed && (
                    <Button
                      variant='light-danger'
                      size='sm'
                      onClick={() => handleRemoveUnit(index)}
                    >
                      <KTIcon iconName='trash' className='fs-1' />
                    </Button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Unit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type='text'
            value={newUnitName}
            onChange={(e) => setNewUnitName(e.target.value)}
            placeholder='Enter unit name'
            className='form-control'
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant='primary' onClick={handleAddUnit}>
            Add Unit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default UnitSetting
