import React, {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'
import {getHRPLeavesByMonth} from '../core/_requests'

const HRPLeaveDetailByMonth = () => {
  const {currentUser} = useAuth()
  const {month} = useParams()
  const [employeeList, setEmployeeList] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {})
    return formattedDate
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)
        const response = await getHRPLeavesByMonth(month, currentUser?.organization)
        setEmployeeList(response)
      } catch {
        console.log('error')
      } finally {
        setIsLoading(false)
      }
    }

    if (currentUser?.organization !== undefined && month) {
      fetchData()
    }
  }, [currentUser?.organization, month])

  return (
    <div className='card card-flush'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>
            Employee Leaves of <span className='capitalize'>{month}</span>
          </span>
        </h3>
      </div>
      <div className='card-body py-3'>
        {isLoading ? (
          <div className='d-flex align-items-center justify-center'>
            <div className='loader'></div>
          </div>
        ) : (
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4 table-row-bordered'>
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-50px rounded-start'>No</th>
                  <th className='min-w-150px'>Employee</th>
                  <th className='min-w-150px'>Department</th>
                  <th className='min-w-100px'>Leave Type</th>
                  <th className='min-w-100px'>Start Date</th>
                  <th className='min-w-100px'>End Date</th>
                  <th className='min-w-100px'>Reason</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(employeeList) &&
                  employeeList.map((employee: any, index) => (
                    <tr key={employee?._id?.toString()}>
                      <td>
                        <div className='ps-4 text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          {index + 1}
                        </div>
                      </td>
                      <td>
                        <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          {employee?.employeeId?.firstName + ' ' + employee?.employeeId?.lastName}
                        </div>
                      </td>
                      <td>
                        <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          {employee?.departmentId?.name}
                        </div>
                      </td>
                      <td>
                        <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          {employee?.leaveType?.leaveType}
                        </div>
                      </td>
                      <td>
                        <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          {formatCreatedAt(employee?.startDate)}
                        </div>
                      </td>
                      <td>
                        <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          {formatCreatedAt(employee?.endDate)}
                        </div>
                      </td>
                      <td>
                        <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          {employee?.reason}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  )
}

export default HRPLeaveDetailByMonth
