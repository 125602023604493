import React, {ChangeEvent, useEffect, useState} from 'react'
import {useAuth} from '../../../modules/auth'
import {getTasksForAdmin, getTasks, setStatus} from '../core/_requests'
import {KTIcon, KTSVG} from '../../../../_metronic/helpers'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Select from 'react-select'
import AgentList from '../../calender/AgentList'
import {addevents} from '../../calender/core/_requests'
import {getUsersByOrgId} from '../../sales/Core/_requests'
import {useSelector, useDispatch} from 'react-redux'
import {setTaskData} from '../../../redux/task/taskSlice'
import {RootState} from '../../../redux/store'

const TasksList = () => {
  const {currentUser} = useAuth()
  // const taskList = useSelector((state: any) => state.tools.task.tasks)
  const loading = useSelector((state: RootState) => state.task.task.loading)
  const PF: any = 'https://qf-assets.s3.me-central-1.amazonaws.com/'
  const Leadlist = useSelector((state: any) => state.task.task.taskData)
  const [leadlist, setLeadlist] = useState<any>([])
  const [assiopen, setassiopen] = useState<boolean>(false)
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [agentids, setagentids] = useState<string[]>([])
  const [agentsList, setAgentsList] = useState<any>([])
  const [selectedAgent, setselectedAgent] = useState<any>(null)
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    type: 'todo',
    priority: '',
    color: '',
    company: currentUser?.company,
    reminder: '5',
  })
  const dispatch = useDispatch()

  useEffect(() => {
    if (formData.priority === 'Important') {
      setFormData((prev) => {
        return {...prev, color: '#dc3545'}
      })
    } else if (formData.priority === 'Medium') {
      setFormData((prev) => {
        return {...prev, color: '#ffc107'}
      })
    } else {
      setFormData((prev) => {
        return {...prev, color: '#28a745'}
      })
    }
  }, [formData.priority])

  const [permissions, setPermissions] = useState<any>({})

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const handleAgentChange = async (selectedOption) => {
    setselectedAgent(selectedOption)
    if (selectedOption === null) {
      const res = await getTasksForAdmin(currentUser?.organization)
      setLeadlist(res)
      return
    }
    const res = await getTasks(selectedOption.value)
    setLeadlist(res)
  }

  const getdealslist = async () => {
    const res = await getTasks(currentUser?._id)
    setLeadlist(res)
    dispatch(setTaskData(res))
  }

  useEffect(() => {
    const getAgenttList = async () => {
      try {
        const res = await getUsersByOrgId(currentUser?.organization)
        setAgentsList(
          res.map((r) => ({
            value: r._id,
            label: r.fullName, // Corrected the typo here from "labe" to "label"
          }))
        )

        console.log(res)
      } catch (error) {
        console.error('Error fetching client list:', error)
      }
    }

    if (Leadlist.length === 0) {
      getdealslist()
    } else {
      setLeadlist(Leadlist)
    }
    getAgenttList()
  }, [])

  // const handleApproved = async (id, index) => {
  //   const res = await setStatusApprove(id)
  //   if (res) {
  //     getdealslist()
  //     //   setLeadlist((prev: any) => {
  //     //     prev[index].status = 'done'
  //     //     return prev
  //     //   })
  //   }
  // }

  // const handleReject = async (id, index) => {
  //   const res = await setStatusReject(id)
  //   if (res) {
  //     getdealslist()
  //     //   setLeadlist((prev: any) => {
  //     //     prev[index].status = 'cancelled'
  //     //     return prev
  //     //   })
  //   }
  // }

  const handleStatusChange = async (id, status) => {
    const res = await setStatus(id, status)
    if (res) {
      getdealslist()
    }
  }

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    // return dd/mm/yyyy
    const formattedDate = new Date(createdAt)
    return `${formattedDate.getDate()}/${
      formattedDate.getMonth() + 1
    }/${formattedDate.getFullYear()}`
  }

  const formatCreatedAttime = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'

    const formattedTime = new Date(createdAt).toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    })

    return formattedTime
  }

  const handleDateChange = (date) => {
    setSelectedDate(date)
  }

  const handleDatePickerClick = (e) => {
    // Prevent the click event from propagating to parent elements
    e.stopPropagation()
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    e.preventDefault()
    const {name, value} = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleSubmit = async () => {
    const data = {
      ...formData,
      start: selectedDate,
      end: selectedDate,
      agentids,
      assignee: currentUser._id,
      organization: currentUser.organization,
    }
    const res = await addevents(data, currentUser?._id)
    if (res) {
      setFormData({
        title: '',
        description: '',
        type: 'meeting',
        priority: '',
        color: '',
        reminder: '5',
        company: currentUser?.company,
      })
      setagentids([])
      setSelectedDate(new Date())
      getdealslist()
      const closeModalButton2 = document.getElementById('closeModalButton2')
      closeModalButton2?.click()
    }
  }

  if (loading) {
    return (
      <div className='d-flex align-items-center justify-center'>
        <div className='loader'></div>
      </div>
    )
  }

  return (
    <div>
      {(currentUser?.profileType === 'superadmin' || permissions?.tasks?.admin) && (
        <div className='d-flex justify-end m-4'>
          <div
            className='btn btn-primary me-3'
            data-kt-menu-trigger='hover'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTIcon iconName='filter' className='fs-2' />
            Filter
            <div
              className='menu menu-sub menu-sub-dropdown w-400px w-md-400px p-5'
              data-kt-menu='true'
              style={{zIndex: 1000000}}
            >
              {/* begin::Header */}
              <div className='px-7 py-5'>
                <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
              </div>
              {/* end::Header */}

              {/* begin::Separator */}
              <div className='separator border-gray-200'></div>
              {/* end::Separator */}

              {/* begin::Content */}
              <div className='px-7 py-5' data-kt-user-table-filter='form'>
                {/* begin::Input group */}
                <div className='mb-10 d-flex flex-row align-content-center justify-content-center'>
                  <label className='form-label fs-6 fw-bold text-gray-700 mb-3 me-5 mt-5'>
                    User:
                  </label>

                  <Select
                    name='agent'
                    aria-label='Select a User'
                    placeholder='Select user'
                    options={agentsList}
                    defaultValue={{value: currentUser?._id, label: currentUser?.fullName}}
                    isSearchable={true}
                    className='form-control form-control-solid'
                    onChange={handleAgentChange}
                    value={selectedAgent}
                  />
                </div>

                {/* end::Input group */}

                {/* begin::Actions */}
                <div className='d-flex justify-content-end'>
                  <button
                    type='button'
                    // disabled={isLoading}
                    // onClick={filterData}
                    className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                    data-kt-menu-dismiss='true'
                    data-kt-user-table-filter='reset'
                    onClick={() => {
                      setselectedAgent(null)
                      // getevent()
                      // getdealslist()
                      setLeadlist(Leadlist)
                      // dispatch(setTaskData(taskList))
                    }}
                  >
                    MY EVENTS
                  </button>
                  <button
                    type='button'
                    // disabled={isLoading}
                    // onClick={filterData}
                    className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                    data-kt-menu-dismiss='true'
                    data-kt-user-table-filter='reset'
                    onClick={() => {
                      setselectedAgent(null)
                      handleAgentChange(null)
                      // handleAgentChange(null)
                    }}
                  >
                    ALL
                  </button>
                </div>
                {/* end::Actions */}
              </div>
              {/* end::Content */}
            </div>
          </div>
        </div>
      )}
      <div className={`card h-md-50 mb-5 mb-xl-10`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Tasks</span>
          </h3>

          <div className='card-toolbar' data-bs-toggle='modal' data-bs-target='#kt_modal_2'>
            <a href='#' className='btn btn-sm btn-light-primary'>
              <KTIcon iconName='plus' className='fs-2' />
              Add New Task
            </a>
          </div>
        </div>

        <div className='modal fade' tabIndex={-1} id='kt_modal_2'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>Add Task</h5>
                <div
                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr061.svg'
                    className='svg-icon svg-icon-2x'
                  />
                </div>
              </div>
              {!assiopen ? (
                <div className='modal-body'>
                  <div className='mb-10'>
                    <label className='form-label'>Title</label>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Enter title'
                      name='title'
                      value={formData.title}
                      onChange={handleChange}
                    />
                  </div>

                  <div className='mb-10 d-flex flex-column'>
                    <label className='form-label'>Date</label>
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      dateFormat='MMMM d, yyyy'
                      className='form-control'
                      onClick={handleDatePickerClick}
                    />
                  </div>

                  <div className='mb-10 d-flex flex-column'>
                    <label className='form-label'>Time</label>
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      dateFormat='h:mm aa'
                      className='form-control'
                      onClick={handleDatePickerClick}
                    />
                  </div>

                  <div className='mb-10 d-flex flex-column'>
                    <label className='form-label'>Description</label>
                    <input
                      type='text'
                      className='form-control'
                      name='description'
                      placeholder='Enter Description (Optional)'
                      value={formData.description}
                      onChange={handleChange}
                    />
                  </div>

                  <div className='mb-10 d-flex flex-column'>
                    <label className='form-label'>Reminder</label>
                    <select
                      className='form-select'
                      value={formData.reminder}
                      name='reminder'
                      onChange={handleChange}
                    >
                      <option value='5'>5 minutes before</option>
                      <option value='15'>15 minutes before</option>
                      <option value='30'>30 minutes before</option>
                      <option value='60'>1 hour before</option>
                      <option value='10'>1 day before</option>
                    </select>
                  </div>

                  <div className='container mt-3'>
                    <label htmlFor='exampleSelect' className='form-label'>
                      Select Priority
                    </label>
                    <select
                      className='form-select'
                      id='exampleSelect'
                      value={formData.priority}
                      name='priority'
                      onChange={handleChange}
                    >
                      <option value=''>Select Priority</option>
                      <option value='Important'>Important</option>
                      <option value='Medium'>Medium</option>
                      <option value='Flexible'>Flexible</option>
                      {/* Add more options as needed */}
                    </select>
                  </div>

                  <button
                    type='button'
                    className='btn btn-sm btn-light-primary mt-5'
                    onClick={() => setassiopen(true)}
                  >
                    <KTIcon iconName='plus' className='fs-2' />
                    Assign User
                  </button>
                </div>
              ) : (
                <div className='modal-body'>
                  <button
                    type='button'
                    className='btn btn-sm btn-light-primary mb-5'
                    onClick={() => setassiopen(false)}
                  >
                    <KTIcon iconName='arrow-left' className='fs-2' />
                  </button>
                  <AgentList agentids={agentids} setagentids={setagentids} />
                </div>
              )}

              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-light'
                  data-bs-dismiss='modal'
                  id='closeModalButton2'
                >
                  Close
                </button>
                <button type='button' className='btn btn-primary' onClick={handleSubmit}>
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-4 table-row-bordered'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-50px rounded-start'>ID</th>
                  <td className='min-w-200px'>Title</td>
                  {(currentUser?.profileType === 'superadmin' || permissions?.meetings?.admin) && (
                    <th className='min-w-200px'>User</th>
                  )}
                  <th className='min-w-200px'>Date</th>
                  <th className='min-w-200px'>Time</th>
                  <th className='min-w-200px'>Priority</th>
                  <th className='min-w-100px'>Status</th>
                  {/* <th className='min-w-200px rounded-end'>Action</th> */}
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {leadlist?.map((lead: any, index) => (
                  <tr key={lead?._id?.toString()}>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary d-block ps-4 mb-1 fs-6'
                      >
                        {index + 1}
                      </a>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 capitalize'
                      >
                        {lead?.title}
                      </a>
                    </td>
                    {(currentUser?.profileType === 'superadmin' || permissions?.meetings?.admin) &&
                      (lead?.agent.length > 1 ? (
                        <td>
                          <div
                            className='symbol-group symbol-hover'
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'
                            data-kt-menu-flip='top-end'
                          >
                            {lead.agent.map((item, index) => (
                              <div className='symbol symbol-circle symbol-30px '>
                                {item.profilePic !== '' ? (
                                  <img
                                    src={PF + item.profilePic}
                                    alt=''
                                    className='object-fit-cover'
                                  />
                                ) : (
                                  <div className='symbol-label fs-6 fw-semibold bg-danger text-inverse-danger'>
                                    {item.fullName.charAt(0).toUpperCase()}
                                  </div>
                                )}
                              </div>
                            ))}

                            <div
                              className='menu menu-sub menu-sub-dropdown w-200px w-md-200px p-5'
                              data-kt-menu='true'
                              style={{zIndex: 1000000}}
                            >
                              {lead.agent.map((item, index) => (
                                <div className='p-3'>
                                  <div className='symbol symbol-30px me-2 symbol-square'>
                                    {item?.profilePic ? (
                                      <img
                                        src={PF + item.profilePic}
                                        className='object-fit-cover'
                                        alt=''
                                      />
                                    ) : (
                                      <div className='symbol-label fs-6 fw-semibold bg-danger text-inverse-danger'>
                                        {item.fullName.charAt(0).toUpperCase()}
                                      </div>
                                    )}
                                  </div>
                                  <a
                                    href='#'
                                    className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                                  >
                                    {item.fullName}
                                  </a>
                                </div>
                              ))}
                            </div>
                          </div>
                        </td>
                      ) : (
                        <div>
                          <div className='symbol symbol-30px me-2 symbol-square'>
                            {lead?.agent && lead.agent[0]?.profilePic ? (
                              <img
                                src={PF + lead.agent[0].profilePic}
                                className='object-fit-cover'
                                alt=''
                              />
                            ) : (
                              <div className='symbol-label fs-6 fw-semibold bg-danger text-inverse-danger'>
                                {lead.agent[0].fullName.charAt(0).toUpperCase()}
                              </div>
                            )}
                          </div>
                          <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                            {lead?.agent[0]?.fullName}
                          </a>
                        </div>
                      ))}
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {formatCreatedAt(lead?.start)}
                      </a>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {formatCreatedAttime(lead?.start)}
                      </a>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        <span
                          className={`badge fs-7 fw-semibold ${
                            lead?.priority === 'Important'
                              ? 'badge-light-danger'
                              : lead?.priority === 'Medium'
                              ? 'badge-light-warning'
                              : 'badge-light-success'
                          }`}
                        >
                          {lead?.priority}
                        </span>
                      </a>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        <div
                          className={`cursor-pointer badge fs-6 capitalize fw-semibold  ${
                            lead?.status === 'pending'
                              ? 'badge-light-warning'
                              : lead?.status === 'done'
                              ? 'badge-light-success'
                              : 'badge-light-danger'
                          }`}
                          data-kt-menu-trigger='click'
                          data-kt-menu-placement='bottom-end'
                          data-kt-menu-flip='top-end'
                        >
                          {lead?.status}
                        </div>
                        <div
                          className='menu menu-sub menu-sub-dropdown w-150px w-md-150px p-5'
                          data-kt-menu='true'
                          style={{zIndex: 1000000}}
                        >
                          <div
                            className={`badge  fs-7 p-3 mb-3 fw-semibold cursor-pointer ${
                              lead?.status === 'pending' ? 'badge-warning' : 'badge-light-dark'
                            } `}
                            onClick={() => handleStatusChange(lead?._id, 'pending')}
                          >
                            pending
                          </div>
                          <div
                            className={`badge  fs-7 p-3 mb-3 fw-semibold cursor-pointer ${
                              lead?.status === 'done' ? 'badge-success' : 'badge-light-dark'
                            } `}
                            onClick={() => handleStatusChange(lead?._id, 'done')}
                          >
                            done
                          </div>
                          <div
                            className={`badge  fs-7 p-3 mb-3 fw-semibold cursor-pointer ${
                              lead?.status === 'cancelled' ? 'badge-danger' : 'badge-light-dark'
                            } `}
                            onClick={() => handleStatusChange(lead?._id, 'cancelled')}
                          >
                            cancelled
                          </div>
                        </div>
                      </a>
                    </td>
                    {/* <td>
                          <div className='d-flex'>
                            {(lead?.status === 'pending' || lead?.status === 'cancelled') && (
                              <div
                                className='btn btn-sm btn-light-success me-3'
                                onClick={() => handleApproved(lead?._id, index)}
                              >
                                <KTIcon iconName='check' className='fs-1' />
                              </div>
                            )}
                            {(lead?.status === 'pending' || lead?.status === 'done') && (
                              <div
                                className='btn btn-sm btn-light-danger'
                                onClick={() => handleReject(lead?._id, index)}
                              >
                                <KTIcon iconName='cross' className='fs-1' />
                              </div>
                            )}
                          </div>
                        </td> */}
                  </tr>
                ))}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}

        {/* Modal for add client */}
      </div>
    </div>
  )
}

export default TasksList
