import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  customers: [],
  loading: true,
  fetch: true,
}

const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    setCRMCustomers: (state, action) => {
      state.customers = action.payload
      state.loading = false
      state.fetch = false
    },
    setCRMCustomersLoading: (state, action) => {
      state.loading = action.payload
    },
    setCRMCustomersFetch: (state, action) => {
      state.fetch = action.payload
    },
  },
})

export const {setCRMCustomers, setCRMCustomersLoading, setCRMCustomersFetch} =
  customersSlice.actions
export default customersSlice.reducer
