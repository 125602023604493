import {useEffect, useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'
import {KTIcon} from '../../../../_metronic/helpers'
import {getFilteredRFQ, getFilteredRFQByAgentId, updateRFQValidationStatus} from '../Core/_requests'
import qs from 'qs'
import {Filtertype2, ValidFilter} from '../Core/_model'
import Filter2 from '../Components/Filter2'
import {RiArrowDropDownLine} from 'react-icons/ri'
import {useDispatch, useSelector} from 'react-redux'
import {setRfqDataList} from '../../../redux/procurement/rfq/rfqSlice'
import {RootState} from '../../../redux/store'
import debounce from 'lodash/debounce'
import Pagination from '../Components/Pagination'
import DateFilter from '../../account/components/DateFilter'

const RFQ = () => {
  const {currentUser} = useAuth()
  const initialRef = useRef(true)
  // const [RFQList, setRFQList] = useState<any>([])
  const [permissions, setPermissions] = useState<any>({})
  const [dropdown, setDropdown] = useState([])
  const [filteredRFQList, setFilteredRFQList] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const rfqData = useSelector((state: RootState) => state.procurement.rfq.rfqData)
  const totalPagesStore = useSelector((state: RootState) => state.procurement.rfq.totalPages)
  const refetchData = useSelector((state: RootState) => state.procurement.rfq.fetch)
  const dateRange = useSelector((state: RootState) => state.procurement.rfq.dateState)
  const [dateState, setDateState] = useState<any>(dateRange)
  const [vendorName, setVendorName] = useState('')
  // Pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const stringifyObject = (obj: ValidFilter): string => {
    const queryString = qs.stringify(
      {
        filter_validity: obj.filter.isValid,
        filter_approval: obj.filter.approval,
        filter_vendor: obj.filter.vendor,
        filter_vendorName: vendorName,
        search_query: searchQuery,
      },
      {encodeValuesOnly: true, skipNulls: true, delimiter: '&'}
    )

    return queryString
  }

  const getRFQList = async () => {
    setIsLoading(true)
    try {
      const queryString = stringifyObject(searchFilters)
      const res = await getFilteredRFQ(
        queryString,
        currentUser?.organization,
        currentPage,
        25,
        dateState
      )
      setFilteredRFQList(res?.rfq)
      setTotalPages(res?.totalPages)
      setDropdown(Array(res?.rfq?.length).fill(false))
      return res
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const getBillsByAgent = async () => {
    setIsLoading(true)
    try {
      const queryString = stringifyObject(searchFilters)
      const res = await getFilteredRFQByAgentId(
        queryString,
        currentUser?._id,
        currentPage,
        25,
        dateState
      )
      setFilteredRFQList(res?.rfq)
      setTotalPages(res?.totalPages)
      setDropdown(Array(res?.rfq?.length).fill(false))
      return res
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const fetchRFQData = () => {
    if (dateState.startDate !== undefined && dateState.endDate !== undefined) {
      if (permissions?.rfq?.admin === false) {
        getBillsByAgent()
      } else if (permissions?.rfq?.admin === true || currentUser?.profileType === 'superadmin') {
        getRFQList()
      }
    }
  }

  const fetchRFQs = () => {
    if (rfqData.length === 0 || refetchData) {
      if (permissions?.rfq?.admin === false) {
        getBillsByAgent().then((res) => {
          dispatch(setRfqDataList(res))
        })
      } else if (permissions?.rfq?.admin === true || currentUser?.profileType === 'superadmin') {
        getRFQList().then((res) => {
          dispatch(setRfqDataList(res))
        })
      }
    } else {
      setFilteredRFQList(rfqData)
      setDropdown(Array(rfqData.length).fill(false))
      setTotalPages(totalPagesStore)
    }
  }

  useEffect(() => {
    fetchRFQs()
  }, [permissions])

  const debounceSearch = debounce(fetchRFQData, 500)

  useEffect(() => {
    if (initialRef.current) {
      initialRef.current = false
      return
    }

    debounceSearch()

    return () => debounceSearch?.cancel()
  }, [searchQuery, currentPage, dateState.endDate, vendorName])

  const handleClick = (index) => {
    const newDropdownState = dropdown.map((_, i) => (i === index ? !dropdown[i] : false))
    setDropdown(newDropdownState)
  }

  const handleActivation = async (id, valid) => {
    const res = await updateRFQValidationStatus(id, {valid: !valid})
    if (res) {
      fetchRFQData()
    }
  }

  let searchFilters: ValidFilter = {
    filter: {
      isValid: true,
    },
  }

  const setSearchFilter = (updateValues: {filter?: Partial<Filtertype2>}): void => {
    searchFilters = {
      ...searchFilters,
      ...(updateValues.filter && {
        filter: {
          ...searchFilters.filter,
          ...updateValues.filter,
        },
      }),
    }
  }

  const setValidityFilter = (isValid: boolean) => {
    setSearchFilter({
      filter: {
        isValid,
      },
    })
  }

  const setApprovalFilter = (approval: string) => {
    setSearchFilter({
      filter: {
        isValid: searchFilters.filter.isValid,
        status: searchFilters.filter.status,
        approval: approval, // Update approval filter value
      },
    })
  }

  // useEffect(() => {
  //   const filteredData = RFQList.filter(
  //     (item) =>
  //       item.vendor?.displayName.toLowerCase().includes(searchQuery.toLowerCase()) ||
  //       item.employee?.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
  //       item?.id?.toLowerCase().includes(searchQuery.toLowerCase())
  //   )
  //   setFilteredRFQList(filteredData)
  // }, [searchQuery, RFQList])

  const handlereset = async () => {
    setSearchFilter({
      filter: {
        isValid: true,
        approval: '',
      },
    })
    setFilteredRFQList(rfqData)
    setDropdown(Array(rfqData.length)?.fill(false))
  }

  return (
    <div className='card card-flush'>
      <span className='card-label fw-bold fs-3 ps-15 pt-8 '>Request for Quotation</span>
      <div className='card-header border-0 pt-5'>
        <div className='flex gap-5'>
          <div className='d-flex flex-row card-title align-items-center gap-4'>
            <form
              data-kt-search-element='form'
              className='w-100 position-relative '
              autoComplete='off'
            >
              <KTIcon
                iconName='magnifier'
                className='fs-2 text-lg-1 text-gray-500 position-absolute top-50 translate-middle-y ms-2'
              />
              <input
                type='text'
                className='form-control form-control-solid w-250px ps-14'
                name='search'
                placeholder='Search (RFQ ID)'
                data-kt-search-element='input'
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </form>

            {/* input field for vendor display name */}
            <form
              data-kt-search-element='form'
              className='w-100 position-relative '
              autoComplete='off'
            >
              <KTIcon
                iconName='magnifier'
                className='fs-2 text-lg-1 text-gray-500 position-absolute top-50 translate-middle-y ms-2'
              />
              <input
                type='text'
                className='form-control form-control-solid w-250px ps-14'
                name='search'
                placeholder='Search (Vendor Name)'
                value={vendorName}
                onChange={(e) => setVendorName(e.target.value)}
              />
            </form>
            <DateFilter dateState={dateState} setDateState={setDateState} />
          </div>
          <div className=' d-flex flex-row  card-title align-items-center'>
            <Filter2
              searchFilters={searchFilters}
              setSearchFilter={setSearchFilter}
              handlereset={handlereset}
              getDocsList={fetchRFQData}
              setValidityFilter={setValidityFilter}
              showStatusFilter={false}
              setApprovalFilter={setApprovalFilter}
            />
          </div>
        </div>

        {(permissions?.rfq?.create || currentUser?.profileType === 'superadmin') && (
          <div className='card-toolbar'>
            <Link to={'/procurement/rfq/add'} className='btn btn-sm btn-light-primary'>
              <KTIcon iconName='plus' className='fs-2' />
              Add RFQ
            </Link>
          </div>
        )}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        {isLoading ? (
          <div className='d-flex align-items-center justify-center'>
            <div className='loader'></div>
          </div>
        ) : (
          <div className='table-responsive overflow-visible'>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-4 table-row-bordered'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-50px rounded-start'>ID</th>
                  <th className='min-w-150px'>Employee</th>
                  <th className='min-w-150px'>Vendor</th>
                  {/* <th className='min-w-150px'>Inventory</th> */}
                  {/* <th className='min-w-100px'>Amount</th> */}
                  <th className='min-w-100px'>Approval</th>
                  {(permissions?.rfq?.update || currentUser?.profileType === 'superadmin') && (
                    <th className='min-w-150px text-center'>Actions</th>
                  )}
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {filteredRFQList?.map((lead: any, index: number) => (
                  <tr key={lead?._id?.toString()}>
                    <td>
                      <Link
                        to={`/procurement/rfq/${lead._id}`}
                        className='ps-4 text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {lead?.id}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/procurement/rfq/${lead._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {lead?.employee?.firstName}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/procurement/rfq/${lead._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {lead?.vendor?.displayName}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/sales/quotes/${lead._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        <span
                          className={`badge  fs-7 fw-semibold ${
                            lead?.approval === 'pending'
                              ? 'badge-light-primary'
                              : lead?.approval === 'accepted'
                              ? 'badge-light-success'
                              : 'badge-light-danger'
                          }`}
                        >
                          {lead?.approval}
                        </span>
                      </Link>
                    </td>
                    {(permissions?.rfq?.update || currentUser?.profileType === 'superadmin') && (
                      <td className='relative '>
                        <div className='d-flex justify-content-center flex-shrink-0'>
                          <button
                            onClick={() => handleClick(index)}
                            className=' bg-gray-100 hover:bg-lime-50 px-6 py-2 flex items-center rounded self-end'
                          >
                            Actions <RiArrowDropDownLine />
                          </button>
                        </div>
                        {dropdown[index] && (
                          <div className='absolute flex flex-col bg-white  w-40 shadow-[0_16px_16px_6px_rgba(128,128,128,0.1)] top-16 right-[70px] rounded p-3 z-50 space-y-2'>
                            <Link to={`edit/${lead._id}`}>
                              <button className='hover:bg-lime-50  hover:w-full  p-2  text-start rounded'>
                                Edit
                              </button>
                            </Link>
                            <Link to={`revised/${lead._id}`}>
                              <button className='hover:bg-lime-50  hover:w-full  p-2  text-start rounded'>
                                Revised
                              </button>
                            </Link>
                            {!lead?.valid ? (
                              <button
                                className='hover:bg-lime-50 p-2 text-start rounded'
                                onClick={() => handleActivation(lead._id, lead.valid)}
                              >
                                Valid
                              </button>
                            ) : (
                              <button
                                className='hover:bg-lime-50 p-2 text-start rounded'
                                onClick={() => handleActivation(lead._id, lead.valid)}
                              >
                                Invalid
                              </button>
                            )}
                          </div>
                        )}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
        )}
        {/* end::Table container */}
      </div>

      {/* Pagination */}
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
      />
    </div>
  )
}

export default RFQ
