import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {Link, useParams} from 'react-router-dom'
import {getTransactionsByCustomerId} from '../Core/_requests'
import {formatCurrencyWithoutSymbol} from '../../../utils/utils'
import {useAuth} from '../../../modules/auth'

export default function Transaction({className}) {
  const id = useParams().customerId
  const {currentUser} = useAuth()
  const [transactions, setTransactions] = useState([])
  const [showDropdown, setShowDropdown] = useState({
    invoice: false,
    paymentreceive: false,
  })

  useEffect(() => {
    const fetchData = async () => {
      const res = await getTransactionsByCustomerId(id, '365', currentUser.organization)
      setTransactions(res?.transactions)
    }

    fetchData()
  }, [])

  const formatDate = (date) => {
    const d = new Date(date)
    // return date as 30/12/2021
    return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
  }

  return (
    <div className={`card w-100 position-relative ${className} `}>
      <div className='card-body'>
        <div className='d-flex align-items-center bg-light rounded p-5 mb-7'>
          <div
            className='flex-grow-1 me-2'
            onClick={(e) => {
              e.preventDefault()
              setShowDropdown({...showDropdown, invoice: !showDropdown.invoice})
            }}
          >
            <a href='#' className='fw-bold text-gray-800 text-hover-primary fs-6'>
              <KTIcon iconName='arrow-right' /> Invoice
            </a>
            <span className='text-muted fw-semibold d-block'></span>
          </div>
          <div className='card-toolbar'>
            <Link to={'/sales/invoice/add'}>
              <KTIcon iconName='plus' className='text-primary rounded border border-primary me-2' />
              Add-new
            </Link>
          </div>
        </div>
        {showDropdown.invoice && (
          // a dropdown menu containing data of transactions table for invoices
          <div className='table-responsive mt-5'>
            <table className='table table-borderless'>
              <thead>
                <tr className='bg-light-dark'>
                  <th scope='col' className='ps-2'>
                    Date
                  </th>
                  <th scope='col'>Transaction</th>
                  <th scope='col'>Amount</th>
                  {/* <th scope='col'>Payment</th> */}
                  <th scope='col'>Balance</th>
                </tr>
              </thead>
              <tbody>
                {transactions?.map((transaction, index) => (
                  <>
                    {transaction?.type === 'invoice' && (
                      <tr key={index}>
                        <td className='ps-2'>{formatDate(transaction.createdAt)}</td>
                        <td>{transaction?.id}</td>
                        <td>
                          {transaction.debit !== 0
                            ? formatCurrencyWithoutSymbol(transaction?.debit)
                            : formatCurrencyWithoutSymbol(transaction?.credit)}
                        </td>
                        <td>{transaction.debit}</td>
                      </tr>
                    )}
                  </>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <div className='d-flex align-items-center bg-light rounded p-5 mb-7'>
          <div
            className='flex-grow-1 me-2'
            onClick={(e) => {
              e.preventDefault()
              setShowDropdown({...showDropdown, paymentreceive: !showDropdown.paymentreceive})
            }}
          >
            <a href='#' className='fw-bold text-gray-800 text-hover-primary fs-6'>
              <KTIcon iconName='arrow-right' /> Payment Receive
            </a>
            <span className='text-muted fw-semibold d-block'></span>
          </div>
          <div className='card-toolbar'>
            <Link to={'/sales/paymentReceived/add'}>
              <KTIcon iconName='plus' className='text-primary rounded border border-primary me-2' />
              Add-new
            </Link>
          </div>
        </div>
        {showDropdown.paymentreceive && (
          // a dropdown menu containing data of transactions table for payment receive
          <div className='table-responsive mt-5'>
            <table className='table table-borderless'>
              <thead>
                <tr className='bg-light-dark'>
                  <th scope='col' className='ps-2'>
                    Date
                  </th>
                  <th scope='col'>Transaction</th>
                  <th scope='col'>Amount</th>
                  {/* <th scope='col'>Payment</th> */}
                  <th scope='col'>Balance</th>
                </tr>
              </thead>
              <tbody>
                {transactions?.map((transaction, index) => (
                  <>
                    {transaction?.type === 'payment receive' && (
                      <tr key={index}>
                        <td className='ps-2'>{formatDate(transaction.createdAt)}</td>
                        <td>{transaction?.id}</td>
                        <td>
                          {transaction.debit !== 0
                            ? formatCurrencyWithoutSymbol(transaction?.debit)
                            : formatCurrencyWithoutSymbol(transaction?.credit)}
                        </td>
                        <td>
                          {transaction.debit !== 0
                            ? formatCurrencyWithoutSymbol(transaction?.debit)
                            : formatCurrencyWithoutSymbol(transaction?.credit)}
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  )
}
