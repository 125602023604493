import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  leads: [],
  loading: true,
  fetch: true,
}

const leadsSlice = createSlice({
  name: 'leads',
  initialState,
  reducers: {
    setCRMLeads: (state, action) => {
      state.leads = action.payload
      state.loading = false
      state.fetch = false
    },
    setCRMLeadsLoading: (state, action) => {
      state.loading = action.payload
    },
    setCRMLeadsFetch: (state) => {
      state.fetch = true
    },
  },
})

export const {setCRMLeads, setCRMLeadsLoading, setCRMLeadsFetch} = leadsSlice.actions
export default leadsSlice.reducer
