import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface DateState {
  startDate: string
  endDate: string
}

interface RFQState {
  rfqData: any[]
  loading: boolean
  totalPages: number
  dateState: DateState
  error: string | null
  fetch: boolean
}

const initialState: RFQState = {
  rfqData: [],
  dateState: {
    startDate: null,
    endDate: null,
  },
  totalPages: 1,
  loading: true,
  error: null,
  fetch: true,
}

const rfqSlice = createSlice({
  name: 'rfq',
  initialState,
  reducers: {
    setRfqDataList(state, action) {
      state.rfqData = action.payload.rfq
      state.totalPages = action.payload.totalPages
      state.loading = false
      state.error = null
      state.fetch = false
    },
    setDateRange(state, action: PayloadAction<DateState>) {
      state.dateState = action.payload
    },
    setLoading(state) {
      state.loading = true
      state.error = null
    },
    setError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    setRFQDataFetch(state) {
      state.fetch = true
    },
  },
})

export const {setRfqDataList, setLoading, setError, setRFQDataFetch, setDateRange} =
  rfqSlice.actions
export default rfqSlice.reducer
