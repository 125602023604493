import {useCallback} from 'react'
import {useAuth} from '../../../modules/auth'
import Swal from 'sweetalert2'

const useExcelImport = () => {
  const {currentUser} = useAuth()
  const handleDownloadExcel = useCallback(async (event, submitData, setLoading, title) => {
    const file = event.target.files[0]
    if (!file) return
    setLoading(true)

    const formData = new FormData()
    formData.append('file', file)

    const response =
      title === 'goods' || title === 'consumables' || title === 'rentals' || title === 'materials'
        ? await submitData(formData, currentUser.company, currentUser.organization, title)
        : await submitData(formData, currentUser.company, currentUser.organization)

    if (response.errors && response.errors.length > 0) {
      const errorMessages = response.errors
        .map((error) => `Row ${error.row}: ${error.error}`)
        .join('\n')

      await Swal.fire({
        title: 'Import Results',
        html: `
            <div>
              <p>Total Processed: ${response.totalProcessed}</p>
              <p>Successful Uploads: ${response.successCount}</p>
              <p>Errors: ${response.errorCount}</p>
              ${response.errorCount > 0 ? '<h4>Error Details:</h4>' : ''}
              <pre style="max-height: 200px; overflow-y: auto; text-align: left;">${errorMessages}</pre>
            </div>
          `,
        icon: 'info',
        confirmButtonText: 'OK',
      })
    } else {
      await Swal.fire('Success', `All ${title} were imported successfully!`, 'success')
    }
    setLoading(false)
  }, [])

  return handleDownloadExcel
}

export default useExcelImport
