import React, {useEffect, useState} from 'react'
import {useAuth} from '../../../modules/auth'
import {Link} from 'react-router-dom'
import {Modal, Button, Form, Dropdown} from 'react-bootstrap'
import toast from 'react-hot-toast'
import {
  getHRPEmployeeCamps,
  getHRPEmployees,
  postHRPEmployeeCamp,
  putHRPEmployeeCamp,
} from '../core/_requests'
import {useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../../redux/store'
import {setEmployees} from '../../../redux/hrp/hrpEmployeesSlice'
import {setCamps} from '../../../redux/hrp/campsSlice'

const HRPEmployeeCamp = () => {
  const {currentUser} = useAuth()
  const [permissions, setPermissions] = useState<any>({})
  const dispatch = useDispatch()
  const hrpCampsFetch = useSelector((state: RootState) => state.hrp.camps.fetch)
  const hrpCampsData = useSelector((state: RootState) => state.hrp.camps.camps)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [campName, setCampName] = useState('')
  const [loading, setLoading] = useState<boolean>(false)
  const availableEmployees = useSelector((state: RootState) => state.hrp.employees.employees)
  const hrpEmployeesFetch = useSelector((state: RootState) => state.hrp.employees.fetch)
  const [selectedEmployees, setSelectedEmployees] = useState<any>([])
  const [editId, setEditId] = useState('')

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const fetchEmployeeGroups = async () => {
    setLoading(true)
    const res = await getHRPEmployeeCamps(currentUser?.organization)
    dispatch(setCamps(res))
    setLoading(false)
  }

  const fetchAvailableEmployees = async () => {
    const res = await getHRPEmployees(currentUser?.organization)
    dispatch(setEmployees(res))
  }

  useEffect(() => {
    if (currentUser?.organization) {
      if (hrpCampsFetch) fetchEmployeeGroups()
      if (hrpEmployeesFetch) fetchAvailableEmployees()
    }
  }, [currentUser?.organization])

  const handleSubmit = async () => {
    try {
      if (campName.trim() === '') {
        toast.error('Please enter a group name')
        return
      }
      const data = {
        name: campName,
        employees: selectedEmployees,
        company: currentUser?.company,
        organization: currentUser?.organization,
      }
      await postHRPEmployeeCamp(data).then((res) => {
        toast.success('Employee group added successfully')
        setIsModalOpen(false)
        setCampName('')
        setSelectedEmployees([])
        fetchEmployeeGroups()
      })
    } catch (error) {
      console.error('Error submitting employee group:', error)
    }
  }

  const handleUpdate = async () => {
    if (campName.trim() === '') {
      toast.error('Please enter a group name')
      return
    }

    try {
      const data = {
        name: campName,
        employees: selectedEmployees,
      }
      await putHRPEmployeeCamp(data, editId).then((res) => {
        toast.success('Employee group updated successfully')
        setIsEditModalOpen(false)
        setIsModalOpen(false)
        setCampName('')
        setSelectedEmployees([])
        setEditId('')
        fetchEmployeeGroups()
      })
    } catch (error) {
      console.error('Error updating employee group:', error)
    }
  }

  const handleEmployeeSelect = (employeeId: string) => {
    setSelectedEmployees((prev) =>
      prev.includes(employeeId) ? prev.filter((id) => id !== employeeId) : [...prev, employeeId]
    )
  }

  return (
    <div className='p-6 md:m-4 card card-xxl-stretch'>
      <div className='d-flex justify-between card-label fw-bold fs-3 mb-1s'>
        Camps
        {(permissions?.camps?.create || currentUser?.profileType === 'superadmin') && (
          <Button variant='primary' onClick={() => setIsModalOpen(true)}>
            Add New Camp
          </Button>
        )}
      </div>

      <div className='card-body py-3'>
        {loading ? (
          <div className='d-flex align-items-center justify-center'>
            <div className='loader'></div>
          </div>
        ) : (
          <div className='table-responsive min-h-500px'>
            <table className='table align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-25px'>No.</th>
                  <th className='min-w-200px rounded-start'>Group Name</th>
                  <th className='min-w-125px text-center'>No. of Employees</th>
                  {(permissions?.camps?.update || currentUser?.profileType === 'superadmin') && (
                    <th className='min-w-125px text-center'>Action</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {hrpCampsData &&
                  hrpCampsData.map((item, i) => (
                    <tr key={i} style={{marginBottom: '10px'}}>
                      <td className='ps-4'>{i + 1}</td>
                      <td>
                        <Link to={`${item._id}`}>
                          <span className='text-dark fw-bold text-hover-primary fs-6 cursor-pointer'>
                            {item?.name}
                          </span>
                        </Link>
                      </td>
                      <td className='text-center'>{item?.employees?.length}</td>
                      {(permissions?.camps?.update ||
                        currentUser?.profileType === 'superadmin') && (
                        <td className='text-center'>
                          <Dropdown>
                            <Dropdown.Toggle variant='secondary' id='dropdown-basic'>
                              Action
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => {
                                  setIsModalOpen(true)
                                  setIsEditModalOpen(true)
                                  setCampName(item?.name)
                                  setSelectedEmployees(item?.employees)
                                  setEditId(item?._id)
                                }}
                              >
                                Edit
                              </Dropdown.Item>
                              {/* <Dropdown.Item>Delete</Dropdown.Item> */}
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{isEditModalOpen ? 'Edit Camp' : 'Add New Camp'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className='mb-3'>
              <Form.Label className='required'>Camp Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter camp name'
                value={campName}
                onChange={(e) => setCampName(e.target.value)}
              />
            </Form.Group>
            <div style={{maxHeight: '60vh', overflowY: 'auto'}} className='mt-3'>
              <Form.Label>Select Employees</Form.Label>
              {availableEmployees.map((employee) => (
                <Form.Group key={employee._id} className='mb-3'>
                  <Form.Check
                    type='checkbox'
                    id={`employee-${employee._id}`}
                    label={employee?.firstName + ' ' + employee?.lastName}
                    checked={selectedEmployees.includes(employee._id)}
                    onChange={() => handleEmployeeSelect(employee._id)}
                  />
                </Form.Group>
              ))}
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setIsModalOpen(false)}>
            Close
          </Button>
          <Button
            variant={isEditModalOpen ? 'warning' : 'primary'}
            onClick={isEditModalOpen ? handleUpdate : handleSubmit}
          >
            {isEditModalOpen ? 'Update' : 'Submit'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default HRPEmployeeCamp
