import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface PnlState {
  gross: number
  operating: number
  net: number
  income: number
  expense: number
  goods: number
}

interface MarketingState {
  pnl: PnlState
  expense: string
  profit: string
  totalBusinessSum: string
  salesPerformanceData: any[]
  vendorsData: any[]
  fetch: boolean
}

const initialState: MarketingState = {
  pnl: {
    gross: 0,
    operating: 0,
    net: 0,
    income: 0,
    expense: 0,
    goods: 0,
  },
  expense: 'Loading',
  profit: 'Loading',
  totalBusinessSum: 'Loading',
  salesPerformanceData: [],
  vendorsData: [],
  fetch: true,
}

const marketingSlice = createSlice({
  name: 'marketing',
  initialState,
  reducers: {
    setPnl(state, action: PayloadAction<PnlState>) {
      state.pnl = action.payload
    },
    setExpense(state, action: PayloadAction<string>) {
      state.expense = action.payload
    },
    setProfit(state, action: PayloadAction<string>) {
      state.profit = action.payload
      state.fetch = false
    },
    setTotalBusinessSum(state, action: PayloadAction<string>) {
      state.totalBusinessSum = action.payload
    },
    setSalesPerformanceData(state, action: PayloadAction<any[]>) {
      state.salesPerformanceData = action.payload
    },
    setVendorsData(state, action: PayloadAction<any[]>) {
      state.vendorsData = action.payload
    },
  },
})

export const {
  setPnl,
  setExpense,
  setProfit,
  setTotalBusinessSum,
  setSalesPerformanceData,
  setVendorsData,
} = marketingSlice.actions

export default marketingSlice.reducer
