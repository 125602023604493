import React, {useEffect, useRef, useState} from 'react'
import {useAuth} from '../../../modules/auth'
import {
  getEquipmentsList,
  getIssuesByProductId,
  getPartsList,
  getServiceTasks,
  getVehiclesList,
  getRepairPriorityCodes,
  postWorkOrder,
} from '../core/_requests'
import Select from 'react-select'
import Datetime from 'react-datetime'
import 'react-datetime/css/react-datetime.css'
import {getVendor} from '../../purchaseOrders/Core/_requests'
import {useNavigate, useParams} from 'react-router-dom'
import {getEmployeeByStatus} from '../../hrm/core/_requests'
import {KTIcon} from '../../../../_metronic/helpers'
import {productImage} from '../../Inventory/Core/_requests'

const AddWorkOrderWithItem = () => {
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const {id} = useParams()
  const [loading, setLoading] = useState<boolean>(false)
  const [issuesList, setIssuesList] = useState<any>([])
  const [serviceTaskList, setServiceTaskList] = useState<any>([])
  const [selectedServiceTasks, setSelectedServiceTasks] = useState<any>([])
  const [selectedIssues, setSelectedIssues] = useState<any>([])
  const [vendorList, setVendorList] = useState<any>([])
  const [products, setProducts] = useState<any>([])
  const [productType, setProductType] = useState<any>([])
  const [isVehicle, setIsVehicle] = useState<any>(true)
  const [employees, setEmployees] = useState<any>([])
  const [selectedLabor, setSelectedLabor] = useState<any>([])
  const [partsList, setPartsList] = useState<any>([])
  const [priorityList, setPriorityList] = useState<any>([])
  const [selectedParts, setSelectedParts] = useState<any>([])
  const [selectedPhoto, setSelectedPhoto] = useState<any>(null)
  const [selectedDocument, setSelectedDocument] = useState<any>(null)
  const photoRef = useRef<any>(null)
  const documentRef = useRef<any>(null)
  const [prevOdoMeter, setPrevOdoMeter] = useState<any>({
    value: 0,
    date: new Date(),
  })
  const [formData, setFormData] = useState({
    rentalType: 'vehicle',
    product: '',
    status: 'open',
    priority: '',
    priorityId: '',
    issueDate: new Date(),
    scheduledStartDate: new Date(),
    startDate: null,
    expectedCompletionDate: new Date(),
    completionDate: new Date(),
    vendor: null,
    assignedTo: null,
    invoiceNo: '',
    poNo: '',
    comments: '',
    notes: '',
    odometer: 0,
    laborCost: 0,
    partsCost: 0,
    subtotal: 0,
    tax: 0,
    discount: 0,
    total: 0,
    photo: '',
    document: '',
  })

  useEffect(() => {
    if (id) {
      setFormData({...formData, product: id})
    }
  }, [id])

  const fetchProducts = async () => {
    if (formData?.rentalType === 'vehicle') {
      const res = await getVehiclesList(currentUser?.organization)
      console.log(res)
      const data = res?.map((item: any) => ({
        label: item.productName,
        value: item._id,
      }))
      setProducts(data)
      const data1 = res?.map((product) => {
        return {
          label: product._id,
          rentalType: product?.rentalType,
          odometerReading: product?.odometerReading,
          odometerReadingDate: product?.odometerReadingDate,
        }
      })
      setProductType(data1)
      setFormData({...formData, product: data[0]?.value})
    } else {
      const res = await getEquipmentsList(currentUser?.organization)
      console.log(res)
      const data = res?.map((item: any) => ({
        label: item.productName,
        value: item._id,
      }))
      setProducts(data)
      setFormData({...formData, product: data[0]?.value})
    }
  }

  const fetchServiceTasks = async () => {
    const res = await getServiceTasks(currentUser?.organization)
    console.log(res)
    const data = res?.map((item: any) => ({
      label: item.name,
      value: item._id,
      labor: 0,
      parts: 0,
    }))
    setServiceTaskList(data)
  }

  const fetchEmployeeList = async () => {
    const res = await getEmployeeByStatus('filter_status=true', currentUser?.organization)
    const data = res?.map((item: any) => ({
      label: item.firstName + ' ' + item.lastName,
      value: item._id,
      hourlyPay: item?.hourlyPay || 0,
      hoursRequired: 1,
      labor: 0,
    }))
    setEmployees(data)
  }

  const fetchVendors = async () => {
    const res = await getVendor('', currentUser?.organization)
    console.log(res)
    const data = res?.map((item: any) => ({
      label: item.displayName,
      value: item._id,
    }))
    setVendorList(data)
  }

  const fetchParts = async () => {
    const res = await getPartsList(currentUser?.organization)
    console.log(res)
    const data = res?.map((item: any) => ({
      label: item.productName,
      value: item._id,
      cost: item.price,
      quantity: 1,
      parts: 0,
    }))
    setPartsList(data)
  }

  const fetchPriorityCodes = async () => {
    const res = await getRepairPriorityCodes(currentUser?.organization)
    const data = res?.map((item: any) => ({
      label: item.priorityName,
      value: item._id,
    }))
    setPriorityList(data)
    setFormData({...formData, priority: data[0]?.label, priorityId: data[0]?.value})
  }

  useEffect(() => {
    fetchVendors()
    fetchPriorityCodes()
    fetchEmployeeList()
    fetchServiceTasks()
    fetchParts()
  }, [])

  useEffect(() => {
    fetchProducts()
  }, [formData.rentalType])

  const fetchIssuesByProduct = async () => {
    const res = await getIssuesByProductId(formData.product)
    console.log(res)
    setIssuesList(res)
  }

  const formatDaysAgo = (date: Date) => {
    const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000)
    let interval = Math.floor(seconds / 31536000)

    if (interval > 1) {
      return `${interval} years ago`
    }
    interval = Math.floor(seconds / 2592000)
    if (interval > 1) {
      return `${interval} months ago`
    }
    interval = Math.floor(seconds / 86400)
    if (interval > 1) {
      return `${interval} days ago`
    }
    interval = Math.floor(seconds / 3600)
    if (interval > 1) {
      return `${interval} hours ago`
    }
    interval = Math.floor(seconds / 60)
    if (interval > 1) {
      return `${interval} minutes ago`
    }
    return `${Math.floor(seconds) || 0} seconds ago`
  }

  useEffect(() => {
    if (formData.product !== '') {
      fetchIssuesByProduct()
    }
    setSelectedIssues([])
    const selectedproduct = productType.find((item: any) => item.label === formData.product)
    if (selectedproduct) {
      setPrevOdoMeter({
        value: selectedproduct?.odometerReading || 0,
        date: new Date(selectedproduct?.odometerReadingDate),
      })
      setFormData({...formData, odometer: 0})
    }
  }, [formData.product])

  useEffect(() => {
    // discount and tax are in percentage
    const subtotal = formData.laborCost + formData.partsCost
    const discount = (subtotal * formData.discount) / 100
    const tax = (subtotal * formData.tax) / 100
    const total = subtotal + tax - discount
    setFormData({...formData, subtotal, total})
  }, [formData.laborCost, formData.partsCost, formData.tax, formData.discount])

  useEffect(() => {
    // calculate the labor cost
    let laborCost = employees.reduce((acc: number, item: any) => acc + item.labor, 0)
    laborCost += serviceTaskList.reduce((acc: number, item: any) => acc + item.labor, 0)

    let partsCost = partsList.reduce((acc: number, item: any) => acc + item.parts, 0)
    partsCost += serviceTaskList.reduce((acc: number, item: any) => acc + item.parts, 0)

    setFormData({...formData, laborCost, partsCost})
  }, [employees, serviceTaskList, partsList])

  useEffect(() => {
    // put the labor and parts cost value to 0 if the task is not selected
    const newServiceTaskList = serviceTaskList.map((item: any) => {
      if (!selectedServiceTasks.includes(item.value)) {
        return {...item, labor: 0, parts: 0}
      }
      return item
    })
    setServiceTaskList(newServiceTaskList)
  }, [selectedServiceTasks])

  useEffect(() => {
    const newEmployees = employees.map((item: any) => {
      if (!selectedLabor.includes(item.value)) {
        return {...item, labor: 0, hoursRequired: 1}
      } else {
        return {
          ...item,
          labor: item.hourlyPay * item.hoursRequired,
        }
      }
    })
    setEmployees(newEmployees)
  }, [selectedLabor])

  useEffect(() => {
    const newPartsList = partsList.map((item: any) => {
      if (!selectedParts.includes(item.value)) {
        return {...item, parts: 0, quantity: 1}
      } else {
        return {
          ...item,
          parts: item.cost * item.quantity,
        }
      }
    })
    setPartsList(newPartsList)
  }, [selectedParts])

  useEffect(() => {
    const priority = priorityList.find((item: any) => item.value === formData.priorityId)
    setFormData({...formData, priority: priority?.label})
  }, [formData.priorityId])

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (selectedIssues.length === 0) {
      alert('Please select at least one issue')
      return
    }
    if (isVehicle && formData.odometer <= 0) {
      alert('Please enter a valid odometer reading')
      return
    }
    setLoading(true)
    const data = {
      ...formData,
      issues: selectedIssues,
      serviceTask: serviceTaskList
        .filter((item: any) => selectedServiceTasks.includes(item.value))
        .map((item: any) => ({
          task: item.value,
          laborCost: item.labor,
          partsCost: item.parts,
        })),
      laborList: employees
        .filter((item: any) => selectedLabor.includes(item.value))
        .map((item: any) => ({
          labor: item.value,
          laborCost: item.labor,
        })),
      partsList: partsList
        .filter((item: any) => selectedParts.includes(item.value))
        .map((item: any) => ({
          part: item.value,
          partsCost: item.parts,
        })),
      organization: currentUser?.organization,
      agent: currentUser?._id,
      company: currentUser?.company,
    }
    console.log(data)
    const res = await postWorkOrder(data)
    if (res) {
      navigate('/maintenance/work-order')
    }
  }

  const handlePhotoUpload = async (e) => {
    const file = e.target.files[0]
    if (!file) {
      setSelectedPhoto(null)
      setFormData({...formData, photo: ''})
      return
    }
    setSelectedPhoto(file)

    setLoading(true)

    const timestamp = Date.now()
    const formData1 = new FormData()
    formData1.append('image', file)
    formData1.append('name', `${timestamp}${file.name}`)

    setFormData({...formData, photo: `${timestamp}${file.name}`})

    const res = await productImage(formData1)
    console.log(res)
    setLoading(false)
  }

  const handleDocumentUpload = async (e) => {
    const file = e.target.files[0]
    if (!file) {
      setSelectedDocument(null)
      setFormData({...formData, document: ''})
      return
    }
    setSelectedDocument(file)

    setLoading(true)

    const timestamp = Date.now()
    const formData1 = new FormData()
    formData1.append('image', file)
    formData1.append('name', `${timestamp}${file.name}`)

    setFormData({...formData, document: `${timestamp}${file.name}`})

    const res = await productImage(formData1)
    console.log(res)
    setLoading(false)
  }

  return (
    <div className='container'>
      <div className='card'>
        <div className='card-header d-flex justify-between align-items-center'>
          <h3>New Work Order</h3>
        </div>
        <div className='card-body'>
          <label className='form-label'>Rental Type</label>
          <select
            className='form-select'
            value={formData.rentalType}
            onChange={(e) => {
              setFormData({...formData, rentalType: e.target.value})
              setIsVehicle(e.target.value === 'vehicle')
            }}
          >
            <option value='vehicle'>Vehicle</option>
            <option value='equipment'>Equipment</option>
          </select>
          <label className='form-label mt-3 required'>Product</label>
          <Select
            options={products}
            isSearchable
            value={products.find((item: any) => item.value === formData.product)}
            onChange={(e) => setFormData({...formData, product: e.value})}
          />
          <label className='form-label mt-3'>Status</label>
          <select
            className='form-select'
            value={formData.status}
            onChange={(e) => setFormData({...formData, status: e.target.value})}
          >
            <option value='open'>Open</option>
            <option value='pending'>Pending</option>
            <option value='completed'>Completed</option>
          </select>
          <label className='form-label mt-3'>Repair Priority Class</label>
          <select
            className='form-select'
            value={formData.priorityId}
            onChange={(e) => setFormData({...formData, priorityId: e.target.value})}
          >
            {priorityList.map((item: any) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
          {isVehicle && (
            <>
              <label className='form-label mt-3 required'>Start Odometer</label>
              <input
                type='number'
                className='form-control'
                value={formData.odometer}
                onChange={(e) => setFormData({...formData, odometer: +e.target.value})}
              />
              <div className=''>
                <span className='text-muted'>
                  Last updated: {prevOdoMeter?.value} mi ({formatDaysAgo(prevOdoMeter?.date)})
                </span>
              </div>
            </>
          )}
          <label className='form-label mt-3 required'>Issue Date</label>
          <Datetime
            value={formData.issueDate}
            dateFormat='DD/MM/YYYY'
            onChange={(e: any) => setFormData({...formData, issueDate: e.toDate()})}
          />
          <div className='row gx-10 mt-3'>
            <div className='col-6'>
              <label className='form-label '>Scheduled Start Date</label>
              <Datetime
                value={formData.scheduledStartDate}
                dateFormat='DD/MM/YYYY'
                onChange={(e: any) => setFormData({...formData, scheduledStartDate: e.toDate()})}
              />
            </div>
            <div className='col-6'>
              <label className='form-label '>Actual start Date</label>
              <Datetime
                value={formData.startDate}
                dateFormat='DD/MM/YYYY'
                onChange={(e: any) => setFormData({...formData, startDate: e.toDate()})}
              />
            </div>
          </div>
          <div className='row gx-10 mt-3'>
            <div className='col-6'>
              <label className='form-label'>Expected Completion Date</label>
              <Datetime
                value={formData.expectedCompletionDate}
                dateFormat='DD/MM/YYYY'
                onChange={(e: any) =>
                  setFormData({...formData, expectedCompletionDate: e.toDate()})
                }
              />
            </div>
            <div className='col-6'>
              <label className='form-label'>Actual Completion Date</label>
              <Datetime
                value={formData.completionDate}
                dateFormat='DD/MM/YYYY'
                onChange={(e: any) => setFormData({...formData, completionDate: e.toDate()})}
              />
            </div>
          </div>
          <label className='form-label mt-3'>Assigned To</label>
          <Select
            options={employees}
            isSearchable
            value={employees.find((item: any) => item.value === formData.assignedTo)}
            onChange={(e) => setFormData({...formData, assignedTo: e.value})}
          />
          <label className='form-label mt-3'>Vendor</label>
          <Select
            options={vendorList}
            isSearchable
            value={vendorList.find((item: any) => item.value === formData.vendor)}
            onChange={(e) => setFormData({...formData, vendor: e.value})}
          />
          <div className='row gx-10 mt-3'>
            <div className='col-6'>
              <label className='form-label'>Invoice Number</label>
              <input
                type='text'
                className='form-control'
                value={formData.invoiceNo}
                onChange={(e) => setFormData({...formData, invoiceNo: e.target.value})}
              />
            </div>
            <div className='col-6'>
              <label className='form-label'>PO Number</label>
              <input
                type='text'
                className='form-control'
                value={formData.poNo}
                onChange={(e) => setFormData({...formData, poNo: e.target.value})}
              />
            </div>
          </div>
          <div className='card mt-3'>
            <div className='card-body'>
              <h3>Issues</h3>
              <table className='table table-striped'>
                <thead>
                  <tr className='bg-primary text-white fw-semibold'>
                    <th className='ps-2'>
                      <input
                        type='checkbox'
                        className='form-check-input'
                        checked={selectedIssues.length === issuesList.length}
                        onChange={(e) =>
                          setSelectedIssues(
                            e.target.checked ? issuesList.map((issue: any) => issue._id) : []
                          )
                        }
                      />
                    </th>
                    <th>Priority</th>
                    <th>Issue</th>
                    <th>Summary</th>
                  </tr>
                </thead>
                <tbody>
                  {issuesList?.map((issue: any) => (
                    <tr key={issue._id}>
                      <td className='ps-2'>
                        <input
                          type='checkbox'
                          className='form-check-input'
                          checked={selectedIssues.includes(issue._id)}
                          onChange={(e) =>
                            setSelectedIssues(
                              e.target.checked
                                ? [...selectedIssues, issue._id]
                                : selectedIssues.filter((id: string) => id !== issue._id)
                            )
                          }
                        />
                      </td>
                      <td className='uppercase'>{issue?.priority}</td>
                      <td>#{issue.issueId}</td>
                      <td>{issue.summary}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className='card mt-3'>
            <div className='card-header d-flex justify-between align-items-center'>
              <h3>Line Items</h3>
            </div>
            <div className='card-body'>
              <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
                <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fw-semibold mb-n2'>
                  <li className='nav-item'>
                    <a
                      className='nav-link text-active-primary pb-4 active'
                      data-bs-toggle='tab'
                      href='#kt_ecommerce_service_tasks'
                    >
                      Service Tasks{' '}
                      <span className='text-muted rounded-full bg-gray-300 p-1'>
                        {selectedServiceTasks.length}
                      </span>
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className='nav-link text-active-primary pb-4'
                      data-bs-toggle='tab'
                      href='#kt_ecommerce_labor'
                    >
                      Labor{' '}
                      <span className='text-muted rounded-full bg-gray-300 p-1'>
                        {selectedLabor.length}
                      </span>
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className='nav-link text-active-primary pb-4'
                      data-bs-toggle='tab'
                      href='#kt_ecommerce_parts'
                    >
                      Parts{' '}
                      <span className='text-muted rounded-full bg-gray-300 p-1'>
                        {selectedParts.length}
                      </span>
                    </a>
                  </li>
                </ul>
                <div className='tab-content'>
                  <div
                    className='tab-pane fade show active'
                    id='kt_ecommerce_service_tasks'
                    role='tab-panel'
                  >
                    <Select
                      options={serviceTaskList}
                      isMulti
                      isSearchable
                      value={serviceTaskList.filter((item: any) =>
                        selectedServiceTasks.includes(item.value)
                      )}
                      onChange={(e) => {
                        setSelectedServiceTasks(e.map((item: any) => item.value))
                      }}
                    />
                    <table className='table table-striped my-3'>
                      <thead>
                        <tr className='bg-primary text-white fw-semibold'>
                          <th className='ps-2 min-w-300px'>Task</th>
                          <th>Labor</th>
                          <th>Parts</th>
                          <th>Subtotal</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {serviceTaskList
                          ?.filter((item: any) => selectedServiceTasks.includes(item.value))
                          ?.map((task: any) => (
                            <tr key={task.value}>
                              <td className='ps-2'>{task.label}</td>
                              <td>
                                <input
                                  type='number'
                                  className='form-control'
                                  value={task.labor}
                                  min={0}
                                  onChange={(e) => {
                                    const newServiceTaskList = serviceTaskList.map((item: any) =>
                                      item.value === task.value
                                        ? {...item, labor: +e.target.value}
                                        : item
                                    )
                                    setServiceTaskList(newServiceTaskList)
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type='number'
                                  className='form-control'
                                  value={task.parts}
                                  min={0}
                                  onChange={(e) => {
                                    const newServiceTaskList = serviceTaskList.map((item: any) =>
                                      item.value === task.value
                                        ? {...item, parts: +e.target.value}
                                        : item
                                    )
                                    setServiceTaskList(newServiceTaskList)
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type='number'
                                  className='form-control'
                                  value={task.labor + task.parts}
                                  disabled
                                />
                              </td>
                              <td>
                                <button
                                  className='btn btn-danger btn-sm'
                                  onClick={() => {
                                    setSelectedServiceTasks(
                                      selectedServiceTasks.filter((id: string) => id !== task.value)
                                    )
                                  }}
                                >
                                  <KTIcon iconName='trash' />
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <div className='tab-pane fade' id='kt_ecommerce_labor' role='tab-panel'>
                    <Select
                      options={employees}
                      isMulti
                      isSearchable
                      value={employees.filter((item: any) => selectedLabor.includes(item.value))}
                      onChange={(e) => {
                        setSelectedLabor(e.map((item: any) => item.value))
                      }}
                    />
                    <table className='table table-striped my-3'>
                      <thead>
                        <tr className='bg-primary text-white fw-semibold'>
                          <th className='ps-2 min-w-300px'>Labor</th>
                          <th>Hours Required</th>
                          <th>Hourly Rate</th>
                          <th>Subtotal</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {employees
                          ?.filter((item: any) => selectedLabor.includes(item.value))
                          ?.map((employee: any) => (
                            <tr key={employee.value}>
                              <td className='ps-2'>{employee.label}</td>
                              <td>
                                <input
                                  type='number'
                                  className='form-control'
                                  value={employee.hoursRequired}
                                  min={0}
                                  onChange={(e) => {
                                    const newEmployees = employees.map((item: any) =>
                                      item.value === employee.value
                                        ? {
                                            ...item,
                                            hoursRequired: +e.target.value,
                                            labor: +e.target.value * item.hourlyPay,
                                          }
                                        : item
                                    )
                                    setEmployees(newEmployees)
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type='number'
                                  className='form-control'
                                  value={employee.hourlyPay}
                                  readOnly
                                />
                              </td>
                              <td>
                                <input
                                  type='number'
                                  className='form-control'
                                  value={employee.hourlyPay * employee.hoursRequired}
                                  readOnly
                                />
                              </td>
                              <td>
                                <button
                                  className='btn btn-danger btn-sm'
                                  onClick={() => {
                                    setSelectedLabor(
                                      selectedLabor.filter((id: string) => id !== employee.value)
                                    )
                                  }}
                                >
                                  <KTIcon iconName='trash' />
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <div className='tab-pane fade' id='kt_ecommerce_parts' role='tab-panel'>
                    <Select
                      options={partsList}
                      isMulti
                      isSearchable
                      value={partsList.filter((item: any) => selectedParts.includes(item.value))}
                      onChange={(e) => {
                        setSelectedParts(e.map((item: any) => item.value))
                      }}
                    />
                    <table className='table table-striped my-3'>
                      <thead>
                        <tr className='bg-primary text-white fw-semibold'>
                          <th className='ps-2 min-w-300px'>Parts</th>
                          <th>Quantity</th>
                          <th>Unit Cost</th>
                          <th>Subtotal</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {partsList
                          ?.filter((item: any) => selectedParts.includes(item.value))
                          ?.map((part: any) => (
                            <tr key={part.value}>
                              <td className='ps-2'>{part.label}</td>
                              <td>
                                <input
                                  type='number'
                                  className='form-control'
                                  value={part.quantity}
                                  min={0}
                                  onChange={(e) => {
                                    const newPartsList = partsList.map((item: any) =>
                                      item.value === part.value
                                        ? {
                                            ...item,
                                            quantity: +e.target.value,
                                            parts: +e.target.value * item.cost,
                                          }
                                        : item
                                    )
                                    setPartsList(newPartsList)
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type='number'
                                  className='form-control'
                                  value={part.cost}
                                  readOnly
                                />
                              </td>
                              <td>
                                <input
                                  type='number'
                                  className='form-control'
                                  value={part.cost * part.quantity}
                                  readOnly
                                />
                              </td>
                              <td>
                                <button
                                  className='btn btn-danger btn-sm'
                                  onClick={() => {
                                    setSelectedParts(
                                      selectedParts.filter((id: string) => id !== part.value)
                                    )
                                  }}
                                >
                                  <KTIcon iconName='trash' />
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className='row gx-10 mt-5'>
                <div className='col-8'>
                  {/* text area for notes */}
                  <label className='form-label'>General Notes</label>
                  <textarea
                    className='form-control'
                    placeholder='Add notes or additional details'
                    value={formData.notes}
                    onChange={(e) => setFormData({...formData, notes: e.target.value})}
                    rows={10}
                  />
                </div>
                <div className='col-4 d-flex flex-column gap-3'>
                  <h5>Cost Summary</h5>
                  {/* labor parts subtotal discount tax total */}
                  <div className='d-flex gap-2 justify-between mt-5'>
                    <label className='form-label mt-3'>Labor</label>
                    <input
                      type='number'
                      className='form-control w-75'
                      value={formData.laborCost}
                      onChange={(e) => setFormData({...formData, laborCost: +e.target.value})}
                      readOnly
                    />
                  </div>
                  <div className='d-flex gap-2 justify-between'>
                    <label className='form-label mt-3'>Parts</label>
                    <input
                      type='number'
                      className='form-control w-75'
                      value={formData.partsCost}
                      onChange={(e) => setFormData({...formData, partsCost: +e.target.value})}
                      readOnly
                    />
                  </div>
                  <div className='d-flex gap-2 justify-between'>
                    <label className='form-label mt-3'>Subtotal</label>
                    <input
                      type='number'
                      className='form-control w-75'
                      value={formData.subtotal}
                      readOnly
                    />
                  </div>
                  <div className='d-flex gap-2 justify-between'>
                    <label className='form-label mt-3'>Tax (%)</label>
                    <input
                      type='number'
                      className='form-control w-75'
                      value={formData.tax}
                      min={0}
                      onChange={(e) => setFormData({...formData, tax: +e.target.value})}
                    />
                  </div>
                  <div className='d-flex gap-2 justify-between'>
                    <label className='form-label mt-3'>Discount (%)</label>
                    <input
                      type='number'
                      className='form-control w-75'
                      value={formData.discount}
                      min={0}
                      onChange={(e) => setFormData({...formData, discount: +e.target.value})}
                    />
                  </div>
                  <div className='d-flex gap-2 justify-between'>
                    <label className='form-label mt-3 fw-semibold'>Total</label>
                    <input
                      type='number'
                      className='form-control fw-semibold w-75'
                      value={formData.total}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row gx-10 mt-4'>
            <div className='col-6'>
              <label className='form-label'>Upload Photo</label>
              <input
                type='file'
                accept='image/*'
                className='form-control'
                ref={photoRef}
                onChange={handlePhotoUpload}
              />
            </div>
            <div className='col-6'>
              <label className='form-label'>Upload Document</label>
              <input
                type='file'
                className='form-control'
                ref={documentRef}
                onChange={handleDocumentUpload}
              />
            </div>
          </div>
          <label className='form-label mt-3'>Comments</label>
          <textarea
            className='form-control'
            placeholder='Add an optional comment'
            value={formData.comments}
            onChange={(e) => setFormData({...formData, comments: e.target.value})}
          />
          {/* submit button */}
          <div className='my-3'>
            <button className='btn btn-primary' disabled={loading} onClick={handleSubmit}>
              Save Work Order
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddWorkOrderWithItem
