import React from 'react'
import {Modal, Button, Form} from 'react-bootstrap'
interface AddLeadOrContactModalProps {
  isOpen: boolean
  onClose: () => void
  type: 'lead' | 'contact'
  data?: Array<any>
  selectedData?: Array<any>
  setSelectedData?: (data: Array<any>) => void
  onSubmit: (data: any) => void
  isEdit?: boolean
}

const AddLeadOrContactModal = ({
  isOpen,
  onClose,
  type,
  data,
  selectedData,
  setSelectedData,
  onSubmit,
  isEdit,
}: AddLeadOrContactModalProps) => {
  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Dialog>
        <Modal.Header closeButton>
          <Modal.Title>
            {isEdit
              ? `Edit ${type === 'lead' ? 'Lead' : 'Contact'}`
              : `Add ${type === 'lead' ? 'Lead' : 'Contact'}`}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {data && selectedData && setSelectedData ? (
            <div className='table-responsive'>
              <table className='table align-middle table-bordered table-hover'>
                <thead>
                  <tr>
                    <th className='min-w-25px text-center'>Select</th>
                    <th className='min-w-150px'>Display Name</th>
                    <th className='min-w-150px'>Email</th>
                    <th className='min-w-150px'>Phone</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item: any) => (
                    <tr key={item._id}>
                      <td className='text-center'>
                        <Form.Check
                          type='checkbox'
                          checked={selectedData.some((selected: any) => selected === item._id)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedData([...selectedData, item._id])
                            } else {
                              setSelectedData(
                                selectedData.filter((selected: any) => selected !== item._id)
                              )
                            }
                          }}
                        />
                      </td>
                      <td>{item.displayName}</td>
                      <td>{item.email}</td>
                      <td>{item.phone}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className='text-center'>No data available</div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant='secondary' onClick={onClose}>
            Close
          </Button>
          <Button
            variant='primary'
            onClick={(e) => {
              if (selectedData) {
                onSubmit(e)
              }
              onClose()
            }}
          >
            {isEdit ? 'Update' : 'Add'}
          </Button>
        </Modal.Footer>
      </Modal.Dialog>
    </Modal>
  )
}

export default AddLeadOrContactModal
