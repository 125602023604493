import React, {useState, useEffect} from 'react'
import {Link, useParams} from 'react-router-dom'
import {getProjectById, getTargetByProjectId} from '../core/_requests'
import AddTargetPopUp from '../components/steps/AddTargetPopUp'
import TargetPage from '../components/TargetPage'
import {FilesPage} from '../components/FilesPage'
import Overview from '../components/Overview'
import Budget from '../components/ProjectDetailsSteps/Budget'
import Users from '../components/ProjectDetailsSteps/Users'
import Products from '../components/Products'
import Manpower from '../components/Manpower'
import TargetCalendar from '../components/TargetCalendar'
import TransactionTable from '../components/TransactionTable'
import OrderTable from '../components/OrderTable'
import TimeSheet from '../components/Timesheet'
import {useAuth} from '../../../modules/auth'
import TaskPage from '../components/TaskPage'
import PurchaseRequestList from '../components/PurchaseRequestList'
import EQTimesheet from '../components/EQTimesheet'

const ProjectDetails = () => {
  const {currentUser} = useAuth()
  const [property, setProperty] = useState('Overview')
  const [isSalesOrder, setIsSalesOrder] = useState(false)
  const [data, setData] = useState([])
  const id = useParams().id
  const [target, setTarget] = useState([])
  const [task, setTask] = useState()

  const fetchData = async () => {
    try {
      const res = await getProjectById(id)
      setData(res)
      console.log('data', res)
    } catch (error) {
      console.error('Error fetching the employee details', error)
    }
  }

  console.log('agents', data.items)

  const getTargets = async () => {
    try {
      const res = await getTargetByProjectId(id)
      const tasknew = res.filter((task) => task.status === 'new')
      const taskinprogress = res.filter((task) => task.status === 'inprogress')
      const taskdone = res.filter((task) => task.status === 'done')
      const tasks = {
        new: tasknew,
        inprogress: taskinprogress,
        done: taskdone,
      }
      setTask(tasks)
      setTarget(res)
      console.log('targets', res)
    } catch (error) {
      console.log('error')
    }
  }

  const totalBudget = target
    .reduce((total, target) => {
      return total + target.targetBudget
    }, 0)
    .toLocaleString()

  console.log('total', totalBudget)

  const formatDate = (dateString) => {
    if (!dateString) return ''
    const dateParts = dateString.split('T')[0].split('-')
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ]
    const monthIndex = parseInt(dateParts[1], 10) - 1
    const monthName = monthNames[monthIndex]
    return `${dateParts[2]} ${monthName} ${dateParts[0]}`
  }

  useEffect(() => {
    fetchData()
    getTargets()
  }, [])

  return (
    <div className='content d-flex flex-column flex-column-fluid' id='kt_content'>
      <div className='container-xxl' id='kt_content_container'>
        <div className='card mb-6 mb-xl-9'>
          <div className='card-body pt-9 pb-0'>
            <div className='d-flex flex-wrap flex-sm-nowrap mb-6'>
              <div className='d-flex flex-center flex-shrink-0 bg-light rounded w-100px h-100px w-lg-150px h-lg-150px me-7 mb-4'>
                <img
                  className='mw-50px mw-lg-75px'
                  src='/media/svg/brand-logos/volicity-9.svg'
                  alt='image'
                />
              </div>

              <div className='flex-grow-1'>
                <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                  <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center mb-1'>
                      <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bold me-3'>
                        {data?.projectName}
                      </a>
                      <span className='badge badge-light-success me-auto'>{data?.status}</span>
                    </div>
                    <div className='d-flex flex-wrap fw-semibold mb-4 fs-5 text-gray-400'>
                      {data?.projectDescription}
                    </div>
                  </div>

                  <div className='d-flex mb-4'>
                    {/* <a
                      href='#'
                      className='btn btn-sm btn-bg-light btn-active-color-primary me-3'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_modal_users_search'
                    >
                      Add User
                    </a> */}
                    {currentUser?.projectFeatures?.targets && (
                      <a
                        href='#'
                        className='btn btn-sm btn-primary me-3'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_new_target'
                      >
                        Add Target
                      </a>
                    )}

                    <div className='me-0'>
                      <button
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                      >
                        <i className='ki-solid ki-dots-horizontal fs-2x'></i>
                      </button>

                      <div
                        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3'
                        data-kt-menu='true'
                      >
                        <div className='menu-item px-3'>
                          <div className='menu-content text-muted pb-2 px-3 fs-7 text-uppercase'>
                            Menu
                          </div>
                        </div>

                        {currentUser?.projectFeatures?.order && (
                          <>
                            <div className='menu-item px-3'>
                              <div
                                className='menu-link px-3'
                                onClick={() => {
                                  setProperty('order')
                                  setIsSalesOrder(true)
                                }}
                              >
                                Create Sales Order
                              </div>
                            </div>

                            <div className='menu-item px-3'>
                              <div
                                className='menu-link px-3'
                                onClick={() => {
                                  setProperty('order')
                                  setIsSalesOrder(false)
                                }}
                              >
                                Create Purchase Order
                              </div>
                            </div>
                          </>
                        )}

                        <div className='menu-item px-3'>
                          <Link to={`/project/edit/${id}`} className='menu-link px-3'>
                            Edit Project
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='d-flex flex-wrap justify-content-start'>
                  <div className='d-flex flex-wrap'>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <div className='fs-4 fw-bold'> {formatDate(data?.startDate)}</div>
                      </div>
                      <div className='fw-semibold fs-6 text-gray-400'>Start Date</div>
                    </div>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <div className='fs-4 fw-bold'> {formatDate(data?.releaseDate)}</div>
                      </div>
                      <div className='fw-semibold fs-6 text-gray-400'>Release Date</div>
                    </div>

                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <i className='ki-duotone ki-arrow-down fs-3 text-danger me-2'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                        </i>
                        <div
                          className='fs-4 fw-bold'
                          data-kt-countup='true'
                          data-kt-countup-value='75'
                        >
                          {/* {data && data.target && data.target.length} */}
                          {target?.length}
                        </div>
                      </div>

                      <div className='fw-semibold fs-6 text-gray-400'>Open Tasks</div>
                    </div>

                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <i className='ki-duotone ki-arrow-up fs-3 text-success me-2'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                        </i>
                        <div
                          className='fs-4 fw-bold'
                          data-kt-countup='true'
                          data-kt-countup-value='15000'
                          data-kt-countup-prefix='$'
                        >
                          {totalBudget}
                        </div>
                      </div>

                      <div className='fw-semibold fs-6 text-gray-400'>Budget Spent</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='separator'></div>
            <div className='d-flex overflow-x-auto overflow-y-hidden h-55px'>
              <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                <li className='nav-item cursor-pointer'>
                  <div
                    className={`nav-link text-active-primary me-6  
            ${property === 'Overview' ? ' active' : ''}`}
                    onClick={() => setProperty('Overview')}
                  >
                    Overview
                  </div>
                </li>
                {currentUser?.projectFeatures?.tasks && (
                  <li className='nav-item cursor-pointer'>
                    <div
                      className={`nav-link text-active-primary me-6  
                        ${property === 'task' ? ' active' : ''}`}
                      onClick={() => setProperty('task')}
                    >
                      Tasks
                    </div>
                  </li>
                )}
                {currentUser?.projectFeatures?.targets && (
                  <li className='nav-item cursor-pointer'>
                    <div
                      className={`nav-link text-active-primary me-6  
            ${property === 'Targets' ? ' active' : ''}`}
                      onClick={() => setProperty('Targets')}
                    >
                      Sprints
                    </div>
                  </li>
                )}
                {currentUser?.projectFeatures?.calendar && (
                  <li className='nav-item cursor-pointer'>
                    <div
                      className={`nav-link text-active-primary me-6  
            ${property === 'calendar' ? ' active' : ''}`}
                      onClick={() => setProperty('calendar')}
                    >
                      Calendar
                    </div>
                  </li>
                )}
                {currentUser?.projectFeatures?.products && currentUser?.app?.inventory && (
                  <li className='nav-item cursor-pointer'>
                    <div
                      className={`nav-link text-active-primary me-6  
            ${property === 'Products' ? ' active' : ''}`}
                      onClick={() => setProperty('Products')}
                    >
                      Products
                    </div>
                  </li>
                )}
                {currentUser?.projectFeatures?.eqtimesheet && (
                  <li className='nav-item cursor-pointer'>
                    <div
                      className={`nav-link text-active-primary me-6 whitespace-nowrap  
            ${property === 'eqtimesheet' ? ' active' : ''}`}
                      onClick={() => setProperty('eqtimesheet')}
                    >
                      EQ Timesheet
                    </div>
                  </li>
                )}
                {currentUser?.projectFeatures?.manpower && (
                  <li className='nav-item cursor-pointer'>
                    <div
                      className={`nav-link text-active-primary me-6  
            ${property === 'Manpower' ? ' active' : ''}`}
                      onClick={() => setProperty('Manpower')}
                    >
                      Manpower
                    </div>
                  </li>
                )}
                {currentUser?.projectFeatures?.timesheet && (
                  <li className='nav-item cursor-pointer'>
                    <div
                      className={`nav-link text-active-primary me-6 whitespace-nowrap  
            ${property === 'timesheet' ? ' active' : ''}`}
                      onClick={() => setProperty('timesheet')}
                    >
                      Payroll Timesheet
                    </div>
                  </li>
                )}
                {currentUser?.projectFeatures?.budget && (
                  <li className='nav-item cursor-pointer'>
                    <div
                      className={`nav-link text-active-primary me-6  
            ${property === 'Budget' ? ' active' : ''}`}
                      onClick={() => setProperty('Budget')}
                    >
                      Budget
                    </div>
                  </li>
                )}

                {/* {currentUser?.projectFeatures?.users && (
                  <li className='nav-item cursor-pointer'>
                    <div
                      className={`nav-link text-active-primary me-6  
            ${property === 'Users' ? ' active' : ''}`}
                      onClick={() => setProperty('Users')}
                    >
                      Users
                    </div>
                  </li>
                )} */}
                {currentUser?.projectFeatures?.files && (
                  <li className='nav-item cursor-pointer'>
                    <div
                      className={`nav-link text-active-primary me-6  
            ${property === 'Files' ? ' active' : ''}`}
                      onClick={() => setProperty('Files')}
                    >
                      Files
                    </div>
                  </li>
                )}
                {currentUser?.projectFeatures?.purchaserequest && (
                  <li className='nav-item cursor-pointer'>
                    <div
                      className={`nav-link text-active-primary me-6 whitespace-nowrap  
            ${property === 'purchaserequest' ? 'active' : ''}`}
                      onClick={() => setProperty('purchaserequest')}
                    >
                      Purchase Request
                    </div>
                  </li>
                )}
                {currentUser?.projectFeatures?.order && (
                  <li className='nav-item cursor-pointer'>
                    <div
                      className={`nav-link text-active-primary me-6  
            ${property === 'order' ? 'active' : ''}`}
                      onClick={() => setProperty('order')}
                    >
                      Order
                    </div>
                  </li>
                )}
                {currentUser?.projectFeatures?.transactions && (
                  <li className='nav-item cursor-pointer'>
                    <div
                      className={`nav-link text-active-primary me-6  
            ${property === 'transactions' ? ' active' : ''}`}
                      onClick={() => setProperty('transactions')}
                    >
                      Transactions
                    </div>
                  </li>
                )}
                {currentUser?.projectFeatures?.statement && (
                  <li className='nav-item cursor-pointer'>
                    <div
                      className={`nav-link text-active-primary me-6  
            ${property === '   Statement' ? ' active' : ''}`}
                      onClick={() => setProperty('Statement')}
                    >
                      Statement
                    </div>
                  </li>
                )}
                <li className='nav-item cursor-pointer'>
                  <div
                    className={`nav-link text-active-primary me-6  
            ${property === '   Settings' ? ' active' : ''}`}
                    onClick={() => setProperty('Settings')}
                  >
                    Settings
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {property === 'Overview' && <Overview setProperty={setProperty} team={data?.team} />}
        {property === 'task' && <TaskPage tasks={target} />}
        {property === 'Targets' && <TargetPage task={task} getTargets={getTargets} />}
        {property === 'calendar' && <TargetCalendar />}
        {property === 'Files' && <FilesPage />}
        {property === 'Budget' && <Budget />}
        {property === 'Users' && <Users users={data?.assignedAgents} />}
        {property === 'Products' && currentUser?.app?.inventory && (
          <Products products={data?.items} />
        )}
        {property === 'Manpower' && <Manpower team={data?.team} />}
        {property === 'timesheet' && <TimeSheet team={data?.team} />}
        {property === 'eqtimesheet' && <EQTimesheet />}
        {property === 'purchaserequest' && <PurchaseRequestList />}
        {property === 'order' && <OrderTable isSalesOrder={isSalesOrder} />}
        {property === 'transactions' && <TransactionTable />}
      </div>

      <div class='modal fade' id='kt_modal_new_target' tabindex='-1' aria-hidden='true'>
        <AddTargetPopUp getTargets={getTargets} />
      </div>
    </div>
  )
}
export default ProjectDetails
