import React, {useEffect} from 'react'
import {useState} from 'react'
import 'quill/dist/quill.snow.css'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'
import {
  addTagToOrg,
  getStockAccounts,
  getTagsByOrgId,
  productImage,
} from '../../Inventory/Core/_requests'
import AccountsInformation from '../../Inventory/Components/AccountsInformation'
import Media from '../../Inventory/Components/Media'
import Shipping from '../../Inventory/Components/Shipping'
import Thumbnail from '../../Inventory/Components/Thumbnail'
import {postParts} from '../core/_requests'
import Select from 'react-select'
import {KTSVG} from '../../../../_metronic/helpers'

export default function AddParts() {
  const {currentUser} = useAuth()
  const [name, setName] = useState('')
  const [tagList, setTagList] = useState([])
  const [loading, setLoading] = useState(false)
  const [previewImage, setPreviewImage] = useState(null) // Thumbnail
  const [thumbnail, setThumbnail] = useState(null)
  const [tags, setTags] = useState([])
  const [productName, setProductName] = useState('') //General
  const [productDescription, setProductDescription] = useState('')
  const [media, setMedia] = useState([]) //Media
  const [sku, setSku] = useState('')
  const [description, setDescription] = useState('')
  const [weight, setWeight] = useState('')
  const [width, setWidth] = useState('')
  const [height, setHeight] = useState('')
  const [length, setLength] = useState('')
  const [selectedImage, setSelectedImage] = useState(null)
  const [mediaName, setMediaName] = useState([])
  const [salesInfo, setSalesInfo] = useState(true)
  const [purchaseInfo, setPurchaseInfo] = useState(true)
  const [inventoryInfo, setInventoryInfo] = useState(true)
  const [salesAccount, setSalesAccount] = useState('')
  const [purchaseAccount, setPurchaseAccount] = useState('')
  const [inventoryAccount, setInventoryAccount] = useState('')
  const [inventoryAccounts, setInventoryAccounts] = useState([])
  const [manufacturer, setManufacturer] = useState('')
  const [brand, setBrand] = useState('')
  const [upc, setUpc] = useState('')
  const [ean, setEan] = useState('')
  const [mpn, setMpn] = useState('')
  const [isbn, setIsbn] = useState('')
  const [unit, setUnit] = useState('')
  const [costPrice, setCostPrice] = useState('')
  const [sellingPrice, setSellingPrice] = useState('')
  const [openingStock, setOpeningStock] = useState('')
  const [openingStockRate, setOpeningStockRate] = useState('')
  const [reorderPoint, setReorderPoint] = useState('')
  const [mrq, setMrq] = useState('')
  const [mrqCheckbox, setMrqCheckbox] = useState(false)

  const navigate = useNavigate()

  const fetchInventoryAccounts = async () => {
    const res = await getStockAccounts(currentUser?.organization)
    const data = res.map((item) => {
      return {
        value: item._id,
        label: item.accountName,
      }
    })
    setInventoryAccounts(data)
  }

  const fetchTags = async () => {
    const res = await getTagsByOrgId(currentUser?.organization)
    const data = res.map((tag) => ({value: tag, label: tag}))
    setTagList(data)
  }

  const handleSubmitTag = () => {
    const data = {
      tags: name,
    }
    const res = addTagToOrg(data, currentUser?.organization)
    if (res) {
      setName('')
      setTagList([...tagList, {value: name, label: name}])
      const closeModalButton = document.getElementById('closeModalButton')
      closeModalButton.click()
    }
  }

  useEffect(() => {
    fetchInventoryAccounts()
    fetchTags()
  }, [])

  const handleCancel = () => {
    navigate(`/parts/`)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (sellingPrice === '') {
      alert('Please enter selling price')
      return
    }
    if (costPrice === '') {
      alert('Please enter cost price')
      return
    }
    if (productName === '') {
      alert('Please enter product name')
      return
    }
    if (sku === '') {
      alert('Please enter SKU')
      return
    }
    if (unit === '') {
      alert('Please select unit')
      return
    }
    console.log('Form submitted!')

    setLoading(true)

    const imageData = new FormData()
    const productPic = thumbnail
    imageData.append('name', productPic)
    imageData.append('image', selectedImage)

    const mediaData = new FormData()
    media.forEach((file, index) => {
      const currentMediaName = mediaName[index]
      mediaData.append('name', currentMediaName)
      mediaData.append('media', file)
    })

    const formData = {
      thumbnail,
      tags,
      productName,
      productDescription,
      mediaName,
      price: sellingPrice,
      sku,
      inWarehouseQuantity: openingStock || 0,
      length,
      width,
      height,
      weight,
      description,
      salesInfo,
      purchaseInfo,
      salesAccount,
      purchaseAccount,
      manufacturer,
      brand,
      upc,
      ean,
      mpn,
      isbn,
      costPrice,
      openingStock,
      openingStockRate,
      reorderPoint,
      unit,
      inventoryInfo,
      inventoryAccount,
      mrq,
      mrqCheckbox,
    }
    console.log('formData', formData)
    if (selectedImage) {
      await productImage(imageData).then(async (res) => {
        // await productMedia(mediaData).then(async (req) => {
        const data = {
          ...formData,
          organization: currentUser?.organization,
          company: currentUser?.company,
        }
        await postParts(data).then((response) => {
          navigate(`/parts/`)
        })
        // })
      })
    } else {
      const data = {
        ...formData,
        organization: currentUser?.organization,
        company: currentUser?.company,
      }
      await postParts(data).then((response) => {
        navigate(`/parts/`)
      })
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className='container-fluid bg-gray-50 p-4'>
        <div className='row my-4 '>
          <div className='col-xl-4 space-y-6'>
            <Thumbnail
              previewImage={previewImage}
              setPreviewImage={setPreviewImage}
              thumbnail={thumbnail}
              setThumbnail={setThumbnail}
              selectedImage={selectedImage}
              setSelectedImage={setSelectedImage}
            />
            <div className='card card-flush py-4 d-flex flex-column'>
              <div className='card-body p-0 px-2 pb-2'>
                <label className='form-label d-block'>Tags</label>
                <Select
                  className='form-control mb-2'
                  isMulti
                  options={tagList}
                  value={tags.map((tag) => tagList.find((t) => t.value === tag))}
                  onChange={(selectedOptions) =>
                    setTags(selectedOptions.map((option) => option.value))
                  }
                />

                <div className='text-muted fs-7'>Add tags to a product.</div>
                <div
                  className='card-toolbar me-5'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_1'
                >
                  <a className='btn btn-sm btn-light-warning mb-10'>
                    <i className='ki-duotone ki-plus fs-2'></i>Create New Tag
                  </a>
                </div>
                <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
                  <div className='modal-dialog'>
                    <div className='modal-content'>
                      <div className='modal-header'>
                        <h5 className='modal-title'>Add New Tag</h5>
                        <div
                          className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                          data-bs-dismiss='modal'
                          aria-label='Close'
                        >
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr061.svg'
                            className='svg-icon svg-icon-2x'
                          />
                        </div>
                      </div>

                      <div className='modal-body'>
                        <form>
                          <div className='row rowwidthclient'>
                            <div className='form-group mb-3 col-md-12'>
                              <label>Tag Name</label>
                              <input
                                type='text'
                                name='name'
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className='form-control'
                              />
                            </div>
                          </div>
                        </form>
                      </div>

                      <div className='modal-footer'>
                        <button
                          type='button'
                          className='btn btn-light'
                          data-bs-dismiss='modal'
                          id='closeModalButton'
                        >
                          Close
                        </button>
                        <button type='button' className='btn btn-primary' onClick={handleSubmitTag}>
                          Save changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-xl-8 mt-4'>
            <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
              <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
                <li className='nav-item'>
                  <a
                    className='nav-link text-active-primary pb-4 active'
                    data-bs-toggle='tab'
                    href='#kt_ecommerce_add_product_general'
                  >
                    Details
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className='nav-link text-active-primary pb-4'
                    data-bs-toggle='tab'
                    href='#kt_ecommerce_add_product_advan'
                  >
                    Advanced
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className='nav-link text-active-primary pb-4'
                    data-bs-toggle='tab'
                    href='#kt_ecommerce_add_product_accounts'
                  >
                    Accounts
                  </a>
                </li>
              </ul>
              <div className='tab-content'>
                <div
                  className='tab-pane fade show active'
                  id='kt_ecommerce_add_product_general'
                  role='tab-panel'
                >
                  <div className='d-flex flex-column gap-7 gap-lg-10'>
                    <div className='card card-flush py-4'>
                      <div className='card-header'>
                        <div className='card-title'>
                          <h2>Parts Details</h2>
                        </div>
                      </div>

                      <div className='card-body pt-0'>
                        <div className='mb-10 fv-row'>
                          <label className='required form-label'>Parts Name</label>

                          <input
                            type='text'
                            name='name'
                            className='form-control mb-2'
                            placeholder='Item name'
                            value={productName}
                            onChange={(e) => setProductName(e.target.value)}
                          />

                          <div className='text-muted fs-7'>
                            An Item name is required and recommended to be unique.
                          </div>
                        </div>

                        <div className='mb-10 fv-row'>
                          <label className='form-label'>Description</label>
                          <div className='w-full rounded-lg h-full'>
                            <textarea
                              name='description'
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                              placeholder='type your text here..'
                              rows={4}
                              cols={50}
                              className='w-full rounded border px-4 py-2'
                            ></textarea>
                          </div>

                          <div className='text-muted fs-7'>
                            Set a description to the item for better visibility.
                          </div>
                        </div>

                        <div className='mb-10 fv-row'>
                          <label className='required form-label'>SKU</label>

                          <input
                            type='text'
                            name='sku'
                            className='form-control mb-2'
                            placeholder='SKU Number'
                            value={sku}
                            onChange={(e) => setSku(e.target.value)}
                          />

                          <div className='text-muted fs-7'>Enter the item SKU.</div>
                        </div>

                        <div className='mb-10 w-50'>
                          <label className='form-label required'>Unit</label>
                          <select
                            className='form-select form-control-solid'
                            id='unit'
                            name='unit'
                            value={unit}
                            onChange={(e) => setUnit(e.target.value)}
                          >
                            <option value=''>Select Unit</option>
                            {currentUser?.units?.map((unit) => (
                              <option value={unit.name}>{unit.name}</option>
                            ))}
                          </select>
                        </div>

                        <div className='mb-5 fv-row form-check form-switch'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='mrqCheckbox'
                            checked={mrqCheckbox}
                            onChange={(e) => setMrqCheckbox(e.target.checked)}
                          />
                          <label className='form-check-label' htmlFor='mrqCheckbox'>
                            Enable MRQ
                          </label>
                        </div>

                        <div className='mb-10 fv-row'>
                          <label className='form-label'>MRQ</label>
                          <input
                            type='number'
                            name='mrq'
                            className='form-control mb-2'
                            placeholder='Minimum Required Quantity'
                            value={mrq}
                            onChange={(e) => setMrq(e.target.value)}
                            disabled={!mrqCheckbox}
                          />

                          <div className='text-muted fs-7'>Enter the item MRQ.</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='tab-pane fade' id='kt_ecommerce_add_product_advan' role='tab-panel'>
                  <div className='d-flex flex-column gap-7 gap-lg-10'>
                    <Media
                      media={media}
                      setMedia={setMedia}
                      mediaName={mediaName}
                      setMediaName={setMediaName}
                    />

                    <div className='card card-flush py-4'>
                      <div className='card-header'>
                        <div className='card-title'>
                          <h2>Other Details</h2>
                        </div>
                      </div>

                      <div className='card-body pt-0'>
                        <div className='mb-10'>
                          <label className='form-label'>Manufacturer</label>

                          <input
                            type='text'
                            className='form-control mb-2'
                            name='meta_title'
                            placeholder='Enter manufacturer name'
                            value={manufacturer}
                            onChange={(e) => setManufacturer(e.target.value)}
                          />
                        </div>
                        <div className='mb-10'>
                          <label className='form-label'>Brand</label>

                          <input
                            type='text'
                            className='form-control mb-2'
                            name='meta_title'
                            placeholder='Enter brand name'
                            value={brand}
                            onChange={(e) => setBrand(e.target.value)}
                          />
                        </div>
                        <div className='mb-10'>
                          <label className='form-label'>UPC</label>

                          <input
                            type='text'
                            className='form-control mb-2'
                            name='meta_title'
                            placeholder='Enter UPC code'
                            value={upc}
                            onChange={(e) => setUpc(e.target.value)}
                          />
                        </div>
                        <div className='mb-10'>
                          <label className='form-label'>EAN</label>

                          <input
                            type='text'
                            className='form-control mb-2'
                            name='meta_title'
                            placeholder='Enter EAN code'
                            value={ean}
                            onChange={(e) => setEan(e.target.value)}
                          />
                        </div>
                        <div className='mb-10'>
                          <label className='form-label'>MPN</label>

                          <input
                            type='text'
                            className='form-control mb-2'
                            name='meta_title'
                            placeholder='Enter MPN code'
                            value={mpn}
                            onChange={(e) => setMpn(e.target.value)}
                          />
                        </div>
                        <div className='mb-10'>
                          <label className='form-label'>ISBN</label>

                          <input
                            type='text'
                            className='form-control mb-2'
                            name='meta_title'
                            placeholder='Enter ISBN code'
                            value={isbn}
                            onChange={(e) => setIsbn(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>

                    <Shipping
                      weight={weight}
                      setWeight={setWeight}
                      width={width}
                      setWidth={setWidth}
                      height={height}
                      setHeight={setHeight}
                      length={length}
                      setLength={setLength}
                    />
                  </div>
                </div>

                <div
                  className='tab-pane fade'
                  id='kt_ecommerce_add_product_accounts'
                  role='tab-panel'
                >
                  <div className='d-flex flex-column gap-7 gap-lg-10'>
                    <AccountsInformation
                      sellingPrice={sellingPrice}
                      setSellingPrice={setSellingPrice}
                      costPrice={costPrice}
                      setCostPrice={setCostPrice}
                      salesInfo={salesInfo}
                      setSalesInfo={setSalesInfo}
                      purchaseInfo={purchaseInfo}
                      setPurchaseInfo={setPurchaseInfo}
                      salesAccount={salesAccount}
                      setSalesAccount={setSalesAccount}
                      purchaseAccount={purchaseAccount}
                      setPurchaseAccount={setPurchaseAccount}
                    />
                    <div className='card card-flush py-4'>
                      <div className='card-header'>
                        <div className='card-title'>
                          <input
                            type='checkbox'
                            checked={inventoryInfo}
                            className='form-check-input w-20px h-20px me-4'
                            onChange={(e) => {
                              setInventoryInfo(e.target.checked)
                              setInventoryAccount('')
                            }}
                          />
                          <h2>Inventory Information</h2>
                        </div>
                      </div>

                      <div className='card-body pt-0'>
                        <div className='mb-10 fv-row'>
                          <label className='required form-label'>Account</label>

                          <select
                            name='accountType'
                            onChange={(e) => setInventoryAccount(e.target.value)}
                            className='form-control form-control-solid w-75'
                            disabled={!inventoryInfo}
                            value={inventoryAccount}
                          >
                            <option value=''>Select an account</option>
                            <optgroup label='Stock'>
                              {inventoryAccounts?.map((item) => (
                                <option key={item.value} value={item.value}>
                                  {item.label}
                                </option>
                              ))}
                            </optgroup>
                          </select>
                        </div>
                        <div className='mb-10 fv-row'>
                          <label className='form-label'>Opening Stock</label>
                          <input
                            type='number'
                            name='quantity'
                            min='0'
                            className='form-control mb-2 form-control-solid w-75'
                            placeholder=''
                            value={openingStock}
                            onChange={(e) => setOpeningStock(e.target.value)}
                          />
                        </div>
                        <div className='mb-10 fv-row'>
                          <label className='form-label'>Opening Stock Rate Per Unit</label>
                          <input
                            type='number'
                            name='quantity'
                            min='0'
                            className='form-control mb-2 form-control-solid w-75'
                            placeholder=''
                            value={openingStockRate}
                            onChange={(e) => setOpeningStockRate(e.target.value)}
                          />
                        </div>
                        <div className='mb-10 fv-row'>
                          <label className='form-label'>Reorder Point</label>
                          <input
                            type='number'
                            name='quantity'
                            min='0'
                            className='form-control mb-2 form-control-solid w-75'
                            placeholder=''
                            value={reorderPoint}
                            onChange={(e) => setReorderPoint(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='d-flex justify-content-end'>
                <button
                  id='kt_ecommerce_add_product_cancel'
                  className='btn btn-light me-5'
                  onClick={handleCancel}
                >
                  Cancel
                </button>

                <button
                  type='submit'
                  id='kt_ecommerce_add_product_submit'
                  className='btn btn-primary'
                  disabled={loading}
                >
                  <span className='indicator-label'>Save Changes</span>
                  <span className='indicator-progress'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}
