import React, {useContext, useEffect, useState} from 'react'
import {KTIcon, KTSVG} from '../../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import Select from 'react-select'
import {deleteAccount, getAccounts, updateActivationStatus} from '../core/_requests'
import Swal from 'sweetalert2'
import {FilterContext} from '../AccountWrapper'
import {useAuth} from '../../../modules/auth'
import {useSelector, useDispatch} from 'react-redux'
import {setChartOfAccountantData} from '../../../redux/accounts/accountant/chartOfAccountant/chartOfAccountantSlice'
import {RiArrowDropDownLine} from 'react-icons/ri'
import {CiLock} from 'react-icons/ci'

const accountTypeOrder = [
  'otherasset',
  'othercurrentasset',
  'cash',
  'bank',
  'fixedasset',
  'stock',
  'paymentclearing',
  'othercurrentliability',
  'creditcard',
  'longtermliability',
  'otherliability',
  'equity',
  'income',
  'otherincome',
  'expense',
  'costofgoodssold',
  'otherexpense',
]

const accountTypeLabels = {
  otherasset: 'Other Assets',
  othercurrentasset: 'Other Current Assets',
  cash: 'Cash',
  bank: 'Bank',
  fixedasset: 'Fixed Assets',
  stock: 'Stock',
  paymentclearing: 'Payment Clearing',
  othercurrentliability: 'Other Current Liabilities',
  creditcard: 'Credit Card',
  longtermliability: 'Long Term Liabilities',
  otherliability: 'Other Liabilities',
  equity: 'Equity',
  income: 'Income',
  otherincome: 'Other Income',
  expense: 'Expenses',
  costofgoodssold: 'Cost of Goods Sold',
  otherexpense: 'Other Expenses',
}

const Accountant = () => {
  const {currentUser} = useAuth()
  const [permissions, setPermissions] = useState<any>({})
  // const [tableData, setTableData] = useState([])
  const [deleteId, setDeleteId] = useState('')
  const [dropdown, setDropdown] = useState([])
  const {filter, setFilter} = useContext(FilterContext)
  const [selectedAccountType, setSelectedAccountType] = useState(filter.previousSelectedAccountType)
  const [searchTerm, setSearchTerm] = useState('')

  const dispatch = useDispatch()
  const tableData = useSelector(
    (state: any) => state.account.chartOfAccountant.chartOfAccountantData
  )

  const handleClick = (index) => {
    const newDropdownState = dropdown.map((_, i) => (i === index ? !dropdown[i] : false))
    setDropdown(newDropdownState)
  }

  const filteredData = tableData.filter((account) =>
    account.accountName.toLowerCase().includes(searchTerm.toLowerCase())
  )

  useEffect(() => {
    if (currentUser) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const [accountTypes] = useState([
    {
      value: 'active',
      label: 'Active Accounts',
    },
    {
      value: 'inactive',
      label: 'Inactive Accounts',
    },
    {
      value: 'asset',
      label: 'Asset Accounts',
    },
    {
      value: 'liability',
      label: 'Liability Accounts',
    },
    {
      value: 'equity',
      label: 'Equity Accounts',
    },
    {
      value: 'income',
      label: 'Income Accounts',
    },
    {
      value: 'expense',
      label: 'Expense Accounts',
    },
  ])

  const fetchData = async () => {
    if (filter.previousSelectedAccountType) {
      const res = await getAccounts(
        filter.previousSelectedAccountType?.value,
        currentUser?.organization
      )
      // setTableData(res)
      dispatch(setChartOfAccountantData(res))
      setDropdown(Array(res?.length)?.fill(false))
    } else fetchAllData()
  }

  const fetchAllData = async () => {
    const res = await getAccounts('0', currentUser?.organization)
    // setTableData(res)
    dispatch(setChartOfAccountantData(res))
    setDropdown(Array(res?.length)?.fill(false))
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleAccountTypeChange = async (e) => {
    setSelectedAccountType(e)
    setFilter({previousSelectedAccountType: e})
    if (e === null) {
      fetchAllData()
      return
    }
    const res = await getAccounts(e?.value, currentUser?.organization)
    // setTableData(res)
    dispatch(setChartOfAccountantData(res))
    setDropdown(Array(res?.length)?.fill(false))
  }

  const handleShowModal = (index) => {
    Swal.fire({
      title: tableData[index]?.accountName,
      html: `<div class="row table-responsive">
      <div class="col-12">
        <p><b>Account Name:</b> ${tableData[index]?.accountName}</p>
        <p><b>Account Code:</b> ${tableData[index]?.accountCode}</p>
        <p><b>Account Type:</b> ${tableData[index]?.accountType}</p>
        <p><b>Account Description:</b> ${tableData[index]?.description}</p>
        <p><b>Amount:</b> ${tableData[index]?.amount}</p>
      </div>
    </div>`,
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: 'Close',
    })
  }

  const handleActivation = async (id, status) => {
    const res = await updateActivationStatus(id, {status: !status})
    if (res) {
      fetchData()
    }
  }

  const handleDelete = async () => {
    await deleteAccount(deleteId)
      .then((res) => {
        if (res) {
          const closeModal = document.getElementById('closeModalButtondelete')
          closeModal?.click()
          const index = tableData.findIndex((item) => item?._id === deleteId)
          const temp = [...tableData]
          temp.splice(index, 1)
          // setTableData(temp)
          dispatch(setChartOfAccountantData(res))
          setDropdown(Array(temp?.length)?.fill(false))
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const groupedAccounts = filteredData.reduce((acc, account) => {
    if (!acc[account.accountType]) {
      acc[account.accountType] = []
    }
    acc[account.accountType].push(account)
    return acc
  }, {})

  return (
    <div className='card card-flush'>
      <div className='card-header border-0 pt-5 d-flex justify-between'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Chart of Accounts</span>
        </h3>

        {/* <div className='mr-auto ml-4 flex items-center'>
          <input
            type='text'
            className='form-control form-control-solid w-250px ps-12'
            placeholder='Search account name'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <span className='position-absolute top-50 translate-middle-y start-0 ms-4'>
            <KTIcon iconName='magnifier' className='fs-4' />
          </span>
        </div> */}

        {/* make the magnifier icon fixed on the input field */}
        <div className='mr-auto ml-4 flex items-center'>
          <div className='position-relative w-250px'>
            <input
              type='text'
              className='form-control form-control-solid ps-10'
              placeholder='Search account name'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <span className='position-absolute top-50 translate-middle-y start-0 ms-4'>
              <KTIcon iconName='magnifier' className='fs-4' />
            </span>
          </div>
        </div>

        <div className='d-flex gap-4 align-items-center'>
          <div
            className='btn btn-primary me-3'
            data-kt-menu-trigger='hover'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTIcon iconName='filter' className='fs-2' />
            Filter
            <div
              className='menu menu-sub menu-sub-dropdown w-400px w-md-400px p-5'
              data-kt-menu='true'
              style={{zIndex: 1000000}}
            >
              {/* begin::Header */}
              <div className='px-7 py-5'>
                <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
              </div>
              {/* end::Header */}

              {/* begin::Separator */}
              <div className='separator border-gray-200'></div>
              {/* end::Separator */}

              {/* begin::Content */}
              <div className='px-7 py-5' data-kt-user-table-filter='form'>
                {/* begin::Input group */}
                <div className='mb-10 d-flex flex-row align-content-center justify-content-center'>
                  <label className='form-label fs-6 fw-bold text-gray-700 mb-3 me-5 mt-5'>
                    Account type:
                  </label>

                  <Select
                    name='accountType'
                    aria-label='Select an Account Type'
                    placeholder='Select Account Type'
                    options={accountTypes}
                    defaultValue={{value: '', label: 'All Accounts'}}
                    isSearchable={true}
                    className='form-control form-control-solid'
                    onChange={handleAccountTypeChange}
                    value={selectedAccountType}
                  />
                </div>

                {/* end::Input group */}

                {/* begin::Actions */}
                <div className='d-flex justify-content-end'>
                  <button
                    type='button'
                    // disabled={isLoading}
                    // onClick={filterData}
                    className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                    data-kt-menu-dismiss='true'
                    data-kt-user-table-filter='reset'
                    onClick={() => {
                      setSelectedAccountType(null)
                      setFilter({previousSelectedAccountType: null})
                      handleAccountTypeChange(null)
                    }}
                  >
                    RESET
                  </button>
                </div>
              </div>
              {/* end::Actions */}
            </div>
            {/* end::Content */}
          </div>

          {(currentUser?.profileType === 'superadmin' || permissions?.accountant?.create) && (
            <div className='card-toolbar'>
              <Link to={'/account/chartofaccounts/add'} className='btn btn-sm btn-light-success'>
                <KTIcon iconName='plus' className='fs-2' />
                New Account
              </Link>
            </div>
          )}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4 table-row-bordered'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-25px rounded-start'></th>
                <th className='min-w-150px'>Account Name</th>
                <th className='min-w-150px'>Account Code</th>
                <th className='min-w-150px'>Closing Balance</th>
                <th className='min-w-150px'>Account Type</th>
                {(currentUser?.profileType === 'superadmin' || permissions?.accountant?.update) && (
                  <th className='min-w-150px text-center'>Action</th>
                )}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {Object.entries(groupedAccounts).map(([accountType, accounts]: any) => (
                <React.Fragment key={accountType}>
                  <tr className='bg-secondary'>
                    <td colSpan={6} className='fw-bold text-uppercase fs-5 ps-8'>
                      {accountTypeLabels[accountType]}
                    </td>
                  </tr>
                  {accounts.map((item, index) => (
                    <tr key={item?._id}>
                      <td className='ps-4 text-hover-primary'>
                        {item?.fixed ? <CiLock className='fs-2 text-muted' /> : ''}
                      </td>
                      <td className='capitalize cursor-pointer fw-bold text-hover-primary mb-1'>
                        <Link to={`/account/chartofaccounts/${item?._id}`}>
                          {item?.accountName}
                        </Link>
                      </td>
                      <td>{item?.accountCode}</td>
                      <td>
                        {item?.amount?.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                        })}
                      </td>
                      <td>{item?.accountType}</td>
                      {(currentUser?.profileType === 'superadmin' ||
                        permissions?.accountant?.create) && (
                        <td className='relative'>
                          {!item?.fixed && (
                            <>
                              <div className='d-flex justify-content-center flex-shrink-0'>
                                <button
                                  onClick={() => handleClick(index)}
                                  className='bg-gray-100 hover:bg-lime-50 px-6 py-2 flex items-center rounded self-end'
                                >
                                  Actions <RiArrowDropDownLine />
                                </button>
                              </div>
                              {dropdown[index] && (
                                <div className='absolute flex flex-col top-16 right-[0px] z-50 bg-white w-40 shadow-[0_16px_16px_6px_rgba(128,128,128,0.1)] rounded p-3 space-y-2'>
                                  <Link to={`/account/chartofaccounts/edit/${item?._id}`}>
                                    <button className='hover:bg-lime-50 p-2 text-start rounded'>
                                      Edit
                                    </button>
                                  </Link>
                                  <button
                                    className='hover:bg-lime-50 p-2 text-start rounded'
                                    onClick={() => handleActivation(item._id, item?.status)}
                                  >
                                    {item?.status ? 'Deactivate' : 'Activate'}
                                  </button>
                                </div>
                              )}
                            </>
                          )}
                        </td>
                      )}
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </tbody>

            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}

      {/* Modal for add client */}
    </div>
  )
}

export default Accountant
