import React, {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import profile from '../../../../_metronic/assets/profile.png'
import {getEmployeesByEmployeeGroup} from '../core/_requests'

const EmployeeGroupDetail: React.FC = () => {
  const {id} = useParams()
  const [employeeData, setEmployeeData] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [employeeGroupName, setEmployeeGroupName] = useState<any>('')

  useEffect(() => {
    const getEmployeelist = async () => {
      try {
        setLoading(true)
        const res = await getEmployeesByEmployeeGroup(id)
        setEmployeeGroupName(res?.name)
        setEmployeeData(res?.employees)
      } catch (error) {
        console.error('Error fetching Customer list:', error)
      } finally {
        setLoading(false)
      }
    }

    if (id !== undefined) getEmployeelist()
  }, [id])

  return (
    <>
      <div className='card'>
        <span className=' card-label fw-bold fs-3 ps-15 pt-8 !capitalize'>{employeeGroupName}</span>
        <div className='card-body py-3 '>
          {loading ? (
            <div className='d-flex align-items-center justify-center'>
              <div className='loader'></div>
            </div>
          ) : (
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted bg-light'>
                    <th className='ps-4 min-w-200px rounded-start'>Employees</th>
                    <th className='min-w-125px'>Department</th>
                    <th className='min-w-125px'>Roles</th>
                    <th className='min-w-125px'>Employee Id</th>
                    <th className='min-w-150px'>Contract</th>
                    <th className='min-w-100px'>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {employeeData &&
                    employeeData.map((item, i) => (
                      <tr key={i}>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='symbol symbol-50px me-5'>
                              <img src={`${profile}`} className='img-fluid rounded-circle' alt='' />
                            </div>
                            <div className='d-flex justify-content-start flex-column'>
                              <Link
                                to={`view-employee/${item._id}`}
                                className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                              >
                                {item.firstName} {item.lastName}
                              </Link>
                            </div>
                          </div>
                        </td>
                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                          >
                            {item?.department?.name}
                          </a>
                        </td>
                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                          >
                            {item?.role}
                          </a>
                        </td>
                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                          >
                            {item.employeeId}
                          </a>
                        </td>
                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 capitalize'
                          >
                            {item.contractType}
                          </a>
                        </td>
                        <td>
                          <span className='badge badge-light-primary fs-7 fw-semibold'>
                            {item.employeeStatus}
                          </span>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default EmployeeGroupDetail
