import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'
// import { getDeals, getQuotes, getQuotesbyAgentId } from '../core/_requests';
import {KTIcon} from '../../../../_metronic/helpers'
import {getLeavesWithMonth} from '../core/_requests'
import {useSelector, useDispatch} from 'react-redux'
import {setLeaveManagementData} from '../../../redux/hrm/leaveManagement/leaveManagementSlice'

function EmployeeLeaveMainPage() {
  const {currentUser} = useAuth()
  const [permissions, setPermissions] = useState<any>({})
  // const [leaveList, setLeaveList] = useState<any>([])
  const dispatch = useDispatch()

  const leaveList = useSelector((state: any) => state.hrm.leaveManagement.leaveManagementData)

  useEffect(() => {
    if (currentUser) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const fetchleaveList = async () => {
    const res = await getLeavesWithMonth(currentUser?.organization)
    // setLeaveList(res)
    dispatch(setLeaveManagementData(res))
    console.log(res)
  }

  useEffect(() => {
    fetchleaveList()
  }, [])

  // function for formatting date to string of month and year
  // const formatDate = (date: any) => {
  //   const d = new Date(date)
  //   const month = d.toLocaleString('default', {month: 'long'})
  //   const year = d.getFullYear()
  //   return `${month} ${year}`
  // }

  return (
    <div className='card card-flush'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Employee Leaves</span>
        </h3>
        <div className=' d-flex flex-row card-title align-items-start'>
          <div className='d-flex gap-5 justify-between'>
            {/* Render the "Add Leave Type" button only if the current user is not an admin */}
            {(currentUser?.profileType === 'superadmin' ||
              permissions?.leavemanagement?.create) && (
              <div className='card-toolbar mr-5'>
                {/* Replace the link with the appropriate link for adding employee leave */}
                <Link to={'addLeaveType'} className='btn btn-sm btn-light-primary'>
                  <KTIcon iconName='plus' className='fs-2' />
                  Add Leave Type
                </Link>
              </div>
            )}

            {(currentUser?.profileType === 'superadmin' ||
              permissions?.leavemanagement?.create) && (
              <div className='card-toolbar'>
                {/* Replace the link with the appropriate link for adding employee leave */}
                <Link to={'add'} className='btn btn-sm btn-light-primary'>
                  <KTIcon iconName='plus' className='fs-2' />
                  Apply Leave
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4 table-row-bordered'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-50px rounded-start'>Month</th>
                <th className='min-w-150px text-center'>Total Leaves</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {leaveList?.map((item: any, index) => (
                <tr key={item?._id?.toString()}>
                  <td>
                    <Link
                      to={`${item?._id}`}
                      className='ps-4 text-dark fw-bold text-hover-primary d-block mb-1 fs-6 capitalize'
                    >
                      {item?._id}
                    </Link>
                  </td>
                  <td className='text-center'>
                    <Link
                      to={`${item?._id}`}
                      className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 capitalize'
                    >
                      {item?.count}
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}

      {/* Modal for add client */}
    </div>
  )
}

export default EmployeeLeaveMainPage
