import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  contacts: [],
  loading: true,
  fetch: true,
}

const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    setCRMContacts: (state, action) => {
      state.contacts = action.payload
      state.loading = false
      state.fetch = false
    },
    setCRMContactsLoading: (state, action) => {
      state.loading = action.payload
    },
    setCRMContactsFetch: (state) => {
      state.fetch = true
    },
  },
})

export const {setCRMContacts, setCRMContactsLoading, setCRMContactsFetch} = contactsSlice.actions
export default contactsSlice.reducer
