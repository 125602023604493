import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import './print.css'
import {MdOutlineMailOutline} from 'react-icons/md'
import {GoGlobe} from 'react-icons/go'
import {getDepartmentPayrollById} from '../core/_requests'

function DepartmentPayrollDetail({minFooter = true}) {
  const [data, setPayrollData] = useState(null)
  const {id} = useParams()
  const [imageSize, setImageSize] = useState(false)
  const [color, setColor] = useState('#cococo')

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await getDepartmentPayrollById(id)
        setPayrollData(res)
        setColor(res?.company?.procurementColor)
      } catch (error) {
        console.error('Axios error:', error)
        throw error
      }
    }

    getData()
  }, [id])

  const formatDate = (date) => {
    if (!date) return 'N/A'
    return new Date(date).toLocaleDateString('en-GB')
  }

  const handleOnLoad = (e) => {
    const img = e.target
    setImageSize(img.naturalWidth > img.naturalHeight)
  }

  const NUMBERS = new Map([
    [1e9, 'Billion'],
    [1e6, 'Million'],
    [1e3, 'Thousand'],
    [1e2, 'Hundred'],
    [90, 'Ninety'],
    [80, 'Eighty'],
    [70, 'Seventy'],
    [60, 'Sixty'],
    [50, 'Fifty'],
    [40, 'Forty'],
    [30, 'Thirty'],
    [20, 'Twenty'],
    [19, 'Nineteen'],
    [18, 'Eighteen'],
    [17, 'Seventeen'],
    [16, 'Sixteen'],
    [15, 'Fifteen'],
    [14, 'Fourteen'],
    [13, 'Thirteen'],
    [12, 'Twelve'],
    [11, 'Eleven'],
    [10, 'Ten'],
    [9, 'Nine'],
    [8, 'Eight'],
    [7, 'Seven'],
    [6, 'Six'],
    [5, 'Five'],
    [4, 'Four'],
    [3, 'Three'],
    [2, 'Two'],
    [1, 'One'],
    [0, 'Zero'],
  ])

  const numberToWords = (num) => {
    for (const [intValue, textValue] of NUMBERS as any) {
      if (num >= intValue) {
        const prefix = num >= 100 ? numberToWords(Math.trunc(num / intValue)) : ''
        const suffix = num % intValue > 0 ? numberToWords(num % intValue) : ''
        return `${prefix} ${textValue} ${suffix}`.trim()
      }
    }
    return ''
  }

  const convertSARAmountToWords = (amount: number) => {
    const [riyals, halalas] = amount?.toString()?.split('.')?.map(Number)
    const riyalsInWords = numberToWords(riyals)
    const halalasInWords = halalas ? numberToWords(halalas?.toString()?.padEnd(2, '0')) : ''

    return `${riyalsInWords} Riyals${halalasInWords ? ` and ${halalasInWords} Halalas` : ''} Only`
  }

  const PF = 'https://qf-assets.s3.me-central-1.amazonaws.com/'

  return (
    <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
      <div className='d-flex flex-column flex-column-fluid'>
        {/* <div id='kt_app_toolbar' className='app-toolbar mb-6'></div> */}
        <div className='app-content flex-column-fluid'>
          <div id='kt_app_content_container' className=' container-xxl print-content'>
            <div
              id='pdf-content'
              className='card !rounded-none mx-auto'
              style={{
                height: '297mm',
                width: '210mm',
              }}
            >
              <div className='card-body pt-8 pb-0'>
                <div className='mw-lg-1050px w-100'>
                  {/* Header */}
                  <div className='d-flex justify-content-between align-items-center flex-column flex-sm-row'>
                    <div className='text-sm-end'>
                      <div className={`d-block ${imageSize ? 'mw-275px' : 'mw-150px'} ms-sm-auto`}>
                        <img
                          alt='thumbnail'
                          src={PF + data?.company?.companyLogo}
                          className={imageSize ? 'w-100' : 'w-50'}
                          onLoad={handleOnLoad}
                        />
                      </div>
                    </div>
                    <h4 className='fw-bold text-gray-800 xl:fs-1 d-flex flex-column text-right header-text'>
                      <span
                        className='mb-1 text-right fs-1'
                        style={{
                          fontFamily: 'Scheherazade New',
                          fontOpticalSizing: 'auto',
                          fontStyle: 'normal',
                          wordSpacing: '10px',
                        }}
                      >
                        {data?.company?.arabicName}
                      </span>
                      <span className='mb-1 text-right fs-3 tracking-wider' style={{color: color}}>
                        {data?.company?.name}
                      </span>
                      <span className='fs-6 text-muted'>
                        VAT {data?.company?.vat} <span style={{color: color}}>|</span> C.R.{' '}
                        {data?.company?.cr}
                      </span>
                    </h4>
                  </div>
                  <div className='border-b-black border-b mx-9'></div>

                  {/* Payroll Details */}
                  <div className='fs-1 mt-8 uppercase font-bold text-gray-800'>
                    Department Payroll
                  </div>
                  <div className='pb-2 mt-8'>
                    <div className='d-flex flex-column gap-7 gap-md-10'>
                      <div
                        className='d-flex justify-content-between flex-column flex-sm-row fs-5'
                        style={{textTransform: 'uppercase'}}
                      >
                        <div className='mb-8'>
                          <div className='d-flex flex-column mb-8'>
                            <span className='fw-bold'>Salary Type</span>
                            <span className='fs-4'>{data?.type}</span>
                          </div>
                        </div>
                        <div className='mb-8'>
                          <div className='d-flex flex-column mb-8'>
                            <span className='fw-bold'>Pay Period</span>
                            <span className='fs-4'>
                              {formatDate(data?.startDate)} - {formatDate(data?.endDate)}
                            </span>
                          </div>
                        </div>
                        <div className='text-end mb-8'>
                          <div className='d-flex flex-column mb-8'>
                            <span className='fw-bold'>Month</span>
                            <span className='fs-4'>{data?.month}</span>
                          </div>
                          <div className='d-flex flex-column mb-8'>
                            <span className='fw-bold'>Pay Date</span>
                            <span className='fs-4'>{formatDate(data?.createdAt)}</span>
                          </div>
                        </div>
                      </div>

                      <div className='flex-grow-1'>
                        {/*begin::Table*/}
                        <div className='table-responsive'>
                          <table
                            className='table table-bordered m-0'
                            style={{
                              borderColor: '#020617',
                            }}
                          >
                            <thead>
                              <tr
                                className='fs-7 fw-bold uppercase'
                                style={{
                                  backgroundColor: color,
                                }}
                              >
                                <th className='min-w-25px text-white text-center pb-3 '>
                                  <span>
                                    <span className='arabic-font'>رقم سري</span>
                                    <br />
                                    S.No.
                                  </span>
                                </th>

                                <th className='min-w-175px text-white text-center pb-3 '>
                                  <span className='arabic-font'>اسم الموظف</span>
                                  <br />
                                  Employee Name
                                </th>

                                <th className='min-w-70px text-white text-center pb-3'>
                                  <span className='arabic-font'>دور الموظف</span>
                                  <br />
                                  Role
                                </th>
                                <th className='min-w-80px text-white text-center pb-3'>
                                  <span className='arabic-font'>الراتب الأساسي</span>
                                  <br />
                                  Basic Salary
                                </th>
                                <th className='min-w-80px text-white text-center pb-3'>
                                  <span className='arabic-font'>ساعات العمل الإضافي</span>
                                  <br />
                                  Overtime Hours
                                </th>

                                <th className='min-w-80px text-white text-center pb-3'>
                                  <span className='arabic-font'>المبلغ الإجمالي</span>
                                  <br />
                                  Amount
                                </th>
                              </tr>
                            </thead>
                            <tbody className='' style={{height: '250px'}}>
                              {data?.recordedTime?.map((item, index) => (
                                <tr key={index} className='fw-semibold text-black fs-8 text-center'>
                                  <td className='text-black'>{index + 1}</td>
                                  <td className='text-justify text-black'>{item?.employeeName}</td>
                                  <td className='text-black'>{item?.role}</td>
                                  <td className='uppercase text-black'>
                                    {item?.regular?.toLocaleString('en-US', {
                                      minimumFractionDigits: 2,
                                    })}
                                  </td>
                                  <td className='text-center text-black'>{item?.ot}</td>
                                  <td className='text-center text-black'>
                                    {item?.salary?.toLocaleString('en-US', {
                                      minimumFractionDigits: 2,
                                    })}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>

                      {/* Notes Section */}
                      {data?.notes && (
                        <div className='d-flex flex-column mb-8'>
                          <span className='fw-bold fs-6 mb-2'>Notes:</span>
                          <span className='fs-4'>{data.notes}</span>
                        </div>
                      )}

                      {/* <div className={`m-0 pt-8 px-10 py-5 `}>
                        <div className='d-flex  justify-content-between'>
                          <h6 className='mb-8 text-black text-center fw-bold fs-5'>
                            <span className='fs-6 arabic-font fw-normal'>أُعدت بواسطة</span>
                            <br />
                            Prepared By
                            {data?.agent &&
                              data?.agent?.signature &&
                              data?.agent?.signature !== '' && (
                                <img
                                  src={PF + data?.agent?.signature}
                                  alt='signature'
                                  className='w-80px h-80px mt-3'
                                />
                              )}
                          </h6>

                          <h6 className='mb-4 text-black relative text-center fw-bold fs-5'>
                            <span className='fs-6 arabic-font fw-normal'>اعتمدت بواسة</span>
                            <br />
                            Approved By
                            {data?.approval === 'accepted' &&
                              data?.company?.companySeal &&
                              data?.company?.companySeal !== '' && (
                                <img
                                  src={PF + data?.company?.companySeal}
                                  alt='company seal'
                                  className='w-80px mt-3 h-80px aboslute top-5 right-5 z-1'
                                />
                              )}
                            {data?.approval === 'accepted' &&
                              data?.company?.companySignature &&
                              data?.company?.companySignature !== '' && (
                                <img
                                  src={PF + data?.company?.companySignature}
                                  alt='company signature'
                                  className='w-80px h-80px absolute top-20 right-10 z-10'
                                />
                              )}
                          </h6>

                          <h6 className='mb-4 text-black text-center fs-5 fw-bold'>
                            <span className='fs-6 arabic-font fw-normal'>استلمت من قبل</span>
                            <br />
                            Received By
                          </h6>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>

              {/* Footer */}
              <div id='pdfFooter' className='card-footer text-center py-2 px-0 fw-bold mb-1'>
                <div className={`bg-[#515560] ${!minFooter && '-skew-x-12'} text-white`}>
                  <div className={`${!minFooter && 'skew-x-12'} py-1`}>
                    {!minFooter &&
                      `Mobile :${' '}
        ${data?.company?.mobileNumber} | `}
                    <MdOutlineMailOutline
                      style={{
                        color,
                      }}
                      className='inline mr-2'
                    />
                    {data?.company?.companyEmail}
                    <GoGlobe
                      className='inline mr-2 ml-4'
                      style={{
                        color,
                      }}
                    />
                    {data?.company?.webURL}
                  </div>
                </div>
                <div
                  className={`${!minFooter && '-skew-x-12'} text-white`}
                  style={{
                    backgroundColor: `${color}`,
                  }}
                >
                  <div className={`${!minFooter && 'skew-x-12'} py-1`}>
                    P.O. Box : {data?.company?.pOBox} | Address: {data?.company?.companyAddress}
                  </div>
                </div>
              </div>
              {/* <div className='d-flex justify-center mb-4'>
                <p className='fs-8 serif-font'>Page 1 of 1</p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DepartmentPayrollDetail
