import React from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useAuth} from '../../../modules/auth'
import {createParentAccount} from '../core/_requests'
import {useNavigate} from 'react-router-dom'
import {KTIcon} from '../../../../_metronic/helpers'

const accountTypes = [
  'otherasset',
  'othercurrentasset',
  'cash',
  'bank',
  'fixedasset',
  'stock',
  'paymentclearing',
  'othercurrentliability',
  'creditcard',
  'longtermliability',
  'otherliability',
  'equity',
  'income',
  'otherincome',
  'expense',
  'costofgoodssold',
  'otherexpense',
]

const AddParentAccount = () => {
  const {currentUser} = useAuth()
  const navigate = useNavigate()

  const initialValues = {
    accountName: '',
    accountType: '',
    accountCode: '',
    description: '',
  }

  const validationSchema = Yup.object().shape({
    accountName: Yup.string().required('Account Name is required'),
    accountType: Yup.string().required('Account Type is required').oneOf(accountTypes),
    accountCode: Yup.string().required('Account Code is required'),
    description: Yup.string(),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      try {
        const parentAccountData = {
          ...values,
          company: currentUser?.company,
          organization: currentUser?.organization,
        }
        await createParentAccount(parentAccountData).then(() => {
          navigate('/account/parentaccounts')
        })
      } catch (error) {
        console.error('Error creating parent account:', error)
      } finally {
        setSubmitting(false)
      }
    },
  })

  return (
    <div className='card'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Add Parent Account</span>
        </h3>
      </div>
      <form onSubmit={formik.handleSubmit} className='form'>
        <div className='card-body'>
          <div className='row mb-6'>
            <div className='col-lg-6 mb-4 mb-lg-0'>
              <div className='fv-row mb-0'>
                <label htmlFor='accountName' className='form-label fs-6 fw-bolder mb-3 required'>
                  Account Name
                </label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Enter Account Name'
                  {...formik.getFieldProps('accountName')}
                />
                {formik.touched.accountName && formik.errors.accountName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.accountName}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='fv-row mb-0'>
                <label htmlFor='accountType' className='form-label fs-6 fw-bolder mb-3 required'>
                  Account Type
                </label>
                <select
                  name='accountType'
                  className='form-select form-select-lg form-select-solid'
                  {...formik.getFieldProps('accountType')}
                >
                  <option value=''>Select Account Type</option>
                  <optgroup label='Assets'>
                    <option value={'otherasset'}>Other Asset</option>
                    <option value={'othercurrentasset'}>Other Current Asset</option>
                    <option value={'cash'}>Cash</option>
                    <option value={'bank'}>Bank</option>
                    <option value={'fixedasset'}>Fixed Asset</option>
                    <option value={'stock'}>Stock</option>
                    <option value={'paymentclearing'}>Payment Clearing</option>
                  </optgroup>
                  <optgroup label='Liability'>
                    <option value={'othercurrentliability'}>Other Current Liability</option>
                    <option value={'creditcard'}>Credit Card</option>
                    <option value={'longtermliability'}>Long term Liability</option>
                    <option value={'otherliability'}>Other Liability</option>
                  </optgroup>
                  <optgroup label='Equity'>
                    <option value={'equity'}>Equity</option>
                  </optgroup>
                  <optgroup label='Income'>
                    <option value={'income'}>Income</option>
                    <option value={'otherincome'}>Other Income</option>
                  </optgroup>
                  <optgroup label='Expense'>
                    <option value={'expense'}>Expense</option>
                    <option value={'costofgoodssold'}>Cost of Goods Sold</option>
                    <option value={'otherexpense'}>Other Expense</option>
                  </optgroup>
                </select>
                {formik.touched.accountType && formik.errors.accountType && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.accountType}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='row mb-6'>
            <div className='col-lg-6 mb-4 mb-lg-0'>
              <div className='fv-row mb-0'>
                <label htmlFor='accountCode' className='form-label fs-6 fw-bolder mb-3 required'>
                  Account Code
                </label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Enter Account Code'
                  {...formik.getFieldProps('accountCode')}
                />
              </div>
            </div>
          </div>
          <div className='row mb-6'>
            <div className='col-lg-12'>
              <div className='fv-row mb-0'>
                <label htmlFor='description' className='form-label fs-6 fw-bolder mb-3'>
                  Description
                </label>
                <textarea
                  className='form-control form-control-lg form-control-solid'
                  rows={3}
                  placeholder='Enter Description'
                  {...formik.getFieldProps('description')}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button
            type='button'
            className='btn btn-light btn-active-light-primary me-2'
            onClick={() => navigate('/account/parentaccounts')}
          >
            Cancel
          </button>
          <button type='submit' className='btn btn-primary' disabled={formik.isSubmitting}>
            {!formik.isSubmitting && (
              <span className='indicator-label'>
                Save Changes
                <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
              </span>
            )}
            {formik.isSubmitting && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export default AddParentAccount
