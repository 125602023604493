import React, {useEffect, useState} from 'react'
import Select from 'react-select'
import 'react-datepicker/dist/react-datepicker.css'
import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/themes/material_blue.css'
import 'flatpickr/dist/flatpickr.min.css'
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd'
import {useAuth} from '../../../modules/auth/core/Auth'
import {KTIcon, KTSVG} from '../../../../_metronic/helpers'
import {useNavigate, useParams} from 'react-router-dom'
import {
  addDeliveryMethod,
  revisedSalesOrder,
  getCustomer,
  getCustomerByStatusForAgent,
  getDeliveryMethods,
  getItemsByType,
  getSalesOrder,
  getSalesOrderDetailById,
  getStocksForSales,
} from '../Core/_requests'
import {useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../../redux/store'
import {setServices} from '../../../redux/inventory/products/servicesSlice'
import {setSalesStock} from '../../../redux/inventory/products/stockSlice'
import {getEmployeeByStatus} from '../../hrm/core/_requests'

function RevisedSalesOrder() {
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const salesStock = useSelector((state: RootState) => state.inventory.stock.salesStock)
  const servicesList = useSelector((state: RootState) => state.inventory.services.services)
  const salesOrderId = useParams().id
  const [orderId, setOrderId] = useState('')
  const [loading, setLoading] = useState(false)
  const [deliveryMethodOption, setDeliveryMethodOption] = useState([])
  const [name, setName] = useState('')
  const [permissions, setPermissions] = useState<any>({})
  const [customerList, setCustomerList] = useState<any>([])
  const [employeeList, setEmployeeList] = useState<any>([])
  const [productList, setProductList] = useState<any>([])
  const [selectedBillFrom, setselectedBillFrom] = useState(null)
  const [selectedBillTo, setselectedBillTo] = useState(null)
  const [salesOrderNumber, setSalesOrderNumber] = useState('')
  const [isEditingNotes, setIsEditingNotes] = useState(false)
  const [isEditingTerms, setIsEditingTerms] = useState(false)
  const [termsTextareaHeight, setTermsTextareaHeight] = useState('3rem')
  const [notesTextareaHeight, setNotesTextareaHeight] = useState('3rem')
  const [newItem, setNewItem] = useState<any>([])

  const [salesOrder, setSalesOrder] = useState({
    salesOrderDate: new Date(),
    id: '',
    employee: '',
    customer: '',
    reference: '',
    expectedShipmentDate: new Date(),
    paymentTerms: '',
    deliveryMethod: '',
    subtotal: 0,
    tax: 0,
    total: 0,
    notes: ' ',
    termsNCondition: ' ',
    document: '',
  })
  const [items, setItems] = useState([
    {
      itemId: '',
      price: '',
      quantity: 0,
      discount: 0,
      amount: '',
      selectedOption: {
        value: '',
        label: '',
        inWarehouseQuantity: 0,
      },
      type: 'product',
      productName: '',
    },
  ])

  const termsOption = [
    {label: 'Net 15', value: 'net15'},
    {label: 'Net 30', value: 'net30'},
    {label: 'Net 45', value: 'net45'},
    {label: 'Net 60', value: 'net60'},
    {label: 'Due on Receipt', value: 'receipt'},
    {label: 'Due on End of Month', value: 'endofMonth'},
    {label: 'Due on End of Next Month', value: 'endOfNextMonth'},
    {label: 'Custom', value: 'custom'},
  ]

  const fetchDeliveryMethods = async () => {
    const res = await getDeliveryMethods(currentUser?.organization)
    const data = res.map((item) => ({value: item, label: item}))
    setDeliveryMethodOption(data)
  }

  const handleNewItemChange = (index, e) => {
    const {name, value} = e.target
    setNewItem((prevItems) => {
      const updatedItems = [...prevItems]
      updatedItems[index] = {
        ...updatedItems[index],
        [name]: value,
      }
      const total =
        updatedItems[index].price * updatedItems[index].quantity - updatedItems[index].discount
      updatedItems[index] = {
        ...updatedItems[index],
        amount: total,
      }
      return updatedItems
    })
  }

  const handleDeliveryMethodSubmit = () => {
    const data = {
      deliveryMethod: name,
    }
    const res = addDeliveryMethod(currentUser?.organization, data)
    if (res) {
      setName('')
      setDeliveryMethodOption([...deliveryMethodOption, {value: name, label: name}])
      const closeModalButton1 = document.getElementById('closeModalButton1')
      closeModalButton1.click()
    }
  }

  const handleDragEnd = (result) => {
    if (!result.destination) return // dropped outside the list

    if (items.length > 1) {
      const itemsCopy = [...items]
      const [reorderedItem] = itemsCopy.splice(result.source.index, 1)
      itemsCopy.splice(result.destination.index, 0, reorderedItem)

      // Update your state with the reordered items
      setItems(itemsCopy)
    } else if (newItem.length > 1) {
      const itemsCopy = [...newItem]
      const [reorderedItem] = itemsCopy.splice(result.source.index, 1)
      itemsCopy.splice(result.destination.index, 0, reorderedItem)

      // Update your state with the reordered items
      setNewItem(itemsCopy)
    }
  }

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  useEffect(() => {
    if (salesOrder.termsNCondition === '') {
      setTermsTextareaHeight('3rem')
    }
  }, [salesOrder.termsNCondition])

  useEffect(() => {
    if (salesOrder.notes === '') {
      setNotesTextareaHeight('3rem')
    }
  }, [salesOrder.notes])

  useEffect(() => {
    const fetchSalesOrders = async () => {
      try {
        const salesOrderList = await getSalesOrder(currentUser?.organization)
        console.log('list', salesOrderList)

        if (salesOrderList && salesOrderList.length > 0) {
          const firstSalesOrder = salesOrderList[0]

          setSalesOrder((prev) => ({
            ...prev,
            notes: firstSalesOrder.notes || ' ',
            termsNCondition: firstSalesOrder.termsNCondition || ' ',
          }))
        }
      } catch (error) {
        console.error('Error fetching delivery notes:', error)
      }
    }

    fetchSalesOrders()
  }, [])

  const handleEditClick = (e, field) => {
    e.preventDefault()
    if (field === 'notes') {
      setIsEditingNotes(!isEditingNotes)
      setIsEditingTerms(false) // Make sure only one is in edit mode at a time
    } else if (field === 'termsNCondition') {
      setIsEditingTerms(!isEditingTerms)
      setIsEditingNotes(false) // Make sure only one is in edit mode at a time
    }
  }

  const getCustomerList = async () => {
    try {
      const res = await getCustomer('', currentUser?.organization)
      console.log(res)
      setCustomerList(
        res.map((r) => ({
          value: r?._id,
          label: r?.displayName,
        }))
      )
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  const getEmployeeList = async () => {
    try {
      const res = await getEmployeeByStatus('filter_status=true', currentUser?.organization)
      console.log(res)
      setEmployeeList(
        res.map((r) => ({
          value: r._id,
          label: r.firstName, // Corrected the typo here from "labe" to "label"
        }))
      )
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  const getProductlist = async () => {
    try {
      let salesStockData = []
      if (salesStock.length === 0) {
        const res = await getStocksForSales(currentUser?.organization)
        console.log(res)
        salesStockData = res.map((r) => ({
          value: r._id,
          label: r.productName,
          price: r.price,
          inWarehouseQuantity: r.inWarehouseQuantity,
          type: 'product',
        }))
        dispatch(setSalesStock(res))
      } else {
        salesStockData = salesStock.map((r) => ({
          value: r._id,
          label: r.productName,
          price: r.price,
          inWarehouseQuantity: r.inWarehouseQuantity,
          type: 'product',
        }))
      }

      let serviceData = []
      if (servicesList.length === 0) {
        const resp = await getItemsByType('service', currentUser?.organization)
        serviceData = resp.map((r) => ({
          value: r._id,
          label: r.name,
          price: r.price,
          inWarehouseQuantity: 1,
          type: 'item',
        }))
        dispatch(setServices(resp))
      } else {
        serviceData = servicesList.map((r) => ({
          value: r._id,
          label: r.name,
          price: r.price,
          inWarehouseQuantity: 1,
          type: 'item',
        }))
      }
      setProductList((prev) => {
        const updatedData = [...salesStockData, ...serviceData]
        return updatedData
      })
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  const getCustomerForAgent = async () => {
    try {
      const res = await getCustomerByStatusForAgent('', currentUser?._id)
      console.log(res)
      setCustomerList(
        res.map((r) => ({
          value: r?._id,
          label: r?.displayName,
        }))
      )
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  useEffect(() => {
    if (permissions?.salesorder?.admin === false) {
      getCustomerForAgent()
    } else if (
      permissions?.salesorder?.admin === true ||
      currentUser?.profileType === 'superadmin'
    ) {
      getCustomerList()
    }
  }, [permissions])

  const fetchSalesOrderData = async () => {
    const res = await getSalesOrderDetailById(salesOrderId)
    console.log(res)
    setSalesOrder(() => {
      return {
        ...res,
        date: new Date(res.date),
        employee: res.employee?._id,
        customer: res.customer?._id,
      }
    })
    setselectedBillTo({
      value: res.customer?._id,
      label: res.customer?.displayName,
    })
    setselectedBillFrom({
      value: res.employee?._id,
      label: res.employee?.firstName,
    })
    setSalesOrderNumber(res.id)
    setOrderId(res.order)
    const data = res.items.filter((item) => !item.itemId && !item.itemsId)
    setNewItem(data)
    setItems(
      res.items
        .filter((item) => item.itemId || item.itemsId)
        .map((item) => {
          return {
            itemId: item.itemId?._id || item.itemsId?._id,
            price: item.price,
            productName: item?.productName,
            quantity: item.quantity,
            discount: item.discount,
            amount: item.amount,
            selectedOption: {
              value: item.itemId?._id || item.itemsId?._id,
              label: item.itemId ? item.itemId.productName : item.itemsId.name,
              inWarehouseQuantity: item.itemId ? item.itemId.inWarehouseQuantity : 1,
              type: item.type,
            },
            type: item.type,
          }
        })
    )
  }

  useEffect(() => {
    fetchSalesOrderData()
    getEmployeeList()
    getProductlist()
    fetchDeliveryMethods()
  }, [])

  // const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
  const handleChange = (e) => {
    e.preventDefault()
    const {name, value} = e.target
    if (name === 'notes' && value.trim() === '') {
      setSalesOrder((prev) => ({
        ...prev,
        notes: '• ' + value,
      }))
    } else {
      setSalesOrder((prevInvoice) => ({
        ...prevInvoice,
        [name]: value,
        // You can add other properties if needed
      }))
    }
    if (name === 'termsNCondition') {
      setTermsTextareaHeight(`${e.target.scrollHeight}px`)
    } else if (name === 'notes') {
      setNotesTextareaHeight(`${e.target.scrollHeight}px`)
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      setSalesOrder((prev) => ({
        ...prev,
        notes: prev.notes + '\n• ',
      }))

      setNotesTextareaHeight(`${event.target.scrollHeight}px`)
    }
  }

  const handleChangerows = (index, e) => {
    const {name, value} = e.target
    if (name === 'quantity' && items[index].type !== 'item') {
      if (value > items[index].selectedOption.inWarehouseQuantity) {
        return
      }
    }
    setItems((prevItems) => {
      const updatedItems = [...prevItems]
      updatedItems[index] = {
        ...updatedItems[index],
        [name]: value,
      }
      return updatedItems
    })
    setItems((prevItems) => {
      const updatedItems = [...prevItems]
      const data: any = (
        Number(prevItems[index].price) * Number(prevItems[index].quantity) -
        Number(prevItems[index].discount)
      ).toFixed(2)

      updatedItems[index] = {
        ...updatedItems[index],
        amount: data,
      }
      return updatedItems
    })
  }

  const handleSelectChangeInRows = (index, selectedOption) => {
    console.log(selectedOption)
    setItems((prevItems) => {
      const updatedItems = [...prevItems]
      updatedItems[index] = {
        ...updatedItems[index],
        itemId: selectedOption.value,
        selectedOption,
        price: selectedOption.price,
        amount: selectedOption.price,
        quantity: 1,
        type: selectedOption.type,
        productName: selectedOption.label,
      }
      return updatedItems
    })
  }

  useEffect(() => {
    let sum = items.reduce((acc, item) => {
      return acc + parseFloat(item.amount)
    }, 0)
    sum += newItem.reduce((acc, item) => {
      return acc + parseFloat(item.amount)
    }, 0)
    setSalesOrder((prevInvoice) => ({
      ...prevInvoice,
      subtotal: sum,
      // You can add other properties if needed
    }))
  }, [items, newItem])

  useEffect(() => {
    setSalesOrder((prevInvoice) => {
      const subtotal = prevInvoice.subtotal || 0
      const tax = prevInvoice.tax || 0

      const total = (subtotal * (1 + tax / 100)).toFixed(2)

      return {
        ...prevInvoice,
        total: parseFloat(total),
        // You can add other properties if needed
      }
    })
  }, [items, salesOrder.tax, newItem])

  const handleBillToChange = (selectedOption) => {
    setselectedBillTo(selectedOption)

    setSalesOrder((prevInvoice) => ({
      ...prevInvoice,
      customer: selectedOption.value,
    }))
  }

  const handleBillFromChange = (selectedOption) => {
    console.log(selectedOption)
    setselectedBillFrom(selectedOption)
    setSalesOrder((prevInvoice) => ({
      ...prevInvoice,
      employee: selectedOption.value,
    }))
  }

  const handleDateChange = (selectedDate) => {
    setSalesOrder((prevInvoice) => ({
      ...prevInvoice,
      salesOrderDate: selectedDate[0], // Flatpickr returns an array of selected dates
    }))
  }
  const handleExpectedDateChange = (selectedDate) => {
    setSalesOrder((prevInvoice) => ({
      ...prevInvoice,
      expectedShipmentDate: selectedDate[0], // Flatpickr returns an array of selected dates
    }))
  }

  const handleEdit = async () => {
    if (salesOrder.customer === '' || salesOrder.employee === '') {
      alert('Please fill all the fields')
      return
    }
    if (items[0].itemId === '') {
      alert('Please select an item to add to the sales order')
      return
    }
    setLoading(true)
    const itemsList = items.map((item) => ({
      itemId: item.type === 'product' ? item.itemId : null,
      itemsId: item.type === 'item' ? item.itemId : null,
      productName: item.productName,
      price: item.price,
      quantity: item.quantity,
      discount: item.discount,
      amount: item.amount,
      type: item.type,
    }))
    const data = {
      ...salesOrder,
      order: orderId,
      id: salesOrderNumber,
      approval: permissions?.salesorder?.enable ? 'accepted' : 'pending',
      items: [...itemsList, ...newItem],
      company: currentUser?.company,
      organization: currentUser?.organization,
      agent: currentUser?._id,
    }
    console.log(data)
    try {
      await revisedSalesOrder(data, salesOrderId).then((res) => {
        console.log('Submitted', res)
        navigate(`/sales/salesorders`)
      })
    } catch (error) {
      console.error('Error creating invoice:', error)
    }
  }

  const additem = () => {
    setItems([
      ...items,
      {
        itemId: '',
        price: '',
        quantity: 0,
        discount: 0,
        amount: '',
        selectedOption: {
          value: '',
          label: '',
          inWarehouseQuantity: 0,
        },
        type: 'product',
        productName: '',
      },
    ])
  }

  const removeitem = (index) => {
    if (items.length === 1) {
      setItems([
        {
          itemId: '',
          price: '',
          quantity: 0,
          discount: 0,
          amount: '',
          selectedOption: {
            value: '',
            label: '',
            inWarehouseQuantity: 0,
          },
          type: 'product',
          productName: '',
        },
      ])
      return
    }
    const temp = [...items]
    temp.splice(index, 1)
    setItems(temp)
  }

  return (
    <div id='kt_app_content' className='app-content flex-column-fluid'>
      {/* begin::Content container */}
      <div id='kt_app_content_container' className='app-container container-xxl'>
        {/* begin::Layout */}
        <div className='d-flex flex-column flex-lg-row'>
          {/* begin::Content */}
          <div className='flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10'>
            {/* begin::Card */}
            <div className='card'>
              {/* begin::Card body */}
              <div className='card-body p-12'>
                {/* begin::Form */}
                <form action='' id='kt_quote_form'>
                  {/* begin::Wrapper */}
                  <div className='d-flex flex-column align-items-start flex-xxl-row'>
                    {/* begin::Input group */}
                    <div
                      className='d-flex align-items-center flex-equal fw-row me-4 order-2'
                      data-bs-toggle='tooltip'
                      data-bs-trigger='hover'
                      title='Specify invoice date'
                    >
                      {/* begin::Date */}
                      <div className='fs-6 fw-bold text-gray-700 text-nowrap'>Date:</div>
                      {/* Date */}
                      {/* begin::Input */}
                      <div className='position-relative d-flex align-items-center w-150px'>
                        {/* begin::Datepicker */}
                        <Flatpickr
                          value={salesOrder.salesOrderDate}
                          onChange={handleDateChange}
                          className='form-control form-control-solid'
                          placeholder='Pick date'
                          options={{
                            dateFormat: 'd-m-Y',
                          }}
                        />
                        {/* Datepicker */}
                        {/* begin::Icon */}
                        {/* <i className='ki-duotone ki-down fs-4 position-absolute ms-4 end-0'></i> */}
                        {/* Icon */}
                      </div>
                      {/* Input */}
                    </div>
                    {/* Input group */}
                    <div className='d-flex align-items-center flex-equal fw-row me-4 order-2'>
                      <span className='fs-2x fw-bold text-gray-800'>Sales Order #</span>
                      <span className='fs-2x fw-bold text-gray-800'>{salesOrderNumber}</span>
                    </div>
                    {/* Input group */}
                  </div>
                  {/* Top */}
                  {/* begin::Separator */}
                  <div className='separator separator-dashed my-10'></div>
                  {/* Separator */}
                  {/* begin::Wrapper */}
                  <div className='mb-0'>
                    {/* begin::Row */}
                    <div className='row gx-10 mb-5'>
                      {/* begin::Col */}
                      <div className='col-lg-6'>
                        <label className='form-label required fs-6 fw-bold text-gray-700 mb-3'>
                          Sales Person
                        </label>
                        {/* begin::Input group */}
                        <Select
                          name='employee'
                          aria-label='Select a Employee'
                          placeholder='Select Employee'
                          options={employeeList}
                          defaultValue={{value: currentUser?._id, label: currentUser?.fullName}}
                          isSearchable={true}
                          className='form-control form-control-solid'
                          onChange={handleBillFromChange}
                          value={selectedBillFrom}
                        />
                        {/* Input group */}
                      </div>
                      {/* Col */}
                      {/* begin::Col */}
                      <div className='col-lg-6'>
                        <label className='form-label required fs-6 fw-bold text-gray-700 mb-3'>
                          Customer Name
                        </label>
                        {/* begin::Input group */}
                        <Select
                          name='customer'
                          aria-label='Select a Customer'
                          placeholder='Select a Customer'
                          options={customerList}
                          isSearchable={true}
                          className='form-control form-control-solid'
                          onChange={handleBillToChange}
                          value={selectedBillTo}
                        />
                      </div>
                      {/* Col */}
                    </div>
                    {/* Row */}
                    <div className=''>
                      <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                        Reference#
                      </label>
                      <input
                        type='text'
                        className='form-control form-control-solid '
                        name='reference'
                        placeholder='Reference#'
                        value={salesOrder.reference}
                        onChange={handleChange}
                      />
                    </div>
                    <div className='row gx-10 mb-5'>
                      {/* begin::Col */}
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                          Expected Shipment Date
                        </label>
                        <Flatpickr
                          value={salesOrder.expectedShipmentDate}
                          onChange={handleExpectedDateChange}
                          className='form-control form-control-solid'
                          placeholder='Pick date'
                          options={{
                            dateFormat: 'd-m-Y',
                          }}
                        />

                        {/* Input group */}
                      </div>
                      {/* Col */}
                      {/* begin::Col */}
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                          Payment Terms
                        </label>
                        {/* begin::Input group */}
                        <Select
                          name='terms'
                          aria-label='Select a Terms'
                          placeholder='Select a Terms'
                          options={termsOption}
                          value={termsOption.find(
                            (option) => option.value === salesOrder.paymentTerms
                          )}
                          // className='form-control'
                          onChange={(selectedOption) => {
                            setSalesOrder((prevInvoice) => ({
                              ...prevInvoice,
                              paymentTerms: selectedOption.value,
                            }))
                          }}
                        />
                      </div>
                      <div className='mb-10'>
                        <label className='form-label fw-bold fs-6 text-gray-700'>
                          Delivery Method
                        </label>
                        <Select
                          name='deliveryMethod'
                          aria-label='Select a Delivery Method'
                          placeholder='Select a Delivery Method'
                          options={deliveryMethodOption}
                          // defaultValue={defaultDeliveryMethod}
                          value={deliveryMethodOption.find(
                            (option) => option.value === salesOrder.deliveryMethod
                          )}
                          isSearchable={true}
                          className=''
                          onChange={(selectedOption) => {
                            setSalesOrder((prevInvoice) => ({
                              ...prevInvoice,
                              deliveryMethod: selectedOption.value,
                            }))
                          }}
                        />
                        <div
                          className='card-toolbar mt-5'
                          data-bs-toggle='modal'
                          data-bs-target='#kt_modal_1'
                        >
                          <a className='btn btn-sm btn-light-warning'>
                            <i className='ki-duotone ki-plus fs-2'></i>Create New Delivery Method
                          </a>
                        </div>
                        <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
                          <div className='modal-dialog'>
                            <div className='modal-content'>
                              <div className='modal-header'>
                                <h5 className='modal-title'>Add New Delivery Method</h5>
                                <div
                                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                                  data-bs-dismiss='modal'
                                  aria-label='Close'
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/arrows/arr061.svg'
                                    className='svg-icon svg-icon-2x'
                                  />
                                </div>
                              </div>

                              <div className='modal-body'>
                                <form>
                                  <div className='row rowwidthclient'>
                                    <div className='form-group mb-3 col-md-12'>
                                      <label>Delivery Method Name</label>
                                      <input
                                        type='text'
                                        name='name'
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        className='form-control'
                                      />
                                    </div>
                                  </div>
                                </form>
                              </div>

                              <div className='modal-footer'>
                                <button
                                  type='button'
                                  className='btn btn-light'
                                  data-bs-dismiss='modal'
                                  id='closeModalButton1'
                                >
                                  Close
                                </button>
                                <button
                                  type='button'
                                  className='btn btn-primary'
                                  onClick={handleDeliveryMethodSubmit}
                                >
                                  Save changes
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Col */}
                    </div>
                    {/* begin::Table wrapper */}
                    <div className='fs-6 fw-bold text-gray-700 mb-3'>Item Table</div>
                    <div className='table-responsive mb-10'>
                      {/* begin::Table */}
                      <table
                        className='table g-5 gs-0 mb-0 fw-bold text-gray-700'
                        data-kt-element='items'
                      >
                        {/* begin::Table head */}
                        <thead>
                          <tr className='border-bottom fs-7 fw-bold text-gray-700 text-uppercase'>
                            <th className='min-w-15px w-15px'>No.</th>
                            <th className='min-w-100px w-500px'>Item</th>
                            <th className='min-w-150px w-250px'>Quantity</th>
                            <th className='min-w-50px w-250px'>Rate</th>
                            <th className='min-w-70px w-150px'>VAT</th>
                            <th className='min-w-70px w-250px'>Amount</th>
                            <th className='min-w-70px w-200px'>Action</th>
                          </tr>
                        </thead>
                        {/* Table head */}
                        {/* begin::Table body */}
                        <DragDropContext onDragEnd={handleDragEnd}>
                          <Droppable droppableId='droppable'>
                            {(provided) => (
                              <tbody ref={provided.innerRef} {...provided.droppableProps}>
                                {items.map((item, index) => (
                                  <Draggable
                                    key={index}
                                    draggableId={`item-${index}`}
                                    index={index}
                                  >
                                    {(provider) => (
                                      <tr
                                        className='border-bottom border-bottom-dashed'
                                        data-kt-element='item'
                                        key={index}
                                        ref={provider.innerRef}
                                        {...provider.draggableProps}
                                      >
                                        <td className='d-flex align-items-center'>{index + 1}</td>
                                        <td className='pe-7'>
                                          {/* begin::Input group */}
                                          <Select
                                            name='itemId'
                                            aria-label='Select a Product'
                                            placeholder='Select a Product'
                                            options={productList}
                                            isSearchable={true}
                                            className='form-control form-control-solid'
                                            onChange={(e) => handleSelectChangeInRows(index, e)}
                                            value={item.selectedOption}
                                            required
                                          />
                                        </td>
                                        <td className='pe-7'>
                                          {/* begin::Input group */}

                                          <input
                                            type='number'
                                            className='form-control form-control-solid'
                                            name='quantity'
                                            placeholder='0.00'
                                            max={
                                              item?.type === 'product' &&
                                              item?.selectedOption?.inWarehouseQuantity
                                            }
                                            min={0}
                                            value={item.quantity}
                                            onChange={(e) => handleChangerows(index, e)}
                                          />
                                        </td>

                                        <td className='pe-7'>
                                          {/* begin::Input group */}

                                          <input
                                            type='number'
                                            className='form-control form-control-solid'
                                            name='price'
                                            placeholder='0.00'
                                            value={item.price}
                                            onChange={(e) => handleChangerows(index, e)}
                                          />
                                        </td>

                                        <td className='pe-7'>
                                          {/* begin::Input group */}

                                          <input
                                            type='number'
                                            className='form-control form-control-solid'
                                            name='discount'
                                            placeholder='0.00'
                                            value={item.discount}
                                            onChange={(e) => handleChangerows(index, e)}
                                          />
                                        </td>

                                        <td>
                                          <input
                                            type='number'
                                            className='form-control form-control-solid text-end'
                                            name='amount'
                                            placeholder='0.00'
                                            value={item.amount}
                                            disabled
                                            // onChange={(e) => handleChangerows(index, e)}
                                          />
                                        </td>
                                        <td>
                                          <div
                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3'
                                            onClick={additem}
                                          >
                                            <KTIcon iconName='plus-square' className='fs-3' />
                                          </div>
                                          <div
                                            className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm '
                                            onClick={() => removeitem(index)}
                                          >
                                            <KTIcon iconName='trash' className='fs-3' />
                                          </div>
                                          <div
                                            {...provider.dragHandleProps}
                                            className='fs-3 fw-bold text-gray-400 ms-3'
                                          >
                                            ...
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                                {newItem.length > 0 &&
                                  newItem.map((item, index) => (
                                    <Draggable
                                      key={index}
                                      draggableId={`item-${items.length + index}`}
                                      index={index}
                                    >
                                      {(provider) => (
                                        <tr
                                          className='border-bottom border-bottom-dashed'
                                          data-kt-element='item'
                                          key={index}
                                          ref={provider.innerRef}
                                          {...provider.draggableProps}
                                        >
                                          <td className='d-flex align-items-center'>{index + 1}</td>
                                          <td className='w-[300px] pe-7'>
                                            {/* begin::Input group */}
                                            <input
                                              type='text'
                                              className='form-control form-control-solid'
                                              name='productName'
                                              placeholder='Enter Product Name'
                                              value={item.productName}
                                              onChange={(e) => handleNewItemChange(index, e)}
                                            />
                                          </td>
                                          <td className='pe-7'>
                                            {/* begin::Input group */}

                                            <input
                                              type='number'
                                              className='form-control form-control-solid'
                                              name='quantity'
                                              placeholder='0.00'
                                              max={
                                                item?.type === 'product' &&
                                                item?.selectedOption?.inWarehouseQuantity
                                              }
                                              min={0}
                                              value={item.quantity}
                                              onChange={(e) => handleNewItemChange(index, e)}
                                            />
                                          </td>

                                          <td className='pe-7'>
                                            {/* begin::Input group */}

                                            <input
                                              type='number'
                                              className='form-control form-control-solid'
                                              name='price'
                                              placeholder='0.00'
                                              value={item.price}
                                              onChange={(e) => handleNewItemChange(index, e)}
                                            />
                                          </td>

                                          <td className='pe-7'>
                                            {/* begin::Input group */}

                                            <input
                                              type='number'
                                              className='form-control form-control-solid'
                                              name='discount'
                                              placeholder='0.00'
                                              value={item.discount}
                                              onChange={(e) => handleNewItemChange(index, e)}
                                            />
                                          </td>

                                          <td>
                                            <input
                                              type='number'
                                              className='form-control form-control-solid text-end'
                                              name='amount'
                                              placeholder='0.00'
                                              value={item.amount}
                                              disabled
                                              // onChange={(e) => handleChangerows(index, e)}
                                            />
                                          </td>
                                          <td>
                                            <div
                                              className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm '
                                              onClick={() => {
                                                const temp = [...newItem]
                                                temp.splice(index, 1)
                                                setNewItem(temp)
                                              }}
                                            >
                                              <KTIcon iconName='trash' className='fs-3' />
                                            </div>
                                            <div
                                              {...provider.dragHandleProps}
                                              className='fs-3 fw-bold text-gray-400 ms-3'
                                            >
                                              ...
                                            </div>
                                          </td>
                                        </tr>
                                      )}
                                    </Draggable>
                                  ))}
                                {/* button for adding new Item */}

                                <tr>
                                  <td colSpan={6} className='text-center'>
                                    <button
                                      className='btn btn-info w-50'
                                      type='button'
                                      onClick={() =>
                                        setNewItem([
                                          ...newItem,
                                          {
                                            productName: '',
                                            price: 0,
                                            quantity: 0,
                                            discount: 0,
                                            amount: 0,
                                          },
                                        ])
                                      }
                                    >
                                      Add New Item
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            )}
                          </Droppable>
                        </DragDropContext>
                        {/* Table body */}
                        {/* begin::Table foot */}
                        <tfoot>
                          <tr className='border-top border-top-dashed align-top fs-6 fw-bold text-gray-700'>
                            <th className='text-primary'></th>
                            <th className='text-primary'></th>
                            <th className='text-primary'></th>
                            <th className='border-bottom border-bottom-dashed ps-0'>
                              <div className='d-flex flex-column align-items-end'>
                                <div className='fs-5'>Subtotal</div>
                                <button
                                  className='btn btn-link '
                                  data-bs-toggle='tooltip'
                                  data-bs-trigger='hover'
                                  title='Tax in percentage'
                                >
                                  VAT %
                                </button>
                              </div>
                            </th>
                            <th className='border-bottom border-bottom-dashed text-center'>
                              <span data-kt-element='sub-total' className=' '>
                                {salesOrder.subtotal}
                              </span>
                              <input
                                type='number'
                                className='form-control form-control-solid text-center '
                                name='tax'
                                placeholder='0.00'
                                value={salesOrder.tax}
                                onChange={handleChange}
                              />
                            </th>
                          </tr>
                          <tr className='align-top fw-bold text-gray-700'>
                            <th className='min-w-15px w-15px'></th>
                            <th className='min-w-150px w-150px'></th>
                            <th className='min-w-150px w-150px'></th>
                            <th className='fs-4 ps-0 min-w-50px w-50px text-end'>Total</th>
                            <th className=' fs-4 text-nowrap min-w-70px w-70px text-center'>
                              {currentUser?.baseCurrency}
                              <span data-kt-element='grand-total text-center'>
                                {salesOrder.total}
                              </span>
                            </th>
                          </tr>
                        </tfoot>
                        {/* Table foot */}
                      </table>
                    </div>
                    {/* Table */}
                    {/* begin::Item template */}

                    <table className='table d-none' data-kt-element='empty-template'>
                      <tr data-kt-element='empty'>
                        <th colSpan={5} className='text-muted text-center py-10'>
                          No items
                        </th>
                      </tr>
                    </table>
                    {/* Item template */}
                    {/* begin::Notes */}
                    <div className='mb-0'>
                      <label className='form-label fs-6 fw-bold text-gray-700'>Notes</label>
                      <textarea
                        name='notes'
                        className={`position-relative  form-control form-control-solid mb-0 h-10 ${
                          isEditingNotes ? 'border border-dark-subtle' : ''
                        }`}
                        placeholder='Enter customer notes'
                        value={salesOrder.notes}
                        onChange={handleChange}
                        readOnly={!isEditingNotes}
                        onKeyDown={handleKeyPress}
                        style={{
                          minHeight: '3rem',
                          height: notesTextareaHeight,
                          resize: 'none',
                        }}
                      />
                      <button onClick={(e) => handleEditClick(e, 'notes')}>
                        <KTIcon
                          iconName='pencil'
                          className='position-absolute text-black bg-gray-100 right-16 bottom-72 fs-3'
                        />
                      </button>
                    </div>
                    <div className='mb-10'>
                      <label className='form-label fw-bold fs-6 text-gray-700'>
                        Terms and Conditions
                      </label>
                      <textarea
                        name='termsNCondition'
                        className={`position-relative  form-control form-control-solid mb-0 h-10 ${
                          isEditingTerms ? 'border border-dark-subtle' : ''
                        }`}
                        placeholder='Enter terms and conditions'
                        value={salesOrder.termsNCondition}
                        onChange={handleChange}
                        readOnly={!isEditingTerms}
                        style={{
                          minHeight: '3rem',
                          height: termsTextareaHeight,
                          resize: 'none',
                        }}
                      />
                      <button onClick={(e) => handleEditClick(e, 'termsNCondition')}>
                        <KTIcon
                          iconName='pencil'
                          className='absolute text-black bg-gray-100 right-16 bottom-44 fs-3'
                        />
                      </button>
                    </div>
                    <button
                      className='btn btn-warning w-30'
                      type='button'
                      id='kt_quote_submit_button'
                      disabled={loading}
                      onClick={handleEdit}
                    >
                      <i className='ki-duotone ki-triangle fs-3'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>
                      Update Sales Order
                    </button>
                    {/* Notes */}
                  </div>
                  {/* Wrapper */}
                </form>
                {/* Form */}
              </div>
              {/* Card body */}
            </div>
            {/* Card */}
          </div>
          {/* Content */}
          {/* begin::Sidebar */}

          {/* <!--::Sidebar--> */}
        </div>
      </div>
      {/* <!--::Content container--> */}
    </div>
  )
}

export default RevisedSalesOrder
