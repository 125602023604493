import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  agents: [],
  selectedDepartments: [],
  loading: true,
  fetch: true,
}

const agentsSlice = createSlice({
  name: 'agents',
  initialState,
  reducers: {
    setCRMAgents: (state, action) => {
      state.agents = action.payload.employees
      state.selectedDepartments = action.payload.departments
      state.loading = false
      state.fetch = false
    },
    setCRMAgentsSelectedDepartments: (state, action) => {
      state.selectedDepartments = action.payload
    },
    setCRMAgentsLoading: (state, action) => {
      state.loading = action.payload
    },
    setCRMAgentsFetch: (state, action) => {
      state.fetch = action.payload
    },
  },
})

export const {
  setCRMAgents,
  setCRMAgentsSelectedDepartments,
  setCRMAgentsLoading,
  setCRMAgentsFetch,
} = agentsSlice.actions
export default agentsSlice.reducer
