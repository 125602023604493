import React from 'react'
import {TbFileUpload} from 'react-icons/tb'
import {RxCrossCircled} from 'react-icons/rx'
import {RxCross2} from 'react-icons/rx'

export default function Media({media, setMedia, mediaName, setMediaName}) {
  const s3ImageUrl = `https://https://qf-assets.s3.me-central-1.amazonaws.com/`
  console.log(mediaName)
  const handleFileChange = (e) => {
    const files = e.target.files
    const updatedMedia = [...media, ...Array.from(files)]
    const updatedMediaNames = [
      ...mediaName,
      ...Array.from(files).map((file) => Date.now() + file.name),
    ]
    setMedia(updatedMedia)
    setMediaName(updatedMediaNames)
  }

  const handleDrop = (e) => {
    e.preventDefault()
    const files = e.dataTransfer.files
    const updatedMedia = [...media, ...Array.from(files)]
    const updatedMediaNames = [
      ...mediaName,
      ...Array.from(files).map((file) => Date.now() + file.name),
    ]
    setMedia(updatedMedia)
    setMediaName(updatedMediaNames)
  }

  const handleDragOver = (e) => {
    e.preventDefault()
  }

  const handleFileRemove = (index, e) => {
    e.preventDefault()
    const updatedMedia = [...media]
    updatedMedia.splice(index, 1)
    setMedia(updatedMedia)
    const updatedMediaNames = [...mediaName]
    updatedMedia.splice(index, 1)
    updatedMediaNames.splice(index, 1)
    setMediaName(updatedMediaNames)
  }

  return (
    // <div className='text-start space-y-2 bg-white p-7 mt-3 rounded'>
    //   <h1 className='text-start fw-semibold mb-3'>Media</h1>
    //   <div
    //     className='bg-lime-50 w-full h-full flex border border-lime-400'
    //     onDrop={handleDrop}
    //     onDragOver={handleDragOver}
    //   >
    //     <input
    //       type='file'
    //       multiple
    //       className='hidden h-full w-full'
    //       id='fileupload'
    //       name='media'
    //       onChange={handleFileChange}
    //     />

    //     <label htmlFor='fileupload' className='cursor-pointer w-full p-4'>
    //       {media.length ? (
    //         <div className='mt-3'>
    //           <div className='flex flex-wrap gap-2'>
    //             {media.map((file, index) => (
    //               <div key={index} className='relative'>
    //                 <img
    //                   src={URL.createObjectURL(file)}
    //                   alt={URL.createObjectURL(file)}
    //                   className='rounded-md w-20 h-20 object-cover'
    //                 />
    //                 <button
    //                   className='absolute top-0 right-0 bg-red-500 text-white p-1 rounded-full cursor-pointer'
    //                   onClick={(e) => handleFileRemove(index, e)}
    //                 >
    //                   X
    //                 </button>
    //               </div>
    //             ))}
    //           </div>
    //         </div>
    //       ) : (
    //         <>
    //           <div className='h-full flex p-2 text-lime-500'>
    //             <TbFileUpload size={40} />
    //             <div>
    //               <h2 className='fw-semibold'>Drop the files here or Click to upload</h2>
    //               <p className='card-text w-full text-gray-500 text-start'>Upload up to 10 files</p>
    //             </div>
    //           </div>
    //         </>
    //       )}
    //     </label>
    //   </div>

    //   <p className='card-text w-full mt-1 text-gray-500 text-start'>
    //     Set the product media gallery.
    //   </p>
    // </div>

    <div class='card card-flush py-4'>
      <div class='card-header'>
        <div class='card-title'>
          <h2>Media</h2>
        </div>
      </div>

      <div class='card-body pt-0'>
        <div class='fv-row mb-2 '>
          <div class='dropzone' id='kt_ecommerce_add_product_media'>
            <div class='dz-message needsclick' onDrop={handleDrop} onDragOver={handleDragOver}>
              <input
                type='file'
                multiple
                className='hidden h-full w-full'
                id='fileupload'
                name='media'
                onChange={handleFileChange}
              />

              <label htmlFor='fileupload' className='cursor-pointer w-full p-4'>
                {media.length ? (
                  <div className='mt-3'>
                    <div className='flex flex-wrap gap-2'>
                      {media.map((file, index) => (
                        <div key={index} className='relative'>
                          <img
                            src={URL.createObjectURL(file)}
                            alt={URL.createObjectURL(file)}
                            className='rounded-md w-20 h-20 object-cover'
                          />
                          <button
                            className='absolute top-0 right-0 bg-white  rounded-full cursor-pointer'
                            onClick={(e) => handleFileRemove(index, e)}
                          >
                            <RxCross2 />
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <>
                    <div className='h-full flex p-2 '>
                      <i class=' ki-duotone ki-file-up text-primary fs-3x'>
                        <span class='path1 '></span>
                        <span class='path2 '></span>
                      </i>

                      <div class='ms-4'>
                        <h3 class='fs-5 fw-bold text-gray-900 mb-1'>
                          Drop files here or click to upload.
                        </h3>
                        <span class='fs-7 fw-semibold text-gray-400'>Upload up to 10 files</span>
                      </div>
                    </div>
                  </>
                )}
              </label>
            </div>
          </div>
        </div>

        <div class='text-muted fs-7'>Set the product media gallery.</div>
      </div>
    </div>
  )
}
