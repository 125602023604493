import React from 'react'
import {Routes, Route} from 'react-router-dom'
import HRPProjectPage from './pages/HRPProjectPage'
import HRPDepartment from './pages/HRPDepartment'
import HRPEmployeePage from './pages/HRPEmployeePage'
import HRPEmployeeGroup from './pages/HRPEmployeeGroup'
import EmployeeGroupDetail from './components/EmployeeGroupDetail'
import HRPEmployeeCamp from './pages/HRPEmployeeCamp'
import EmployeeCampDetail from './components/EmployeeCampDetail'
import HRPTimesheet from './pages/HRPTimesheet'
import HRPTimesheetDetail from './components/HRPTimesheetDetail'
import HRPAttendance from './pages/HRPAttendance'
import HRPLeavePage from './pages/HRPLeavePage'
import AddLeave from '../hrm/Pages/AddLeave'
import LeaveApply from '../hrm/Pages/LeaveApply'
import HRPLeaveDetailByMonth from './components/HRPLeaveDetailByMonth'
import HRPDocuments from './pages/HRPDocuments'
import HRPFilesPage from './components/HRPFilesPage'
import EmployeeDetail from './components/EmployeeDetail'
import HRPEmployeeReportsRoutes from './HRPEmployeeReportsRoutes'

const HRPPage = () => {
  return (
    <Routes>
      <Route path='/project' element={<HRPProjectPage />} />
      <Route path='/department' element={<HRPDepartment />} />
      <Route path='/employees' element={<HRPEmployeePage link='view-employee' />} />
      <Route path='/employees/view-employee/:id' element={<EmployeeDetail />} />
      <Route path='/employee-groups' element={<HRPEmployeeGroup />} />
      <Route path='/employee-groups/:id' element={<EmployeeGroupDetail />} />
      <Route path='/camps' element={<HRPEmployeeCamp />} />
      <Route path='/camps/:id' element={<EmployeeCampDetail />} />
      <Route path='/time-sheet' element={<HRPTimesheet />} />
      <Route path='/time-sheet/:id' element={<HRPTimesheetDetail />} />
      <Route path='/attendance' element={<HRPAttendance />} />
      <Route path='/time-tracking' element={<HRPEmployeePage link='/hrm/time-tracker' />} />
      <Route path='/leave-management' element={<HRPLeavePage />} />
      <Route path='/leave-management/addLeaveType' element={<AddLeave isHRP={true} />} />
      <Route path='/leave-management/add' element={<LeaveApply isHRP={true} />} />
      <Route path='/leave-management/:month' element={<HRPLeaveDetailByMonth />} />
      <Route path='/reports/*' element={<HRPEmployeeReportsRoutes />} />
      <Route path='/documents' element={<HRPDocuments />} />
      <Route path='/documents/:id' element={<HRPFilesPage />} />
    </Routes>
  )
}

export default HRPPage
