export interface Lead {
  _id?: string
  firstName: string
  lastName: string
  displayName: string
  email: string
  phone: string
  companyName: string
  customerType: 'business' | 'individual'
}

export interface Contact {
  _id?: string
  firstName: string
  lastName: string
  displayName: string
  email: string
  phone: string
  companyName: string
  customerType: 'business' | 'individual'
}

export const pipelineStatus = [
  {status: 'new', color: 'primary'},
  {status: 'prospect', color: 'warning'},
  {status: 'proposal', color: 'info'},
  {status: 'closed', color: 'success'},
  {status: 'rejected', color: 'danger'},
]
