import React, {useEffect, useState} from 'react'
import Thumbnail from '../Components/Thumbnail'
import Status from '../Components/Status'
import ProductTemplate from '../Components/ProductTemplate'
import General from '../Components/General'
import MetaOptions from '../Components/MetaOptions'
import {useNavigate, useParams} from 'react-router-dom'
import {getCategory} from '../Core/_categoryRequest'
import Automation from '../Components/Automation'
import { useAuth } from '../../../modules/auth'

export default function ViewCategory() {
  const {currentUser} = useAuth()
  const [selectedImage, setSelectedImage] = useState(null)
  const [previewImage, setPreviewImage] = useState(null)
  const [isProduct, setIsProduct] = useState(false)

  const [state, setState] = useState({
    thumbnail: null,
    status: 'Published',
    scheduleDate: '',
    template: '',
    categoryName: '',
    categoryDescription: '',
    metaTagTitle: '',
    description: '',
    metaTagKeywords: '',
    isManualChecked: false,
    isAutomaticChecked: false,
    allConditionsChecked: false,
    anyConditionsChecked: false,
    conditions: [],
    categoryType: '',
  })

  const navigate = useNavigate()

  const handleCancel = () => {
    navigate('/category')
  }

  const params = useParams()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const products = await getCategory(currentUser?.organization)
        const selectedCategory = products.find((category) => category._id === params.id)

        if (selectedCategory) {
          setState((prev) => ({
            ...prev,
            thumbnail: selectedCategory.thumbnail,
            status: selectedCategory.status,
            scheduleDate: selectedCategory.scheduleDate,
            template: selectedCategory.template,
            categoryName: selectedCategory.categoryName,
            categoryDescription: selectedCategory.categoryDescription,
            metaTagTitle: selectedCategory.metaTagTitle,
            description: selectedCategory.description,
            metaTagKeywords: selectedCategory.metaTagKeywords,
            isManualChecked: selectedCategory.isManualChecked,
            isAutomaticChecked: selectedCategory.isAutomaticChecked,
            allConditionsChecked: selectedCategory.allConditionsChecked,
            anyConditionsChecked: selectedCategory.anyConditionsChecked,
            conditions: selectedCategory.conditions,
            categoryType: selectedCategory.categoryType,
            selectedImage: selectedCategory.thumbnail,
          }))
        }
        if (selectedCategory.thumbnail) {
          const s3ImageUrl = `https://qf-assets.s3.me-central-1.amazonaws.com/${selectedCategory.thumbnail}`

          setPreviewImage(s3ImageUrl)
        }
      } catch (error) {
        console.error('Error fetching product:', error)
      }
    }
    fetchData()
  }, [params.id])

  return (
    <form encType='multipart/form-data'>
      <div className='container-fluid bg-gray-50 p-4'>
        <div className='row my-4 '>
          <div className='col-xl-4 space-y-6'>
            <Thumbnail
              previewImage={previewImage}
              setPreviewImage={setPreviewImage}
              thumbnail={state.thumbnail}
              setThumbnail={(thumbnail) => setState((prev) => ({...prev, thumbnail}))}
              selectedImage={selectedImage}
              setSelectedImage={setSelectedImage}
            />

            <Status
              status={state.status}
              setStatus={(status) => setState((prev) => ({...prev, status}))}
              scheduleDate={state.scheduleDate}
              setScheduleDate={(scheduleDate) => setState((prev) => ({...prev, scheduleDate}))}
            />
            <ProductTemplate
              template={state.template}
              setTemplate={(template) => setState((prev) => ({...prev, template}))}
            />
          </div>

          <div class='d-flex flex-column flex-row-fluid gap-7 gap-lg-10 col-xl-8'>
            <General
              productName={state.categoryName}
              isProduct={isProduct}
              setProductName={(categoryName) => setState((prev) => ({...prev, categoryName}))}
              productDescription={state.categoryDescription}
              setProductDescription={(categoryDescription) =>
                setState((prev) => ({...prev, categoryDescription}))
              }
            />

            <MetaOptions
              metaTagTitle={state.metaTagTitle}
              setMetaTagTitle={(metaTagTitle) => setState((prev) => ({...prev, metaTagTitle}))}
              description={state.description}
              setDescription={(description) => setState((prev) => ({...prev, description}))}
              metaTagKeywords={state.metaTagKeywords}
              setMetaTagKeywords={(metaTagKeywords) =>
                setState((prev) => ({...prev, metaTagKeywords}))
              }
            />

            <Automation
              isManualChecked={state.isManualChecked}
              setIsManualChecked={(value) =>
                setState((prev) => ({...prev, isManualChecked: value}))
              }
              isAutomaticChecked={state.isAutomaticChecked}
              setIsAutomaticChecked={(value) =>
                setState((prev) => ({...prev, isAutomaticChecked: value}))
              }
              allConditionsChecked={state.allConditionsChecked}
              setAllConditionsChecked={(value) =>
                setState((prev) => ({...prev, allConditionsChecked: value}))
              }
              anyConditionsChecked={state.anyConditionsChecked}
              setAnyConditionsChecked={(value) =>
                setState((prev) => ({...prev, anyConditionsChecked: value}))
              }
              conditions={state.conditions}
              setConditions={(value) => setState((prev) => ({...prev, conditions: value}))}
              categoryType={state.categoryType}
              setCategoryType={(categoryType) => setState((prev) => ({...prev, categoryType}))}
            />
          </div>

          <div className='text-end mt-4 space-x-3 '>
            <button type='button' className='btn hover:bg-gray-100 p-2 px-4' onClick={handleCancel}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}
