import React, {useEffect, useState} from 'react'
import Select from 'react-select'
import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/themes/material_blue.css' // Choose a theme from the flatpickr themes folder
import 'flatpickr/dist/flatpickr.min.css' // Import the Flatpickr CS
import {useAuth} from '../../../modules/auth/core/Auth'
import {KTIcon} from '../../../../_metronic/helpers'
import {useNavigate} from 'react-router-dom'
import {getAccountsForPCR, postPCR} from '../core/_requests'
import {getEmployeeByStatus} from '../../hrm/core/_requests'

function AddPCR() {
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const [submitted, setSubmitted] = useState(false)
  const [isEditingNotes, setIsEditingNotes] = useState(false)
  const [pcrAccounts, setPCRAccounts] = useState({})
  const [loading, setLoading] = useState(false)
  const [employeeList, setEmployeeList] = useState([])
  const [selectedEmployee, setSelectedEmployee] = useState({})

  const [pcr, setPcr] = useState({
    date: new Date(),
    pcrAccount: '',
    amount: 0,
    description: '',
    notes: '',
    employeeName: '',
    employee: '',
  })

  const fetchAccounts = async () => {
    const res = await getAccountsForPCR(currentUser?.organization)
    let data = {
      cash: [],
    }
    res.forEach((r) => {
      if (r.accountType === 'cash') {
        data['cash'].push({
          value: r?._id,
          label: r?.accountName,
        })
      }
    })
    setPCRAccounts(data)
    setPcr((prev) => ({
      ...prev,
      pcrAccount: data['cash'][0]?.value,
    }))
  }

  const handleEditClick = (e, field) => {
    e.preventDefault()
    if (field === 'notes') {
      setIsEditingNotes(!isEditingNotes)
    }
  }

  useEffect(() => {
    const fetchEmployees = async () => {
      const res = await getEmployeeByStatus('filter_status=true', currentUser.organization)
      const list = res.map((item, index) => ({
        value: item?._id,
        label: item?.firstName + ' ' + item?.lastName,
      }))
      setEmployeeList(list)
    }

    fetchAccounts()
    fetchEmployees()
  }, [])

  const handleChange = (e) => {
    e.preventDefault()
    const {name, value} = e.target
    const rawValue = value.replace(/,/g, '') // Remove commas from the value

    setPcr((prevPcr) => ({
      ...prevPcr,
      [name]: rawValue, // Store the raw value without commas
    }))
  }

  const formatNumber = (num) => {
    if (!num) return ''
    return Number(num).toLocaleString('en-US')
  }

  const handleBlur = (e) => {
    const {name, value} = e.target
    const rawValue = value.replace(/,/g, '') // Ensure raw value is saved
    setPcr((prevPcr) => ({
      ...prevPcr,
      [name]: rawValue,
    }))
  }

  const handleEmployeeChange = (selectedOption) => {
    setSelectedEmployee(selectedOption)
    setPcr((prevInvoice) => ({
      ...prevInvoice,
      employee: selectedOption.value,
      employeeName: selectedOption.label,
    }))
  }

  const handleDateChange = (selectedDate) => {
    setPcr((prevInvoice) => ({
      ...prevInvoice,
      date: selectedDate[0], // Flatpickr returns an array of selected dates
    }))
  }

  const handleSubmit = async () => {
    setLoading(true)
    if (pcr.employee === '' || pcr.employee === null || pcr.description === '') {
      alert('Please Fill all required details')
      return
    }
    if (pcr.amount < 0) {
      alert('Please Fill all required details')
      return
    }

    const data = {
      ...pcr,
      employee: pcr.employee !== '' ? pcr.employee : null,
      company: currentUser?.company,
      organization: currentUser?.organization,
    }
    try {
      await postPCR(data).then((res) => {
        setSubmitted(true)
        setLoading(false)
        navigate(`/account/pcr`)
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className='container-lg'>
      <div className='row justify-content-center'>
        <div className='col-lg-6'>
          <div className='card card-custom card-stretch'>
            <div className='card-body'>
              <h2 className='text-center mb-4'>Petty Cash Request</h2>
              <form>
                <div className='mb-3'>
                  <label htmlFor='date' className='form-label required'>
                    Date
                  </label>
                  <Flatpickr
                    value={pcr.date}
                    onChange={handleDateChange}
                    className='form-control form-control-solid'
                    placeholder='Pick date'
                    options={{dateFormat: 'd-m-Y'}}
                  />
                </div>
                <div className='mb-0'>
                  <div className='mb-3'>
                    <label className='form-label required fs-6 fw-bold text-gray-700 mb-3'>
                      Account
                    </label>
                    {/* begin::Input group */}
                    <select
                      className='form-select form-select-solid'
                      value={pcr.pcrAccount}
                      name='pcrAccount'
                      onChange={handleChange}
                    >
                      {Object.keys(pcrAccounts).map((key, index) => {
                        return (
                          <optgroup label={key} key={index}>
                            {pcrAccounts[key].map((item) => {
                              return (
                                <option value={item?.value} key={item?.value}>
                                  {item?.label}
                                </option>
                              )
                            })}
                          </optgroup>
                        )
                      })}
                    </select>
                  </div>

                  <div className='mb-3'>
                    <label className='form-label fs-6 fw-bold text-gray-700 mb-3 required'>
                      Employee
                    </label>
                    {/* begin::Input group */}
                    <Select
                      name='employee'
                      options={employeeList}
                      isSearchable={true}
                      className='form-control form-control-solid'
                      onChange={handleEmployeeChange}
                      value={selectedEmployee}
                      placeholder='Select Employee'
                    />
                  </div>

                  <div className='mb-3'>
                    <label className='form-label required fs-6 fw-bold text-gray-700 mb-3'>
                      Amount
                    </label>
                    {/* begin::Input group */}
                    <input
                      type='text'
                      name='amount'
                      className='form-control form-control-solid'
                      placeholder='Enter amount'
                      value={formatNumber(pcr.amount)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {/* Input group */}
                  </div>

                  <div className='mb-3'>
                    <label className='form-label fs-6 fw-bold text-gray-700 mb-3 required'>
                      Description
                    </label>
                    {/* begin::Input group */}
                    <input
                      type='text'
                      name='description'
                      className='form-control form-control-solid'
                      placeholder='Enter reference number'
                      value={pcr.description}
                      onChange={handleChange}
                    />
                  </div>

                  <div className='mb-3'>
                    <label className='form-label fs-6 fw-bold text-gray-700'>Notes</label>
                    <textarea
                      name='notes'
                      className={`position-relative  form-control form-control-solid mb-0 h-10 ${
                        isEditingNotes ? 'border border-dark-subtle' : ''
                      }`}
                      placeholder='Enter notes'
                      value={pcr.notes}
                      onChange={handleChange}
                    />
                  </div>

                  <div className='text-center'>
                    {!submitted ? (
                      <button
                        type='button'
                        className='btn btn-primary w-30'
                        id='kt_quote_submit_button'
                        onClick={handleSubmit}
                        disabled={loading}
                      >
                        <i className='ki-duotone ki-triangle fs-3'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                          <span className='path3'></span>
                        </i>
                        {loading ? 'Loading...' : 'Save PCR'}
                      </button>
                    ) : (
                      <button className='btn btn-danger w-100' id='kt_quote_submit_button'>
                        <i className='ki-duotone ki-triangle fs-3'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                          <span className='path3'></span>
                        </i>
                        Print Expense
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddPCR
