import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  camps: [],
  isLoading: false,
  fetch: true,
}

const campsSlice = createSlice({
  name: 'camps',
  initialState,
  reducers: {
    setCamps: (state, action) => {
      state.camps = action.payload
      state.isLoading = false
      state.fetch = false
    },
    setCampsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setCampsDataFetch: (state) => {
      state.fetch = true
    },
  },
})

export const {setCamps, setCampsLoading, setCampsDataFetch} = campsSlice.actions
export default campsSlice.reducer
