import React, {useEffect, useState} from 'react'
import {useParams, useNavigate} from 'react-router-dom'
import {getStatementsByCustomerId} from '../Core/_requests'
import {formatCurrency, formatCurrencyWithoutSymbol} from '../../../utils/utils'
import {useAuth} from '../../../modules/auth'

export default function Statements({currency = 'SAR'}) {
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const id = useParams().customerId
  const [statements, setStatements] = useState([])
  const [totalInvoice, setTotalInvoice] = useState(0)
  const [totalPayment, setTotalPayment] = useState(0)
  const [dateFilter, setDateFilter] = useState('30')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchStatements = async () => {
      setLoading(true)
      const res = await getStatementsByCustomerId(id, dateFilter, currentUser.organization)
      setStatements(res?.transactions)
      setTotalInvoice(res?.invoiceTotal)
      setTotalPayment(res?.paymentReceivedTotal)
      setLoading(false)
    }

    fetchStatements()
  }, [dateFilter, id])

  const formatDate = (date) => {
    const d = new Date(date)
    // return date as 30/12/2021
    return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
  }

  const toggleEmailComposer = () => {
    navigate(`email-composer/${dateFilter}`)
  }

  return (
    <div className='card'>
      <div className='card-header align-items-center border-0'>
        <div className='d-flex space-x-2'>
          <select
            className='form-select'
            value={dateFilter}
            onChange={(e) => {
              setDateFilter(e.target.value)
            }}
          >
            <option value='1'>Today</option>
            <option value='7'>This Week</option>
            <option value='30'>This Month</option>
            <option value='90'>This Quarter</option>
            <option value='365'>This Year</option>
          </select>
        </div>
        <div className='d-flex space-x-2'>
          <div className='symbol symbol-45px me-2'>
            <span className='symbol-label'>
              <span class='ki-outline ki-printer fs-2 '></span>
            </span>
          </div>
          <div className='symbol symbol-45px me-2'>
            <span className='symbol-label'>
              <i class='bi bi-file-pdf fs-2 '></i>
            </span>
          </div>

          <div className='symbol symbol-45px me-2'>
            <span className='symbol-label'>
              <i class='bi bi-filetype-xls'></i>
            </span>
          </div>
          <button className='btn btn-primary whitespace-nowrap' onClick={toggleEmailComposer}>
            <i class='bi bi-envelope me-2 fs-2'></i>
            Compose Email
          </button>
        </div>
      </div>
      {loading ? (
        <div className='d-flex justify-content-center align-items-center h-500px'>
          <div className='loader'></div>
        </div>
      ) : (
        <div className='card-body'>
          {/* accounts summary */}
          <div className='d-flex justify-end'>
            <div className='d-flex flex-column gap-3'>
              <div>
                <h3 className='uppercase'>Statement of Accounts</h3>
              </div>
              {/* flex column */}
              <div>
                <h4 className='bg-dark text-white p-1'>Account Summary</h4>
                <div className='d-flex flex-column justify-content-between'>
                  <div className='d-flex justify-between mb-3'>
                    <p>Invoiced Amount</p>
                    <h4>{formatCurrency(totalInvoice, currentUser?.baseCurrency)}</h4>
                  </div>
                  <div className='d-flex justify-between gap-2'>
                    <p>Payment Received</p>
                    <h4>{formatCurrency(totalPayment, currentUser?.baseCurrency)}</h4>
                  </div>
                  <hr />
                  <div className='d-flex justify-between'>
                    <p>Balance Due</p>
                    <h4>
                      {formatCurrency(totalInvoice - totalPayment, currentUser?.baseCurrency)}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='table-responsive mt-5'>
            <table className='table table-borderless'>
              <thead>
                <tr className='bg-dark text-white'>
                  <th scope='col' className='ps-2'>
                    Date
                  </th>
                  <th scope='col'>Transaction</th>
                  <th scope='col'>Details</th>
                  <th scope='col'>Amount</th>
                  <th scope='col'>Payment</th>
                  <th scope='col'>Balance</th>
                </tr>
              </thead>
              <tbody>
                {statements.map((statement, index) => (
                  <tr key={index}>
                    <td className='ps-2'>{formatDate(statement.createdAt)}</td>
                    <td>{statement?.type}</td>
                    <td>{statement?.id}</td>
                    <td>
                      {statement.type !== 'payment receive'
                        ? statement.debit !== 0
                          ? formatCurrencyWithoutSymbol(statement?.debit)
                          : formatCurrencyWithoutSymbol(statement?.credit)
                        : ''}
                    </td>
                    <td>
                      {statement.type === 'payment receive'
                        ? statement.debit !== 0
                          ? formatCurrencyWithoutSymbol(statement?.debit)
                          : formatCurrencyWithoutSymbol(statement?.credit)
                        : ''}
                    </td>
                    <td>
                      {statement.type !== 'payment receive'
                        ? statement.debit !== 0
                          ? formatCurrencyWithoutSymbol(statement?.debit)
                          : formatCurrencyWithoutSymbol(statement?.credit)
                        : 0}
                    </td>
                  </tr>
                ))}
                {/* row for total balance due */}
                <tr className='border-top'>
                  <td></td>
                  <td></td>
                  <td></td>
                  {/* Balance due with colspan 2 */}
                  <td colSpan='2'>
                    <h5 className='text-center'>Balance Due</h5>
                  </td>
                  <td>
                    <h5>
                      {formatCurrency(totalInvoice - totalPayment, currentUser?.baseCurrency)}
                    </h5>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  )
}
