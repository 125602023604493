import React, {useEffect, useRef, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {
  getFilteredPaymentReceived,
  getFilteredPaymentReceivedByAgentId,
  invalidatePaymentReceived,
} from '../Core/_requests'
import {useAuth} from '../../../modules/auth'
import {Filtertype2, RootState, ValidFilter} from '../Core/_model'
import Filter2 from '../Components/Filter2'
import qs from 'qs'
import {useDispatch, useSelector} from 'react-redux'
import {setPaymentReceivedData} from '../../../redux/sales/paymentReceived/paymentReceivedSlice'
import {RiArrowDropDownLine} from 'react-icons/ri'
import Pagination from '../../purchaseOrders/Components/Pagination'
import debounce from 'lodash/debounce'
import DateFilter from '../../account/components/DateFilter'

const PaymentReceivedList = () => {
  const {currentUser} = useAuth()
  const [permissions, setPermissions] = useState<any>({})
  const [filteredPaymentReceiveList, setFilteredPaymentReceiveList] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [customerName, setCustomerName] = useState('')
  const [dropdown, setDropdown] = useState([])
  const dispatch = useDispatch()
  const paymentReceivedData = useSelector(
    (state: RootState) => state.sales.paymentReceived.paymentReceivedData
  )
  const initialRef = useRef(true)
  const [isLoading, setIsLoading] = useState(false)
  const [dateState, setDateState] = useState<any>({
    startDate: null,
    endDate: null,
  })

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const handleClick = (index) => {
    const newDropdownState = dropdown.map((_, i) => (i === index ? !dropdown[i] : false))
    setDropdown(newDropdownState)
  }

  const stringifyObject = (obj: ValidFilter): string => {
    const queryString = qs.stringify(
      {
        filter_validity: obj.filter.isValid,
        filter_approval: obj.filter.approval,
        filter_status: obj.filter.status,
        filter_customer: obj.filter.customer,
        customer_name: customerName,
        search_query: searchQuery,
      },
      {encodeValuesOnly: true, skipNulls: true, delimiter: '&'}
    )

    return queryString
  }

  const handleActivation = async (id) => {
    const res = await invalidatePaymentReceived(id)
    if (res) {
      if (permissions?.quotation?.admin === false) {
        getDataForAgent()
      } else {
        getData()
      }
    }
  }

  const getData = async () => {
    setIsLoading(true)
    const queryString = stringifyObject(searchFilters)
    try {
      const data = await getFilteredPaymentReceived(
        queryString,
        currentUser?.organization,
        currentPage,
        25,
        dateState
      )
      setFilteredPaymentReceiveList(data?.paymentReceiveds)
      setTotalPages(data?.totalPages)
      setDropdown(Array(data?.paymentReceiveds?.length)?.fill(false))
      return data?.paymentReceiveds
    } catch (error) {
      console.error('Payment received error', error)
    } finally {
      setIsLoading(false)
    }
  }

  const getDataForAgent = async () => {
    setIsLoading(true)
    const queryString = stringifyObject(searchFilters)
    try {
      const data = await getFilteredPaymentReceivedByAgentId(
        queryString,
        currentUser?._id,
        currentPage,
        25,
        dateState
      )
      setFilteredPaymentReceiveList(data?.paymentReceiveds)
      setTotalPages(data?.totalPages)
      setDropdown(Array(data?.paymentReceiveds?.length)?.fill(false))
      return data?.paymentReceiveds
    } catch (error) {
      console.error('Payment received error', error)
    } finally {
      setIsLoading(false)
    }
  }

  const getPaymentReceived = () => {
    if (dateState.startDate !== undefined && dateState.endDate !== undefined) {
      if (permissions?.paymentreceived?.admin === false) {
        getDataForAgent()
      } else if (
        permissions?.paymentreceived?.admin === true ||
        currentUser?.profileType === 'superadmin'
      ) {
        getData()
      }
    }
  }

  const getPaymentReceivedData = () => {
    if (paymentReceivedData.length === 0) {
      if (permissions?.paymentreceived?.admin === false) {
        getDataForAgent().then((res) => dispatch(setPaymentReceivedData(res)))
      } else if (
        permissions?.paymentreceived?.admin === true ||
        currentUser?.profileType === 'superadmin'
      ) {
        getData().then((res) => dispatch(setPaymentReceivedData(res)))
      }
    } else {
      setFilteredPaymentReceiveList(paymentReceivedData)
      setDropdown(Array(paymentReceivedData.length).fill(false))
    }
  }

  const debounceSearch = debounce(() => {
    getPaymentReceived()
  }, 500)

  useEffect(() => {
    if (initialRef.current) {
      initialRef.current = false
      return
    }

    debounceSearch()

    return () => debounceSearch?.cancel()
  }, [searchQuery, customerName, currentPage, dateState.endDate])

  useEffect(() => {
    getPaymentReceivedData()
  }, [permissions])

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'

    const dateObject = new Date(createdAt)
    const day = dateObject.getDate().toString().padStart(2, '0')
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0')
    const year = dateObject.getFullYear()

    return `${day}/${month}/${year}`
  }

  let searchFilters: ValidFilter = {
    filter: {
      isValid: true,
      approval: '',
    },
  }
  const setSearchFilter = (updateValues: {filter?: Partial<Filtertype2>}): void => {
    searchFilters = {
      ...searchFilters,
      ...(updateValues.filter && {
        filter: {
          ...searchFilters.filter,
          ...updateValues.filter,
        },
      }),
    }
  }
  const setValidityFilter = (isValid: boolean) => {
    setSearchFilter({
      filter: {
        isValid,
      },
    })
  }

  const handlereset = async () => {
    setSearchFilter({
      filter: {
        isValid: true,
        approval: '',
        status: '',
      },
    })
    setCurrentPage(1)
    getPaymentReceived()
  }

  const setApprovalFilter = (approval: string) => {
    setSearchFilter({
      filter: {
        isValid: searchFilters.filter.isValid,
        status: searchFilters.filter.status,
        approval: approval,
      },
    })
  }

  return (
    <div className='card card-flush'>
      <span className='card-label fw-bold fs-3 ps-15 pt-8 '>Payment Received</span>
      <div className='card-header border-0 pt-5'>
        <div className='flex gap-5'>
          <div className='d-flex flex-row card-title align-items-center gap-4'>
            <form
              data-kt-search-element='form'
              className='w-100 position-relative '
              autoComplete='off'
            >
              <KTIcon
                iconName='magnifier'
                className='fs-2 text-lg-1 text-gray-500 position-absolute top-50 translate-middle-y ms-2'
              />
              <input
                type='text'
                className='form-control form-control-solid w-250px ps-14'
                name='search'
                placeholder='Search (Payment ID and Reference No.)'
                data-kt-search-element='input'
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </form>
            <form
              data-kt-search-element='form'
              className='w-100 position-relative '
              autoComplete='off'
            >
              <KTIcon
                iconName='magnifier'
                className='fs-2 text-lg-1 text-gray-500 position-absolute top-50 translate-middle-y ms-2'
              />
              <input
                type='text'
                className='form-control form-control-solid w-250px ps-14'
                name='customer_name'
                placeholder='Search (Customer Name)'
                data-kt-search-element='input'
                value={customerName}
                onChange={(e) => setCustomerName(e.target.value)}
              />
            </form>
            <DateFilter dateState={dateState} setDateState={setDateState} />
          </div>

          <div className=' d-flex flex-row  card-title align-items-center'>
            <Filter2
              searchFilters={searchFilters}
              setSearchFilter={setSearchFilter}
              handlereset={handlereset}
              getDocsList={getPaymentReceived}
              setValidityFilter={setValidityFilter}
              statusOptions={[
                {value: 'partial', label: 'Partial'},
                {value: 'closed', label: 'Closed'},
              ]}
              setApprovalFilter={setApprovalFilter}
            />
          </div>
        </div>

        <div className='card-toolbar'>
          <Link to={'/sales/paymentReceived/add'} className='btn btn-sm btn-light-primary'>
            <KTIcon iconName='plus' className='fs-2' />
            Record Payment
          </Link>
        </div>
      </div>

      <div className='card-body py-3'>
        {isLoading ? (
          <div className='d-flex align-items-center justify-center'>
            <div className='loader'></div>
          </div>
        ) : (
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4 table-row-bordered'>
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-50px rounded-start'>Date</th>
                  <th className='min-w-150px'>Payment#</th>
                  <th className='min-w-150px'>Reference Number</th>
                  <th className='min-w-150px'>Customer Name</th>
                  <th className='min-w-100px'>Status</th>
                  <th className='min-w-150px'>Mode</th>
                  <th className='min-w-150px'>Amount</th>
                  {/* <th className='min-w-150px'>Unused Amount</th> */}
                  <th className='min-w-100px'>Approval</th>
                  {(permissions?.paymentreceived?.update ||
                    currentUser?.profileType === 'superadmin') && (
                    <th className='min-w-100px text-center'>Actions</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {Array.isArray(filteredPaymentReceiveList) &&
                  filteredPaymentReceiveList.map((item: any, index: number) => (
                    <tr key={item?._id?.toString()}>
                      <td>
                        <Link
                          to={`/sales/paymentReceived/${item._id}`}
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          {formatCreatedAt(item?.paymentDate)}
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/sales/paymentReceived/${item._id}`}
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          {item?.id}
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/sales/paymentReceived/${item._id}`}
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          {item?.invoiceNumber}
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/sales/paymentReceived/${item._id}`}
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          {item?.customer?.displayName}
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/sales/paymentReceived/${item._id}}`}
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          {item?.status}
                        </Link>
                      </td>

                      <td>
                        <Link
                          to={`/sales/paymentReceived/${item._id}`}
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          <span className={`badge  fs-6 fw-semibold `}> {item?.paymentMode}</span>
                        </Link>
                      </td>

                      <td>
                        <Link
                          to={`/sales/paymentReceived/${item._id}`}
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          <span className={`badge  fs-6 fw-semibold `}>
                            {item?.amountReceived
                              ? item?.amountReceived?.toLocaleString('en-US', {
                                  minimumFractionDigits: 2,
                                })
                              : ' '}{' '}
                            {item?.customer?.currency}
                          </span>
                        </Link>
                      </td>

                      {/* <td>
                        <Link
                          to={`/sales/paymentReceived/${item._id}`}
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          <span className={`badge  fs-6 fw-semibold `}>
                          </span>
                        </Link>
                      </td> */}

                      <td>
                        <Link
                          to={`/sales/paymentReceived/details/${item.id}`}
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          <span
                            className={`badge  fs-7 fw-semibold ${
                              item?.approval === 'pending'
                                ? 'badge-light-primary'
                                : item?.approval === 'accepted'
                                ? 'badge-light-success'
                                : 'badge-light-danger'
                            }`}
                          >
                            {item?.approval}
                          </span>
                        </Link>
                      </td>
                      {(permissions?.invoice?.update ||
                        currentUser?.profileType === 'superadmin') && (
                        <td className='relative'>
                          <div className='d-flex justify-content-center flex-shrink-0'>
                            <button
                              onClick={() => handleClick(index)}
                              className=' bg-gray-100 hover:bg-lime-50 px-6 py-2 flex items-center rounded self-end'
                            >
                              Actions <RiArrowDropDownLine />
                            </button>
                          </div>
                          {dropdown[index] && (
                            <div className='absolute flex flex-col top-16 right-[0px] z-50  bg-white  w-40 shadow-[0_16px_16px_6px_rgba(128,128,128,0.1)] rounded p-3 space-y-2'>
                              {item?.valid && (
                                <>
                                  <Link to={`edit/${item._id}`}>
                                    <button className='hover:bg-lime-50 p-2  text-start rounded'>
                                      Edit
                                    </button>
                                  </Link>

                                  <Link to={`revised/${item._id}`}>
                                    <button className='hover:bg-lime-50 p-2  text-start rounded'>
                                      Revised
                                    </button>
                                  </Link>

                                  <button
                                    className='hover:bg-lime-50 p-2 text-start rounded'
                                    onClick={() => handleActivation(item._id)}
                                  >
                                    Invalid
                                  </button>
                                </>
                              )}
                            </div>
                          )}
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {/* Pagination */}
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
      />
    </div>
  )
}

export default PaymentReceivedList
