import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface DateState {
  startDate: string
  endDate: string
}

interface JournalEntryState {
  journalEntryData: any[]
  loading: boolean
  dateState: DateState
  error: string | null
}

const initialState: JournalEntryState = {
  journalEntryData: [],
  dateState: {
    startDate: null,
    endDate: null,
  },
  loading: true,
  error: null,
}

const journalEntrySlice = createSlice({
  name: 'journalEntry',
  initialState,
  reducers: {
    setJournalEntryData(state, action) {
      state.journalEntryData = action.payload
      state.loading = false
      state.error = null
    },
    setDateRange(state, action: PayloadAction<DateState>) {
      state.dateState = action.payload
    },
    setLoading(state) {
      state.loading = true
      state.error = null
    },
    setError(state, action) {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {setJournalEntryData, setLoading, setError, setDateRange} = journalEntrySlice.actions
export default journalEntrySlice.reducer
