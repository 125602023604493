import React, {useEffect, useState} from 'react'
import {useAuth} from '../../../modules/auth'
import {
  deleteCRMAccount,
  getCRMAccounts,
  postCRMAccount,
  updateCRMAccountName,
} from '../core/_requests'
import {toast} from 'react-hot-toast'
import Swal from 'sweetalert2'
import {Button, Dropdown} from 'react-bootstrap'
import CRMAccountsModal from '../components/CRMAccountsModal'
import {Link} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../../redux/store'
import {setCRMAccounts, setCRMAccountsFetch} from '../../../redux/crm/accounts/accountsSlice'

const CRMAccountsPage = () => {
  const {currentUser} = useAuth()
  const [loading, setLoading] = useState(false)
  const [permissions, setPermissions] = useState<any>({})
  const accounts = useSelector((state: RootState) => state.crm.accounts.accounts)
  const crmAccountsFetch = useSelector((state: RootState) => state.crm.accounts.fetch)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [formData, setFormData] = useState({
    name: '',
  })
  const [isEdit, setIsEdit] = useState(false)
  const [editData, setEditData] = useState<any>({})
  const dispatch = useDispatch()

  useEffect(() => {
    if (currentUser.permissions) {
      setPermissions(currentUser.permissions)
    }
  }, [currentUser])

  const fetchAccounts = async () => {
    setLoading(true)
    const response = await getCRMAccounts(currentUser.organization)
    dispatch(setCRMAccounts(response))
    setLoading(false)
  }

  useEffect(() => {
    if (currentUser.organization !== undefined && crmAccountsFetch) {
      fetchAccounts()
    }
  }, [currentUser])

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    if (formData.name === '') {
      toast.error('Name is required')
      return
    }
    const data = {
      name: formData.name,
      organization: currentUser.organization,
      company: currentUser.company,
    }
    const response = await postCRMAccount(data)
    if (response) {
      toast.success('Account added successfully')
      const temp = [...accounts]
      temp.push(response)
      dispatch(setCRMAccounts(temp))
      setIsModalOpen(false)
    }
  }

  const handleEdit = async (e: any) => {
    e.preventDefault()
    const response = await updateCRMAccountName(editData._id, formData)
    if (response) {
      toast.success('Account updated successfully')
      const temp = [...accounts]
      const index = temp.findIndex((item) => item._id === editData._id)
      temp[index] = response
      dispatch(setCRMAccounts(temp))
      setIsModalOpen(false)
    }
  }

  const handleDelete = async (id: string) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to delete this account. This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    })

    if (result.isConfirmed) {
      const response = await deleteCRMAccount(id)
      if (response) {
        toast.success('Account deleted successfully')
        const temp = [...accounts]
        dispatch(setCRMAccounts(temp.filter((item) => item._id !== id)))
      }
    }
  }

  return (
    <div className='container-fluid'>
      <div className='card'>
        <div className='card-header d-flex justify-content-between align-items-center'>
          <h3>Accounts</h3>
          {permissions?.crmaccounts?.create ||
            (currentUser?.profileType === 'superadmin' && (
              <Button
                variant='primary'
                onClick={() => {
                  setIsModalOpen(true)
                  setIsEdit(false)
                  setFormData({
                    name: '',
                  })
                }}
              >
                Add Account
              </Button>
            ))}
        </div>
        <div className='card-body'>
          {loading ? (
            <div className='d-flex align-items-center justify-center'>
              <div className='loader'></div>
            </div>
          ) : (
            <div className='table-responsive min-h-500px'>
              <table className='table align-xRmiddle gs-0 gy-4 table-row-bordered'>
                <thead>
                  <tr className='fw-bold text-muted bg-light'>
                    <th className='ps-4 min-w-25px text-center'>Sr. No.</th>
                    <th className='min-w-150px'>Name</th>
                    <th className='min-w-100px text-center'>No. of Leads</th>
                    <th className='min-w-100px text-center'>No. of Contacts</th>
                    {(permissions?.crmaccounts?.update ||
                      currentUser?.profileType === 'superadmin') && (
                      <th className='min-w-100px text-center'>Actions</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {accounts?.map((account: any, index: number) => (
                    <tr key={account._id}>
                      <td className='ps-4 text-center'>{index + 1}</td>
                      <td>
                        <Link to={`${account._id}`}>{account?.name}</Link>
                      </td>
                      <td className='text-center'>{account?.leads?.length}</td>
                      <td className='text-center'>{account?.contacts?.length}</td>
                      {(permissions?.crmaccounts?.update ||
                        currentUser?.profileType === 'superadmin') && (
                        <td className='text-center'>
                          <Dropdown>
                            <Dropdown.Toggle variant='secondary' size='sm' id='dropdown-basic'>
                              Action
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => {
                                  setIsEdit(true)
                                  setFormData(account)
                                  setIsModalOpen(true)
                                  setEditData(account)
                                }}
                              >
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item onClick={() => handleDelete(account._id)}>
                                Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
      <CRMAccountsModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={isEdit ? handleEdit : handleSubmit}
        formData={formData}
        setFormData={setFormData}
        isEdit={isEdit}
      />
    </div>
  )
}

export default CRMAccountsPage
