import React, {useEffect, useState} from 'react'
import {getAccounts} from '../Core/_requests'
import {useAuth} from '../../../modules/auth'

interface AccountsInformationProps {
  salesInfo: boolean
  purchaseInfo: boolean
  salesAccount: string
  purchaseAccount: string
  sellingPrice: string
  costPrice: string
  setSalesInfo: (value: boolean) => void
  setPurchaseInfo: (value: boolean) => void
  setSalesAccount: (value: string) => void
  setPurchaseAccount: (value: string) => void
  setSellingPrice: (value: string) => void
  setCostPrice: (value: string) => void
  categoryType?: string
}

const AccountsInformation = ({
  salesInfo,
  purchaseInfo,
  salesAccount,
  purchaseAccount,
  sellingPrice,
  costPrice,
  setSalesInfo,
  setPurchaseInfo,
  setSalesAccount,
  setPurchaseAccount,
  setSellingPrice,
  setCostPrice,
  categoryType,
}: AccountsInformationProps) => {
  const {currentUser} = useAuth()
  const [incomeAccounts, setIncomeAccounts] = useState([])
  const [expenseAccounts, setExpenseAccounts] = useState([])

  const fetchIncomeAccounts = async () => {
    const res = await getAccounts(currentUser?.organization, 'income')
    const data = res.map((item) => {
      return {
        value: item._id,
        label: item.accountName,
      }
    })
    setIncomeAccounts(data)
  }

  const fetchExpenseAccounts = async () => {
    const res = await getAccounts(currentUser?.organization, 'expense')
    const data = res.map((item) => {
      return {
        value: item._id,
        label: item.accountName,
      }
    })
    setExpenseAccounts(data)
  }

  useEffect(() => {
    fetchIncomeAccounts()
    fetchExpenseAccounts()
  }, [])

  return (
    <div className='card card-flush py-4'>
      <div className='card-header'>
        <div className='card-title'>
          <input
            type='checkbox'
            checked={salesInfo}
            className='form-check-input w-20px h-20px me-4'
            onChange={(e) => {
              setSalesInfo(e.target.checked)
              setSalesAccount('')
            }}
          />
          <h2>Sales Information</h2>
        </div>
      </div>

      <div className='card-body pt-0'>
        <div className='mb-10 fv-row'>
          <label className={categoryType === 'rentals' ? 'form-label' : 'form-label required'}>
            Selling Price
          </label>
          <input
            type='number'
            name='quantity'
            min='0'
            className='form-control mb-2 form-control-solid w-25'
            placeholder={currentUser?.baseCurrency}
            value={sellingPrice}
            onChange={(e) => setSellingPrice(e.target.value)}
          />
        </div>
        <div className='mb-10 fv-row'>
          <label className='required form-label'>Account</label>

          <select
            name='accountType'
            onChange={(e) => setSalesAccount(e.target.value)}
            className='form-control form-control-solid w-75'
            disabled={!salesInfo}
            value={salesAccount}
          >
            <option value=''>Select an account</option>
            <optgroup label='Income'>
              {incomeAccounts?.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ))}
            </optgroup>
          </select>
        </div>
      </div>

      <div className='card-header'>
        <div className='card-title'>
          <input
            type='checkbox'
            checked={purchaseInfo}
            className='form-check-input w-20px h-20px me-4'
            onChange={(e) => {
              setPurchaseInfo(e.target.checked)
              setPurchaseAccount('')
            }}
          />
          <h2>Purchase Information</h2>
        </div>
      </div>

      <div className='card-body pt-0'>
        <div className='mb-10 fv-row'>
          <label className={categoryType === 'rentals' ? 'form-label' : 'form-label required'}>
            Cost Price
          </label>
          <input
            type='number'
            name='quantity'
            min='0'
            className='form-control mb-2 form-control-solid w-25'
            placeholder={currentUser?.baseCurrency}
            value={costPrice}
            onChange={(e) => setCostPrice(e.target.value)}
          />
        </div>
        <div className='mb-10 fv-row'>
          <label className='required form-label'>Account</label>

          <select
            name='purchaseaccountType'
            onChange={(e) => setPurchaseAccount(e.target.value)}
            className='form-control form-control-solid w-75'
            disabled={!purchaseInfo}
            value={purchaseAccount}
          >
            <option value=''>Select an account</option>
            <optgroup label='Expense'>
              {expenseAccounts?.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ))}
            </optgroup>
          </select>
        </div>
      </div>
    </div>
  )
}

export default AccountsInformation
