import {useRef, useState, useEffect} from 'react'
import PurchaseOrder from '../Components/PurchaseOrderDetails/PurchaseOrder'
import {useNavigate, useParams} from 'react-router-dom'
import {getPurchaseQuotationById, sendPurchaseOrderMailByID} from '../Core/_requests'
import * as bootstrap from 'bootstrap'
import PurchaseOrderMultiLang from '../Components/PurchaseOrderDetails/PurchaseOrderMultiLang'
import toast, {Toaster} from 'react-hot-toast'

function PurchaseOrderDetails() {
  const PF: any = 'https://qf-assets.s3.me-central-1.amazonaws.com/'
  const navigate = useNavigate()
  const [activeComponent, setActiveComponent] = useState('PurchaseOrderWithMinFooter')
  const [isLoading, setIsLoading] = useState(false)
  const [image, setImage] = useState(null)
  const [color, setColor] = useState('#ffffff')
  const [customizeCheckedField, setCustomizeCheckedField] = useState(true)
  const [modalName, setModalName] = useState('')
  const inputRef = useRef(null)
  const [emailLoading, setEmailLoading] = useState(false)
  const [data, setDeliveryNoteData] = useState<any>({
    docAttached: '',
  })
  const {id} = useParams()
  const fetchData = async () => {
    try {
      const response = await getPurchaseQuotationById(id)
      setDeliveryNoteData(response)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    fetchData()
  }, [])

  const switchToComponent = (component) => {
    console.log('component', component)
    if (activeComponent !== component) {
      setIsLoading(true) // Set loading state
      setTimeout(() => {
        setActiveComponent(component)
        setIsLoading(false)
        openModal(component) // Turn off loading state
      }, 1000) // Simulating a delay for demonstration purposes
    }
  }

  const openModal = (modalId) => {
    const modal = document.getElementById(modalId)
    if (modal) {
      const modalInstance = new bootstrap.Modal(modal)
      modalInstance.show()
    }
  }
  const handleModalChange = (id) => {
    setTimeout(() => {
      setModalName(id) // Passing handleModalClose as a callback
    }, 3000)
  }

  const handleModalClose = (id) => {
    const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0]
    const modal = document.getElementById(id)

    // Check if modalBackdrop exists and is not undefined
    if (modalBackdrop && typeof modalBackdrop !== 'undefined') {
      modalBackdrop.remove() // Remove backdrop manually
    }

    // Check if modal exists and is not undefined
    if (modal && typeof modal !== 'undefined') {
      modal.classList.remove('show')
      setTimeout(() => {
        modal.style.display = 'none' // Hide modal after transition
      }, 300)
    }
  }
  const InitialModalData = [
    // {
    //     id: 'stacked2',
    //     name: 'Invoice First',
    //     image: '/media/modalImagesInvoice/invoiceFirst/invoiceFirst.png',
    //     activeComponentName: 'Invoice',
    //     Footer: 'Themes 1',
    // },
    {
      id: 'stacked3',
      name: 'RFQ',
      image: '/media/modalImagesInvoice/invoiceSecond.png',
      activeComponentName: 'PurchaseOrderDetails',
      Footer: 'Themes 1',
    },
    {
        id: 'stacked4',
        name: 'Changed Footer',
        image: '/media/modalImagesInvoice/invoiceThird.png',
        activeComponentName: 'InvoiceThird',
        Footer: 'Themes 3',
    },
  ]
  const SecondaryModalData = [
    // {
    //     id: 'stacked2',
    //     data: [
    //         {
    //             activeComponentName: "Invoice",
    //             image: '/media/modalImagesInvoice/invoiceFirst/invoiceFirst.png',
    //         },
    //         {
    //             activeComponentName: "InvoiceArabic",
    //             image: '/media/modalImagesInvoice/invoiceFirst/invoiceArabic.png',

    //         },
    //         {
    //             activeComponentName: "InvoiceMultiLang",
    //             image: '/media/modalImagesInvoice/invoiceFirst/invoiceMultiLang.png',

    //         },
    //     ]
    // },
    {
      id: 'stacked3',
      data: [
        {
          activeComponentName: 'PurchaseOrderDetails',
          image: '/media/modalImagesInvoice/invoiceSecond.png',
        },
        {
          activeComponentName: 'PurchaseOrderDetailsArabic',
          image: '/media/modalImagesInvoice/invoiceFirst/invoiceArabic.png',
        },
        {
          activeComponentName: 'PurchaseOrderDetailsMultiLang',
          image: '/media/modalImagesInvoice/invoiceFirst/invoiceMultiLang.png',
        },
      ],
    },
    {
        id: 'stacked4',
        data: [
            {
                activeComponentName: "PurchaseOrderWithMinFooter",
                image: '/media/modalImagesInvoice/invoiceThird.png',
            },
        ]
    },
  ]

  const closeModal = () => {
    const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0]
    const modal = document.getElementById('kt_modal_stacked_1')

    // Check if modalBackdrop exists and is not undefined
    if (modalBackdrop && typeof modalBackdrop !== 'undefined') {
      modalBackdrop.remove() // Remove backdrop manually
    }

    // Check if modal exists and is not undefined
    if (modal && typeof modal !== 'undefined') {
      modal.classList.remove('show')
      setTimeout(() => {
        modal.style.display = 'none' // Hide modal after transition
      }, 300)
    }
  }

  const handlePrint = () => {
    window.print() // This will trigger the browser's print functionality
  }
  const handleImageClick = () => {
    inputRef.current.click()
  }
  const handleUpload = (event) => {
    const file = event.target.files[0]
    setImage(file)
  }
  // const sendEmail = async (id) => {
  //   setEmailLoading(true)
  //   try {
  //     const response = await sendPurchaseOrderMailByID(id)
  //     setEmailLoading(false)
  //     toast.success('Email sent successfully')
  //   } catch (error) {
  //     console.error('Axios error:', error)
  //     setEmailLoading(false)
  //     throw error
  //   }
  // }
  const toggleEmailComposer = () => {
    navigate('email-composer')
  }

  return (
    <>
      <Toaster position='top-right' reverseOrder={false} />
      <div id='kt_app_toolbar_container' className='app-container container-xxl d-flex flex-stack'>
        {/*begin::Page title*/}
        <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3 '>
          {/*begin::Title*/}
          <h1 className='page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0'>
            Purchase Order
          </h1>
          {/*end::Title*/}
          {/*begin::Breadcrumb*/}
          <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
            {/*begin::Item*/}
            <li className='breadcrumb-item text-muted'>Procurement </li>
            {/*end::Item*/}
            {/*begin::Item*/}
            <li className='breadcrumb-item'>
              <span className='bullet bg-gray-500 w-5px h-2px' />
            </li>
            {/*end::Item*/}
            {/*begin::Item*/}
            <li className='breadcrumb-item text-muted'>Purchase Manager</li>
            {/*end::Item*/}
            {/*begin::Item*/}
            <li className='breadcrumb-item'>
              <span className='bullet bg-gray-500 w-5px h-2px' />
            </li>
            {/*end::Item*/}
            {/*begin::Item*/}
            <li className='breadcrumb-item text-muted'>
              <a href='/sales/deliveryChallan' className='text-muted text-hover-primary'>
                Purchase Order
              </a>
            </li>
            {/*end::Item*/}
          </ul>
          {/*end::Breadcrumb*/}
        </div>
        <div className='d-flex gap-5'>
          <div className=' mb-8 d-flex gap-3'>
            {(data?.status === 'pending' && data?.approval === 'accepted') && (
              <button
                className='btn btn-sm btn-info'
                type='button'
                onClick={() => {
                  navigate(`/procurement/purchaseReceiveList/purchaseorder/${id}`)
                }}
              >
                Create Purchase Receive
              </button>
            )}
            {data?.approval === 'accepted' && <button className='btn btn-sm btn-success' type='button' onClick={toggleEmailComposer}>
              Compose Email
            </button>}

            {/* <button
              type='button'
              className={`btn btn-sm btn-success ${emailLoading ? 'disabled' : ''}`}
              onClick={() => sendEmail(id)}
              disabled={emailLoading}
            >
              {emailLoading ? 'Sending...' : 'Send Email'}
            </button> */}
            {data?.docAttached !== '' && (
              <div className=''>
                <button type='button' className='btn btn-sm btn-success'>
                  <a href={PF + data.docAttached} download className='text-white' target='_blank'>
                    View File
                  </a>
                </button>
              </div>
            )}
            <div className=''>
              <button
                type='button'
                className='btn btn-sm btn-primary '
                id='customizeDropdown'
                data-bs-toggle='dropdown'
                aria-expanded='false'
              >
                Customize
              </button>
              <ul className='dropdown-menu' aria-labelledby='customizeDropdown'>
                <li>
                  <button
                    className='dropdown-item'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_stacked_1'
                  >
                    Change Template
                  </button>
                </li>
                {/* Add more options as needed */}
                <li>
                  <button
                    className='dropdown-item'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_update_logo'
                  >
                    Update Logo
                  </button>
                </li>
                <li>
                  <button className='dropdown-item'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_update_color'
                  >Change Color</button>
                </li>
                <li>
                  <button className='dropdown-item' onClick={()=>{
                    setCustomizeCheckedField(!customizeCheckedField)
                  }}
                  >Verified By</button>
                </li>
              </ul>
            </div>
            {/* Button trigger modal */}
            <div
              className='modal fade'
              id='kt_modal_stacked_1'
              tabIndex={-1}
              aria-labelledby='ModalLabel'
              aria-hidden='true'
            >
              <div className='modal-dialog modal-dialog-centered '>
                <div className='modal-content'>
                  <div className='modal-header'>
                    <h1 className='modal-title fs-5' id='ModalLabel' aria-hidden='true'>
                      Select Template
                    </h1>
                    <button type='button' className='btn-close' data-bs-dismiss='modal' />
                  </div>
                  <div className='modal-body'>
                    <div className='d-flex flex-row justify-content-around'>
                      {InitialModalData &&
                        InitialModalData.map((item) => (
                          <div key={item.id} className='card col-4 '>
                            <div
                              className='card-body '
                              onClick={() => setModalName(item?.id)}
                              data-bs-toggle='modal'
                              data-bs-target='#stacked2'
                              data-bs-dismiss='modal'
                            >
                              <div className='sidebar-templete-container py-3 greyish-blue position-relative d-flex justify-content-center rounded-top-md'>
                                <div
                                  className='h-200 templete-blur cursor-pointer'
                                  data-ember-action=''
                                  data-ember-action-869={869}
                                >
                                  <div id='ember870' className='ember-view'>
                                    <img
                                      src={item.image}
                                      className='rounded '
                                      height={200}
                                      width={150}
                                    />{' '}
                                    {/**/}
                                  </div>
                                </div>{' '}
                              </div>
                            </div>
                            {activeComponent === `${item.activeComponentName}` && (
                              <div className='selected-label text-center bg-success text-white fw-bold rounded-xl'>
                                Selected
                              </div>
                            )}
                            {activeComponent !== `${item.activeComponentName}` && (
                              <div className='selected-label text-center bg-info text-white fw-bold rounded-xl'>
                                Choose
                              </div>
                            )}
                            <button type='button' className='btn btn-sm btn-primary mt-8 '>
                              {item.name}
                            </button>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='' data-bs-backdrop='static'>
              {
                <div
                  className='modal fade'
                  id={'stacked2'}
                  tabIndex={-1}
                  role='dialog'
                  aria-labelledby='ModalLabel2'
                  aria-hidden='true'
                >
                  <div className='modal-dialog modal-dialog-centered modal-lg'>
                    <div className='modal-content'>
                      <div className='modal-header'>
                        <h1 className='modal-title fs-5' id='ModalLabel2' aria-hidden='true'>
                          Select Language
                        </h1>
                        <button type='button' className='btn-close' data-bs-dismiss='modal' />
                      </div>
                      <div className='modal-body'>
                        {/* Your modal content */}
                        {/* Example buttons to switch between components */}

                        <div className='d-flex flex-row justify-content-around'>
                          {SecondaryModalData?.map(
                            (item) =>
                              modalName === item?.id && (
                                <>
                                  {item?.data?.map((dataItem, i) => (
                                    <div
                                      key={i}
                                      className='card col-4'
                                      data-bs-dismiss='modal'
                                      onClick={() =>
                                        switchToComponent(dataItem.activeComponentName)
                                      }
                                    >
                                      <div className='card-body '>
                                        <div className='sidebar-templete-container py-3 greyish-blue position-relative d-flex justify-content-center rounded-top-md'>
                                          <div
                                            className='h-200 templete-blur cursor-pointer'
                                            data-ember-action=''
                                            data-ember-action-869={869}
                                          >
                                            <div id='ember870' className='ember-view'>
                                              <img
                                                src={dataItem.image}
                                                className='rounded '
                                                height={200}
                                                width={150}
                                              />{' '}
                                              {/**/}
                                            </div>
                                          </div>{' '}
                                        </div>
                                      </div>
                                      {activeComponent === `${dataItem.activeComponentName}` && (
                                        <div className='selected-label text-center bg-success text-white fw-bold rounded-xl'>
                                          Selected
                                        </div>
                                      )}
                                      {activeComponent !== `${dataItem.activeComponentName}` && (
                                        <div className='selected-label text-center bg-info text-white fw-bold rounded-xl'>
                                          Choose
                                        </div>
                                      )}
                                      {/* <div className="card-footer">Themes 1</div> */}
                                      <button type='button' className='btn btn-primary mt-8'>
                                        {dataItem.activeComponentName}
                                      </button>
                                    </div>
                                  ))}
                                </>
                              )
                          )}
                        </div>
                        {/* Add more buttons for other components as needed */}
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
            <div
              className='modal fade'
              id='kt_modal_update_logo'
              tabIndex={-1}
              aria-labelledby='ModalLabel1'
              aria-hidden='true'
            >
              <div className='modal-dialog'>
                <div className='modal-content'>
                  <div className='modal-header'>
                    <h1 className='modal-title fs-5' id='ModalLabel1' aria-hidden='true'>
                      Modal title
                    </h1>
                    <button type='button' className='btn-close' data-bs-dismiss='modal' />
                  </div>
                  <div className='modal-body'>
                    <div className='d-flex align-items-center' data-bs-dismiss='modal'>
                      <div className='pl-4'>
                        <div className='upload-widget-small '>
                          <div className='file-upload-container'>
                            <div id='ember2451' className='ember-view'>
                              {/**/} {/**/}{' '}
                              <div className='dropzone ' onClick={handleImageClick}>
                                <button className='btn file-upload ' type='button'>
                                  <input
                                    ref={inputRef}
                                    type='file'
                                    accept='image/*'
                                    onChange={handleUpload}
                                    style={{display: 'none'}}
                                  />{' '}
                                  <div className='flex-column'>
                                    <div className=''>
                                      <i className='bi bi-box-arrow-up'></i>
                                    </div>{' '}
                                    <span>&nbsp;Upload Your Organization Logo</span>
                                  </div>
                                </button>{' '}
                                {/**/}
                              </div>
                            </div>
                          </div>
                          {/**/}
                        </div>
                      </div>{' '}
                      <div className='pl-4'>
                        <label>
                          This logo will be displayed in transaction PDFs and email notifications.
                        </label>{' '}
                        <p className='font-xs text-muted w-85'>
                          Preferred Image Dimensions: 240 x 240 pixels @ 72 DPI
                          <br />
                          Maximum File Size: 1MB
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='modal fade'
              id='kt_modal_update_color'
              tabIndex={-1}
              aria-labelledby='ModalLabel1'
              aria-hidden='true'
            >
              <div className='modal-dialog'>
                <div className='modal-content'>
                  <div className='modal-header'>
                    <h1 className='modal-title fs-5' id='ModalLabel1' aria-hidden='true'>
                      Change Color
                    </h1>
                    <button type='button' className='btn-close' data-bs-dismiss='modal' />
                  </div>
                  <div className='modal-body'>
                    <div className='d-flex align-items-center'>
                      {/* color picker */}
                      <div className='d-flex gap-2'>
                        <div className='color-picker'>
                          <input
                            type='color'
                            id='color'
                            name='color'
                            value={color}
                            onChange={(e) => setColor(e.target.value)}
                          />
                          <label htmlFor='color'>Choose your color</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*Modal Not in use */}
            {/*Modal Not in use */}
          </div>
          {data?.approval === 'accepted' && (
            <button
              className='hide-on-pdf btn btn-sm btn-danger print-button mb-8 align-items-right'
              id='kt_delivery_submit_button'
              onClick={handlePrint}
            >
              Print PO
            </button>
          )}
        </div>
      </div>

      <div id='pdf-content' className='app-content  print-content'>
        {isLoading && <p>Loading...</p>}

        {!isLoading && activeComponent === 'PurchaseOrderDetails' && (
          <PurchaseOrder companyLogo={image} color={color} setColor={setColor} minFooter={false} checkedBy={customizeCheckedField}/>
        )}
        {!isLoading && activeComponent === 'PurchaseOrderDetailsMultiLang' && (
          <PurchaseOrderMultiLang companyLogo={image} color={color} setColor={setColor}/>
        )}
        {!isLoading && activeComponent === 'PurchaseOrderWithMinFooter' && (
          <PurchaseOrder companyLogo={image} color={color} setColor={setColor} minFooter={true} checkedBy={customizeCheckedField}/>
        )}
      </div>
    </>
  )
}
export default PurchaseOrderDetails
