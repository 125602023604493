import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import '../print.css'
import {getSalesOrderDetailById} from '../../Core/_requests'
import {useAuth} from '../../../../modules/auth'
import {getCompanyById} from '../../../setting/core/_requests'

interface IBankDetails {
  bankName: string
  accountNumber: string
  iBANNumber: string
}

function SalesOrderMultiLang({companyLogo, color, setColor}) {
  const {currentUser} = useAuth()
  const {id} = useParams()

  const NUMBERS = new Map([
    [1e9, 'Billion'],
    [1e6, 'Million'],
    [1e3, 'Thousand'],
    [1e2, 'Hundred'],
    [90, 'Ninety'],
    [80, 'Eighty'],
    [70, 'Seventy'],
    [60, 'Sixty'],
    [50, 'Fifty'],
    [40, 'Forty'],
    [30, 'Thirty'],
    [20, 'Twenty'],
    [19, 'Nineteen'],
    [18, 'Eighteen'],
    [17, 'Seventeen'],
    [16, 'Sixteen'],
    [15, 'Fifteen'],
    [14, 'Fourteen'],
    [13, 'Thirteen'],
    [12, 'Twelve'],
    [11, 'Eleven'],
    [10, 'Ten'],
    [9, 'Nine'],
    [8, 'Eight'],
    [7, 'Seven'],
    [6, 'Six'],
    [5, 'Five'],
    [4, 'Four'],
    [3, 'Three'],
    [2, 'Two'],
    [1, 'One'],
    [0, 'Zero'],
  ])

  const numberToWords = (num) => {
    for (const [intValue, textValue] of NUMBERS as any) {
      if (num >= intValue) {
        const prefix = num >= 100 ? numberToWords(Math.trunc(num / intValue)) : ''
        const suffix = num % intValue > 0 ? numberToWords(num % intValue) : ''
        return `${prefix} ${textValue} ${suffix}`.trim()
      }
    }
    return ''
  }

  const [vat, setVat] = useState(0)
  const [data, setSalesdata] = useState<any>({})
  const [page, setPage] = useState(1)
  const [bank, setBank] = useState<IBankDetails>({
    bankName: '',
    accountNumber: '',
    iBANNumber: '',
  })

  const [imageSize, setImageSize] = useState<boolean>(true)
  const [contactPerson, setContactPerson] = useState<any>({})
  const handleOnLoad = (e) => {
    const img = e.target
    if (img.naturalWidth > img.naturalHeight) {
      setImageSize(true)
    } else {
      setImageSize(false)
    }
  }

  useEffect(() => {
    const getCompany = async () => {
      const res = await getCompanyById(currentUser.company)
      setColor(res?.salesColor)

      setBank({
        bankName: res?.bankName || '',
        accountNumber: res?.accountNumber || '',
        iBANNumber: res?.iBANNumber || '',
      })
    }

    getCompany()
  }, [currentUser.company, setColor])

  useEffect(() => {
    const getSalesdata = async () => {
      const res = await getSalesOrderDetailById(id)
      setSalesdata(res)
      if (res?.items?.length > 5) {
        const pages = Math.ceil((res?.items.length - 5) / 20)
        if ((res?.items.length - 12) / 20 > 12) {
          setPage(pages + 2)
        } else {
          setPage(pages + 1)
        }
      }
      if (res?.contactPerson !== '') {
        setContactPerson(
          res?.customer?.contactPersons?.find((person) => person?._id === res?.contactPerson) || {}
        )
      } else {
        setContactPerson(res?.customer?.primaryContact)
      }
    }

    getSalesdata()
  }, [id])

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })
    return formattedDate
  }

  useEffect(() => {
    if (data?.tax) {
      const tax = (data?.subtotal * data?.tax) / 100
      setVat(tax)
    }
  }, [data])

  const PF = 'https://qf-assets.s3.me-central-1.amazonaws.com/'

  const PageContent = Array.from({length: page}).map((_, idx) => (
    <div
      key={idx}
      id={`pdf-content-${idx}`}
      className='card overflow-hidden mx-auto !rounded-none'
      style={{
        height: '297mm',
        width: '210mm',
      }}
    >
      {/* begin::Body */}
      <div className='p-2 mx-3 mt-6'>
        <div className='d-flex justify-content-between align-items-center flex-column flex-sm-row '>
          {/*end::Logo*/}
          <div className='text-sm-end'>
            {/*begin::Logo*/}
            <div className={`d-block ${imageSize ? 'mw-275px' : 'mw-150px'} ms-sm-auto`}>
              {companyLogo ? (
                <img
                  alt='Logo'
                  src={URL.createObjectURL(companyLogo)}
                  className={imageSize ? 'w-100' : 'w-50'}
                  onLoad={handleOnLoad}
                />
              ) : (
                <img
                  alt='thumbnail'
                  src={PF + data?.company?.companyLogo}
                  className={imageSize ? 'w-100' : 'w-50'}
                  onLoad={handleOnLoad}
                />
              )}
            </div>
            {/*end::Logo*/}
          </div>
          <h4 className='fw-bold text-gray-800 xl:fs-1 d-flex flex-column pb-7 text-right header-text'>
            <span
              className='mb-1 text-right fs-1 tracking-wider '
              style={{
                fontFamily: 'Rubik',
                fontOpticalSizing: 'auto',
                fontStyle: 'normal',
                wordSpacing: '10px',
              }}
            >
              {data?.company?.arabicName}
            </span>
            <span className='mb-1 text-right fs-3 tracking-wider'>{data?.company?.name}</span>
            <span className='fs-6 text-muted'>
              VAT:{data?.company?.vat} | C.R : {data?.company?.cr}
            </span>
          </h4>
        </div>
      </div>
      <div className='separator'></div>
      <div className='card-body px-lg-15'>
        {/* begin::Layout */}

        <div className='d-flex flex-column '>
          {/* begin::Content */}
          <div className='flex-lg-row-fluid mb-10 mb-xl-0'>
            {/* begin::Invoice 1 content */}
            <div className='mt-n1'>
              <div className=''>
                {/*begin::Top*/}
                {idx === 0 && (
                  <div className='text-center mb-6 fs-2 fw-bold text-gray-800'>
                    <div className=''>طلب المبيعات Sales Order</div>
                  </div>
                )}
                {/*end::Top*/}
                {/*begin::Wrapper*/}
                <div className='m-0'>
                  {idx === 0 && (
                    <div className='d-flex justify-content-between flex-column flex-sm-row'>
                      <div className=' mb-5 fw-bold'>
                        <div className='d-flex  flex-column mb-5'>
                          <span className='text-muted'>
                            رقم الطلب
                            <br />
                            Order NO
                          </span>
                          <span className='fs-7'>#{data?.reference}</span>
                        </div>
                        <div className='d-flex flex-column mb-5'>
                          <span className='text-muted'>
                            عميل
                            <br />
                            Customer
                          </span>

                          <span className='fs-7'>{data?.customer?.companyName}</span>
                        </div>
                        <div className='d-flex flex-md-root flex-column fw-bold mb-5'>
                          <span className='text-muted mb-1'>
                            عنوان العميل
                            <br />
                            Customer Address
                          </span>
                          <span className='fs-7 mw-250px'>
                            {data?.customer?.billingAddress.addressLine1}{' '}
                            {data?.customer?.billingAddress.addressLine2}
                            <br />
                            {data?.customer?.billingAddress.city}{' '}
                            {data?.customer?.billingAddress.postalCode}
                            <br />
                            {data?.customer?.billingAddress.country}
                          </span>
                        </div>
                      </div>
                      <div className='  mb-5  fw-bold'>
                        <div className='d-flex  flex-column mb-5'>
                          <span className='text-muted'>
                            انتباه
                            <br />
                            Attention
                          </span>
                          <span className='fs-7'>
                            {contactPerson?.salutation} {contactPerson?.firstName}{' '}
                            {contactPerson?.lastName}
                          </span>
                        </div>
                        <div className=' d-flex flex-column mb-5'>
                          <span className='text-muted'>
                            تعيين
                            <br />
                            Designation
                          </span>
                          <span className='fs-7'>{contactPerson?.designation}</span>
                        </div>
                        <div className=' d-flex flex-column  '>
                          <span className='text-muted'>
                            طريقة التوصيل
                            <br />
                            Delivery Method
                          </span>
                          <span className='fs-7'>{data?.deliveryMethod}</span>
                        </div>
                      </div>
                      <div className='text-end  mb-5  fw-bold'>
                        <div className='d-flex flex-column mb-5'>
                          <span className='text-muted'>
                            تاريخ الطلب
                            <br />
                            Order Date
                          </span>

                          <span className='fs-7'>{formatCreatedAt(data?.salesOrderDate)}</span>
                        </div>
                        <div className=' d-flex flex-column mb-5'>
                          <span className='text-muted'>
                            رقم المبيعات
                            <br />
                            Sales Number
                          </span>

                          <span className='fs-7'>{data?.id}</span>
                        </div>
                      </div>
                    </div>
                  )}
                  {/*end::Row*/}
                  {/*begin::Content*/}
                  <div className='flex-grow-1'>
                    {/*begin::Table*/}
                    <div className='table-responsive'>
                      <table className='table table-bordered m-0'>
                        <thead>
                          <tr
                            className='fs-9 fw-bold  '
                            style={{
                              backgroundColor: color,
                            }}
                          >
                            <th className='min-w-50px text-white text-start pb-3 '>
                              <span>
                                رقم سري
                                <br />
                                Sr. No.
                              </span>
                            </th>

                            <th className='min-w-175px text-white text-center pb-3 '>
                              Description
                            </th>

                            <th className='min-w-70px text-white text-center pb-3'>
                              كمية
                              <br />
                              Quantity
                            </th>
                            <th className='min-w-80px text-white text-center pb-3'>
                              وحدة قياس
                              <br />
                              UOM
                            </th>
                            <th className='min-w-80px text-white text-center pb-3'>
                              سعر الوحدة
                              <br />
                              Unit Price
                            </th>

                            <th className='min-w-80px text-white text-center pb-3'>
                              المجموع
                              <br />
                              Total
                            </th>
                          </tr>
                        </thead>
                        <tbody className='' style={{height: '250px'}}>
                          {data?.items
                            ?.slice(
                              idx === 1 || idx === 0 ? idx * 12 : idx * 20 - 8,
                              idx === 0 ? 12 : idx * 20 + 12
                            )
                            .map((item, index) => (
                              <tr
                                key={index}
                                className='border fw-semibold text-gray-600 fs-7 text-center'
                              >
                                <td className=' text-start'>
                                  {idx === 0
                                    ? index + 1
                                    : idx === 1
                                    ? index + 13
                                    : index + idx * 20 - 7}
                                </td>
                                <td className=' text-center '>
                                  <td>{item?.productName}</td>
                                </td>
                                <td>{item.quantity}</td>
                                <td className=''>UOM</td>
                                <td className=''>
                                  {Number(item?.price).toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                  })}{' '}
                                </td>
                                <td className=' text-gray-900 fw-bolder'>
                                  {Number(item?.amount).toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                  })}{' '}
                                </td>
                              </tr>
                            ))}
                          {(idx + 1 === page || page === 1) && (
                            <tr className=''>
                              <td colSpan={2} className='p-0 m-0'>
                                <table className='table table-bordered w-full m-0 p-0 '>
                                  <tbody className='min-w-80px '>
                                    <tr className='p-0 '>
                                      <td className='text-start d-flex justify-content-between'>
                                        <div className=''>
                                          <h6 className='mb-3 fw-bolder text-gray-600 text-hover-primary fs-7'>
                                            PAYMENT DETAILS
                                          </h6>
                                          <div className='mb-2 d-flex '>
                                            <div className='fw-semibold text-gray-600 fs-8 w-150px'>
                                              Bank Name:
                                            </div>
                                            <div className='fw-bold text-gray-800 fs-8'>
                                              {bank.bankName}
                                            </div>
                                          </div>
                                          <div className='mb-2 d-flex '>
                                            <div className='fw-semibold text-gray-600 fs-8 w-150px'>
                                              Account Number:
                                            </div>
                                            <div className='fw-bold text-gray-800 fs-8'>
                                              {bank.accountNumber}
                                            </div>
                                          </div>
                                          <div className='mb-2 d-flex'>
                                            <div className='fw-semibold text-gray-600 fs-8 w-150px'>
                                              IBAN Number:
                                            </div>
                                            <div className='fw-bold text-gray-800 fs-8'>
                                              {bank.iBANNumber}
                                            </div>
                                          </div>
                                        </div>
                                        <div className='text-end'>
                                          <h6 className='mb-2 fw-bolder text-gray-600 text-hover-primary fs-8'>
                                            بيانات الدفع
                                          </h6>
                                          <div className='mb-2'>
                                            <div className='fw-semibold text-gray-600 fs-9'>
                                              طريقة الدفع:
                                            </div>
                                            <div className='fw-bold text-gray-800 fs-8'>Cash</div>
                                          </div>
                                          <div className=''>
                                            <div className='fw-semibold text-gray-600 fs-9'>
                                              إيداع في:
                                            </div>
                                            <div className='fw-bold text-gray-800 fs-8'>Nl24IB</div>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>

                                    <tr className='p-0 fw-bold text-muted fs-9'>
                                      <td className='space-x-8'>
                                        <span className='fs-8 fw-bold text-dark'>
                                          المجموع بالكلمات
                                          <br />
                                          Total in words
                                        </span>
                                        {/* <br /> */}
                                        <span> {numberToWords(data?.total)}</span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td colSpan={2} rowSpan={3} className='p-0 text-sm'>
                                <table className='table table-bordered fs-8 w-full m-0 p-0 '>
                                  <thead className='hidden'>
                                    <th className='mw-80px'></th>
                                    <th className='mw-80px'></th>
                                  </thead>
                                  <tbody className='p-0'>
                                    <tr className='text-end  '>
                                      <td className='py-3'>
                                        المجموع الفرعي
                                        <br />
                                        Subtotal
                                        <br />
                                      </td>
                                    </tr>
                                    <tr className='text-end p-3'>
                                      <td className='py-4 '>
                                        إجمالي الوزن المبلغ({data?.tax}%)
                                        <br />
                                        Total VAT Amount ({data?.tax}%)
                                        <br />
                                      </td>
                                    </tr>
                                    <tr className='text-end p-3'>
                                      <td className='fw-bold py-4'>
                                        المجموع الإجمالي
                                        <br />
                                        Grand Total
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td colSpan={2} rowSpan={3} className='p-0 text-sm'>
                                <table className='table table-bordered fs-8 w-full m-0 p-0 '>
                                  <thead className='hidden'>
                                    <th className='min-w-80px'></th>
                                  </thead>
                                  <tbody>
                                    <tr className=' text-center p-2 '>
                                      <td className='py-6  text-end'>
                                        {data?.customer?.currency}
                                        <br />
                                      </td>
                                      <td className='py-6 '>
                                        {data?.subtotal?.toLocaleString('en-US', {
                                          minimumFractionDigits: 2,
                                        })}{' '}
                                        <br />
                                      </td>
                                    </tr>
                                    <tr className=' text-center p-2 '>
                                      <td className='py-6 text-end'>{data?.customer?.currency}</td>
                                      <td className='py-6 '>{vat}</td>
                                    </tr>
                                    <tr className='text-center p-3 fw-bold'>
                                      <td className='py-6 text-end'>{data?.customer?.currency}</td>
                                      <td className='py-5'>
                                        {data?.total?.toLocaleString('en-US', {
                                          minimumFractionDigits: 2,
                                        })}{' '}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              {/* end::Wrapper */}
            </div>
            {/* end::Invoice 2 content */}
          </div>
          {/* end::Content */}
          {/* begin::Sidebar */}
          {(idx + 1 === page || page === 1) && (
            <div className={`m-0  px-10 py-2 `}>
              <div className='d-flex  flex-column '>
                <h6 className=' fw-bold text-danger'>ملحوظات Notes</h6>
                <p
                  className='min-h-40px'
                  style={{
                    lineHeight: '8px',
                  }}
                >
                  {data?.notes?.split('\n').map((line, index) => (
                    <p key={index}>{line}</p>
                  ))}
                </p>

                <h6 className=' fw-bold text-danger'>الأحكام والشروط Terms and Conditions</h6>
                <p
                  className='min-h-40px'
                  style={{
                    lineHeight: '8px',
                  }}
                >
                  {data?.termsNCondition?.split('\n').map((line, index) => (
                    <p key={index}>{line}</p>
                  ))}
                </p>
              </div>
              {/* end::Invoice 2 sidebar */}
            </div>
          )}
          {/* end::Sidebar */}
        </div>
        {/* end::Layout */}
      </div>
      {/* end::Body */}
      <div id='pdfFooter' className='card-footer text-center p-2 fw-bold'>
        <div className='bg-secondary -skew-x-12 text-gray-800'>
          <div className='skew-x-12 py-1'>
            Mobile : {data?.company?.mobileNumber} | E-mail : {data?.company?.companyEmail} |
            Website : {data?.company?.webURL}
          </div>
        </div>
        <div
          className='-skew-x-12 text-white '
          style={{
            backgroundColor: `${color}`,
          }}
        >
          <div className='skew-x-12 py-1'>
            P.O. Box : {data?.company?.pOBox} | Address: {data?.company?.companyAddress}
          </div>
        </div>
      </div>
      <div className='d-flex justify-center mb-4'>
        <p className='fs-8 serif-font'>
          Page {idx + 1} of {page}
        </p>
      </div>
    </div>
  ))

  return (
    <>
      <div className='app-content flex-column-fluid '>
        {/* begin::Content container */}

        <div id='kt_app_content_container' className='print-content container-xxl'>
          {/* begin::Invoice 2 main */}
          {PageContent}
          {/* end::Invoice 2 main */}
        </div>
        {/* end::Content container */}
      </div>
    </>
  )
}

export default SalesOrderMultiLang
