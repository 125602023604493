import React, {useEffect, useRef, useState} from 'react'
import {getFilteredProject, getFilteredProjectByAgentId} from '../../projects/core/_requests'
import {useAuth} from '../../../modules/auth'
import {useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../../redux/store'
import {setProjects} from '../../../redux/projects/projectSlice'
import {Modal, Button, Form} from 'react-bootstrap'
import {getHRPProjects, postHRPProject} from '../core/_requests'
import toast from 'react-hot-toast'
import ProjectCard from '../../projects/Pages/ProjectCard'
import {setHRPProjects} from '../../../redux/hrp/projectsSlice'

const HRPProjectPage = () => {
  const {currentUser} = useAuth()
  const dispatch = useDispatch()
  const initialRef = useRef(true)
  const projectList = useSelector((state: RootState) => state.projects.projects.projects)
  const projectLoading = useSelector((state: RootState) => state.projects.projects.loading)
  const [permissions, setPermissions] = useState<any>({})
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedProjects, setSelectedProjects] = useState([])
  const [availableProjects, setAvailableProjects] = useState([])
  const projectDataList = useSelector((state: RootState) => state.hrp.projects.projects)
  const hrpProjectFetch = useSelector((state: RootState) => state.hrp.projects.fetch)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const getProjectData = async () => {
    try {
      const response = await getFilteredProject('filter_status=false', currentUser?.organization)
      setAvailableProjects(response)
      return response
    } catch (e) {
      console.log(e)
    }
  }

  const getProjectDataForAgent = async () => {
    try {
      const response = await getFilteredProjectByAgentId('filter_status=false', currentUser?._id)
      setAvailableProjects(response)
      return response
    } catch (e) {
      console.log(e)
    }
  }

  const fetchProjects = () => {
    if (permissions?.myproject?.admin === false) {
      if (initialRef.current) {
        getProjectDataForAgent().then((data) => {
          dispatch(setProjects(data))
        })
        initialRef.current = false
      } else {
        getProjectDataForAgent()
      }
    } else if (
      permissions?.myproject?.admin === true ||
      currentUser?.profileType === 'superadmin'
    ) {
      if (initialRef.current) {
        getProjectData().then((data) => {
          dispatch(setProjects(data))
        })
        initialRef.current = false
      } else {
        getProjectData()
      }
    }
  }

  useEffect(() => {
    if (projectList?.length === 0) fetchProjects()
    else {
      setAvailableProjects(projectList)
    }
  }, [permissions])

  const handleProjectSelect = (projectId) => {
    setSelectedProjects((prev) =>
      prev.includes(projectId) ? prev.filter((id) => id !== projectId) : [...prev, projectId]
    )
  }

  const fetchData = async () => {
    setLoading(true)
    const response = await getHRPProjects(currentUser?.organization)
    dispatch(setHRPProjects(response))
    const temp = response.map((project) => project._id)
    setSelectedProjects(temp)
    setLoading(false)
  }

  useEffect(() => {
    if (currentUser.organization !== undefined) {
      if (hrpProjectFetch) fetchData()
      else {
        const temp = projectDataList.map((project) => project._id)
        setSelectedProjects(temp)
      }
    }
  }, [currentUser.organization])

  const handleSubmit = async () => {
    try {
      if (selectedProjects.length === 0) {
        toast.error('Please select at least one project')
        return
      }
      const data = {
        project: selectedProjects,
        company: currentUser?.company,
        organization: currentUser?.organization,
      }
      await postHRPProject(data).then((res) => {
        toast.success('Projects added successfully')
        fetchData()
        setIsModalOpen(false)
      })
    } catch (error) {
      console.error('Error submitting projects:', error)
    }
  }

  if (projectLoading) {
    return (
      <div className='d-flex align-items-center justify-center'>
        <div className='loader'></div>
      </div>
    )
  }

  return (
    <div>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>Projects</h3>
        {(permissions?.hrpprojects?.update || currentUser?.profileType === 'superadmin') && (
          <Button variant='primary' onClick={() => setIsModalOpen(true)}>
            Add Project
          </Button>
        )}
      </div>

      <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Select Projects</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div style={{maxHeight: '60vh', overflowY: 'auto'}}>
              {availableProjects.map((project) => (
                <Form.Group key={project._id} className='mb-3'>
                  <Form.Check
                    type='checkbox'
                    id={`project-${project._id}`}
                    label={project?.projectName}
                    checked={selectedProjects.includes(project._id)}
                    onChange={() => handleProjectSelect(project._id)}
                  />
                </Form.Group>
              ))}
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setIsModalOpen(false)}>
            Close
          </Button>
          <Button variant='primary' onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {loading ? (
        <div className='d-flex align-items-center justify-center'>
          <div className='loader'></div>
        </div>
      ) : (
        <ProjectCard projectData={projectDataList} fetchProjects={fetchProjects} />
      )}
    </div>
  )
}

export default HRPProjectPage
