import React, {FC} from 'react'
import {ErrorMessage, Field, useFormikContext} from 'formik'
import {ICreateAccount} from '../../core/CreateAccountWizardHelper'

const Step3: FC = () => {
  const {setFieldValue, values} = useFormikContext<ICreateAccount>()

  const calculateValues = (changedField: string, value: number) => {
    const workingDaysPerMonth = 22 // Assuming 22 working days per month

    let monthlyPay = Number(values.monthlyPay) || 0
    let hourlyPay = Number(values.hourlyPay) || 0
    let dailyWorkingHours = Number(values.dailyWorkingHours) || 0
    let dailyPay = Number(values.dailyPay) || 0
    let overtimePay = Number(values.overtimePay) || 0

    switch (changedField) {
      case 'monthlyPay':
        monthlyPay = value
        dailyPay = monthlyPay / workingDaysPerMonth
        hourlyPay = dailyWorkingHours ? dailyPay / dailyWorkingHours : 0
        overtimePay = hourlyPay * 1.5
        break
      case 'hourlyPay':
        hourlyPay = value
        dailyPay = hourlyPay * dailyWorkingHours
        monthlyPay = dailyPay * workingDaysPerMonth
        overtimePay = hourlyPay * 1.5
        break
      case 'dailyWorkingHours':
        dailyWorkingHours = value
        dailyPay = hourlyPay * dailyWorkingHours
        monthlyPay = dailyPay * workingDaysPerMonth
        overtimePay = hourlyPay * 1.5
        break
      case 'dailyPay':
        dailyPay = value
        monthlyPay = dailyPay * workingDaysPerMonth
        hourlyPay = dailyWorkingHours ? dailyPay / dailyWorkingHours : 0
        overtimePay = hourlyPay * 1.5
        break
    }

    setFieldValue('monthlyPay', monthlyPay.toFixed(2))
    setFieldValue('hourlyPay', hourlyPay.toFixed(2))
    setFieldValue('dailyWorkingHours', dailyWorkingHours.toFixed(2))
    setFieldValue('dailyPay', dailyPay.toFixed(2))
    setFieldValue('overtimePay', overtimePay.toFixed(2))
  }

  const calculateInvoiceValues = (changedField: string, value: number) => {
    const workingDaysPerMonth = 22 // Assuming 22 working days per month

    let monthlyPay = Number(values.invoiceRate?.monthlyPay) || 0
    let hourlyPay = Number(values.invoiceRate?.hourlyPay) || 0
    let dailyWorkingHours = Number(values.invoiceRate?.dailyWorkingHours) || 0
    let overtimePay = Number(values.invoiceRate?.overtimePay) || 0

    switch (changedField) {
      case 'monthlyPay':
        monthlyPay = value
        hourlyPay = dailyWorkingHours ? monthlyPay / (workingDaysPerMonth * dailyWorkingHours) : 0
        overtimePay = hourlyPay * 1.5
        break
      case 'hourlyPay':
        hourlyPay = value
        monthlyPay = hourlyPay * dailyWorkingHours * workingDaysPerMonth
        overtimePay = hourlyPay * 1.5
        break
      case 'dailyWorkingHours':
        dailyWorkingHours = value
        monthlyPay = hourlyPay * dailyWorkingHours * workingDaysPerMonth
        overtimePay = hourlyPay * 1.5
        break
    }

    setFieldValue('invoiceRate.monthlyPay', monthlyPay.toFixed(2))
    setFieldValue('invoiceRate.hourlyPay', hourlyPay.toFixed(2))
    setFieldValue('invoiceRate.dailyWorkingHours', dailyWorkingHours.toFixed(2))
    setFieldValue('invoiceRate.overtimePay', overtimePay.toFixed(2))
  }

  // const InvoiceRateSection: FC = () => (

  // )

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>Salary Information</h2>
      </div>

      <div className='pb-10'>
        <h3 className='fw-bold text-dark'>Payroll Rate</h3>
        {/* <PayrollRateSection /> */}
      </div>

      {/* Payroll rate */}
      <div className='d-flex whitespace-nowrap space-x-4 items-center w-100 mb-10'>
        <label className='form-label required col-md-3'>Daily Working Hours:</label>
        <Field
          type='number'
          name='dailyWorkingHours'
          placeholder='0.00'
          className='form-control form-control-lg form-control-solid'
          required
          onChange={(e) => calculateValues('dailyWorkingHours', parseFloat(e.target.value) || 0)}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='dailyWorkingHours' />
        </div>
      </div>

      <div className='d-flex whitespace-nowrap space-x-4 items-center w-100 mb-10'>
        <label className='form-label required col-md-3'>Monthly Pay :</label>
        <Field
          type='number'
          name='monthlyPay'
          placeholder='0.00'
          className='form-control form-control-lg form-control-solid'
          required
          onChange={(e) => calculateValues('monthlyPay', parseFloat(e.target.value) || 0)}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='monthlyPay' />
        </div>
      </div>

      <div className='d-flex whitespace-nowrap space-x-4 items-center w-100 mb-10'>
        <label className='form-label required col-md-3'>Hourly Pay :</label>
        <Field
          type='number'
          name='hourlyPay'
          placeholder='0.00'
          className='form-control form-control-lg form-control-solid'
          required
          onChange={(e) => calculateValues('hourlyPay', parseFloat(e.target.value) || 0)}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='hourlyPay' />
        </div>
      </div>

      {/* <div className='mb-3 d-flex whitespace-nowrap space-x-4 items-center w-100'>
        <label className='form-label col-md-3'>Daily Pay:</label>
        <Field
          type='number'
          name='dailyPay'
          className='form-control form-control-lg form-control-solid'
          onChange={(e) => calculateValues('dailyPay', parseFloat(e.target.value) || 0)}
        />
      </div> */}

      <div className='d-flex whitespace-nowrap space-x-4 items-center w-100 mb-10'>
        <label className='form-label col-md-3'>Over Time Pay :</label>
        <Field
          type='number'
          name='overtimePay'
          placeholder='0.00'
          className='form-control form-control-lg form-control-solid'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='overtimePay' />
        </div>
      </div>

      <div className='form-check form-switch form-check-custom form-check-solid mb-5'>
        <input
          className='form-check-input'
          type='checkbox'
          id='showInvoiceRate'
          checked={values.needInvoiceRate}
          onChange={() => setFieldValue('needInvoiceRate', !values.needInvoiceRate)}
        />
        <label className='form-check-label' htmlFor='showInvoiceRate'>
          Include Invoice Rate
        </label>
      </div>

      {values.needInvoiceRate && (
        <div className='pb-10'>
          <h3 className='fw-bold text-dark'>Invoice Rate</h3>
          {/* <InvoiceRateSection /> */}
          <>
            <div className='d-flex align-items-center mb-7'>
              <label className='form-label required col-lg-4 col-md-6'>Daily Working Hours:</label>
              <Field
                type='number'
                name='invoiceRate.dailyWorkingHours'
                placeholder='0.00'
                className='form-control form-control-lg form-control-solid'
                required
                onChange={(e) =>
                  calculateInvoiceValues('dailyWorkingHours', parseFloat(e.target.value) || 0)
                }
              />
              <ErrorMessage
                name='invoiceRate.dailyWorkingHours'
                component='div'
                className='text-danger ms-2'
              />
            </div>

            <div className='d-flex align-items-center mb-7'>
              <label className='form-label required col-lg-4 col-md-6'>Monthly Pay:</label>
              <Field
                type='number'
                name='invoiceRate.monthlyPay'
                placeholder='0.00'
                className='form-control form-control-lg form-control-solid'
                required
                onChange={(e) =>
                  calculateInvoiceValues('monthlyPay', parseFloat(e.target.value) || 0)
                }
              />
              <ErrorMessage
                name='invoiceRate.monthlyPay'
                component='div'
                className='text-danger ms-2'
              />
            </div>

            <div className='d-flex align-items-center mb-7'>
              <label className='form-label required col-lg-4 col-md-6'>Hourly Pay:</label>
              <Field
                type='number'
                name='invoiceRate.hourlyPay'
                placeholder='0.00'
                className='form-control form-control-lg form-control-solid'
                required
                onChange={(e) =>
                  calculateInvoiceValues('hourlyPay', parseFloat(e.target.value) || 0)
                }
              />
              <ErrorMessage
                name='invoiceRate.hourlyPay'
                component='div'
                className='text-danger ms-2'
              />
            </div>

            <div className='d-flex align-items-center mb-7'>
              <label className='form-label col-lg-4 col-md-6'>Over Time Pay:</label>
              <Field
                type='number'
                name='invoiceRate.overtimePay'
                placeholder='0.00'
                className='form-control form-control-lg form-control-solid'
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='invoiceRate.overtimePay' />
              </div>
            </div>
          </>
        </div>
      )}
    </div>
  )
}

export {Step3}
