import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  departments: [],
  isLoading: false,
  fetch: true,
}

const hrpDepartmentsSlice = createSlice({
  name: 'hrpDepartments',
  initialState,
  reducers: {
    setDepartments: (state, action) => {
      state.departments = action.payload
      state.isLoading = false
      state.fetch = false
    },
    setDepartmentsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setDepartmentsDataFetch: (state) => {
      state.fetch = true
    },
  },
})

export const {setDepartments, setDepartmentsLoading, setDepartmentsDataFetch} =
  hrpDepartmentsSlice.actions
export default hrpDepartmentsSlice.reducer
