import React, {useEffect, useState} from 'react'
import {Contact} from '../core/model'
import {Button, Dropdown} from 'react-bootstrap'
import {useAuth} from '../../../modules/auth'
import CRMContactsModal from '../components/CRMContactsModal'
import {deleteContact, getContacts, postContact, updateContact} from '../core/_requests'
import {toast} from 'react-hot-toast'
import Swal from 'sweetalert2'
import {useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../../redux/store'
import {setCRMContacts} from '../../../redux/crm/contacts/contactsSlice'

const CRMContactsPage = () => {
  const {currentUser} = useAuth()
  const contacts = useSelector((state: RootState) => state.crm.contacts.contacts)
  const crmContactsFetch = useSelector((state: RootState) => state.crm.contacts.fetch)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [permissions, setPermissions] = useState<any>({})
  const [formData, setFormData] = useState<Contact>({
    firstName: '',
    lastName: '',
    displayName: '',
    email: '',
    phone: '',
    companyName: '',
    customerType: 'individual',
  })
  const [selectedContact, setSelectedContact] = useState<Contact | null>(null)
  const [isEdit, setIsEdit] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (currentUser) {
      setPermissions(currentUser.permissions)
    }
  }, [currentUser])

  const fetchData = async () => {
    setLoading(true)
    const res = await getContacts(currentUser?.organization)
    dispatch(setCRMContacts(res))
    setLoading(false)
  }

  useEffect(() => {
    if (currentUser?.organization !== undefined && crmContactsFetch) {
      fetchData()
    }
  }, [currentUser?.organization])

  const handleEdit = async (e: any) => {
    e.preventDefault()
    const res = await updateContact(selectedContact?._id, formData)
    if (res) {
      toast.success('Contact updated successfully')
      const temp = [...contacts]
      const index = temp.findIndex((contact) => contact._id === selectedContact?._id)
      temp[index] = {...temp[index], ...formData}
      dispatch(setCRMContacts(temp))
      setIsModalOpen(false)
      setSelectedContact(null)
    }
  }

  const handleDelete = async (contactId: string) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to delete this contact. This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    })

    if (result.isConfirmed) {
      const res = await deleteContact(contactId)
      if (res) {
        toast.success('Contact deleted successfully')
        const temp = contacts.filter((contact) => contact._id !== contactId)
        dispatch(setCRMContacts(temp))
        fetchData()
      }
    }
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    const data = {
      ...formData,
      organization: currentUser?.organization,
      company: currentUser?.company,
    }
    if (selectedContact) {
      toast.success('Contact updated successfully')
    } else {
      const res = await postContact(data)
      if (res) {
        toast.success('Contact created successfully')
      }
    }
    setIsModalOpen(false)
    setSelectedContact(null)
    fetchData()
  }

  return (
    <div className='container-fluid'>
      <div className='card'>
        <div className='card-header d-flex justify-content-between align-items-center'>
          <h3>Contacts</h3>
          {permissions?.contacts?.create ||
            (currentUser?.profileType === 'superadmin' && (
              <Button
                variant='primary'
                onClick={() => {
                  setIsModalOpen(true)
                  setIsEdit(false)
                  setSelectedContact(null)
                  setFormData({
                    firstName: '',
                    lastName: '',
                    displayName: '',
                    email: '',
                    phone: '',
                    companyName: '',
                    customerType: 'individual',
                  })
                }}
              >
                Add Contact
              </Button>
            ))}
        </div>
        <div className='card-body'>
          {loading ? (
            <div className='d-flex align-items-center justify-center'>
              <div className='loader'></div>
            </div>
          ) : (
            <div className='table-responsive min-h-500px'>
              <table className='table align-xRmiddle gs-0 gy-4 table-row-bordered'>
                <thead>
                  <tr className='fw-bold text-muted bg-light'>
                    <th className='ps-4 min-w-25px text-center'>Sr. No.</th>
                    <th className='min-w-150px'>Display Name</th>
                    <th className='min-w-150px'>Company Name</th>
                    <th className='min-w-150px'>Email</th>
                    <th className='min-w-100px'>Phone</th>
                    <th className='min-w-100px'>Customer Type</th>
                    {(permissions?.contacts?.update ||
                      currentUser?.profileType === 'superadmin') && (
                      <th className='min-w-100px'>Actions</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {contacts?.map((contact: Contact, index: number) => (
                    <tr key={contact._id}>
                      <td className='ps-4 text-center'>{index + 1}</td>
                      <td>{contact.displayName}</td>
                      <td className='text-dark'>{contact.companyName}</td>
                      <td>
                        <a
                          href={`mailto:${contact.email}`}
                          className='text-primary text-hover-dark'
                        >
                          {contact.email}
                        </a>
                      </td>
                      <td>
                        <div className='text-dark'>{contact.phone}</div>
                      </td>
                      <td>
                        <span
                          className={`badge badge-light-${
                            contact.customerType === 'business' ? 'success' : 'info'
                          }`}
                        >
                          {contact.customerType}
                        </span>
                      </td>
                      {(permissions?.contacts?.update ||
                        currentUser?.profileType === 'superadmin') && (
                        <td>
                          <Dropdown>
                            <Dropdown.Toggle variant='secondary' size='sm' id='dropdown-basic'>
                              Action
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => {
                                  setIsEdit(true)
                                  setFormData(contact)
                                  setIsModalOpen(true)
                                  setSelectedContact(contact)
                                }}
                              >
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item onClick={() => handleDelete(contact._id)}>
                                Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
      <CRMContactsModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={isEdit ? handleEdit : handleSubmit}
        formData={formData}
        setFormData={setFormData}
        isEdit={isEdit}
      />
    </div>
  )
}

export default CRMContactsPage
