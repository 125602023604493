import React, {useState, useEffect} from 'react'
import ReactApexChart from 'react-apexcharts'
import {ApexOptions} from 'apexcharts'
import {useAuth} from '../../../modules/auth'
import {getCashFlowChartData} from '../core/_requests'
import {KTIcon} from '../../../../_metronic/helpers'
import {Dropdown1} from '../../../../_metronic/partials'
import {getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'

interface ChartData {
  month: number
  total: number
}

const CashFlowChart: React.FC = () => {
  const {currentUser} = useAuth()
  const [chartData, setChartData] = useState<ChartData[]>([])

  useEffect(() => {
    const fetchData = async () => {
      await getCashFlowChartData(currentUser.organization).then((res) => {
        setChartData(res)
      })
    }

    if (currentUser.organization !== undefined) fetchData()
  }, [currentUser])

  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-primary')
  const secondaryColor = getCSSVariableValue('--bs-info')

  const options: ApexOptions = {
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: 750,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 4,
        distributed: true,
        barHeight: '80%',
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return currentUser?.baseCurrency + ' ' + val?.toLocaleString('en-US', {minimumFractionDigits: 2})
      },
      style: {
        fontSize: '12px',
        fontWeight: '600',
        colors: ['#ffffff'],
      },
    },
    legend: {
      show: false,
    },
    colors: [
      baseColor,
      secondaryColor,
      baseColor,
      secondaryColor,
      baseColor,
      secondaryColor,
      baseColor,
      secondaryColor,
      baseColor,
      secondaryColor,
      baseColor,
      secondaryColor,
    ],
    xaxis: {
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return currentUser?.baseCurrency + ' ' + val?.toLocaleString('en-US', {minimumFractionDigits: 2})
        },
      },
    },
  }

  const series = [
    {
      data: chartData.map((item) => item.total),
    },
  ]

  return (
    // <div id='chart'>
    //   <ReactApexChart options={options} series={series} type='bar' height={380} />
    // </div>
    <div className={`card h-100`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Monthly Cash Flow</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>
            Cash flow for each month of the current year
          </span>
        </h3>
        <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTIcon iconName='category' className='fs-2' />
          </button>
          <Dropdown1 />
        </div>
      </div>
      <div className='card-body'>
        <ReactApexChart options={options} series={series} type='bar' height={500} />
      </div>
    </div>
  )
}

export default CashFlowChart
