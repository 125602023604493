import React, {useEffect, useState} from 'react'
import {getHRPProjectsTimesheetByEmployee} from '../core/_requests'
import {useAuth} from '../../../modules/auth'
import {useParams} from 'react-router-dom'
import {formatCreatedAt} from '../../../utils/utils'

interface TimesheetRecord {
  _id: string
  projectId: {
    _id: string
    projectName: string
  }
  recordedTime: {
    employeeId: string
    regular: number
    ot: number
    _id: string
  }[]
  startDate: string
  endDate: string
  month: string
}

const HRPEmployeeTimesheetDetail: React.FC = () => {
  const {id} = useParams()
  const {currentUser} = useAuth()
  const [timesheetData, setTimesheetData] = useState<TimesheetRecord[]>([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchTimesheet = async () => {
      try {
        if (id && currentUser?.organization !== undefined) {
          const data = await getHRPProjectsTimesheetByEmployee(id, currentUser.organization)
          setTimesheetData(data)
        }
      } catch (error) {
        console.error('Error fetching timesheet:', error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchTimesheet()
  }, [currentUser, id])

  return (
    <div className='card card-flush'>
      <div className='card-body py-3'>
        {isLoading ? (
          <div className='d-flex align-items-center justify-center'>
            <div className='loader'></div>
          </div>
        ) : (
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4 table-row-bordered'>
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-150px rounded-start'>Project Name</th>
                  <th className='min-w-100px text-center'>Month</th>
                  <th className='min-w-150px text-center'>Period</th>
                  <th className='min-w-100px text-center'>Regular Hours</th>
                  <th className='min-w-100px text-center'>Overtime Hours</th>
                  <th className='min-w-100px text-center rounded-end'>Total Hours</th>
                </tr>
              </thead>
              <tbody>
                {timesheetData.map((record) => {
                  const employeeTime = record.recordedTime.find((time) => time.employeeId === id)

                  return (
                    <tr key={record._id}>
                      <td>
                        <div className='ps-4 text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          {record.projectId.projectName}
                        </div>
                      </td>
                      <td>
                        <div className='text-center text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          {record.month}
                        </div>
                      </td>
                      <td>
                        <div className='text-center text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          {/* {new Date(record.startDate).toLocaleDateString()} -{' '}
                        {new Date(record.endDate).toLocaleDateString()} */}
                          {formatCreatedAt(record.startDate)} - {formatCreatedAt(record.endDate)}
                        </div>
                      </td>
                      <td>
                        <div className='text-center text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          {employeeTime?.regular || 0}
                        </div>
                      </td>
                      <td>
                        <div className='text-center text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          {employeeTime?.ot || 0}
                        </div>
                      </td>
                      <td>
                        <div className='text-center text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          {(employeeTime?.regular || 0) + (employeeTime?.ot || 0)}
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  )
}

export default HRPEmployeeTimesheetDetail
