import React, {useState} from 'react'
import EmployeeAttendance from '../../hrm/Components/EmployeeAttendance'
import TimeTracker from '../../hrm/Components/TimeTracker'
import EmployeeLeaveTab from '../../hrm/Components/LeaveManagement/EmployeeLeaveTab'

const HRPEmployeeAttendanceDetail = ({maxLeaves}: {maxLeaves: number}) => {
  const [activeTab, setActiveTab] = useState('attendance')

  const renderContent = () => {
    switch (activeTab) {
      case 'attendance':
        return <EmployeeAttendance />
      case 'timeTracker':
        return <TimeTracker />
      case 'leaveManagement':
        return <EmployeeLeaveTab maxLeaves={maxLeaves} />
      default:
        return <EmployeeAttendance />
    }
  }

  return (
    <div className='p-4'>
      <div className='flex gap-4 mb-4 justify-end mr-4'>
        <button
          className={`px-4 py-2 rounded ${
            activeTab === 'attendance' ? 'bg-blue-500 text-white' : 'bg-gray-200'
          }`}
          onClick={() => setActiveTab('attendance')}
        >
          Attendance
        </button>
        <button
          className={`px-4 py-2 rounded ${
            activeTab === 'timeTracker' ? 'bg-blue-500 text-white' : 'bg-gray-200'
          }`}
          onClick={() => setActiveTab('timeTracker')}
        >
          Time Tracker
        </button>
        <button
          className={`px-4 py-2 rounded ${
            activeTab === 'leaveManagement' ? 'bg-blue-500 text-white' : 'bg-gray-200'
          }`}
          onClick={() => setActiveTab('leaveManagement')}
        >
          Leave Management
        </button>
      </div>
      {renderContent()}
    </div>
  )
}

export default HRPEmployeeAttendanceDetail
