import {combineReducers} from '@reduxjs/toolkit'

import accountsReducer from './accounts/accountsSlice'
import agentsReducer from './agents/agentsSlice'
import contactsReducer from './contacts/contactsSlice'
import customersReducer from './customers/customersSlice'
import leadsReducer from './leads/leadsSlice'
import productsReducer from './products/productsSlice'
import projectsReducer from './projects/projectsSlice'
import servicesReducer from './services/servicesSlice'

const rootReducer = combineReducers({
  projects: projectsReducer,
  accounts: accountsReducer,
  agents: agentsReducer,
  contacts: contactsReducer,
  customers: customersReducer,
  leads: leadsReducer,
  products: productsReducer,
  services: servicesReducer,
})

export default rootReducer
