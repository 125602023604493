import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  leaves: [],
  fetch: true,
  loading: true,
}

const hrpLeaveManagementSlice = createSlice({
  name: 'hrpLeaveManagement',
  initialState,
  reducers: {
    setHRPLeaveManagement: (state, action) => {
      state.leaves = action.payload
      state.fetch = false
      state.loading = false
    },
    setHRPLeaveManagementLoading: (state, action) => {
      state.loading = action.payload
    },
    setHRPLeaveManagementDataFetch: (state) => {
      state.fetch = true
    },
  },
})

export const {setHRPLeaveManagement, setHRPLeaveManagementLoading, setHRPLeaveManagementDataFetch} =
  hrpLeaveManagementSlice.actions
export default hrpLeaveManagementSlice.reducer
