import React, {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {getProjectsOfEmployee} from '../core/_requests'

function EmployeeProjects() {
  const {id} = useParams()
  const [projectList, setprojectList] = useState<any>([])

  const fetchprojectList = async () => {
    const res = await getProjectsOfEmployee(id)
    setprojectList(res)
  }
  useEffect(() => {
    fetchprojectList()
  }, [])

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })
    return formattedDate
  }

  return (
    <div className='card card-flush'>
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4 table-row-bordered'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                {/* sr no */}
                <th className='ps-4 min-w-50px rounded-start'>Sr No</th>
                <th className='min-w-150px'>Project Name</th>
                <th className='min-w-150px text-center'>Budget</th>
                <th className='min-w-150px text-center'>Release Date</th>
                <th className='min-w-150px text-center'>Status</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {projectList?.map((item: any, index) => (
                <tr key={item?._id?.toString()}>
                  <td>
                    <div className='ps-4 text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {index + 1}
                    </div>
                  </td>
                  <td>
                    <div className='capitalize text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {item?.projectName}
                    </div>
                  </td>
                  <td>
                    <div className='text-center text-dark fw-bold text-hover-primary d-block mb-1 fs-6 capitalize'>
                      {item?.budgetAmount}
                    </div>
                  </td>
                  <td>
                    <div className='text-center text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {formatCreatedAt(item?.releaseDate)}
                    </div>
                  </td>
                  <td>
                    <div className='text-center text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {item?.status}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}

      {/* Modal for add client */}
    </div>
  )
}

export default EmployeeProjects
