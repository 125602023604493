/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useAuth} from '../../../../modules/auth'
import {
  getFilteredRFQ,
  getFilteredRFQByAgentId,
  setRFQStatusApprove,
  setRFQStatusReject,
} from '../../Core/_requests'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../Core/_model'
import {setRFQDataFetch, setRfqDataList} from '../../../../redux/procurement/rfq/rfqSlice'
import Pagination from '../Pagination'

const RFQApproval: React.FC = () => {
  const {currentUser} = useAuth()
  const [permissions, setPermissions] = useState<any>({})
  const [rfq, setRFQ] = useState<any>([])
  const dispatch = useDispatch()
  const rfqData = useSelector((state: RootState) => state.procurement.rfq.rfqData)
  const totalPagesStore = useSelector((state: RootState) => state.procurement.rfq.totalPages)
  const [loading, setLoading] = useState(false)
  // Pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const itemsPerPage = 25

  useEffect(() => {
    if (currentUser) {
      setPermissions(currentUser.permissions)
    }
  }, [currentUser])

  const fetchData = async () => {
    if (rfqData.length === 0 || currentPage !== 1) {
      setLoading(true)
      if (permissions?.rfq?.admin === false) {
        getlistbyAgent()
      } else if (permissions?.rfq?.admin === true || currentUser?.profileType === 'superadmin') {
        getlist()
      }
    } else {
      setRFQ(rfqData)
      setTotalPages(totalPagesStore)
    }
  }

  const getlist = async () => {
    const res = await getFilteredRFQ('', currentUser?.organization, currentPage, itemsPerPage, '')
    setRFQ(res?.rfq)
    if (currentPage === 1) dispatch(setRfqDataList(res))
    setLoading(false)
  }

  const getlistbyAgent = async () => {
    const res = await getFilteredRFQByAgentId('', currentUser?._id, currentPage, itemsPerPage, '')
    setRFQ(res?.rfq)
    if (currentPage === 1) dispatch(setRfqDataList(res))
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [permissions, currentPage])

  const handleApproved = async (id, index) => {
    const res = await setRFQStatusApprove(id)
    if (res) {
      const updatedData = rfq?.map((item, idx) =>
        idx === index ? {...item, approval: 'accepted'} : item
      )
      setRFQ(updatedData)
      if (currentPage === 1) {
        dispatch(
          setRfqDataList({
            rfq: [],
            totalPages: 1,
          })
        )
        dispatch(setRFQDataFetch())
      }
    }
  }

  const handleReject = async (id, index) => {
    const res = await setRFQStatusReject(id)
    if (res) {
      const updatedData = rfq?.map((item, idx) =>
        idx === index ? {...item, approval: 'rejected'} : item
      )
      setRFQ(updatedData)
      if (currentPage === 1) {
        dispatch(
          setRfqDataList({
            rfq: [],
            totalPages: 1,
          })
        )
        dispatch(setRFQDataFetch())
      }
    }
  }

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      // day: '2-digit',
      // month: 'short',
      // year: 'numeric',
    })
    return formattedDate
  }

  return (
    <div className='card mt-5'>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5 flex justify-between'>
        <div>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>RFQ</span>
          </h3>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        {loading ? (
          <div className='d-flex align-items-center justify-center'>
            <div className='loader'></div>
          </div>
        ) : (
          <div className='table-responsive'>
            {/* begin::Table */}

            <table className='table align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-150px rounded-start'>ID</th>
                  <th className='min-w-200px'>Employee</th>
                  <th className='min-w-200px'>Vendor</th>
                  <th className='min-w-150px'>Date</th>
                  <th className='min-w-100px'>Approval</th>
                  {(currentUser?.profileType === 'superadmin' ||
                    permissions?.procurementapproval?.update) && (
                    <th className='min-w-100px rounded-end px-10'>Action</th>
                  )}
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {rfq?.map((user, index) => (
                  <tr key={index}>
                    <td>
                      <div className='d-flex align-items-center ps-4'>
                        <div className='d-flex justify-content-start flex-column'>
                          <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                            {user?.id}
                          </a>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        {/* <div className='symbol symbol-50px me-5'>
                        <img
                          src={user?.agent.profilePic ? PF + user?.agent.profilePic : profile}
                          className=''
                          alt=''
                        />
                      </div> */}
                        <div className='d-flex justify-content-start flex-column'>
                          <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                            {user?.employee?.firstName}
                          </a>
                        </div>
                      </div>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {user?.vendor?.displayName}
                      </a>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {formatCreatedAt(user.createdAt)}
                      </a>
                    </td>
                    <td>
                      <span
                        className={`badge fs-7 fw-semibold ${
                          user.approval === 'accepted'
                            ? 'badge-light-success'
                            : user.approval === 'rejected'
                            ? 'badge-light-danger'
                            : 'badge-light-warning'
                        }`}
                      >
                        {user?.approval}
                      </span>
                    </td>
                    {(currentUser?.profileType === 'superadmin' ||
                      permissions?.procurementapproval?.update) && (
                      <td className='text-center'>
                        <div className='d-flex'>
                          <div className='dropdown'>
                            <button
                              className='btn btn-light btn-active-light-primary btn-flex btn-center btn-sm dropdown-toggle'
                              type='button'
                              id={`actionDropdown${index}`}
                              data-bs-toggle='dropdown'
                              aria-expanded='false'
                            >
                              Actions
                            </button>
                            <ul
                              className='dropdown-menu'
                              aria-labelledby={`actionDropdown${index}`}
                            >
                              {(user.approval === 'pending' || user.approval === 'rejected') && (
                                <li>
                                  <button
                                    className='dropdown-item'
                                    onClick={() => handleApproved(user._id, index)}
                                  >
                                    Accept
                                  </button>
                                </li>
                              )}
                              {(user.approval === 'pending' || user.approval === 'accepted') && (
                                <li>
                                  <button
                                    className='dropdown-item'
                                    onClick={() => handleReject(user._id, index)}
                                  >
                                    Reject
                                  </button>
                                </li>
                              )}
                            </ul>
                          </div>
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
              {/* end::Table body */}
            </table>

            {/* end::Table */}
          </div>
        )}
        {/* end::Table container */}
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
        />
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {RFQApproval}
