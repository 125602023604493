import React, {useState, useEffect} from 'react'
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd'
import {format} from 'date-fns'
import {useAuth} from '../../../modules/auth'

const defaultColumns = [
  {id: 'taskName', label: 'Task Name'},
  {id: 'status', label: 'Status'},
  {id: 'budget', label: 'Budget'},
  {id: 'startDate', label: 'Start Date'},
  {id: 'endDate', label: 'End Date'},
  {id: 'createdDate', label: 'Created Date'},
  {id: 'assignedAgent', label: 'Assigned Agent'},
  {id: 'assignedUser', label: 'Assigned User'},
  {id: 'tags', label: 'Tags'},
]

const TaskPage = ({tasks}) => {
  const {currentUser} = useAuth()
  const [columns, setColumns] = useState(() => {
    const savedColumns = localStorage.getItem('taskPageColumns')
    return savedColumns ? JSON.parse(savedColumns) : defaultColumns
  })

  useEffect(() => {
    localStorage.setItem('taskPageColumns', JSON.stringify(columns))
  }, [columns])

  const onDragEnd = (result) => {
    if (!result.destination) return

    const newColumns = Array.from(columns)
    const [reorderedItem] = newColumns.splice(result.source.index, 1)
    newColumns.splice(result.destination.index, 0, reorderedItem)

    setColumns(newColumns)
  }

  const formatDate = (dateString) => {
    return dateString ? format(new Date(dateString), 'dd MMM yyyy') : 'N/A'
  }

  const renderCell = (task, column) => {
    switch (column.id) {
      case 'taskName':
        return task.title
      case 'startDate':
        return formatDate(task.startDate)
      case 'endDate':
        return formatDate(task.dueDate)
      case 'createdDate':
        return formatDate(task.createdAt)
      case 'assignedAgent':
        return task.assignedTo?.map((agent) => agent.firstName).join(', ') || 'N/A'
      case 'assignedUser':
        return task.user?.fullName || 'N/A'
      case 'budget':
        return `${currentUser?.baseCurrency} ${task.targetBudget.toFixed(2)}`
      case 'status':
        return (
          <span
            className={`badge ${
              task.status === 'new'
                ? 'badge-light-primary'
                : task.status === 'inprogress'
                ? 'badge-light-warning'
                : 'badge-light-success'
            }`}
          >
            {task.status.toUpperCase()}
          </span>
        )
      case 'tags':
        return (
          <span
            className={`badge ${
              task.tags === 'medium'
                ? 'badge-light-warning'
                : task.tags === 'flexible'
                ? 'badge-light-primary'
                : 'badge-light-success'
            }`}
          >
            {task.tags.toUpperCase()}
          </span>
        )
      default:
        return 'N/A'
    }
  }

  return (
    <div className='card'>
      <div className='card-header'>
        <h3 className='card-title'>Task List</h3>
      </div>
      <div className='card-body'>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='columns' direction='horizontal'>
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className='flex flex-wrap mb-4'
              >
                {columns?.map((column, index) => (
                  <Draggable key={column.id} draggableId={column.id} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className='mr-2 mb-2 p-2 bg-light rounded cursor-move'
                      >
                        {column.label}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fs-8 fw-bold text-muted border-top border-gray-400'>
                <th className='min-w-30px text-center border-end border-start border-gray-400'>
                  Sr. No.
                </th>
                {columns?.map((column, index) => (
                  <th key={column.id} className='min-w-50px border-end border-gray-400'>
                    {column.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tasks?.map((task, index: number) => (
                <tr
                  key={task._id}
                  className={`fs-8 ${
                    index === tasks.length - 1 ? 'border-bottom border-gray-400' : ''
                  }`}
                >
                  <td className='text-center border-start border-end border-gray-400'>
                    {index + 1}
                  </td>
                  {columns?.map((column, columnIndex) => (
                    <td key={`${task._id}-${column.id}`} className='border-end border-gray-400'>
                      {renderCell(task, column)}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default TaskPage
