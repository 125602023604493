import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {getContacts, getCRMAccountById, getLeads, updateCRMAccount} from '../core/_requests'
import {Button} from 'react-bootstrap'
import {useAuth} from '../../../modules/auth'
import AddLeadOrContactModal from './AddLeadOrContactModal'
import toast from 'react-hot-toast'

const CRMAccountDetail = () => {
  const {id} = useParams()
  const {currentUser} = useAuth()
  const [permissions, setPermissions] = useState<any>({})
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState<any>(null)
  const [isAddLeadModalOpen, setIsAddLeadModalOpen] = useState(false)
  const [isAddContactModalOpen, setIsAddContactModalOpen] = useState(false)
  const [leads, setLeads] = useState<any[]>([])
  const [contacts, setContacts] = useState<any[]>([])
  const [selectedLeads, setSelectedLeads] = useState<any[]>([])
  const [selectedContacts, setSelectedContacts] = useState<any[]>([])

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const fetchData = async () => {
    setIsLoading(true)
    const res = await getCRMAccountById(id)
    setData(res)
    const leadsIds = res?.leads?.map((lead: any) => lead._id)
    const contactsIds = res?.contacts?.map((contact: any) => contact._id)
    setSelectedLeads(leadsIds)
    setSelectedContacts(contactsIds)
    setIsLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [id])

  useEffect(() => {
    const fetchLeads = async () => {
      const res = await getLeads(currentUser.organization)
      setLeads(res)
    }

    const fetchContacts = async () => {
      const res = await getContacts(currentUser.organization)
      setContacts(res)
    }

    if (currentUser.organization !== undefined) {
      fetchLeads()
      fetchContacts()
    }
  }, [currentUser])

  if (isLoading) {
    return (
      <div className='d-flex align-items-center justify-content-center'>
        <div className='loader'></div>
      </div>
    )
  }

  const handleAddLead = async (e: any) => {
    e.preventDefault()
    const data = {
      leads: selectedLeads,
    }
    const res = await updateCRMAccount(id, data)
    if (res) {
      setIsAddLeadModalOpen(false)
      toast.success('Leads added successfully')
      fetchData()
    }
  }

  const handleAddContact = async (e: any) => {
    e.preventDefault()
    const data = {
      contacts: selectedContacts,
    }
    const res = await updateCRMAccount(id, data)
    if (res) {
      setIsAddContactModalOpen(false)
      toast.success('Contacts added successfully')
      fetchData()
    }
  }

  return (
    <div className='container-fluid'>
      <div className='card'>
        <div className='card-header d-flex justify-content-between align-items-center'>
          <h3>{data?.name}</h3>
        </div>
        <div className='card-body'>
          {!data ? (
            <div className='d-flex align-items-center justify-center'>
              <div className='loader'></div>
            </div>
          ) : (
            <div className='row'>
              <div className='col-md-6'>
                <div className='card h-100'>
                  <div className='card-header d-flex justify-content-between align-items-center'>
                    <h4>Leads ({data?.leads?.length || 0})</h4>
                    {(permissions?.crmaccounts?.update ||
                      currentUser?.profileType === 'superadmin') && (
                      <div className='d-flex gap-2'>
                        <Button variant='primary' onClick={() => setIsAddLeadModalOpen(true)}>
                          Select Leads
                        </Button>
                      </div>
                    )}
                  </div>
                  <div className='card-body'>
                    <div className='table-responsive'>
                      <table className='table align-middle gs-0 gy-4 table-row-bordered'>
                        <thead>
                          <tr className='fw-bold text-muted bg-light'>
                            <th className='ps-4 min-w-25px text-center'>Sr. No.</th>
                            <th className='min-w-150px'>Display Name</th>
                            <th className='min-w-150px text-center'>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.leads?.map((lead: any, index: number) => (
                            <tr key={lead._id}>
                              <td className='ps-4 text-center'>{index + 1}</td>
                              <td>{lead?.displayName}</td>
                              <td className='text-center'>
                                <span
                                  className={`badge capitalize badge-light-${
                                    lead?.pipelineStatus === 'new'
                                      ? 'primary'
                                      : lead?.pipelineStatus === 'prospect'
                                      ? 'warning'
                                      : lead?.pipelineStatus === 'proposal'
                                      ? 'info'
                                      : lead?.pipelineStatus === 'closed'
                                      ? 'success'
                                      : 'danger'
                                  }`}
                                >
                                  {lead?.pipelineStatus}
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='card h-100'>
                  <div className='card-header d-flex justify-content-between align-items-center'>
                    <h4>Contacts ({data?.contacts?.length || 0})</h4>
                    {(permissions?.crmaccounts?.update ||
                      currentUser?.profileType === 'superadmin') && (
                      <div className='d-flex gap-2'>
                        <Button variant='primary' onClick={() => setIsAddContactModalOpen(true)}>
                          Select Contacts
                        </Button>
                      </div>
                    )}
                  </div>
                  <div className='card-body'>
                    <div className='table-responsive'>
                      <table className='table align-middle gs-0 gy-4 table-row-bordered'>
                        <thead>
                          <tr className='fw-bold text-muted bg-light'>
                            <th className='ps-4 min-w-25px text-center'>Sr. No.</th>
                            <th className='min-w-150px'>Display Name</th>
                            <th className='min-w-150px text-center'>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.contacts?.map((contact: any, index: number) => (
                            <tr key={contact._id}>
                              <td className='ps-4 text-center'>{index + 1}</td>
                              <td>{contact?.displayName}</td>
                              <td className='text-center'>
                                <span
                                  className={`badge capitalize badge-light-${
                                    contact?.pipelineStatus === 'new'
                                      ? 'primary'
                                      : contact?.pipelineStatus === 'prospect'
                                      ? 'warning'
                                      : contact?.pipelineStatus === 'proposal'
                                      ? 'info'
                                      : contact?.pipelineStatus === 'closed'
                                      ? 'success'
                                      : 'danger'
                                  }`}
                                >
                                  {contact?.pipelineStatus}
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <AddLeadOrContactModal
        isOpen={isAddLeadModalOpen}
        onClose={() => setIsAddLeadModalOpen(false)}
        type='lead'
        data={leads}
        selectedData={selectedLeads}
        setSelectedData={setSelectedLeads}
        onSubmit={handleAddLead}
      />
      <AddLeadOrContactModal
        isOpen={isAddContactModalOpen}
        onClose={() => setIsAddContactModalOpen(false)}
        type='contact'
        data={contacts}
        selectedData={selectedContacts}
        setSelectedData={setSelectedContacts}
        onSubmit={handleAddContact}
      />
    </div>
  )
}

export default CRMAccountDetail
