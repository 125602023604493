import React, {useEffect, useState} from 'react'
import Select from 'react-select'
import {useAuth} from '../../../modules/auth'
import {getCategoryByProductType} from '../Core/_requests'

export default function CategoryChangeDetails({changeCategory, setChangeCategory}) {
  const {currentUser} = useAuth()
  const [categoriesData, setCategoriesData] = useState([]) // To store the full API response
  const [categoryTypesOptions, setCategoryTypesOptions] = useState([]) // Category type options for the first select
  const [selectedCategoryType, setSelectedCategoryType] = useState(null)
  const [filteredCategoryNames, setFilteredCategoryNames] = useState([]) // Category name options for the second select
  const [selectedCategoryName, setSelectedCategoryName] = useState(null)

  const fetchCategoryData = async () => {
    try {
      const res = await getCategoryByProductType(currentUser?.organization)
      if (res) {
        setCategoriesData(res) // Store the API response
        const types = res.map((categoryGroup) => ({
          value: categoryGroup._id, // Use the type as the value
          label: categoryGroup._id, // Display the type as the label
        }))
        setCategoryTypesOptions(types)
      }
    } catch (error) {
      console.error('Error fetching categories:', error)
    }
  }

  useEffect(() => {
    fetchCategoryData()
  }, [])

  useEffect(() => {
    if (selectedCategoryType) {
      // Find the selected category type from categoriesData and map over its categories
      const selectedGroup = categoriesData.find((group) => group._id === selectedCategoryType.value)
      if (selectedGroup) {
        const filteredNames = selectedGroup.categories.map((category) => ({
          value: category?._id,
          label: category?.categoryName,
        }))
        setFilteredCategoryNames(filteredNames)
      }
    } else {
      setFilteredCategoryNames([])
    }
  }, [selectedCategoryType, categoriesData])

  const handleCategoryTypeChange = (selectedOption) => {
    setSelectedCategoryType(selectedOption)
    setSelectedCategoryName(null) // Reset category name selection when type changes
  }

  const handleCategoryNameChange = (selectedOption) => {
    setSelectedCategoryName(selectedOption)
    setChangeCategory(selectedOption.value) // Update the product category in EditProduct
  }

  return (
    <div className='card card-flush py-4 d-flex flex-column'>
      <div className='card-header'>
        <div className='card-title'>
          <h2>Change Inventory Type</h2>
        </div>
      </div>

      <div className='card-body p-0 px-6 pb-2'>
        <label className='form-label'>Inventory Type</label>
        <Select
          className='form-control mb-2'
          options={categoryTypesOptions}
          value={selectedCategoryType}
          onChange={handleCategoryTypeChange}
        />

        <label className='form-label'>Category Name</label>
        <Select
          className='form-control mb-2'
          options={filteredCategoryNames}
          value={selectedCategoryName}
          onChange={handleCategoryNameChange}
          isDisabled={!selectedCategoryType} // Disable until category type is selected
        />
      </div>
    </div>
  )
}
