import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {getIndividualTimesheet, getProjectInfo} from '../core/_requests'

const IndividualSummary = ({showGenerateInvoice}) => {
  const {id} = useParams()
  const navigate = useNavigate()
  const PF = 'https://qf-assets.s3.me-central-1.amazonaws.com/'
  const [data, setData] = useState<any>([])
  const [loading, setLoading] = useState(true)
  const [company, setCompany] = useState<any>({})
  const [projectData, setProjectData] = useState<any>({})

  const fetchData = async () => {
    const res = await getIndividualTimesheet(id)
    console.log(res)
    setData(res)
    setLoading(false)
  }

  const NUMBERS = new Map([
    [1e9, 'Billion'],
    [1e6, 'Million'],
    [1e3, 'Thousand'],
    [1e2, 'Hundred'],
    [90, 'Ninety'],
    [80, 'Eighty'],
    [70, 'Seventy'],
    [60, 'Sixty'],
    [50, 'Fifty'],
    [40, 'Forty'],
    [30, 'Thirty'],
    [20, 'Twenty'],
    [19, 'Nineteen'],
    [18, 'Eighteen'],
    [17, 'Seventeen'],
    [16, 'Sixteen'],
    [15, 'Fifteen'],
    [14, 'Fourteen'],
    [13, 'Thirteen'],
    [12, 'Twelve'],
    [11, 'Eleven'],
    [10, 'Ten'],
    [9, 'Nine'],
    [8, 'Eight'],
    [7, 'Seven'],
    [6, 'Six'],
    [5, 'Five'],
    [4, 'Four'],
    [3, 'Three'],
    [2, 'Two'],
    [1, 'One'],
    [0, 'Zero'],
  ])

  useEffect(() => {
    fetchData()
    getCompany()
  }, [])

  const numberToWords = (num) => {
    for (const [intValue, textValue] of NUMBERS as any) {
      if (num >= intValue) {
        const prefix = num >= 100 ? numberToWords(Math.trunc(num / intValue)) : ''
        const suffix = num % intValue > 0 ? numberToWords(num % intValue) : ''
        return `${prefix} ${textValue} ${suffix}`.trim()
      }
    }
    return ''
  }

  const handlePrint = () => {
    window.print()
  }

  const formatCreatedAt = (createdAt: any | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })
    return formattedDate
  }

  const [bank, setBank] = useState<any>({
    bankName: '',
    accountNumber: '',
    iBANNumber: '',
  })

  const getCompany = async () => {
    const res = await getProjectInfo(id)
    console.log(res)

    setCompany(res?.company)
    setProjectData(res?.projectId)

    setBank({
      bankName: res?.company?.bankName || '',
      accountNumber: res?.company?.accountNumber || '',
      iBANNumber: res?.company?.iBANNumber || '',
    })
  }

  const handleRedirection = () => {
    navigate(`/project/individual-summary/invoice/${id}`)
  }

  return (
    <>
      <div id='kt_app_toolbar_container' className='app-container container-xxl d-flex flex-stack '>
        {/*begin::Page title*/}
        <div className='d-flex gap-5 justify-content-end w-100'>
          <button
            className='hide-on-pdf btn btn-sm btn-danger print-button mb-8 align-items-right'
            id='kt_quote_submit_button'
            onClick={handlePrint}
          >
            Print Summary
          </button>
          {data?.length > 0 && showGenerateInvoice && (
            <button
              className='btn btn-info hide-on-pdf btn-sm print-button mb-8 align-items-right'
              onClick={handleRedirection}
            >
              Generate Invoice
            </button>
          )}
        </div>
      </div>
      <div id='pdf-content' className='app-content  print-content'>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            <div className='app-content flex-column-fluid'>
              {/* begin::Content container */}

              <div id='kt_app_content_container' className='app-container container-xxl'>
                {/* begin::Invoice 2 main */}

                <div id='pdf-content' className='card print-content'>
                  {/* begin::Body */}

                  <div className='card-body px-lg-15'>
                    {/* begin::Layout */}
                    <div className='d-flex flex-column'>
                      {/* begin::Content */}
                      <div className='flex-lg-row-fluid mb-10 mb-xl-0'>
                        {/* begin::Invoice 1 content */}
                        <div className='mt-n1'>
                          {/* begin::Top */}
                          <div className='d-flex justify-content-between align-items-center flex-column flex-sm-row mb-3 '>
                            {/*end::Logo*/}
                            <div className='text-sm-end'>
                              {/*begin::Logo*/}
                              <a href='#' className='d-block mw-150px ms-sm-auto '>
                                <img
                                  alt='thumbnail'
                                  src={PF + company?.companyLogo}
                                  className='w-50'
                                />
                              </a>
                              {/*end::Logo*/}
                            </div>
                            {/* <h4 className="fw-bold text-gray-800 fs-1  pb-7 ">Company Address</h4> */}
                            <h4 className='fw-bold text-gray-800 xl:fs-1  pb-7 '>
                              {company?.name}
                              <br />
                              <span className='fs-6 text-muted'>
                                Vat:{company?.vat} <br /> C.R : {company?.cr}
                              </span>
                            </h4>
                          </div>

                          {/* end::Top */}

                          <div className='separator'></div>

                          {/* begin::Wrapper */}
                          <div className='mt-5'>
                            <div className='d-flex justify-content-between mb-6 fs-6 fw-bold text-gray-800'>
                              <div className=''>Summary Invoice</div>
                              <div className=''>فاتورة موجزة</div>
                            </div>
                            {/*end::Top*/}
                            {/*begin::Wrapper*/}
                            <div className='m-0'>
                              {/*begin::Label*/}
                              <div className='d-flex justify-content-between flex-column flex-sm-row'>
                                <div className=' mb-5  fw-bold'>
                                  <div className='d-flex  flex-column mb-7'>
                                    <span className='text-muted'>Issue Date</span>
                                    <span className='fs-6'>{formatCreatedAt(new Date())}</span>
                                  </div>
                                  <div className='d-flex flex-column mb-7'>
                                    <span className='text-muted'>Company Name</span>

                                    <span className='fs-6'>
                                      {projectData?.customer?.companyName}
                                    </span>
                                  </div>
                                  <div className='d-flex flex-column mb-7'>
                                    <span className='text-muted'>Company Address</span>

                                    <span className='fs-6'>
                                      {projectData?.customer?.companyName}
                                    </span>
                                  </div>
                                </div>
                                <div className='  mb-5  fw-bold'>
                                  <div className='d-flex  flex-column mb-7'>
                                    <span className='text-muted'>Project Name</span>
                                    <span className='fs-6'>{projectData?.projectName}</span>
                                  </div>
                                  <div className=' d-flex flex-column mb-7'>
                                    <span className='text-muted'>PO Number</span>
                                    <span className='fs-6'>{projectData?.lpo}</span>
                                  </div>
                                  <div className=' d-flex flex-column mb-7'>
                                    <span className='text-muted'>VAT Number</span>
                                    <span className='fs-6'>{projectData?.customer?.vatNumber}</span>
                                  </div>
                                </div>
                                <div className='  mb-5  text-end fw-bold'>
                                  <div className='d-flex  flex-column mb-7'>
                                    <span className='text-muted'>اسم المشروع</span>
                                    <span className='fs-6'>{projectData?.projectName}</span>
                                  </div>
                                  <div className=' d-flex flex-column mb-7'>
                                    <span className='text-muted'>رقم طلب الشراء</span>
                                    <span className='fs-6'>{projectData?.lpo}</span>
                                  </div>
                                  <div className=' d-flex flex-column mb-7'>
                                    <span className='text-muted'>ظريبه الشراء</span>
                                    <span className='fs-6'>{projectData?.customer?.vatNumber}</span>
                                  </div>
                                </div>
                                <div className=' mb-5 text-end fw-bold'>
                                  <div className='d-flex  flex-column mb-7'>
                                    <span className='text-muted'>تاريخ الإصدار</span>
                                    <span className='fs-6'>{formatCreatedAt(new Date())}</span>
                                  </div>
                                  <div className='d-flex flex-column mb-7'>
                                    <span className='text-muted'>اسم الشركة</span>

                                    <span className='fs-6'>
                                      {projectData?.customer?.companyName}
                                    </span>
                                  </div>
                                  <div className='d-flex flex-column mb-7'>
                                    <span className='text-muted'>عنوان الشركة</span>

                                    <span className='fs-6'>
                                      {projectData?.customer?.companyName}
                                    </span>
                                  </div>
                                </div>
                                {/*end::Order details*/}
                                {/*begin::Billing & shipping*/}
                              </div>
                              {/*end::Row*/}
                              {/*begin::Content*/}
                              <div className='flex-grow-1'>
                                {/*begin::Table*/}
                                <div className='table-responsive'>
                                  <table className='table table-bordered m-0'>
                                    <thead>
                                      <tr className='fs-9 fw-bold bg-[#0047AB] '>
                                        <th className='min-w-50px text-white text-start pb-3 '>
                                          رقم سري
                                          <br />
                                          <span>Sr. No.</span>
                                        </th>
                                        <th className='min-w-175px text-white text-center pb-3 '>
                                          اسم الموظف
                                          <br />
                                          Employee Name
                                        </th>
                                        <th className='min-w-80px text-white text-center pb-3'>
                                          دور الموظف
                                          <br />
                                          Role
                                        </th>
                                        <th className='min-w-100px text-white text-center pb-3'>
                                          إجمالي ساعات العمل
                                          <br />
                                          Total Hours
                                        </th>
                                        <th className='min-w-100px text-white text-center pb-3'>
                                          المعدل العادي/ساعة
                                          <br />
                                          Regular Rate/hour
                                        </th>
                                        <th className='min-w-100px text-white text-center pb-3'>
                                          المبلغ الإجمالي
                                          <br />
                                          Amount
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className='' style={{height: '250px'}}>
                                      {data?.map((item, index) => (
                                        <tr
                                          key={index}
                                          className='border fw-semibold text-gray-600 fs-7 text-center'
                                        >
                                          <td className='ps-2'>{index + 1}</td>
                                          <td className=' text-center '>{item?.employeeName}</td>
                                          <td className=''>{item?.role}</td>
                                          <td className=''>{item?.totalHours}</td>
                                          <td className=''>{item?.hourlyPay}</td>
                                          <td className=''>{item?.totalPay}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                    <tbody className='p-0'>
                                      <tr className=''>
                                        <td colSpan={2} className='p-0'>
                                          <table className='table w-full m-0 '>
                                            <tbody
                                              className='min-w-80px'
                                              style={{display: 'flex', flexDirection: 'column'}}
                                            >
                                              <tr className=''>
                                                <td className='ps-2 text-start w-2/3 '>
                                                  <div className=''>
                                                    <h6 className='mb-3 fw-bolder text-gray-600 text-hover-primary fs-7 '>
                                                      PAYMENT DETAILS
                                                    </h6>
                                                    <div className='mb-2 d-flex '>
                                                      <div className='fw-semibold text-gray-600 fs-8 w-150px'>
                                                        Bank Name:
                                                      </div>
                                                      <div className='fw-bold text-gray-800 fs-8'>
                                                        {bank?.bankName}
                                                      </div>
                                                    </div>
                                                    <div className='mb-2 d-flex '>
                                                      <div className='fw-semibold text-gray-600 fs-8 w-150px'>
                                                        Account Number:
                                                      </div>
                                                      <div className='fw-bold text-gray-800 fs-8'>
                                                        {bank?.accountNumber}
                                                      </div>
                                                    </div>
                                                    <div className='mb-2 d-flex'>
                                                      <div className='fw-semibold text-gray-600 fs-8 w-150px'>
                                                        IBAN Number:
                                                      </div>
                                                      <div className='fw-bold text-gray-800 fs-8'>
                                                        {bank?.iBANNumber}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                        <td colSpan={2} className='p-0 text-sm'>
                                          <table className='table table-bordered fs-8 w-full m-0 p-0 '>
                                            <thead>
                                              <th className='min-w-80px'></th>
                                            </thead>
                                            <tbody>
                                              <tr className='text-end '>
                                                <td className=' '>
                                                  المجموع الفرعي:
                                                  <br />
                                                  Subtotal
                                                </td>
                                              </tr>
                                              <tr className='text-end '>
                                                <td className=''>
                                                  إجمالي الوزن المبلغ
                                                  <br />
                                                  Total VAT Amount ({data?.tax}%)
                                                </td>
                                              </tr>
                                              <tr className='text-end '>
                                                <td className='fw-bold '>
                                                  المجموع
                                                  <br />
                                                  Total
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                        <td colSpan={2} className='p-0 text-sm'>
                                          <table className='table table-bordered fs-8 w-full m-0 p-0 '>
                                            <thead>
                                              <th className='min-w-80px'></th>
                                            </thead>
                                            <tbody>
                                              <tr className=' text-center p-2 '>
                                                <td className='text-end'>
                                                  {}
                                                  <br />
                                                  {projectData?.customer?.currency}
                                                </td>
                                                <td>
                                                  {}
                                                  <br />
                                                  {data?.reduce((acc, item) => {
                                                    return acc + item.totalPay
                                                  }, 0)}
                                                </td>
                                              </tr>
                                              <tr className=' text-center p-2 '>
                                                <td className='text-end'>
                                                  {}
                                                  <br />
                                                  {projectData?.customer?.currency}
                                                </td>
                                                <td>
                                                  {}
                                                  <br />0
                                                </td>
                                              </tr>
                                              <tr className='text-center p-2 fw-bold '>
                                                <td className='text-end'>
                                                  {}
                                                  <br />
                                                  {projectData?.customer?.currency}
                                                </td>
                                                <td>
                                                  {}
                                                  <br />
                                                  {data?.reduce((acc, item) => {
                                                    return acc + item.totalPay
                                                  }, 0)}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                      <tr className='py-1 fw-bold'>
                                        <td colSpan={6}>
                                          Total in words
                                          <br />
                                          {numberToWords(
                                            data?.reduce((acc, item) => acc + item.totalPay, 0)
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* end::Invoice 2 content */}
                      </div>
                      {/* end::Content */}
                    </div>
                    {/* end::Layout */}
                  </div>
                  {/* end::Body */}
                  <div id='pdfFooter' className='card-footer text-center p-2 fw-bold'>
                    <div className='bg-secondary -skew-x-12 text-gray-800'>
                      <div className='skew-x-12 py-1'>
                        Mobile : {company?.mobileNumber} | Fax Number: {company?.faxNumber} | P.O.
                        Box : {company?.pOBox}{' '}
                      </div>
                    </div>
                    <div className='bg-[#0047AB] -skew-x-12 text-white '>
                      <div className='skew-x-12 py-1'>
                        E-mail : {company?.companyEmail} | Website : {company?.webURL}
                      </div>
                    </div>
                  </div>
                </div>
                {/* end::Invoice 2 main */}
              </div>
              {/* end::Content container */}
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default IndividualSummary
