import React from 'react'
import {KTIcon} from '../../../../_metronic/helpers/components/KTIcon'
import {useAuth} from '../../../modules/auth'

const SalesSummaryCard = ({color = 'success', customers}) => {
  // Calculate the total sum of all totalBusiness values
  const {currentUser} = useAuth()
  const totalBusinessSum = customers.reduce((acc, curr) => acc + curr.totalBusiness, 0)

  return (
    <div className='card h-100'>
      {/* begin::Body */}
      <div className='card card-flush h-md-100'>
        {/* begin::Header */}
        <div className={`px-9 pt-4 card-rounded h-250px w-100 bg-${color}`}>
          {/* begin::Heading */}
          <div className='d-flex flex-stack'>
            <h3 className='m-0 text-white fw-bold fs-3'>Sales Summary</h3>
            <div className='ms-1'></div>
          </div>

          <div className='d-flex text-center flex-column text-white pt-8'>
            <span className='fw-semibold fs-7'>Total Business</span>
            <span className='fw-bold fs-2x pt-1'>
              {totalBusinessSum?.toLocaleString('en-US', {
                minimumFractionDigits: 2,
              })}{' '}
              {currentUser?.baseCurrency}
            </span>
          </div>
        </div>

        <div
          className='shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-body'
          style={{marginTop: '-100px'}}
        >
          {customers.map((customer) => (
            <div key={customer.customerId} className='d-flex align-items-center mb-6'>
              {/* begin::Symbol */}
              <div className='symbol symbol-45px w-40px me-5'>
                <span className='symbol-label bg-lighten'>
                  <KTIcon iconName='compass' className='fs-1' />
                </span>
              </div>
              {/* end::Symbol */}
              {/* begin::Description */}
              <div className='d-flex align-items-center flex-wrap w-100'>
                {/* begin::Title */}
                <div className='mb-1 pe-3 flex-grow-1'>
                  <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                    {customer.customerName}
                  </a>
                </div>
                {/* end::Title */}
                {/* begin::Label */}
                <div className='d-flex align-items-center'>
                  <div className='fw-bold fs-5 text-gray-800 pe-1'>
                    {customer?.totalBusiness?.toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                    })}{' '}
                    {customer.currency}
                  </div>
                  <KTIcon iconName='arrow-up' className='fs-5 text-success ms-1' />
                </div>
                {/* end::Label */}
              </div>
              {/* end::Description */}
            </div>
          ))}
        </div>
        {/* end::Items */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export default SalesSummaryCard
