import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {getOrderById, changeOrderStatus} from '../Core/_requests'
import OrderManagementHistory from '../Components/OrderManagementHistory'

function OrderManagementHeader() {
  const id = useParams().id
  const [order, setOrder] = useState<any>()

  const getordersbyid = async () => {
    const res = await getOrderById(id)
    setOrder(res[0])
    console.log(res)
  }
  useEffect(() => {
    getordersbyid()
  }, [])

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })
    return formattedDate
  }

  const handleStatusChange = async (status: string) => {
    if (status === order?.status) return
    await changeOrderStatus(order?.status, status, id).then((res) =>
      setOrder((prev) => ({...prev, status: status}))
    )
  }

  return (
    <div>
      <div className='d-flex flex-column flex-xl-row gap-7 gap-lg-10 mb-10'>
        <div className='card card-flush py-4 flex-row-fluid'>
          <div className='card-header'>
            <div className='card-title'>
              <h2>Order Details </h2>
            </div>
          </div>

          <div className='card-body pt-0'>
            <div className='table-responsive'>
              <table className='table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px'>
                <tbody className='fw-semibold text-gray-600'>
                  <tr>
                    <td className='text-muted'>
                      <div className='d-flex align-items-center'>
                        <i className='ki-duotone ki-calendar fs-2 me-2'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                        </i>
                        Order Id
                      </div>
                    </td>
                    <td className='fw-bold text-end'>#{order?.id}</td>
                  </tr>
                  <tr>
                    <td className='text-muted'>
                      <div className='d-flex align-items-center'>
                        <i className='ki-duotone ki-calendar fs-2 me-2'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                        </i>
                        Date Added
                      </div>
                    </td>
                    <td className='fw-bold text-end'>{formatCreatedAt(order?.createdAt)}</td>
                  </tr>
                  {order?.project?.projectName && (
                    <tr>
                      <td className='text-muted'>
                        <div className='d-flex align-items-center'>
                          <i className='ki-duotone ki-file fs-2 me-2'>
                            <span className='path1'></span>
                            <span className='path2'></span>
                          </i>
                          Project
                        </div>
                      </td>
                      <td className='fw-bold text-end'>{order?.project?.projectName}</td>
                    </tr>
                  )}
                  <tr>
                    <td className='text-muted'>
                      <div className='d-flex align-items-center'>
                        <i className='ki-duotone ki-wallet fs-2 me-2'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                          <span className='path3'></span>
                          <span className='path4'></span>
                        </i>
                        Status
                      </div>
                    </td>
                    {/* <td className='fw-bold text-end'>{order?.status}</td> */}
                    <td
                      className={`cursor-pointer badge fw-bold text-end d-flex justify-end ${
                        order?.status === 'open' ? 'badge-light-primary' : 'badge-light-success'
                      }`}
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                    >
                      {order?.status}
                    </td>
                    <div
                      className='menu menu-sub menu-sub-dropdown w-150px w-md-150px p-5'
                      data-kt-menu='true'
                      style={{zIndex: 1000000}}
                    >
                      <div
                        className={`badge  fs-7 p-3 mb-3 fw-semibold cursor-pointer ${
                          order?.status === 'open' ? 'badge-primary' : 'badge-light-dark'
                        } `}
                        onClick={() => handleStatusChange('open')}
                      >
                        open
                      </div>
                      <div
                        className={`badge  fs-7 p-3 mb-3 fw-semibold cursor-pointer ${
                          order?.status === 'close' ? 'badge-success' : 'badge-light-dark'
                        } `}
                        onClick={() => handleStatusChange('close')}
                      >
                        close
                      </div>
                    </div>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className='card card-flush py-4 flex-row-fluid'>
          <div className='card-header'>
            <div className='card-title'>
              <h2>Vendor Details</h2>
            </div>
          </div>

          <div className='card-body pt-0'>
            <div className='table-responsive'>
              <table className='table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px'>
                <tbody className='fw-semibold text-gray-600'>
                  <tr>
                    <td className='text-muted'>
                      <div className='d-flex align-items-center'>
                        <i className='ki-duotone ki-profile-circle fs-2 me-2'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                          <span className='path3'></span>
                        </i>
                        Vendor
                      </div>
                    </td>
                    <td className='fw-bold text-end'>
                      <div className='d-flex align-items-center justify-content-end'>
                        <div className='symbol symbol-circle symbol-25px overflow-hidden me-3'>
                          <a href='../../demo1/dist/apps/ecommerce/customers/details.html'></a>
                        </div>

                        <a
                          href='../../demo1/dist/apps/ecommerce/customers/details.html'
                          className='text-gray-600 text-hover-primary'
                        >
                          {order?.vendor?.displayName}
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className='text-muted'>
                      <div className='d-flex align-items-center'>
                        <i className='ki-duotone ki-sms fs-2 me-2'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                        </i>
                        Email
                      </div>
                    </td>
                    <td className='fw-bold text-end'>
                      <a
                        href='../../demo1/dist/apps/user-management/users/view.html'
                        className='text-gray-600 text-hover-primary'
                      >
                        {order?.vendor?.emailAddress}
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td className='text-muted'>
                      <div className='d-flex align-items-center'>
                        <i className='ki-duotone ki-phone fs-2 me-2'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                        </i>
                        Phone
                      </div>
                    </td>
                    <td className='fw-bold text-end'>{order?.vendor?.contactNumbers?.workPhone}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className='card card-flush py-4 flex-row-fluid'>
          <div className='card-header'>
            <div className='card-title'>
              <h2>Employee Details</h2>
            </div>
          </div>

          <div className='card-body pt-0'>
            <div className='table-responsive'>
              <table className='table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px'>
                <tbody className='fw-semibold text-gray-600'>
                  <tr>
                    <td className='text-muted'>
                      <div className='d-flex align-items-center'>
                        <i className='ki-duotone ki-devices fs-2 me-2'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                          <span className='path3'></span>
                          <span className='path4'></span>
                          <span className='path5'></span>
                        </i>
                        Name
                        <span
                          className='ms-1'
                          data-bs-toggle='tooltip'
                          title='View the invoice generated by this order.'
                        >
                          <i className='ki-duotone ki-information-5 text-gray-500 fs-6'>
                            <span className='path1'></span>
                            <span className='path2'></span>
                            <span className='path3'></span>
                          </i>
                        </span>
                      </div>
                    </td>
                    <td className='fw-bold text-end'>
                      <a
                        href='../../demo1/dist/apps/invoices/view/invoice-3.html'
                        className='text-gray-600 text-hover-primary'
                      >
                        {order?.employee?.firstName + ' ' + order?.employee?.lastName}
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td className='text-muted'>
                      <div className='d-flex align-items-center'>
                        <i className='ki-duotone ki-truck fs-2 me-2'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                          <span className='path3'></span>
                          <span className='path4'></span>
                          <span className='path5'></span>
                        </i>
                        Email
                        <span
                          className='ms-1'
                          data-bs-toggle='tooltip'
                          title='View the shipping manifest generated by this order.'
                        >
                          <i className='ki-duotone ki-information-5 text-gray-500 fs-6'>
                            <span className='path1'></span>
                            <span className='path2'></span>
                            <span className='path3'></span>
                          </i>
                        </span>
                      </div>
                    </td>
                    <td className='fw-bold text-end'>
                      <a href='#' className='text-gray-600 text-hover-primary'>
                        {order?.employee?.email}
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td className='text-muted'>
                      <div className='d-flex align-items-center'>
                        <i className='ki-duotone ki-discount fs-2 me-2'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                        </i>
                        Phone
                        <span
                          className='ms-1'
                          data-bs-toggle='tooltip'
                          title='Reward value earned by customer when purchasing this order'
                        >
                          <i className='ki-duotone ki-information-5 text-gray-500 fs-6'>
                            <span className='path1'></span>
                            <span className='path2'></span>
                            <span className='path3'></span>
                          </i>
                        </span>
                      </div>
                    </td>
                    <td className='fw-bold text-end'>{order?.employee?.workPhoneNo}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <OrderManagementHistory />
    </div>
  )
}

export default OrderManagementHeader
