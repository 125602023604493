import React, {useEffect, useState} from 'react'
import {useAuth} from '../../../modules/auth'
import {
  editEQTimesheet,
  getEquipmentsByProjectId,
  getEQTimesheetByProjectId,
  postEQTimesheet,
  // postTimesheetPayroll,
} from '../core/_requests'
import {Link, useParams} from 'react-router-dom'
import Swal from 'sweetalert2'

export default function EQTimesheet() {
  const id = useParams().id

  const {currentUser} = useAuth()
  const [payrollLoading, setPayrollLoading] = useState(false)
  const [equipments, setEquipments] = useState([
    {
      productId: '',
      productName: '',
      invoiceHourlyRate: 0,
      sku: '',
      mobilizedHours: 0,
      extraHours: 0,
    },
  ])

  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [month, setMonth] = useState(new Date().getMonth() + 1)
  const [loading, setLoading] = useState(false)
  const [timesheetData, setTimesheetData] = useState([])
  const [monthData, setMonthData] = useState('')
  const [updateId, setUpdateId] = useState('')

  useEffect(() => {
    const fetchEquipments = async () => {
      const res = await getEquipmentsByProjectId(id)
      if (res) {
        const temp = res?.map((item) => {
          return {
            productId: item?._id,
            productName: item?.productName || item?.itemId?.productName,
            sku: item?.itemId?.sku,
            invoiceHourlyRate: item?.itemId?.invoiceHourlyRate,
            mobilizedHours: 0,
            extraHours: 0,
          }
        })
        setEquipments(temp)
      }
    }
    fetchEquipments()
  }, [id])

  useEffect(() => {
    fetchTimeSheetData()
  }, [])

  const fetchTimeSheetData = async () => {
    const res = await getEQTimesheetByProjectId(id)
    if (res) {
      setTimesheetData(res)
    }
  }

  const handleSubmit = async () => {
    if (!startDate || !endDate) return alert('Please select start and end date')
    setLoading(true)
    const data = {
      projectId: id,
      startDate,
      endDate,
      month,
      equipments,
      company: currentUser?.company,
      organization: currentUser?.organization,
    }
    const res = await postEQTimesheet(data)
    console.log('res', res)
    if (res) {
      setLoading(false)
      const closeButton = document.getElementById('kt_modal_create_app_cancel')
      closeButton.click()
      Swal.fire({
        icon: 'success',
        title: 'Project TimeSheet Created Successfully',
      })
    }
  }

  const handleEdit = async () => {
    if (!startDate || !endDate) return alert('Please select start and end date')
    setLoading(true)
    const data = {
      projectId: id,
      startDate,
      endDate,
      month,
      equipments,
      company: currentUser?.company,
      organization: currentUser?.organization,
    }
    const res = await editEQTimesheet(updateId, data)
    console.log('res', res)
    if (res) {
      setLoading(false)
      const closeButton = document.getElementById('kt_modal_edit_app_cancel')
      closeButton.click()
      Swal.fire({
        icon: 'success',
        title: 'Project TimeSheet Updated Successfully',
      })
      fetchTimeSheetData()
    }
  }

  // const handleGeneratingPayroll = async (obj) => {
  //   setPayrollLoading(true)
  //   const data = {
  //     projectId: obj?.projectId,
  //     timesheetId: obj?._id,
  //     startDate: obj?.startDate,
  //     endDate: obj?.endDate,
  //     month: obj?.month,
  //     equipments,
  //     company: currentUser.company,
  //     organization: currentUser.organization,
  //   }
  //   await postTimesheetPayroll(data).then((res) => {
  //     Swal.fire({
  //       icon: 'success',
  //       title: 'Payroll Generated',
  //       text: 'Payroll has been generated successfully!',
  //     })
  //     setPayrollLoading(false)
  //   })
  // }

  const formatDate = (date) => {
    const d = new Date(date)
    return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
  }

  return (
    <div className='card'>
      <div className='card-header border-0 pt-5 d-flex align-items-center justify-between'>
        <span className=' card-label fw-bold fs-3 ps-20 pt-8 '>EQ Timesheet</span>

        <div className='d-flex gap-3'>
          <button
            type='button'
            className='btn btn-primary btn-sm'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_create_app'
            onClick={() => {
              setStartDate(new Date())
              setEndDate(new Date())
              setMonth(new Date().getMonth() + 1)
            }}
          >
            Create TimeSheet
          </button>
        </div>
      </div>

      <div className='card-header border-0 pt-5 '>
        <div className=' d-flex flex-row  card-title align-items-start'>
          <h3 className='d-flex flex-row card-title align-items-start '></h3>
        </div>
        <div className='card-toolbar'></div>

        <div className='card-body py-3 w-100 '>
          {payrollLoading ? (
            <div className='d-flex align-items-center justify-center'>
              <div className='loader'></div>
            </div>
          ) : (
            <div className='table-responsive min-h-500px'>
              <table className='table align-middle gs-0 gy-4 w-100'>
                <thead>
                  <tr className='fw-bold text-muted bg-light'>
                    <th className='ps-4 min-w-50px rounded-start'>Sr. No.</th>
                    <th className='min-w-125px'>Month</th>
                    <th className='min-w-125px'>No. of Equipments</th>
                    <th className='min-w-125px'>Start Date</th>
                    <th className='min-w-150px'>End Date</th>
                    <th className='min-w-150px'>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {timesheetData?.map((item, i) => (
                    <tr key={i}>
                      <td className='ps-4 text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        {i + 1}
                      </td>
                      <td
                        className='text-dark capitalize fw-bold text-hover-primary mb-1 fs-6 cursor-pointer'
                        onClick={() => {
                          setMonthData(item?.month)
                          setStartDate(new Date(item?.startDate))
                          setEndDate(new Date(item?.endDate))
                          setEquipments(item?.equipments)
                        }}
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_view'
                      >
                        {item?.month}
                      </td>
                      <td className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        {item?.equipments?.length}
                      </td>
                      <td className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        {formatDate(item?.startDate)}
                      </td>
                      <td className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        {formatDate(item?.endDate)}
                      </td>
                      <td className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        {/* action dropdown with edit and generate summary */}
                        <div className='dropdown dropdown-inline'>
                          <button
                            className='btn btn-sm btn-bg-light btn-active-light-primary cursor-pointer dropdown-toggle'
                            data-bs-toggle='dropdown'
                            aria-expanded='false'
                          >
                            Action
                          </button>
                          <ul className='dropdown-menu dropdown-menu-end'>
                            <li>
                              <a
                                className='dropdown-item cursor-pointer'
                                data-bs-toggle='modal'
                                data-bs-target='#kt_modal_edit_app'
                                onClick={() => {
                                  setMonth(item?.month)
                                  setUpdateId(item?._id)
                                  setStartDate(new Date(item?.startDate))
                                  setEndDate(new Date(item?.endDate))
                                  setEquipments(item?.equipments)
                                }}
                              >
                                Edit
                              </a>
                            </li>
                            {/* <li>
                              <Link
                                to={`/project/category-summary/${item?._id}`}
                                className='dropdown-item cursor-pointer'
                              >
                                Generate Category Summary
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={`/project/individual-summary/${item?._id}`}
                                className='dropdown-item cursor-pointer'
                              >
                                Generate Indiviudal Summary
                              </Link>
                            </li>
                            <li>
                              <button
                                className='dropdown-item cursor-pointer'
                                // onClick={() => handleGeneratingPayroll(item)}
                              >
                                Generate Payroll
                              </button>
                            </li> */}
                          </ul>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>

      <div className='modal fade' id='kt_modal_create_app' tabIndex={-1} aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered mw-850px'>
          <div className='modal-content'>
            <div className='modal-header' id='kt_modal_create_app_header'>
              <h2 className='fw-bolder'>Generate TimeSheet</h2>

              <button
                type='button'
                className='btn btn-sm btn-icon btn-active-light-primary'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <i className='bi bi-x fs-3'></i>
              </button>
            </div>
            <div className='modal-body scroll-y mx-5 my-7'>
              {/* select month */}
              <div className='fv-row mb-10'>
                <label className='required fw-bold fs-6 mb-2'>Select Month</label>
                <input
                  type='month'
                  className='form-control form-control-solid'
                  placeholder='Month'
                  value={month}
                  required
                  onChange={(e: any) => setMonth(e.target.value)}
                />
              </div>

              <div className='fv-row mb-10 gx-10 row'>
                <div className='col-md-6'>
                  <label className='required fw-bold fs-6 mb-2'>Start Date</label>
                  <input
                    type='date'
                    className='form-control form-control-solid'
                    placeholder='Start Date'
                    value={
                      new Date(startDate)?.toISOString().split('T')[0] ||
                      new Date().toISOString().split('T')[0]
                    }
                    required
                    onChange={(e: any) => setStartDate(e.target.value)}
                  />
                </div>
                <div className='col-md-6'>
                  <label className='required fw-bold fs-6 mb-2'>End Date</label>
                  <input
                    type='date'
                    className='form-control form-control-solid'
                    placeholder='End Date'
                    value={
                      new Date(endDate)?.toISOString().split('T')[0] ||
                      new Date().toISOString().split('T')[0]
                    }
                    required
                    onChange={(e: any) => setEndDate(e.target.value)}
                  />
                </div>
              </div>

              {/* employee table with regular and OT input field */}
              <div className='table-responsive'>
                <table className='table align-middle gs-0 gy-4 w-100'>
                  <thead>
                    <tr className='fw-bold text-muted bg-light'>
                      <th className='ps-4 min-w-200px rounded-start'>Product Name</th>
                      <th className='min-w-125px'>SKU</th>
                      <th className='min-w-125px'>Invoice Rate (per hour)</th>
                      <th className='min-w-125px'>Mobilized Hours</th>
                      <th className='min-w-125px'>Extra Hours</th>
                    </tr>
                  </thead>

                  <tbody>
                    {equipments?.map((equipment, i) => (
                      <tr key={i}>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <div
                                // to={`view-employee/${item._id}`}
                                className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                              >
                                {equipment?.productName}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                          >
                            {equipment?.sku}
                          </a>
                        </td>
                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                          >
                            {equipment?.invoiceHourlyRate}
                          </a>
                        </td>
                        <td>
                          <input
                            type='number'
                            className='form-control form-control-solid'
                            placeholder='Mobilized Hours'
                            min='0'
                            value={equipment?.mobilizedHours}
                            onChange={(e) => {
                              const data = [...equipments]
                              data[i].mobilizedHours = Number(e.target.value)
                              setEquipments(data)
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type='number'
                            className='form-control form-control-solid'
                            placeholder='Extra Hours'
                            min='0'
                            value={equipment?.extraHours}
                            onChange={(e) => {
                              const data = [...equipments]
                              data[i].extraHours = Number(e.target.value)
                              setEquipments(data)
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className='modal-footer flex-center'>
              <button
                type='button'
                className='btn btn-light btn-active-light-primary me-3'
                data-bs-dismiss='modal'
                id='kt_modal_create_app_cancel'
              >
                Close
              </button>
              <button
                type='button'
                className='btn btn-primary'
                disabled={loading}
                onClick={handleSubmit}
              >
                Generate
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='modal fade' id='kt_modal_edit_app' tabIndex={-1} aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered mw-850px'>
          <div className='modal-content'>
            <div className='modal-header' id='kt_modal_create_app_header'>
              <h2 className='fw-bolder'>Edit TimeSheet</h2>

              <button
                type='button'
                className='btn btn-sm btn-icon btn-active-light-primary'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <i className='bi bi-x fs-3'></i>
              </button>
            </div>
            <div className='modal-body scroll-y mx-5 my-7'>
              {/* select month */}
              <div className='fv-row mb-10'>
                <label className='required fw-bold fs-6 mb-2'>Select Month</label>
                <input
                  type='month'
                  className='form-control form-control-solid'
                  placeholder='Month'
                  value={month}
                  required
                  onChange={(e: any) => setMonth(e.target.value)}
                />
              </div>

              <div className='fv-row mb-10 gx-10 row'>
                <div className='col-md-6'>
                  <label className='required fw-bold fs-6 mb-2'>Start Date</label>
                  <input
                    type='date'
                    className='form-control form-control-solid'
                    placeholder='Start Date'
                    value={
                      new Date(startDate)?.toISOString().split('T')[0] ||
                      new Date().toISOString().split('T')[0]
                    }
                    required
                    onChange={(e: any) => setStartDate(e.target.value)}
                  />
                </div>
                <div className='col-md-6'>
                  <label className='required fw-bold fs-6 mb-2'>End Date</label>
                  <input
                    type='date'
                    className='form-control form-control-solid'
                    placeholder='End Date'
                    value={
                      new Date(endDate)?.toISOString().split('T')[0] ||
                      new Date().toISOString().split('T')[0]
                    }
                    required
                    onChange={(e: any) => setEndDate(e.target.value)}
                  />
                </div>
              </div>

              {/* employee table with regular and OT input field */}
              <div className='table-responsive'>
                <table className='table align-middle gs-0 gy-4 w-100'>
                  <thead>
                    <tr className='fw-bold text-muted bg-light'>
                      <th className='ps-4 min-w-200px rounded-start'>Product Name</th>
                      <th className='min-w-125px'>SKU</th>
                      <th className='min-w-125px'>Invoice Rate (per hour)</th>
                      <th className='min-w-125px'>Mobilized Hours</th>
                      <th className='min-w-125px'>Extra Hours</th>
                    </tr>
                  </thead>

                  <tbody>
                    {equipments?.map((equipment, i) => (
                      <tr>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <div
                                // to={`view-employee/${item._id}`}
                                className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                              >
                                {equipment?.productName}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                          >
                            {equipment?.sku}
                          </a>
                        </td>
                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                          >
                            {equipment?.invoiceHourlyRate}
                          </a>
                        </td>
                        <td>
                          <input
                            type='number'
                            className='form-control form-control-solid'
                            placeholder='Mobilized Hours'
                            min='0'
                            value={equipment?.mobilizedHours}
                            onChange={(e) => {
                              const data = [...equipments]
                              data[i].mobilizedHours = Number(e.target.value)
                              setEquipments(data)
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type='number'
                            className='form-control form-control-solid'
                            placeholder='Extra Hours'
                            min='0'
                            value={equipment?.extraHours}
                            onChange={(e) => {
                              const data = [...equipments]
                              data[i].extraHours = Number(e.target.value)
                              setEquipments(data)
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className='modal-footer flex-center'>
              <button
                type='button'
                className='btn btn-light btn-active-light-primary me-3'
                data-bs-dismiss='modal'
                id='kt_modal_edit_app_cancel'
              >
                Close
              </button>
              <button
                type='button'
                className='btn btn-warning'
                disabled={loading}
                onClick={handleEdit}
              >
                Edit
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='modal fade' id='kt_modal_view' tabIndex={-1} aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered mw-850px'>
          <div className='modal-content'>
            <div className='modal-header' id='kt_modal_create_app_header'>
              <h2 className='fw-bolder uppercase'>{monthData} Timesheet</h2>

              <button
                type='button'
                className='btn btn-sm btn-icon btn-active-light-primary'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <i className='bi bi-x fs-3'></i>
              </button>
            </div>
            <div className='modal-body scroll-y mx-5 my-7'>
              {/* select month */}
              <div className='fv-row mb-10'>
                <label className='fw-bold fs-6 mb-2'>Month</label>
                <input
                  type='text'
                  className='form-control form-control-solid'
                  placeholder='Month'
                  value={monthData}
                  readOnly
                />
              </div>

              <div className='fv-row mb-10 gx-10 row'>
                <div className='col-md-6'>
                  <label className='fw-bold fs-6 mb-2'>Start Date</label>
                  <input
                    type='date'
                    className='form-control form-control-solid'
                    placeholder='Start Date'
                    value={
                      new Date(startDate)?.toISOString().split('T')[0] ||
                      new Date().toISOString().split('T')[0]
                    }
                    readOnly
                  />
                </div>
                <div className='col-md-6'>
                  <label className='fw-bold fs-6 mb-2'>End Date</label>
                  <input
                    type='date'
                    className='form-control form-control-solid'
                    placeholder='End Date'
                    value={
                      new Date(endDate)?.toISOString().split('T')[0] ||
                      new Date().toISOString().split('T')[0]
                    }
                    readOnly
                  />
                </div>
              </div>

              {/* employee table with regular and OT input field */}
              <div className='table-responsive'>
                <table className='table align-middle gs-0 gy-4 w-100'>
                  <thead>
                    <tr className='fw-bold text-muted bg-light'>
                      <th className='ps-4 min-w-200px rounded-start'>Product Name</th>
                      <th className='min-w-125px'>SKU</th>
                      <th className='min-w-125px'>Invoice Rate (per hour)</th>
                      <th className='min-w-125px'>Mobilized Hours</th>
                      <th className='min-w-125px'>Extra Hours</th>
                    </tr>
                  </thead>

                  <tbody>
                    {equipments?.map((equipment, i) => (
                      <tr>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <div
                                // to={`view-employee/${item._id}`}
                                className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                              >
                                {equipment?.productName}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                          >
                            {equipment?.sku}
                          </a>
                        </td>
                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                          >
                            {equipment?.invoiceHourlyRate}
                          </a>
                        </td>
                        <td>
                          <input
                            type='number'
                            className='form-control form-control-solid'
                            placeholder='Mobilized Hours'
                            min='0'
                            value={equipment?.mobilizedHours}
                            readOnly
                          />
                        </td>
                        <td>
                          <input
                            type='number'
                            className='form-control form-control-solid'
                            placeholder='Extra Hours'
                            min='0'
                            value={equipment?.extraHours}
                            readOnly
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className='modal-footer flex-center'>
              <button
                type='button'
                className='btn btn-light btn-active-light-primary me-3'
                data-bs-dismiss='modal'
                id='kt_modal_create_app_cancel'
                onClick={() => {
                  setStartDate(new Date())
                  setEndDate(new Date())
                  setMonth(new Date().getMonth() + 1)
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
