import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  employeeGroups: [],
  isLoading: false,
  fetch: true,
}

const hrpEmployeeGroupSlice = createSlice({
  name: 'hrpEmployeeGroup',
  initialState,
  reducers: {
    setEmployeeGroups: (state, action) => {
      state.employeeGroups = action.payload
      state.isLoading = false
      state.fetch = false
    },
    setEmployeeGroupsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setEmployeeGroupsDataFetch: (state) => {
      state.fetch = true
    },
  },
})

export const {setEmployeeGroups, setEmployeeGroupsLoading, setEmployeeGroupsDataFetch} =
  hrpEmployeeGroupSlice.actions
export default hrpEmployeeGroupSlice.reducer
