import React, {FC, useEffect, useState} from 'react'
import {ErrorMessage, Field, useFormikContext} from 'formik'
import {ICreateAccount} from '../../core/CreateAccountWizardHelper'
import {useAuth} from '../../../../modules/auth'

const CTCItem: FC<{name: string; label: string}> = ({name, label}) => {
  const {values, setFieldValue} = useFormikContext<ICreateAccount>()
  const enabled = values?.ctc[name]?.enabled

  return (
    <div className='row mb-6 d-flex align-items-center'>
      <div className='col-lg-3 col-md-3 col-sm-12'>
        <div className='form-check form-check-custom form-check-solid'>
          <input
            className='form-check-input'
            type='checkbox'
            id={`ctc_${name}_enabled`}
            checked={enabled}
            onChange={(e) => {
              setFieldValue(`ctc.${name}.enabled`, e.target.checked)
              setFieldValue(`ctc.${name}.value`, 0)
              setFieldValue(`ctc.${name}.type`, 'month')
            }}
          />
          <label className='form-check-label' htmlFor={`ctc_${name}_enabled`}>
            {label}
          </label>
        </div>
      </div>
      {enabled && (
        <>
          <div className='col-lg-4 col-md-4 col-sm-12'>
            <Field
              type='number'
              name={`ctc.${name}.value`}
              className='form-control form-control-lg form-control-solid'
              placeholder='0'
            />
            <ErrorMessage name={`ctc.${name}.value`} component='div' className='text-danger mt-2' />
          </div>
          <div className='col-lg-5 col-md-5 col-sm-12'>
            <Field
              as='select'
              name={`ctc.${name}.type`}
              className='form-select form-select-lg form-select-solid'
            >
              <option value='month'>Per Month</option>
              <option value='year'>Per Year</option>
            </Field>
            <ErrorMessage name={`ctc.${name}.type`} component='div' className='text-danger mt-2' />
          </div>
        </>
      )}
    </div>
  )
}

const CTC: FC = () => {
  const {values} = useFormikContext<ICreateAccount>()
  const [totalCTC, setTotalCTC] = useState(0)
  const {currentUser} = useAuth()

  useEffect(() => {
    const calculateTotalCTC = () => {
      let total = 0
      total += Number(values.monthlyPay) * 12
      const ctcItems = values.ctc
      for (const key in ctcItems) {
        if (ctcItems[key].enabled) {
          total += ctcItems[key].value * (ctcItems[key].type === 'month' ? 12 : 1)
        }
      }
      setTotalCTC(total)
    }

    calculateTotalCTC()
  }, [values.ctc, values.dailyWorkingHours, values.monthlyPay])

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>Cost to Company (CTC) Information</h2>
      </div>

      <div className='d-flex whitespace-nowrap space-x-4 items-center w-100 mb-10'>
        <label className='form-check-label col-md-3'>Daily Working Hours:</label>
        <Field
          type='number'
          name='dailyWorkingHours'
          placeholder='0.00'
          className='form-control form-control-lg form-control-solid'
          disabled
        />
      </div>

      <div className='d-flex whitespace-nowrap space-x-4 items-center w-100 mb-10'>
        <label className='form-check-label col-md-3'>Basic Monthly Pay :</label>
        <Field
          type='number'
          name='monthlyPay'
          placeholder='0.00'
          className='form-control form-control-lg form-control-solid'
          disabled
        />
      </div>

      <CTCItem name='accomodation' label='Accomodation' />
      <CTCItem name='food' label='Food' />
      <CTCItem name='transportation' label='Transportation' />
      <CTCItem name='medical' label='Medical' />
      <CTCItem name='airTickets' label='Air Tickets' />

      {/* Total CTC Calculation */}
      <div className='d-flex justify-content-end mt-6 fs-3 align-items-center'>
        <label className='fw-bold text-dark'>Total CTC: </label>
        <span className='ms-4 fw-bolder'>
          {currentUser?.baseCurrency}{' '}
          {totalCTC?.toLocaleString('en-US', {
            minimumFractionDigits: 2,
          })}
        </span>
      </div>
    </div>
  )
}

export {CTC}
