import React, {useEffect, useState, memo} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {
  deactivateCRMCustomer,
  getContactsWithoutCustomer,
  getCRMCustomers,
  getLeadsWithoutCustomer,
} from '../core/_requests'
import {useAuth} from '../../../modules/auth'
import {useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../../redux/store'
import {setCRMCustomers} from '../../../redux/crm/customers/customersSlice'
import {Dropdown, Form, Modal} from 'react-bootstrap'
import {KTIcon} from '../../../../_metronic/helpers'

// Extract CustomerRow component
const CustomerRow = memo(
  ({
    customer,
    index,
    editAccess,
    handleDeleteToggle,
  }: {
    customer: any
    index: number
    editAccess: boolean
    handleDeleteToggle: (id: string) => void
  }) => (
    <tr>
      <td className='text-center'>{index + 1}</td>
      <td>
        <div className='d-flex align-items-center'>
          <div className='d-flex justify-content-start flex-column'>
            <Link
              to={`/sales/customer/${customer._id}`}
              className='text-dark fw-bold text-hover-primary mb-1 fs-6'
            >
              {customer.displayName}
            </Link>
          </div>
        </div>
      </td>
      <td>
        <Link
          to={`/sales/customer/${customer._id}`}
          className={`capitalize badge badge-light-${
            customer.customerType === 'business' ? 'success' : 'info'
          }`}
        >
          {customer.customerType}
        </Link>
      </td>
      <td>
        <Link
          to={`/sales/customer/${customer._id}`}
          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
        >
          {customer.companyName}
        </Link>
      </td>
      <td>
        <Link
          to={`/sales/customer/${customer._id}`}
          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
        >
          {customer.emailAddress}
        </Link>
      </td>
      <td>
        <span className='badge badge-light-primary fs-7 fw-semibold'>{customer.currency}</span>
      </td>
      {editAccess && (
        <td className='text-center'>
          <Dropdown>
            <Dropdown.Toggle variant='secondary' size='sm' id='dropdown-basic'>
              Action
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>
                <Link to={`/sales/customer/edit/crm/${customer._id}`}>Edit</Link>
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => handleDeleteToggle(customer._id)}
                data-bs-toggle='modal'
                data-bs-target='#myModal'
                className='menu-link px-3 hover:w-full'
                data-kt-users-table-filter='delete_row'
              >
                {customer?.isActivated ? 'Deactivate' : 'Activate'}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      )}
    </tr>
  )
)

const CRMCustomerPage = () => {
  const {currentUser} = useAuth()
  const [permissions, setPermissions] = useState<any>({})
  const customers = useSelector((state: RootState) => state.crm.customers.customers)
  const crmCustomersFetch = useSelector((state: RootState) => state.crm.customers.fetch)
  const leads = useSelector((state: RootState) => state.crm.leads.leads)
  const contacts = useSelector((state: RootState) => state.crm.contacts.contacts)
  const crmLeadsFetch = useSelector((state: RootState) => state.crm.leads.fetch)
  const crmContactsFetch = useSelector((state: RootState) => state.crm.contacts.fetch)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [showModal, setShowModal] = useState(false)
  const [isLead, setIsLead] = useState(true)
  const [selectedCRMId, setSelectedCRMId] = useState('')
  const [leadList, setLeadList] = useState<any[]>([])
  const [contactList, setContactList] = useState<any[]>([])
  const [selectedIndex, setSelectedIndex] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (currentUser.permissions) {
      setPermissions(currentUser.permissions)
    }
  }, [currentUser])

  const fetchCustomers = async () => {
    try {
      setLoading(true)
      setError(null)
      const res = await getCRMCustomers(currentUser.organization)
      dispatch(setCRMCustomers(res))
    } catch (err) {
      setError('Failed to fetch customers. Please try again.')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (currentUser.organization && crmCustomersFetch) {
      fetchCustomers()
    }
  }, [currentUser.organization])

  useEffect(() => {
    const fetchLeads = async () => {
      const res = await getLeadsWithoutCustomer(currentUser.organization)
      setLeadList(res)
    }

    const fetchContacts = async () => {
      const res = await getContactsWithoutCustomer(currentUser.organization)
      setContactList(res)
    }

    if (currentUser.organization !== undefined) {
      if (crmLeadsFetch) fetchLeads()
      else {
        const temp = leads.filter((item) => !item.isCustomer)
        setLeadList(temp)
      }
      if (crmContactsFetch) fetchContacts()
      else {
        const temp = contacts.filter((item) => !item.isCustomer)
        setContactList(temp)
      }
    }
  }, [currentUser.organization])

  const handleCustomerAddFromCRM = () => {
    setShowModal(false)
    navigate(`/sales/customer/addCustomer/${selectedCRMId}/${isLead ? 'lead' : 'contact'}`)
  }

  const handleDeleteToggle = (i) => {
    setSelectedIndex(i)
  }

  const handleDelete = async (i) => {
    try {
      await deactivateCRMCustomer(i).then(() => {
        fetchCustomers()
      })
    } catch (error) {
      console.error('Error deleting employee:', error)
    }
  }

  if (loading) {
    return (
      <div className='card'>
        <div className='card-body d-flex align-items-center justify-content-center min-h-300px'>
          <div className='loader'></div>
        </div>
      </div>
    )
  }

  if (error) {
    return (
      <div className='card'>
        <div className='card-body d-flex align-items-center justify-content-center min-h-300px'>
          <div className='alert alert-danger'>{error}</div>
        </div>
      </div>
    )
  }

  return (
    <div className='card'>
      <div className='card-header d-flex align-items-center justify-content-between'>
        <span className='card-label fw-bold fs-3 pt-8'>Customer List</span>
        {(permissions?.crmcustomers?.create || currentUser?.profileType === 'superadmin') && (
          <div className='card-toolbar'>
            <div className='btn btn-sm btn-light-info' onClick={() => setShowModal(true)}>
              <KTIcon iconName='plus' className='fs-2' />
              New Customer from CRM
            </div>
          </div>
        )}
      </div>
      <div className='card-body py-3'>
        {customers.length === 0 ? (
          <div className='text-center text-muted'>No customers found</div>
        ) : (
          <div className='table-responsive min-h-500px'>
            <table className='table align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='text-center min-w-25px rounded-start'>Sr. No.</th>
                  <th className='min-w-200px rounded-start'>Customer Display Name</th>
                  <th className='min-w-125px'>Customer Type</th>
                  <th className='min-w-125px'>Company Name</th>
                  <th className='min-w-150px'>Email</th>
                  <th className='min-w-150px'>Currency</th>
                  {(permissions?.crmcustomers?.update ||
                    currentUser?.profileType === 'superadmin') && (
                    <th className='min-w-150px text-center'>Action</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {customers.map((customer, index) => (
                  <CustomerRow
                    key={customer._id}
                    customer={customer}
                    index={index}
                    editAccess={
                      permissions?.crmcustomers?.update || currentUser?.profileType === 'superadmin'
                    }
                    handleDeleteToggle={handleDeleteToggle}
                  />
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false)
          setSelectedCRMId('')
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>New Customer from CRM</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap cursor-pointer'>
            <li className='nav-item'>
              <div
                className={`nav-link text-active-primary me-6  
                  ${isLead ? ' active' : ''}`}
                onClick={() => setIsLead(true)}
              >
                Lead
              </div>
            </li>
            <li className='nav-item'>
              <div
                className={`nav-link text-active-primary me-6  
                  ${!isLead ? ' active' : ''}`}
                onClick={() => setIsLead(false)}
              >
                Contact
              </div>
            </li>
          </ul>
          <Form className='!mt-6'>
            {isLead ? (
              <div style={{maxHeight: '60vh', overflowY: 'auto'}}>
                {leadList.map((lead) => (
                  <Form.Group key={lead._id} className='mb-3'>
                    <Form.Check
                      type='checkbox'
                      id={`lead-${lead._id}`}
                      label={lead.displayName}
                      checked={selectedCRMId === lead._id}
                      onChange={() => setSelectedCRMId(lead._id)}
                    />
                  </Form.Group>
                ))}
              </div>
            ) : (
              <div style={{maxHeight: '60vh', overflowY: 'auto'}}>
                {contactList.map((contact) => (
                  <Form.Group key={contact._id} className='mb-3'>
                    <Form.Check
                      type='checkbox'
                      id={`contact-${contact._id}`}
                      label={contact.displayName}
                      checked={selectedCRMId === contact._id}
                      onChange={() => setSelectedCRMId(contact._id)}
                    />
                  </Form.Group>
                ))}
              </div>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button
            type='button'
            className='btn btn-light'
            onClick={() => {
              setShowModal(false)
              setSelectedCRMId('')
            }}
          >
            Close
          </button>
          <button type='button' className='btn btn-primary' onClick={handleCustomerAddFromCRM}>
            Select
          </button>
        </Modal.Footer>
      </Modal>

      <div className='modal' id='myModal'>
        <div className='modal-dialog modal-dialog-centered '>
          <div className='modal-content'>
            <div className='modal-body text-center fs-6'>
              Are you sure you want to{' '}
              {customers.find((customer) => customer._id === selectedIndex)?.isActivated
                ? 'Deactivate'
                : 'Activate'}{' '}
              the {customers.find((customer) => customer._id === selectedIndex)?.displayName} ?
            </div>

            <div className='modal-footer text-center mx-auto'>
              <button
                type='button'
                className='btn btn-danger '
                data-bs-dismiss='modal'
                onClick={() => handleDelete(selectedIndex)}
              >
                {customers.find((customer) => customer._id === selectedIndex)?.isActivated
                  ? 'Deactivate'
                  : 'Activate'}
              </button>

              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                id='closeModalButtondelete'
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CRMCustomerPage
