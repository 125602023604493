import axios, {AxiosResponse} from 'axios'
const API_URL = process.env.REACT_APP_THEME_API_URL

const CRM_PROJECT_URL = `${API_URL}/crm/projects`
const CRM_PRODUCTS_URL = `${API_URL}/crm/products`
const CRM_SERVICES_URL = `${API_URL}/crm/services`
const LEADS_URL = `${API_URL}/crm/leads`
const CONTACTS_URL = `${API_URL}/crm/contacts`
const CRM_ACCOUNTS_URL = `${API_URL}/crm/accounts`
const CRM_AGENTS_URL = `${API_URL}/crm/agents`
const CRM_TASKS_URL = `${API_URL}/crm/tasks`
const CRM_CUSTOMERS_URL = `${API_URL}/crm/customers`
const CRM_QUOTES_URL = `${API_URL}/crm/quotes`

export const getCRMProjects = async (orgid: string) => {
  try {
    const response = await axios.get(`${CRM_PROJECT_URL}/${orgid}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

export const postCRMProject = async (data: any) => {
  try {
    const response = await axios.post(`${CRM_PROJECT_URL}/create`, data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

export const addProjectToCRM = async (data: any, orgid: string) => {
  try {
    const response = await axios.put(`${CRM_PROJECT_URL}/add-project/${orgid}`, data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

export const getCRMProducts = async (orgid: string) => {
  try {
    const response = await axios.get(`${CRM_PRODUCTS_URL}/${orgid}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

export const postCRMProduct = async (data: any) => {
  try {
    const response = await axios.post(`${CRM_PRODUCTS_URL}/create`, data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

export const addProductToCRM = async (data: any, orgid: string) => {
  try {
    const response = await axios.put(`${CRM_PRODUCTS_URL}/add-product/${orgid}`, data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

export const getCRMServices = async (orgid: string) => {
  try {
    const response = await axios.get(`${CRM_SERVICES_URL}/${orgid}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

export const postCRMService = async (data: any) => {
  try {
    const response = await axios.post(`${CRM_SERVICES_URL}/create`, data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

export const addServiceToCRM = async (data: any, orgid: string) => {
  try {
    const response = await axios.put(`${CRM_SERVICES_URL}/add-service/${orgid}`, data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

export const postLead = async (data: any) => {
  try {
    const response = await axios.post(`${LEADS_URL}/create`, data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

export const getLeads = async (orgid: string) => {
  try {
    const response = await axios.get(`${LEADS_URL}/${orgid}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

export const updateLead = async (id: string, data: any) => {
  try {
    const response = await axios.put(`${LEADS_URL}/${id}`, data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

export const deleteLead = async (id: string) => {
  try {
    const response = await axios.delete(`${LEADS_URL}/${id}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

export const changeLeadStatus = async (id: string, pipelineStatus: string) => {
  try {
    const response = await axios.put(`${LEADS_URL}/changepipeline/${id}`, {pipelineStatus})
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

export const getLeadsWithoutCustomer = async (orgid: string) => {
  try {
    const response = await axios.get(`${LEADS_URL}/getleadswithoutcustomer/${orgid}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

export const getLeadById = async (id: string) => {
  try {
    const response = await axios.get(`${LEADS_URL}/getleadbyid/${id}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

export const getContacts = async (orgid: string) => {
  try {
    const response = await axios.get(`${CONTACTS_URL}/${orgid}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

export const postContact = async (data: any) => {
  try {
    const response = await axios.post(`${CONTACTS_URL}/create`, data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

export const updateContact = async (id: string, data: any) => {
  try {
    const response = await axios.put(`${CONTACTS_URL}/${id}`, data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

export const deleteContact = async (id: string) => {
  try {
    const response = await axios.delete(`${CONTACTS_URL}/${id}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

export const changeContactStatus = async (id: string, pipelineStatus: string) => {
  try {
    const response = await axios.put(`${CONTACTS_URL}/changepipeline/${id}`, {pipelineStatus})
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

export const getContactsWithoutCustomer = async (orgid: string) => {
  try {
    const response = await axios.get(`${CONTACTS_URL}/getcontactswithoutcustomer/${orgid}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

export const getContactById = async (id: string) => {
  try {
    const response = await axios.get(`${CONTACTS_URL}/getcontactbyid/${id}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

export const getCRMAccounts = async (orgid: string) => {
  try {
    const response = await axios.get(`${CRM_ACCOUNTS_URL}/${orgid}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

export const postCRMAccount = async (data: any) => {
  try {
    const response = await axios.post(`${CRM_ACCOUNTS_URL}/create`, data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

export const updateCRMAccountName = async (id: string, data: any) => {
  try {
    const response = await axios.put(`${CRM_ACCOUNTS_URL}/updatename/${id}`, data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

export const updateCRMAccount = async (id: string, data: any) => {
  try {
    const response = await axios.put(`${CRM_ACCOUNTS_URL}/${id}`, data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

export const deleteCRMAccount = async (id: string) => {
  try {
    const response = await axios.delete(`${CRM_ACCOUNTS_URL}/${id}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

export const getCRMAccountById = async (id: string) => {
  try {
    const response = await axios.get(`${CRM_ACCOUNTS_URL}/getaccountbyid/${id}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

export const postCRMAgent = async (data: any) => {
  try {
    const response = await axios.post(`${CRM_AGENTS_URL}/create`, data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

export const getCRMAgents = async (orgid: string) => {
  try {
    const response = await axios.get(`${CRM_AGENTS_URL}/${orgid}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

export const addevents = async (formData, agentId) => {
  try {
    const response = await axios.post(`${CRM_TASKS_URL}/${agentId}`, formData)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

export const deleteEvent = async (id: string) => {
  try {
    const response = await axios.delete(`${CRM_TASKS_URL}/${id}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

export async function editEvent(todoId: string, data: any): Promise<Array<any>> {
  try {
    const response = await axios.put(`${CRM_TASKS_URL}/${todoId}`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Events error:', error)
    throw error
  }
}

export async function getAllevents(companyId: string): Promise<Array<any>> {
  try {
    const response = await axios.get(`${CRM_TASKS_URL}/all/${companyId}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Events error:', error)
    throw error
  }
}

export const getCRMCustomers = async (orgid: string) => {
  try {
    const response = await axios.get(`${CRM_CUSTOMERS_URL}/${orgid}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

export const getCRMCustomerById = async (id: string) => {
  try {
    const response = await axios.get(`${CRM_CUSTOMERS_URL}/getcustomerbyid/${id}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

export const deactivateCRMCustomer = async (id) => {
  try {
    const response = await axios.put(`${CRM_CUSTOMERS_URL}/deactivate/${id}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const editCustomer = async (data, id) => {
  try {
    const response = await axios.put(`${CRM_CUSTOMERS_URL}/${id}`, data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export async function getFilteredQuotes(
  query: string,
  orgid,
  page = 1,
  limit = 25,
  date = null
): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${CRM_QUOTES_URL}/filter/${orgid}?${query}&page=${page}&limit=${limit}&startDate=${date?.startDate}&endDate=${date?.endDate}`
    )
    return response.data
  } catch (error) {
    throw error
  }
}

export async function getFilteredQuotesByAgentId(
  query: string,
  agentId,
  page = 1,
  limit = 25,
  date = null
): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${CRM_QUOTES_URL}/agent/filter/${agentId}?${query}&page=${page}&limit=${limit}&startDate=${date?.startDate}&endDate=${date?.endDate}`
    )
    return response.data
  } catch (error) {
    throw error
  }
}

export async function updateValidationStatus(id: string, data: any): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.put(
      `${CRM_QUOTES_URL}/changevalidation/${id}`,
      data
    )
    return response.data
  } catch (error) {
    throw error
  }
}

export const postCRMQuote = async (quote: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.post(`${CRM_QUOTES_URL}/`, quote)
    return response.data
  } catch (error) {
    throw error
  }
}

export async function getCRMQuotebyids(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${CRM_QUOTES_URL}/quotebyid/${id}`)
    return response.data
  } catch (error) {
    throw error
  }
}

export async function updateCRMQuoteStatus(id: string, data: any): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.put(
      `${CRM_QUOTES_URL}/edit-status/${id}`,
      data
    )
    return response.data
  } catch (error) {
    throw error
  }
}

export async function getCRMQuotebyQuoteid(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${CRM_QUOTES_URL}/quotebyquoteid/${id}`)
    return response.data
  } catch (error) {
    throw error
  }
}

export const editCRMQuote = async (data: any, quoteid: string): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.put(`${CRM_QUOTES_URL}/${quoteid}`, data)
    return response.data
  } catch (error) {
    throw error
  }
}

export const revisedCRMQuote = async (data: any, quoteid: string): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.put(
      `${CRM_QUOTES_URL}/revised/${quoteid}`,
      data
    )
    return response.data
  } catch (error) {
    throw error
  }
}
