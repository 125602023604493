import React, {useCallback, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'
import {KTIcon, KTSVG} from '../../../../_metronic/helpers'
import {
  getAccountsForExpensePaidThrough,
  getPCR,
  updatePCRApprove,
  updatePCRReject,
} from '../core/_requests'
import {RiArrowDropDownLine} from 'react-icons/ri'

function PCRPage() {
  const {currentUser} = useAuth()
  const [permissions, setPermissions] = useState<any>({})
  const [pcr, setPcr] = useState([])
  const [dropdown, setDropdown] = useState([])
  const [loading, setLoading] = useState(true)
  const [paidThroughAccounts, setPaidThroughAccounts] = useState({})
  const [paidThroughAccount, setPaidThroughAccount] = useState('')
  const [selectedPayrollId, setSelectedPayrollId] = useState('')
  const [selectedIndex, setSelectedIndex] = useState(0)

  useEffect(() => {
    if (currentUser) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const fetchExpenseList = useCallback(async () => {
    try {
      setLoading(false)
      const res = await getPCR(currentUser?.organization)
      setPcr(res)
      setDropdown(Array(res?.length)?.fill(false))
    } catch (error) {
      console.error('Error fetching pcr list:', error)
    }
  }, [currentUser?.organization])

  const fetchAccounts = async () => {
    const resp = await getAccountsForExpensePaidThrough(currentUser?.organization)
    const paidThrough = {
      Cash: [],
      Bank: [],
      'Other Current Liability': [],
      Equity: [],
      'Other Current Asset': [],
      'Fixed Asset': [],
    }
    resp.forEach((r) => {
      if (r.accountType === 'cash') {
        paidThrough['Cash'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'bank') {
        paidThrough['Bank'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'othercurrentliability') {
        paidThrough['Other Current Liability'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'equity') {
        paidThrough['Equity'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'othercurrentasset') {
        paidThrough['Other Current Asset'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'fixedasset') {
        paidThrough['Fixed Asset'].push({
          value: r?._id,
          label: r?.accountName,
        })
      }
    })
    setPaidThroughAccounts(paidThrough)
  }

  const handleClick = (index) => {
    const newDropdownState = dropdown.map((_, i) => (i === index ? !dropdown[i] : false))
    setDropdown(newDropdownState)
  }

  useEffect(() => {
    if (currentUser.organization !== undefined) {
      fetchExpenseList()
      fetchAccounts()
    }
  }, [currentUser.organization])

  const handleApproved = async (id, index) => {
    const res = await updatePCRApprove(id, paidThroughAccount)
    if (res) {
      const updatedData = pcr.map((item, idx) =>
        idx === index ? {...item, approval: 'accepted'} : item
      )
      setPcr(updatedData)
      const closeModalButton = document.getElementById('closeModalButton1')
      if (closeModalButton) {
        closeModalButton.click()
      }
    }
  }

  const handleReject = async (id, index) => {
    const res = await updatePCRReject(id)
    if (res) {
      const updatedData = pcr.map((item, idx) =>
        idx === index ? {...item, approval: 'rejected'} : item
      )
      setPcr(updatedData)
    }
  }

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    return new Date(createdAt).toLocaleDateString('en-GB')
  }

  return (
    <div className='card card-flush'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-center d-flex gap-4'>
          <span className='card-label fw-bold fs-3 mb-1'>Petty Cash Request</span>
          {/* <input
            type='text'
            className='form-control form-control-solid me-3'
            value={searchquery}
            placeholder='Search Reference'
            onChange={(e) => setSearchquery(e.target.value)}
          />
          <DateFilter dateState={dateState} setDateState={setDateState} /> */}
        </h3>

        {(currentUser?.profileType === 'superadmin' || permissions?.pcr?.create) && (
          <div className='card-toolbar'>
            <Link to='/account/pcr/add' className='btn btn-light-primary'>
              <KTIcon iconName='plus' className='fs-2' />
              Add Petty Cash Request
            </Link>
          </div>
        )}
      </div>
      <div className='card-body py-3'>
        {loading ? (
          <div className='d-flex align-items-center justify-content-center'>
            <div className='loader'></div>
          </div>
        ) : (
          <div className='table-responsive min-h-500px'>
            <table className='table align-middle gs-0 gy-4 table-row-bordered'>
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-50px rounded-start'>Date</th>
                  <th className='min-w-150px'>Employee</th>
                  <th className='min-w-150px'>Account Name</th>
                  <th className='min-w-100px'>Amount</th>
                  <th className='min-w-100px text-center'>Approval</th>
                  {(currentUser.profileType === 'superadmin' || permissions?.pcr?.update) && (
                    <th className='min-w-100px text-center'>Actions</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {pcr?.map((item: any, index: number) => (
                  <tr key={item?._id?.toString()}>
                    <td>
                      <Link
                        to={`${item?._id}`}
                        className='ps-4 text-dark fw-bold d-block mb-1 fs-6'
                      >
                        {formatCreatedAt(item?.date)}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`${item?._id}`}
                        className='text-dark fw-bold d-block mb-1 fs-6 capitalize'
                      >
                        {item?.employeeName}
                      </Link>
                    </td>
                    <td>
                      <Link to={`${item?._id}`} className='text-dark fw-bold d-block mb-1 fs-6'>
                        {item?.pcrAccount?.accountName}
                      </Link>
                    </td>
                    <td>
                      <Link to={`${item?._id}`} className='text-dark fw-bold d-block mb-1 fs-6'>
                        {item?.amount.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                        })}{' '}
                        {/* {item?.customer?.currency} */}
                      </Link>
                    </td>
                    <td>
                      <div className='text-dark text-center fw-bold text-hover-primary d-block mb-1 fs-6'>
                        <span
                          className={`badge  fs-7 fw-semibold ${
                            item?.approval === 'pending'
                              ? 'badge-light-primary'
                              : item?.approval === 'accepted'
                              ? 'badge-light-success'
                              : 'badge-light-danger'
                          }`}
                        >
                          {item?.approval}
                        </span>
                      </div>
                    </td>
                    {(permissions?.pcr?.update || currentUser?.profileType === 'superadmin') && (
                      <td className='relative'>
                        <div className='d-flex justify-content-center flex-shrink-0'>
                          <button
                            onClick={() => handleClick(index)}
                            className=' bg-gray-100 hover:bg-lime-50 px-6 py-2 flex items-center rounded self-end'
                          >
                            Actions <RiArrowDropDownLine />
                          </button>
                        </div>
                        {dropdown[index] && (
                          <div className='absolute flex flex-col top-16 right-[0px] z-50  bg-white  w-40 shadow-[0_16px_16px_6px_rgba(128,128,128,0.1)] rounded p-3 space-y-2'>
                            {/* <Link to={`/hrm/payroll/edit/${item._id}`}>
                              <button className='hover:bg-lime-50 p-2  text-start rounded'>
                                Edit
                              </button>
                            </Link> */}
                            {(item?.approval === 'pending' || item?.approval === 'rejected') && (
                              <button
                                className='hover:bg-green-50 p-2 text-start rounded'
                                data-bs-toggle='modal'
                                data-bs-target='#kt_modal_2'
                                onClick={() => {
                                  setSelectedIndex(index)
                                  setSelectedPayrollId(item?._id)
                                  setPaidThroughAccount(item?.paidThrough?._id || '')
                                }}
                              >
                                Approve
                              </button>
                            )}
                            {(item?.approval === 'pending' || item?.approval === 'accepted') && (
                              <button
                                className='hover:bg-red-50 p-2 text-start rounded'
                                onClick={() => handleReject(item._id, index)}
                              >
                                Reject
                              </button>
                            )}
                          </div>
                        )}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_modal_2'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>PCR Approval</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>

            <div className='modal-body'>
              <form>
                <div className='row rowwidthclient'>
                  <div className='mb-6'>
                    <label className='form-label required fs-6 fw-bold text-gray-700 mb-3'>
                      Select Paid Through Account
                    </label>
                    {/* begin::Input group */}
                    <select
                      className='form-select form-select-solid'
                      value={paidThroughAccount}
                      name='paidThrough'
                      onChange={(e) => {
                        setPaidThroughAccount(e.target.value)
                      }}
                    >
                      <option value=''>Select Account</option>
                      {Object.keys(paidThroughAccounts).map((key, index) => {
                        return (
                          <optgroup label={key} key={index}>
                            {paidThroughAccounts[key].map((item) => {
                              return (
                                <option value={item?.value} key={item?.value}>
                                  {item?.label}
                                </option>
                              )
                            })}
                          </optgroup>
                        )
                      })}
                    </select>
                  </div>
                </div>
              </form>
            </div>

            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                id='closeModalButton1'
              >
                Close
              </button>
              <button
                type='button'
                className='btn btn-success'
                onClick={() => handleApproved(selectedPayrollId, selectedIndex)}
              >
                Aprove
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PCRPage
