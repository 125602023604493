import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import profile from '../../../../_metronic/assets/profile.png'
import {useParams} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'
import {getEmployeeById} from '../../hrm/core/_requests'
import EmployeeOverview from './EmployeeOverview'
import HRPEmployeeProject from './HRPEmployeeProject'
import HRPFilesPage from './HRPFilesPage'
import HRPEmployeeAttendanceDetail from './HRPEmployeeAttendanceDetail'
import HRPEmployeeTimesheetDetail from './HRPEmployeeTimesheetDetail'

interface EmployeeData {
  firstName?: string
  lastName?: string
  workPhoneNo?: string
  currentAddress?: {
    country: string
  }
  email?: string
  dateOfJoining?: string
  totalCTC?: number
  totalWallet?: number
  maxLeaves?: number
}

const NAV_ITEMS = [
  {id: 'overview', label: 'Overview'},
  {id: 'project', label: 'Projects'},
  {id: 'tasks', label: 'Tasks'},
  {id: 'dps', label: 'Daily Planning Sheets'},
  {id: 'reports', label: 'Reports'},
  {id: 'timesheet', label: 'Timesheet'},
  {id: 'attendance', label: 'Attendance'},
  {id: 'performance', label: 'Performance'},
  {id: 'docs', label: 'Documents'},
]

export default function EmployeeDetail() {
  const {currentUser} = useAuth()
  const [data, setData] = useState<EmployeeData>()
  const [property, setProperty] = useState('overview')
  const {id} = useParams()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getEmployeeById(id)
        setData(res)
      } catch (error) {
        console.error('Error fetching the employee details', error)
      }
    }

    if (id) fetchData()
  }, [id])

  const renderFinancialCard = (title: string, amount?: number, subtitle?: string) => (
    <div className='col-lg-6'>
      <div className='card bg-light-secondary p-5 mt-4'>
        <div className='d-flex justify-content-between align-items-center'>
          <div>
            <h4 className='text-primary fw-bold'>{title}</h4>
            {subtitle && <span className='text-muted'>{subtitle}</span>}
          </div>
          <div className='fs-2x fw-bolder text-primary'>
            {currentUser?.baseCurrency} {amount?.toLocaleString()}
          </div>
        </div>
      </div>
    </div>
  )

  const renderNavItems = () =>
    NAV_ITEMS.map(({id, label}) => (
      <li className='nav-item' key={id}>
        <div
          className={`nav-link text-active-primary me-6 ${property === id ? 'active' : ''}`}
          onClick={() => setProperty(id)}
        >
          {label}
        </div>
      </li>
    ))

  const isValidJoiningDate =
    data?.dateOfJoining && data.dateOfJoining !== '1970-01-01T00:00:45.286Z'

  return (
    <div>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative border border-gray'>
                <img src={`${profile}`} alt='Metornic' />
                <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle  border-2 border-white h-20px w-20px'></div>
              </div>
            </div>

            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <div className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                      {data?.firstName} {data?.lastName}
                    </div>
                    <div>
                      <KTIcon iconName='verify' className='fs-1 text-primary' />
                    </div>
                  </div>

                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    {data?.workPhoneNo && (
                      <div className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'>
                        <i className='bi bi-telephone-fill fs-4 me-2'></i>
                        {data?.workPhoneNo}
                      </div>
                    )}
                    {data?.currentAddress?.country && data?.currentAddress?.country !== '' && (
                      <div className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'>
                        <KTIcon iconName='geolocation' className='fs-4 me-1' />
                        {data?.currentAddress?.country}
                      </div>
                    )}
                    {data?.email !== '' && (
                      <div className='d-flex align-items-center text-gray-400 text-hover-primary mb-2 me-5'>
                        <KTIcon iconName='sms' className='fs-4 me-1' />
                        {data?.email}
                      </div>
                    )}

                    {/* date of joining */}
                    {isValidJoiningDate && (
                      <div className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'>
                        <i className='bi bi-calendar3 fs-4 me-2'></i>
                        Joining Date: {new Date(data?.dateOfJoining).toLocaleDateString('en-GB')}
                      </div>
                    )}
                  </div>
                </div>

                <div className='d-flex my-4'>
                  <div className='btn btn-sm btn-light me-2' id='kt_user_follow_button'>
                    <KTIcon iconName='check' className='fs-3 d-none' />

                    <span className='indicator-label'>Whatsapp</span>
                    <span className='indicator-progress'>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  </div>
                  <div className='btn btn-sm btn-primary me-3'>Call</div>
                  <div className='dropdown'>
                    <button
                      className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary dropdown-toggle'
                      type='button'
                      id='dropdownMenuButton1'
                      data-bs-toggle='dropdown'
                      aria-expanded='false'
                    >
                      <i className='bi bi-three-dots fs-3'></i>
                    </button>
                    <ul className='dropdown-menu' aria-labelledby='dropdownMenuButton1'>
                      <li>
                        <div className='dropdown-item'>Info</div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* Financial Cards */}
              {data?.totalCTC > 0 && (
                <div className='row gx-10'>
                  {renderFinancialCard('Total CTC', data.totalCTC, 'Annual Compensation')}
                  {renderFinancialCard('Total Wallet', data.totalWallet)}
                </div>
              )}
            </div>
          </div>

          {/* Navigation */}
          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap cursor-pointer'>
              {renderNavItems()}
            </ul>
          </div>
        </div>
      </div>

      {property === 'overview' && <EmployeeOverview data={data} />}
      {property === 'project' && <HRPEmployeeProject />}
      {property === 'timesheet' && <HRPEmployeeTimesheetDetail />}
      {property === 'attendance' && <HRPEmployeeAttendanceDetail maxLeaves={data?.maxLeaves} />}
      {property === 'docs' && <HRPFilesPage />}
    </div>
  )
}
