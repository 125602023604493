import React, {useState, useEffect} from 'react'
import Select from 'react-select'
import Flatpickr from 'react-flatpickr'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import {
  getEmployeeByStatus,
  getLeaveTypeByDeptId,
  getLeaveTypes,
  postLeaveApply,
} from '../core/_requests'
import {KTIcon} from '../../../../_metronic/helpers'
import {useAuth} from '../../../modules/auth'
import {useNavigate} from 'react-router-dom'
import {getHRPEmployees} from '../../hrp/core/_requests'
import {RootState} from '../../../redux/store'
import {useSelector, useDispatch} from 'react-redux'
import {setEmployees} from '../../../redux/hrp/hrpEmployeesSlice'

const LeaveApply = ({isHRP = false}) => {
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const hrpEmployeesData = useSelector((state: RootState) => state.hrp.employees.employees)
  const hrpEmployeesFetch = useSelector((state: RootState) => state.hrp.employees.fetch)
  const [loading, setLoading] = useState(false)
  const [leaveData, setLeaveData] = useState({
    employeeId: '',
    jobRoleId: '',
    departmentId: '',
    leaveType: '',
    startDate: new Date(),
    endDate: new Date(),
    month: new Date(),
    reason: '',
    status: 'pending',
  })

  // const [employees, setEmployees] = useState([]);
  const [leaveList, setLeaveList] = useState([])
  const [selectedLeave, setSelectedLeave] = useState(null)
  const [employeeList, setEmployeeList] = useState<any>([])
  const [selectedEmployee, setSelectedEmployee] = useState(null)
  const [roles, setRoles] = useState('')
  const [departments, setDepartments] = useState()
  const [numberOfDays, setNumberOfDays] = useState('0')

  const getLeaveList = async () => {
    try {
      const res = await getLeaveTypes(currentUser?.organization)
      console.log('LEAVE LIST FETCHED', res)
      setLeaveList(
        res?.map((r) => ({
          value: r._id,
          label: r.leaveType,
        }))
      )
    } catch (error) {
      console.error('Error fetching Leave list:', error)
    }
  }

  const getEmployeeList = async () => {
    try {
      const res = await (isHRP
        ? getHRPEmployees(currentUser?.organization)
        : getEmployeeByStatus('filter_status=true', currentUser?.organization))
      if (isHRP) {
        dispatch(setEmployees(res))
      }
      setEmployeeList(
        res?.map((r) => ({
          value: r._id,
          label: r?.firstName,
          jobRoleId: r?.role, // Store jobRoleId along with employee data
          departmentId: r?.department?._id || '',
          departmentName: r?.department?.name, // Store departmentId along with employee data
          maxLeaves: r?.maxLeaves,
        }))
      )
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  const getLeaveListByDeptId = async () => {
    try {
      console.log(' leave list')
      const res = await getLeaveTypeByDeptId(
        currentUser?.organization,
        selectedEmployee?.departmentId
      )
      console.log('LEAVE LIST FETCHED', res)
      setLeaveList(
        res?.map((r) => ({
          value: r._id,
          label: r.leaveType,
          maxDuration: r?.maxDuration,
        }))
      )
      setSelectedLeave(null)
    } catch (error) {
      console.error('Error fetching Leave list:', error)
    }
  }

  const handleLeaveChange = (selectedOption) => {
    setSelectedLeave(selectedOption)
    setLeaveData((prevData) => ({
      ...prevData,
      endDate: new Date(
        new Date(prevData.startDate).setDate(
          new Date(prevData.startDate).getDate() + selectedOption.maxDuration
        )
      ),
    }))
  }
  const handleEmployeeChange = (selectedOption) => {
    setSelectedEmployee(selectedOption)
    console.log(selectedOption)
    const employeeId = selectedOption?.value || ''
    // const selectedEmployee = employeeList.find((emp) => emp._id === value)

    setLeaveData((prevData) => ({
      ...prevData,
      employeeId: employeeId, // Corrected the key from "employee" to "employeeId"
      jobRoleId: selectedOption?.jobRoleId || '', // Update jobRoleId based on selected employee
      departmentId: selectedOption?.departmentId || '',
    }))
    setDepartments(selectedOption?.departmentName)
    setRoles(selectedOption?.jobRoleId || '')
  }

  const handleChange = (e) => {
    const {name, value} = e.target
    setLeaveData({...leaveData, [name]: value})

    // If the changed field is employeeId, find the selected employee and update jobRoleId and departmentId
    if (name === 'employeeId') {
      const selectedEmployee = employeeList.find((emp) => emp._id === value)
      if (selectedEmployee) {
        setLeaveData({
          ...leaveData,
          jobRoleId: selectedEmployee.jobRoleId,
          departmentId: selectedEmployee.department._id,
        })
      }
    }
  }

  const handleDateChange = (date, name) => {
    setLeaveData({...leaveData, [name]: date[0]})
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    console.log(leaveData)
    if (!selectedLeave) {
      alert('Please select leave type')
      return
    }
    if (!selectedEmployee) {
      alert('Please select employee')
      return
    }
    if (!leaveData.startDate || !leaveData.endDate) {
      alert('Please select start and end date')
      return
    }
    if (!leaveData.reason) {
      alert('Please enter reason')
      return
    }
    setLoading(true)
    const reason = leaveData.reason || ''
    const data = {
      ...leaveData,
      reason: reason,
      leaveType: selectedLeave.value,
      company: currentUser?.company,
      organization: currentUser?.organization,
    }
    try {
      const res = await postLeaveApply(data)
      if (res) {
        navigate('/hrm/leaveManagement')
      }
    } catch (error) {
      console.error('Error submitting leave application:', error)
    }
  }

  useEffect(() => {
    if (isHRP) {
      if (hrpEmployeesFetch) getEmployeeList()
      else {
        const temp = hrpEmployeesData?.map((r) => ({
          value: r._id,
          label: r?.firstName,
          jobRoleId: r?.role, // Store jobRoleId along with employee data
          departmentId: r?.department?._id || '',
          departmentName: r?.department?.name, // Store departmentId along with employee data
          maxLeaves: r?.maxLeaves,
        }))
        setEmployeeList(temp)
      }
    } else getEmployeeList()
    // getLeaveList()
  }, [])

  useEffect(() => {
    if (selectedEmployee) {
      getLeaveListByDeptId()
    }
  }, [selectedEmployee])

  useEffect(() => {
    setNumberOfDays(
      String(
        leaveData.startDate && leaveData.endDate
          ? Math.ceil(
              (new Date(leaveData.endDate).getTime() - new Date(leaveData.startDate).getTime()) /
                (1000 * 3600 * 24)
            )
          : ''
      )
    )
  }, [leaveData.startDate, leaveData.endDate])

  const handleEndDateChange = (date) => {
    if (selectedLeave === null) {
      alert('Please select leave type')
      return
    }
    if (date[0] < leaveData.startDate) {
      alert('End date should be greater than start date')
      return
    }
    const temp = new Date(
      new Date(leaveData.startDate).setDate(
        new Date(leaveData.startDate).getDate() + selectedLeave?.maxDuration || 0
      )
    )
    if (date[0] > temp) {
      alert('End date should not exceed max leave duration')
      return
    }
    if (
      selectedEmployee?.maxLeaves <
      Math.ceil(
        (new Date(leaveData.endDate).getTime() - new Date(leaveData.startDate).getTime()) /
          (1000 * 3600 * 24)
      )
    ) {
      alert('You have exceeded max leaves')
      return
    }
    handleDateChange(date, 'endDate')
  }

  return (
    <div className='container-lg'>
      <div className='row justify-content-center'>
        <div className='col-lg-6'>
          <div className='card card-custom card-stretch'>
            <div className='card-body'>
              <h2 className='text-center mb-4'>APPLY LEAVE</h2>
              <form onSubmit={handleSubmit}>
                <div className='mb-3'>
                  <label htmlFor='employeeId' className='form-label required'>
                    Employee
                  </label>
                  <Select
                    name='employeeId'
                    options={employeeList}
                    onChange={handleEmployeeChange}
                    value={selectedEmployee}
                  />
                </div>
                <div className='mb-3'>
                  <label htmlFor='jobRoleId' className='form-label'>
                    Job Role
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='jobRole'
                    name='jobRoleId'
                    value={roles}
                    // onChange={handleChange}
                  />
                </div>
                <div className='mb-3'>
                  <label htmlFor='departmentId' className='form-label required'>
                    Department
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='departmentId'
                    name='departmentId'
                    value={departments}
                    // onChange={handleChange}
                  />
                </div>
                <div className='mb-3'>
                  <label htmlFor='leaveType' className='form-label required'>
                    Leave Type
                  </label>
                  <Select
                    name='leaveType'
                    options={leaveList}
                    onChange={handleLeaveChange}
                    value={selectedLeave}
                  />
                </div>
                <div className='mb-3'>
                  <label htmlFor='month' className='form-label required'>
                    Select Month
                  </label>
                  <Flatpickr
                    value={leaveData.month}
                    onChange={(date) => handleDateChange(date, 'month')}
                    className='form-control'
                    options={{
                      dateFormat: 'F Y',
                      plugins: [
                        monthSelectPlugin({
                          shorthand: true,
                          dateFormat: 'F Y',
                          altFormat: 'F Y',
                        }),
                      ],
                    }}
                  />
                </div>
                {/* read only field for number of days */}
                <div className='mb-3'>
                  <label htmlFor='numberOfDays' className='form-label'>
                    Number of Days
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='numberOfDays'
                    name='numberOfDays'
                    value={numberOfDays}
                    readOnly
                  />
                </div>
                <div className='mb-3'>
                  <label htmlFor='startDate' className='form-label required'>
                    Start Date
                  </label>
                  <Flatpickr
                    value={leaveData.startDate}
                    onChange={(date) => handleDateChange(date, 'startDate')}
                    className='form-control'
                    options={{
                      dateFormat: 'd-m-Y',
                    }}
                  />
                </div>
                <div className='mb-3'>
                  <label htmlFor='endDate' className='form-label required'>
                    End Date
                  </label>
                  <Flatpickr
                    value={leaveData.endDate}
                    onChange={handleEndDateChange}
                    className='form-control'
                    options={{
                      dateFormat: 'd-m-Y',
                    }}
                  />
                </div>
                <div className='mb-3'>
                  <label htmlFor='reason' className='form-label required'>
                    Reason
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='reason'
                    name='reason'
                    value={leaveData.reason}
                    onChange={handleChange}
                  />
                </div>
                <div className='text-center'>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    <span className='indicator-label'>
                      Submit <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LeaveApply
