import React, {useRef, useEffect, useState} from 'react'
import {Col, Row, Card} from 'react-bootstrap'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import Swal from 'sweetalert2'
import listPlugin from '@fullcalendar/list'
import multiMonthPlugin from '@fullcalendar/multimonth'
import {KTIcon, KTSVG} from '../../../../_metronic/helpers'
import {useAuth} from '../../../modules/auth'
import {
  addevents,
  deleteEvent,
  editEvent,
  getAllevents,
  getContacts,
  getLeads,
} from '../core/_requests'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Select from 'react-select'
import toast from 'react-hot-toast'

const CRMTasksNMeetings = () => {
  const calendarComponentRef = useRef(null)
  const {currentUser} = useAuth()
  const [permissions, setPermissions] = useState<any>({})
  const [calendarEvents, setCalendarEvents] = useState([])
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [reminder, setReminder] = useState('5')
  const [selectedOption, setSelectedOption] = useState('')
  const [color, setColor] = useState('')
  const [agentIds, setAgentIds] = useState<string[]>([])
  const editTodo = useRef(null)
  const [editTodoId, setEditTodoId] = useState('')
  const [property, setProperty] = useState('task')
  const [leadsList, setLeadsList] = useState<any>([])
  const [contactsList, setContactsList] = useState<any>([])
  const [selectedLead, setSelectedLead] = useState(null)
  const [selectedContact, setSelectedContact] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [calendarEventModal, setCalendarEventModal] = useState<any>({})

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser.permissions)
    }
  }, [currentUser])

  const handleShowModal = (e) => {
    setShowModal(true)
    setCalendarEventModal(e.event)
  }

  const handleCloseModal = () => {
    setShowModal(false)
    setCalendarEventModal({})
  }

  const handleDeleteEvent = () => {
    handleCloseModal()
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
    }).then((res) => {
      if (res.value) {
        const resp = handleDeleteTodo(calendarEventModal?._def?.extendedProps?._id)
        if (resp) {
          calendarEventModal?.remove()
          Swal.fire('Deleted!', 'Your Event has been deleted.', 'success')
        }
      }
    })
  }

  const handleEditEvent = () => {
    handleCloseModal()
    setTitle(calendarEventModal?.title)
    setSelectedDate(calendarEventModal?.start)
    setSelectedOption(calendarEventModal?._def?.extendedProps?.priority)
    setColor(calendarEventModal?._def?.extendedProps?.color)
    setProperty(calendarEventModal?._def?.extendedProps?.type)
    setSelectedLead({
      label: calendarEventModal?._def?.extendedProps?.leads?.displayName,
      value: calendarEventModal?._def?.extendedProps?.leads?.displayName,
    })
    setSelectedContact({
      label: calendarEventModal?._def?.extendedProps?.contacts?.displayName,
      value: calendarEventModal?._def?.extendedProps?.contacts?.displayName,
    })
    setAgentIds(calendarEventModal?._def?.extendedProps?.agent)
    setEditTodoId(calendarEventModal?._def?.extendedProps?._id)
    setDescription(calendarEventModal?._def?.extendedProps?.description)
    setReminder(calendarEventModal?._def?.extendedProps?.reminder)
    Swal.fire({
      title: 'Edit Event ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Edit it!',
      cancelButtonText: 'No, cancel!',
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
    }).then((res) => {
      if (res.value) {
        editTodo.current.click()
      }
    })
  }

  const getClientList = async () => {
    try {
      const res = await getLeads(currentUser?.organization)
      setLeadsList(
        res.map((r) => ({
          value: r._id,
          label: r.displayName,
        }))
      )
      const res2 = await getContacts(currentUser?.organization)
      setContactsList(
        res2.map((r) => ({
          value: r._id,
          label: r.displayName,
        }))
      )
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  useEffect(() => {
    getClientList()
  }, [currentUser.organization])

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value)
  }

  const handleDateChange = (date) => {
    setSelectedDate(date)
  }

  useEffect(() => {
    if (selectedOption === 'Important') {
      setColor('#dc3545')
    } else if (selectedOption === 'Medium') {
      setColor('#ffc107')
    } else {
      setColor('#28a745')
    }
  }, [selectedOption])

  const getEvent = async () => {
    try {
      const res = await getAllevents(currentUser?.organization)
      setCalendarEvents(res)
    } catch (error) {
      console.error('Error fetching todo list:', error)
    }
  }

  useEffect(() => {
    getEvent()
  }, [permissions])

  const handleTodoSubmit = async () => {
    const todo = {
      title,
      start: selectedDate,
      end: selectedDate,
      type: property,
      leads: selectedLead ? selectedLead.value : null,
      contacts: selectedContact ? selectedContact.value : null,
      priority: selectedOption,
      color: color,
      agentIds,
      description,
      reminder,
      company: currentUser?.company,
      organization: currentUser?.organization,
    }

    await addevents(todo, currentUser?._id).then((res) => {
      toast.success(property === 'task' ? 'Task added successfully' : 'Meeting added successfully')
      const updateEvents = [...calendarEvents, res]
      setTitle('')
      setSelectedOption('')
      setColor('')
      setSelectedDate(new Date())
      setAgentIds([])
      setCalendarEvents(updateEvents)
      getEvent()
      setProperty('')
      setDescription('')
      setReminder('')
      setSelectedLead(null)
      setSelectedContact(null)

      const closeModalButton = document.getElementById('closeModalButton')
      if (closeModalButton) {
        closeModalButton.click()
      }
    })
  }

  const handleTodoEdit = async () => {
    const todo = {
      title,
      start: selectedDate,
      end: selectedDate,
      type: property,
      leads: selectedLead ? selectedLead.value : null,
      contacts: selectedContact ? selectedContact.value : null,
      priority: selectedOption,
      color: color,
      agentIds,
      description,
      reminder,
      company: currentUser?.company,
      organization: currentUser?.organization,
    }

    await editEvent(editTodoId, todo).then((res) => {
      toast.success(
        property === 'task' ? 'Task updated successfully' : 'Meeting updated successfully'
      )
      const updateEvents = [...calendarEvents, res]
      setTitle('')
      setSelectedOption('')
      setColor('')
      setSelectedDate(new Date())
      setAgentIds([])
      setCalendarEvents(updateEvents)
      setProperty('')
      setSelectedLead(null)
      setSelectedContact(null)
      setDescription('')
      setReminder('')
      getEvent()

      const closeModalButton = document.getElementById('closeModalButton2')
      if (closeModalButton) {
        closeModalButton.click()
      }
    })
  }

  const handleDeleteTodo = async (todoId) => {
    const res = await deleteEvent(todoId)
    return res
  }

  const handleDateSelect = (selectInfo) => {
    const {start, end} = selectInfo
  }

  const handleDatePickerClick = (e) => {
    e.stopPropagation()
  }

  return (
    <div className='animated fadeIn demo-app '>
      {/* button for adding task */}
      <div className='d-flex justify-content-end mb-3'>
        {(permissions?.crmtasks?.create || currentUser?.profileType === 'superadmin') && (
          <button
            type='button'
            className='btn btn-primary'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_1'
          >
            <KTIcon iconName='plus' className='fs-2' />
            Add Task
          </button>
        )}
        <button
          type='button'
          data-bs-toggle='modal'
          data-bs-target='#kt_modal_1234'
          ref={editTodo}
        ></button>
      </div>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <div className='demo-app-calendar' id='mycalendartest'>
                <FullCalendar
                  eventTimeFormat={{
                    hour: 'numeric',
                    minute: '2-digit',
                    meridiem: 'short',
                  }}
                  plugins={[
                    dayGridPlugin,
                    timeGridPlugin,
                    interactionPlugin,
                    listPlugin,
                    multiMonthPlugin,
                  ]}
                  initialView={'dayGridMonth'}
                  headerToolbar={{
                    start: 'today prev,next',
                    center: 'title',
                    end: 'timeGridDay,timeGridWeek,dayGridMonth,multiMonthYear,listYear',
                  }}
                  selectable={true}
                  select={handleDateSelect}
                  rerenderDelay={10}
                  eventDurationEditable={false}
                  weekends={true}
                  ref={calendarComponentRef}
                  events={calendarEvents}
                  eventClick={(e) => {
                    handleShowModal(e)
                  }}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {showModal && (
        <div className='modal fade show' tabIndex={-1} role='dialog' style={{display: 'block'}}>
          <div className='modal-dialog' role='document'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title capitalize'>
                  {calendarEventModal?._def?.extendedProps?.client !== null ? 'Meeting' : 'Task'}
                </h5>
                <button type='button' className='close' onClick={handleCloseModal}>
                  <span>&times;</span>
                </button>
              </div>
              <div className='modal-body'>
                <div className='table-responsive'>
                  <table className='table'>
                    <tbody>
                      <tr>
                        <td>Title</td>
                        <td>
                          <strong className='capitalize'>{calendarEventModal?.title}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>Start Time</td>
                        <td>
                          <strong>{calendarEventModal?.start.toString()}</strong>
                        </td>
                      </tr>
                      {calendarEventModal?._def?.extendedProps?.description !== '' && (
                        <tr>
                          <td>Description</td>
                          <td>
                            <strong>{calendarEventModal?._def?.extendedProps?.description}</strong>
                          </td>
                        </tr>
                      )}
                      {calendarEventModal?._def?.extendedProps?.leads !== null && (
                        <tr>
                          <td>Lead </td>
                          <td>
                            <strong>
                              {calendarEventModal?._def?.extendedProps?.leads?.displayName}
                            </strong>
                          </td>
                        </tr>
                      )}
                      {calendarEventModal?._def?.extendedProps?.contacts !== null && (
                        <tr>
                          <td>Contact </td>
                          <td>
                            <strong>
                              {calendarEventModal?._def?.extendedProps?.contacts?.displayName}
                            </strong>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className='modal-footer'>
                <button type='button' className='btn btn-secondary' onClick={handleCloseModal}>
                  Close
                </button>
                {(permissions?.crmtasks?.delete || currentUser?.profileType === 'superadmin') && (
                  <button type='button' className='btn btn-danger' onClick={handleDeleteEvent}>
                    Remove Event
                  </button>
                )}
                {(permissions?.crmtasks?.update || currentUser?.profileType === 'superadmin') && (
                  <button type='button' className='btn btn-warning' onClick={handleEditEvent}>
                    Edit Event
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <div className='d-flex h-55px'>
                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap cursor-pointer'>
                  <li className='nav-item'>
                    <div
                      className={`nav-link text-active-primary me-6  
                  ${property === 'task' ? ' active' : ''}`}
                      onClick={() => setProperty('task')}
                    >
                      Task
                    </div>
                  </li>
                  <li className='nav-item'>
                    <div
                      className={`nav-link text-active-primary me-6  
                  ${property === 'meeting' ? ' active' : ''}`}
                      onClick={() => setProperty('meeting')}
                    >
                      Meeting
                    </div>
                  </li>
                </ul>
              </div>

              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='mb-10'>
                <label className='form-label'>Title</label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Enter title'
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>

              <div className='mb-10 d-flex flex-column'>
                <label className='form-label'>Date</label>
                <DatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                  dateFormat='MMMM d, yyyy'
                  className='form-control'
                  onClick={handleDatePickerClick}
                />
              </div>

              <div className='mb-10 d-flex flex-column'>
                <label className='form-label'>Time</label>
                <DatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  dateFormat='h:mm aa'
                  className='form-control'
                  onClick={handleDatePickerClick}
                />
              </div>

              <div className='mb-10 d-flex flex-column'>
                <label className='form-label'>Description</label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Enter Description (Optional)'
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>

              {property === 'meeting' && (
                <>
                  <div className='form-group mt-10 mb-10 col-md-12'>
                    <label className='form-label'>Select Lead</label>
                    <Select
                      name='leads'
                      aria-label='Select Lead'
                      placeholder='Select Lead'
                      options={leadsList}
                      isSearchable={true}
                      onChange={(e) => {
                        setSelectedLead(e)
                        setSelectedContact(null)
                      }}
                      value={selectedLead}
                    />
                  </div>

                  <div className='form-group mt-10 mb-10 col-md-12'>
                    <label className='form-label'>Select Contact</label>
                    <Select
                      name='contacts'
                      aria-label='Select Contact'
                      placeholder='Select Contact'
                      options={contactsList}
                      isSearchable={true}
                      onChange={(e) => {
                        setSelectedContact(e)
                        setSelectedLead(null)
                      }}
                      value={selectedContact}
                    />
                  </div>
                </>
              )}

              <div className='container mt-3'>
                <label htmlFor='exampleSelect' className='form-label'>
                  Select Priority
                </label>
                <select
                  className='form-select'
                  id='exampleSelect'
                  value={selectedOption}
                  onChange={handleSelectChange}
                >
                  <option value=''>Select Priority</option>
                  <option value='Important'>Important</option>
                  <option value='Medium'>Medium</option>
                  <option value='Flexible'>Flexible</option>
                </select>
              </div>
            </div>

            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                id='closeModalButton'
              >
                Close
              </button>
              <button type='button' className='btn btn-primary' onClick={handleTodoSubmit}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='modal fade' tabIndex={-1} id='kt_modal_1234'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Edit</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='mb-10'>
                <label className='form-label'>Title</label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Enter title'
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>

              <div className='mb-10 d-flex flex-column'>
                <label className='form-label'>Date</label>
                <DatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                  dateFormat='MMMM d, yyyy'
                  className='form-control'
                  onClick={handleDatePickerClick}
                />
              </div>

              <div className='mb-10 d-flex flex-column'>
                <label className='form-label'>Time</label>
                <DatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  dateFormat='h:mm aa'
                  className='form-control'
                  onClick={handleDatePickerClick}
                />
              </div>

              <div className='mb-10 d-flex flex-column'>
                <label className='form-label'>Description</label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Enter Description (Optional)'
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>

              <div className='container mt-3'>
                <label htmlFor='exampleSelect' className='form-label'>
                  Select Priority
                </label>
                <select
                  className='form-select'
                  id='exampleSelect'
                  value={selectedOption}
                  onChange={handleSelectChange}
                >
                  <option value=''>Select Priority</option>
                  <option value='Important'>Important</option>
                  <option value='Medium'>Medium</option>
                  <option value='Flexible'>Flexible</option>
                </select>
              </div>

              {property === 'meeting' && (
                <>
                  <div className='form-group mt-10 mb-10 col-md-12'>
                    <label className='form-label'>Select Lead</label>
                    <Select
                      name='leads'
                      aria-label='Select Lead'
                      placeholder='Select Lead'
                      options={leadsList}
                      isSearchable={true}
                      onChange={(e) => {
                        setSelectedLead(e)
                        setSelectedContact(null)
                      }}
                      value={selectedLead}
                    />
                  </div>
                  <div className='form-group mt-10 mb-10 col-md-12'>
                    <label className='form-label'>Select Contact</label>
                    <Select
                      name='contacts'
                      aria-label='Select Contact'
                      placeholder='Select Contact'
                      options={contactsList}
                      isSearchable={true}
                      onChange={(e) => {
                        setSelectedContact(e)
                        setSelectedLead(null)
                      }}
                      value={selectedContact}
                    />
                  </div>
                </>
              )}
            </div>

            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                id='closeModalButton2'
              >
                Close
              </button>
              <button type='button' className='btn btn-primary' onClick={handleTodoEdit}>
                Edit changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CRMTasksNMeetings
