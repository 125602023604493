import React, {useEffect, useState} from 'react'
import {useAuth} from '../../../modules/auth'
import {KTIcon} from '../../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {getWorkOrders} from '../core/_requests'
import {useSelector, useDispatch} from 'react-redux'
import {setWorkOrderData} from '../../../redux/maintenance/service/workOrder/workOrderSlice'

const WorkOrder = () => {
  const {currentUser} = useAuth()
  const [permissions, setPermissions] = useState<any>()
  // const [tableData, setTableData] = useState<any>()

  const dispatch = useDispatch()
  const tableData = useSelector((state: any) => state.maintenance.workOrder.workOrderData)

  useEffect(() => {
    if (currentUser) {
      setPermissions(currentUser.permissions)
    }
  }, [currentUser])

  const fetchData = async () => {
    const res = await getWorkOrders(currentUser?.organization)
    console.log(res)
    // setTableData(res)
    dispatch(setWorkOrderData(res))
  }

  useEffect(() => {
    fetchData()
  }, [])

  const formatCreatedAt = (date: string) => {
    // format date as 27/03/2024
    const d = new Date(date)
    return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
  }

  return (
    <div className='card'>
      <div className='card-header d-flex align-items-center justify-between my-3'>
        <h3 className='card-title'>Work Order</h3>
        {(permissions?.workorder?.create || currentUser?.profileType === 'superadmin') && (
          <Link to='add'>
            <div className='btn btn-primary'>
              <KTIcon iconName='plus' className='me-2' />
              Create Work Order
            </div>
          </Link>
        )}
      </div>
      <div className='card-body'>
        <div className='table-responsive'>
          <table className='table table-striped table-row-bordered gy-7 gs-7'>
            <thead>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                <th className='min-w-200px'>Item</th>
                <th className='min-w-150px'>Number</th>
                <th className='min-w-150px'>Status</th>
                <th className='min-w-200px'>Repair Priority Class</th>
                <th className='min-w-150px'>Resolved Issues</th>
                <th className='min-w-150px'>Issue Date</th>
                <th className='min-w-200px'>Scheduled Start Date</th>
                <th className='min-w-200px'>Expected Completion Date</th>
                <th className='min-w-150px'>Assigned To</th>
                <th className='min-w-150px'>Total Cost</th>
                {/* <th>Completion Date</th> */}
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {tableData?.map((item: any) => (
                <tr key={item._id}>
                  <td>
                    <Link to={`${item._id}`}>{item.item?.productName}</Link>
                  </td>
                  <td>#{item.id}</td>
                  <td>{item.status}</td>
                  <td>{item.priority}</td>
                  <td>
                    <div className='d-flex flex-column gap-1'>
                      {item?.issues?.map((currItem, idx) => {
                        return <span key={idx}>#{currItem.issueId}</span>
                      })}
                    </div>
                  </td>
                  <td>{formatCreatedAt(item.issueDate)}</td>
                  <td>{formatCreatedAt(item.scheduledStartDate)}</td>
                  <td>{formatCreatedAt(item.expectedCompletionDate)}</td>
                  <td>
                    {item?.assignedTo?.firstName} {item?.assignedTo?.lastName}
                  </td>
                  <td>
                    {item?.vendor?.currency || 'SAR'} {item?.total}
                  </td>
                  <td>
                    {(permissions?.workorder?.update ||
                      currentUser?.profileType === 'superadmin') && (
                      <Link to={`/maintenance/work-order/edit/${item._id}`}>
                        <div className='btn btn-sm btn-light btn-active-light-primary'>Edit</div>
                      </Link>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default WorkOrder
