import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface DateState {
  startDate: string
  endDate: string
}

interface RFPState {
  rfpData: any[]
  loading: boolean
  totalPages: number
  dateState: DateState
  error: string | null
  fetch: boolean
}

const initialState: RFPState = {
  rfpData: [],
  dateState: {
    startDate: null,
    endDate: null,
  },
  totalPages: 1,
  loading: true,
  error: null,
  fetch: true,
}

const rfpSlice = createSlice({
  name: 'rfp',
  initialState,
  reducers: {
    setRfpDataList(state, action) {
      state.rfpData = action.payload.rfp
      state.totalPages = action.payload.totalPages
      state.loading = false
      state.error = null
      state.fetch = false
    },
    setDateRange(state, action: PayloadAction<DateState>) {
      state.dateState = action.payload
    },
    setLoading(state) {
      state.loading = true
      state.error = null
    },
    setError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    setRFPDataFetch(state) {
      state.fetch = true
    },
  },
})

export const {setRfpDataList, setLoading, setError, setRFPDataFetch, setDateRange} =
  rfpSlice.actions
export default rfpSlice.reducer
