import axios from 'axios'
const API_URL = process.env.REACT_APP_THEME_API_URL

const HRP_PROJECT_URL = `${API_URL}/hrp/projects`
const HRP_DEPARTMENT_URL = `${API_URL}/hrp/department`
const HRP_EMPLOYEE_GROUP_URL = `${API_URL}/hrp/employeegroup`
const HRP_EMPLOYEE_CAMP_URL = `${API_URL}/hrp/employeecamp`
const POST_DOCS_URL = `${API_URL}/uploaddocs/hrpemployee/`
const HRP_REPORTS_URL = `${API_URL}/hrp/employeeReport`
const HRP_REPORTS_SUBMISSION_URL = `${API_URL}/hrp/employeeReportSubmission`

export const getHRPProjects = async (orgid: string) => {
  try {
    const response = await axios.get(`${HRP_PROJECT_URL}/${orgid}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

export const postHRPProject = async (data: any) => {
  try {
    const response = await axios.post(`${HRP_PROJECT_URL}/create`, data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

export const getHRPProjectsByEmployee = async (empid: string, orgid: string) => {
  try {
    const response = await axios.get(`${HRP_PROJECT_URL}/employees/${empid}/${orgid}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

export const getHRPProjectsTimesheetByEmployee = async (empid: string, orgid: string) => {
  try {
    const response = await axios.get(`${HRP_PROJECT_URL}/employees/timesheet/${empid}/${orgid}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

export const postHRPDepartment = async (data: any) => {
  try {
    const response = await axios.post(`${HRP_DEPARTMENT_URL}/create`, data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

export const getHRPDepartments = async (orgid: string) => {
  try {
    const response = await axios.get(`${HRP_DEPARTMENT_URL}/${orgid}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

export const getHRPEmployees = async (orgid: string) => {
  try {
    const response = await axios.get(`${HRP_DEPARTMENT_URL}/employees/${orgid}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

export const getHRPEmployeeDetail = async (id: string) => {
  try {
    const response = await axios.get(`${HRP_DEPARTMENT_URL}/employee/detail/${id}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

export const postHRPEmployeeGroup = async (data: any) => {
  try {
    const response = await axios.post(`${HRP_EMPLOYEE_GROUP_URL}/create`, data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

export const getHRPEmployeeGroups = async (orgid: string) => {
  try {
    const response = await axios.get(`${HRP_EMPLOYEE_GROUP_URL}/${orgid}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

export const putHRPEmployeeGroup = async (data: any, id: string) => {
  try {
    const response = await axios.put(`${HRP_EMPLOYEE_GROUP_URL}/update/${id}`, data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

export const getEmployeesByEmployeeGroup = async (id: string) => {
  try {
    const response = await axios.get(`${HRP_EMPLOYEE_GROUP_URL}/getemployeegroupbyid/${id}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

export const getHRPEmployeeCamps = async (orgid: string) => {
  try {
    const response = await axios.get(`${HRP_EMPLOYEE_CAMP_URL}/${orgid}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

export const postHRPEmployeeCamp = async (data: any) => {
  try {
    const response = await axios.post(`${HRP_EMPLOYEE_CAMP_URL}/create`, data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

export const putHRPEmployeeCamp = async (data: any, id: string) => {
  try {
    const response = await axios.put(`${HRP_EMPLOYEE_CAMP_URL}/update/${id}`, data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

export const getEmployeesByEmployeeCamp = async (id: string) => {
  try {
    const response = await axios.get(`${HRP_EMPLOYEE_CAMP_URL}/getemployeecampbyid/${id}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

export async function getHRPEmployeesWebAttendance(orgid) {
  try {
    const response = await axios.get(`${HRP_DEPARTMENT_URL}/webattendance/${orgid}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export async function getHRPEmployeesIdOfWebAttendanceByOrgId(orgid) {
  try {
    const response = await axios.get(`${HRP_DEPARTMENT_URL}/attendance/employee/${orgid}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getHRPLeavesWithMonth = async (orgid) => {
  try {
    const response = await axios.get(`${HRP_DEPARTMENT_URL}/getleavesbymonth/${orgid}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getHRPLeavesByMonth = async (month, orgid) => {
  try {
    const response = await axios.get(`${HRP_DEPARTMENT_URL}/getleavesbymonth/${orgid}/${month}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export async function getHRPFiles(docid) {
  try {
    const response = await axios.get(`${HRP_DEPARTMENT_URL}/files/${docid}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export const deleteHRPFile = async (id, docid) => {
  try {
    const response = await axios.delete(`${HRP_DEPARTMENT_URL}/files/${id}/${docid}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export const editHRPFile = async (data, id, docid) => {
  try {
    const response = await axios.put(`${HRP_DEPARTMENT_URL}/files/${id}/${docid}`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function uploadHRPFile(formData, id) {
  try {
    const response = await axios.post(`${POST_DOCS_URL}${id}`, formData)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function getEmployeeReports(orgid: string) {
  try {
    const response = await axios.get(`${HRP_REPORTS_URL}/allemployeeReport/${orgid}`)
    return response.data
  } catch (error) {
    console.error('Add Client error:', error)
  }
}

export async function postHRPReport(data) {
  try {
    const response = await axios.post(`${HRP_REPORTS_URL}`, data)
    return response.data
  } catch (error) {
    console.error('Error submitting data:', error)
  }
}

export async function getEmployeeReportSubmissionCount(orgid) {
  try {
    const response = await axios.get(`${HRP_REPORTS_URL}/submission-count/${orgid}`)
    return response.data
  } catch (error) {
    console.error('Add client error:', error)
  }
}

export const getEmployeeByEmail = async (email: string) => {
  try {
    const response = await axios.get(`${API_URL}/employee/employeeByEmail/${email}`)
    return response.data
  } catch (error) {
    if (error.response.status === 404) {
      alert('Employee with this email does not exist')
    } else {
      console.error('Axios error:', error)
    }
  }
}

export async function getEmployeeReportbyDeptandRole(deptId: string, role: string) {
  try {
    const response = await axios.get(`${HRP_REPORTS_URL}/department/${deptId}/role/${role}/`)
    return response.data
  } catch (error) {
    console.error('Add client error:', error)
  }
}

export async function getEmployeeReportbyId(Id: string) {
  try {
    const response = await axios.get(`${HRP_REPORTS_URL}/employeeReportById/${Id}`)
    return response.data
  } catch (error) {
    console.error('Add client error:', error)
  }
}

export async function postEmployeeReportAnswersByFormId(formId: string, values: any) {
  try {
    const response = await axios.post(`${HRP_REPORTS_SUBMISSION_URL}/submit/${formId}`, values)
    return response.data
  } catch (error) {
    console.error('Error submitting Answers', error)
  }
}

export async function getEmployeeReportSubmissionByReportId(reportId: string) {
  try {
    const response = await axios.get(
      `${HRP_REPORTS_SUBMISSION_URL}/employee-report/${reportId}/submissions`
    )
    return response.data
  } catch (error) {
    console.error('Add client error:', error)
    throw error
  }
}

export async function getEmployeeReportSubmissionDetailsById(Id) {
  try {
    const response = await axios.get(`${HRP_REPORTS_SUBMISSION_URL}/submission/${Id}`)
    return response.data
  } catch (error) {
    console.error('Add client error:', error)
    throw error
  }
}
