import {useEffect, useRef, useState} from 'react'
import * as bootstrap from 'bootstrap'
import QuoteSecond from '../Components/Quote/QuoteSecond'
import QuoteSecondArabic from '../Components/Quote/QuoteSecondArabic'
import QuoteSecondMultiLang from '../Components/Quote/QuoteSecondMultiLang'
import {getQuotebyids, updateQuoteStatus} from '../Core/_requests'
import {useNavigate, useParams} from 'react-router-dom'
import toast from 'react-hot-toast'
import {getCRMQuotebyids, updateCRMQuoteStatus} from '../../crm/core/_requests'

function QuoteSelection({isCRM = false}: {isCRM?: boolean}) {
  const id = useParams().id
  const navigate = useNavigate()
  const PF: any = 'https://qf-assets.s3.me-central-1.amazonaws.com/'
  const [data, setdata] = useState([])
  const [activeComponent, setActiveComponent] = useState('QuoteWithMinFooter')
  const [isLoading, setIsLoading] = useState(false)
  const [image, setImage] = useState(null)
  const [modalName, setModalName] = useState('')
  const [color, setColor] = useState('#ffffff')
  const inputRef = useRef(null)

  useEffect(() => {
    const getquotedetails = async () => {
      if (isCRM) {
        await getCRMQuotebyids(id).then((res) => {
          setdata(res)
        })
      } else {
        await getQuotebyids(id).then((res) => {
          setdata(res)
        })
      }
    }

    getquotedetails()
  }, [id])

  const handleStatusChange = async (status) => {
    const reqData = {
      acceptStatus: status,
    }
    if (isCRM) {
      await updateCRMQuoteStatus(id, reqData).then(() => {
        toast.success('Status Updated Successfully')
        const newdata = [...data]
        newdata[0].acceptStatus = status
        setdata(newdata)
      })
    } else {
      await updateQuoteStatus(id, reqData).then(() => {
        toast.success('Status Updated Successfully')
        const newdata = [...data]
        newdata[0].acceptStatus = status
        setdata(newdata)
      })
    }
  }

  const switchToComponent = (component) => {
    console.log('component', component)
    if (activeComponent !== component) {
      setIsLoading(true) // Set loading state
      setTimeout(() => {
        setActiveComponent(component)
        setIsLoading(false)
        openModal(component) // Turn off loading state
      }, 1000) // Simulating a delay for demonstration purposes
    }
  }

  const openModal = (modalId) => {
    const modal = document.getElementById(modalId)
    if (modal) {
      const modalInstance = new bootstrap.Modal(modal)
      modalInstance.show()
    }
  }
  const handleModalChange = (id) => {
    setTimeout(() => {
      setModalName(id) // Passing handleModalClose as a callback
    }, 3000)
  }

  const handleModalClose = (id) => {
    const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0]
    const modal = document.getElementById(id)

    // Check if modalBackdrop exists and is not undefined
    if (modalBackdrop && typeof modalBackdrop !== 'undefined') {
      modalBackdrop.remove() // Remove backdrop manually
    }

    // Check if modal exists and is not undefined
    if (modal && typeof modal !== 'undefined') {
      modal.classList.remove('show')
      setTimeout(() => {
        modal.style.display = 'none' // Hide modal after transition
      }, 300)
    }
  }
  const InitialModalData = [
    // {
    //     id: 'stacked2',
    //     name: 'Invoice First',
    //     image: '/media/modalImagesInvoice/invoiceFirst/invoiceFirst.png',
    //     activeComponentName: 'Invoice',
    //     Footer: 'Themes 1',
    // },
    {
      id: 'stacked3',
      name: 'Quote',
      image: '/media/modalImagesInvoice/invoiceSecond.png',
      activeComponentName: 'InvoiceSecond',
      Footer: 'Themes 2',
    },
    {
      id: 'stacked4',
      name: 'Changed Footer',
      image: '/media/modalImagesInvoice/invoiceSecond.png',
      activeComponentName: 'quoteFooter',
      Footer: 'Themes 3',
    },
    // {
    //     id: 'stacked4',
    //     name: 'Invoice Third',
    //     image: '/media/modalImagesInvoice/invoiceThird.png',
    //     activeComponentName: 'InvoiceThird',
    //     Footer: 'Themes 3',
    // },
  ]
  const SecondaryModalData = [
    // {
    //     id: 'stacked2',
    //     data: [
    //         {
    //             activeComponentName: "Invoice",
    //             image: '/media/modalImagesInvoice/invoiceFirst/invoiceFirst.png',
    //         },
    //         {
    //             activeComponentName: "InvoiceArabic",
    //             image: '/media/modalImagesInvoice/invoiceFirst/invoiceArabic.png',

    //         },
    //         {
    //             activeComponentName: "InvoiceMultiLang",
    //             image: '/media/modalImagesInvoice/invoiceFirst/invoiceMultiLang.png',

    //         },
    //     ]
    // },
    {
      id: 'stacked3',
      data: [
        {
          activeComponentName: 'QuoteSecond',
          image: '/media/modalImagesInvoice/invoiceSecond.png',
        },
        {
          activeComponentName: 'QuoteSecondArabic',
          image: '/media/modalImagesInvoice/invoiceFirst/invoiceArabic.png',
        },
        {
          activeComponentName: 'QuoteSecondMultiLang',
          image: '/media/modalImagesInvoice/invoiceFirst/invoiceMultiLang.png',
        },
      ],
    },
    {
      id: 'stacked4',
      data: [
        {
          activeComponentName: 'QuoteWithMinFooter',
          image: '/media/modalImagesInvoice/invoiceThird.png',
        },
      ],
    },
  ]

  const closeModal = () => {
    const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0]
    const modal = document.getElementById('kt_modal_stacked_1')

    // Check if modalBackdrop exists and is not undefined
    if (modalBackdrop && typeof modalBackdrop !== 'undefined') {
      modalBackdrop.remove() // Remove backdrop manually
    }

    // Check if modal exists and is not undefined
    if (modal && typeof modal !== 'undefined') {
      modal.classList.remove('show')
      setTimeout(() => {
        modal.style.display = 'none' // Hide modal after transition
      }, 300)
    }
  }
  /*  
    
    const switchToInvoice = () => {
        if (activeComponent !== 'Invoice') {
            setIsLoading(true); // Set loading state
            setTimeout(() => {
                setActiveComponent('Invoice');
                setIsLoading(false); // Turn off loading state
                // closeModal();
            }, 1000); // Simulating a delay for demonstration purposes
        }
    };
    const switchToInvoiceSecond = () => {
        if (activeComponent !== 'InvoiceSecond') {
            setIsLoading(true); // Set loading state
            setTimeout(() => {
                setActiveComponent('InvoiceSecond');
                setIsLoading(false); // Turn off loading state
                // closeModal();
            }, 1000); // Simulating a delay for demonstration purposes
        }
    };
    const switchToInvoiceArabic = () => {
        if (activeComponent !== 'InvoiceArabic') {
            setIsLoading(true); // Set loading state
            setTimeout(() => {
                setActiveComponent('InvoiceArabic');
                setIsLoading(false); // Turn off loading state
                // closeModal();
            }, 1000); // Simulating a delay for demonstration purposes
        }
    };
    const switchToInvoiceMultiLang = () => {
        if (activeComponent !== 'InvoiceMultiLang') {
            setIsLoading(true); // Set loading state
            setTimeout(() => {
                setActiveComponent('InvoiceMultiLang');
                setIsLoading(false); // Turn off loading state
                // closeModal();
            }, 1000); // Simulating a delay for demonstration purposes
        }
    };
    const switchToInvoiceThird = () => {
        if (activeComponent !== 'InvoiceThird') {
            setIsLoading(true); // Set loading state
            setTimeout(() => {
                setActiveComponent('InvoiceThird');
                setIsLoading(false); // Turn off loading state
                // closeModal();
            }, 1000); // Simulating a delay for demonstration purposes
        }
    };
    const closeModal = () => {
        const modal = document.getElementById('kt_modal_create_app');
        const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
        modalBackdrop.remove(); // Remove backdrop manually
        modal.classList.remove('show');
        setTimeout(() => {
            modal.style.display = 'none'; // Hide modal after transition
        }, 300);
    };
    */

  const handlePrint = () => {
    window.print()
  }
  const handleImageClick = () => {
    inputRef.current.click()
  }
  const handleUpload = (event) => {
    const file = event.target.files[0]
    setImage(file)
  }

  // const sendEmail = async (id) => {
  //   setEmailLoading(true)
  //   try {
  //     const response = await sendQuotationMailByID(id)
  //     setEmailLoading(false)
  //     toast.success('Email sent successfully')
  //   } catch (error) {
  //     console.error('Axios error:', error)
  //     setEmailLoading(false)
  //     throw error
  //   }
  // }
  const toggleEmailComposer = () => {
    navigate('email-composer')
  }

  return (
    <>
      <div id='kt_app_toolbar_container' className='app-container container-xxl d-flex flex-stack '>
        {/*begin::Page title*/}
        <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3 '>
          {/*begin::Title*/}
          <h1 className='page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0'>
            Quote
          </h1>
          {/*end::Title*/}
          {/*begin::Breadcrumb*/}
          <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
            {/*begin::Item*/}
            <li className='breadcrumb-item text-muted'>
              <a href='/sales/quote' className='text-muted text-hover-primary'>
                Sales{' '}
              </a>
            </li>
            {/*end::Item*/}
            {/*begin::Item*/}
            <li className='breadcrumb-item'>
              <span className='bullet bg-gray-500 w-5px h-2px' />
            </li>
            {/*end::Item*/}
            {/*begin::Item*/}
            <li className='breadcrumb-item text-muted '>Quote Manager</li>
            {/*end::Item*/}
            {/*begin::Item*/}
            <li className='breadcrumb-item'>
              <span className='bullet bg-gray-500 w-5px h-2px' />
            </li>
            {/*end::Item*/}
            {/*begin::Item*/}
            <li className='breadcrumb-item text-muted'>Quotes </li>
            {/*end::Item*/}
          </ul>
          {/*end::Breadcrumb*/}
        </div>
        <div className='d-flex gap-5'>
          <div className=' mb-8 d-flex gap-3'>
            {data[0]?.status === 'pending' && data[0]?.approval === 'accepted' && (
              <button
                type='button'
                className='btn btn-sm btn-info'
                onClick={() => navigate(`/sales/salesorders/quote/${data[0]?._id}`)}
              >
                Create Sales Order
              </button>
            )}

            {data[0]?.approval === 'accepted' && (
              <button
                className='btn btn-sm btn-success'
                type='button'
                onClick={toggleEmailComposer}
              >
                Compose Email
              </button>
            )}
            {/* <button
              type='button'
              className={`btn btn-sm btn-success ${emailLoading ? 'disabled' : ''}`}
              onClick={() => sendEmail(id)}
              disabled={emailLoading}
            >
              {emailLoading ? 'Sending...' : 'Send Email'}
            </button> */}

            {data[0]?.docAttached !== '' && (
              <div className=''>
                <button type='button' className='btn btn-sm btn-success'>
                  <a
                    href={PF + data[0]?.docAttached}
                    download
                    className='text-white'
                    target='_blank'
                  >
                    View File
                  </a>
                </button>
              </div>
            )}
            <div className=''>
              <button
                type='button'
                className='btn btn-sm btn-warning '
                id='statusDropdown'
                data-bs-toggle='dropdown'
                aria-expanded='false'
              >
                Change Status
              </button>
              <ul className='dropdown-menu' aria-labelledby='statusDropdown'>
                <li>
                  <button className='dropdown-item' onClick={() => handleStatusChange('accepted')}>
                    Accept
                  </button>
                </li>
                <li>
                  <button className='dropdown-item' onClick={() => handleStatusChange('rejected')}>
                    Reject
                  </button>
                </li>
              </ul>
            </div>
            <div className=''>
              <button
                type='button'
                className='btn btn-sm btn-primary '
                id='customizeDropdown'
                data-bs-toggle='dropdown'
                aria-expanded='false'
              >
                Customize
              </button>
              <ul className='dropdown-menu' aria-labelledby='customizeDropdown'>
                <li>
                  <button
                    className='dropdown-item'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_stacked_1'
                  >
                    Change Template
                  </button>
                </li>
                {/* Add more options as needed */}
                <li>
                  <button
                    className='dropdown-item'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_update_logo'
                  >
                    Update Logo
                  </button>
                  {/* <input
                                        type="file"
                                        accept = "image/*"
                                        onChange={handleUpload}
                                    /> */}
                </li>
                <li>
                  <button
                    className='dropdown-item'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_update_color'
                  >
                    Change Color
                  </button>
                </li>
              </ul>
            </div>
            {/* Button trigger modal */}
            <div
              className='modal fade'
              id='kt_modal_stacked_1'
              tabIndex={-1}
              aria-labelledby='ModalLabel'
              aria-hidden='true'
            >
              <div className='modal-dialog modal-dialog-centered '>
                <div className='modal-content'>
                  <div className='modal-header'>
                    <h1 className='modal-title fs-5' id='ModalLabel' aria-hidden='true'>
                      Select Template
                    </h1>
                    <button type='button' className='btn-close' data-bs-dismiss='modal' />
                  </div>
                  <div className='modal-body'>
                    <div className='d-flex flex-row justify-content-around'>
                      {InitialModalData &&
                        InitialModalData.map((item) => (
                          <div key={item.id} className='card col-4 '>
                            <div
                              className='card-body '
                              onClick={() => setModalName(item?.id)}
                              data-bs-toggle='modal'
                              data-bs-target='#stacked2'
                              data-bs-dismiss='modal'
                            >
                              <div className='sidebar-templete-container py-3 greyish-blue position-relative d-flex justify-content-center rounded-top-md'>
                                <div
                                  className='h-200 templete-blur cursor-pointer'
                                  data-ember-action=''
                                  data-ember-action-869={869}
                                >
                                  <div id='ember870' className='ember-view'>
                                    <img
                                      src={item.image}
                                      className='rounded '
                                      height={200}
                                      width={150}
                                    />{' '}
                                    {/**/}
                                  </div>
                                </div>{' '}
                              </div>
                            </div>
                            {activeComponent === `${item.activeComponentName}` && (
                              <div className='selected-label text-center bg-success text-white fw-bold rounded-xl'>
                                Selected
                              </div>
                            )}
                            {activeComponent !== `${item.activeComponentName}` && (
                              <div className='selected-label text-center bg-info text-white fw-bold rounded-xl'>
                                Choose
                              </div>
                            )}
                            <button type='button' className='btn btn-sm btn-primary mt-8 '>
                              {item.name}
                            </button>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='' data-bs-backdrop='static'>
              {
                <div
                  className='modal fade'
                  id={'stacked2'}
                  tabIndex={-1}
                  role='dialog'
                  aria-labelledby='ModalLabel2'
                  aria-hidden='true'
                >
                  <div className='modal-dialog modal-dialog-centered modal-lg'>
                    <div className='modal-content'>
                      <div className='modal-header'>
                        <h1 className='modal-title fs-5' id='ModalLabel2' aria-hidden='true'>
                          Select Quotation Template
                        </h1>
                        <button type='button' className='btn-close' data-bs-dismiss='modal' />
                      </div>
                      <div className='modal-body'>
                        {/* Your modal content */}
                        {/* Example buttons to switch between components */}

                        <div className='d-flex flex-row justify-content-around'>
                          {SecondaryModalData?.map(
                            (item) =>
                              modalName === item?.id && (
                                <>
                                  {item?.data?.map((dataItem, i) => (
                                    <div
                                      key={i}
                                      className='card col-4'
                                      data-bs-dismiss='modal'
                                      onClick={() =>
                                        switchToComponent(dataItem.activeComponentName)
                                      }
                                    >
                                      <div className='card-body '>
                                        <div className='sidebar-templete-container py-3 greyish-blue position-relative d-flex justify-content-center rounded-top-md'>
                                          <div
                                            className='h-200 templete-blur cursor-pointer'
                                            data-ember-action=''
                                            data-ember-action-869={869}
                                          >
                                            <div id='ember870' className='ember-view'>
                                              <img
                                                src={dataItem.image}
                                                className='rounded '
                                                height={200}
                                                width={150}
                                              />{' '}
                                              {/**/}
                                            </div>
                                          </div>{' '}
                                        </div>
                                      </div>
                                      {activeComponent === `${dataItem.activeComponentName}` && (
                                        <div className='selected-label text-center bg-success text-white fw-bold rounded-xl'>
                                          Selected
                                        </div>
                                      )}
                                      {activeComponent !== `${dataItem.activeComponentName}` && (
                                        <div className='selected-label text-center bg-info text-white fw-bold rounded-xl'>
                                          Choose
                                        </div>
                                      )}
                                      {/* <div className="card-footer">Themes 1</div> */}
                                      <button type='button' className='btn btn-primary mt-8'>
                                        {dataItem.activeComponentName}
                                      </button>
                                    </div>
                                  ))}
                                </>
                              )
                          )}
                        </div>
                        {/* Add more buttons for other components as needed */}
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
            <div
              className='modal fade'
              id='kt_modal_update_logo'
              tabIndex={-1}
              aria-labelledby='ModalLabel1'
              aria-hidden='true'
            >
              <div className='modal-dialog'>
                <div className='modal-content'>
                  <div className='modal-header'>
                    <h1 className='modal-title fs-5' id='ModalLabel1' aria-hidden='true'>
                      Modal title
                    </h1>
                    <button type='button' className='btn-close' data-bs-dismiss='modal' />
                  </div>
                  <div className='modal-body'>
                    <div className='d-flex align-items-center' data-bs-dismiss='modal'>
                      <div className='pl-4'>
                        <div className='upload-widget-small '>
                          <div className='file-upload-container'>
                            <div id='ember2451' className='ember-view'>
                              {/**/} {/**/}{' '}
                              <div className='dropzone ' onClick={handleImageClick}>
                                <button className='btn file-upload ' type='button'>
                                  <input
                                    ref={inputRef}
                                    type='file'
                                    accept='image/*'
                                    onChange={handleUpload}
                                    style={{display: 'none'}}
                                  />{' '}
                                  <div className='flex-column'>
                                    <div className=''>
                                      <i className='bi bi-box-arrow-up'></i>
                                    </div>{' '}
                                    <span>&nbsp;Upload Your Organization Logo</span>
                                  </div>
                                </button>{' '}
                                {/**/}
                              </div>
                            </div>
                          </div>
                          {/**/}
                        </div>
                      </div>{' '}
                      <div className='pl-4'>
                        <label>
                          This logo will be displayed in transaction PDFs and email notifications.
                        </label>{' '}
                        <p className='font-xs text-muted w-85'>
                          Preferred Image Dimensions: 240 x 240 pixels @ 72 DPI
                          <br />
                          Maximum File Size: 1MB
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='modal fade'
              id='kt_modal_update_color'
              tabIndex={-1}
              aria-labelledby='ModalLabel1'
              aria-hidden='true'
            >
              <div className='modal-dialog'>
                <div className='modal-content'>
                  <div className='modal-header'>
                    <h1 className='modal-title fs-5' id='ModalLabel1' aria-hidden='true'>
                      Change Color
                    </h1>
                    <button type='button' className='btn-close' data-bs-dismiss='modal' />
                  </div>
                  <div className='modal-body'>
                    <div className='d-flex align-items-center'>
                      {/* color picker */}
                      <div className='d-flex gap-2'>
                        <div className='color-picker'>
                          <input
                            type='color'
                            id='color'
                            name='color'
                            value={color}
                            onChange={(e) => setColor(e.target.value)}
                          />
                          <label htmlFor='color'>Choose your color</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*Modal Not in use */}
            {/*Modal Not in use */}
          </div>
          {data[0]?.approval === 'accepted' && (
            <button
              className='hide-on-pdf btn btn-sm btn-danger print-button mb-8 align-items-right'
              id='kt_quote_print_button'
              onClick={handlePrint}
            >
              Print
            </button>
          )}
        </div>
      </div>

      <div id='pdf-content' className='app-content  print-content'>
        {isLoading && <p>Loading...</p>}
        {/* {!isLoading && activeComponent === 'Invoice' && <Invoice companyLogo={image} />}
                {!isLoading && activeComponent === 'InvoiceSecond' && <InvoiceSecond companyLogo={image} />}
                {!isLoading && activeComponent === 'InvoiceArabic' && <InvoiceArabic companyLogo={image} />}
                {!isLoading && activeComponent === 'InvoiceMultiLang' && <InvoiceMultiLang companyLogo={image} />}
                {!isLoading && activeComponent === 'InvoiceThird' && <InvoiceThird companyLogo={image} />} */}
        {!isLoading && activeComponent === 'QuoteSecond' && (
          <QuoteSecond
            companyLogo={image}
            color={color}
            setColor={setColor}
            minFooter={false}
            isCRM={isCRM}
          />
        )}
        {!isLoading && activeComponent === 'QuoteSecondArabic' && (
          <QuoteSecondArabic companyLogo={image} />
        )}
        {!isLoading && activeComponent === 'QuoteSecondMultiLang' && (
          <QuoteSecondMultiLang companyLogo={image} color={color} setColor={setColor} />
        )}
        {!isLoading && activeComponent === 'QuoteWithMinFooter' && (
          <QuoteSecond
            companyLogo={image}
            color={color}
            setColor={setColor}
            minFooter={true}
            isCRM={isCRM}
          />
        )}
        {/* {!isLoading && activeComponent === 'InvoiceThirdArabic' && <InvoiceThirdArabic companyLogo={image} />}
                {!isLoading && activeComponent === 'InvoiceThirdMultiLang' && <InvoiceThirdMultiLang companyLogo={image} />} */}
      </div>
    </>
  )
}
export default QuoteSelection
