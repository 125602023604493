import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {Link, useParams} from 'react-router-dom'
import {getTransactionsByVendorId} from '../Core/_requests'
import {formatCurrencyWithoutSymbol} from '../../../utils/utils'
import {useAuth} from '../../../modules/auth'

export default function TransactionVendor({className}) {
  const id = useParams().id
  const {currentUser} = useAuth()
  const [transactions, setTransactions] = useState([])
  const [showDropdown, setShowDropdown] = useState({
    purchaseorder: false,
    purchasereceive: false,
    bills: false,
    paymentmade: false,
  })

  const fetchData = async () => {
    const res = await getTransactionsByVendorId(id, '365', currentUser.organization)
    setTransactions(res?.transactions)
  }

  useEffect(() => {
    fetchData()
  }, [])

  const formatDate = (date) => {
    const d = new Date(date)
    // return date as 30/12/2021
    return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
  }

  return (
    <div className={`card w-100 position-relative ${className} `}>
      {/* <div className='card-header align-items-center border-0'>
        <h3 className='card-title align-items-start flex-column'>
          <span>
            Go To Transaction <KTIcon iconName='arrow-down' />{' '}
          </span>
        </h3>
      </div> */}
      <div className='card-body'>
        <div className='d-flex align-items-center bg-light rounded p-5 mb-7'>
          <div
            className='flex-grow-1 me-2'
            onClick={(e) => {
              e.preventDefault()
              setShowDropdown({...showDropdown, purchaseorder: !showDropdown.purchaseorder})
            }}
          >
            <a href='#' className='fw-bold text-gray-800 text-hover-primary fs-6'>
              <KTIcon iconName='arrow-right' /> Purchase Order
            </a>
            <span className='text-muted fw-semibold d-block'></span>
          </div>
          <div className='card-toolbar'>
            <Link to={'/procurement/purchaseOrdersList/add'}>
              <KTIcon iconName='plus' className='text-primary rounded border border-primary me-2' />
              Add-new
            </Link>
          </div>
        </div>
        {showDropdown.purchaseorder && (
          // a dropdown menu containing data of transactions table for invoices
          <div className='table-responsive mt-5'>
            <table className='table table-borderless'>
              <thead>
                <tr className='bg-light-dark'>
                  <th scope='col' className='ps-2'>
                    Date
                  </th>
                  <th scope='col'>Transaction</th>
                  <th scope='col'>Amount</th>
                  <th scope='col'>Balance</th>
                </tr>
              </thead>
              <tbody>
                {transactions?.map((transaction, index) => (
                  <>
                    {transaction?.type === 'purchase' && (
                      <tr key={index}>
                        <td className='ps-2'>{formatDate(transaction.createdAt)}</td>
                        <td>{transaction?.id}</td>
                        <td>
                          {transaction.debit !== 0
                            ? formatCurrencyWithoutSymbol(transaction?.debit)
                            : formatCurrencyWithoutSymbol(transaction?.credit)}
                        </td>
                        <td>{formatCurrencyWithoutSymbol(transaction.debit)}</td>
                      </tr>
                    )}
                  </>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <div className='d-flex align-items-center bg-light rounded p-5 mb-7'>
          <div
            className='flex-grow-1 me-2'
            onClick={(e) => {
              e.preventDefault()
              setShowDropdown({...showDropdown, purchasereceive: !showDropdown.purchasereceive})
            }}
          >
            <a href='#' className='fw-bold text-gray-800 text-hover-primary fs-6'>
              <KTIcon iconName='arrow-right' /> Purchase Receive
            </a>
            <span className='text-muted fw-semibold d-block'></span>
          </div>
          <div className='card-toolbar'>
            <Link to={'/procurement/purchaseReceiveList/add'}>
              <KTIcon iconName='plus' className='text-primary rounded border border-primary me-2' />
              Add-new
            </Link>
          </div>
        </div>
        {showDropdown.purchasereceive && (
          // a dropdown menu containing data of transactions table for payment receive
          <div className='table-responsive mt-5'>
            <table className='table table-borderless'>
              <thead>
                <tr className='bg-light-dark'>
                  <th scope='col' className='ps-2'>
                    Date
                  </th>
                  <th scope='col'>Transaction</th>
                  <th scope='col'>Amount</th>
                  <th scope='col'>Balance</th>
                </tr>
              </thead>
              <tbody>
                {transactions?.map((transaction, index) => (
                  <>
                    {transaction?.type === 'purchase receive' && (
                      <tr key={index}>
                        <td className='ps-2'>{formatDate(transaction.createdAt)}</td>
                        <td>{transaction?.id}</td>
                        <td>
                          {transaction.debit !== 0
                            ? formatCurrencyWithoutSymbol(transaction?.debit)
                            : formatCurrencyWithoutSymbol(transaction?.credit)}
                        </td>
                        <td>
                          {transaction.debit !== 0
                            ? formatCurrencyWithoutSymbol(transaction?.debit)
                            : formatCurrencyWithoutSymbol(transaction?.credit)}
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <div className='d-flex align-items-center bg-light rounded p-5 mb-7'>
          <div
            className='flex-grow-1 me-2'
            onClick={(e) => {
              e.preventDefault()
              setShowDropdown({...showDropdown, bills: !showDropdown.bills})
            }}
          >
            <a href='#' className='fw-bold text-gray-800 text-hover-primary fs-6'>
              <KTIcon iconName='arrow-right' /> Bills
            </a>
            <span className='text-muted fw-semibold d-block'></span>
          </div>
          <div className='card-toolbar'>
            <Link to={'/procurement/billsList/add'}>
              <KTIcon iconName='plus' className='text-primary rounded border border-primary me-2' />
              Add-new
            </Link>
          </div>
        </div>
        {showDropdown.bills && (
          // a dropdown menu containing data of transactions table for bills
          <div className='table-responsive mt-5'>
            <table className='table table-borderless'>
              <thead>
                <tr className='bg-light-dark'>
                  <th scope='col' className='ps-2'>
                    Date
                  </th>
                  <th scope='col'>Transaction</th>
                  <th scope='col'>Amount</th>
                  <th scope='col'>Balance</th>
                </tr>
              </thead>
              <tbody>
                {transactions?.map((transaction, index) => (
                  <>
                    {transaction?.type === 'bill' && (
                      <tr key={index}>
                        <td className='ps-2'>{formatDate(transaction.createdAt)}</td>
                        <td>{transaction?.id}</td>
                        <td>
                          {transaction.debit !== 0
                            ? formatCurrencyWithoutSymbol(transaction?.debit)
                            : formatCurrencyWithoutSymbol(transaction?.credit)}
                        </td>
                        <td>
                          {transaction.debit !== 0
                            ? formatCurrencyWithoutSymbol(transaction?.debit)
                            : formatCurrencyWithoutSymbol(transaction?.credit)}
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <div className='d-flex align-items-center bg-light rounded p-5 mb-7'>
          <div
            className='flex-grow-1 me-2'
            onClick={(e) => {
              e.preventDefault()
              setShowDropdown({...showDropdown, paymentmade: !showDropdown.paymentmade})
            }}
          >
            <a href='#' className='fw-bold text-gray-800 text-hover-primary fs-6'>
              <KTIcon iconName='arrow-right' /> Payment Made
            </a>
            <span className='text-muted fw-semibold d-block'></span>
          </div>
          <div className='card-toolbar'>
            <Link to={'/procurement/paymentMadeList/add'}>
              <KTIcon iconName='plus' className='text-primary rounded border border-primary me-2' />
              Add-new
            </Link>
          </div>
        </div>
        {showDropdown.paymentmade && (
          // a dropdown menu containing data of transactions table for payment made
          <div className='table-responsive mt-5'>
            <table className='table table-borderless'>
              <thead>
                <tr className='bg-light-dark'>
                  <th scope='col' className='ps-2'>
                    Date
                  </th>
                  <th scope='col'>Transaction</th>
                  <th scope='col'>Amount</th>
                  <th scope='col'>Balance</th>
                </tr>
              </thead>
              <tbody>
                {transactions?.map((transaction, index) => (
                  <>
                    {transaction?.type === 'payment made' && (
                      <tr key={index}>
                        <td className='ps-2'>{formatDate(transaction.createdAt)}</td>
                        <td>{transaction?.id}</td>
                        <td>
                          {transaction.debit !== 0
                            ? formatCurrencyWithoutSymbol(transaction?.debit)
                            : formatCurrencyWithoutSymbol(transaction?.credit)}
                        </td>
                        <td>
                          {transaction.debit !== 0
                            ? formatCurrencyWithoutSymbol(transaction?.debit)
                            : formatCurrencyWithoutSymbol(transaction?.credit)}
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  )
}
