import React, {useEffect, useState} from 'react'
import {getAllGroupedAccounts, postCOA} from '../core/_requests'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'
import Select from 'react-select'

const AddCategoryOfAccounts = () => {
  const {currentUser} = useAuth()
  const [formData, setFormData] = useState({
    accountType: 'otherasset',
    name: '',
    accounts: [],
  })
  const [accountsList, setAccountsList] = useState<any>({})
  const navigate = useNavigate()

  const fetchParentAccounts = async () => {
    const res = await getAllGroupedAccounts(currentUser?.organization)
    if (res) {
      setAccountsList(res)
    }
  }

  useEffect(() => {
    fetchParentAccounts()
  }, [currentUser])

  const handleChange = (e) => {
    const {name, value} = e.target
    console.log(value)
    const updatedFormData = {...formData}
    updatedFormData[name] = value
    setFormData(updatedFormData)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    // console.log(formData)
    const data = {
      ...formData,
      company: currentUser?.company,
      organization: currentUser?.organization,
    }
    console.log(data)
    const res = await postCOA(data)
    if (res) {
      navigate('/account/categoryofaccounts')
    }
  }

  return (
    <div className='card p-4'>
      <div className='card-header pt-4'>
        <h3>Create Category of Accounts</h3>
      </div>
      <div className='card-body'>
        <form>
          <div className='d-flex flex-col gap-2'>
            <div className=''>
              <p>
                <span className='required'>Select Account Type</span>
              </p>
              <select
                name='accountType'
                onChange={(e) => {
                  setFormData({...formData, accountType: e.target.value})
                }}
                className='form-control form-control-solid'
              >
                <optgroup label='Assets'>
                  <option value={'otherasset'}>Other Asset</option>
                  <option value={'othercurrentasset'}>Other Current Asset</option>
                  <option value={'cash'}>Cash</option>
                  <option value={'bank'}>Bank</option>
                  <option value={'fixedasset'}>Fixed Asset</option>
                  <option value={'stock'}>Stock</option>
                  <option value={'paymentclearing'}>Payment Clearing</option>
                </optgroup>
                <optgroup label='Liability'>
                  <option value={'othercurrentliability'}>Other Current Liability</option>
                  <option value={'creditcard'}>Credit Card</option>
                  <option value={'longtermliability'}>Long term Liability</option>
                  <option value={'otherliability'}>Other Liability</option>
                </optgroup>
                <optgroup label='Equity'>
                  <option value={'equity'}>Equity</option>
                </optgroup>
                <optgroup label='Income'>
                  <option value={'income'}>Income</option>
                  <option value={'otherincome'}>Other Income</option>
                </optgroup>
                <optgroup label='Expense'>
                  <option value={'expense'}>Expense</option>
                  <option value={'costofgoodssold'}>Cost of Goods Sold</option>
                  <option value={'otherexpense'}>Other Expense</option>
                </optgroup>
              </select>
            </div>
            <div className=''>
              <p>
                <span className='required'>Category Name</span>
              </p>
              <input
                type='text'
                name='name'
                value={formData?.name}
                className='form-control form-control-solid'
                required
                onChange={handleChange}
              />
            </div>
            <div className=''>
              <p>
                <span className='required'>Select Accounts</span>
              </p>
              <Select
                options={accountsList[formData.accountType]}
                isMulti
                name='accounts'
                className='basic-multi-select'
                classNamePrefix='select'
                // value={accountsList[formData.accountType]?.filter((item) =>
                //   formData.accounts.includes(item.value)
                // )}
                onChange={(e: any) =>
                  setFormData({...formData, accounts: e.map((item) => item.value)})
                }
              />
            </div>
          </div>
          <button type='button' className='btn btn-success my-3' onClick={handleSubmit}>
            Save
          </button>
        </form>
      </div>
    </div>
  )
}

export default AddCategoryOfAccounts
