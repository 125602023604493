import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {getItemById} from '../../Core/_requests'
import {ServiceDocuments} from '../ServiceDocuments'
import ServiceTransactionTable from '../ServiceTransactionTable'

const ItemDetail = () => {
  const PF = 'https://qf-assets.s3.me-central-1.amazonaws.com/'
  const params = useParams()
  const id = params.id
  const [data, setData] = useState({
    name: '',
    description: '',
    price: 0,
    type: 'service',
    sku: '',
    unit: '',
    salesAccount: {accountName: ''},
    purchaseAccount: {accountName: ''},
    costPrice: 0,
    tags: [],
    thumbnail: '',
    category: '',
  })

  const [openImage, setOpenImage] = useState(null)

  const handleImageClick = (imgSrc) => {
    setOpenImage(imgSrc)
  }

  const fetchData = async () => {
    const res = await getItemById(id)
    console.log('Item ', res)
    setData({
      name: res.name,
      description: res.description,
      price: res.price,
      type: res.type,
      sku: res.sku,
      unit: res.unit,
      salesAccount: res?.salesAccount,
      purchaseAccount: res?.purchaseAccount,
      costPrice: res.costPrice,
      tags: res.tags,
      thumbnail: res.thumbnail,
      category: res.category?.categoryName,
    })
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div className='container-fluid bg-gray-50 p-4'>
      <div className='row my-4 '>
        <div className='col-xl-4 space-y-6'>
          <div className='card border-0 p-4 rounded-md '>
            <div className='card-body '>
              <h5 className='card-title text-start fw-semibold fs-5'>Thumbnail</h5>
              <img
                src={`${PF}${data.thumbnail}`}
                alt='Thumbnail'
                className='w-48 h-48 object-cover'
              />
            </div>
          </div>

          <div className='card card-flush py-4'>
            <div className='card-header '>
              <div className='card-title  '>
                <h2>Category Details</h2>
              </div>
            </div>

            <div className='card-body p-0 px-2 pb-2'>
              <label className='form-label'>Categories</label>
              {<div className='form-control mb-2'>{data.category}</div>}

              <label className='form-label d-block'>Tags</label>
              {
                <div className='form-control mb-2'>
                  {data.tags?.map((tag, idx) => (
                    <span className='badge badge-light mb-2 me-2' key={idx}>
                      {tag}
                    </span>
                  ))}
                </div>
              }
            </div>
          </div>
        </div>
        <div className='col-xl-8 mt-4'>
          <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
            <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
              <li className='nav-item'>
                <a
                  className='nav-link text-active-primary pb-4 active'
                  data-bs-toggle='tab'
                  href='#kt_ecommerce_add_product_general'
                >
                  Details
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link text-active-primary pb-4'
                  data-bs-toggle='tab'
                  href='#kt_ecommerce_add_product_accounts'
                >
                  Accounts
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link text-active-primary pb-4'
                  data-bs-toggle='tab'
                  href='#kt_ecommerce_add_product_documents'
                >
                  Documents
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link text-active-primary pb-4'
                  data-bs-toggle='tab'
                  href='#kt_ecommerce_add_product_transactions'
                >
                  Transactions
                </a>
              </li>
            </ul>
            <div className='tab-content'>
              <div
                className='tab-pane fade show active'
                id='kt_ecommerce_add_product_general'
                role='tab-panel'
              >
                <div className='d-flex flex-column gap-7 gap-lg-10'>
                  <div className='card card-flush py-4'>
                    <div className='card-header'>
                      <div className='card-title'>
                        <h2>Service Details</h2>
                      </div>
                    </div>
                    <div className='card-body pt-0'>
                      <div className='mb-10 fv-row'>
                        <label className='required form-label'>Service Name</label>
                        <div className='form-control mb-2'>{data.name}</div>
                      </div>

                      <div className='mb-10 fv-row'>
                        <label className='form-label'>Description</label>
                        <div className='w-full rounded-lg h-full'>
                          <textarea
                            name='description'
                            value={data.description}
                            disabled
                            placeholder='type your text here..'
                            rows={4}
                            cols={50}
                            className='w-full rounded border px-4 py-2'
                          ></textarea>
                        </div>
                      </div>

                      <div className='mb-10 fv-row'>
                        <label className='required form-label'>SKU</label>
                        <div className='form-control mb-2'>{data.sku}</div>
                      </div>

                      <div className='mb-10 fv-row'>
                        <label className='form-label required'>Unit</label>
                        <div className='form-control mb-2'>{data.unit}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className='tab-pane fade'
                id='kt_ecommerce_add_product_accounts'
                role='tab-panel'
              >
                <div className='d-flex flex-column gap-7 gap-lg-10'>
                  <div className='card card-flush py-4'>
                    <div className='card-header'>
                      <div className='card-title'>
                        <h2>Sales Information</h2>
                      </div>
                    </div>

                    <div className='card-body pt-0'>
                      <div className='mb-10 fv-row'>
                        <label className='form-label'>Selling Price</label>
                        <div className='form-control mb-2'>{data.price}</div>
                      </div>
                      <div className='mb-10 fv-row'>
                        <label className='form-label'>Accounts Information</label>
                        <div className='form-control mb-2'>
                          {data.salesAccount?.accountName || 'No Account Selected'}
                        </div>
                      </div>
                    </div>

                    <div className='card-header'>
                      <div className='card-title'>
                        <h2>Purchase Information</h2>
                      </div>
                    </div>

                    <div className='card-body pt-0'>
                      <div className='mb-10 fv-row'>
                        <label className='form-label'>Cost Price</label>
                        <div className='form-control mb-2'>{data.costPrice}</div>
                      </div>
                      <div className='mb-10 fv-row'>
                        <label className='form-label'>Accounts Information</label>
                        <div className='form-control mb-2'>
                          {data.purchaseAccount?.accountName || 'No Account Selected'}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className='tab-pane fade'
                id='kt_ecommerce_add_product_documents'
                role='tab-panel'
              >
                <ServiceDocuments />
              </div>

              <div
                className='tab-pane fade'
                id='kt_ecommerce_add_product_transactions'
                role='tab-panel'
              >
                <ServiceTransactionTable />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <div className='container mx-auto border shadow-md rounded p-4'>
    //   <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mt-2'>
    //     {/* Left column */}
    //     {/* <div className='md:col-span-2'>
    //       <div className='grid md:grid-rows-4 gap-y-4 p-2'>
    //         <div className='md:row-span-3 h-full w-full'>
    //           <img
    //             // style={{height: '100%', width: '100%', objectFit: 'fill'}}
    //             className='rounded-lg'
    //             src='https://evotech.global/wp-content/uploads/2023/03/1.png'
    //             alt='IMAGE 1'
    //           />
    //         </div>

    //         <div className='grid md:row-span-1 md:grid-cols-4 gap-4 md:place-items-stretch mt-2'>
    //           <div>
    //             <img
    //               className='h-auto w-full max-w-full rounded-lg'
    //               src=''
    //               alt='IMAGE 2'
    //             />
    //           </div>
    //           <div>
    //             <img
    //               className='h-auto w-full max-w-full rounded-lg'
    //               src=''
    //               alt='IMAGE 3'
    //             />
    //           </div>
    //           <div>
    //             <img
    //               className='h-auto w-full max-w-full rounded-lg'
    //               src=''
    //               alt='IMAGE 4'
    //             />
    //           </div>
    //           <div>
    //             <img
    //               className='h-auto w-full max-w-full rounded-lg'
    //               src=''
    //               alt='IMAGE 5'
    //             />
    //           </div>
    //         </div>
    //       </div>
    //     </div> */}
    //     {/* Right column */}
    //     <div className='col-span-1 md:col-span-3 '>
    //       <div className='card'>
    //         <div className='card-body'>
    //           <div className='text-center'>
    //             <h1 className='text-7xl font-semibold'>{data?.name}</h1>
    //           </div>

    //           <div className='text-start'>
    //             <p className='text-gray-400'>PRICE</p>
    //             <p className='text-3xl font-semibold text-gray-700'>{data.price} SAR</p>
    //           </div>

    //           <div className='grid sm:grid-cols-2 gap-4 mb-4'>
    //             <div>
    //               {data.sku !== '' && (
    //                 <div className='flex justify-start gap-x-4 items-center '>
    //                   <div className='text-gray-400'>SKU</div>
    //                   <div className='text-lg font-semibold py-4 px-12 rounded border-blue-600 bg-blue-50 text-blue-600  text-center'>
    //                     {data.sku}
    //                   </div>
    //                 </div>
    //               )}
    //             </div>

    //             <div>
    //               {data.unit !== '' && (
    //                 <div className='flex justify-start gap-x-4 items-center ml-auto'>
    //                   <div className='text-gray-400'>UNIT</div>
    //                   <div className='text-lg font-semibold py-4 px-12 rounded border-blue-600 bg-blue-50 text-blue-600  text-center'>
    //                     {data.unit}
    //                   </div>
    //                 </div>
    //               )}
    //             </div>
    //           </div>

    //           <div className='flex justify-between gap-x-4 items-center mb-4'>
    //             {data?.salesAccount?.accountName && (
    //               <div className='flex  flex-shrink-0 justify-start gap-x-4 items-center w-full'>
    //                 <div className='text-gray-400'>SALES ACCOUNT </div>
    //                 <div className='text-lg bg-gray-100  font-semibold p-4 mb-4 rounded text-gray-700'>
    //                   {data.salesAccount.accountName}
    //                 </div>
    //               </div>
    //             )}
    //             {data?.purchaseAccount?.accountName && (
    //               <div className='flex flex-shrink-0  justify-start gap-x-4 items-center w-full'>
    //                 <div className='text-gray-400'>PURCHASE ACCOUNT</div>
    //                 <div className='text-lg  bg-gray-100 font-semibold p-4 mb-4 rounded text-gray-700'>
    //                   {data.purchaseAccount.accountName}
    //                 </div>
    //               </div>
    //             )}
    //           </div>

    //           {data.description !== '' && (
    //             <div className='text-start '>
    //               <div className='text-gray-400'>DESCRIPTION</div>
    //               <div className='text-xl p-8 mb-4 rounded bg-gray-100 font-semibold text-gray-700'>
    //                 {data.description}
    //               </div>
    //             </div>
    //           )}
    //           {/* Add more details here if needed */}
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  )
}

export default ItemDetail
