import React, {useEffect, useRef, useState} from 'react'
import {Card} from 'react-bootstrap'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import multiMonthPlugin from '@fullcalendar/multimonth'
import {useAuth} from '../../../modules/auth'
import {getEmployeesAttendance, getEmployeeAttendanceByEmployeeId} from '../core/_requests'
import {useParams} from 'react-router-dom'

const EmployeeAttendance = () => {
  const id = useParams().id
  const calendarComponentRef = useRef(null)
  const {currentUser} = useAuth()
  const [calendarEvents, setCalendarEvents] = useState([])

  const getCalendarData = async () => {
    try {
      if (currentUser?.attendanceMethod === 'mobile') {
        const response = await getEmployeesAttendance(currentUser?.organization)
        console.log('res', response)
        const data = response?.result?.map((item) => ({
          title: `${item?.count}/${response?.totalEmployees}`,
          start: item?.date,
        }))
        setCalendarEvents(data)
      } else if (currentUser?.attendanceMethod === 'web') {
        const response = await getEmployeeAttendanceByEmployeeId(id)

        const data1 = []
        // loop to the date uptil 90 days ago and get the count of employees present on that day
        for (let i = 0; i < 90; i++) {
          const date = new Date()
          date.setDate(date.getDate() - i)
          const dateStr = date.toDateString()
          const matchingKey = Object.keys(response).find(
            (key) => new Date(key).toDateString() === dateStr
          )

          const count = matchingKey !== undefined ? 'P' : 'A'

          data1.push({
            title: count,
            start: date,
          })
        }
        setCalendarEvents(data1)
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getCalendarData()
  }, [currentUser?.attendanceMethod])

  const renderEventContent = (eventInfo) => {
    return (
      <div
        className={`fw-semibold mx-auto ps-4 fs-3 ${
          eventInfo.event.title === 'A' ? 'text-danger' : 'text-success'
        }`}
      >
        {eventInfo.event.title}
      </div>
    )
  }

  return (
    <div>
      <div className=''>
        <Card>
          <Card.Body>
            <div className='demo-app-calendar' id='mycalendartest'>
              <FullCalendar
                plugins={[
                  dayGridPlugin,
                  timeGridPlugin,
                  interactionPlugin,
                  listPlugin,
                  multiMonthPlugin,
                ]}
                initialView={'dayGridMonth'}
                headerToolbar={{
                  start: 'today prev,next',
                  center: 'title',
                  end: 'timeGridDay,timeGridWeek,dayGridMonth,multiMonthYear,listYear',
                }}
                rerenderDelay={10}
                eventDurationEditable={false}
                weekends={true}
                ref={calendarComponentRef}
                events={calendarEvents}
                eventContent={renderEventContent}
              />
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  )
}

export default EmployeeAttendance
