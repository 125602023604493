import React, {FC, useEffect, useRef, useState} from 'react'
import {KTIcon, KTSVG} from '../../../../../_metronic/helpers'
import {ErrorMessage, Field, Form, useFormikContext} from 'formik'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Select from 'react-select'
import {Alert} from 'react-bootstrap'
import {ICreateAccount} from '../../Core/CreateVendorHelper'
import {addVendorType, getVendorType} from '../../Core/_requests'
import {useAuth} from '../../../../modules/auth'

const Step1: FC = () => {
  const {currentUser} = useAuth()
  const closeModal = useRef<HTMLButtonElement>(null)
  const {setFieldValue, values} = useFormikContext<ICreateAccount>()
  const [workPhoneNo, setWorkPhoneNo] = useState<string | undefined>('')
  const [personalNo, setPersonalNo] = useState<string | undefined>('')
  const [addvendortype, setAddvendortype] = useState('')
  const [vendorTypeOptions, setVendorTypeOptions] = useState([])

  useEffect(() => {
    if (values?.contactNumbers.workPhone && values?.contactNumbers.mobilePhone) {
      setWorkPhoneNo(String(values.contactNumbers.workPhone))
      setPersonalNo(String(values.contactNumbers.mobilePhone))
    }
  }, [])

  useEffect(() => {
    const fetchVendorType = async () => {
      try {
        const res = await getVendorType(currentUser?.organization)
        if (!res || !Array.isArray(res.vendorType)) {
          console.error('Invalid response format from getIncoTerms:', res)
          return
        }
        const filteredVendorType = res.vendorType.filter((item) => item !== null)
        const data = filteredVendorType.map((item) => ({value: item, label: item}))
        setVendorTypeOptions(data)
      } catch (error) {
        console.error('Error fetching incoTerms:', error)
      }
    }

    if (currentUser.organization !== undefined) fetchVendorType()
  }, [currentUser])

  useEffect(() => {
    setFieldValue('contactNumbers.workPhone', workPhoneNo)
    setFieldValue('contactNumbers.mobilePhone', personalNo)
  }, [setFieldValue, workPhoneNo, personalNo])

  const handleVendorTypeSubmit = () => {
    const data = {
      vendorType: addvendortype,
    }
    const res = addVendorType(currentUser?.organization, data)
    if (res) {
      setAddvendortype('')
      setVendorTypeOptions([...vendorTypeOptions, {value: addvendortype, label: addvendortype}])
      closeModal.current.click()
    }
  }

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>New Vendor</h2>
        {/* <div className='text-gray-400 fw-bold fs-6'>
          If you need more info, please check out
          <a href='/dashboard' className='link-primary fw-bolder'>
            Help Page
          </a>
          .
        </div> */}
      </div>
      {/* 
      <div className='mb-10 fv-row'>
        <label className='d-flex align-items-center form-label mb-3'>
          Specify Team Size
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Provide your team size to help us setup your billing'
          ></i>
        </label>

        <div className='row mb-2' data-kt-buttons='true'>
          <div className='col'>
            <Field
              type='radio'
              className='btn-check'
              name='accountTeamSize'
              value='1-1'
              id='kt_account_team_size_select_1'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_account_team_size_select_1'
            >
              <span className='fw-bolder fs-3'>1-1</span>
            </label>
          </div>

          <div className='col'>
            <Field
              type='radio'
              className='btn-check'
              name='accountTeamSize'
              value='2-10'
              id='kt_account_team_size_select_2'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_account_team_size_select_2'
            >
              <span className='fw-bolder fs-3'>2-10</span>
            </label>
          </div>

          <div className='col'>
            <Field
              type='radio'
              className='btn-check'
              name='accountTeamSize'
              value='10-50'
              id='kt_account_team_size_select_3'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_account_team_size_select_3'
            >
              <span className='fw-bolder fs-3'>10-50</span>
            </label>
          </div>

          <div className='col'>
            <Field
              type='radio'
              className='btn-check'
              name='accountTeamSize'
              value='50+'
              id='kt_account_team_size_select_4'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_account_team_size_select_4'
            >
              <span className='fw-bolder fs-3'>50+</span>
            </label>
          </div>
        </div>

        <div className='form-text'>
          Customers will see this shortened version of your statement descriptor
        </div>
      </div> */}

      <div className='mb-10 fv-row'>
        <label className='form-label mb-3'>Primary Contact</label>
        <i
          className='fas fa-exclamation-circle ms-2 fs-7'
          data-bs-toggle='tooltip'
          title='The name you enter here will be the primary contact for this vendor. This person will be the main point of contact for all communications with this vendor.'
        ></i>
        <div className='row'>
          <div className='col-md-4'>
            <Field
              as='select'
              className='form-select form-select-lg form-select-solid mb-3 required'
              name='primaryContact.salutation'
              placeholder='Salutation'
            >
              <option></option>
              <option value='Mr.'>Mr.</option>
              <option value='Mrs.'>Mrs.</option>
              <option value='Ms.'>Ms.</option>
              <option value='Miss.'>Miss.</option>
              <option value='Dr.'>Dr.</option>
            </Field>
            <div className='text-danger mt-2'>
              <ErrorMessage name='primaryContact.salutation' />
            </div>
          </div>
          <div className='col-md-4'>
            <Field
              type='text'
              className='form-control form-control-lg form-control-solid required'
              name='primaryContact.firstName'
              placeholder='First Name'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='primaryContact.firstName' />
            </div>
          </div>
          <div className='col-md-4'>
            <Field
              type='text'
              className='form-control form-control-lg form-control-solid required'
              name='primaryContact.lastName'
              placeholder='Last Name'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='primaryContact.lastName' />
            </div>
          </div>
        </div>
      </div>

      <div className='mb-10 fv-row'>
        <label className='  form-label mb-3 required'>Company Name</label>

        <Field
          type='text'
          className='form-control form-control-lg form-control-solid'
          name='companyName'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='companyName' />
        </div>
      </div>

      <div className='mb-10 fv-row'>
        <label className='form-label mb-3 required'>Vendor Display Name</label>
        <i
          className='fas fa-exclamation-circle ms-2 fs-7'
          data-bs-toggle='tooltip'
          title='The name you enter here will be the primary contact for this vendor. This person will be the main point of contact for all communications with this vendor.'
        ></i>

        <Field
          type='text'
          className='form-control form-control-lg form-control-solid'
          name='displayName'
          placeholder='Display Name'
        />

        <div className='text-danger mt-2'>
          <ErrorMessage name='displayName' />
        </div>
      </div>

      <div className='mb-10 fv-row'>
        <label className='form-label mb-3 required'>Vendor id</label>

        <Field
          type='text'
          className='form-control form-control-lg form-control-solid'
          name='vendorID'
          placeholder='Vendor ID'
        />

        <div className='text-danger mt-2'>
          <ErrorMessage name='vendorID' />
        </div>
      </div>

      <div className='mb-10 fv-row'>
        <label className='form-label mb-3 required'>Vendor Type</label>
        <div className='d-flex align-items-center'>
          <Field
            as='select'
            className='form-control form-control-lg form-control-solid'
            name='vendorType'
          >
            <option></option>
            {vendorTypeOptions?.map((item, index) => (
              <option value={item?.value} key={item?.value}>
                {item?.label}
              </option>
            ))}
          </Field>
          <div className='card-toolbar mt-5'>
            <div data-bs-toggle='modal' data-bs-target='#kt_modal_2' className='cursor-pointer'>
              <KTIcon iconName={'plus-square'} className='fs-2' />
            </div>
          </div>
        </div>

        <div className='text-danger mt-2'>
          <ErrorMessage name='vendorType' />
        </div>
      </div>

      <div className='mb-10 fv-row'>
        <label className='form-label mb-3 required'>Vendor Email</label>

        <Field
          type='text'
          className='form-control form-control-lg form-control-solid'
          name='emailAddress'
          placeholder='Email'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='emailAddress' />
        </div>
      </div>

      <div className='mb-10 fv-row'>
        <label className='form-label mb-3  required'>Work Phone</label>
        <div className='form-control form-control-lg form-control-solid'>
          <PhoneInput
            country={'sa'}
            value={workPhoneNo}
            onChange={(phone) => setWorkPhoneNo(phone)}
          />
        </div>

        {/* <Field
          type='mobile'
          className='form-control form-control-lg form-control-solid'
          name='workPhone'
          placeholder='Work Phone'
        /> */}
        <div className='text-danger mt-2'>
          <ErrorMessage name='workPhone' />
        </div>
      </div>
      <div className='mb-10 fv-row'>
        <label className='form-label mb-3 required'>Personal Mobile</label>
        <div className='form-control form-control-lg form-control-solid'>
          <PhoneInput
            country={'sa'}
            value={personalNo}
            onChange={(phone) => setPersonalNo(phone)}
          />
        </div>

        <div className='text-danger mt-2'>
          <ErrorMessage name='personalMobile' />
        </div>
      </div>

      {/* <div className='mb-0 fv-row'>
        <label className='d-flex align-items-center form-label mb-5'>
          Select Account Plan
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Monthly billing will be based on your account plan'
          ></i>
        </label>

        <div className='mb-0'>
          <label className='d-flex flex-stack mb-5 cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='symbol symbol-50px me-6'>
                <span className='symbol-label'>
                  <KTIcon iconName='bank' className='fs-1 text-gray-600' />
                </span>
              </span>

              <span className='d-flex flex-column'>
                <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                  Company Account
                </span>
                <span className='fs-6 fw-bold text-gray-400'>
                  Use images to enhance your post flow
                </span>
              </span>
            </span>

            <span className='form-check form-check-custom form-check-solid'>
              <Field className='form-check-input' type='radio' name='accountPlan' value='1' />
            </span>
          </label>

          <label className='d-flex flex-stack mb-5 cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='symbol symbol-50px me-6'>
                <span className='symbol-label'>
                  <KTIcon iconName='chart' className='fs-1 text-gray-600' />
                </span>
              </span>

              <span className='d-flex flex-column'>
                <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                  Developer Account
                </span>
                <span className='fs-6 fw-bold text-gray-400'>Use images to your post time</span>
              </span>
            </span>

            <span className='form-check form-check-custom form-check-solid'>
              <Field className='form-check-input' type='radio' name='accountPlan' value='2' />
            </span>
          </label>

          <label className='d-flex flex-stack mb-0 cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='symbol symbol-50px me-6'>
                <span className='symbol-label'>
                  <KTIcon iconName='chart-pie-4' className='fs-1 text-gray-600' />
                </span>
              </span>

              <span className='d-flex flex-column'>
                <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                  Testing Account
                </span>
                <span className='fs-6 fw-bold text-gray-400'>
                  Use images to enhance time travel rivers
                </span>
              </span>
            </span>

            <span className='form-check form-check-custom form-check-solid'>
              <Field className='form-check-input' type='radio' name='accountPlan' value='3' />
            </span>
          </label>
        </div>
      </div> */}
      <div className='modal fade' tabIndex={-1} id='kt_modal_2'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Add New Vendor Type</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>

            <div className='modal-body'>
              <form>
                <div className='row rowwidthclient'>
                  <div className='form-group mb-3 col-md-12'>
                    <label className='mb-3 required'>Vendor Type</label>
                    <input
                      type='text'
                      name='vendorType'
                      value={addvendortype}
                      onChange={(e) => setAddvendortype(e.target.value)}
                      className='form-control'
                    />
                  </div>
                </div>
              </form>
            </div>

            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                id='closeModalButton2'
                ref={closeModal}
              >
                Close
              </button>
              <button type='button' className='btn btn-primary' onClick={handleVendorTypeSubmit}>
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {Step1}
