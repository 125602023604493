import axios from 'axios'
const API_URL = process.env.REACT_APP_THEME_API_URL
const FILE_URL = `${API_URL}/multi-images`

export const PF = 'https://qf-assets.s3.me-central-1.amazonaws.com/'

export const formatCreatedAt = (date) => {
  if (date === undefined || date === null) return 'N/A'
  const createdAt = new Date(date)
  return `${createdAt.getDate()}/${createdAt.getMonth() + 1}/${createdAt.getFullYear()}`
}

export const formatCurrency = (amount, currency = 'SAR') => {
  return amount.toLocaleString('en-US', {style: 'currency', currency: currency})
}

export const formatCurrencyWithoutSymbol = (amount) => {
  return amount.toLocaleString('en-US', {minimumFractionDigits: 2})
}

const numberToWords = (num) => {
  const ones = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine']
  const teens = [
    'Eleven',
    'Twelve',
    'Thirteen',
    'Fourteen',
    'Fifteen',
    'Sixteen',
    'Seventeen',
    'Eighteen',
    'Nineteen',
  ]
  const tens = [
    '',
    'Ten',
    'Twenty',
    'Thirty',
    'Forty',
    'Fifty',
    'Sixty',
    'Seventy',
    'Eighty',
    'Ninety',
  ]
  const thousands = ['', 'Thousand', 'Million', 'Billion']

  if (num === 0) return 'Zero'

  const helper = (num) => {
    if (num < 10) return ones[num]
    else if (num < 20) return teens[num - 11]
    else if (num < 100) return tens[Math.floor(num / 10)] + ' ' + ones[num % 10]
    else if (num < 1000) return ones[Math.floor(num / 100)] + ' Hundred ' + helper(num % 100)
    for (let i = 0, n = num; n > 0; i++, n = Math.floor(n / 1000)) {
      if (n % 1000 !== 0)
        return helper(Math.floor(n / 1000)) + ' ' + thousands[i] + ' ' + helper(num % 1000)
    }
  }

  return helper(num).trim()
}

export const convertAmountToWords = (amount, currency) => {
  const currencyNames = {
    INR: ['Rupees', 'Paise'],
    USD: ['Dollars', 'Cents'],
    AED: ['Dirhams', 'Fils'],
    QAR: ['Riyals', 'Dirhams'],
    EUR: ['Euros', 'Cents'],
  }

  const [wholePart, decimalPart] = amount.toString().split('.').map(Number)
  const wholeInWords = numberToWords(wholePart)
  const decimalInWords = decimalPart ? numberToWords(decimalPart.toString().padEnd(2, '0')) : ''

  const [wholeName, decimalName] = currencyNames[currency] || ['Currency', 'Subunit']

  return `${wholeInWords} ${wholeName}${
    decimalInWords ? ` and ${decimalInWords} ${decimalName}` : ''
  } Only`
}

export const uploadMultipleImages = async (images) => {
  try {
    const response = await axios.post(`${FILE_URL}`, images, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  } catch (error) {
    console.error(error)
    return error
  }
}
