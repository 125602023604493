import React, {FC, useEffect, useState} from 'react'
import {Field, ErrorMessage, useField, useFormikContext} from 'formik'
import {KTIcon} from '../../../../../_metronic/helpers'
import {getAllDepartment} from '../../core/_requests'
import {useAuth} from '../../../../modules/auth'
import {ICreateAccount} from '../../core/CreateAccountWizardHelper'

const Step2: FC = () => {
  const {currentUser} = useAuth()
  const {setFieldValue, values} = useFormikContext<ICreateAccount>()
  const [startDate, setStartDate] = useState(new Date())
  const [departmentOptions, setDepartmentOptions] = useState([])
  const [field, , helpers] = useField('department')
  const [roles, setRoles] = useState({})
  const [department, setDepartment] = useState('')

  const fetchDepartments = async () => {
    try {
      const response = await getAllDepartment(currentUser?.organization)
      // Assuming the response data is an array of objects with id and name properties
      const departments = response.map((department) => ({
        value: department._id,
        label: department.name,
        roles: department.roles,
      }))
      setDepartmentOptions(departments)
      let temp = {}
      response.forEach((item) => {
        temp[item._id] = item.roles
      })
      setRoles(temp)
    } catch (error) {
      console.error('Error fetching departments:', error)
    }
  }

  useEffect(() => {
    fetchDepartments()
  }, [])

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-12'>
        <h2 className='fw-bolder text-dark'>Work Information</h2>

        <div className='text-gray-400 fw-bold fs-6'>
          If you need more info, please check out
          <a href='/dashboard' className='link-primary fw-bolder'>
            Help Page
          </a>
        </div>
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label'>Department</label>
        <div className='d-flex align-items-center'>
          <Field
            as='select'
            name='department'
            className='form-select form-select-lg form-select-solid'
            onChange={(e) => {
              helpers.setValue(e.target.value)
              setDepartment(e.target.value)
            }}
          >
            <option value=''>Select Department</option>
            {departmentOptions.map((department) => (
              <option key={department.value} value={department.value}>
                {department.label}
              </option>
            ))}
          </Field>
          {/* <KTIcon iconName={'plus-square'} className='fs-2' /> */}
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label'>Designation</label>
        <Field
          type='text'
          className='form-control form-control-lg form-control-solid'
          name='designation'
        />
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label'>Role</label>
        <Field
          as='select'
          name='role'
          value={values?.role}
          className='form-select form-select-lg form-select-solid'
        >
          <option></option>
          {/* {roles[department]?.map((role) => ( */}
          {(values?.department ? roles[values.department] : roles[department])?.map((role) => (
            <option key={role} value={role}>
              {role}
            </option>
          ))}
        </Field>
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label'>Leaves Alloted</label>
        <Field
          type='number'
          className='form-control form-control-lg form-control-solid'
          name='maxLeaves'
        />
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label'>Location</label>
        <div className='d-flex align-items-center'>
          <Field
            as='select'
            name='location'
            className='form-select form-select-lg form-select-solid'
          >
            <option></option>
            <option value='OFFICE'>OFFICE</option>
            <option value='REMOTE'>REMOTE</option>
            <option value='ONSITE'>ONSITE</option>
            <option value='PROJECT'>PROJECT</option>
            <option value='FACTORY'>FACTORY</option>
          </Field>
          {/* <KTIcon iconName={'plus-square'} className='fs-2' /> */}
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label'>Employment Type</label>
        <div className='d-flex align-items-center'>
          <Field
            as='select'
            name='employmentType'
            className='form-select form-select-lg form-select-solid'
            value={values?.employmentType}
          >
            <option></option>
            <option value='Full-Time'>Full-Time</option>
            <option value='Part-Time'>Part-Time</option>
            <option value='Remote'>Remote</option>
            <option value='Contract'>Contract</option>
          </Field>
          {/* <KTIcon iconName={'plus-square'} className='fs-2' /> */}
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label'>Employment Contract</label>
        <div className='d-flex align-items-center'>
          <Field
            as='select'
            name='contractType'
            className='form-select form-select-lg form-select-solid'
            value={values?.contractType}
          >
            <option></option>
            <option value='temporary'>Temporary</option>
            <option value='permanent'>Permanent</option>
            <option value='freelancer'>Freelancer</option>
          </Field>
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label'>Employee Status</label>

        <Field
          as='select'
          name='employeeStatus'
          className='form-select form-select-lg form-select-solid'
        >
          <option></option>
          <option value='Active'>Active</option>
          <option value='Absent'>Inactive</option>
        </Field>
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label  '>Source Of Hire</label>
        <div className='d-flex align-items-center'>
          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='sourceOfHire'
          />

          {/* <KTIcon iconName={'plus-square'} className='fs-2' /> */}
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label  '>Date of Joining</label>

        <input
          type='date'
          name='dateOfJoining'
          // value={
          //   values?.dateOfJoining ? new Date(values.dateOfJoining).toISOString().split('T')[0] : ''
          // }
          value={
            values.dateOfJoining ? new Date(values.dateOfJoining).toISOString().split('T')[0] : ''
          }
          onChange={(e) => setFieldValue('dateOfJoining', e.target.value)}
          className='form-control form-control-md form-control-solid'
        ></input>
      </div>
      <div className='mb-10 fv-row'>
        <label className='form-label mb-3'>Current Experience</label>

        <Field
          type='text'
          className='form-control form-control-lg form-control-solid'
          name='currentExperience'
        />
      </div>
    </div>
  )
}

export {Step2}
