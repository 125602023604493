import React, {useEffect, useRef, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {getRFPById} from '../Core/_requests'
import * as bootstrap from 'bootstrap'
import RFP from '../Components/rfp/RFP'

function RFPDetails() {
  const navigate = useNavigate()
  const [activeComponent, setActiveComponent] = useState('RFPWithMinFooter')
  const [color, setColor] = useState('#ffffff')
  const [isLoading, setIsLoading] = useState(false)
  const [image, setImage] = useState(null)
  const inputRef = useRef(null)

  const [data, setDeliveryNoteData] = useState<any>()
  const {id} = useParams()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getRFPById(id)
        setDeliveryNoteData(response)
      } catch (error) {
        console.log(error)
      }
    }

    fetchData()
  }, [id])

  const switchToComponent = (component) => {
    if (activeComponent !== component) {
      setIsLoading(true) // Set loading state
      setTimeout(() => {
        setActiveComponent(component)
        setIsLoading(false)
        openModal(component) // Turn off loading state
      }, 1000) // Simulating a delay for demonstration purposes
    }
  }

  const openModal = (modalId) => {
    const modal = document.getElementById(modalId)
    if (modal) {
      const modalInstance = new bootstrap.Modal(modal)
      modalInstance.show()
    }
  }

  const InitialModalData = [
    // {
    //     id: 'stacked2',
    //     name: 'Invoice First',
    //     image: '/media/modalImagesInvoice/invoiceFirst/invoiceFirst.png',
    //     activeComponentName: 'Invoice',
    //     Footer: 'Themes 1',
    // },
    {
      id: 'stacked3',
      name: 'RFP',
      image: '/media/modalImagesInvoice/invoiceSecond.png',
      activeComponentName: 'RFPDetails',
      Footer: 'Themes 1',
    },
    {
      id: 'stacked4',
      name: 'Changed Footer',
      image: '/media/modalImagesInvoice/invoiceThird.png',
      activeComponentName: 'InvoiceThird',
      Footer: 'Themes 3',
    },
  ]
  const SecondaryModalData = [
    // {
    //     id: 'stacked2',
    //     data: [
    //         {
    //             activeComponentName: "Invoice",
    //             image: '/media/modalImagesInvoice/invoiceFirst/invoiceFirst.png',
    //         },
    //         {
    //             activeComponentName: "InvoiceArabic",
    //             image: '/media/modalImagesInvoice/invoiceFirst/invoiceArabic.png',

    //         },
    //         {
    //             activeComponentName: "InvoiceMultiLang",
    //             image: '/media/modalImagesInvoice/invoiceFirst/invoiceMultiLang.png',

    //         },
    //     ]
    // },
    {
      id: 'stacked3',
      data: [
        {
          activeComponentName: 'RFPDetails',
          image: '/media/modalImagesInvoice/invoiceSecond.png',
        },
        {
          activeComponentName: 'RFPDetailsArabic',
          image: '/media/modalImagesInvoice/invoiceFirst/invoiceArabic.png',
        },
        {
          activeComponentName: 'RFPDetailsMultiLang',
          image: '/media/modalImagesInvoice/invoiceFirst/invoiceMultiLang.png',
        },
      ],
    },
    {
      id: 'stacked4',
      data: [
        {
          activeComponentName: 'RFPWithMinFooter',
          image: '/media/modalImagesInvoice/invoiceThird.png',
        },
      ],
    },
  ]

  const handlePrint = () => {
    window.print() // This will trigger the browser's print functionality
  }
  const handleImageClick = () => {
    inputRef.current.click()
  }
  const handleUpload = (event) => {
    const file = event.target.files[0]
    setImage(file)
  }

  const toggleEmailComposer = () => {
    navigate('email-composer')
  }

  return (
    <>
      <div id='kt_app_toolbar_container' className='app-container container-xxl d-flex flex-stack '>
        {/*begin::Page title*/}
        <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3 '>
          {/*begin::Title*/}
          <h1 className='page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0'>
            RFP Details
          </h1>
          {/*end::Title*/}
          {/*begin::Breadcrumb*/}
          <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
            {/*begin::Item*/}
            <li className='breadcrumb-item text-muted'>Procurement </li>
            {/*end::Item*/}
            {/*begin::Item*/}
            <li className='breadcrumb-item'>
              <span className='bullet bg-gray-500 w-5px h-2px' />
            </li>
            {/*end::Item*/}
            {/*begin::Item*/}
            <li className='breadcrumb-item text-muted'>RFP Manager</li>
            {/*end::Item*/}
            {/*begin::Item*/}
            <li className='breadcrumb-item'>
              <span className='bullet bg-gray-500 w-5px h-2px' />
            </li>
            {/*end::Item*/}
            {/*begin::Item*/}
            <li className='breadcrumb-item text-muted'>
              <a href='/sales/deliveryChallan' className='text-muted text-hover-primary'>
                RFP
              </a>
            </li>
            {/*end::Item*/}
          </ul>
          {/*end::Breadcrumb*/}
        </div>
        <div className='d-flex gap-5'>
          {data?.approval === 'accepted' && (
            <button
              className='hide-on-pdf btn btn-sm btn-danger print-button mb-8 align-items-right'
              id='kt_delivery_submit_button'
              onClick={handlePrint}
            >
              Print RFP
            </button>
          )}
        </div>
      </div>

      <div id='pdf-content' className='app-content  print-content'>
        {isLoading && <p>Loading...</p>}

        {!isLoading && activeComponent === 'RFPDetails' && (
          <RFP companyLogo={image} color={color} setColor={setColor} minFooter={false} />
        )}
        {/* {!isLoading && activeComponent === 'RFPDetailsMultiLang' && (
          <RFPMultiLang companyLogo={image} color={color} setColor={setColor} />
        )} */}
        {!isLoading && activeComponent === 'RFPWithMinFooter' && (
          <RFP companyLogo={image} color={color} setColor={setColor} minFooter={true} />
        )}
      </div>
    </>
  )
}
export default RFPDetails
