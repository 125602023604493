import React, {useEffect, useState} from 'react'
import {useAuth} from '../../../modules/auth'
import Select from 'react-select'
import Flatpickr from 'react-flatpickr'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import {getAllDepartment, getEmployeeByStatus} from '../../hrm/core/_requests'
import {postDepartmentPayroll} from '../core/_requests'
import {useNavigate} from 'react-router-dom'

const AddDepartmentPayroll = () => {
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [payroll, setPayroll] = useState({
    departmentId: '',
    startDate: new Date(),
    endDate: new Date(),
    month: new Date(),
    notes: '',
  })
  const payrollList = [
    {value: 'full', label: 'Salary Payment'},
    {value: 'advance', label: 'Advance Payment'},
  ]
  const [payrollType, setpayrollType] = useState(null)
  const [dept, setdept] = useState(null)
  const [departmentList, setDepartmentList] = useState([])
  const [employeeList, setEmployeeList] = useState([])
  const [employeeLoading, setEmployeeLoading] = useState(false)

  useEffect(() => {
    const fetchDept = async () => {
      const res = await getAllDepartment(currentUser.organization)
      const temp = res?.map((item, index) => ({
        value: item?._id,
        label: item?.name,
      }))
      setDepartmentList(temp)
    }

    if (currentUser.organization !== undefined) fetchDept()
  }, [currentUser.organization])

  const handlepayrollChange = (selectedOption) => {
    setpayrollType(selectedOption)
  }

  const handledeptChange = async (selectedOption) => {
    setdept(selectedOption)
    if (selectedOption?.value !== null) {
      setEmployeeLoading(true)
      const res = await getEmployeeByStatus(
        `filter_status=true&filter_dept=${selectedOption.value}`,
        currentUser.organization
      )
      const temp = res?.map((item, index) => ({
        employeeName: item?.firstName,
        employeeId: item?._id,
        monthlyPay: item?.monthlyPay || 0,
        overtimePay: item?.overtimePay || 0,
        regular: item?.monthlyPay || 0,
        ot: 0,
        salary: item?.monthlyPay || 0,
        role: item?.role,
      }))
      setEmployeeList(temp)
      setEmployeeLoading(false)
    }
  }

  const handleChange = (e) => {
    const {name, value} = e.target
    setPayroll({...payroll, [name]: value})
  }

  const handleDateChange = (date, name) => {
    setPayroll({...payroll, [name]: date[0]})
  }

  const handleEmployeeChange = (index, field, value) => {
    const updatedEmployeeList = [...employeeList]
    updatedEmployeeList[index][field] = parseFloat(value) || 0

    // Calculate salary
    const employee = updatedEmployeeList[index]
    employee.salary = employee.regular + employee.ot * employee.overtimePay

    setEmployeeList(updatedEmployeeList)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (dept === null) {
      alert('Please select a department')
      return
    }

    setLoading(true)

    const recordedTime = employeeList.filter((item) => item.salary !== 0)

    const data = {
      ...payroll,
      departmentId: dept?.value,
      type: payrollType?.value,
      recordedTime,
      company: currentUser?.company,
      organization: currentUser?.organization,
    }
    try {
      await postDepartmentPayroll(data).then((res) => {
        setLoading(false)
        navigate('/hrm/payroll/department')
      })
    } catch (error) {
      console.error('Error submitting leave application:', error)
    }
  }

  return (
    <div id='kt_app_content' className='app-content flex-column-fluid'>
      <div id='kt_app_content_container' className='app-container container-xxl'>
        <div className='d-flex flex-column flex-lg-row'>
          <div className='flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10'>
            <div className='card'>
              <div className='card-body p-12'>
                <form action='' id='kt_quote_form'>
                  <div className='d-flex flex-column align-items-start flex-xxl-row'>
                    <div className='d-flex align-items-center flex-equal fw-row me-4 order-2'>
                      <div className='d-flex flex-center flex-equal fw-row text-nowrap order-1 order-xxl-2 me-4'>
                        <span className='fs-2x fw-bold text-gray-800'>Add Payroll</span>
                      </div>
                    </div>
                  </div>
                  <div className='separator separator-dashed my-10'></div>
                  <div className='row gx-10'>
                    <div className='col-lg-6'>
                      <div className='mb-3'>
                        <label htmlFor='payroll' className='form-label required'>
                          Payroll Type
                        </label>
                        <Select
                          name='payroll'
                          options={payrollList}
                          onChange={handlepayrollChange}
                          value={payrollType}
                        />
                      </div>
                    </div>
                    <div className='mb-3 col-lg-6'>
                      <label htmlFor='month' className='form-label required'>
                        Select Month
                      </label>
                      <Flatpickr
                        value={payroll.month}
                        onChange={(date) => handleDateChange(date, 'month')}
                        className='form-control'
                        options={{
                          dateFormat: 'F Y',
                          plugins: [
                            monthSelectPlugin({
                              shorthand: true,
                              dateFormat: 'F Y',
                              altFormat: 'F Y',
                            }),
                          ],
                        }}
                      />
                    </div>
                  </div>
                  <div className='row gx-10'>
                    <div className='mb-3 col-6'>
                      <label htmlFor='startDate' className='form-label required'>
                        Start Date
                      </label>
                      <Flatpickr
                        value={payroll.startDate}
                        onChange={(date) => handleDateChange(date, 'startDate')}
                        className='form-control'
                        options={{
                          dateFormat: 'd-m-Y',
                        }}
                        disabled={payrollType?.value === 'projecttimesheet'}
                      />
                    </div>
                    <div className='mb-3 col-6'>
                      <label htmlFor='endDate' className='form-label required'>
                        End Date
                      </label>
                      <Flatpickr
                        value={payroll.endDate}
                        onChange={(date) => handleDateChange(date, 'endDate')}
                        className='form-control'
                        options={{
                          dateFormat: 'd-m-Y',
                        }}
                        disabled={payrollType?.value === 'projecttimesheet'}
                      />
                    </div>
                  </div>
                  <div className='mb-3'>
                    <label htmlFor='notes' className='form-label'>
                      Notes
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      id='notes'
                      name='notes'
                      value={payroll.notes}
                      onChange={handleChange}
                    />
                  </div>
                  <div className='row gx-10'>
                    <div className='col-lg-6'>
                      <div className='mb-3'>
                        <label htmlFor='payroll' className='form-label required'>
                          Department
                        </label>
                        <Select
                          name='department'
                          options={departmentList}
                          onChange={handledeptChange}
                          value={dept}
                        />
                      </div>
                    </div>
                  </div>
                  {employeeLoading ? (
                    <div className='d-flex align-items-center justify-center my-5'>
                      <div className='loader'></div>
                    </div>
                  ) : (
                    <>
                      {/* complete the table */}
                      <div className='overflow-x-auto my-4'>
                        <table className='w-full text-left text-gray-500'>
                          <thead className='text-gray-700 uppercase bg-gray-50'>
                            <tr>
                              <th scope='col' className='px-6 py-3 font-semibold'>
                                Sr. No.
                              </th>
                              <th scope='col' className='px-6 py-3 font-semibold'>
                                Employee Name
                              </th>
                              <th scope='col' className='px-6 py-3 font-semibold'>
                                Role
                              </th>
                              <th scope='col' className='px-6 py-3 font-semibold'>
                                Basic Salary
                              </th>
                              <th scope='col' className='px-6 py-3 font-semibold'>
                                Overtime Hours
                              </th>
                              <th scope='col' className='px-6 py-3 font-semibold'>
                                Salary
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {employeeList.map((employee, index) => (
                              <tr
                                key={employee.employeeId}
                                className='bg-white border-b hover:bg-gray-50'
                              >
                                <td className='px-6 py-4 font-medium text-center text-gray-900'>
                                  {index + 1}
                                </td>
                                <td className='px-6 py-4 font-medium text-gray-900 capitalize'>
                                  {employee.employeeName}
                                </td>
                                <td className='px-6 py-4 capitalize'>{employee.role}</td>
                                <td className='px-6 py-4'>
                                  <input
                                    type='number'
                                    className='w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500'
                                    value={employee.regular}
                                    onChange={(e) =>
                                      handleEmployeeChange(index, 'regular', e.target.value)
                                    }
                                  />
                                </td>
                                <td className='px-6 py-4'>
                                  <input
                                    type='number'
                                    className='w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500'
                                    value={employee.ot}
                                    onChange={(e) =>
                                      handleEmployeeChange(index, 'ot', e.target.value)
                                    }
                                  />
                                </td>
                                <td className='px-6 py-4 font-semibold text-gray-900'>
                                  {currentUser?.baseCurrency} {employee.salary.toFixed(2)}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                  <div className='text-center'>
                    <button
                      type='submit'
                      className='btn btn-primary'
                      disabled={loading}
                      onClick={handleSubmit}
                    >
                      <span className='indicator-label'>
                        {loading ? 'Please wait...' : 'Add Payroll'}
                      </span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddDepartmentPayroll
