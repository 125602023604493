import React from 'react'
import {Link} from 'react-router-dom'
import {KTIcon} from '../../../../_metronic/helpers'

const ParentAccountPage = () => {
  const accountTypes = {
    asset: 'Asset',
    liability: 'Liability',
    equity: 'Equity',
    income: 'Income',
    expense: 'Expense',
  }

  return (
    <div className='card'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Parent Accounts</span>
        </h3>
        <div className='card-toolbar'>
          <Link to='add' className='btn btn-sm btn-light-primary'>
            <KTIcon iconName='plus' className='fs-3' />
            New Parent Account
          </Link>
        </div>
      </div>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted bg-light fs-5'>
                <th className='ps-4 min-w-300px rounded-start'>Account Type</th>
                <th className='min-w-125px rounded-end'>Actions</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(accountTypes).map(([key, value]) => (
                <tr key={key}>
                  <td className='ps-4'>
                    <Link to={`/account/parentaccounts/${key}`}>
                      <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-5'>
                        {value}
                      </span>
                    </Link>
                  </td>
                  <td>
                    <Link
                      to={`/account/parentaccounts/${key}`}
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                      <KTIcon iconName='switch' className='fs-3' />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default ParentAccountPage
