import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import '../print.css'
import {getQuotebyids} from '../../Core/_requests'
import {getCompanyById} from '../../../setting/core/_requests'
import {useAuth} from '../../../../modules/auth'
import {KTIcon} from '../../../../../_metronic/helpers'
import {GoGlobe} from 'react-icons/go'
import {MdOutlineMailOutline} from 'react-icons/md'
import {convertAmountToWords} from '../../../../utils/utils'
import {getCRMQuotebyids} from '../../../crm/core/_requests'
interface IBankDetails {
  bankName: string
  accountNumber: string
  iBANNumber: string
}

const termsNConditionIcons = ['timer', 'dollar', 'shield-tick', 'time', 'truck']

function QuoteSecond({
  companyLogo,
  color,
  setColor,
  minFooter,
  isCRM,
}: {
  companyLogo: any
  color: string
  setColor: any
  minFooter: boolean
  isCRM?: boolean
}) {
  const {currentUser} = useAuth()
  const NUMBERS = new Map([
    [1e9, 'Billion'],
    [1e6, 'Million'],
    [1e3, 'Thousand'],
    [1e2, 'Hundred'],
    [90, 'Ninety'],
    [80, 'Eighty'],
    [70, 'Seventy'],
    [60, 'Sixty'],
    [50, 'Fifty'],
    [40, 'Forty'],
    [30, 'Thirty'],
    [20, 'Twenty'],
    [19, 'Nineteen'],
    [18, 'Eighteen'],
    [17, 'Seventeen'],
    [16, 'Sixteen'],
    [15, 'Fifteen'],
    [14, 'Fourteen'],
    [13, 'Thirteen'],
    [12, 'Twelve'],
    [11, 'Eleven'],
    [10, 'Ten'],
    [9, 'Nine'],
    [8, 'Eight'],
    [7, 'Seven'],
    [6, 'Six'],
    [5, 'Five'],
    [4, 'Four'],
    [3, 'Three'],
    [2, 'Two'],
    [1, 'One'],
    [0, 'Zero'],
  ])

  const numberToWords = (num) => {
    for (const [intValue, textValue] of NUMBERS as any) {
      if (num >= intValue) {
        const prefix = num >= 100 ? numberToWords(Math.trunc(num / intValue)) : ''
        const suffix = num % intValue > 0 ? numberToWords(num % intValue) : ''
        return `${prefix} ${textValue} ${suffix}`.trim()
      }
    }
    return ''
  }

  const convertSARAmountToWords = (amount) => {
    const [riyals, halalas] = amount.toString().split('.').map(Number)
    const riyalsInWords = numberToWords(riyals)
    const halalasInWords = halalas ? numberToWords(halalas.toString().padEnd(2, '0')) : ''

    return `${riyalsInWords} Riyals${halalasInWords ? ` and ${halalasInWords} Halalas` : ''} Only`
  }

  const [vat, setVat] = useState(0)
  const id = useParams().id
  const [data, setdata] = useState([])
  const [showRFQDescription, setShowRFQDescription] = useState(false)
  const [contactPerson, setContactPerson] = useState<any>({})

  const [bank, setBank] = useState<IBankDetails>({
    bankName: '',
    accountNumber: '',
    iBANNumber: '',
  })
  const [page, setPage] = useState(1)
  const [imageSize, setImageSize] = useState<any>(false)

  useEffect(() => {
    const getCompany = async () => {
      const res = await getCompanyById(currentUser.company)
      console.log(res)

      setColor(res?.salesColor)

      setBank({
        bankName: res?.bankName || '',
        accountNumber: res?.accountNumber || '',
        iBANNumber: res?.iBANNumber || '',
      })
    }

    getCompany()
  }, [])

  useEffect(() => {
    const getquotedetails = async () => {
      if (!isCRM) {
        await getQuotebyids(id).then((res) => {
          setdata(res)
          const showRFQ = res[0]?.items.some(
            (item) => item.rfqDescription && item.rfqDescription !== ''
          )
          if (res[0]?.items?.length > 3) {
            const pages = Math.ceil((res[0]?.items.length - 3) / 20)
            if ((res[0]?.items.length - 10) / 20 > 10) {
              setPage(pages + 2)
            } else {
              setPage(pages + 1)
            }
          }
          setShowRFQDescription(showRFQ)
          if (res[0]?.contactPerson !== '') {
            setContactPerson(
              res[0]?.customer?.contactPersons?.find(
                (person) => person?._id === res[0]?.contactPerson
              ) || {}
            )
          } else {
            setContactPerson(res[0]?.customer?.primaryContact)
          }
        })
      } else {
        await getCRMQuotebyids(id).then((res) => {
          setdata(res)
          const showRFQ = res[0]?.items.some(
            (item) => item.rfqDescription && item.rfqDescription !== ''
          )
          if (res[0]?.items?.length > 3) {
            const pages = Math.ceil((res[0]?.items.length - 3) / 20)
            if ((res[0]?.items.length - 10) / 20 > 10) {
              setPage(pages + 2)
            } else {
              setPage(pages + 1)
            }
          }
          setShowRFQDescription(showRFQ)
          if (res[0]?.contactPerson !== '') {
            setContactPerson(
              res[0]?.customer?.contactPersons?.find(
                (person) => person?._id === res[0]?.contactPerson
              ) || {}
            )
          } else {
            setContactPerson(res[0]?.customer?.primaryContact)
          }
        })
      }
    }

    getquotedetails()
  }, [id])

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })
    return formattedDate
  }

  useEffect(() => {
    if (data[0]?.tax) {
      const tax = (data[0]?.subtotal * data[0]?.tax) / 100
      setVat(tax)
    }
  }, [data])

  const handleOnLoad = (e) => {
    const img = e.target
    if (img.naturalWidth > img.naturalHeight) {
      setImageSize(true)
    } else {
      setImageSize(false)
    }
  }

  const PF = 'https://qf-assets.s3.me-central-1.amazonaws.com/'

  const PageContent = Array.from({length: page}).map((_, idx) => (
    <div
      key={idx}
      id={`pdf-content-${idx}`}
      className='card overflow-hidden mx-auto !rounded-none'
      style={{
        height: '297mm',
        width: '210mm',
      }}
    >
      {/* begin::Body */}
      <div id='pdfHeader' className='px-2 mx-3 mt-2'>
        <div className='d-flex justify-content-between align-items-center flex-column flex-sm-row '>
          {/*end::Logo*/}
          <div className='text-sm-end'>
            {/*begin::Logo*/}
            <a href='#' className={`d-block ${imageSize ? 'mw-275px' : 'mw-150px'} ms-sm-auto`}>
              {companyLogo ? (
                <img
                  alt='Logo'
                  src={URL.createObjectURL(companyLogo)}
                  className={imageSize ? 'w-100' : 'w-50'}
                  onLoad={handleOnLoad}
                />
              ) : (
                <img
                  alt='thumbnail'
                  src={PF + data[0]?.company?.companyLogo}
                  className={imageSize ? 'w-100' : 'w-50'}
                  onLoad={handleOnLoad}
                />
              )}
            </a>
            {/*end::Logo*/}
          </div>
          <h4 className='fw-bold text-gray-800 xl:fs-1 d-flex flex-column text-right header-text'>
            <span
              className='mb-1 text-right fs-1 fw-semibold tracking-[1px]'
              style={{
                // fontFamily: 'Rubik',
                fontFamily: 'Scheherazade New',
                fontOpticalSizing: 'auto',
                fontStyle: 'normal',
                wordSpacing: '10px',
              }}
            >
              {data[0]?.company?.arabicName}
            </span>
            <span
              className='mb-1 text-right fs-2 tracking-wider'
              style={{
                color: color,
              }}
            >
              {data[0]?.company?.name}
            </span>
            <span className='fs-5 text-muted'>
              VAT {data[0]?.company?.vat} <span style={{color: color}}>|</span> C.R.{' '}
              {data[0]?.company?.cr}
            </span>
          </h4>
        </div>
      </div>
      <div className='border-b-gray-700 border-b mx-9'></div>
      <div className='card-body px-lg-15 pdf-set pdf-body pb-0'>
        {/* begin::Layout */}

        <div className='d-flex flex-column '>
          {/* begin::Content */}
          <div className='flex-lg-row-fluid mb-10 mb-xl-0'>
            {/* begin::Invoice 1 content */}
            <div className='mt-n1'>
              <div className=''>
                {/*begin::Top*/}
                {idx === 0 && (
                  <div className='mb-6 fs-5 fw-bold text-gray-800'>
                    <div className='uppercase'>Sales Quotation</div>
                  </div>
                )}
                <div className='m-0'>
                  {idx === 0 && (
                    <div
                      className='d-flex justify-content-between flex-column flex-sm-row fs-7 text-black'
                      style={{
                        textTransform: 'uppercase',
                      }}
                    >
                      <div className=' mb-5 text-black'>
                        <div className='d-flex  flex-column mb-5'>
                          <span className='fw-bold '>RFQ NO</span>
                          <span className=''>{data[0]?.rfqNumber}</span>
                        </div>
                        <div className='d-flex flex-column mb-5'>
                          <span className='fw-bold'>Customer</span>

                          <span className=''>{data[0]?.customer?.companyName}</span>
                        </div>
                        <div className='d-flex flex-md-root flex-column mb-5'>
                          <span className='fw-bold mb-1'>Customer Address</span>
                          <span className=' mw-200px'>
                            {data[0]?.customer?.billingAddress.addressLine1}{' '}
                            {data[0]?.customer?.billingAddress.addressLine2}
                            <br />
                            {data[0]?.customer?.billingAddress.city}{' '}
                            {data[0]?.customer?.billingAddress.postalCode}{' '}
                            {data[0]?.customer?.billingAddress.country}
                          </span>
                        </div>
                        <div className='d-flex flex-column mb-5'>
                          <span className='fw-bold'>Contact Number</span>

                          <span className=' text-black'>
                            {data[0]?.customer?.contactNumbers?.mobilePhone}
                          </span>
                        </div>
                      </div>
                      <div className='  mb-5 '>
                        <div className='d-flex  flex-column mb-5'>
                          <span className='fw-bold'>Attention</span>
                          <span className=''>
                            {contactPerson?.salutation} {contactPerson?.firstName}{' '}
                            {contactPerson?.lastName}
                          </span>
                        </div>
                        <div className=' d-flex flex-column mb-5'>
                          <span className='fw-bold'>Designation</span>
                          <span className=''>{contactPerson?.designation}</span>
                        </div>
                        <div className=' d-flex flex-column  '>
                          <span className='fw-bold'>Email ID</span>
                          <span className=''>{contactPerson?.emailAddress}</span>
                        </div>
                      </div>
                      <div className='text-end  mb-5 '>
                        <div className='d-flex flex-column mb-5'>
                          <span className='fw-bold'>Quotation Date</span>

                          <span className=''>{formatCreatedAt(data[0]?.createdAt)}</span>
                        </div>
                        <div className=' d-flex flex-column mb-5'>
                          <span className='fw-bold'>Quotation Number</span>

                          <span className=''>{data[0]?.id}</span>
                        </div>
                        <div className=' d-flex flex-column  '>
                          <span className='fw-bold'>Vendor ID</span>

                          <span className=''></span>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className='flex-grow-1 -mx-5'>
                    {/*begin::Table*/}
                    <div className='table-responsive'>
                      <table
                        className='table table-bordered m-0'
                        style={{
                          borderColor: '#020617',
                        }}
                      >
                        <thead>
                          <tr
                            className='fs-7 fw-bold uppercase'
                            style={{backgroundColor: `${color}`}}
                          >
                            <th className='min-w-25px text-white text-center pb-3 '>
                              <span>
                                <span className='arabic-font'>رقم</span>
                                <br />
                                S.No.
                              </span>
                            </th>
                            <th className='min-w-125px text-white text-center pb-3'>
                              <span className='arabic-font'>وصف</span>
                              <br />
                              Item
                            </th>
                            {showRFQDescription && (
                              <th className='min-w-150px text-white text-center pb-3 '>
                                <span className='arabic-font'>وصف طلب عرض الأسعار</span>
                                <br />
                                RFQ Description
                              </th>
                            )}
                            <th className='min-w-50px text-white text-center pb-3'>
                              <span className='arabic-font'>كمية</span>
                              <br />
                              Qty
                            </th>
                            <th className='min-w-80px text-white text-center pb-3'>
                              <span className='arabic-font'>وحدة</span>
                              <br />
                              UOM
                            </th>
                            <th className='min-w-80px text-white text-center pb-3'>
                              <span className='arabic-font'>سعر الوحدة</span>
                              <br />
                              Unit Price
                            </th>

                            <th className='min-w-80px text-white text-center pb-3'>
                              <span className='arabic-font'>المجموع</span>
                              <br />
                              Total
                            </th>
                          </tr>
                        </thead>
                        <tbody className='' style={{height: '250px'}}>
                          {data[0]?.items
                            ?.slice(
                              idx === 1 || idx === 0 ? idx * 10 : idx * 20 - 10,
                              idx === 0 ? 10 : idx * 20 + 10
                            )
                            .map((item, index) => (
                              <tr
                                key={index + idx}
                                className='fw-semibold text-black fs-8 text-center'
                              >
                                <td className='text-black'>
                                  {idx === 0
                                    ? index + 1
                                    : idx === 1
                                    ? index + 11
                                    : index + idx * 20 - 9}
                                </td>
                                <td className='text-justify text-black'>{item?.productName}</td>
                                {showRFQDescription && (
                                  <td className=' text-center text-black'>
                                    {item?.rfqDescription}
                                  </td>
                                )}
                                <td className='text-black'>{item.quantity}</td>
                                <td className='uppercase text-black'>
                                  {item?.unit ||
                                    item?.itemId?.unit ||
                                    item?.itemsId?.unit ||
                                    'units'}
                                </td>
                                <td className='text-end text-black'>
                                  {Number(item?.price).toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                  })}{' '}
                                </td>
                                <td className='text-end text-black'>
                                  {Number(item?.amount).toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                  })}{' '}
                                </td>
                              </tr>
                            ))}
                          {(idx + 1 === page || page === 1) && (
                            <>
                              <tr className=''>
                                <td colSpan={3} className='p-0 m-0'>
                                  <table className='table table-bordered w-full m-0 p-0 border-transparent'>
                                    <tbody className='min-w-80px '>
                                      <tr className='px-3'>
                                        <td className='text-start flex items-center mt-6 justify-start'>
                                          <div className=''>
                                            <h6 className='mb-3 fw-bolder text-black fs-7'>
                                              BANK DETAILS
                                            </h6>
                                            <div className='mb-2 d-flex '>
                                              <div className='fw-semibold text-black fs-7 w-100px '>
                                                Bank Name:
                                              </div>
                                              <div className='fw-bold text-black fs-8'>
                                                {bank.bankName}
                                              </div>
                                            </div>
                                            <div className='mb-2 d-flex '>
                                              <div className='fw-semibold text-black fs-7 w-100px'>
                                                Account Number:
                                              </div>
                                              <div className='fw-bold text-black fs-8'>
                                                {bank.accountNumber}
                                              </div>
                                            </div>
                                            <div className='mb-2 d-flex'>
                                              <div className='fw-semibold text-black fs-7 w-100px'>
                                                IBAN Number:
                                              </div>
                                              <div className='fw-bold text-black fs-8'>
                                                {bank.iBANNumber}
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                                <td colSpan={2} className='p-0 text-sm'>
                                  <div className='d-flex flex-column justify-between w-100 h-100 uppercase fs-7 text-black'>
                                    <div
                                      className='border-b px-3 py-6 text-right'
                                      style={{
                                        borderColor: '#020617',
                                      }}
                                    >
                                      <p className='mb-0'>Sub Total</p>
                                    </div>
                                    <div
                                      className='border-b px-3 py-6 text-right'
                                      style={{
                                        borderColor: '#020617',
                                      }}
                                    >
                                      <p className='mb-0'>
                                        Total VAT Amount ({data[0]?.tax ?? 0}%)
                                      </p>
                                    </div>
                                    <div className='px-3 py-6 text-right'>
                                      <p className='font-bold mb-0'>Grand Total</p>
                                    </div>
                                  </div>
                                </td>
                                <td colSpan={2} className='p-0 text-sm'>
                                  <div className='d-flex flex-column justify-between w-100 h-100 fs-7 text-black'>
                                    <div
                                      className='border-b px-3 py-6  d-flex justify-between'
                                      style={{
                                        borderColor: '#020617',
                                      }}
                                    >
                                      <p className='pe-0 mb-0'>{data[0]?.customer?.currency}</p>
                                      <p className='text-end ps-0 mb-0'>
                                        {data[0]?.subtotal?.toLocaleString('en-US', {
                                          minimumFractionDigits: 2,
                                        })}{' '}
                                      </p>
                                    </div>
                                    <div
                                      className='border-b px-3 py-6  d-flex justify-between'
                                      style={{
                                        borderColor: '#020617',
                                      }}
                                    >
                                      <p className='mb-0 pe-0'>{data[0]?.customer?.currency}</p>
                                      <p className='mb-0 text-end ps-0'>
                                        {vat?.toLocaleString('en-US', {
                                          minimumFractionDigits: 2,
                                        })}
                                      </p>
                                    </div>
                                    <div className='px-3 py-6  d-flex justify-between'>
                                      <p className='mb-0 pe-0'>{data[0]?.customer?.currency}</p>
                                      <p className='mb-0 text-end ps-0'>
                                        {data[0]?.total?.toLocaleString('en-US', {
                                          minimumFractionDigits: 2,
                                        })}{' '}
                                      </p>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr
                                className='p-0 fs-7'
                                style={{
                                  borderColor: '#020617',
                                }}
                              >
                                <td colSpan={8} className='text-black'>
                                  <span className='fs-7 fw-bold text-black uppercase'>
                                    Total in words
                                  </span>
                                  <br />
                                  <span className='fw-bold'>
                                    {data[0]?.customer?.currency}
                                  </span>{' '}
                                  {/* {numberToWords(data[0]?.total)}
                                  {data[0]?.customer?.currency === 'SAR' && ' Riyals'} Only. */}
                                  {data[0]?.customer?.currency === 'SAR'
                                    ? convertSARAmountToWords(data[0]?.total)
                                    : data[0]?.customer?.currency === 'USD' ||
                                      data[0]?.customer?.currency === 'EUR' ||
                                      data[0]?.customer?.currency === 'INR' ||
                                      data[0]?.customer?.currency === 'QAR' ||
                                      data[0]?.customer?.currency === 'AED'
                                    ? convertAmountToWords(
                                        data[0]?.total,
                                        data[0]?.customer?.currency
                                      )
                                    : `${numberToWords(data[0]?.total)} Only.`}
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                    {/*end::Table*/}
                    {/*begin::Container*/}
                    {/*end::Container*/}
                  </div>
                  {/*end::Content*/}
                </div>
                {/*end::Wrapper*/}
              </div>

              {/* end::Wrapper */}
            </div>
          </div>
          {/* end::Content */}
          {/* begin::Sidebar */}
          {(idx === page - 1 || page === 1) && (
            <div className={`m-0  p-2`}>
              <div className='d-flex  flex-column '>
                <h6 className=' fw-bold text-danger fs-7'>Terms</h6>
                <p
                  className=' fs-7 mb-0 text-black'
                  style={{
                    lineHeight: '8px',
                  }}
                >
                  {data[0]?.termsNCondition?.trim()?.length > 0 &&
                    data[0]?.termsNCondition?.split('\n').map((line, index) => {
                      // ;<p key={index}>
                      //   <KTIcon
                      //     iconName={`${termsNConditionIcons[index]}`}
                      //     className='mr-1'
                      //     iconType='solid'
                      //     style={{
                      //       color: color,
                      //     }}
                      //   />
                      //   {line}
                      // </p>
                      const [firstSentence, secondSentence] = line.split(':')
                      return (
                        <div
                          key={index}
                          style={{display: 'flex', width: '100%', marginBottom: '5px'}}
                        >
                          <span
                            style={{
                              width: '150px',
                              whiteSpace: 'nowrap',
                              // overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                            className='fw-semibold mb-1'
                          >
                            <KTIcon
                              iconName={`${termsNConditionIcons[index]}`}
                              className='mr-1'
                              iconType='solid'
                              style={{
                                color: color,
                              }}
                            />
                            {firstSentence}
                          </span>
                          <span
                            style={{flex: 1, marginLeft: '5px'}}
                            className='d-flex align-items-center'
                          >
                            {secondSentence}
                          </span>
                        </div>
                      )
                    })}
                </p>

                <h6 className='mt-5 fw-bold text-danger fs-7'>Notes</h6>
                <p
                  className='fs-7 text-black'
                  style={{
                    lineHeight: '8px',
                  }}
                >
                  {data[0]?.notes?.split('\n').map((line, index) => (
                    <p key={index}>{line}</p>
                  ))}
                </p>
              </div>
              {/* end::Invoice 2 sidebar */}
            </div>
          )}
          {/* end::Sidebar */}
        </div>
        {/* end::Layout */}
      </div>
      {/* end::Body */}
      <div id='pdfFooter' className='card-footer text-center py-2 px-0 fw-bold mb-1'>
        <div className={`bg-[#515560] ${!minFooter && '-skew-x-12'} text-white`}>
          <div className={`${!minFooter && 'skew-x-12'} py-1`}>
            {!minFooter && `Mobile : ${data[0]?.company?.mobileNumber} | `}
            <MdOutlineMailOutline
              style={{
                color,
              }}
              className='inline mr-2'
            />
            {data[0]?.company?.companyEmail}
            <GoGlobe
              className='inline mr-2 ml-4'
              style={{
                color,
              }}
            />
            {data[0]?.company?.webURL}
          </div>
        </div>
        <div
          className={`${!minFooter && '-skew-x-12'} text-white `}
          style={{
            backgroundColor: `${color}`,
          }}
        >
          <div className={`${!minFooter && 'skew-x-12'} py-1`}>
            P.O. Box : {data[0]?.company?.pOBox} | Address: {data[0]?.company?.companyAddress}
          </div>
        </div>
      </div>
      <div className='d-flex justify-center'>
        <p className='fs-7 serif-font mb-1'>
          Page {idx + 1} of {page}
        </p>
      </div>
    </div>
  ))

  return (
    <>
      <div className='app-content flex-column-fluid '>
        {/* begin::Content container */}

        <div id='kt_app_content_container' className='print-content container-xxl serif-font'>
          {/* begin::Invoice 2 main */}

          {
            // render below div page number of times
            PageContent
          }
          {/* end::Invoice 2 main */}
        </div>
        {/* end::Content container */}
      </div>
    </>
  )
}

export default QuoteSecond
