import React, {useCallback, useEffect, useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'
import {KTIcon} from '../../../../_metronic/helpers'
import {
  bulkUploadExpenses,
  getExpensesWithDate,
  updateExpenseApprove,
  updateExpenseReject,
} from '../core/_requests'
import {useSelector, useDispatch} from 'react-redux'
import {
  setDateRange,
  setExpenseData,
  setLoading,
} from '../../../redux/accounts/expenses/expenseSlice'
import DateFilter from '../components/DateFilter'
import {RootState} from '../../../redux/store'
import {RiArrowDropDownLine} from 'react-icons/ri'
import useExcelDownload from '../components/useExcelDownload'
import {Spinner} from 'react-bootstrap'
import debounce from 'lodash/debounce'
import useExcelImport from '../components/useExcelImport'

function ExpensePage() {
  const {currentUser} = useAuth()
  const excelfileRef = useRef<HTMLInputElement>(null)
  const [permissions, setPermissions] = useState<any>({})
  const initialRef = useRef(true)
  const dispatch = useDispatch()
  const expenseList = useSelector((state: RootState) => state.account.expense.expenseData)
  const dateRange = useSelector((state: RootState) => state.account.expense.dateState)
  const loading = useSelector((state: RootState) => state.account.expense.loading)
  const [dropdown, setDropdown] = useState(Array(expenseList?.length)?.fill(false))
  const [dateState, setDateState] = useState<any>(dateRange)
  const [searchquery, setSearchquery] = useState('')
  const [downloadExcelLoading, setDownloadExcelLoading] = useState(false)
  const downloadExcel = useExcelDownload()
  const [excelImportLoading, setExcelImportLoading] = useState(false)
  const handleUploadExcel = useExcelImport()

  useEffect(() => {
    if (currentUser) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const fetchExpenseList = useCallback(async () => {
    dispatch(setLoading())
    try {
      const res = await getExpensesWithDate(
        currentUser?.organization,
        dateState.startDate,
        dateState.endDate,
        searchquery
      )
      dispatch(setExpenseData(res))
      setDropdown(Array(res?.length)?.fill(false))
      // dispatch(
      //   setDateRange({
      //     startDate: new Date(dateState.startDate).toISOString(),
      //     endDate: new Date(dateState.endDate).toISOString(),
      //   })
      // )
    } catch (error) {
      console.error('Error fetching expense list:', error)
    }
  }, [currentUser?.organization, dispatch, dateState, searchquery])

  const handleClick = (index) => {
    const newDropdownState = dropdown.map((_, i) => (i === index ? !dropdown[i] : false))
    setDropdown(newDropdownState)
  }

  const handleExcelDownload = () => {
    const excelData = expenseList?.map((item, index) => ({
      ...item,
      _id: index + 1,
      expenseAccount: item?.expenseAccount?.accountName || '',
      paidThrough: item?.paidThrough?.accountName || '',
      vendor: item?.vendor?.displayName || '',
      customer: item?.customer?.displayName || '',
      date: item?.date ? new Date(item.date).toISOString().split('T')[0] : '',
    }))
    downloadExcel(excelData, 'expenses.xlsx', setDownloadExcelLoading)
  }

  useEffect(() => {
    if (loading) {
      fetchExpenseList()
    }
  }, [currentUser.organization])

  const debounceSearch = debounce(fetchExpenseList, 500)

  useEffect(() => {
    if (initialRef.current) {
      initialRef.current = false
      return
    }

    if (dateState.startDate && dateState.endDate) {
      debounceSearch()
    }
    return () => debounceSearch?.cancel()
  }, [dateState.endDate, fetchExpenseList, searchquery])

  const handleApproved = async (id, index) => {
    const res = await updateExpenseApprove(id)
    if (res) {
      const updatedData = expenseList.map((item, idx) =>
        idx === index ? {...item, approval: 'accepted'} : item
      )
      dispatch(setExpenseData(updatedData))
    }
  }

  const handleReject = async (id, index) => {
    const res = await updateExpenseReject(id)
    if (res) {
      const updatedData = expenseList.map((item, idx) =>
        idx === index ? {...item, approval: 'rejected'} : item
      )
      dispatch(setExpenseData(updatedData))
    }
  }

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    return new Date(createdAt).toLocaleDateString('en-GB')
  }

  return (
    <div className='card card-flush'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-center d-flex gap-4'>
          <span className='card-label fw-bold fs-3 mb-1'>Expenses</span>
          <input
            type='text'
            className='form-control form-control-solid me-3'
            value={searchquery}
            placeholder='Search Reference'
            onChange={(e) => setSearchquery(e.target.value)}
          />
          <DateFilter dateState={dateState} setDateState={setDateState} />
        </h3>

        <div className='flex items-center ml-auto mr-4'>
          <button className='btn btn-primary' onClick={handleExcelDownload}>
            {downloadExcelLoading ? (
              <>
                <Spinner animation='border' role='status' />
              </>
            ) : (
              'Download Excel'
            )}
          </button>
        </div>

        {(currentUser?.hierarchy === 1 || currentUser?.profileType === 'superadmin') && (
          <div className='flex items-center mr-4'>
            <button
              className='btn btn-danger'
              onClick={() => {
                excelfileRef.current.click()
              }}
            >
              {excelImportLoading ? (
                <>
                  <Spinner animation='border' role='status' />
                </>
              ) : (
                'Import Expenses'
              )}
            </button>
            <input
              type='file'
              accept='.xlsx, .xls'
              onChange={(e) =>
                handleUploadExcel(e, bulkUploadExpenses, setExcelImportLoading, 'expenses')
              }
              ref={excelfileRef}
              className='hidden'
            />
          </div>
        )}

        {(currentUser?.profileType === 'superadmin' || permissions?.expenses?.create) && (
          <div className='card-toolbar'>
            <Link to='/account/expenses/add' className='btn btn-light-primary'>
              <KTIcon iconName='plus' className='fs-2' />
              Add Expense
            </Link>
          </div>
        )}
      </div>
      <div className='card-body py-3'>
        {loading ? (
          <div className='d-flex align-items-center justify-content-center'>
            <div className='loader'></div>
          </div>
        ) : (
          <div className='table-responsive min-h-500px'>
            <table className='table align-middle gs-0 gy-4 table-row-bordered'>
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-50px rounded-start'>Date</th>
                  <th className='min-w-150px'>Expense Account</th>
                  <th className='min-w-150px'>Reference#</th>
                  {/* <th className='min-w-150px'>Vendor</th> */}
                  <th className='min-w-100px'>Paid Through</th>
                  {/* <th className='min-w-100px'>Customer</th> */}
                  <th className='min-w-100px'>Amount</th>
                  <th className='min-w-100px text-center'>Approval</th>
                  {(currentUser.profileType === 'superadmin' || permissions?.expenses?.update) && (
                    <th className='min-w-100px text-center'>Actions</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {expenseList?.map((item: any, index: number) => (
                  <tr key={item?._id?.toString()}>
                    <td>
                      <Link
                        to={`${item?._id}`}
                        className='ps-4 text-dark fw-bold d-block mb-1 fs-6'
                      >
                        {formatCreatedAt(item?.date)}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`${item?._id}`}
                        className='text-dark fw-bold d-block mb-1 fs-6 capitalize'
                      >
                        {item?.expenseAccount?.accountName}
                      </Link>
                    </td>
                    <td>
                      <Link to={`${item?._id}`} className='text-dark fw-bold d-block mb-1 fs-6'>
                        {item?.reference}
                      </Link>
                    </td>
                    {/* <td>
                      <span className='text-dark fw-bold d-block mb-1 fs-6'>
                        {item?.vendor?.displayName}
                      </span>
                    </td> */}
                    <td>
                      <Link to={`${item?._id}`} className='text-dark fw-bold d-block mb-1 fs-6'>
                        {item?.paidThrough?.accountName}
                      </Link>
                    </td>
                    {/* <td>
                      <span className='text-dark fw-bold d-block mb-1 fs-6'>
                        {item?.customer?.displayName}
                      </span>
                    </td> */}
                    <td>
                      <Link to={`${item?._id}`} className='text-dark fw-bold d-block mb-1 fs-6'>
                        {item?.amount.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                        })}{' '}
                        {/* {item?.customer?.currency} */}
                      </Link>
                    </td>
                    <td>
                      <div className='text-dark text-center fw-bold text-hover-primary d-block mb-1 fs-6'>
                        <span
                          className={`badge  fs-7 fw-semibold ${
                            item?.approval === 'pending'
                              ? 'badge-light-primary'
                              : item?.approval === 'accepted'
                              ? 'badge-light-success'
                              : 'badge-light-danger'
                          }`}
                        >
                          {item?.approval}
                        </span>
                      </div>
                    </td>
                    {(permissions?.expenses?.update ||
                      currentUser?.profileType === 'superadmin') && (
                      <td className='relative'>
                        <div className='d-flex justify-content-center flex-shrink-0'>
                          <button
                            onClick={() => handleClick(index)}
                            className=' bg-gray-100 hover:bg-lime-50 px-6 py-2 flex items-center rounded self-end'
                          >
                            Actions <RiArrowDropDownLine />
                          </button>
                        </div>
                        {dropdown[index] && (
                          <div className='absolute flex flex-col top-16 right-[0px] z-50  bg-white  w-40 shadow-[0_16px_16px_6px_rgba(128,128,128,0.1)] rounded p-3 space-y-2'>
                            {/* <Link to={`/hrm/payroll/edit/${item._id}`}>
                              <button className='hover:bg-lime-50 p-2  text-start rounded'>
                                Edit
                              </button>
                            </Link> */}
                            {(item?.approval === 'pending' || item?.approval === 'rejected') && (
                              <button
                                className='hover:bg-green-50 p-2 text-start rounded'
                                onClick={() => handleApproved(item._id, index)}
                              >
                                Approve
                              </button>
                            )}
                            {(item?.approval === 'pending' || item?.approval === 'accepted') && (
                              <button
                                className='hover:bg-red-50 p-2 text-start rounded'
                                onClick={() => handleReject(item._id, index)}
                              >
                                Reject
                              </button>
                            )}
                          </div>
                        )}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  )
}

export default ExpensePage
