import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'
import {getVendor} from '../Core/_requests'
import {setVendorDataList} from '../../../redux/procurement/vendor/vendorSlice'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../redux/store'

const VendorStatementPage = () => {
  const {currentUser} = useAuth()
  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const vendorDataList = useSelector((state: RootState) => state.procurement.vendor.vendorData)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      const res = await getVendor('', currentUser.organization)
      setData(res)
      dispatch(setVendorDataList(data))
      setLoading(false)
    }
    if (vendorDataList?.length === 0 && currentUser.organization !== undefined) {
      fetchData()
    } else {
      setData(vendorDataList)
      setLoading(false)
    }
  }, [currentUser.organization, vendorDataList])

  return (
    <div className='card'>
      <div className='card-header d-flex align-items-center'>
        <h3>Statement of Vendors</h3>
      </div>
      <div className='card-body'>
        <div className='table-responsive'>
          {loading ? (
            <div className='d-flex align-items-center justify-center'>
              <div className='loader'></div>
            </div>
          ) : (
            <table className='table align-middle gs-0 gy-4 table-row-bordered'>
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 rounded-start'>Sr. No.</th>
                  <th className='rounded-start'>Vendor</th>
                  <th>Company Name</th>
                  <th>Contact Number</th>
                  <th>Vendor Type</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item: any, index: number) => (
                  <tr key={item._id}>
                    <td className='text-dark fw-bold text-hover-primary mb-1 fs-6 ps-4'>
                      {index + 1}
                    </td>
                    <td>
                      <Link
                        to={`${item._id}`}
                        className='text-dark fw-bold text-hover-primary mb-1 fs-6 capitalize'
                      >
                        {item?.displayName}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`${item._id}`}
                        className='text-dark fw-bold text-hover-primary mb-1 fs-6 capitalize'
                      >
                        {item?.companyName}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`${item._id}`}
                        className='text-dark fw-bold text-hover-primary mb-1 fs-6 capitalize'
                      >
                        {item?.contactNumbers?.mobilePhone || item?.contactNumbers?.workPhone}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`${item._id}`}
                        className='text-dark fw-bold text-hover-primary mb-1 fs-6 capitalize'
                      >
                        {item?.vendorType}
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  )
}

export default VendorStatementPage
