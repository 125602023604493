import {FC, useEffect, useRef, useState} from 'react'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'
import {ICreateAccount, createAccountSchemas, inits} from './AddProjectHelper'
import {Form, Formik, FormikValues} from 'formik'
import {KTIcon} from '../../../../_metronic/helpers'
import {ProjectType} from './steps/ProjectType'
import {ProjectSettings} from './steps/ProjectSettings'
import {AddBudget} from './steps/AddBudget'
import {AddTarget} from './steps/AddTarget'
import {Completed} from './steps/Completed'
import {postProject} from '../core/_requests'
import AddTeam from './steps/AddTeam'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'
import AssignUser from './steps/AssignUser'
import RentalProductAssign from './steps/RentalProductAssign'
// import { Completed } from './steps/Completed'
import {useDispatch} from 'react-redux'
import {addProject} from '../../../redux/projects/projectSlice'
import ConsumablesProductAssign from './steps/ConsumablesProductAssign'
import MaterialsProductAssign from './steps/MaterialsProductAssign'

const AddProject: FC = () => {
  const {currentUser} = useAuth()
  const dispatch = useDispatch()
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0])
  const [initValues] = useState<ICreateAccount>(inits)
  const [isSubmitButton, setSubmitButton] = useState(false)

  const navigate = useNavigate()

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])

    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totalStepsNumber)
  }

  const submitStep = async (values: ICreateAccount, actions: FormikValues) => {
    console.log('values', values)
    console.log('Continue button')
    if (!stepper.current) {
      console.log('value of stepper is', stepper.current)
      return
    }

    if (stepper.current.currentStepIndex !== stepper.current.totalStepsNumber) {
      stepper.current.goNext()
    } else {
      const body = {
        ...values,
        items: values.materials.concat(values.rentals, values.consumables),
        organization: currentUser?.organization,
        company: currentUser?.company,
      }
      console.log('value', values)
      const data = await postProject(body)
      console.log('submited', data)
      dispatch(addProject(data))
      navigate('/project/projectlist')
      // stepper.current.goto(1)

      actions.resetForm()
    }

    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totalStepsNumber)

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  return (
    <div className='card'>
      <div className='card-body'>
        <div
          ref={stepperRef}
          className='stepper stepper-links d-flex flex-column pt-15'
          id='kt_create_account_stepper'
        >
          <div className='stepper-nav mb-5'>
            <div className='stepper-item current' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Project Type</h3>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Project Settings</h3>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Budget</h3>
            </div>

            {currentUser?.app?.inventory && (
              <>
                {currentUser.inventoryFeatures.materials && (
                  <div className='stepper-item' data-kt-stepper-element='nav'>
                    <h3 className='stepper-title'>Add Materials</h3>
                  </div>
                )}

                {currentUser.inventoryFeatures.consumables && (
                  <div className='stepper-item' data-kt-stepper-element='nav'>
                    <h3 className='stepper-title'>Add Consumables</h3>
                  </div>
                )}

                {currentUser.inventoryFeatures.rentals && (
                  <div className='stepper-item' data-kt-stepper-element='nav'>
                    <h3 className='stepper-title'>Add Equipments</h3>
                  </div>
                )}
              </>
            )}

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Build a Team</h3>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Assign Users</h3>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Completed</h3>
            </div>
          </div>

          <Formik
            validationSchema={currentSchema}
            initialValues={initValues}
            onSubmit={submitStep}
            enableReinitialize
          >
            {() => (
              <Form className='mx-auto mw-800px w-100 pt-15 pb-10' id='kt_create_account_form'>
                <div className='current' data-kt-stepper-element='content'>
                  <ProjectType />
                </div>

                <div data-kt-stepper-element='content'>
                  <ProjectSettings />
                </div>

                <div data-kt-stepper-element='content'>
                  <AddBudget />
                </div>

                {currentUser?.app?.inventory && (
                  <>
                    {currentUser.inventoryFeatures.materials && (
                      <div data-kt-stepper-element='content'>
                        <MaterialsProductAssign />
                      </div>
                    )}

                    {currentUser.inventoryFeatures.consumables && (
                      <div data-kt-stepper-element='content'>
                        <ConsumablesProductAssign />
                      </div>
                    )}

                    {currentUser.inventoryFeatures.rentals && (
                      <div data-kt-stepper-element='content'>
                        <RentalProductAssign />
                      </div>
                    )}
                  </>
                )}

                <div data-kt-stepper-element='content'>
                  <AddTeam />
                </div>

                <div data-kt-stepper-element='content'>
                  <AssignUser />
                </div>

                <div data-kt-stepper-element='content'>
                  <Completed />
                </div>

                <div className='d-flex flex-stack pt-10 '>
                  <div className='mr-2'>
                    {stepper.current?.currentStepIndex > 0 && (
                      <button
                        onClick={prevStep}
                        type='button'
                        className='btn btn-lg btn-light-primary me-3'
                        data-kt-stepper-action='previous'
                      >
                        <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                        Previous
                      </button>
                    )}
                  </div>

                  <div>
                    {stepper.current?.currentStepIndex !==
                      6 + (currentUser?.app?.inventory ? 3 : 0) && (
                      <button type='submit' className='btn btn-lg btn-primary me-3'>
                        <span className='indicator-label'>Continue</span>
                        <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                      </button>
                    )}

                    {stepper.current?.currentStepIndex ===
                      6 + (currentUser?.app?.inventory ? 3 : 0) && (
                      <button type='submit' className='btn btn-lg btn-primary me-3'>
                        <span className='indicator-label'>Submit</span>
                        <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                      </button>
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export {AddProject}
