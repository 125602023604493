import React, {ChangeEvent, useEffect, useRef, useState} from 'react'
import {KTIcon, KTSVG} from '../../../../_metronic/helpers'
import {Card} from 'react-bootstrap'
import {DragDropContext, Droppable, Draggable, DropResult} from 'react-beautiful-dnd'
import {useParams} from 'react-router-dom'
import DatePicker from 'react-datepicker'
import Swal from 'sweetalert2'
import {changeTargetStatus, deleteTargetById, getTargetById} from '../core/_requests'
import TargetEdit from './TargetEdit'
import {useAuth} from '../../../modules/auth'

interface TaskItem {
  id: string
  title: string
  items: any[] // Adjust the type according to your data structure
}

const TargetPage: React.FC = ({task, getTargets}: any) => {
  // const params = useParams()
  // const projectid = params.id
  const {currentUser} = useAuth()
  const [data, setData] = useState<TaskItem[]>([])
  // const [task, setTask] = useState<any>()
  const [showModal, setShowModal] = useState(false)
  const [currentTaskData, setCurrentTaskData] = useState<any>({})
  const editTodo = useRef(null)
  const [editTaskId, setEditTaskId] = useState<string>('')
  const [formData, setFormData] = useState({
    title: '',
    assignedTo: [],
    user: '',
    startDate: new Date(),
    dueDate: new Date(),
    targetDetails: '',
    tags: '',
    targetBudget: 0,
    targetNotifications: {
      email: false,
      phone: false,
    },
    hoursPerDay: 0,
    items: [],
  })

  useEffect(() => {
    if (task) {
      const initialData: TaskItem[] = [
        {
          id: 'new',
          title: 'Not Started',
          items: task.new || [],
        },
        {
          id: 'inprogress',
          title: 'In Progress',
          items: task.inprogress || [],
        },
        {
          id: 'done',
          title: 'Done',
          items: task.done || [],
        },
      ]
      setData(initialData)
    }
  }, [task])

  // const fetchTasks = async () => {
  //   const res = await getTargetByProjectId(projectid)
  //   console.log(res)
  //   const tasknew = res.filter((task: any) => task.status === 'new')
  //   const taskinprogress = res.filter((task: any) => task.status === 'inprogress')
  //   const taskdone = res.filter((task: any) => task.status === 'done')
  //   const tasks = {
  //     new: tasknew,
  //     inprogress: taskinprogress,
  //     done: taskdone,
  //   }
  //   setTask(tasks)
  // }

  // useEffect(() => {
  //   fetchTasks()
  // }, [])

  const handleDateChange = (date) => {
    setFormData({
      ...formData,
      dueDate: date,
    })
  }

  const handleDatePickerClick = (e) => {
    // Prevent the click event from propagating to parent elements
    e.stopPropagation()
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    e.preventDefault()
    const {name, value} = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  //   const handleEdit = async () => {
  //     const res = await editTask(data, editTaskId)
  //     if (res) {
  //       setFormData({
  //         title: '',
  //         targetDetails: '',
  //         dueDate: new Date(),
  //         tags: '',
  //         targetBudget: 0,
  //         targetNotifications: {
  //           email: false,
  //           phone: false,
  //         },
  //         assignedTo: '',
  //       })
  //       setEditTaskId('')
  //       fetchTasks()
  //       const closeModalButton3 = document.getElementById('closeModalButton3')
  //       closeModalButton3?.click()
  //     }
  //   }

  const handleDeleteEvent = () => {
    handleCloseModal()
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
    }).then(async (res) => {
      if (res.value) {
        const resp = await deleteTargetById(currentTaskData?._id)
        if (resp) {
          // fetchTasks()
          getTargets()
          Swal.fire('Deleted!', 'Target has been deleted.', 'success')
        }
      }
    })
  }

  const handleEditEvent = () => {
    handleCloseModal()
    setEditTaskId(currentTaskData?._id)
    setFormData({
      title: currentTaskData?.title,
      targetDetails: currentTaskData?.targetDetails,
      dueDate: new Date(currentTaskData?.dueDate),
      tags: currentTaskData?.tags,
      targetBudget: currentTaskData?.targetBudget,
      targetNotifications: {
        email: currentTaskData?.targetNotifications?.email,
        phone: currentTaskData?.targetNotifications?.phone,
      },
      hoursPerDay: currentTaskData?.hoursPerDay,
      startDate: new Date(currentTaskData?.startDate),
      assignedTo: currentTaskData?.assignedTo,
      user: currentTaskData?.user?._id,
      items: currentTaskData?.items,
    })
    Swal.fire({
      title: 'Edit Target ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Edit it!',
      cancelButtonText: 'No, cancel!',
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
    }).then((res) => {
      if (res.value) {
        editTodo.current.click()
      }
    })
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return

    const {source, destination, draggableId} = result

    if (source.droppableId === destination.droppableId) {
      // Reorder items within the same card
      const cardIndex = data.findIndex((card) => card.id === source.droppableId)
      const items = [...data[cardIndex].items]
      const [removedItem] = items.splice(source.index, 1)
      items.splice(destination.index, 0, removedItem)

      const updatedData = [...data]
      updatedData[cardIndex].items = items
      setData(updatedData)
    } else {
      // Move item to a different card
      const sourceCardIndex = data.findIndex((card) => card.id === source.droppableId)
      const destCardIndex = data.findIndex((card) => card.id === destination.droppableId)
      const sourceItems = [...data[sourceCardIndex].items]
      const destItems = [...data[destCardIndex].items]
      const [movedItem] = sourceItems.splice(source.index, 1)
      destItems.splice(destination.index, 0, movedItem)

      const updatedData = [...data]
      updatedData[sourceCardIndex].items = sourceItems
      updatedData[destCardIndex].items = destItems
      setData(updatedData)
      console.log(movedItem._id)
      changeTargetStatus(data[destCardIndex].id, movedItem._id)
    }
  }

  const formatDueTime = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })
    return formattedDate
  }

  const handleShowModal = async (key) => {
    setShowModal(true)
    const res = await getTargetById(key)
    console.log(res)
    setCurrentTaskData(res)
  }

  const handleCloseModal = () => {
    setShowModal(false)
    setCurrentTaskData({})
  }

  const handleWindowClick = (e) => {
    const nearestTask = e.target.closest('#currentTask')
    if (nearestTask) {
      const elem = nearestTask.dataset.currenttask
      handleShowModal(elem)
    }
  }

  return (
    <div>
      <button
        type='button'
        data-bs-toggle='modal'
        data-bs-target='#kt_modal_1234'
        ref={editTodo}
      ></button>
      <div className='m-4' onClick={handleWindowClick}>
        <DragDropContext onDragEnd={onDragEnd}>
          <div className='row g-5 g-xl-10 mb-5 mb-xl-10 w-100'>
            {data?.map((card) => (
              <div className='col-xl-4 mb-4' key={card.id}>
                <Card
                  className={`bg-light  w-100 ${
                    card.title === 'Not Started'
                      ? 'bg-light-primary  border border-primary'
                      : card.title === 'In Progress'
                      ? 'bg-light-warning border border-warning'
                      : card.title === 'Done'
                      ? 'bg-light-success border border-success'
                      : 'bg-light-danger border border-danger'
                  }`}
                  style={{width: '19rem'}}
                >
                  {/* <Card.Header> */}
                  <div
                    className={`p-2  m-2  ${
                      card.title === 'Not Started'
                        ? 'bg-light-primary '
                        : card.title === 'In Prrogress'
                        ? 'bg-light-warning'
                        : card.title === 'Done'
                        ? 'bg-light-success'
                        : 'bg-light-danger'
                    }`}
                  >
                    <div
                      className={`capitalize font-semibold flex justify-center items-center  text-xl ${
                        card.title === 'Not Started'
                          ? 'text-primary'
                          : card.title === 'In Progress'
                          ? 'text-warning'
                          : card.title === 'Done'
                          ? 'text-success'
                          : 'text-danger'
                      } `}
                    >
                      {card.title}
                    </div>
                  </div>
                  {/* </Card.Header> */}
                  <Droppable droppableId={card.id} type='ITEM'>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {card?.items.map((item, index) => (
                          <Draggable key={item?._id} draggableId={item?._id} index={index}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div
                                  className=' m-3 my-5'
                                  id='currentTask'
                                  data-currenttask={item?._id}
                                >
                                  <div className=' text-md font-light bg-white rounded-lg p-3 leading-none px-3'>
                                    <h4 className='text-black capitalize'>{item?.title}</h4>
                                    {item?.targetDetails && (
                                      <p className='text-black'>{item?.targetDetails}</p>
                                    )}
                                    <p className=' text-black uppercase'>
                                      TAGS:
                                      <span className={`badge badge-light-${item?.tagColor}`}>
                                        {item?.targetTags}
                                      </span>
                                    </p>
                                    <p className=' text-black'>
                                      Due: {formatDueTime(item?.dueDate)}
                                    </p>
                                    <p className=' text-black uppercase'>
                                      Priority:
                                      <span
                                        className={`badge ${
                                          item?.tags === 'important'
                                            ? 'badge-light-danger'
                                            : item?.tags === 'medium'
                                            ? 'badge-light-warning'
                                            : 'badge-light-success'
                                        }`}
                                      >
                                        {item?.tags}
                                      </span>
                                    </p>
                                    {item?.assignedTo?.length > 0 && (
                                      <p className='text-black truncate'>
                                        <span className='text-black'>
                                          Assigned To:{' '}
                                          {item?.assignedTo?.map((agent, index) => (
                                            <span
                                              key={agent._id}
                                              className='text-black fw-semibold'
                                            >
                                              {agent?.firstName}
                                              {index < item.assignedTo.length - 1 && ', '}
                                            </span>
                                          ))}
                                        </span>
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </Card>
              </div>
            ))}
          </div>
        </DragDropContext>
      </div>
      {showModal && (
        <div className='modal fade show' tabIndex={-1} role='dialog' style={{display: 'block'}}>
          <div className='modal-dialog' role='document'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title capitalize'>
                  {/* {calendarEventModal?._def?.extendedProps?.client !== null ? 'Meeting' : 'Task'} */}
                </h5>
                <button type='button' className='close' onClick={handleCloseModal}>
                  <span>&times;</span>
                </button>
              </div>
              <div className='modal-body'>
                <div className='table-responsive'>
                  <table className='table'>
                    <tbody>
                      <tr>
                        <td>Title</td>
                        <td>
                          <strong className='capitalize'>{currentTaskData?.title}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>Description</td>
                        <td>
                          <strong>{currentTaskData?.targetDetails}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>Start date</td>
                        <td>
                          <strong>{formatDueTime(currentTaskData?.startDate)}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>Due date</td>
                        <td>
                          <strong>{formatDueTime(currentTaskData?.dueDate)}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>Created date</td>
                        <td>
                          <strong>{formatDueTime(currentTaskData?.createdAt)}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>Assignee</td>
                        <td>
                          <strong>
                            {currentTaskData?.assignedTo
                              ?.map((agent) => agent?.firstName)
                              .join(', ')}
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>User</td>
                        <td>
                          <strong>{currentTaskData?.user?.fullName}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>Budget</td>
                        <td>
                          <strong>{currentTaskData?.targetBudget}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>Status</td>
                        <td>
                          <strong
                            className={`badge uppercase ${
                              currentTaskData?.status === 'inprogress'
                                ? 'badge-light-warning'
                                : currentTaskData?.status === 'new'
                                ? 'badge-light-primary'
                                : 'badge-light-success'
                            }`}
                          >
                            {currentTaskData?.status === 'new'
                              ? 'NOT STARTED'
                              : currentTaskData?.status === 'inprogress'
                              ? 'IN PROGRESS'
                              : 'DONE'}
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>Tags</td>
                        <td>
                          <strong
                            className={`badge uppercase badge-light-${currentTaskData?.tagColor}`}
                          >
                            {currentTaskData?.targetTags}
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>Priority</td>
                        <td>
                          <strong
                            className={`badge uppercase ${
                              currentTaskData?.tags === 'medium'
                                ? 'badge-light-warning'
                                : currentTaskData?.tags === 'flexible'
                                ? 'badge-light-primary'
                                : 'badge-light-success'
                            }`}
                          >
                            {currentTaskData?.tags}
                          </strong>
                        </td>
                      </tr>
                      {currentUser?.app?.inventory && (
                        <>
                          <tr>
                            <td>Items</td>
                          </tr>
                          <tr>
                            <td colSpan={2}>
                              {/* display item name and quantity */}
                              <table className='table'>
                                <thead>
                                  <tr>
                                    <th className='fw-semibold'>Item</th>
                                    <th className='fw-semibold'>Quantity</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {currentTaskData?.items?.map((item, index) => (
                                    <tr key={index}>
                                      <td>{item?.itemId?.productName}</td>
                                      <td>{item.quantity}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className='modal-footer'>
                <button type='button' className='btn btn-secondary' onClick={handleCloseModal}>
                  Close
                </button>
                <button type='button' className='btn btn-danger' onClick={handleDeleteEvent}>
                  Remove Target
                </button>
                <button type='button' className='btn btn-warning' onClick={handleEditEvent}>
                  Edit Target
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className='modal fade' tabIndex={-1} id='kt_modal_1234'>
        <TargetEdit
          formData={formData}
          setFormData={setFormData}
          editTaskId={editTaskId}
          getTargets={getTargets}
        />
        {/* <div className='modal-footer'>
          <button
            type='button'
            className='btn btn-light'
            data-bs-dismiss='modal'
            id='closeModalButton3'
          >
            Close
          </button>
          <button type='button' className='btn btn-primary' onClick={handleEdit}>
            Edit
          </button>
        </div> */}
      </div>
    </div>
  )
}

export default TargetPage
