import React, {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'
import {
  getAccountsForExpensePaidThrough,
  getPayrollsByMonth,
  updatePayrollApprove,
  updatePayrollReject,
} from '../core/_requests'
import Swal from 'sweetalert2'
import {RiArrowDropDownLine} from 'react-icons/ri'
import useExcelDownload from '../components/useExcelDownload'
import {Spinner} from 'react-bootstrap'
import {KTSVG} from '../../../../_metronic/helpers'

function PayrollMonthTable() {
  const {currentUser} = useAuth()
  const params = useParams()
  const month = params?.month
  const [payrollList, setpayrollList] = useState<any>([])
  const [permissions, setPermissions] = useState<any>({})
  const [dropdown, setDropdown] = useState([])
  const [paidThroughAccounts, setPaidThroughAccounts] = useState({})
  const [paidThroughAccount, setPaidThroughAccount] = useState('')
  const [selectedPayrollId, setSelectedPayrollId] = useState('')
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [downloadExcelLoading, setDownloadExcelLoading] = useState(false)
  const downloadExcel = useExcelDownload()

  useEffect(() => {
    if (currentUser.permissions !== undefined) {
      setPermissions(currentUser.permissions)
    }
  }, [currentUser.permissions])

  const fetchpayrollList = async () => {
    // if(currentUser?.profileType === "superadmin"){
    const res = await getPayrollsByMonth(month, currentUser?.organization)
    setpayrollList(res)
    setDropdown(Array(res?.length)?.fill(false))
    // }else if(currentUser?.profileType === "superadmin"){
    //   const res = await getQuotesbyAgentId(currentUser?._id)
    //   setpayrollList(res)
    //   console.log(res)
    // }
  }

  const fetchAccounts = async () => {
    const resp = await getAccountsForExpensePaidThrough(currentUser?.organization)
    const paidThrough = {
      Cash: [],
      Bank: [],
      'Other Current Liability': [],
      Equity: [],
      'Other Current Asset': [],
      'Fixed Asset': [],
    }
    resp.forEach((r) => {
      if (r.accountType === 'cash') {
        paidThrough['Cash'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'bank') {
        paidThrough['Bank'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'othercurrentliability') {
        paidThrough['Other Current Liability'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'equity') {
        paidThrough['Equity'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'othercurrentasset') {
        paidThrough['Other Current Asset'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'fixedasset') {
        paidThrough['Fixed Asset'].push({
          value: r?._id,
          label: r?.accountName,
        })
      }
    })
    setPaidThroughAccounts(paidThrough)
  }

  const handleExcelDownload = () => {
    const excelData = payrollList?.map((item, index) => ({
      ...item,
      _id: index + 1,
      paidThrough: item?.paidThrough?.accountName,
      createdAt: '',
      startDate: item?.startDate ? new Date(item.startDate).toISOString().split('T')[0] : '',
      endDate: item?.endDate ? new Date(item.endDate).toISOString().split('T')[0] : '',
    }))
    downloadExcel(excelData, 'payrolls.xlsx', setDownloadExcelLoading)
  }

  const handleClick = (index) => {
    const newDropdownState = dropdown.map((_, i) => (i === index ? !dropdown[i] : false))
    setDropdown(newDropdownState)
  }

  useEffect(() => {
    fetchpayrollList()
    fetchAccounts()
  }, [])

  const handleApproved = async (id, index) => {
    const res = await updatePayrollApprove(id, paidThroughAccount)
    if (res) {
      const updatedData = payrollList.map((item, idx) =>
        idx === index ? {...item, approval: 'accepted'} : item
      )
      setpayrollList(updatedData)
      const closeModalButton = document.getElementById('closeModalButton1')
      if (closeModalButton) {
        closeModalButton.click()
      }
    }
  }

  const handleReject = async (id, index) => {
    const res = await updatePayrollReject(id)
    if (res) {
      const updatedData = payrollList.map((item, idx) =>
        idx === index ? {...item, approval: 'rejected'} : item
      )
      setpayrollList(updatedData)
    }
  }

  const showDetail = (item: any) => {
    Swal.fire({
      title: 'Payroll Details',
      // showing all details like employee name, paid through, amount, date, notes
      html: `
        <div class="row">
          <div class="col-6">
            <div class="mb-3">
              <label class="form-label">Employee</label>
                <input type="text" class="form-control" value="${
                  item?.employeeId?.firstName
                }" readonly>
            </div>
            </div>
            <div class="col-6">
            <div class="mb-3">
              <label class="form-label
                ">Paid Through</label>
                    <input type="text" class="form-control" value="${
                      item?.paidThrough?.accountName
                    }" readonly>
            </div>
            </div>
            <div class="col-12">
            <div class="mb-3">
              <label class="form-label
                ">Amount</label>
                    <input type="text" class="form-control" value="${item?.salary}" readonly>
            </div>
            </div>
            <div class="col-12">
            <div class="mb-3">
              <label class="form-label
                ">Date</label>
                    <input type="text" class="form-control" value="${formatCreatedAt(
                      item?.startDate
                    )} - ${formatCreatedAt(item?.endDate)}" readonly>
            </div>
            </div>
            <div class="col-12">
            <div class="mb-3">
              <label class="form-label
                ">Notes</label>
                    <textarea class="form-control" readonly>${item?.notes}</textarea>
            </div>
            </div>
        </div>
        `,

      showCloseButton: true,
      showCancelButton: false,
      customClass: 'swal-wide',
      focusConfirm: false,
      confirmButtonText: 'Close',
    })
  }

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      // day: '2-digit',
      // month: 'short',
      // year: 'numeric',
    })
    return formattedDate
  }

  return (
    <div className='card card-flush'>
      <div className='card-header border-0 pt-5 d-flex justify-between'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>
            Payrolls of <span className='capitalize'>{month}</span>
          </span>
        </h3>
        <div className='flex items-center'>
          <button className='btn btn-primary' onClick={handleExcelDownload}>
            {downloadExcelLoading ? (
              <>
                <Spinner animation='border' role='status' />
              </>
            ) : (
              'Download Excel'
            )}
          </button>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive min-h-screen'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4 table-row-bordered'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-50px rounded-start'>Created On</th>
                <th className='min-w-150px text-center'>Employee</th>
                <th className='min-w-150px text-center'>Paid Through</th>
                <th className='min-w-150px text-center'>Amount</th>
                <th className='min-w-150px rounded-end text-center'>Date</th>
                <th className='min-w-100px text-center'>Approval</th>
                {(currentUser.profileType === 'superadmin' || permissions?.payroll?.update) && (
                  <th className='min-w-100px text-center'>Actions</th>
                )}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {payrollList?.map((item: any, index) => (
                <tr
                  key={item?._id?.toString()}
                  // onClick={() => {
                  //   showDetail(item)
                  // }}
                >
                  <td>
                    <Link
                      to={`/hrm/payroll/payslip/${item?._id}`}
                      className='ps-4 text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                    >
                      {formatCreatedAt(item?.createdAt)}
                    </Link>
                  </td>
                  <td>
                    <Link
                      to={`/hrm/payroll/payslip/${item?._id}`}
                      className='text-center text-dark fw-bold text-hover-primary d-block mb-1 fs-6 capitalize'
                    >
                      {item?.employeeId?.firstName}
                    </Link>
                  </td>
                  <td>
                    <Link
                      to={`/hrm/payroll/payslip/${item?._id}`}
                      className='text-center text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                    >
                      {item?.paidThrough?.accountName}
                    </Link>
                  </td>
                  <td>
                    <Link
                      to={`/hrm/payroll/payslip/${item?._id}`}
                      className='text-center text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                    >
                      {item?.salary}
                    </Link>
                  </td>
                  <td>
                    <Link
                      to={`/hrm/payroll/payslip/${item?._id}`}
                      className='text-center text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                    >
                      {formatCreatedAt(item?.startDate)} - {formatCreatedAt(item?.endDate)}
                    </Link>
                  </td>
                  <td>
                    <div className='text-dark text-center fw-bold text-hover-primary d-block mb-1 fs-6'>
                      <span
                        className={`badge  fs-7 fw-semibold ${
                          item?.approval === 'pending'
                            ? 'badge-light-primary'
                            : item?.approval === 'accepted'
                            ? 'badge-light-success'
                            : 'badge-light-danger'
                        }`}
                      >
                        {item?.approval}
                      </span>
                    </div>
                  </td>
                  {(permissions?.payroll?.update || currentUser?.profileType === 'superadmin') && (
                    <td className='relative'>
                      <div className='d-flex justify-content-center flex-shrink-0'>
                        <button
                          onClick={() => handleClick(index)}
                          className=' bg-gray-100 hover:bg-lime-50 px-6 py-2 flex items-center rounded self-end'
                        >
                          Actions <RiArrowDropDownLine />
                        </button>
                      </div>
                      {dropdown[index] && (
                        <div className='absolute flex flex-col top-16 right-[0px] z-50  bg-white  w-40 shadow-[0_16px_16px_6px_rgba(128,128,128,0.1)] rounded p-3 space-y-2'>
                          <Link to={`/hrm/payroll/edit/${item._id}`}>
                            <button className='hover:bg-lime-50 p-2  text-start rounded'>
                              Edit
                            </button>
                          </Link>
                          {(item?.approval === 'pending' || item?.approval === 'rejected') && (
                            <button
                              className='hover:bg-green-50 p-2 text-start rounded'
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_2'
                              onClick={() => {
                                setSelectedIndex(index)
                                setSelectedPayrollId(item?._id)
                                setPaidThroughAccount(item?.paidThrough?._id || '')
                              }}
                            >
                              Approve
                            </button>
                          )}
                          {(item?.approval === 'pending' || item?.approval === 'accepted') && (
                            <button
                              className='hover:bg-red-50 p-2 text-start rounded'
                              onClick={() => handleReject(item._id, index)}
                            >
                              Reject
                            </button>
                          )}
                        </div>
                      )}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>

      <div className='modal fade' tabIndex={-1} id='kt_modal_2'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Payroll Approval</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>

            <div className='modal-body'>
              <form>
                <div className='row rowwidthclient'>
                  <div className='mb-6'>
                    <label className='form-label required fs-6 fw-bold text-gray-700 mb-3'>
                      Select Paid Through Account
                    </label>
                    {/* begin::Input group */}
                    <select
                      className='form-select form-select-solid'
                      value={paidThroughAccount}
                      name='paidThrough'
                      onChange={(e) => {
                        setPaidThroughAccount(e.target.value)
                      }}
                    >
                      <option value=''>Select Account</option>
                      {Object.keys(paidThroughAccounts).map((key, index) => {
                        return (
                          <optgroup label={key} key={index}>
                            {paidThroughAccounts[key].map((item) => {
                              return (
                                <option value={item?.value} key={item?.value}>
                                  {item?.label}
                                </option>
                              )
                            })}
                          </optgroup>
                        )
                      })}
                    </select>
                  </div>
                </div>
              </form>
            </div>

            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                id='closeModalButton1'
              >
                Close
              </button>
              <button
                type='button'
                className='btn btn-success'
                onClick={() => handleApproved(selectedPayrollId, selectedIndex)}
              >
                Aprove
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PayrollMonthTable
