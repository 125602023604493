import React, {useEffect, useState} from 'react'
import {useAuth} from '../../../modules/auth'
import {getCategoryOfAccounts} from '../core/_requests'
import {Link} from 'react-router-dom'
import {KTIcon} from '../../../../_metronic/helpers'
import {useSelector, useDispatch} from 'react-redux'
import {setCategoryOfAccountsData} from '../../../redux/accounts/accountant/categoryOfAccountant/categoryOfAccountsSlice'

const CategoryPage = () => {
  const {currentUser} = useAuth()
  // const [tableData, setTableData] = useState([])
  const dispatch = useDispatch()
  const tableData = useSelector(
    (state: any) => state.account.categoryOfAccounts.categoryOfAccountsData
  )

  const fetchData = async () => {
    const res = await getCategoryOfAccounts(currentUser?.organization)
    const sortedList = res.sort((a: any, b: any) => {
      const dateA: any = new Date(a.createdAt)
      const dateB: any = new Date(b.createdAt)
      return dateB - dateA
    })
    console.log(sortedList)
    // setTableData(res)
    dispatch(setCategoryOfAccountsData(res))
  }

  useEffect(() => {
    fetchData()
  }, [])

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {})
    return formattedDate
  }

  return (
    <div className='card w-75 mx-auto'>
      <div className='card-header d-flex justify-between align-items-center'>
        <h3>Category of Accounts</h3>
        <div className='card-toolbar'>
          <Link to={'/account/categoryofaccounts/add'} className='btn btn-sm btn-light-success'>
            <KTIcon iconName='plus' className='fs-2' />
            New
          </Link>
        </div>
      </div>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table align-middle gs-0 gy-4 table-row-bordered'>
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-50px rounded-start'>Date</th>
                <th>Name</th>
                <th>type</th>
                <th>Total Accounts</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {tableData?.map((item: any) => (
                <tr key={item.id}>
                  <td>
                    <Link
                      to={`${item._id}`}
                      className='ps-4 text-dark fw-bold text-hover-primary mb-1 fs-6'
                    >
                      {formatCreatedAt(item.createdAt)}
                    </Link>
                  </td>
                  <td>
                    <Link
                      to={`${item._id}`}
                      className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                    >
                      {item?.name}
                    </Link>
                  </td>
                  <td className='text-dark capitalize fw-bold text-hover-primary mb-1 fs-6'>
                    {item?.accountType}
                  </td>
                  <td className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    {item.accounts?.length}
                  </td>
                  <td className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    {item.accounts?.reduce((acc: any, curr: any) => acc + curr.amount, 0)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default CategoryPage
