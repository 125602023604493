import React, {useEffect, useRef} from 'react'
import {useState} from 'react'
import 'quill/dist/quill.snow.css' // Add css for snow theme
import Thumbnail from '../Components/Thumbnail'
import Status from '../Components/Status'
import Sales from '../Components/Sales'
import ProductDetails from '../Components/ProductDetails'
import ProductTemplate from '../Components/ProductTemplate'
import General from '../Components/General'
import Media from '../Components/Media'
import Pricing from '../Components/Pricing'
import Inventory from '../Components/Inventory'
import Variations from '../Components/Variations'
import Shipping from '../Components/Shipping'
import MetaOptions from '../Components/MetaOptions'
import {useNavigate, useParams} from 'react-router-dom'
import {createStock, getStockAccounts, productImage, productMedia} from '../Core/_requests'
import AccountsInformation from '../Components/AccountsInformation'
import {useAuth} from '../../../modules/auth'
import {getCategoriesForProducts} from '../Core/_categoryRequest'
import RentalDetails from '../Components/RentalDetails'
import {useDispatch} from 'react-redux'
import {setGoodsDataFetch} from '../../../redux/inventory/products/goodsSlice'
import {setRentalsDataFetch} from '../../../redux/inventory/products/rentalsSlice'
import {setConsumablesDataFetch} from '../../../redux/inventory/products/consumablesSlice'
import {setMaterialsDataFetch} from '../../../redux/inventory/products/materialsSlice'

export default function AddProduct() {
  const {currentUser} = useAuth()
  const {categoryType} = useParams()
  const accountsTab = useRef(null)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [previewImage, setPreviewImage] = useState(null) // Thumbnail
  const [thumbnail, setThumbnail] = useState(null)
  const [categories, setCategories] = useState('') //Product Details
  const [tags, setTags] = useState([])
  const [productName, setProductName] = useState('') //General
  const [productDescription, setProductDescription] = useState('')
  const [media, setMedia] = useState([]) //Media
  const [sku, setSku] = useState('')
  const [description, setDescription] = useState('')
  const [weight, setWeight] = useState('')
  const [width, setWidth] = useState('')
  const [height, setHeight] = useState('')
  const [length, setLength] = useState('')
  const [selectedImage, setSelectedImage] = useState(null)
  const [mediaName, setMediaName] = useState([])
  const [salesInfo, setSalesInfo] = useState(true)
  const [purchaseInfo, setPurchaseInfo] = useState(true)
  const [inventoryInfo, setInventoryInfo] = useState(true)
  const [salesAccount, setSalesAccount] = useState('')
  const [purchaseAccount, setPurchaseAccount] = useState('')
  const [inventoryAccount, setInventoryAccount] = useState('')
  const [inventoryAccounts, setInventoryAccounts] = useState([])
  const [categoriesOptions, setCategoriesOptions] = useState([])
  const [manufacturer, setManufacturer] = useState('')
  const [brand, setBrand] = useState('')
  const [upc, setUpc] = useState('')
  const [ean, setEan] = useState('')
  const [mpn, setMpn] = useState('')
  const [isbn, setIsbn] = useState('')
  const [unit, setUnit] = useState('')
  const [costPrice, setCostPrice] = useState('')
  const [sellingPrice, setSellingPrice] = useState('')
  const [openingStock, setOpeningStock] = useState('')
  const [openingStockRate, setOpeningStockRate] = useState('')
  const [reorderPoint, setReorderPoint] = useState('')
  const [mrq, setMrq] = useState('')
  const [mrqCheckbox, setMrqCheckbox] = useState(false)
  const [rentalType, setRentalType] = useState('vehicle')
  const [mobilizedHoursPerDay, setMobilizedHoursPerDay] = useState('8')
  const [invoiceHourlyRate, setInvoiceHourlyRate] = useState('')
  const [invoiceDailyRate, setInvoiceDailyRate] = useState('')
  const [invoiceMonthlyRate, setInvoiceMonthlyRate] = useState('')
  const [poHourlyRate, setPoHourlyRate] = useState('')
  const [poDailyRate, setPoDailyRate] = useState('')
  const [poMonthlyRate, setPoMonthlyRate] = useState('')

  const navigate = useNavigate()

  const fetchInventoryAccounts = async () => {
    const res = await getStockAccounts(currentUser?.organization)
    const data = res.map((item) => {
      return {
        value: item._id,
        label: item.accountName,
      }
    })
    setInventoryAccounts(data)
  }

  const fetchCategories = async () => {
    try {
      const response = await getCategoriesForProducts(currentUser.organization, categoryType)
      console.log('response', response)
      const categories = response.map((category) => ({
        value: category._id,
        label: category.categoryName,
      }))
      console.log('categories', categories)
      setCategoriesOptions(categories)
    } catch (error) {
      console.log('error fetching categories', error)
    }
  }

  useEffect(() => {
    fetchCategories()
    fetchInventoryAccounts()
  }, [currentUser, categoryType])

  const handleCancel = () => {
    navigate(`/product/${categoryType}`)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (productName === '') {
      alert('Please enter product name')
      return
    }
    if (sku === '') {
      alert('Please enter SKU')
      return
    }
    if (unit === '') {
      alert('Please select unit')
      return
    }
    if (!categories) {
      alert('Please select category')
      return
    }
    if (sellingPrice === '' && categoryType !== 'rentals') {
      accountsTab.current.click()
      alert('Please enter selling price')
      return
    }
    if (costPrice === '' && categoryType !== 'rentals') {
      accountsTab.current.click()
      alert('Please enter cost price')
      return
    }
    console.log('Form submitted!')

    setLoading(true)

    const imageData = new FormData()
    const productPic = thumbnail
    imageData.append('name', productPic)
    imageData.append('image', selectedImage)

    const mediaData = new FormData()
    media.forEach((file, index) => {
      const currentMediaName = mediaName[index]
      mediaData.append('name', currentMediaName)
      mediaData.append('media', file)
    })

    const formData = {
      thumbnail,
      category: categories,
      tags,
      productName,
      productDescription,
      mediaName,
      price: sellingPrice,
      sku,
      inWarehouseQuantity: openingStock || 0,
      length,
      width,
      height,
      weight,
      description,
      salesInfo,
      purchaseInfo,
      salesAccount,
      purchaseAccount,
      manufacturer,
      brand,
      upc,
      ean,
      mpn,
      isbn,
      costPrice,
      openingStock,
      openingStockRate,
      reorderPoint,
      unit,
      inventoryInfo,
      inventoryAccount,
      mrq,
      mrqCheckbox,
      rentalType,
      mobilizedHoursPerDay,
      invoiceHourlyRate,
      invoiceDailyRate,
      invoiceMonthlyRate,
      poHourlyRate,
      poDailyRate,
      poMonthlyRate,
    }
    console.log('formData', formData)
    if (selectedImage) {
      await productImage(imageData).then(async (res) => {
        // await productMedia(mediaData).then(async (req) => {
        const data = {
          ...formData,
          organization: currentUser?.organization,
          company: currentUser?.company,
        }
        await createStock(data).then((response) => {
          switch (categoryType) {
            case 'goods':
              dispatch(setGoodsDataFetch())
              break
            case 'rentals':
              dispatch(setRentalsDataFetch())
              break
            case 'consumables':
              dispatch(setConsumablesDataFetch())
              break
            case 'materials':
              dispatch(setMaterialsDataFetch())
              break
            default:
              break
          }
          navigate(`/product/${categoryType}`)
        })
        // })
      })
    } else {
      const data = {
        ...formData,
        thumbnail: categoryType,
        organization: currentUser?.organization,
        company: currentUser?.company,
      }
      await createStock(data).then((response) => {
        navigate(`/product/${categoryType}`)
      })
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className='container-fluid bg-gray-50 p-4'>
        <div className='row my-4 '>
          <div className='col-xl-4 space-y-6'>
            <Thumbnail
              previewImage={previewImage}
              setPreviewImage={setPreviewImage}
              thumbnail={thumbnail}
              setThumbnail={setThumbnail}
              selectedImage={selectedImage}
              setSelectedImage={setSelectedImage}
            />

            {categoryType === 'rentals' && (
              <RentalDetails rentalType={rentalType} setRentalType={setRentalType} />
            )}

            <ProductDetails
              categories={categories}
              categoriesOptions={categoriesOptions}
              setCategories={setCategories}
              tags={tags}
              setTags={setTags}
              showTagModal={true}
            />
          </div>
          <div className='col-xl-8 mt-4'>
            <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
              <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
                <li className='nav-item'>
                  <a
                    className='nav-link text-active-primary pb-4 active'
                    data-bs-toggle='tab'
                    href='#kt_ecommerce_add_product_general'
                  >
                    Details
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className='nav-link text-active-primary pb-4'
                    data-bs-toggle='tab'
                    href='#kt_ecommerce_add_product_advan'
                  >
                    Advanced
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className='nav-link text-active-primary pb-4'
                    data-bs-toggle='tab'
                    href='#kt_ecommerce_add_product_accounts'
                    ref={accountsTab}
                  >
                    Accounts
                  </a>
                </li>
                {categoryType === 'rentals' && (
                  <li className='nav-item'>
                    <a
                      className='nav-link text-active-primary pb-4'
                      data-bs-toggle='tab'
                      href='#kt_ecommerce_add_product_rental'
                    >
                      Rental Price
                    </a>
                  </li>
                )}
              </ul>
              <div className='tab-content'>
                <div
                  className='tab-pane fade show active'
                  id='kt_ecommerce_add_product_general'
                  role='tab-panel'
                >
                  <div className='d-flex flex-column gap-7 gap-lg-10'>
                    <div className='card card-flush py-4'>
                      <div className='card-header'>
                        <div className='card-title'>
                          <h2>Item Details</h2>
                        </div>
                      </div>

                      <div className='card-body pt-0'>
                        <div className='mb-10 fv-row'>
                          <label className='required form-label'>Item Name</label>

                          <input
                            type='text'
                            name='name'
                            className='form-control mb-2'
                            placeholder='Item name'
                            value={productName}
                            onChange={(e) => setProductName(e.target.value)}
                          />

                          <div className='text-muted fs-7'>
                            An Item name is required and recommended to be unique.
                          </div>
                        </div>

                        <div className='mb-10 fv-row'>
                          <label className='form-label'>Description</label>
                          <div className='w-full rounded-lg h-full'>
                            <textarea
                              name='description'
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                              placeholder='type your text here..'
                              rows={4}
                              cols={50}
                              className='w-full rounded border px-4 py-2'
                            ></textarea>
                          </div>

                          <div className='text-muted fs-7'>
                            Set a description to the item for better visibility.
                          </div>
                        </div>

                        <div className='mb-10 fv-row'>
                          <label className='required form-label'>SKU</label>

                          <input
                            type='text'
                            name='sku'
                            className='form-control mb-2'
                            placeholder='SKU'
                            value={sku}
                            onChange={(e) => setSku(e.target.value)}
                          />

                          <div className='text-muted fs-7'>Enter the item SKU.</div>
                        </div>

                        <div className='mb-10 w-50'>
                          <label className='form-label required'>Unit</label>
                          <select
                            className='form-select form-control-solid'
                            id='unit'
                            name='unit'
                            value={unit}
                            onChange={(e) => setUnit(e.target.value)}
                          >
                            <option value=''>Select Unit</option>
                            {currentUser?.units?.map((unit) => (
                              <option value={unit.name}>{unit.name}</option>
                            ))}
                          </select>
                        </div>

                        <div className='mb-5 fv-row form-check form-switch'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='mrqCheckbox'
                            checked={mrqCheckbox}
                            onChange={(e) => setMrqCheckbox(e.target.checked)}
                          />
                          <label className='form-check-label' htmlFor='mrqCheckbox'>
                            Enable MRQ
                          </label>
                        </div>

                        <div className='mb-10 fv-row'>
                          <label className='form-label'>MRQ</label>
                          <input
                            type='number'
                            name='mrq'
                            className='form-control mb-2 w-50'
                            placeholder='Minimum Required Quantity'
                            value={mrq}
                            onChange={(e) => setMrq(e.target.value)}
                            disabled={!mrqCheckbox}
                          />

                          <div className='text-muted fs-7'>Enter the item MRQ.</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='tab-pane fade' id='kt_ecommerce_add_product_advan' role='tab-panel'>
                  <div className='d-flex flex-column gap-7 gap-lg-10'>
                    <Media
                      media={media}
                      setMedia={setMedia}
                      mediaName={mediaName}
                      setMediaName={setMediaName}
                    />

                    <div className='card card-flush py-4'>
                      <div className='card-header'>
                        <div className='card-title'>
                          <h2>Other Details</h2>
                        </div>
                      </div>

                      <div className='card-body pt-0'>
                        <div className='mb-10'>
                          <label className='form-label'>Manufacturer</label>

                          <input
                            type='text'
                            className='form-control mb-2'
                            name='meta_title'
                            placeholder='Enter manufacturer name'
                            value={manufacturer}
                            onChange={(e) => setManufacturer(e.target.value)}
                          />
                        </div>
                        <div className='mb-10'>
                          <label className='form-label'>Brand</label>

                          <input
                            type='text'
                            className='form-control mb-2'
                            name='meta_title'
                            placeholder='Enter brand name'
                            value={brand}
                            onChange={(e) => setBrand(e.target.value)}
                          />
                        </div>
                        <div className='mb-10'>
                          <label className='form-label'>UPC</label>

                          <input
                            type='text'
                            className='form-control mb-2'
                            name='meta_title'
                            placeholder='Enter UPC code'
                            value={upc}
                            onChange={(e) => setUpc(e.target.value)}
                          />
                        </div>
                        <div className='mb-10'>
                          <label className='form-label'>EAN</label>

                          <input
                            type='text'
                            className='form-control mb-2'
                            name='meta_title'
                            placeholder='Enter EAN code'
                            value={ean}
                            onChange={(e) => setEan(e.target.value)}
                          />
                        </div>
                        <div className='mb-10'>
                          <label className='form-label'>MPN</label>

                          <input
                            type='text'
                            className='form-control mb-2'
                            name='meta_title'
                            placeholder='Enter MPN code'
                            value={mpn}
                            onChange={(e) => setMpn(e.target.value)}
                          />
                        </div>
                        <div className='mb-10'>
                          <label className='form-label'>ISBN</label>

                          <input
                            type='text'
                            className='form-control mb-2'
                            name='meta_title'
                            placeholder='Enter ISBN code'
                            value={isbn}
                            onChange={(e) => setIsbn(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>

                    <Shipping
                      weight={weight}
                      setWeight={setWeight}
                      width={width}
                      setWidth={setWidth}
                      height={height}
                      setHeight={setHeight}
                      length={length}
                      setLength={setLength}
                    />
                  </div>
                </div>

                <div
                  className='tab-pane fade'
                  id='kt_ecommerce_add_product_accounts'
                  role='tab-panel'
                >
                  <div className='d-flex flex-column gap-7 gap-lg-10'>
                    <AccountsInformation
                      sellingPrice={sellingPrice}
                      setSellingPrice={setSellingPrice}
                      costPrice={costPrice}
                      setCostPrice={setCostPrice}
                      salesInfo={salesInfo}
                      setSalesInfo={setSalesInfo}
                      purchaseInfo={purchaseInfo}
                      setPurchaseInfo={setPurchaseInfo}
                      salesAccount={salesAccount}
                      setSalesAccount={setSalesAccount}
                      purchaseAccount={purchaseAccount}
                      setPurchaseAccount={setPurchaseAccount}
                      categoryType={categoryType}
                    />
                    <div className='card card-flush py-4'>
                      <div className='card-header'>
                        <div className='card-title'>
                          <input
                            type='checkbox'
                            checked={inventoryInfo}
                            className='form-check-input w-20px h-20px me-4'
                            onChange={(e) => {
                              setInventoryInfo(e.target.checked)
                              setInventoryAccount('')
                            }}
                          />
                          <h2>Inventory Information</h2>
                        </div>
                      </div>

                      <div className='card-body pt-0'>
                        <div className='mb-10 fv-row'>
                          <label className='required form-label'>Account</label>

                          <select
                            name='accountType'
                            onChange={(e) => setInventoryAccount(e.target.value)}
                            className='form-control form-control-solid w-75'
                            disabled={!inventoryInfo}
                            value={inventoryAccount}
                          >
                            <option value=''>Select an account</option>
                            <optgroup label='Stock'>
                              {inventoryAccounts?.map((item) => (
                                <option key={item.value} value={item.value}>
                                  {item.label}
                                </option>
                              ))}
                            </optgroup>
                          </select>
                        </div>
                        <div className='mb-10 fv-row'>
                          <label className='form-label'>Opening Stock</label>
                          <input
                            type='number'
                            name='quantity'
                            min='0'
                            className='form-control mb-2 form-control-solid w-25'
                            placeholder=''
                            value={openingStock}
                            onChange={(e) => setOpeningStock(e.target.value)}
                          />
                        </div>
                        <div className='mb-10 fv-row'>
                          <label className='form-label'>Opening Stock Rate Per Unit</label>
                          <input
                            type='number'
                            name='quantity'
                            min='0'
                            className='form-control mb-2 form-control-solid w-25'
                            placeholder=''
                            value={openingStockRate}
                            onChange={(e) => setOpeningStockRate(e.target.value)}
                          />
                        </div>
                        <div className='mb-10 fv-row'>
                          <label className='form-label'>Reorder Point</label>
                          <input
                            type='number'
                            name='quantity'
                            min='0'
                            className='form-control mb-2 form-control-solid w-25'
                            placeholder=''
                            value={reorderPoint}
                            onChange={(e) => setReorderPoint(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {categoryType === 'rentals' && (
                  <div
                    className='tab-pane fade'
                    id='kt_ecommerce_add_product_rental'
                    role='tab-panel'
                  >
                    <div className='d-flex flex-column gap-7 gap-lg-10'>
                      <div className='card card-flush py-4'>
                        <div className='card-header'>
                          <div className='card-title'>
                            <h2>Rental Price</h2>
                          </div>
                        </div>

                        <div className='card-body pt-0'>
                          {/* mobilized hours per day (input for hours per day) */}
                          <div className='mb-10 fv-row'>
                            <label className='form-label fw-bold fs-3'>
                              Mobilized Hours Per Day
                            </label>
                            <input
                              type='number'
                              name='mobilizedHoursPerDay'
                              className='form-control mb-2 form-control-solid w-25'
                              min='0'
                              placeholder='Enter mobilized hours per day'
                              value={mobilizedHoursPerDay}
                              onChange={(e) => setMobilizedHoursPerDay(e.target.value)}
                            />
                          </div>
                          <div className='mb-10 fv-row'>
                            <label className='form-label fw-bold fs-3'>Invoice Rate</label>
                            <div className='row mb-2'>
                              <div className='col-lg-3 d-flex align-items-center'>
                                <label className='form-label' id='invoiceHourlyRate'>
                                  Hourly Rate
                                </label>
                              </div>
                              <div className='col-lg-9'>
                                <input
                                  type='number'
                                  name='invoiceHourlyRate'
                                  className='form-control mb-2 form-control-solid w-25'
                                  min='0'
                                  placeholder='Enter invoice rate'
                                  value={invoiceHourlyRate}
                                  onChange={(e) => {
                                    setInvoiceHourlyRate(e.target.value)
                                    setInvoiceDailyRate(
                                      e.target.value * Number(mobilizedHoursPerDay)
                                    )
                                    setInvoiceMonthlyRate(
                                      e.target.value * Number(mobilizedHoursPerDay) * 30
                                    )
                                  }}
                                />
                              </div>
                            </div>
                            <div className='row mb-2'>
                              <div className='col-lg-3 d-flex align-items-center'>
                                <label className='form-label' id='invoiceDailyRate'>
                                  Daily Rate
                                </label>
                              </div>
                              <div className='col-lg-9'>
                                <input
                                  type='number'
                                  name='invoiceDailyRate'
                                  className='form-control mb-2 form-control-solid w-25'
                                  min='0'
                                  placeholder='Enter invoice daily rate'
                                  value={invoiceDailyRate}
                                  onChange={(e) => {
                                    setInvoiceDailyRate(e.target.value)
                                    setInvoiceHourlyRate(
                                      e.target.value / Number(mobilizedHoursPerDay)
                                    )
                                    setInvoiceMonthlyRate(e.target.value * 30)
                                  }}
                                />
                              </div>
                            </div>
                            <div className='row mb-2'>
                              <div className='col-lg-3 d-flex align-items-center'>
                                <label className='form-label' id='invoiceMonthlyRate'>
                                  Monthly Rate
                                </label>
                              </div>
                              <div className='col-lg-9'>
                                <input
                                  type='number'
                                  name='invoiceMonthlyRate'
                                  className='form-control mb-2 form-control-solid w-25'
                                  min='0'
                                  placeholder='Enter invoice monthly rate'
                                  value={invoiceMonthlyRate}
                                  onChange={(e) => {
                                    setInvoiceMonthlyRate(e.target.value)
                                    setInvoiceHourlyRate(
                                      e.target.value / (30 * Number(mobilizedHoursPerDay))
                                    )
                                    setInvoiceDailyRate(
                                      e.target.value / Number(mobilizedHoursPerDay)
                                    )
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className='mb-10 fv-row'>
                            <label className='form-label fw-bold fs-3'>PO Rate</label>
                            <div className='row mb-2'>
                              <div className='col-lg-3 d-flex align-items-center'>
                                <label className='form-label' id='invoiceHourlyRate'>
                                  Hourly Rate
                                </label>
                              </div>
                              <div className='col-lg-9'>
                                <input
                                  type='number'
                                  name='invoiceHourlyRate'
                                  className='form-control mb-2 form-control-solid w-25'
                                  min='0'
                                  placeholder='Enter invoice rate'
                                  value={poHourlyRate}
                                  onChange={(e) => {
                                    setPoHourlyRate(e.target.value)
                                    setPoDailyRate(e.target.value * Number(mobilizedHoursPerDay))
                                    setPoMonthlyRate(
                                      e.target.value * Number(mobilizedHoursPerDay) * 30
                                    )
                                  }}
                                />
                              </div>
                            </div>
                            <div className='row mb-2'>
                              <div className='col-lg-3 d-flex align-items-center'>
                                <label className='form-label' id='invoiceDailyRate'>
                                  Daily Rate
                                </label>
                              </div>
                              <div className='col-lg-9'>
                                <input
                                  type='number'
                                  name='invoiceDailyRate'
                                  className='form-control mb-2 form-control-solid w-25'
                                  min='0'
                                  placeholder='Enter invoice daily rate'
                                  value={poDailyRate}
                                  onChange={(e) => {
                                    setPoDailyRate(e.target.value)
                                    setPoHourlyRate(e.target.value / Number(mobilizedHoursPerDay))
                                    setPoMonthlyRate(e.target.value * 30)
                                  }}
                                />
                              </div>
                            </div>
                            <div className='row mb-2'>
                              <div className='col-lg-3 d-flex align-items-center'>
                                <label className='form-label' id='invoiceMonthlyRate'>
                                  Monthly Rate
                                </label>
                              </div>
                              <div className='col-lg-9'>
                                <input
                                  type='number'
                                  name='invoiceMonthlyRate'
                                  className='form-control mb-2 form-control-solid w-25'
                                  min='0'
                                  placeholder='Enter invoice monthly rate'
                                  value={poMonthlyRate}
                                  onChange={(e) => {
                                    setPoMonthlyRate(e.target.value)
                                    setPoHourlyRate(
                                      e.target.value / (30 * Number(mobilizedHoursPerDay))
                                    )
                                    setPoDailyRate(e.target.value / Number(mobilizedHoursPerDay))
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className='d-flex justify-content-end'>
                <button
                  href='../../demo17/dist/apps/ecommerce/catalog/products.html'
                  id='kt_ecommerce_add_product_cancel'
                  className='btn btn-light me-5'
                  onClick={handleCancel}
                >
                  Cancel
                </button>

                <button
                  type='submit'
                  id='kt_ecommerce_add_product_submit'
                  className='btn btn-primary'
                  disabled={loading}
                >
                  <span className='indicator-label'>Save Changes</span>
                  <span className='indicator-progress'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}
