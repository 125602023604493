import React, {useEffect, useState} from 'react'
import TimeSheet from '../../projects/components/Timesheet'
import {getProjectById} from '../../projects/core/_requests'
import {useParams} from 'react-router-dom'

const HRPTimesheetDetail = () => {
  const {id} = useParams()
  const [data, setData] = useState([])
  const [projectName, setProjectName] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const res = await getProjectById(id)
        setData(res?.team)
        setProjectName(res?.projectName)
      } catch (error) {
        console.error('Error fetching the timesheet details', error)
      } finally {
        setLoading(false)
      }
    }

    if (id !== undefined) fetchData()
  }, [id])

  return loading ? (
    <div className='d-flex align-items-center justify-center'>
      <div className='loader'></div>
    </div>
  ) : (
    <TimeSheet team={data} showCreateBtn={false} showEditBtn={false} projectName={projectName} />
  )
}

export default HRPTimesheetDetail
