import React, {lazy} from 'react'
import {Routes, Route, Navigate} from 'react-router-dom'
import CRMProjectPage from './pages/CRMProjectPage'
import CRMProductsPage from './pages/CRMProducts'
import CRMServices from './pages/CRMServices'
import LeadsPage from './pages/LeadsPage'
import CRMContactsPage from './pages/CRMContactsPage'
import CRMAccountsPage from './pages/CRMAccountsPage'
import CRMAccountDetail from './components/CRMAccountDetail'
import LeadsPipeline from './pages/LeadsPipeline'
import ContactsPipeline from './pages/ContactsPipeline'
import CRMAgentsPage from './pages/CRMAgentsPage'
import CRMTasksNMeetings from './pages/CRMTasksNMeetings'
import CRMCustomerPage from './pages/CRMCustomerPage'
import {SuspensedView} from '../../routing/PrivateRoutes'

const CRMPage = () => {
  const CRMQuotesRoutes = lazy(() => import('./CRMQuotesRoutes'))

  return (
    <Routes>
      <Route path='/projects' element={<CRMProjectPage />} />
      <Route path='/products' element={<CRMProductsPage />} />
      <Route path='/services' element={<CRMServices />} />
      <Route path='/leads' element={<LeadsPage />} />
      <Route path='/contacts' element={<CRMContactsPage />} />
      <Route path='/accounts' element={<CRMAccountsPage />} />
      <Route path='/accounts/:id' element={<CRMAccountDetail />} />
      <Route path='/pipeline/leads' element={<LeadsPipeline />} />
      <Route path='/pipeline/contacts' element={<ContactsPipeline />} />
      <Route path='/agents' element={<CRMAgentsPage />} />
      <Route path='/tasks' element={<CRMTasksNMeetings />} />
      <Route path='/customers' element={<CRMCustomerPage />} />
      <Route path='/quotes' element={<Navigate to='list/pending' />} />
      <Route
        path='/quotes/*'
        element={
          <SuspensedView>
            <CRMQuotesRoutes />
          </SuspensedView>
        }
      />

      <Route path='/pipeline' element={<Navigate to='/crm/pipeline/leads' />} />
    </Routes>
  )
}

export default CRMPage
