import React, {useEffect, useState} from 'react'
import Select from 'react-select' // Make sure to import Select component from 'react-select' package
import Flatpickr from 'react-flatpickr'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {KTIcon} from '../../../../_metronic/helpers'
import {useAuth} from '../../../modules/auth/core/Auth'
import {
  getBillsLastId,
  getPurchaseQuotation,
  getPurchaseReceiveByVendorId,
  getVendor,
  getVendorByAgentId,
  postBills,
} from '../Core/_requests'
import {RootState} from '../Core/_model'
import {useNavigate} from 'react-router-dom'
import {add, endOfMonth, addMonths} from 'date-fns'
import {getNotes, getTerms} from '../../account_settings/core/_request'
import {useDispatch, useSelector} from 'react-redux'
import {setVendorDataList} from '../../../redux/procurement/vendor/vendorSlice'
import {setBillsDataFetch} from '../../../redux/procurement/bills/billsSlice'

export const AddBills = () => {
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const [loading, setLoading] = useState(false)
  const [permissions, setPermissions] = useState<any>({})
  const [bills, setBills] = useState({
    orderNo: '',
    billDate: new Date(),
    dueDate: new Date(),
    subject: '',
    paymentTerms: '',
    notes: '',
    termsCondition: '',
    subtotal: 0,
    total: 0,
    tax: 0,
  })
  const dispatch = useDispatch()
  const vendorDataList = useSelector((state: RootState) => state.procurement.vendor.vendorData)

  const [vendorList, setVendorList] = useState([])
  const [selectedVendor, setSelectedVendor] = useState(null)
  const [purchaseOrderList, setPurchaseOrderList] = useState([])
  const [productList, setProductList] = useState<any>([])
  const [purchaseReceive, setPurchaseReceive] = useState<any>([])
  const [selectedVendorCurrency, setSelectedVendorCurrency] = useState('')

  const [selectedPurchaseReceiveId, setSelectedPurchaseReceiveId] = useState(null)
  const [selectedPurchaseReceiveLabel, setSelectedPurchaseReceiveLabel] = useState(null)

  const [isEditingNotes, setIsEditingNotes] = useState(false)
  const [isEditingTerms, setIsEditingTerms] = useState(false)
  const [termsTextareaHeight, setTermsTextareaHeight] = useState(' ')
  const [notesTextareaHeight, setNotesTextareaHeight] = useState(' ')

  const [selectedPurchaseReceiveItems, setSelectedPurchaseReceiveItems] = useState([])

  const purchaseReceiveOptions = purchaseReceive
    ?.filter((item) => item.status === 'pending')
    ?.map((item) => ({
      value: item._id,
      label: item.id,
    }))
  console.log(purchaseReceiveOptions)
  //useState for Custom Bill no
  const [showModal, setShowModal] = useState(false)
  const [salesOrderNumber, setSalesOrderNumber] = useState('')
  const [selectYearAndMonth, setSelectYearAndMonth] = useState(new Date())
  const [selectedOption, setSelectedOption] = useState('autoGenerate') // Default selected option
  const [prefix, setPrefix] = useState('')
  const [nextNumber, setNextNumber] = useState('')
  const [customID, setCustomID] = useState('')
  const termsOption = [
    {label: 'Net 15', value: 'net15'},
    {label: 'Net 30', value: 'net30'},
    {label: 'Net 45', value: 'net45'},
    {label: 'Net 60', value: 'net60'},
    {label: 'Due on Receipt', value: 'receipt'},
    {label: 'Due on End of Month', value: 'endofMonth'},
    {label: 'Due on End of Next Month', value: 'endOfNextMonth'},
    {label: 'Custom', value: 'custom'},
  ]
  const [selectedTerms, setSelectedTerms] = useState<any>()
  const handleTermsChange = (terms: any) => {
    setSelectedTerms(terms)
    if (terms.value !== 'custom') {
      const {billDate} = bills
      let dueDate

      switch (terms.value) {
        case 'net15':
          dueDate = add(billDate, {days: 15})
          break
        case 'net30':
          dueDate = add(billDate, {days: 30})
          break
        case 'net45':
          dueDate = add(billDate, {days: 45})
          break
        case 'net60':
          dueDate = add(billDate, {days: 60})
          break
        case 'endofMonth':
          dueDate = endOfMonth(billDate)
          break
        case 'endOfNextMonth':
          dueDate = endOfMonth(addMonths(billDate, 1))
          break
        case 'receipt':
          dueDate = billDate
          break
        default:
          // Handle any other cases if needed
          break
      }

      // Update the state with the calculated due date
      setBills((prevBills) => ({...prevBills, dueDate}))
    }
  }

  useEffect(() => {
    if (bills.termsCondition === '') {
      setTermsTextareaHeight('6rem')
    }
  }, [bills.termsCondition])

  useEffect(() => {
    if (bills.notes === '') {
      setNotesTextareaHeight('6rem')
    }
  }, [bills.notes])

  console.log(selectedTerms)
  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const handlePurchaseReceiveChange = (selectedOption) => {
    setSelectedPurchaseReceiveId(selectedOption.value)
    setSelectedPurchaseReceiveLabel(selectedOption.label)

    // Find the selected purchase quotation and set its items to the state
    const selectedQuotation = purchaseReceive?.find(
      (purchaseReceive) => purchaseReceive._id === selectedOption.value
    )

    console.log('items', selectedQuotation)

    if (selectedQuotation) {
      setSelectedPurchaseReceiveItems(selectedQuotation?.purchaseOrder?.items)
    }
  }
  console.log('items', selectedPurchaseReceiveItems)

  const handleEditClick = (e, field) => {
    e.preventDefault()
    if (field === 'notes') {
      setIsEditingNotes(!isEditingNotes)
      setIsEditingTerms(false) // Make sure only one is in edit mode at a time
    } else if (field === 'termsCondition') {
      setIsEditingTerms(!isEditingTerms)
      setIsEditingNotes(false) // Make sure only one is in edit mode at a time
    }
  }

  useEffect(() => {
    const fetchBills = async () => {
      try {
        const BillsNotes = await getNotes(currentUser?.organization, 'Bills')
        const BillsTerms = await getTerms(currentUser?.organization, 'Bills')
        setBills({
          ...bills,
          notes: BillsNotes[0]?.notes || '',
          termsCondition: BillsTerms?.terms || '',
        })
      } catch (error) {
        console.error('Error fetching delivery notes:', error)
      }
    }
    fetchBills()
  }, [])

  const handleVendorChange = (selectedOption) => {
    setSelectedVendor(selectedOption)
    const selectedVendor = vendorList.find((vendor) => vendor.value === selectedOption.value)
    if (selectedVendor) {
      setSelectedVendorCurrency(selectedVendor.currency)
    }
    console.log('vendor', selectedOption, 'currency', selectedVendorCurrency)
  }
  console.log(selectedVendor)

  const handleChange = (e) => {
    const {name, value} = e.target
    if (name === 'notes' && value.trim() === '') {
      setBills((prev) => ({
        ...prev,
        notes: '• ' + value,
      }))
    } else {
      setBills({...bills, [name]: value})
    }
    if (name === 'termsCondition') {
      setTermsTextareaHeight(`${e.target.scrollHeight}px`)
    } else if (name === 'notes') {
      setNotesTextareaHeight(`${e.target.scrollHeight}px`)
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      setBills((prev) => ({
        ...prev,
        notes: prev.notes + '\n• ',
      }))

      setNotesTextareaHeight(`${event.target.scrollHeight}px`)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (selectedVendor === null || selectedVendor === '') {
      alert('Please select a vendor')
      return
    } else if (selectedPurchaseReceiveId === null) {
      alert('Please select a purchase receive')
      return
    }

    setLoading(true)

    const year = String(selectYearAndMonth.getFullYear() - 2000).padStart(2, '0')
    const month = String(selectYearAndMonth.getMonth() + 1).padStart(2, '0')
    const dNM = `${prefix}-${year}-${month}-`

    const data = {
      //data here
      ...bills,
      orderNo: selectedPurchaseReceiveId,
      vendor: selectedVendor.value,
      items: selectedPurchaseReceiveItems,
      prefix: dNM,
      customID: customID,
      id: nextNumber,
      approval: permissions?.bills?.enable ? 'accepted' : 'pending',
      company: currentUser?.company,
      organization: currentUser?.organization,
      agent: currentUser?._id,
    }
    console.log('data', data)
    try {
      await postBills(data, '0').then((res) => {
        console.log('Bill submitted successfully:', res)
        dispatch(setBillsDataFetch())
        navigate(`/procurement/billsList`)
      })
    } catch (error) {
      console.error('Error submitting Bill:', error)
    }
    //   console.log('Purchase receive submitted successfully:',data)
    //     try {
    //       const response=await postills(data)
    //       console.log(response);

    //       // setSubmitted(true)
    //     } catch (error) {
    //       console.error('Error submitting purchase receive:', error)
    //     }
  }
  const getPurchaseReceive = async (id) => {
    try {
      const data = await getPurchaseReceiveByVendorId(id)
      console.log('Purchase Receive List:', data)
      setPurchaseReceive(data)
    } catch (error) {
      console.error('Error fetching vendor list:', error)
    }
  }

  console.log('purchase receive', purchaseReceive)

  useEffect(() => {
    if (selectedVendor?.value) {
      getPurchaseReceive(selectedVendor?.value)
    }
    setSelectedPurchaseReceiveId(null)
    setSelectedPurchaseReceiveLabel(null)
    setSelectedPurchaseReceiveItems([])
  }, [selectedVendor])

  const handledueDateChange = (date) => {
    setSelectedTerms({label: 'Custom', value: 'custom'})
    setBills({...bills, dueDate: date[0]})
  }
  const handleBillDateChange = (date) => {
    setBills({...bills, billDate: date[0]})
  }

  useEffect(() => {
    setBills((prevBill) => ({
      ...prevBill,
      subtotal: selectedPurchaseReceiveItems?.reduce((accumulator, currentItem) => {
        // Assuming that the 'amount' property is a number
        return accumulator + parseFloat(currentItem.amount)
      }, 0),
      // You can add other properties if needed
    }))
  }, [selectedPurchaseReceiveItems])

  useEffect(() => {
    setBills((prevBills) => {
      const subtotal = prevBills.subtotal || 0
      const tax = prevBills.tax || 0

      const total = (subtotal * (1 + tax / 100)).toFixed(2)

      return {
        ...prevBills,
        total: parseFloat(total),
        // You can add other properties if needed
      }
    })
  }, [selectedPurchaseReceiveItems, bills.tax])

  const getVendorList = async () => {
    try {
      const query = null
      const data = await getVendor(query, currentUser?.organization)
      setVendorList(
        data.map((r) => ({
          value: r._id,
          label: r.displayName, // Assuming name is the property containing the vendor's name
          currency: r.currency,
        }))
      )
      dispatch(setVendorDataList(data))
    } catch (error) {
      console.error('Error fetching vendor list:', error)
    }
  }

  const getVendorListForAgent = async () => {
    try {
      const res = await getVendorByAgentId('', currentUser?._id)
      console.log(res)
      setVendorList(
        res.map((r) => ({
          value: r?._id,
          label: r?.displayName, // Corrected the typo here from "labe" to "label"
          currency: r?.currency,
        }))
      )
      dispatch(setVendorDataList(res))
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  const getPurchaseOrderList = async () => {
    try {
      const data = await getPurchaseQuotation(currentUser?.organization)
      console.log('Purchase Order List:', data)
      setPurchaseOrderList(
        data.map((r) => ({
          value: r._id,
          label: r.reference, // Assuming name is the property containing the purchase order's name
        }))
      )
    } catch (error) {
      console.error('Error fetching purchase order list:', error)
    }
  }

  const getBillsID = async () => {
    try {
      const res = await getBillsLastId(currentUser?.organization)
      console.log('ye hai res', res)
      setNextNumber(res.lastId + 1)
      const lastPrefix = res?.prefix.slice(0, res?.prefix?.trim()?.length - 7)
      setPrefix(lastPrefix)
      setSalesOrderNumber(`${res?.prefix}${res?.lastId + 1}`)
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  useEffect(() => {
    if (vendorDataList?.length === 0) {
      if (permissions?.bills?.admin === false) {
        getVendorListForAgent()
      } else if (permissions?.bills?.admin === true || currentUser?.profileType === 'superadmin') {
        getVendorList()
      }
    } else {
      setVendorList(
        vendorDataList.map((r) => ({
          value: r._id,
          label: r.displayName, // Corrected the typo here from "labe" to "label"
          currency: r.currency,
        }))
      )
    }
  }, [permissions])

  useEffect(() => {
    getPurchaseOrderList()
    getBillsID()
  }, [])

  //functions for Custom Payment no
  const handleCloseModal = () => {
    setShowModal(false)
    // setCustomID('')
    // setPrefix('')
    // setNextNumber('')
    // getBillsID()
  }

  const handleSaveChanges = () => {
    let newSalesOrderNumber = ''
    if (selectedOption === 'autoGenerate') {
      setPrefix(prefix)
      console.log(prefix, nextNumber)
      setNextNumber(nextNumber)
      setCustomID('')
      const year = String(selectYearAndMonth.getFullYear() - 2000).padStart(2, '0')
      const month = String(selectYearAndMonth.getMonth() + 1).padStart(2, '0')
      const dNM = `${year}-${month}`
      newSalesOrderNumber = `${prefix}-${dNM}-${nextNumber}`
    } else if (selectedOption === 'manualInput') {
      setCustomID(salesOrderNumber)
      setPrefix('')
      setNextNumber('')
      newSalesOrderNumber = salesOrderNumber
    }
    setSalesOrderNumber(newSalesOrderNumber)
    setShowModal(false)
  }

  // const getSalesOrderID = async () => {
  //   try {
  //     const res = await getSalesOrderLastId()
  //     setNextNumber(res.lastId + 1)
  //     setPrefix(res.prefix)
  //     console.log(res)
  //   } catch (error) {
  //     console.error('Error fetching client list:', error)
  //   }
  // }

  useEffect(() => {
    if (customID) {
      setSalesOrderNumber(customID)
    }
  }, [customID])

  return (
    <div id='kt_app_content' className='app-content flex-column-fluid '>
      {/* begin::Content container */}
      <div id='kt_app_content_container' className='app-container container-xxl'>
        {/* begin::Layout */}
        <div className='d-flex flex-column flex-lg-row'>
          {/* begin::Content */}
          <div className='flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10'>
            {/* begin::Card */}
            <div className='card'>
              {/* begin::Card body */}
              <div className='card-body p-12'>
                {/* begin::Form */}
                <form action='' id='kt_quote_form'>
                  {/* begin::Wrapper */}
                  <div className='d-flex flex-column align-items-start flex-xxl-row'>
                    {/* begin::Input group */}
                    <div
                      className='d-flex align-items-center flex-equal fw-row me-4 order-2'
                      data-bs-toggle='tooltip'
                      data-bs-trigger='hover'
                      title='Specify invoice date'
                    >
                      {/* begin::Date */}
                      {/* <div className='fs-6 fw-bold text-gray-700 text-nowrap'>Date:</div> */}
                      {/* Date */}
                      {/* begin::Input */}
                      <div className='position-relative d-flex align-items-center w-150px'>
                        {/* begin::Datepicker */}
                        {/* <Flatpickr
                          value={purchaseReceive.date}
                          onChange={handleDateChange}
                          className='form-control form-control-solid'
                          placeholder='Pick date'
                        /> */}
                        {/* Datepicker */}
                        {/* begin::Icon */}
                        {/* <i className='ki-duotone ki-down fs-4 position-absolute ms-4 end-0'></i> */}
                        {/* Icon */}
                      </div>
                      {/* Input */}
                    </div>
                    {/* Input group */}
                    {/* begin::Input group */}
                    <div
                      className='d-flex flex-center flex-equal fw-row text-nowrap order-1 order-xxl-2 me-4'
                      data-bs-toggle='tooltip'
                      data-bs-trigger='hover'
                      title='Enter Bill number'
                    >
                      <span className='fs-2x fw-bold text-gray-800 '>NEW BILL #</span>
                      {/* <input
                        type='text'
                        className='form-control form-control-flush fw-bold text-muted fs-3 w-125px'
                        // value={purchaseReceive.id}
                        // placeholder='...'
                        disabled
                      /> */}

                      <input
                        type='text'
                        className='form-control form-control-flush fw-bold text-muted fs-3 w-200px'
                        value={salesOrderNumber}
                        placeholder='...'
                        readOnly
                      />

                      <button
                        className='btn btn-light ms-2'
                        onClick={(e) => {
                          e.preventDefault()
                          setShowModal(true)
                        }}
                      >
                        <KTIcon iconName='gear' />
                      </button>

                      <div>
                        {showModal && (
                          <div
                            className='modal'
                            tabIndex={-1}
                            role='dialog'
                            style={{display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)'}}
                          >
                            <div className='modal-dialog modal-dialog-centered' role='document'>
                              <div className='modal-content'>
                                <div className='modal-header'>
                                  <h5 className='modal-title'>Customize Bill No.</h5>
                                  <button
                                    type='button'
                                    className='btn-close'
                                    onClick={handleCloseModal}
                                  ></button>
                                </div>
                                <div className='modal-body'>
                                  {/* Your modal body content goes here */}
                                  <p>How would you like to Bill numbers?</p>
                                  <div className='form-check'>
                                    <input
                                      className='form-check-input'
                                      type='radio'
                                      name='salesOrderOption'
                                      id='autoGenerate'
                                      value='autoGenerate'
                                      checked={selectedOption === 'autoGenerate'}
                                      onChange={() => setSelectedOption('autoGenerate')}
                                    />
                                    <label className='form-check-label' htmlFor='autoGenerate'>
                                      Continue auto-generating Bill numbers
                                    </label>
                                  </div>
                                  <div className='form-check'>
                                    <input
                                      className='form-check-input'
                                      type='radio'
                                      name='salesOrderOption'
                                      id='manualInput'
                                      value='manualInput'
                                      checked={selectedOption === 'manualInput'}
                                      onChange={() => setSelectedOption('manualInput')}
                                    />
                                    <label className='form-check-label' htmlFor='manualInput'>
                                      Enter Bill numbers manually
                                    </label>
                                  </div>

                                  {/* Additional input fields for manual input */}
                                  {selectedOption === 'autoGenerate' && (
                                    <div className='mt-3'>
                                      <div className='mb-3'>
                                        <label className='form-label'>Prefix:</label>
                                        <input
                                          type='text'
                                          className='form-control'
                                          value={prefix}
                                          onChange={(e) => setPrefix(e.target.value)}
                                          placeholder='Enter Prefix'
                                        />
                                      </div>
                                      <div className='mb-3'>
                                        <label className='form-label'>Month & Year:</label>
                                        <br />
                                        <DatePicker
                                          selected={selectYearAndMonth}
                                          onChange={(date) => setSelectYearAndMonth(date)}
                                          dateFormat='yyyy/MM'
                                          showMonthYearPicker
                                          className='form-control'
                                        />
                                      </div>
                                      <div className='mb-3'>
                                        <label className='form-label'>Next Number:</label>
                                        <input
                                          type='number'
                                          className='form-control'
                                          value={nextNumber}
                                          onChange={(e) => setNextNumber(e.target.value)}
                                          placeholder='Enter Next Number'
                                        />
                                      </div>
                                    </div>
                                  )}
                                  {selectedOption === 'manualInput' && (
                                    <div className='mt-3'>
                                      <div className='mb-3'>
                                        <label className='form-label'>BILL NO. :</label>
                                        <input
                                          type='text'
                                          className='form-control'
                                          value={salesOrderNumber}
                                          onChange={(e) => setSalesOrderNumber(e.target.value)}
                                          placeholder='Enter Bill Number'
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div className='modal-footer'>
                                  <button
                                    type='button'
                                    className='btn btn-secondary'
                                    onClick={handleCloseModal}
                                  >
                                    Close
                                  </button>
                                  <button
                                    type='button'
                                    className='btn btn-primary'
                                    onClick={handleSaveChanges}
                                  >
                                    Save changes
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {/* End of modal content */}
                      </div>
                    </div>
                    {/* Input group */}
                    {/* begin::Input group */}
                    <div
                      className='d-flex align-items-center justify-content-end flex-equal order-3 fw-row'
                      data-bs-toggle='tooltip'
                      data-bs-trigger='hover'
                      title='Specify invoice due date'
                    ></div>
                    {/* Input group */}
                  </div>
                  {/* Top */}
                  {/* begin::Separator */}
                  <div className='separator separator-dashed my-10'></div>
                  {/* Separator */}
                  {/* begin::Wrapper */}
                  <div className='mb-0'>
                    {/* begin::Row */}
                    <div className='row gx-10 mb-5'>
                      {/* begin::Col */}

                      {/* Col */}
                    </div>
                    {/* Row */}
                    <div className='w-50'>
                      <label className='form-label required fs-6 fw-bold text-gray-700 mb-3'>
                        Vendor Name
                      </label>
                      {/* begin::Input group */}
                      <Select
                        name='vendor'
                        aria-label='Select a Vendor'
                        placeholder='Select a Vendor'
                        options={vendorList}
                        isSearchable={true}
                        className='form-control form-control-solid'
                        onChange={handleVendorChange}
                        value={selectedVendor}
                      />
                    </div>

                    <div className='w-50'>
                      <label className='form-label required fs-6 fw-bold text-gray-700 mb-3'>
                        Purchase Receive No
                      </label>
                      <Select
                        name='orderNo'
                        aria-label='Select a Purchase Receive'
                        placeholder='Select a Purchase Receive'
                        className='form-control form-control-solid'
                        options={purchaseReceiveOptions}
                        isSearchable={true}
                        onChange={handlePurchaseReceiveChange}
                        value={
                          selectedPurchaseReceiveLabel
                            ? {
                                value: selectedPurchaseReceiveId,
                                label: selectedPurchaseReceiveLabel,
                              }
                            : null
                        }
                      />
                    </div>

                    {/* <div className='w-50'>
                      <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                        Payment Terms
                      </label>
                      <input
                        type='text'
                        name='paymentTerms'
                        aria-label=''
                        placeholder='Enter Payment Terms'
                        className='form-control form-control-solid'
                        onChange={handleChange}
                        value={bills.paymentTerms}
                      />
                    </div> */}

                    <div className='row gx-10 mb-5'>
                      {/* begin::Col */}
                      <div className='col-lg-4'>
                        <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                          Bill Date
                        </label>
                        <Flatpickr
                          value={bills.billDate}
                          onChange={handleBillDateChange}
                          className='form-control form-control-solid'
                          placeholder='Pick date'
                          options={{
                            dateFormat: 'd-m-Y',
                          }}
                        />
                      </div>
                      <div className='col-lg-4'>
                        <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                          Payment Terms
                        </label>
                        <Select
                          name='terms'
                          aria-label='Select a Terms'
                          placeholder='Select a Terms'
                          options={termsOption}
                          className='form-control form-control-solid'
                          onChange={handleTermsChange}
                          value={selectedTerms}
                        />
                      </div>
                      <div className='col-lg-4'>
                        <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                          Due Date
                        </label>
                        <Flatpickr
                          value={bills.dueDate}
                          onChange={handledueDateChange}
                          className='form-control form-control-solid'
                          placeholder='Pick date'
                          options={{
                            dateFormat: 'd-m-Y',
                          }}
                        />
                      </div>
                      {/* begin::Notes */}
                      <div className='mb-0'>
                        <label className='form-label fs-6 fw-bold text-gray-700'>Subject</label>
                        <input
                          type='textarea'
                          name='subject'
                          className='form-control form-control-solid'
                          placeholder=''
                          value={bills.subject}
                          onChange={handleChange}
                        />
                      </div>
                      <div></div>
                      {/* Notes */}

                      {/* Col */}
                    </div>
                    {/* begin::Table wrapper */}
                    <div className='fs-6 fw-bold text-gray-700 mb-3'>ITEMS & DESCRIPTION</div>
                    <div className='table-responsive mb-10'>
                      {/* begin::Table */}
                      <table
                        className='table g-5 gs-0 mb-0 fw-bold text-gray-700'
                        data-kt-element='items'
                      >
                        {/* begin::Table head */}
                        <thead>
                          <tr className='border-bottom fs-7 fw-bold text-gray-700 text-uppercase'>
                            <th className='min-w-100px w-500px'>Item</th>
                            <th className='min-w-150px w-250px'>Quantity</th>
                            <th className='min-w-50px w-250px'>Rate</th>
                            <th className='min-w-70px w-150px'>VAT</th>
                            <th className='min-w-70px w-250px'>Amount</th>
                          </tr>
                        </thead>
                        {/* Table head */}
                        {/* begin::Table body */}
                        <tbody>
                          {selectedPurchaseReceiveItems?.map((item, index) => (
                            <tr
                              className='border-bottom border-bottom-dashed'
                              data-kt-element='item'
                              key={index}
                            >
                              <td className='pe-7'>
                                {/* begin::Input group */}
                                <input
                                  type='text'
                                  className='form-control form-control-solid'
                                  name='productName'
                                  placeholder=''
                                  value={item?.productName}
                                  disabled={true}
                                />
                              </td>
                              <td className='pe-7'>
                                {/* begin::Input group */}

                                <input
                                  type='number'
                                  className='form-control form-control-solid'
                                  name='quantity'
                                  placeholder='0.00'
                                  max={item?.selectedOption?.inWarehouseQuantity}
                                  min={0}
                                  value={item.quantity}
                                  disabled={true}

                                  // onChange={(e) => handleChangerows(index, e)}
                                />
                              </td>

                              <td className='pe-7'>
                                {/* begin::Input group */}

                                <input
                                  type='number'
                                  className='form-control form-control-solid'
                                  name='price'
                                  placeholder='0.00'
                                  value={item.price}
                                  disabled={true}

                                  // onChange={(e) => handleChangerows(index, e)}
                                />
                              </td>

                              <td className='pe-7'>
                                {/* begin::Input group */}

                                <input
                                  type='number'
                                  className='form-control form-control-solid'
                                  name='discount'
                                  placeholder='0.00'
                                  value={item.discount}
                                  disabled={true}

                                  // onChange={(e) => handleChangerows(index, e)}
                                />
                              </td>

                              <td>
                                <input
                                  type='number'
                                  className='form-control form-control-solid text-start'
                                  name='amount'
                                  placeholder='0.00'
                                  value={item.amount}
                                  disabled
                                  // onChange={(e) => handleChangerows(index, e)}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        {/* Table body */}
                        {/* begin::Table foot */}
                        <tfoot>
                          <tr className='border-top border-top-dashed align-top fs-6 fw-bold text-gray-700'>
                            <th className='text-primary'></th>
                            <th className='text-primary'></th>
                            <th className='text-primary'></th>
                            <th className='border-bottom border-bottom-dashed ps-0'>
                              <div className='d-flex flex-column align-items-center'>
                                <div className='fs-5 w-150 '>Subtotal</div>
                                <button
                                  className='btn btn-link w-150 '
                                  data-bs-toggle='tooltip'
                                  data-bs-trigger='hover'
                                  title='Tax in percentage'
                                >
                                  Add tax %
                                </button>
                              </div>
                            </th>
                            <th className='border-bottom border-bottom-dashed px-6 text-end'>
                              <span data-kt-element='sub-total' className=' '>
                                {bills?.subtotal?.toLocaleString('en-US', {
                                  minimumFractionDigits: 2,
                                })}{' '}
                                {selectedVendorCurrency}
                              </span>
                              <input
                                type='number'
                                className='form-control form-control-solid text-end'
                                name='tax'
                                placeholder='0.00'
                                value={bills.tax}
                                onChange={handleChange}
                              />
                            </th>
                          </tr>
                          <tr className='align-top fw-bold text-gray-700'>
                            <th className='min-w-100px w-100px'></th>
                            <th className='min-w-150px w-150px'></th>
                            <th className='min-w-150px w-150px'></th>
                            <th className='fs-4 ps-0 min-w-50px w-50px text-center'>Total</th>
                            <th className='text-end fs-4 text-nowrap min-w-70px w-70px px-6'>
                              <span data-kt-element='grand-total '>
                                {' '}
                                {bills?.total?.toLocaleString('en-US', {
                                  minimumFractionDigits: 2,
                                })}{' '}
                                {selectedVendorCurrency}
                              </span>
                            </th>
                          </tr>
                        </tfoot>
                        {/* Table foot */}
                      </table>
                    </div>
                    {/* Table */}
                    {/* begin::Item template */}

                    {/* Item template */}
                    {/* begin::Notes */}
                    <div className='mb-0 position-relative '>
                      <label className='form-label fs-6 fw-bold text-gray-700'>Notes</label>
                      <textarea
                        name='notes'
                        className={` form-control form-control-solid mb-0 h-10 ${
                          isEditingNotes ? 'border border-dark-subtle' : ''
                        }`}
                        placeholder='Enter Notes'
                        value={bills.notes}
                        onChange={handleChange}
                        readOnly={!isEditingNotes}
                        onKeyDown={handleKeyPress}
                        style={{
                          minHeight: '6rem',
                          height: notesTextareaHeight,
                          resize: 'none',
                        }}
                      />
                      <button onClick={(e) => handleEditClick(e, 'notes')}>
                        <KTIcon
                          iconName='pencil'
                          className='position-absolute text-black bg-gray-100 right-8 top-[3rem] fs-3'
                        />
                      </button>
                    </div>

                    <div className='mb-10 position-relative'>
                      <label className='form-label fs-6 fw-bold text-gray-700'>
                        Terms & Conditions
                      </label>
                      <textarea
                        name='termsCondition'
                        style={{
                          minHeight: '6rem',
                          height: termsTextareaHeight,
                          resize: 'none',
                        }}
                        className={`  form-control form-control-solid mb-0 h-10 ${
                          isEditingTerms ? 'border border-dark-subtle' : ''
                        }`}
                        placeholder='Enter terms and conditions'
                        value={bills.termsCondition}
                        onChange={handleChange}
                        readOnly={!isEditingTerms}
                      />
                      <button onClick={(e) => handleEditClick(e, 'termsCondition')}>
                        <KTIcon
                          iconName='pencil'
                          className='absolute text-black bg-gray-100 right-8 top-[3rem] fs-3'
                        />
                      </button>
                    </div>

                    <button
                      className='btn btn-primary w-30'
                      id='kt_quote_submit_button'
                      disabled={loading}
                      onClick={handleSubmit}
                    >
                      <i className='ki-duotone ki-triangle fs-3'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>
                      Save as open
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddBills
