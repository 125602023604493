import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  services: [],
  loading: true,
  fetch: true,
}

const servicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    setCRMServices: (state, action) => {
      state.services = action.payload
      state.loading = false
      state.fetch = false
    },
    setCRMServicesLoading: (state, action) => {
      state.loading = action.payload
    },
    setCRMServicesFetch: (state, action) => {
      state.fetch = action.payload
    },
  },
})

export const {setCRMServices, setCRMServicesLoading, setCRMServicesFetch} = servicesSlice.actions
export default servicesSlice.reducer
