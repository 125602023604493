import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../modules/auth'
import {getAllProjectTimesheetByOrg} from '../../core/_requests'

const SummaryInvoiceList = ({type = 'Summary'}) => {
  const {currentUser} = useAuth()
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState([])

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {})
    return formattedDate
  }

  useEffect(() => {
    const fetchData = async () => {
      await getAllProjectTimesheetByOrg(currentUser.organization).then((res) => {
        setData(res)
        setIsLoading(false)
      })
    }

    if (currentUser.organization !== undefined) fetchData()
  }, [currentUser.organization])

  return (
    <div className='card card-flush'>
      <span className='card-label fw-bold fs-3 ps-15 pt-8 '>{type} Invoices</span>

      <div className='card-body py-3'>
        {isLoading ? (
          <div className='d-flex align-items-center justify-center'>
            <div className='loader'></div>
          </div>
        ) : (
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4 table-row-bordered'>
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-50px rounded-start'>Sr. No.</th>
                  <th className='min-w-150px text-center'>Month</th>
                  <th className='min-w-150px text-center'>Project Name</th>
                  <th className='min-w-150px text-center'>Start Date</th>
                  <th className='min-w-150px '>End Date</th>
                </tr>
              </thead>

              <tbody>
                {data?.map((item: any, index: number) => (
                  <tr key={item?._id?.toString()}>
                    <td>
                      <Link
                        to={`${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 ps-5'
                      >
                        {index + 1}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center'
                      >
                        {item?.month}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center'
                      >
                        {item?.projectId?.projectName}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center'
                      >
                        {formatCreatedAt(item?.startDate)}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {formatCreatedAt(item?.endDate)}
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  )
}

export default SummaryInvoiceList
