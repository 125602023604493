import React, {useEffect, useRef, useState} from 'react'
import Select from 'react-select'
import {KTIcon} from '../../../../../_metronic/helpers'
import {getFilteredStocks} from '../../../sales/Core/_requests'
import {useAuth} from '../../../../modules/auth'
interface props {
  products: any
  setProducts: any
  setData: any
  data: any
  type?: string
  key?: string
}

const ProductTable = ({products, setProducts, setData, data, type, key}: props) => {
  const [productList, setProductList] = useState([])
  const {currentUser} = useAuth()
  const initialRef = useRef(true)
  const [items, setItems] = useState([
    {
      itemId: '',
      price: '',
      quantity: 0,
      amount: '',
      selectedOption: {
        value: '',
        label: '',
        inWarehouseQuantity: 0,
      },
      type: 'product',
    },
  ])
  const [subTotal, setSubTotal] = useState(0)

  console.log('items', type, items)

  console.log('products', products)

  useEffect(() => {
    const data = products.map((p) => ({
      itemId: p.itemId?._id,
      price: p.price,
      quantity: p.quantity,
      amount: p.amount,
      type: p.type,
      selectedOption: {
        value: p.itemId?._id,
        label: p?.itemId?.productName,
        inWarehouseQuantity: p.quantity,
      },
    }))
    if (data.length !== 0 && initialRef.current) {
      setItems(data)
      setProducts(data)
      initialRef.current = false
    }
  }, [products])

  const getProductlist = async () => {
    try {
      const res = await getFilteredStocks(currentUser?.organization, type)
      const data = res.map((r) => ({
        value: r?._id,
        label: r?.productName,
        price: r?.price,
        inWarehouseQuantity: r?.inWarehouseQuantity,
        type: 'product',
      }))
      setProductList(data)
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  useEffect(() => {
    if (currentUser.organization !== undefined) {
      getProductlist()
    }
  }, [currentUser, type])

  const additem = () => {
    const newItems = [
      ...items,
      {
        itemId: '',
        price: '',
        quantity: 0,
        amount: '',
        selectedOption: {
          value: '',
          label: '',
          inWarehouseQuantity: 0,
        },
        type: 'product',
      },
    ]
    setItems(newItems)
    setProducts(newItems)
  }

  const removeitem = (index) => {
    if (items.length === 1) {
      setItems([
        {
          itemId: '',
          price: '',
          quantity: 0,
          amount: '',
          selectedOption: {
            value: '',
            label: '',
            inWarehouseQuantity: 0,
          },
          type: 'product',
        },
      ])
      return
    }
    const updatedItems = [...items]
    updatedItems.splice(index, 1)
    setItems(updatedItems)
    setProducts(updatedItems)
  }

  const handleChangerows = (index, e) => {
    const {name, value} = e.target
    if (name === 'quantity') {
      console.log(value, items[index].selectedOption.inWarehouseQuantity)
      if (value > items[index].selectedOption.inWarehouseQuantity) {
        return
      }
    }
    setItems((prevItems) => {
      const updatedItems = [...prevItems]
      updatedItems[index] = {
        ...updatedItems[index],
        [name]: value,
      }
      return updatedItems
    })
    setItems((prevItems) => {
      const updatedItems = [...prevItems]
      const data: any = (
        Number(prevItems[index].price) * Number(prevItems[index].quantity)
      ).toFixed(2)

      updatedItems[index] = {
        ...updatedItems[index],
        amount: data,
      }
      setProducts(updatedItems)
      return updatedItems
    })
  }

  const handleSelectChangeInRows = (index, selectedOption) => {
    console.log(selectedOption)
    setItems((prevItems) => {
      const updatedItems = [...prevItems]
      updatedItems[index] = {
        ...updatedItems[index],
        itemId: selectedOption.value,
        selectedOption,
        price: selectedOption.price,
        amount: selectedOption.price,
        quantity: 1,
        type: selectedOption.type,
      }
      setProducts(updatedItems)
      return updatedItems
    })
  }

  useEffect(() => {
    const calculateSubTotal = () => {
      const total = items.reduce((acc, curr) => acc + parseFloat(curr.amount || '0'), 0)
      return total // Return the total value
    }
    const newSubTotal = calculateSubTotal()
    setSubTotal(newSubTotal) // Update the subTotal state
    setData({
      ...data,
      productSubTotal: newSubTotal, // Update data.productSubTotal
    })
  }, [items, setData])

  return (
    <div className='px-9' key={key}>
      <div className='fs-6 fw-bold text-gray-700 mb-3 '>Items Table</div>
      <div className='table-responsive mb-10'>
        <table className='table g-5 gs-0 mb-0 fw-bold text-gray-700' data-kt-element='items'>
          <thead>
            <tr className='border-bottom fs-7 fw-bold text-gray-700 text-uppercase'>
              <th className='min-w-100px w-500px'>Item</th>
              <th className='min-w-150px w-175px'>Quantity</th>
              <th className='min-w-50px w-250px'>Rate</th>
              <th className='min-w-70px w-250px'>Amount</th>
              <th className='min-w-70px w-200px'>Action</th>
            </tr>
          </thead>

          <tbody>
            {items.map((item, index) => (
              <tr className='border-bottom border-bottom-dashed' data-kt-element='item' key={index}>
                <td className='pe-7'>
                  <Select
                    name='itemId'
                    aria-label='Select an Item'
                    placeholder='Select an Item'
                    options={productList}
                    isSearchable={true}
                    className='form-control form-control-solid'
                    onChange={(e) => handleSelectChangeInRows(index, e)}
                    value={item.selectedOption}
                    required
                  />
                </td>
                <td className='pe-7'>
                  {/* begin::Input group */}

                  <input
                    type='number'
                    className='form-control form-control-solid'
                    name='quantity'
                    placeholder='0.00'
                    max={item?.selectedOption?.inWarehouseQuantity}
                    min={0}
                    value={item.quantity}
                    onChange={(e) => handleChangerows(index, e)}
                  />
                </td>

                <td className='pe-7'>
                  {/* begin::Input group */}

                  <input
                    type='number'
                    className='form-control form-control-solid'
                    name='price'
                    placeholder='0.00'
                    disabled
                    value={item.price}
                    onChange={(e) => handleChangerows(index, e)}
                  />
                </td>

                <td>
                  <input
                    type='number'
                    className='form-control form-control-solid text-end'
                    name='amount'
                    placeholder='0.00'
                    value={item.amount}
                    disabled
                    // onChange={(e) => handleChangerows(index, e)}
                  />
                </td>
                <td>
                  <div
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3'
                    onClick={additem}
                  >
                    <KTIcon iconName='plus-square' className='fs-3' />
                  </div>
                  <div
                    className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm '
                    onClick={() => removeitem(index)}
                  >
                    <KTIcon iconName='trash' className='fs-3' />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>

          <tfoot>
            <tr className='border-top border-top-dashed align-top fs-6 fw-bold text-gray-700'>
              <th className='text-primary'></th>
              <th className='text-primary'></th>
              <th className='text-primary'></th>
              <th className='border-bottom border-bottom-dashed ps-0'>
                <div className='d-flex flex-column align-items-end'>
                  <div className='fs-5'>Subtotal</div>
                </div>
              </th>
              <th className='border-bottom border-bottom-dashed text-center'>
                <span data-kt-element='sub-total' className=' '>
                  {subTotal.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                  })}
                </span>
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  )
}

export default ProductTable
