import React from 'react'
import {useAuth} from '../../../modules/auth'

interface DashboardStatsProps {
  title: string
  number: string | number
  amount: number
  className?: string
  loading?: boolean
}

const DashboardStats: React.FC<DashboardStatsProps> = ({
  title,
  number,
  amount,
  className,
  loading,
}) => {
  const {currentUser} = useAuth()
  return (
    <div className={`rounded-xl p-6 transition-all duration-300 hover:shadow-xl ${className}`}>
      <h3 className='text-2xl font-bold mb-4 text-gray-800'>{title}</h3>
      <div className='flex flex-col space-y-3'>
        <div className='flex items-center'>
          <span className='text-3xl font-bold '>{loading ? 'Loading...' : number}</span>
          <span className='ml-2 text-sm font-medium text-gray-500'>Total</span>
        </div>
        <div className='flex items-center'>
          <span className='text-2xl font-semibold'>
            {currentUser?.baseCurrency}{' '}
            {loading ? 'Loading...' : amount?.toLocaleString('en-US', {minimumFractionDigits: 2})}
          </span>
          <span className='ml-2 text-sm font-medium text-gray-500'>Amount</span>
        </div>
      </div>
    </div>
  )
}

export default DashboardStats
