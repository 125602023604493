import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import '../print.css'
import {getPurchaseQuotationById} from '../../Core/_requests'
import {getTerms} from '../../../account_settings/core/_request'
import {useAuth} from '../../../../modules/auth'

function PurchaseOrderMultiLang({companyLogo, color, setColor}) {
  const {currentUser} = useAuth()
  const NUMBERS = new Map([
    [1e9, 'Billion'],
    [1e6, 'Million'],
    [1e3, 'Thousand'],
    [1e2, 'Hundred'],
    [90, 'Ninety'],
    [80, 'Eighty'],
    [70, 'Seventy'],
    [60, 'Sixty'],
    [50, 'Fifty'],
    [40, 'Forty'],
    [30, 'Thirty'],
    [20, 'Twenty'],
    [19, 'Nineteen'],
    [18, 'Eighteen'],
    [17, 'Seventeen'],
    [16, 'Sixteen'],
    [15, 'Fifteen'],
    [14, 'Fourteen'],
    [13, 'Thirteen'],
    [12, 'Twelve'],
    [11, 'Eleven'],
    [10, 'Ten'],
    [9, 'Nine'],
    [8, 'Eight'],
    [7, 'Seven'],
    [6, 'Six'],
    [5, 'Five'],
    [4, 'Four'],
    [3, 'Three'],
    [2, 'Two'],
    [1, 'One'],
    [0, 'Zero'],
  ])

  const numberToWords = (num) => {
    for (const [intValue, textValue] of NUMBERS as any) {
      if (num >= intValue) {
        const prefix = num >= 100 ? numberToWords(Math.trunc(num / intValue)) : ''
        const suffix = num % intValue > 0 ? numberToWords(num % intValue) : ''
        return `${prefix} ${textValue} ${suffix}`.trim()
      }
    }
    return ''
  }

  const [vat, setVat] = useState(0)
  const [data, setPurchsaseOrderData] = useState<any>()
  const [contactPerson, setContactPerson] = useState<any>()
  const [imageSize, setImageSize] = useState<any>(false)
  const [annexure, setAnnexure] = useState<any>('')
  const [page, setPage] = useState(2)
  const [extraPages, setExtraPages] = useState(0)

  const {id} = useParams()

  const handleOnLoad = (e) => {
    const img = e.target
    if (img.naturalWidth > img.naturalHeight) {
      setImageSize(true)
    } else {
      setImageSize(false)
    }
  }

  useEffect(() => {
    const getData = async () => {
      const res = await getPurchaseQuotationById(id)
      setPurchsaseOrderData(res)
      console.log('res', res)
      setColor(res?.company?.procurementColor)
      if (res?.items?.length > 10) {
        const pages = Math.ceil((res?.items.length - 10) / 20)
        if ((res?.items.length - 13) / 20 > 17) {
          setPage(pages + 3)
        } else {
          setPage(pages + 2)
        }
      }
      if (res?.contactPerson !== '') {
        setContactPerson(
          res?.vendor?.contactPersons?.find((person) => person?._id === res?.contactPerson) || {}
        )
      } else {
        setContactPerson(res?.vendor?.primaryContact)
      }
      const annexureRes = await getTerms(currentUser?.organization, 'Purchase Orders')
      console.log('annexure', annexureRes)
      setAnnexure(annexureRes?.purchaseOrderTerms?.annexure)
      // if (res?.termsCondition?.length > 2200) {
      //   setExtraPages(Math.ceil(res?.termsCondition?.length / 2200))
      // }
      if (annexureRes?.purchaseOrderTerms?.annexure?.length > 3100) {
        setExtraPages(Math.ceil(annexureRes?.purchaseOrderTerms?.annexure?.length / 3100))
      }
    }
    getData()
  }, [])

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })
    return formattedDate
  }

  useEffect(() => {
    if (data?.tax) {
      const tax = (data?.subtotal * data?.tax) / 100
      setVat(tax)
    }
  }, [data])

  const PF = 'https://qf-assets.s3.me-central-1.amazonaws.com/'

  const PageContent = Array.from({length: extraPages === 0 ? page : page + extraPages - 1}).map(
    (_, idx) => (
      <div
        key={idx}
        id={`pdf-content-${idx}`}
        className='card overflow-hidden mx-auto !rounded-none'
        style={{
          height: '297mm',
          width: '210mm',
        }}
      >
        <div id='pdfHeader' className='p-2 mx-3 mt-2 mb-4'>
          <div className='d-flex justify-content-between align-items-center flex-column flex-sm-row'>
            {/*end::Logo*/}
            <div className='text-sm-end'>
              {/*begin::Logo*/}
              <a href='#' className={`d-block ${imageSize ? 'mw-275px' : 'mw-150px'} ms-sm-auto`}>
                {companyLogo ? (
                  <img
                    alt='Logo'
                    src={URL.createObjectURL(companyLogo)}
                    className={imageSize ? 'w-100' : 'w-50'}
                    onLoad={handleOnLoad}
                  />
                ) : (
                  <img
                    alt='thumbnail'
                    src={PF + data?.company?.companyLogo}
                    className={imageSize ? 'w-100' : 'w-50'}
                    onLoad={handleOnLoad}
                  />
                )}
              </a>
              {/*end::Logo*/}
            </div>
            <h4 className='fw-bold text-gray-800 xl:fs-1 d-flex flex-column text-right header-text'>
              <span
                className='mb-1 text-right fs-1 tracking-wider '
                style={{
                  fontFamily: 'Rubik',
                  fontOpticalSizing: 'auto',
                  fontStyle: 'normal',
                  wordSpacing: '10px',
                }}
              >
                {data?.company?.arabicName}
              </span>
              <span
                className='mb-1 text-right fs-3 tracking-wider'
                style={{
                  color: color,
                }}
              >
                {data?.company?.name}
              </span>
              <span className='fs-6 text-muted'>
                VAT:{data?.company?.vat} <span style={{color: color}}>|</span> C.R :{' '}
                {data?.company?.cr}
              </span>
            </h4>
          </div>
        </div>
        {/* end::Top */}

        {/* begin::Wrapper */}
        <div className='border-b-black border-b mx-9'></div>
        {/* begin::Body */}
        <div className='card-body p-lg-15 '>
          {/* begin::Layout */}

          <div className='d-flex flex-column'>
            {/* begin::Content */}
            <div className='flex-lg-row-fluid mb-10 mb-xl-0'>
              {/* begin::Invoice 1 content */}
              <div className='mt-n1'>
                {/* begin::Top */}
                <div className=''>
                  {idx === 0 && (
                    <div className='mb-6 fs-2 fw-bold text-gray-800'>
                      <div className='uppercase'>
                        أمر شراء
                        <br />
                        Purchase Order
                      </div>
                    </div>
                  )}
                  {idx === 0 && (
                    <div
                      className='d-flex justify-content-between flex-column flex-sm-row'
                      style={{textTransform: 'uppercase'}}
                    >
                      <div className=' mb-5 '>
                        <div className='d-flex  flex-column mb-5'>
                          <span className='fw-bold '>
                            اسم البائع
                            <br />
                            Vendor Name
                          </span>
                          <span className='fs-7'>
                            {contactPerson?.salutation} {contactPerson?.firstName}{' '}
                            {contactPerson?.lastName}
                          </span>
                        </div>
                        {/* <div className='d-flex flex-column mb-5'>
                          <span className='fw-bold'>Delivery Address</span>

                          <span className='fs-7 mw-200px'>
                            {data?.company?.name}
                            <br />
                            {data?.company?.companyAddress}{' '}
                          </span>
                        </div> */}
                        <div className='d-flex  flex-column mb-5'>
                          <span className='fw-bold'>
                            أمر شراء
                            <br />
                            PO Number
                          </span>
                          <span className='fs-7'>{data?.id}</span>
                        </div>
                        {/* <div className='d-flex flex-md-root flex-column  mb-5'>
                          <span className='fw-bold mb-1'>Payment Terms</span>
                          <span className='fs-7 mw-200px'>{data?.vendor?.paymentTerms}</span>
                        </div> */}
                        <div className='d-flex flex-md-root flex-column  mb-5'>
                          <span className='fw-bold mb-1'>
                            عنوان البائع
                            <br />
                            Vendor Address
                          </span>
                          <span className='fs-7 mw-200px'>
                            {data?.vendor?.shippingAddress?.addressLine1}{' '}
                            {data?.vendor?.shippingAddress?.addressLine2}
                            <br />
                            {data?.vendor?.shippingAddress?.city}{' '}
                            {data?.vendor?.shippingAddress?.postalCode}
                            <br />
                            {data?.vendor?.shippingAddress?.country}
                          </span>
                        </div>
                      </div>
                      <div className='  mb-5 '>
                        <div className=' d-flex flex-column mb-5 mt-16'>
                          <span className='fw-bold'>
                            رقم البائع
                            <br />
                            Vendor Number
                          </span>
                          <span className='fs-7'>{data?.vendor?.vendorSupplierNo}</span>
                        </div>
                        <div className=' d-flex flex-column  '>
                          <span className='fw-bold'>
                            مصطلحات التجارة الدولية
                            <br />
                            Inco Terms
                          </span>
                          <span className='fs-7'>#{data?.incoTerms}</span>
                        </div>
                      </div>
                      <div className='text-end  mb-5  '>
                        <div className='d-flex flex-column mb-5'>
                          <span className='fw-bold'>
                            تاريخ الطلب
                            <br />
                            PO Date
                          </span>

                          <span className='fs-7'>{formatCreatedAt(data?.date)}</span>
                        </div>
                        <div className='d-flex flex-column mb-5'>
                          <span className='fw-bold'>
                            رقم طلب العروض
                            <br />
                            RFQ Number
                          </span>

                          <span className='fs-7'>{data?.reference}</span>
                        </div>
                      </div>
                    </div>
                  )}
                  {/*end::Row*/}
                  {/*begin::Content*/}
                  <div className='flex-grow-1'>
                    {/*begin::Table*/}
                    <div className='table-responsive'>
                      {idx + 1 < page && (
                        <table className='table table-bordered border-black m-0'>
                          <thead>
                            <tr
                              className='fs-9 fw-bold uppercase'
                              style={{
                                backgroundColor: color,
                              }}
                            >
                              <th className='min-w-50px text-white text-start pb-3 '>
                                رقم سري
                                <br />
                                <span>Sl. No.</span>
                              </th>

                              <th className='min-w-150px text-white text-center pb-3 '>
                                وصف
                                <br />
                                Description
                              </th>

                              <th className='min-w-70px text-white text-center pb-3'>
                                كمية
                                <br />
                                QTY
                              </th>
                              <th className='min-w-80px text-white text-center pb-3'>
                                وحدة قياس
                                <br />
                                UOM
                              </th>

                              <th className='min-w-80px text-white text-center pb-3'>
                                سعر الوحدة
                                <br />
                                Unit Price
                              </th>

                              <th className='min-w-80px text-white text-center pb-3'>
                                المجموع
                                <br />
                                Total
                              </th>
                            </tr>
                          </thead>
                          <tbody className='' style={{height: '250px'}}>
                            {data?.items
                              ?.slice(
                                idx === 1 || idx === 0 ? idx * 13 : idx * 20 - 7,
                                idx === 0 ? 13 : idx * 20 + 13
                              )
                              .map((item, index) => (
                                <tr
                                  key={index}
                                  className='fw-semibold text-gray-600 fs-7 text-center'
                                >
                                  <td className=''>
                                    {idx === 0
                                      ? index + 1
                                      : idx === 1
                                      ? index + 14
                                      : index + idx * 20 - 6}
                                  </td>
                                  <td>{item?.productName}</td>
                                  <td className=''>{item.quantity}</td>
                                  <td className=''>UOM</td>
                                  <td className='text-end'>
                                    {Number(item?.price).toLocaleString('en-US', {
                                      minimumFractionDigits: 2,
                                    })}
                                  </td>
                                  <td className='text-gray-900 fw-bolder text-end'>
                                    {Number(item?.amount).toLocaleString('en-US', {
                                      minimumFractionDigits: 2,
                                    })}{' '}
                                  </td>
                                </tr>
                              ))}
                            {((page === 2 && idx === 0) || idx + 2 === page) && (
                              <>
                                <tr className=''>
                                  <td colSpan={2} className='p-0'>
                                    <table className='table w-full m-0 border-transparent'>
                                      <tbody
                                        className='min-w-80px'
                                        style={{display: 'flex', flexDirection: 'column'}}
                                      >
                                        <tr className=''>
                                          <div className='d-flex flex-column'>
                                            <span className='fw-bold'>
                                              عنوان التسليم
                                              <br />
                                              Delivery Address
                                            </span>

                                            <span className='fs-7 mw-200px'>
                                              {data?.company?.name}
                                              <br />
                                              {data?.company?.companyAddress}
                                            </span>
                                          </div>
                                          <div className='d-flex flex-md-root flex-column '>
                                            <span className='fw-bold mb-1'>
                                              شروط الدفع
                                              <br />
                                              Payment Terms
                                            </span>
                                            <span className='fs-7 mw-200px'>
                                              {data?.vendor?.paymentTerms}
                                            </span>
                                          </div>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                  <td colSpan={2} className='p-0 text-sm'>
                                    <table className='table table-bordered fs-8 w-full m-0 p-0 '>
                                      <thead>
                                        <tr className='hidden'>
                                          <th className='min-w-80px'></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr className='text-end border-b-black'>
                                          <td className='uppercase'>
                                            المجموع الفرعي
                                            <br />
                                            Subtotal
                                          </td>
                                        </tr>
                                        <tr className='text-end border-b-black'>
                                          <td className='uppercase'>
                                            إجمالي الوزن المبلغ
                                            <br />
                                            Total VAT Amount ({data?.tax}%)
                                          </td>
                                        </tr>
                                        <tr className='text-end'>
                                          <td className='fw-bold uppercase'>
                                            المجموع
                                            <br />
                                            Total
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>

                                  <td colSpan={2} className='p-0 text-sm'>
                                    <table
                                      className='table table-bordered fs-8 w-full m-0 p-0 '
                                      // style={{
                                      //   border: 'transparent',
                                      // }}
                                    >
                                      <thead>
                                        <tr className='hidden'>
                                          {/* <th className='min-w-80px'></th> */}
                                          <th className='min-w-80px'></th>
                                          <th className='min-w-80px'></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr className='text-center border-b-black'>
                                          <td className='min-w-80px border-r-black'>
                                            {}
                                            <br />
                                            {data?.vendor?.currency}
                                          </td>
                                          <td className='min-w-80px text-end'>
                                            {}
                                            <br />
                                            {data?.subtotal.toLocaleString('en-US', {
                                              minimumFractionDigits: 2,
                                            })}{' '}
                                          </td>
                                        </tr>
                                        <tr className=' text-center p-2 border-b-black'>
                                          <td className='text-center border-r-black'>
                                            <br />
                                            {data?.vendor?.currency}
                                          </td>
                                          <td className='text-end'>
                                            <br />
                                            {vat}
                                          </td>
                                        </tr>
                                        <tr className='text-center p-2 fw-bold'>
                                          <td className='border-r-black'>
                                            <br />

                                            {data?.vendor?.currency}
                                          </td>
                                          <td className='text-end'>
                                            <br />
                                            {data?.total?.toLocaleString('en-US', {
                                              minimumFractionDigits: 2,
                                            })}{' '}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                  {/* Empty cell for design consistency */}
                                </tr>
                                <tr className='py-1 fw-bold text-end fs-8'>
                                  <td colSpan={2} className='text-start uppercase'>
                                    المجموع بالكلمات
                                    <br />
                                    Total in words
                                    <br />
                                    {numberToWords(data?.total)}
                                  </td>
                                  <td colSpan={2}>
                                    صافي المجموع
                                    <br />
                                    Net Total
                                  </td>
                                  <td className='text-center'>{data?.vendor?.currency}</td>
                                  <td className='text-end'>
                                    {data?.total?.toLocaleString('en-US', {
                                      minimumFractionDigits: 2,
                                    })}{' '}
                                  </td>
                                </tr>
                              </>
                            )}
                          </tbody>
                        </table>
                      )}
                    </div>
                    {/*end::Table*/}
                    {/*begin::Container*/}
                    {/*end::Container*/}
                  </div>
                  {/*end::Content*/}
                </div>

                {/* end::Wrapper */}
              </div>
              {/* end::Order 2 content */}
            </div>
            {/* end::Content */}
            {/* begin::Sidebar */}
            <div className='m-0 '>
              {/* begin::Invoice 2 sidebar */}
              {/* d-print-none */}
              <div className='p-4 bg-lighten '>
                {/* begin::Labels */}

                {((page === 2 && idx === 0) || (page !== 2 && idx + 1 === page + extraPages)) && (
                  <div className={`m-0  px-10 py-2 `}>
                    <div className='d-flex  flex-column '>
                      <h6 className='fw-bold text-danger'>ملحوظات Note</h6>
                      <p
                        className='min-h-40px'
                        style={{
                          lineHeight: '8px',
                        }}
                      >
                        {data?.notes?.split('\n').map((line, index) => (
                          <p key={index}>{line}</p>
                        ))}
                      </p>
                    </div>
                  </div>
                )}
                {idx + 1 >= page && (
                  <div className={`m-0  py-2 `}>
                    <div className='d-flex  flex-column '>
                      {idx === page - 1 && (
                        <>
                          <h6 className=' fw-semibold text-center text-black text-hover-primary'>
                            Annexure (1)
                          </h6>
                          <p
                            className='min-h-40px text-justify fs-8'
                            style={{
                              lineHeight: '15px',
                            }}
                          >
                            {data?.termsCondition?.split('\n').map((line, index) => {
                              const [firstSentence, secondSentence] = line.split(':')
                              return (
                                <div
                                  key={index}
                                  style={{display: 'flex', width: '100%', marginBottom: '5px'}}
                                >
                                  <span
                                    style={{
                                      width: '150px',
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      textTransform: 'uppercase',
                                    }}
                                    className='fw-semibold'
                                  >
                                    {firstSentence}
                                  </span>
                                  <span style={{flex: 1, marginLeft: '5px'}}>
                                    :{secondSentence}
                                  </span>
                                </div>
                              )
                            })}
                          </p>
                        </>
                      )}
                      <p
                        className='min-h-40px text-justify fs-8'
                        style={{
                          lineHeight: '15px',
                        }}
                      >
                        {annexure
                          ?.slice(3100 * (idx - page + 1), 3100 * (idx - page + 2))
                          ?.split('\n')
                          .map((line, index) => (
                            <div
                              key={index}
                              style={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                marginBottom: '5px',
                              }}
                            >
                              {line.trim().startsWith('•') && (
                                <span
                                  style={{
                                    width: '20px', // Adjust this width as needed
                                    display: 'inline-block',
                                  }}
                                >
                                  •
                                </span>
                              )}
                              <span
                                style={{
                                  marginLeft: line.trim().startsWith('•') ? '5px' : '25px',
                                  flex: 1,
                                }}
                              >
                                {line.trim().startsWith('•') ? line.slice(1).trim() : line}
                              </span>
                            </div>
                          ))}
                      </p>
                    </div>
                  </div>
                )}
                {idx + 1 === (extraPages === 0 ? page : page + extraPages - 1) && (
                  <div className={`m-0 pt-8 px-10 py-5 `}>
                    <div className='d-flex  justify-content-between '>
                      <h6 className='mb-8 fw-bolder text-gray-600 text-hover-primary'>
                        أُعدت بواسطة
                        <br />
                        Prepared By
                        {data?.agent && data?.agent?.signature !== '' && (
                          <img
                            src={PF + data?.agent?.signature}
                            alt='signature'
                            className='w-80px h-80px mt-3'
                          />
                        )}
                      </h6>

                      <h6 className='mb-4 fw-bolder text-gray-600 text-hover-primary relative'>
                        تمت الموافقة عليه من قبل
                        <br />
                        Approved By
                        {data?.approval === 'accepted' && data?.company?.companySeal !== '' && (
                          <img
                            src={PF + data?.company?.companySeal}
                            alt='company seal'
                            className='w-80px mt-3 h-80px aboslute top-5 right-5 z-1'
                          />
                        )}
                        {data?.approval === 'accepted' &&
                          data?.company?.companySignature !== '' && (
                            <img
                              src={PF + data?.company?.companySignature}
                              alt='company signature'
                              className='w-80px h-80px absolute top-20 right-10 z-10'
                            />
                          )}
                      </h6>

                      <h6 className='mb-4 fw-bolder text-gray-600 text-hover-primary'>
                        استلمت من قبل
                        <br />
                        Received By
                      </h6>
                    </div>
                    {/* end::Invoice 2 sidebar */}
                  </div>
                )}
                {/* end::Item */}
                {/* begin::Item */}

                {/* end::Item */}
                {/* begin::Title */}

                {/* end::Item */}
              </div>
              {/* end::Invoice 2 sidebar */}
            </div>
            {/* end::Sidebar */}
          </div>
          {/* end::Layout */}
        </div>
        {/* end::Body */}
        <div id='pdfFooter' className='card-footer text-center p-2 fw-bold'>
          <div className={`bg-gray-700 ${false && '-skew-x-12'} text-white`}>
            <div className={`${false && 'skew-x-12'} py-1`}>
              {!false &&
                `Mobile :${' '}
        ${data?.company?.mobileNumber} | `}
              E-mail : {data?.company?.companyEmail} | Website : {data?.company?.webURL}
            </div>
          </div>
          <div
            className={`${false && '-skew-x-12'} text-white`}
            style={{
              backgroundColor: `${color}`,
            }}
          >
            <div className={`${false && 'skew-x-12'} py-1`}>
              P.O. Box : {data?.company?.pOBox} | Address: {data?.company?.companyAddress}
            </div>
          </div>
        </div>
        <div className='d-flex justify-center mb-4'>
          <p className='fs-8 serif-font'>
            Page {idx + 1} of {extraPages === 0 ? page : page + extraPages - 1}
          </p>
        </div>
      </div>
    )
  )

  return (
    <>
      <div className='app-content flex-column-fluid'>
        {/* begin::Content container */}

        <div id='kt_app_content_container' className='print-content container-xxl'>
          {/* begin::Invoice 2 main */}
          {PageContent}
          {/* end::Invoice 2 main */}
        </div>
        {/* end::Content container */}
      </div>
    </>
  )
}

export default PurchaseOrderMultiLang
