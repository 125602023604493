import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'
import {KTIcon} from '../../../../_metronic/helpers'
import {getDepartmentPayrollByOrg} from '../core/_requests'

function DepartmentPayrolls() {
  const {currentUser} = useAuth()
  const [loading, setLoading] = useState(true)
  const [payrolls, setPayrolls] = useState([])
  const [permissions, setPermissions] = useState<any>({})

  useEffect(() => {
    if (currentUser) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  useEffect(() => {
    const fetchpayrollList = async () => {
      const res = await getDepartmentPayrollByOrg(currentUser?.organization)
      setLoading(false)
      setPayrolls(res)
    }
    if (currentUser.organization !== undefined) fetchpayrollList()
  }, [])

  return (
    <div className='card card-flush'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Payroll</span>
        </h3>

        {(permissions?.payroll?.create || currentUser?.profileType === 'superadmin') && (
          <div className='card-toolbar'>
            <Link to={'add'} className='btn btn-sm btn-light-primary'>
              <KTIcon iconName='plus' className='fs-2' />
              Add Payroll
            </Link>
          </div>
        )}
      </div>
      <div className='card-body py-3'>
        {loading ? (
          <div className='d-flex align-items-center justify-center'>
            <div className='loader'></div>
          </div>
        ) : (
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4 table-row-bordered'>
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-25px rounded-start'>Sr. No.</th>
                  <th className='min-w-150px text-center'>Department</th>
                  <th className='min-w-150px text-center'>Total Payrolls</th>
                </tr>
              </thead>
              <tbody>
                {payrolls?.map((item: any, index) => (
                  <tr key={item?.departmentId}>
                    <td>
                      <Link
                        to={`${item?.departmentId}`}
                        className='ps-4 text-dark fw-bold text-hover-primary d-block mb-1 fs-6 capitalize'
                      >
                        {index + 1}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`${item?.departmentId}`}
                        className='text-center text-dark fw-bold text-hover-primary d-block mb-1 fs-6 capitalize'
                      >
                        {item?.departmentName}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`${item?.departmentId}`}
                        className='text-center text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {item?.totalPayroll}
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  )
}

export default DepartmentPayrolls
