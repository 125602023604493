import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {getTransactionsByProductId} from '../Core/_requests'
import {KTIcon} from '../../../../_metronic/helpers'
import {formatCurrencyWithoutSymbol} from '../../../utils/utils'

const TransactionTable = () => {
  const id = useParams().id
  const [transactions, setTransactions] = useState<any[]>([])
  const [transactionType, setTransactionType] = useState<string>('')
  const fetchData = async () => {
    const res = await getTransactionsByProductId(id)
    console.log(res)
    setTransactions(res)
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (transactionType !== '') {
      const filteredTransactions = transactions.filter(
        (transaction) => transaction.type === transactionType
      )
      setTransactions(filteredTransactions)
    } else {
      fetchData()
    }
  }, [transactionType])

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })
    return formattedDate
  }

  return (
    <div className='card mt-5'>
      <div className='card-header d-flex align-items-center justify-between'>
        <h4 className='card-title'>Transactions</h4>
        {/* filter  */}
        <div
          className='btn btn-primary me-3'
          data-kt-menu-trigger='hover'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='top-end'
        >
          <KTIcon iconName='filter' className='fs-2' />
          Filter
          <div
            className='menu menu-sub menu-sub-dropdown w-400px w-md-400px p-5'
            data-kt-menu='true'
            style={{zIndex: 1000000}}
          >
            {/* begin::Header */}
            <div className='px-7 py-5'>
              <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
            </div>
            {/* end::Header */}

            {/* begin::Separator */}
            <div className='separator border-gray-200'></div>
            {/* end::Separator */}

            {/* begin::Content */}
            <div className='px-7 py-5' data-kt-user-table-filter='form'>
              {/* begin::Input group */}
              <div className='mb-10 d-flex flex-row align-content-center justify-content-center'>
                <label className='form-label fs-6 fw-bold text-gray-700 mb-3 me-5 mt-5'>
                  Transaction type:
                </label>
                <select
                  className='form-select form-select-solid fw-bolder fs-6 w-200px'
                  onChange={(e) => setTransactionType(e.target.value)}
                >
                  <option value=''>All</option>
                  <option value='invoice'>Invoice</option>
                  <option value='purchase'>Purchase</option>
                  {/* <option value='payment receive'>Payment Receive</option> */}
                  <option value='purchase receive'>Purchase Receive</option>
                  <option value='bill'>Bills</option>
                </select>
              </div>
            </div>
            {/* end::Actions */}
          </div>
          {/* end::Content */}
        </div>
      </div>
      <div className='card-body'>
        <div className='table-responsive'>
          <table className='table table-striped'>
            <thead>
              <tr>
                <th>Date</th>
                <th>Transaction Details</th>
                <th>Type</th>
                <th>Debit</th>
                <th>Credit</th>
              </tr>
            </thead>
            <tbody>
              {transactions?.map((item, index) => (
                <tr key={item?._id}>
                  <td>{formatCreatedAt(item?.createdAt)}</td>
                  <td>{item?.id}</td>
                  <td>{item?.type}</td>
                  <td>{item?.debit !== 0 && formatCurrencyWithoutSymbol(item?.debit)}</td>
                  <td>{item?.credit !== 0 && formatCurrencyWithoutSymbol(item?.credit)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default TransactionTable
