import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {MenuComponent} from '../../../../_metronic/assets/ts/components'
import {ValidFilter} from '../Core/_model'
import {useAuth} from '../../../modules/auth'
import {getOrdersWithProject, getVendor, getVendorByAgentId} from '../Core/_requests'
import {RootState} from '../../../redux/store'
import {useDispatch, useSelector} from 'react-redux'
import {setVendorDataList} from '../../../redux/procurement/vendor/vendorSlice'

type StatusOption = {
  value: string
  label: string
}

type Props = {
  searchFilters: ValidFilter
  setSearchFilter: (updateValues: ValidFilter) => void
  setValidityFilter?: (isValid: boolean) => void
  setApprovalFilter: (approval: string) => void
  setStatusFilter?: (status: string) => void
  getDocsList: () => void
  handlereset: () => void
  showStatusFilter?: boolean
  statusOptions?: StatusOption[]
  showProjectOrder?: boolean
}

const Filter2: React.FC<Props> = ({
  handlereset,
  setSearchFilter,
  searchFilters,
  setValidityFilter,
  getDocsList,
  showStatusFilter = true,
  statusOptions = [],
  showProjectOrder = false,
}) => {
  const {currentUser} = useAuth()
  const [valid, setValid] = useState('valid')
  const [status, setStatus] = useState('')
  const [approval, setApproval] = useState('')
  const [order, setOrder] = useState('')
  const [vendor, setVendor] = useState('')
  const [orderList, setOrderList] = useState([])
  const [permissions, setPermissions] = useState<any>({})
  const vendorDataList = useSelector((state: RootState) => state.procurement.vendor.vendorData)
  const [vendorData, setVendorData] = useState<any>([])
  const dispatch = useDispatch()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const getVendorList = async () => {
    try {
      const res = await getVendor('filter_status=true', currentUser?.organization)
      const temp = res.map((item) => {
        return {
          value: item?._id,
          label: item?.displayName,
        }
      })
      setVendorData(temp)
      return res
    } catch (error) {
      console.error('Error fetching Customer list:', error)
    }
  }

  const getVendorForAgent = async () => {
    try {
      const res = await getVendorByAgentId('filter_status=true', currentUser?._id)
      const temp = res.map((item) => {
        return {
          value: item?._id,
          label: item?.displayName,
        }
      })
      setVendorData(temp)
      return res
    } catch (error) {
      console.error('Error fetching Customer list:', error)
    }
  }

  // useEffect(() => {
  //   if (vendorDataList?.length === 0) {
  //     if (permissions?.vendors?.admin === false) {
  //       getVendorForAgent().then((res) => {
  //         dispatch(setVendorDataList(res))
  //       })
  //     } else if (
  //       permissions?.vendors?.admin === true ||
  //       currentUser?.profileType === 'superadmin'
  //     ) {
  //       getVendorList().then((res) => {
  //         dispatch(setVendorDataList(res))
  //       })
  //     }
  //   } else {
  //     const temp = vendorDataList.map((item) => {
  //       return {
  //         value: item?._id,
  //         label: item?.displayName,
  //       }
  //     })
  //     setVendorData(temp)
  //   }
  // }, [permissions, currentUser, dispatch])

  useEffect(() => {
    const getOrderWithProject = async () => {
      try {
        const res = await getOrdersWithProject(currentUser?.organization)
        setOrderList(res)
      } catch (error) {
        console.error('Error fetching client list:', error)
      }
    }

    if (currentUser.organization !== undefined) getOrderWithProject()
  }, [currentUser])

  useEffect(() => {
    setSearchFilter({
      filter: {
        isValid: valid === 'valid',
        status: status,
        approval: approval,
        order: order,
        vendor: vendor,
      },
    })
  }, [valid, status, approval, order, vendor])

  const handleValidityChange = (e) => {
    const isValid = e.target.value === 'valid'
    setValid(isValid ? 'valid' : 'invalid')
  }

  const handleStatusChange = (e) => {
    setStatus(e.target.value)
  }

  const handleApprovalChange = (e) => {
    setApproval(e.target.value)
  }

  const handleOrderChange = (e) => {
    setOrder(e.target.value)
  }

  const handleVendorChange = (e) => {
    setVendor(e.target.value)
  }

  return (
    <>
      <div
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        data-kt-menu-flip='top-end'
      >
        <KTIcon iconName='filter' className='fs-2' />
        Filter
      </div>
      <div
        className='menu menu-sub menu-sub-dropdown w-400px w-md-400px p-5'
        data-kt-menu='true'
        style={{zIndex: 1000000}}
      >
        <div className='px-7 py-2'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        <div className='separator border-gray-200'></div>
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          {/* <div className='mb-5 d-flex flex-row align-content-center justify-content-center'>
            <label className='form-label fs-6 fw-bold mt-2 w-100px me-5'>Vendor:</label>
            <select
              className='form-select form-select-solid fw-bolder w-75'
              onChange={handleVendorChange}
              value={vendor}
            >
              <option value=''>All</option>
              {vendorData?.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
          </div> */}

          {showStatusFilter && ( // Render status filter only if showStatusFilter is true
            <div className='mb-5 d-flex flex-row align-content-center justify-content-center'>
              <label className='form-label fs-6 fw-bold mt-2 w-100px me-5'>Delivery Status:</label>
              <select
                className='form-select form-select-solid fw-bolder w-75'
                onChange={handleStatusChange}
                value={status}
              >
                <option value=''>All</option>
                {statusOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          )}

          <div className='mb-5 d-flex flex-row align-content-center justify-content-center'>
            <label className='form-label fs-6 fw-bold mt-2 w-100px me-5'>Approval:</label>
            <select
              className='form-select form-select-solid fw-bolder w-75'
              onChange={handleApprovalChange}
              value={approval}
            >
              <option value=''>All</option>
              <option value='pending'>Pending</option>
              <option value='accepted'>Accepted</option>
              <option value='rejected'>Rejected</option>
            </select>
          </div>

          {showProjectOrder && (
            <div className='mb-5 d-flex flex-row align-content-center justify-content-center'>
              <label className='form-label fs-6 fw-bold mt-2 w-100px me-5'>Project Order:</label>
              <select
                className='form-select form-select-solid fw-bolder w-75'
                onChange={handleOrderChange}
                value={order}
              >
                <option value=''>All</option>
                {orderList?.map((item, index) => (
                  <option value={item?._id} key={item?._id}>
                    {item?.orderName}
                  </option>
                ))}
              </select>
            </div>
          )}

          <div className='mb-5 d-flex flex-row align-content-center justify-content-center'>
            <label className='form-label fs-6 fw-bold mt-2 w-100px me-5'>Validity:</label>
            <select
              className='form-select form-select-solid fw-bolder w-75'
              onChange={handleValidityChange}
              // value={searchFilters.filter.isActive ? 'active' : 'inactive'}
              value={valid}
            >
              <option value='valid'>Valid</option>
              <option value='invalid'>Invalid</option>
            </select>
          </div>

          <div className='d-flex justify-content-end'>
            <button
              type='button'
              // disabled={isLoading}
              // onClick={filterData}
              className='btn btn-light btn-light-danger fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
              onClick={handlereset}
            >
              Reset
            </button>
            <button
              type='button'
              // disabled={isLoading}
              // onClick={filterData}
              className='btn btn-light btn-light-primary fw-bold me-2 px-6'
              onClick={getDocsList}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Filter2
