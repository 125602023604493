import React, {useEffect, useState} from 'react'
import {formatCreatedAt} from '../../../utils/utils'
import {getPaymentPendingPO} from '../Core/_requests'
import {useAuth} from '../../../modules/auth'
import {Link} from 'react-router-dom'

interface PaymentPendingPOProps {
  heading: string
  data: {
    _id: string
    date: string
    id: string
    vendor: {
      displayName?: string
    }
    total: number
    dueDate: string
    paymentStatus: string
  }[]
}

const PaymentPendingPO: React.FC = () => {
  const {currentUser} = useAuth()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<PaymentPendingPOProps['data']>([])

  useEffect(() => {
    const fetchData = async () => {
      const res = await getPaymentPendingPO(currentUser.organization)
      setData(res)
      setLoading(false)
    }
    if (currentUser.organization !== undefined) {
      fetchData()
    }
  }, [currentUser.organization])

  return (
    <div className='card !h-600px'>
      <span className='card-label fw-bold fs-3 ps-15 pt-8 pe-10'>
        Total Payment Pending <br />
        <span className='flex justify-between'>
          <span>No.: {data.length}</span>
          <span>
            Amount: {currentUser?.baseCurrency}{' '}
            {data
              .reduce((acc, curr) => acc + curr?.total, 0)
              ?.toLocaleString('en-US', {
                minimumFractionDigits: 2,
              })}
          </span>
        </span>
      </span>
      <div className='card-body py-3'>
        {/* begin::Table container */}
        {loading ? (
          <div className='flex justify-center items-center h-400px'>
            <div className='loader'></div>
          </div>
        ) : (
          <div className={`table-responsive h-400px`}>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-125px rounded-start'>PO Date</th>
                  <th className='min-w-125px'>PO No.</th>
                  <th className='min-w-200px'>Vendor Name</th>
                  <th className='min-w-150px'>Payment</th>
                  <th className='min-w-125px'>Payment Due</th>
                  <th className='min-w-125px text-center rounded-end'>Payment Status</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {data.map((row, index) => (
                  <tr key={index}>
                    <td>
                      <div className='ps-4 text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        {formatCreatedAt(row.date)}
                      </div>
                    </td>
                    <td>
                      <Link to={`/procurement/purchaseOrdersList/${row._id}`}>
                        <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                          {row.id}
                        </div>
                      </Link>
                    </td>
                    <td>
                      <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        {row.vendor?.displayName}
                      </div>
                    </td>
                    <td>
                      <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        {row.total.toLocaleString('en-US', {
                          style: 'currency',
                          currency: currentUser.baseCurrency,
                        })}
                      </div>
                    </td>
                    <td>
                      <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        {formatCreatedAt(row.dueDate)}
                      </div>
                    </td>
                    <td className='text-center'>
                      <span
                        className={`badge badge-light-${
                          row.paymentStatus === 'Paid'
                            ? 'success'
                            : row.paymentStatus === 'Pending'
                            ? 'warning'
                            : 'danger'
                        } fs-7 fw-semibold`}
                      >
                        {row.paymentStatus}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
        )}
        {/* end::Table container */}
      </div>
    </div>
  )
}

export default PaymentPendingPO
