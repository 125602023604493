import React, {useEffect, useState} from 'react'
import Select from 'react-select'
import Flatpickr from 'react-flatpickr'
import 'react-datepicker/dist/react-datepicker.css'
import {KTIcon} from '../../../../_metronic/helpers'
import {useAuth} from '../../../modules/auth/core/Auth'
import {
  getBillsById,
  updateBills,
  getPurchaseReceiveByVendorId,
  getVendor,
  getVendorByAgentId,
} from '../Core/_requests'
import {RootState} from '../Core/_model'
import {useNavigate, useParams} from 'react-router-dom'
import {add, endOfMonth, addMonths} from 'date-fns'
import {useDispatch, useSelector} from 'react-redux'
import {setVendorDataList} from '../../../redux/procurement/vendor/vendorSlice'
import {setBillsDataFetch} from '../../../redux/procurement/bills/billsSlice'

export const EditBills = () => {
  const navigate = useNavigate()
  const {billId} = useParams()
  const {currentUser} = useAuth()
  const [loading, setLoading] = useState(false)
  const [permissions, setPermissions] = useState<any>({})
  const [bills, setBills] = useState({
    orderNo: '',
    billDate: new Date(),
    dueDate: new Date(),
    subject: '',
    paymentTerms: '',
    notes: '',
    termsCondition: '',
    subtotal: 0,
    total: 0,
    tax: 0,
  })
  const dispatch = useDispatch()
  const vendorDataList = useSelector((state: RootState) => state.procurement.vendor.vendorData)

  const [vendorList, setVendorList] = useState([])
  const [selectedVendor, setSelectedVendor] = useState(null)
  const [purchaseReceive, setPurchaseReceive] = useState<any>([])
  const [selectedVendorCurrency, setSelectedVendorCurrency] = useState('')

  const [selectedPurchaseReceiveId, setSelectedPurchaseReceiveId] = useState(null)
  const [selectedPurchaseReceiveLabel, setSelectedPurchaseReceiveLabel] = useState(null)

  const [isEditingNotes, setIsEditingNotes] = useState(false)
  const [isEditingTerms, setIsEditingTerms] = useState(false)
  const [termsTextareaHeight, setTermsTextareaHeight] = useState(' ')
  const [notesTextareaHeight, setNotesTextareaHeight] = useState(' ')

  const [selectedPurchaseReceiveItems, setSelectedPurchaseReceiveItems] = useState([])

  const purchaseReceiveOptions = purchaseReceive
    ?.filter((item) => item.status === 'pending')
    ?.map((item) => ({
      value: item._id,
      label: item.id,
    }))

  const termsOption = [
    {label: 'Net 15', value: 'net15'},
    {label: 'Net 30', value: 'net30'},
    {label: 'Net 45', value: 'net45'},
    {label: 'Net 60', value: 'net60'},
    {label: 'Due on Receipt', value: 'receipt'},
    {label: 'Due on End of Month', value: 'endofMonth'},
    {label: 'Due on End of Next Month', value: 'endOfNextMonth'},
    {label: 'Custom', value: 'custom'},
  ]
  const [selectedTerms, setSelectedTerms] = useState<any>()

  useEffect(() => {
    const fetchBillData = async () => {
      try {
        const billData = await getBillsById(billId)
        setBills({
          billDate: billData.billDate,
          dueDate: billData.dueDate,
          notes: billData.notes,
          orderNo: billData?.orderNo?._id,
          paymentTerms: billData.paymentTerms,
          subject: billData.subject,
          subtotal: billData.subtotal,
          tax: billData.tax,
          termsCondition: billData.termsCondition,
          total: billData.total,
        })
        setSelectedVendor({value: billData?.vendor?._id, label: billData?.vendor?.displayName})
        setSelectedPurchaseReceiveId(billData?.orderNo?._id)
        setSelectedPurchaseReceiveLabel(billData?.orderNo?.id)
        setSelectedPurchaseReceiveItems(billData.items)
        setSelectedTerms({
          label: billData?.paymentTerms,
          value: billData?.paymentTerms,
        })
      } catch (error) {
        console.error('Error fetching bill data:', error)
      }
    }

    if (billId) {
      fetchBillData()
    }
  }, [billId])

  const handleTermsChange = (terms: any) => {
    setSelectedTerms(terms)
    if (terms.value !== 'custom') {
      const {billDate} = bills
      let dueDate

      switch (terms.value) {
        case 'net15':
          dueDate = add(billDate, {days: 15})
          break
        case 'net30':
          dueDate = add(billDate, {days: 30})
          break
        case 'net45':
          dueDate = add(billDate, {days: 45})
          break
        case 'net60':
          dueDate = add(billDate, {days: 60})
          break
        case 'endofMonth':
          dueDate = endOfMonth(billDate)
          break
        case 'endOfNextMonth':
          dueDate = endOfMonth(addMonths(billDate, 1))
          break
        case 'receipt':
          dueDate = billDate
          break
        default:
          break
      }

      setBills((prevBills) => ({...prevBills, dueDate}))
    }
  }

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const handlePurchaseReceiveChange = (selectedOption) => {
    setSelectedPurchaseReceiveId(selectedOption.value)
    setSelectedPurchaseReceiveLabel(selectedOption.label)

    const selectedQuotation = purchaseReceive?.find(
      (purchaseReceive) => purchaseReceive._id === selectedOption.value
    )

    if (selectedQuotation) {
      setSelectedPurchaseReceiveItems(selectedQuotation?.purchaseOrder?.items)
    }
  }

  const handleEditClick = (e, field) => {
    e.preventDefault()
    if (field === 'notes') {
      setIsEditingNotes(!isEditingNotes)
      setIsEditingTerms(false)
    } else if (field === 'termsCondition') {
      setIsEditingTerms(!isEditingTerms)
      setIsEditingNotes(false)
    }
  }

  const handleVendorChange = (selectedOption) => {
    setSelectedVendor(selectedOption)
    const selectedVendor = vendorList.find((vendor) => vendor.value === selectedOption.value)
    if (selectedVendor) {
      setSelectedVendorCurrency(selectedVendor.currency)
    }
  }

  const handleChange = (e) => {
    const {name, value} = e.target
    if (name === 'notes' && value.trim() === '') {
      setBills((prev) => ({
        ...prev,
        notes: '• ' + value,
      }))
    } else {
      setBills({...bills, [name]: value})
    }
    if (name === 'termsCondition') {
      setTermsTextareaHeight(`${e.target.scrollHeight}px`)
    } else if (name === 'notes') {
      setNotesTextareaHeight(`${e.target.scrollHeight}px`)
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      setBills((prev) => ({
        ...prev,
        notes: prev.notes + '\n• ',
      }))

      setNotesTextareaHeight(`${event.target.scrollHeight}px`)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (selectedVendor === null || selectedVendor === '') {
      alert('Please select a vendor')
      return
    } else if (selectedPurchaseReceiveId === null) {
      alert('Please select a purchase receive')
      return
    }

    setLoading(true)

    const data = {
      ...bills,
      orderNo: selectedPurchaseReceiveId,
      vendor: selectedVendor.value,
      items: selectedPurchaseReceiveItems,
      approval: permissions?.bills?.enable ? 'accepted' : 'pending',
      company: currentUser?.company,
      organization: currentUser?.organization,
      agent: currentUser?._id,
    }

    try {
      await updateBills(data, billId)
      dispatch(setBillsDataFetch())
      navigate(`/procurement/billsList`)
    } catch (error) {
      console.error('Error updating Bill:', error)
    } finally {
      setLoading(false)
    }
  }

  const getPurchaseReceive = async (id) => {
    try {
      const data = await getPurchaseReceiveByVendorId(id)
      setPurchaseReceive(data)
    } catch (error) {
      console.error('Error fetching vendor list:', error)
    }
  }

  useEffect(() => {
    if (selectedVendor?.value) {
      getPurchaseReceive(selectedVendor?.value)
    }
  }, [selectedVendor])

  const handledueDateChange = (date) => {
    setSelectedTerms({label: 'Custom', value: 'custom'})
    setBills({...bills, dueDate: date[0]})
  }
  const handleBillDateChange = (date) => {
    setBills({...bills, billDate: date[0]})
  }

  useEffect(() => {
    setBills((prevBill) => ({
      ...prevBill,
      subtotal: selectedPurchaseReceiveItems?.reduce((accumulator, currentItem) => {
        return accumulator + parseFloat(currentItem.amount)
      }, 0),
    }))
  }, [selectedPurchaseReceiveItems])

  useEffect(() => {
    setBills((prevBills) => {
      const subtotal = prevBills.subtotal || 0
      const tax = prevBills.tax || 0

      const total = (subtotal * (1 + tax / 100)).toFixed(2)

      return {
        ...prevBills,
        total: parseFloat(total),
      }
    })
  }, [selectedPurchaseReceiveItems, bills.tax])

  const getVendorList = async () => {
    try {
      const query = null
      const data = await getVendor(query, currentUser?.organization)
      setVendorList(
        data.map((r) => ({
          value: r._id,
          label: r.displayName,
          currency: r.currency,
        }))
      )
      dispatch(setVendorDataList(data))
    } catch (error) {
      console.error('Error fetching vendor list:', error)
    }
  }

  const getVendorListForAgent = async () => {
    try {
      const res = await getVendorByAgentId('', currentUser?._id)
      setVendorList(
        res.map((r) => ({
          value: r?._id,
          label: r?.displayName,
          currency: r?.currency,
        }))
      )
      dispatch(setVendorDataList(res))
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  useEffect(() => {
    if (vendorDataList?.length === 0) {
      if (permissions?.bills?.admin === false) {
        getVendorListForAgent()
      } else if (permissions?.bills?.admin === true || currentUser?.profileType === 'superadmin') {
        getVendorList()
      }
    } else {
      setVendorList(
        vendorDataList.map((r) => ({
          value: r._id,
          label: r.displayName,
          currency: r.currency,
        }))
      )
    }
  }, [permissions])

  return (
    <div id='kt_app_content' className='app-content flex-column-fluid '>
      <div id='kt_app_content_container' className='app-container container-xxl'>
        <div className='d-flex flex-column flex-lg-row'>
          <div className='flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10'>
            <div className='card'>
              <div className='card-body p-12'>
                <form action='' id='kt_invoice_form'>
                  <div className='d-flex flex-column align-items-start flex-xxl-row'>
                    <div className='d-flex align-items-center flex-equal fw-row me-4 order-2'>
                      <div className='fs-2x fw-bold text-gray-700'>Edit Bill</div>
                    </div>
                  </div>
                  <div className='separator separator-dashed my-10'></div>
                  {/* Separator */}
                  {/* begin::Wrapper */}
                  <div className='mb-0'>
                    <div className='w-50'>
                      <label className='form-label required fs-6 fw-bold text-gray-700 mb-3'>
                        Vendor Name
                      </label>
                      {/* begin::Input group */}
                      <Select
                        name='vendor'
                        aria-label='Select a Vendor'
                        placeholder='Select a Vendor'
                        options={vendorList}
                        isSearchable={true}
                        className='form-control form-control-solid'
                        onChange={handleVendorChange}
                        value={selectedVendor}
                      />
                    </div>

                    <div className='w-50'>
                      <label className='form-label required fs-6 fw-bold text-gray-700 mb-3'>
                        Purchase Receive No
                      </label>
                      <Select
                        name='orderNo'
                        aria-label='Select a Purchase Receive'
                        placeholder='Select a Purchase Receive'
                        className='form-control form-control-solid'
                        options={purchaseReceiveOptions}
                        isSearchable={true}
                        onChange={handlePurchaseReceiveChange}
                        value={
                          selectedPurchaseReceiveLabel
                            ? {
                                value: selectedPurchaseReceiveId,
                                label: selectedPurchaseReceiveLabel,
                              }
                            : null
                        }
                      />
                    </div>

                    <div className='row gx-10 mb-5'>
                      {/* begin::Col */}
                      <div className='col-lg-4'>
                        <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                          Bill Date
                        </label>
                        <Flatpickr
                          value={bills.billDate}
                          onChange={handleBillDateChange}
                          className='form-control form-control-solid'
                          placeholder='Pick date'
                          options={{
                            dateFormat: 'd-m-Y',
                          }}
                        />
                      </div>
                      <div className='col-lg-4'>
                        <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                          Payment Terms
                        </label>
                        <Select
                          name='terms'
                          aria-label='Select a Terms'
                          placeholder='Select a Terms'
                          options={termsOption}
                          className='form-control form-control-solid'
                          onChange={handleTermsChange}
                          value={selectedTerms}
                        />
                      </div>
                      <div className='col-lg-4'>
                        <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                          Due Date
                        </label>
                        <Flatpickr
                          value={bills.dueDate}
                          onChange={handledueDateChange}
                          className='form-control form-control-solid'
                          placeholder='Pick date'
                          options={{
                            dateFormat: 'd-m-Y',
                          }}
                        />
                      </div>
                      {/* begin::Notes */}
                      <div className='mb-0'>
                        <label className='form-label fs-6 fw-bold text-gray-700'>Subject</label>
                        <input
                          type='textarea'
                          name='subject'
                          className='form-control form-control-solid'
                          placeholder=''
                          value={bills.subject}
                          onChange={handleChange}
                        />
                      </div>
                      <div></div>
                      {/* Notes */}

                      {/* Col */}
                    </div>
                    {/* begin::Table wrapper */}
                    <div className='fs-6 fw-bold text-gray-700 mb-3'>ITEMS & DESCRIPTION</div>
                    <div className='table-responsive mb-10'>
                      {/* begin::Table */}
                      <table
                        className='table g-5 gs-0 mb-0 fw-bold text-gray-700'
                        data-kt-element='items'
                      >
                        {/* begin::Table head */}
                        <thead>
                          <tr className='border-bottom fs-7 fw-bold text-gray-700 text-uppercase'>
                            <th className='min-w-100px w-500px'>Item</th>
                            <th className='min-w-150px w-250px'>Quantity</th>
                            <th className='min-w-50px w-250px'>Rate</th>
                            <th className='min-w-70px w-150px'>VAT</th>
                            <th className='min-w-70px w-250px'>Amount</th>
                          </tr>
                        </thead>
                        {/* Table head */}
                        {/* begin::Table body */}
                        <tbody>
                          {selectedPurchaseReceiveItems?.map((item, index) => (
                            <tr
                              className='border-bottom border-bottom-dashed'
                              data-kt-element='item'
                              key={index}
                            >
                              <td className='pe-7'>
                                {/* begin::Input group */}
                                <input
                                  type='text'
                                  className='form-control form-control-solid'
                                  name='productName'
                                  placeholder=''
                                  value={item?.productName}
                                  disabled={true}
                                />
                              </td>
                              <td className='pe-7'>
                                {/* begin::Input group */}

                                <input
                                  type='number'
                                  className='form-control form-control-solid'
                                  name='quantity'
                                  placeholder='0.00'
                                  max={item?.selectedOption?.inWarehouseQuantity}
                                  min={0}
                                  value={item.quantity}
                                  disabled={true}

                                  // onChange={(e) => handleChangerows(index, e)}
                                />
                              </td>

                              <td className='pe-7'>
                                {/* begin::Input group */}

                                <input
                                  type='number'
                                  className='form-control form-control-solid'
                                  name='price'
                                  placeholder='0.00'
                                  value={item.price}
                                  disabled={true}

                                  // onChange={(e) => handleChangerows(index, e)}
                                />
                              </td>

                              <td className='pe-7'>
                                {/* begin::Input group */}

                                <input
                                  type='number'
                                  className='form-control form-control-solid'
                                  name='discount'
                                  placeholder='0.00'
                                  value={item.discount}
                                  disabled={true}

                                  // onChange={(e) => handleChangerows(index, e)}
                                />
                              </td>

                              <td>
                                <input
                                  type='number'
                                  className='form-control form-control-solid text-start'
                                  name='amount'
                                  placeholder='0.00'
                                  value={item.amount}
                                  disabled
                                  // onChange={(e) => handleChangerows(index, e)}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        {/* Table body */}
                        {/* begin::Table foot */}
                        <tfoot>
                          <tr className='border-top border-top-dashed align-top fs-6 fw-bold text-gray-700'>
                            <th className='text-primary'></th>
                            <th className='text-primary'></th>
                            <th className='text-primary'></th>
                            <th className='border-bottom border-bottom-dashed ps-0'>
                              <div className='d-flex flex-column align-items-center'>
                                <div className='fs-5 w-150 '>Subtotal</div>
                                <button
                                  className='btn btn-link w-150 '
                                  data-bs-toggle='tooltip'
                                  data-bs-trigger='hover'
                                  title='Tax in percentage'
                                >
                                  Add tax %
                                </button>
                              </div>
                            </th>
                            <th className='border-bottom border-bottom-dashed px-6 text-end'>
                              <span data-kt-element='sub-total' className=' '>
                                {bills?.subtotal?.toLocaleString('en-US', {
                                  minimumFractionDigits: 2,
                                })}{' '}
                                {selectedVendorCurrency}
                              </span>
                              <input
                                type='number'
                                className='form-control form-control-solid text-end'
                                name='tax'
                                placeholder='0.00'
                                value={bills.tax}
                                onChange={handleChange}
                              />
                            </th>
                          </tr>
                          <tr className='align-top fw-bold text-gray-700'>
                            <th className='min-w-100px w-100px'></th>
                            <th className='min-w-150px w-150px'></th>
                            <th className='min-w-150px w-150px'></th>
                            <th className='fs-4 ps-0 min-w-50px w-50px text-center'>Total</th>
                            <th className='text-end fs-4 text-nowrap min-w-70px w-70px px-6'>
                              <span data-kt-element='grand-total '>
                                {' '}
                                {bills?.total?.toLocaleString('en-US', {
                                  minimumFractionDigits: 2,
                                })}{' '}
                                {selectedVendorCurrency}
                              </span>
                            </th>
                          </tr>
                        </tfoot>
                        {/* Table foot */}
                      </table>
                    </div>
                    {/* Table */}
                    {/* begin::Item template */}

                    {/* Item template */}
                    {/* begin::Notes */}
                    <div className='mb-0 position-relative '>
                      <label className='form-label fs-6 fw-bold text-gray-700'>Notes</label>
                      <textarea
                        name='notes'
                        className={` form-control form-control-solid mb-0 h-10 ${
                          isEditingNotes ? 'border border-dark-subtle' : ''
                        }`}
                        placeholder='Enter Notes'
                        value={bills.notes}
                        onChange={handleChange}
                        readOnly={!isEditingNotes}
                        onKeyDown={handleKeyPress}
                        style={{
                          minHeight: '6rem',
                          height: notesTextareaHeight,
                          resize: 'none',
                        }}
                      />
                      <button onClick={(e) => handleEditClick(e, 'notes')}>
                        <KTIcon
                          iconName='pencil'
                          className='position-absolute text-black bg-gray-100 right-8 top-[3rem] fs-3'
                        />
                      </button>
                    </div>

                    <div className='mb-10 position-relative'>
                      <label className='form-label fs-6 fw-bold text-gray-700'>
                        Terms & Conditions
                      </label>
                      <textarea
                        name='termsCondition'
                        style={{
                          minHeight: '6rem',
                          height: termsTextareaHeight,
                          resize: 'none',
                        }}
                        className={`  form-control form-control-solid mb-0 h-10 ${
                          isEditingTerms ? 'border border-dark-subtle' : ''
                        }`}
                        placeholder='Enter terms and conditions'
                        value={bills.termsCondition}
                        onChange={handleChange}
                        readOnly={!isEditingTerms}
                      />
                      <button onClick={(e) => handleEditClick(e, 'termsCondition')}>
                        <KTIcon
                          iconName='pencil'
                          className='absolute text-black bg-gray-100 right-8 top-[3rem] fs-3'
                        />
                      </button>
                    </div>

                    <button
                      className='btn btn-warning w-30'
                      id='kt_quote_submit_button'
                      disabled={loading}
                      onClick={handleSubmit}
                    >
                      <i className='ki-duotone ki-triangle fs-3'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>
                      Update Bill
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditBills
