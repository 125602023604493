import React, {useEffect, useState} from 'react'
import {getagentdetails} from './core/_requests'
import {useAuth} from '../../modules/auth'
import profile from './profile.png'

interface IProfileDetails {
  profilePic?: string
  fullName?: string
  email?: string
  password?: string
  twoFactor?: boolean
}

const ProfileViewing: React.FC = () => {
  const {currentUser} = useAuth()
  const PF = 'https://qf-assets.s3.me-central-1.amazonaws.com/'
  const [data, setData] = useState<IProfileDetails>({
    profilePic: '',
    fullName: '',
    email: '',
    password: '',
    twoFactor: false,
  })

  const getagent = async () => {
    const res = await getagentdetails(currentUser._id)
    console.log(res)
    setData(res)
  }

  useEffect(() => {
    getagent()
  }, [])

  return (
    <div>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header border-0'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Profile Details</h3>
          </div>
        </div>

        <form noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='fv-row mb-7'>
              {/* begin::Label */}
              <label className='d-block fw-bold fs-6 mb-5'>Avatar</label>
              {/* end::Label */}

              {/* begin::Image input */}
              <div className='image-input image-input-outline'>
                {/* begin::Preview existing avatar */}
                <div className='image-input-wrapper w-125px h-125px'>
                  <img src={data.profilePic ? PF + data.profilePic : profile} alt='profile' />
                </div>
                {/* end::Preview existing avatar */}

                {/* begin::Label */}
                {/* end::Label */}

                {/* begin::Cancel */}

                {/* end::Cancel */}

                {/* end::Remove */}
              </div>
              {/* end::Image input */}

              {/* begin::Hint */}
              {/* end::Hint */}
            </div>

            {/* Full Name and User Name section */}
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Full Name</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  name='fullName'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Full name'
                  value={data.fullName}
                  readOnly
                />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Email</label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  name='email'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Email'
                  value={data.email}
                  readOnly
                />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Password</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='tel'
                  name='password'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Password'
                  value={data.password}
                  readOnly
                />
              </div>
            </div>

            {/* toggle button for Two factor authentication */}
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>2FA</label>
              <div className='col-lg-8 fv-row'>
                <label className='form-check form-switch form-check-custom form-check-solid'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    checked={data.twoFactor}
                    readOnly
                  />
                  <span className='form-check-label'>Enable Two Factor Authentication</span>
                </label>
              </div>
            </div>

            {/* toggle button for notifications */}
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Notifications</label>
              <div className='col-lg-8 fv-row'>
                <label className='form-check form-switch form-check-custom form-check-solid'>
                  <input className='form-check-input' type='checkbox' />
                  <span className='form-check-label'>Enable Notifications</span>
                </label>
              </div>
            </div>

            {/* toggle button for location */}
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Location</label>
              <div className='col-lg-8 fv-row'>
                <label className='form-check form-switch form-check-custom form-check-solid'>
                  <input className='form-check-input' type='checkbox' />
                  <span className='form-check-label'>Enable Location</span>
                </label>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export {ProfileViewing}
