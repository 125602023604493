/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'

import {KTIcon, KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {deletedocs, editdocs, getDocs, uploaddocs} from '../core/_requests'
import {Link, useParams} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'
import Flatpickr from 'react-flatpickr'

const FilesPage: React.FC = () => {
  const id = useParams().id
  const {currentUser} = useAuth()
  const [permissions, setPermissions] = useState<any>({})
  const PF: any = 'https://qf-assets.s3.me-central-1.amazonaws.com/'
  const [docs, setdocs] = useState<any>([])
  const [name, setname] = useState('')
  const [filename, setfilename] = useState('')
  const [isLoading, setisLoading] = useState<boolean>(false)
  const [excelFile, setExcelFile] = useState(null)
  const [deleteid, setdeleteid] = useState()
  const fileInputRef = useRef(null)
  const [formData, setFormData] = useState({
    expiryDate: new Date(new Date().setMonth(new Date().getMonth() + 1)),
    reminderDate: new Date(new Date().setDate(new Date().getDate() + 15)),
    reminder: '15',
    notify: false,
  })
  const [deleteFile, setDeleteFile] = useState<boolean>(false)

  useEffect(() => {
    if (currentUser) {
      setPermissions(currentUser.permissions)
    }
  }, [currentUser])

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    setfilename(event.target.files[0].name)
    setExcelFile(file)
  }

  useEffect(() => {
    if (formData.reminder === 'custom' || formData.reminder === undefined) return
    if (!formData.expiryDate) return

    setFormData((prev) => ({
      ...prev,
      reminderDate: new Date(
        new Date(formData.expiryDate).setDate(
          formData.expiryDate.getDate() - parseInt(formData.reminder)
        )
      ),
    }))
  }, [formData.expiryDate, formData.reminder])

  const getdocss = async () => {
    try {
      const res = await getDocs(id)
      console.log('res', res.documents)
      setdocs(res.documents)
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  useEffect(() => {
    getdocss()
  }, [])

  const handleUpload = async () => {
    if (!excelFile) {
      alert('Please select a file to upload')
      return
    }
    if (name === '') {
      alert('Please enter a name')
      return
    }

    const timestamp = Date.now()

    const formData1 = new FormData()
    formData1.append('file', excelFile)
    formData1.append('name', name)
    formData1.append('filename', timestamp + filename)
    formData1.append('expiryDate', formData.expiryDate.toISOString())
    formData1.append('reminderDate', formData.reminderDate.toISOString())
    formData1.append('notify', formData.notify.toString())
    formData1.append('department', currentUser?.department)
    formData1.append('organization', currentUser?.organization)

    try {
      setisLoading(true)
      const response = await uploaddocs(formData1, id)

      if (response === 'success') {
        console.log('File uploaded successfully')
        setisLoading(false)
        setExcelFile(null)
        setname('')
        setfilename('')
        setFormData({
          expiryDate: new Date(new Date().setMonth(new Date().getMonth() + 1)),
          reminderDate: new Date(new Date().setDate(new Date().getDate() + 15)),
          reminder: '15',
          notify: false,
        })
        // Optionally, update UI to indicate successful upload
        getdocss()
        const closeModalButton = document.getElementById('closeModalButton')
        closeModalButton?.click()
      } else {
        alert('failed to upload please try again')
        // Optionally, update UI to indicate failure
      }
    } catch (error) {
      console.error('Error:', error)
      // Opti
    }
  }

  const handleButtonClick = () => {
    fileInputRef.current.click()
  }

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'

    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })

    return formattedDate
  }

  const handleDownload = (product) => {
    // You can construct the file URL here (replace this with your logic)
    const fileUrl = `${PF}${product.filename}`

    // Create a temporary anchor element
    const downloadLink = document.createElement('a')
    downloadLink.href = fileUrl

    // Set the download attribute with the desired filename
    downloadLink.download = product.filename

    // Append the anchor element to the body
    document.body.appendChild(downloadLink)

    // Trigger a click event to start the download
    downloadLink.click()

    // Remove the anchor element from the body
    document.body.removeChild(downloadLink)
  }

  const handledelete = async () => {
    try {
      const res = await deletedocs(id, deleteid).then((res) => {
        getdocss()
      })

      const closeModalButton = document.getElementById('closeModalButtondeldelete')

      if (closeModalButton) {
        closeModalButton.click()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleEdit = async () => {
    if (name === '') {
      alert('Please enter a name')
      return
    }
    if (deleteFile && !excelFile) {
      alert('Please select a file to upload')
      return
    }
    setisLoading(true)
    if (deleteFile) {
      await handledelete().then(async () => {
        await handleUpload().then(() => {
          const closeModalButton = document.getElementById('closeModalButton1')
          if (closeModalButton) {
            closeModalButton.click()
          }
        })
      })
    } else {
      const data = {
        name: name,
        expiryDate: formData?.expiryDate?.toISOString(),
        reminderDate: formData?.reminderDate?.toISOString(),
        notify: formData.notify,
      }
      const res = await editdocs(data, id, deleteid)
      if (res) {
        getdocss()
        setExcelFile(null)
        setname('')
        setfilename('')
        setFormData({
          expiryDate: new Date(new Date().setMonth(new Date().getMonth() + 1)),
          reminderDate: new Date(new Date().setDate(new Date().getDate() + 15)),
          reminder: '15',
          notify: false,
        })
        const closeModalButton = document.getElementById('closeModalButton1')
        if (closeModalButton) {
          closeModalButton.click()
        }
      }
    }
  }

  return (
    <>
      <div className={'card h-md-50 mb-5 mb-xl-10 min-h-500px overflow-scroll'}>
        {/* begin::Header */}

        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'></span>
          </h3>
          {(permissions?.documents?.create || currentUser?.profileType === 'superadmin') && (
            <div className='d-flex flex-row'>
              <div
                className='card-toolbar me-5'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_11'
              >
                <a className='btn btn-sm btn-light-primary'>
                  <KTIcon iconName='plus' className='fs-2' />
                  Add Files
                </a>
              </div>
            </div>
          )}
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-150px'>Name</th>
                  <th className='min-w-150px'>Expiry Date</th>
                  <th className='min-w-150px'>Action</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {docs.map((product: any | undefined) => (
                  <tr key={product?._id?.toString()}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className=' client_field cursor-pointer capitalize'>
                          <div className='d-flex justify-content-start flex-column'>
                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                              {product?.name}
                            </a>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        {formatCreatedAt(product?.expiryDate)}
                      </a>
                    </td>
                    <td>
                      <div className='d-flex flex-row'>
                        <div className='card-toolbar me-5'>
                          <a
                            href={PF + product.filename}
                            className='btn btn-sm btn-light-success'
                            download
                            target='_blank'
                          >
                            Open
                          </a>
                        </div>

                        {/* <div className='card-toolbar me-5'>
                          <div
                            className='btn btn-sm btn-light-danger'
                            onClick={() => setdeleteid(product?._id)}
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_12'
                          >
                            Delete
                          </div>
                        </div> */}

                        <div className='card-toolbar me-5'>
                          <div
                            className='btn btn-sm btn-light-warning'
                            onClick={(e) => {
                              setdeleteid(product?._id)
                              setname(product?.name)
                              setfilename(product?.filename)
                              setFormData({
                                expiryDate: new Date(product?.expiryDate),
                                reminderDate: new Date(product?.reminderDate),
                                reminder: product?.reminder,
                                notify: product?.notify,
                              })
                            }}
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_13'
                          >
                            Edit
                          </div>
                        </div>
                      </div>
                    </td>

                    {/* <td className='text-end'>
                  <div className='d-flex flex-column w-100 me-2'>
                    <div className='d-flex flex-stack mb-2'>
                      <span className='text-muted me-2 fs-7 fw-semibold'>50%</span>
                    </div>
                    <div className='progress h-6px w-100'>
                      <div
                        className='progress-bar bg-primary'
                        role='progressbar'
                        style={{width: '50%'}}
                      ></div>
                    </div>
                  </div>
                </td>
                 */}
                  </tr>
                ))}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>

      <div className='modal fade' tabIndex={-1} id='kt_modal_12'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Delete File</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></div>
            </div>

            <div className='row modal-body'>Delete This File</div>

            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                id='closeModalButtondeldelete'
              >
                Close
              </button>
              <button type='button' className='btn btn-danger' onClick={() => handledelete()}>
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='modal fade' tabIndex={-1} id='kt_modal_11'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Add Files</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>

            <div className='modal-body'>
              <form>
                <div className='row rowwidthclient'>
                  <div className='form-group mb-3 col-md-12'>
                    <label>File Name</label>
                    <input
                      type='text'
                      name='name'
                      value={name}
                      onChange={(e) => setname(e.target.value)}
                      className='form-control'
                    />
                  </div>

                  <div className='card-toolbar'>
                    <a className='btn btn-sm btn-light-danger' onClick={handleButtonClick}>
                      <KTIcon iconName='plus' className='fs-2' />
                      Upload File
                    </a>
                  </div>

                  <input
                    type='file'
                    accept='.pdf, .jpg, .jpeg, .png, .gif, .doc, .docx, .xlsx, .xls'
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    style={{display: 'none'}}
                  />
                  {excelFile && <p>Selected file: {excelFile.name}</p>}
                </div>

                <div className='row'>
                  <div className='form-group mb-3 col-md-12'>
                    <label>Expiry Date</label>
                    <Flatpickr
                      value={formData.expiryDate}
                      onChange={(date) => setFormData({...formData, expiryDate: new Date(date)})}
                      options={{
                        enableTime: false,
                        dateFormat: 'd-m-Y',
                      }}
                      className='form-control'
                    />
                  </div>
                </div>

                <div className='row'>
                  <div className='form-group mb-3 col-md-12'>
                    <label>Reminder</label>
                    <select
                      className='form-select'
                      value={formData.reminder}
                      onChange={(e) => setFormData({...formData, reminder: e.target.value})}
                    >
                      <option value='1'>1 day</option>
                      <option value='5'>5 days</option>
                      <option value='15'>15 days</option>
                      <option value='30'>30 days</option>
                      <option value='45'>45 days</option>
                      <option value='60'>60 days</option>
                      <option value='custom'>CUSTOM</option>
                    </select>
                  </div>
                </div>

                <div className='row'>
                  <div className='form-group mb-3 col-md-12'>
                    <label>Reminder Date</label>
                    <Flatpickr
                      value={formData.reminderDate}
                      onChange={(date) => {
                        setFormData({...formData, reminderDate: new Date(date), reminder: 'custom'})
                      }}
                      options={{
                        enableTime: false,
                        dateFormat: 'd-m-Y',
                      }}
                      className='form-control'
                    />
                  </div>
                </div>

                <div className='row'>
                  <div className='form-group mb-3 col-md-12'>
                    {/* <label>Want reminder notification</label> */}
                    {/* toggle switch */}
                    <div className='form-check form-switch form-switch-sm'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        id='flexSwitchCheckDefault'
                        checked={formData.notify}
                        onChange={(e) => setFormData({...formData, notify: e.target.checked})}
                      />
                      <label className='form-check-label' htmlFor='flexSwitchCheckDefault'>
                        Want reminder notification
                      </label>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                id='closeModalButton'
              >
                Close
              </button>
              <button
                type='button'
                className='btn btn-primary'
                disabled={isLoading}
                onClick={handleUpload}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='modal fade' tabIndex={-1} id='kt_modal_13'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Edit Files</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>

            <div className='modal-body'>
              <form>
                <div className='row rowwidthclient'>
                  <div className='form-group mb-3 col-md-12'>
                    <label>File Name</label>
                    <input
                      type='text'
                      name='name'
                      value={name}
                      onChange={(e) => setname(e.target.value)}
                      className='form-control'
                    />
                  </div>

                  <div className='card-toolbar d-flex gap-3'>
                    {!deleteFile && (
                      <a
                        className='btn btn-sm btn-light-danger'
                        onClick={(e) => {
                          setDeleteFile(true)
                        }}
                      >
                        <KTIcon iconName='trash' className='fs-2' />
                        Delete Existing File
                      </a>
                    )}
                    {deleteFile && (
                      <a className='btn btn-sm btn-light-success' onClick={handleButtonClick}>
                        <KTIcon iconName='plus' className='fs-2' />
                        Upload File
                      </a>
                    )}
                  </div>

                  <input
                    type='file'
                    accept='.pdf, .jpg, .jpeg, .png, .gif, .doc, .docx, .xlsx, .xls'
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    style={{display: 'none'}}
                  />
                  {excelFile && <p>Selected file: {excelFile.name}</p>}
                </div>

                <div className='row'>
                  <div className='form-group mb-3 col-md-12'>
                    <label>Expiry Date</label>
                    <Flatpickr
                      value={formData.expiryDate}
                      onChange={(date) => setFormData({...formData, expiryDate: new Date(date)})}
                      options={{
                        enableTime: false,
                        dateFormat: 'd-m-Y',
                      }}
                      className='form-control'
                    />
                  </div>
                </div>

                <div className='row'>
                  <div className='form-group mb-3 col-md-12'>
                    <label>Reminder</label>
                    <select
                      className='form-select'
                      value={formData.reminder}
                      onChange={(e) => setFormData({...formData, reminder: e.target.value})}
                    >
                      <option value='1'>1 day</option>
                      <option value='5'>5 days</option>
                      <option value='15'>15 days</option>
                      <option value='30'>30 days</option>
                      <option value='45'>45 days</option>
                      <option value='60'>60 days</option>
                      <option value='custom'>CUSTOM</option>
                    </select>
                  </div>
                </div>

                <div className='row'>
                  <div className='form-group mb-3 col-md-12'>
                    <label>Reminder Date</label>
                    <Flatpickr
                      value={formData.reminderDate}
                      onChange={(date) => {
                        setFormData({...formData, reminderDate: new Date(date), reminder: 'custom'})
                      }}
                      options={{
                        enableTime: false,
                        dateFormat: 'd-m-Y',
                      }}
                      className='form-control'
                    />
                  </div>
                </div>

                <div className='row'>
                  <div className='form-group mb-3 col-md-12'>
                    {/* <label>Want reminder notification</label> */}
                    {/* toggle switch */}
                    <div className='form-check form-switch form-switch-sm'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        id='flexSwitchCheckDefault'
                        checked={formData.notify}
                        onChange={(e) => setFormData({...formData, notify: e.target.checked})}
                      />
                      <label className='form-check-label' htmlFor='flexSwitchCheckDefault'>
                        Want reminder notification
                      </label>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                id='closeModalButton1'
                onClick={() => {
                  setDeleteFile(false)
                  setExcelFile(null)
                  setname('')
                  setfilename('')
                  setFormData({
                    expiryDate: new Date(new Date().setMonth(new Date().getMonth() + 1)),
                    reminderDate: new Date(new Date().setDate(new Date().getDate() + 15)),
                    reminder: '15',
                    notify: false,
                  })
                }}
              >
                Close
              </button>
              <button
                type='button'
                className='btn btn-primary'
                disabled={isLoading}
                onClick={handleEdit}
              >
                Edit changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export {FilesPage}
