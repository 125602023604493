import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'
import {KTIcon} from '../../../../_metronic/helpers'
import {getHRPLeavesWithMonth} from '../core/_requests'
import {useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../../redux/store'
import {setHRPLeaveManagement} from '../../../redux/hrp/hrpLeaveManagementSlice'

function HRPLeavePage() {
  const {currentUser} = useAuth()
  const dispatch = useDispatch()
  const hrpLeaveManagementFetch = useSelector((state: RootState) => state.hrp.leaveManagement.fetch)
  const leaveList = useSelector((state: RootState) => state.hrp.leaveManagement.leaves)
  const [permissions, setPermissions] = useState<any>({})
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (currentUser) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  useEffect(() => {
    const fetchleaveList = async () => {
      setIsLoading(true)
      const res = await getHRPLeavesWithMonth(currentUser?.organization)
      dispatch(setHRPLeaveManagement(res))
      setIsLoading(false)
    }

    if (currentUser?.organization !== undefined && hrpLeaveManagementFetch) {
      fetchleaveList()
    }
  }, [currentUser?.organization])

  return (
    <div className='card card-flush'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Employee Leaves</span>
        </h3>
        <div className=' d-flex flex-row card-title align-items-start'>
          <div className='d-flex gap-5 justify-between'>
            {(currentUser?.profileType === 'superadmin' ||
              permissions?.leavemanagement?.create) && (
              <div className='card-toolbar mr-5'>
                <Link to={'addLeaveType'} className='btn btn-sm btn-light-primary'>
                  <KTIcon iconName='plus' className='fs-2' />
                  Add Leave Type
                </Link>
              </div>
            )}

            {(currentUser?.profileType === 'superadmin' ||
              permissions?.hrpleavemanagement?.create) && (
              <div className='card-toolbar'>
                <Link to={'add'} className='btn btn-sm btn-light-primary'>
                  <KTIcon iconName='plus' className='fs-2' />
                  Apply Leave
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='card-body py-3'>
        {/* begin::Table container */}
        {isLoading ? (
          <div className='d-flex align-items-center justify-center'>
            <div className='loader'></div>
          </div>
        ) : (
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-4 table-row-bordered'>
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-50px rounded-start'>Month</th>
                  <th className='min-w-150px text-center'>Total Leaves</th>
                </tr>
              </thead>
              <tbody>
                {leaveList?.map((item: any, index) => (
                  <tr key={item?._id?.toString()}>
                    <td>
                      <Link
                        to={`${item?._id}`}
                        className='ps-4 text-dark fw-bold text-hover-primary d-block mb-1 fs-6 capitalize'
                      >
                        {item?._id}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`${item?._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 capitalize text-center'
                      >
                        {item?.count}
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
              {/* end::Table */}
            </table>
          </div>
        )}
        {/* end::Table container */}
      </div>
      {/* begin::Body */}

      {/* Modal for add client */}
    </div>
  )
}

export default HRPLeavePage
