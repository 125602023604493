import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {getBillsById} from '../../Core/_requests'
import {convertAmountToWords} from '../../../../utils/utils'

function BillsDetails({companyLogo, color, setColor, minFooter}) {
  const NUMBERS = new Map([
    [1e9, 'Billion'],
    [1e6, 'Million'],
    [1e3, 'Thousand'],
    [1e2, 'Hundred'],
    [90, 'Ninety'],
    [80, 'Eighty'],
    [70, 'Seventy'],
    [60, 'Sixty'],
    [50, 'Fifty'],
    [40, 'Forty'],
    [30, 'Thirty'],
    [20, 'Twenty'],
    [19, 'Nineteen'],
    [18, 'Eighteen'],
    [17, 'Seventeen'],
    [16, 'Sixteen'],
    [15, 'Fifteen'],
    [14, 'Fourteen'],
    [13, 'Thirteen'],
    [12, 'Twelve'],
    [11, 'Eleven'],
    [10, 'Ten'],
    [9, 'Nine'],
    [8, 'Eight'],
    [7, 'Seven'],
    [6, 'Six'],
    [5, 'Five'],
    [4, 'Four'],
    [3, 'Three'],
    [2, 'Two'],
    [1, 'One'],
    [0, 'Zero'],
  ])

  const numberToWords = (num) => {
    for (const [intValue, textValue] of NUMBERS as any) {
      if (num >= intValue) {
        const prefix = num >= 100 ? numberToWords(Math.trunc(num / intValue)) : ''
        const suffix = num % intValue > 0 ? numberToWords(num % intValue) : ''
        return `${prefix} ${textValue} ${suffix}`.trim()
      }
    }
    return ''
  }

  const convertSARAmountToWords = (amount) => {
    const [riyals, halalas] = amount.toString().split('.').map(Number)
    const riyalsInWords = numberToWords(riyals)
    const halalasInWords = halalas ? numberToWords(halalas.toString().padEnd(2, '0')) : ''

    return `${riyalsInWords} Riyals${halalasInWords ? ` and ${halalasInWords} Halalas` : ''} Only`
  }
  const [vat, setVat] = useState(0)

  const {id} = useParams()
  const [imageSize, setImageSize] = useState<any>(false)
  const [data, setBillData] = useState<any>()
  const [page, setPage] = useState(1)
  const getBillData = async () => {
    try {
      const response = await getBillsById(id)
      setBillData(response)
      setColor(response?.company?.procurementColor)
      if (response?.items?.length > 10) {
        const pages = Math.ceil((response?.items.length - 10) / 20)
        if ((response?.items.length - 10) / 20 > 10) {
          setPage(pages + 2)
        } else {
          setPage(pages + 1)
        }
      }
    } catch (error) {
      console.error('Axios error:', error)
      throw error
    }
  }
  const handleOnLoad = (e) => {
    const img = e.target
    if (img.naturalWidth > img.naturalHeight) {
      setImageSize(true)
    } else {
      setImageSize(false)
    }
  }

  useEffect(() => {
    getBillData()
  }, [])

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })
    return formattedDate
  }

  useEffect(() => {
    if (data?.tax) {
      const tax = (data?.subtotal * data?.tax) / 100
      setVat(tax)
    }
  }, [data])

  const PF = 'https://qf-assets.s3.me-central-1.amazonaws.com/'

  const PageContent = Array.from({length: page}).map((_, idx) => (
    <div
      key={idx}
      id={`pdf-content-${idx}`}
      className='card overflow-hidden mx-auto !rounded-none'
      style={{
        height: '297mm',
        width: '210mm',
      }}
    >
      <div id='pdfHeader' className='p-2 mx-3 mt-6'>
        <div className='d-flex justify-content-between align-items-center flex-column flex-sm-row'>
          {/*end::Logo*/}
          <div className='text-sm-end'>
            {/*begin::Logo*/}
            <a href='#' className={`d-block ${imageSize ? 'mw-275px' : 'mw-150px'} ms-sm-auto`}>
              {companyLogo ? (
                <img
                  alt='Logo'
                  src={URL.createObjectURL(companyLogo)}
                  className={imageSize ? 'w-100' : 'w-50'}
                  onLoad={handleOnLoad}
                />
              ) : (
                <img
                  alt='thumbnail'
                  src={PF + data?.company?.companyLogo}
                  className={imageSize ? 'w-100' : 'w-50'}
                  onLoad={handleOnLoad}
                />
              )}
            </a>
            {/*end::Logo*/}
          </div>
          <h4 className='fw-bold text-gray-800 xl:fs-1 d-flex flex-column text-right header-text'>
            <span
              className='mb-1 text-right fs-1'
              style={{
                // fontFamily: 'Rubik',
                fontFamily: 'Scheherazade New',
                fontOpticalSizing: 'auto',
                fontStyle: 'normal',
                wordSpacing: '10px',
              }}
            >
              {data?.company?.arabicName}
            </span>
            <span
              className='mb-1 text-right fs-3 tracking-wider'
              style={{
                color: color,
              }}
            >
              {data?.company?.name}
            </span>
            <span className='fs-6 text-muted'>
              VAT {data?.company?.vat} <span style={{color: color}}>|</span> C.R.{' '}
              {data?.company?.cr}
            </span>
          </h4>
        </div>
      </div>
      {/* end::Top */}

      {/* begin::Wrapper */}
      <div className='border-b-black border-b mx-9'></div>

      {/* begin::Body */}
      <div className='card-body px-lg-15 '>
        {/* begin::Layout */}

        <div className='d-flex flex-column '>
          {/* begin::Content */}
          <div className='flex-lg-row-fluid mb-10 mb-xl-0'>
            {/* begin::Invoice 1 content */}
            <div className='mt-n1'>
              {/* begin::Top */}
              <div className=''>
                {idx === 0 && <div className='mb-6 fs-6 fw-bold text-gray-800 uppercase'>Bill</div>}
                {idx === 0 && (
                  <div
                    className='d-flex justify-content-between flex-column flex-sm-row fs-8'
                    style={{
                      textTransform: 'uppercase',
                    }}
                  >
                    <div className=' mb-5'>
                      <div className='d-flex  flex-column mb-5'>
                        <span className='fw-bold'>Bill NO</span>
                        <span className=''>{data?.id}</span>
                      </div>
                      <div className='d-flex flex-column mb-5'>
                        <span className='fw-bold'>Bill Date</span>

                        <span className=''>{formatCreatedAt(data?.billDate)}</span>
                      </div>
                      <div className=' d-flex flex-column'>
                        <span className='fw-bold'>Due Date</span>

                        <span className=''>{formatCreatedAt(data?.dueDate)}</span>
                      </div>
                    </div>
                    <div className='  mb-5 '>
                      <div className='d-flex flex-column mb-5'>
                        <span className='fw-bold'>Bill Status</span>

                        <div className=' text-dark'>
                          <span
                            className='badge badge-success'
                            style={{
                              padding: '0.5rem',
                            }}
                          >
                            {data?.status}
                          </span>
                        </div>
                      </div>

                      <div className=' d-flex flex-column mb-5 '>
                        <span className='fw-bold'>Payment Terms</span>

                        <span className=''>{data?.vendor?.paymentTerms}</span>
                      </div>

                      <div className=' d-flex flex-column  mb-5'>
                        <span className='fw-bold'>Purchase Order No. </span>

                        <span className=''>{data?.orderNo.id}</span>
                      </div>
                    </div>
                    <div className='text-end  mb-5 '>
                      <div className='d-flex flex-column mb-5'>
                        <span className='fw-bold'>Customer</span>

                        <span className=''>{data?.vendor?.companyName}</span>
                      </div>
                      <div className='d-flex flex-md-root flex-column mb-5'>
                        <span className='fw-bold mb-1'>Customer Address</span>
                        <span className=' mw-200px'>
                          {data?.company?.name}
                          <br />
                          {data?.company?.companyAddress}{' '}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {/*end::Row*/}
                {/*begin::Content*/}
                <div className='flex-grow-1'>
                  {/*begin::Table*/}
                  <div className='table-responsive'>
                    <table className='table table-bordered m-0 border-black'>
                      <thead>
                        <tr
                          className='fs-8 fw-bold uppercase'
                          style={{
                            backgroundColor: color,
                          }}
                        >
                          <th className='min-w-50px text-white text-center pb-3 '>
                            <span>
                              <span className='arabic-font'>رقم </span>
                              <br />
                              S. No.
                            </span>
                          </th>

                          <th className='min-w-150px text-white text-center pb-3 '>
                            <span className='arabic-font'>وصف</span>
                            <br />
                            Description
                          </th>

                          <th className='min-w-70px text-white text-center pb-3'>
                            <span className='arabic-font'>كمية</span>
                            <br />
                            QTY
                          </th>
                          <th className='min-w-80px text-white text-center pb-3'>
                            <span className='arabic-font'>وحدة قياس</span>
                            <br />
                            UOM
                          </th>

                          <th className='min-w-80px text-white text-center pb-3'>
                            <span className='arabic-font'>سعر الوحدة</span>
                            <br />
                            Unit Price
                          </th>

                          <th className='min-w-80px text-white text-center pb-3'>
                            <span className='arabic-font'>المجموع</span>
                            <br />
                            Total
                          </th>
                        </tr>
                      </thead>
                      <tbody className='' style={{height: '250px'}}>
                        {data?.items
                          ?.slice(
                            idx === 1 || idx === 0 ? idx * 10 : idx * 20 - 10,
                            idx === 0 ? 10 : idx * 20 + 10
                          )
                          .map((item, index) => (
                            <tr
                              key={index + idx}
                              className='fw-semibold text-gray-600 fs-9 text-center'
                            >
                              <td className=''>
                                {idx === 0
                                  ? index + 1
                                  : idx === 1
                                  ? index + 11
                                  : index + idx * 20 - 9}
                              </td>
                              <td className='text-justify'>{item?.productName}</td>
                              <td className=''>{item.quantity}</td>
                              <td className='uppercase'>
                                {item?.unit || item?.itemId?.unit || item?.itemsId?.unit || 'units'}
                              </td>
                              <td className='text-end'>
                                {Number(item?.price).toLocaleString('en-US', {
                                  minimumFractionDigits: 2,
                                })}{' '}
                              </td>
                              <td className='text-end'>
                                {Number(item?.amount).toLocaleString('en-US', {
                                  minimumFractionDigits: 2,
                                })}{' '}
                              </td>
                            </tr>
                          ))}
                        {(idx + 1 === page || page === 1) && (
                          <>
                            <tr className=''>
                              <td colSpan={2} className='p-0'>
                                <table className='table w-full m-0 '>
                                  <tbody
                                    className='min-w-80px'
                                    style={{display: 'flex', flexDirection: 'column'}}
                                  >
                                    <tr className=''></tr>
                                  </tbody>
                                </table>
                              </td>
                              <td colSpan={2} className='p-0 text-sm'>
                                <table className='table table-bordered fs-8 w-full m-0 p-0 border-transparent'>
                                  <thead>
                                    <tr className='hidden'>
                                      <th className='min-w-80px'></th>
                                    </tr>
                                  </thead>
                                  <tbody className='text-end '>
                                    <tr className='border-b-black uppercase'>
                                      <td className=' '>
                                        <br />
                                        Sub Total
                                      </td>
                                    </tr>
                                    <tr className='border-b-black uppercase'>
                                      <td className=''>
                                        <br />
                                        Total VAT Amount ({data?.tax}%)
                                      </td>
                                    </tr>
                                    <tr className=''>
                                      <td className=' uppercase'>
                                        <br />
                                        Total
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td colSpan={2} className='p-0 text-sm'>
                                <table className='table table-bordered fs-8 w-full m-0 p-0 border-transparent'>
                                  <thead>
                                    <tr className='hidden'>
                                      <th className='min-w-80px'></th>
                                      <th className='min-w-80px'></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr className=' text-center p-2 border-b-black'>
                                      <td className='min-w-80px'>
                                        {/* {' '} */}
                                        <br />
                                        <span>{data?.vendor?.currency}</span>
                                      </td>
                                      <td className='text-end min-w-80px'>
                                        {/* {' '} */}
                                        <br />
                                        <span>
                                          {data?.subtotal.toLocaleString('en-US', {
                                            minimumFractionDigits: 2,
                                          })}{' '}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr className='text-center p-2 border-b-black'>
                                      <td className=''>
                                        {}
                                        <br />
                                        <span>{data?.vendor?.currency}</span>
                                      </td>
                                      <td className='text-end'>
                                        {}
                                        <br />
                                        {vat?.toLocaleString('en-US', {
                                          minimumFractionDigits: 2,
                                        })}{' '}
                                      </td>
                                    </tr>
                                    <tr className='text-center p-2 '>
                                      <td className=''>
                                        {}
                                        <br />

                                        <span>{data?.vendor?.currency}</span>
                                      </td>
                                      <td className='text-end'>
                                        {}

                                        <br />

                                        <span>
                                          {data?.total?.toLocaleString('en-US', {
                                            minimumFractionDigits: 2,
                                          })}{' '}
                                        </span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              {/* Empty cell for design consistency */}
                            </tr>
                            <tr className='py-1 fs-8 text-end'>
                              <td colSpan={2} className='text-start'>
                                <span className='uppercase fw-bold'>Total in words</span>
                                <br />
                                <span className='fw-bold'>{data?.vendor?.currency}</span>{' '}
                                {/* {numberToWords(data?.total)}
                                {data?.vendor?.currency === 'SAR' && ' Riyals'} */}
                                {data?.vendor?.currency === 'SAR'
                                  ? convertSARAmountToWords(data?.total)
                                  : data?.vendor?.currency === 'USD' ||
                                    data?.vendor?.currency === 'EUR' ||
                                    data?.vendor?.currency === 'INR' ||
                                    data?.vendor?.currency === 'QAR' ||
                                    data?.vendor?.currency === 'AED'
                                  ? convertAmountToWords(data?.total, data?.vendor?.currency)
                                  : `${numberToWords(data?.total)} Only.`}
                              </td>
                              <td colSpan={2} className='uppercase'>
                                Net Total
                              </td>
                              <td className='text-center border-r-transparent'>
                                <span> {data?.vendor?.currency}</span>
                              </td>
                              <td className='text-end'>
                                <span>
                                  {' '}
                                  {data?.total?.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                  })}{' '}
                                </span>
                                <br />
                                <br />
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {/*end::Table*/}
                  {/*begin::Container*/}
                  {/*end::Container*/}
                </div>
                {/*end::Content*/}
              </div>

              {/* end::Wrapper */}
            </div>
            {/* end::Order 2 content */}
          </div>
          {/* end::Content */}
          {/* begin::Sidebar */}
          <div className='m-0 '>
            {/* begin::Invoice 2 sidebar */}
            {/* d-print-none */}
            {(idx + 1 === page || page === 1) && (
              <div className='p-9 bg-lighten'>
                <div className={`m-0  p-2 `}>
                  <div className='d-flex flex-column fs-8'>
                    <h6 className='fw-bold text-danger'>Note</h6>
                    <p
                      className='min-h-40px'
                      style={{
                        lineHeight: '8px',
                      }}
                    >
                      {data?.notes?.split('\n').map((line, index) => (
                        <p key={index}>{line}</p>
                      ))}
                    </p>
                  </div>
                  {/* end::Invoice 2 sidebar */}
                </div>
              </div>
            )}
            {/* end::Invoice 2 sidebar */}
          </div>
          {/* end::Sidebar */}
        </div>
        {/* end::Layout */}
      </div>
      {/* end::Body */}
      <div id='pdfFooter' className='card-footer text-center py-2 px-0 fw-bold mb-1'>
        <div className={`bg-[#515560] ${!minFooter && '-skew-x-12'} text-white`}>
          <div className={`${!minFooter && 'skew-x-12'} py-1`}>
            {!minFooter &&
              `Mobile :${' '}
        ${data?.company?.mobileNumber} | `}
            {data?.company?.companyEmail} | {data?.company?.webURL}
          </div>
        </div>
        <div
          className={`${!minFooter && '-skew-x-12'} text-white`}
          style={{
            backgroundColor: `${color}`,
          }}
        >
          <div className={`${!minFooter && 'skew-x-12'} py-1`}>
            P.O. Box : {data?.company?.pOBox} | Address: {data?.company?.companyAddress}
          </div>
        </div>
      </div>
      <div className='d-flex justify-center mb-4'>
        <p className='fs-8 serif-font'>
          Page {idx + 1} of {page}
        </p>
      </div>
    </div>
  ))

  return (
    <>
      <div className='app-content flex-column-fluid '>
        {/* begin::Content container */}

        <div id='kt_app_content_container' className='print-content container-xxl'>
          {/* begin::Invoice 2 main */}
          {PageContent}
          {/* end::Invoice 2 main */}
        </div>
        {/* end::Content container */}
      </div>
    </>
  )
}

export default BillsDetails
