import React, {useEffect, useState} from 'react'
import {useAuth} from '../../../../modules/auth'
import {getCategoriesForProducts} from '../../../Inventory/Core/_categoryRequest'
import {createStock, postItem, productImage} from '../../../Inventory/Core/_requests'
import Thumbnail from '../../../Inventory/Components/Thumbnail'
import ProductDetails from '../../../Inventory/Components/ProductDetails'

export default function AddProduct({setProductList}: any) {
  const {currentUser} = useAuth()
  const [categoryType, setCategoryType] = useState('goods') //Product Details
  const [previewImage, setPreviewImage] = useState(null) // Thumbnail
  const [thumbnail, setThumbnail] = useState(null)
  const [categories, setCategories] = useState('') //Product Details
  const [tags, setTags] = useState([])
  const [productName, setProductName] = useState('') //General
  const [productDescription, setProductDescription] = useState('')
  const [sku, setSku] = useState('')
  const [description, setDescription] = useState('')
  const [selectedImage, setSelectedImage] = useState(null)
  const [categoriesOptions, setCategoriesOptions] = useState([])
  const [unit, setUnit] = useState('')
  const [costPrice, setCostPrice] = useState('')
  const [sellingPrice, setSellingPrice] = useState('')

  const fetchCategories = async () => {
    try {
      const response = await getCategoriesForProducts(currentUser.organization, categoryType)
      const categories = response.map((category) => ({
        value: category._id,
        label: category.categoryName,
      }))
      setCategoriesOptions(categories)
    } catch (error) {
      console.log('error fetching categories', error)
    }
  }

  useEffect(() => {
    fetchCategories()
  }, [currentUser, categoryType])

  const handleSubmit = async (e) => {
    e.preventDefault()
    console.log('Form submitted!')
    if (!selectedImage) {
      return alert('Please select an image')
    }
    if (
      productName === '' ||
      sku === '' ||
      sellingPrice === '' ||
      costPrice === '' ||
      unit === '' ||
      categories === ''
    ) {
      return alert('Please fill in all required fields')
    }
    const imageData = new FormData()
    const productPic = thumbnail
    imageData.append('name', productPic)
    imageData.append('image', selectedImage)

    const formData = {
      thumbnail,
      category: categories,
      tags,
      productName,
      productDescription,
      price: sellingPrice,
      sku,
      inWarehouseQuantity: 1,
      description,
      costPrice,
    }
    console.log('formData', formData)
    await productImage(imageData).then(async (res) => {
      // await productMedia(mediaData).then(async (req) => {
      if (categoryType === 'rentals' || categoryType === 'goods') {
        const data = {
          ...formData,
          organization: currentUser?.organization,
          company: currentUser?.company,
        }
        await createStock(data).then((response) => {
          setProductList((prev) => [
            ...prev,
            {
              value: response?._id,
              label: productName, // Corrected the typo here from "labe" to "label"
              price: sellingPrice,
              inWarehouseQuantity: 1,
              type: 'product',
            },
          ])
          setPreviewImage(null)
          setThumbnail(null)
          setCategories('')
          setTags([])
          setProductName('')
          setProductDescription('')
          setSku('')
          setDescription('')
          setSelectedImage(null)
          setUnit('')
          setCostPrice('')
          setSellingPrice('')

          const closeModalButton = document.getElementById('closeModalButton1')
          closeModalButton.click()
        })
      } else {
        const data = {
          ...formData,
          name: productName,
          description: productDescription,
          price: sellingPrice,
          category: categories,
          costPrice,
          quantity: 1,
          thumbnail,
          organization: currentUser?.organization,
          company: currentUser?.company,
        }
        await postItem(data).then((response) => {
          setProductList((prev) => [
            ...prev,
            {
              value: response?._id,
              label: productName, // Corrected the typo here from "labe" to "label"
              price: sellingPrice,
              inWarehouseQuantity: 1,
              type: 'item',
            },
          ])
          setPreviewImage(null)
          setThumbnail(null)
          setCategories('')
          setTags([])
          setProductName('')
          setProductDescription('')
          setSku('')
          setDescription('')
          setSelectedImage(null)
          setUnit('')
          setCostPrice('')
          setSellingPrice('')

          const closeModalButton = document.getElementById('closeModalButton1')
          closeModalButton.click()
        })
      }
    })
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className='bg-gray-50 p-4'>
        <div className='space-y-6'>
          <Thumbnail
            previewImage={previewImage}
            setPreviewImage={setPreviewImage}
            thumbnail={thumbnail}
            setThumbnail={setThumbnail}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
          />

          {/* add select for categoryType */}
          <div className='card card-flush py-4 d-flex flex-column'>
            <div className='card-header '>
              <div className='card-title '>
                <h2>Item Type</h2>
              </div>
            </div>
            <div className='card-body p-0 px-2 pb-2'>
              <select
                className='form-select form-control-solid'
                id='category'
                name='categoryType'
                value={categoryType}
                onChange={(e) => {
                  setCategoryType(e.target.value)
                  setCategories('')
                }}
              >
                <option value='goods'>Goods</option>
                <option value='rentals'>Equipments</option>
                <option value='service'>Service</option>
              </select>
            </div>
          </div>

          <ProductDetails
            categories={categories}
            categoriesOptions={categoriesOptions}
            setCategories={setCategories}
            tags={tags}
            setTags={setTags}
            showTagModal={false}
          />
        </div>
        <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
          <div className='d-flex flex-column gap-7 gap-lg-10'>
            <div className='card card-flush py-4 pt-0'>
              <div className='card-header'>
                <div className='card-title'>
                  <h2>Item Details</h2>
                </div>
              </div>

              <div className='card-body pt-0'>
                <div className='mb-10 fv-row'>
                  <label className='required form-label'>Item Name</label>

                  <input
                    type='text'
                    name='name'
                    className='form-control mb-2'
                    placeholder='Item name'
                    value={productName}
                    onChange={(e) => setProductName(e.target.value)}
                  />

                  <div className='text-muted fs-7'>
                    An Item name is required and recommended to be unique.
                  </div>
                </div>

                <div className='mb-10 fv-row'>
                  <label className='form-label'>Description</label>
                  <div className='w-full rounded-lg h-full'>
                    <textarea
                      name='description'
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      placeholder='type your text here..'
                      rows={4}
                      cols={50}
                      className='w-full rounded border px-4 py-2'
                    ></textarea>
                  </div>

                  <div className='text-muted fs-7'>
                    Set a description to the item for better visibility.
                  </div>
                </div>

                <div className='mb-10 fv-row'>
                  <label className='required form-label'>SKU</label>

                  <input
                    type='text'
                    name='sku'
                    className='form-control mb-2'
                    placeholder='SKU Number'
                    value={sku}
                    onChange={(e) => setSku(e.target.value)}
                  />

                  <div className='text-muted fs-7'>Enter the item SKU.</div>
                </div>

                <div className='mb-10 w-50'>
                  <label className='form-label required'>Unit</label>
                  <select
                    className='form-select form-control-solid'
                    id='unit'
                    name='unit'
                    value={unit}
                    onChange={(e) => setUnit(e.target.value)}
                  >
                    <option value=''>Select Unit</option>
                    {currentUser?.units?.map((unit) => (
                      <option value={unit.name}>{unit.name}</option>
                    ))}
                  </select>
                </div>

                <div className='mb-10 fv-row'>
                  <div className='mb-10 fv-row'>
                    <label className='required form-label'>Selling Price</label>
                    <input
                      type='number'
                      name='quantity'
                      min='0'
                      className='form-control mb-2 form-control-solid w-75'
                      placeholder={currentUser?.baseCurrency}
                      value={sellingPrice}
                      onChange={(e) => setSellingPrice(e.target.value)}
                    />
                  </div>
                </div>

                <div className='mb-10 fv-row'>
                  <div className='mb-10 fv-row'>
                    <label className='required form-label'>Cost Price</label>
                    <input
                      type='number'
                      name='quantity'
                      min='0'
                      className='form-control mb-2 form-control-solid w-75'
                      placeholder={currentUser?.baseCurrency}
                      value={costPrice}
                      onChange={(e) => setCostPrice(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='modal-footer d-flex justify-content-end'>
            <button
              type='button'
              className='btn btn-light me-5'
              data-bs-dismiss='modal'
              id='closeModalButton1'
            >
              Close
            </button>
            <button type='submit' className='btn btn-primary'>
              <span className='indicator-label'>Save Changes</span>
              <span className='indicator-progress'>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            </button>
          </div>
        </div>
        {/* </div> */}
        {/* </div> */}
      </div>
    </form>
  )
}
