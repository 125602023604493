import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../modules/auth';
import { Docs, Filtertype, SearchFilter, initialSearchFilter } from '../Core/_models';
import qs from 'qs'
import { getDocs } from '../Core/_requests';
import {Link} from 'react-router-dom'
import { KTIcon } from '../../../../_metronic/helpers';
import Filter from './Filter';
import profile from '../../../../_metronic/assets/profile.png'
import Search from './Search';
import Status from './Status';
import DeleteDoc from './DeleteDoc';

type props = {
    docs:Docs[]
    handlereset: () => void;
 
  }

const Table:React.FC<props> = ({docs,handlereset}) => {

    const PF = 'https://qf-assets.s3.me-central-1.amazonaws.com/'
   console.log(PF)
  return (
   
          
              <table className='table align-middle gs-0 gy-4 table-row-bordered'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted bg-light'>
                    <th className='ps-4 min-w-150px rounded-start'>Name</th>
                    <th className='min-w-150px'>Email</th>
                    <th className='min-w-150px'>Phone</th>
                    <th className='min-w-150px'>Status</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {docs.map((item: Docs | undefined) => (
                    <tr key={item?._id?.toString()}>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='symbol symbol-50px me-5'>
                         <img
                        src={item?.profilePic ? PF+item?.profilePic : profile}
                        className=''
                        alt=''
                      /> 
                          </div>
                          <Link
                            to={`/docs/docDetails/${item._id}`}
                            className=' doc_field cursor-pointer '
                          >
                            <div className='d-flex justify-content-start flex-column'>
                              <a className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                                {item?.name}
                              </a>
                            </div>
                          </Link>
                        </div>
                      </td>
                      <td>
                        <a
                          href={`mailto:${item?.email}`}
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          {item?.email}
                        </a>
                      </td>
                      <td>
                        <a
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          {item?.phone}
                        </a>
                      </td>
                      <td>
                      <Status status = {item?.status} id={item?._id} handlereset={handlereset} />
                      </td>
                      <td>
                      <DeleteDoc id={item?._id} handlereset={handlereset}/>
                      </td>
                     
                      

                     
                    </tr>
                  ))}
                </tbody>
                {/* end::Table body */}
              </table>
    
            
  )
}

export default Table