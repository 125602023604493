import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  employees: [],
  isLoading: false,
  fetch: true,
}

const hrpEmployeesSlice = createSlice({
  name: 'hrpEmployees',
  initialState,
  reducers: {
    setEmployees: (state, action) => {
      state.employees = action.payload
      state.isLoading = false
      state.fetch = false
    },
    setEmployeesLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setEmployeesDataFetch: (state) => {
      state.fetch = true
    },
  },
})

export const {setEmployees, setEmployeesLoading, setEmployeesDataFetch} =
  hrpEmployeesSlice.actions
export default hrpEmployeesSlice.reducer
