import React, {useEffect, useState} from 'react'
import {useAuth} from '../../../modules/auth'
import {useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../../redux/store'
import {Modal, Button, Form} from 'react-bootstrap'
import toast from 'react-hot-toast'
import {getCRMProducts, postCRMProduct} from '../core/_requests'
import {Link} from 'react-router-dom'
import {setGoodsData} from '../../../redux/inventory/products/goodsSlice'
import {getFilteredStocksByType} from '../../Inventory/Core/_requests'
import {PF} from '../../../utils/utils'
import {setCRMProducts} from '../../../redux/crm/products/productsSlice'

const CRMProductsPage = () => {
  const {currentUser} = useAuth()
  const dispatch = useDispatch()
  const [permissions, setPermissions] = useState<any>({})
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedProducts, setSelectedProducts] = useState([])
  const [availableProducts, setAvailableProducts] = useState([])
  const [loading, setLoading] = useState<boolean>(false)
  const goodsList = useSelector((state: RootState) => state.inventory.goods.goodsData)
  const productsDataList = useSelector((state: RootState) => state.crm.products.products)
  const productsFetch = useSelector((state: RootState) => state.crm.products.fetch)

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const getProductData = async () => {
    try {
      const res = await getFilteredStocksByType('', currentUser?.organization, 'goods')
      const data = res.map((r) => ({
        value: r._id,
        label: r.productName,
        price: r.price,
        inWarehouseQuantity: r.inWarehouseQuantity,
        type: 'product',
      }))
      setAvailableProducts(data)
      dispatch(setGoodsData(res))
    } catch (e) {
      console.log(e)
    }
  }

  const fetchProducts = () => {
    getProductData()
  }

  useEffect(() => {
    if (goodsList?.length === 0) fetchProducts()
    else {
      setAvailableProducts(
        goodsList?.map((r) => ({
          value: r._id,
          label: r.productName,
          price: r.price,
          inWarehouseQuantity: r.inWarehouseQuantity,
          type: 'product',
        }))
      )
    }
  }, [])

  const handleProductSelect = (productId) => {
    setSelectedProducts((prev) =>
      prev.includes(productId) ? prev.filter((id) => id !== productId) : [...prev, productId]
    )
  }

  const fetchData = async () => {
    setLoading(true)
    const response = await getCRMProducts(currentUser?.organization)
    dispatch(setCRMProducts(response))
    const temp = response.map((product) => product._id)
    setSelectedProducts(temp)
    setLoading(false)
  }

  useEffect(() => {
    if (currentUser.organization !== undefined) {
      if (productsFetch) fetchData()
      else {
        const temp = productsDataList.map((product) => product._id)
        setSelectedProducts(temp)
      }
    }
  }, [currentUser.organization])

  const handleSubmit = async () => {
    try {
      if (selectedProducts.length === 0) {
        toast.error('Please select at least one product')
        return
      }
      const data = {
        product: selectedProducts,
        company: currentUser?.company,
        organization: currentUser?.organization,
      }
      await postCRMProduct(data).then((res) => {
        toast.success('Products added successfully')
        setIsModalOpen(false)
        fetchData()
      })
    } catch (error) {
      console.error('Error submitting projects:', error)
    }
  }

  return (
    <div>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>Products</h3>
        <div className='d-flex flex-wrap gap-2'>
          {(permissions?.products?.create || currentUser?.profileType === 'superadmin') && (
            <Link to='/product/goods/add-product/'>
              <Button variant='success'>Create Product</Button>
            </Link>
          )}
          {(permissions?.crmproducts?.update || currentUser?.profileType === 'superadmin') && (
            <Button variant='primary' onClick={() => setIsModalOpen(true)}>
              Add Product
            </Button>
          )}
        </div>
      </div>

      <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Select Products</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div style={{maxHeight: '60vh', overflowY: 'auto'}}>
              {availableProducts?.map((product) => (
                <Form.Group key={product.value} className='mb-3'>
                  <Form.Check
                    type='checkbox'
                    id={`product-${product.value}`}
                    label={product?.label}
                    checked={selectedProducts.includes(product.value)}
                    onChange={() => handleProductSelect(product.value)}
                  />
                </Form.Group>
              ))}
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setIsModalOpen(false)}>
            Close
          </Button>
          <Button variant='primary' onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {loading ? (
        <div className='d-flex align-items-center justify-center'>
          <div className='loader'></div>
        </div>
      ) : (
        <div className='card'>
          <div className='card-body py-3'>
            <div className='table-responsive'>
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted '>
                    <th className='min-w-25px text-center'>Sr. No.</th>
                    <th className='min-w-150px text-center'>Product</th>
                    <th className='min-w-100px text-end'>SKU</th>
                    <th className='min-w-100px text-end'>Quantity</th>
                    <th className='min-w-100px text-end'>MRQ</th>
                    <th className='min-w-100px text-end'>Price</th>
                    <th className='min-w-150px text-center'>Tags</th>
                  </tr>
                </thead>
                <tbody>
                  {productsDataList &&
                    productsDataList.map((item, i) => (
                      <tr key={i} className=''>
                        <td className='text-center'>{i + 1}</td>
                        <td className='d-flex align-items-center'>
                          <div className='symbol symbol-50px me-2'>
                            <span className='symbol-label'>
                              <img
                                src={`${PF}${item?.thumbnail}`}
                                alt=''
                                className='h-50 align-self-center'
                              />
                            </span>
                          </div>
                          <div className='d-flex justify-content-start flex-column'>
                            <Link to={`/product/goods/view-product/${item._id}`}>
                              <span className='text-dark fw-bold text-hover-primary fs-6'>
                                {item?.productName.length > 30
                                  ? item?.productName.substring(0, 30) + '...'
                                  : item?.productName}
                              </span>
                            </Link>
                          </div>
                        </td>
                        <td className='text-muted fw-semibold text-end'>{item?.sku}</td>
                        <td className='text-muted fw-semibold text-end'>
                          {item?.inWarehouseQuantity}
                        </td>
                        <td className='text-muted fw-semibold text-end'>
                          {item?.mrqCheckbox ? item?.mrq : 'NA'}
                        </td>
                        <td className='text-muted fw-semibold text-end'>{item?.price}</td>
                        <td className='text-muted fw-semibold text-center'>
                          {item?.tags?.join(', ')}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default CRMProductsPage
