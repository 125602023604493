/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {Languages} from './Languages'
import profile from '../../../layout/components/header/profile.png'
import {useDispatch} from 'react-redux'
import {resetStore} from '../../../../app/redux/resetAction'

const HeaderUserMenu: FC = () => {
  const {currentUser, logout} = useAuth()
  const PF: any = 'https://qf-assets.s3.me-central-1.amazonaws.com/'
  const dispatch = useDispatch()

  const handleLogout = () => {
    dispatch(resetStore())
    logout()
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px !min-w-fit'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            {currentUser?.profilePic === '' && !currentUser.profilePic ? (
              <img src={profile} alt='' />
            ) : (
              <img alt='Logo' src={PF + currentUser.profilePic} />
            )}
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.fullName}
              <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>
                {currentUser?.profileType}
              </span>
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7 !truncate'>
              {currentUser?.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to={`profile-view`} className='menu-link px-5'>
          My Profile
        </Link>
      </div>

      {currentUser?.attendanceMethod === 'web' && (
        <div className='menu-item px-5'>
          <Link to='/attendance/add-attendance' className='menu-link px-5'>
            Add Attendance
          </Link>
        </div>
      )}

      <div className='separator my-2'></div>

      <Languages />

      <div className='menu-item px-5 my-1'>
        <Link to='/setting' className='menu-link px-5'>
          Profile Settings
        </Link>
      </div>

      <div className='menu-item px-5 my-1'>
        <Link to='/supportCenter' className='menu-link px-5'>
          Support Center
        </Link>
      </div>

      <div className='menu-item px-5'>
        <a onClick={handleLogout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
