import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import '../print.css'
import {getPurchaseReceiveById} from '../../Core/_requests'
import {GoGlobe} from 'react-icons/go'
import {MdOutlineMailOutline} from 'react-icons/md'

function PurchaseReceive({companyLogo, color, setColor, minFooter}) {
  const [contactPerson, setContactPerson] = useState<any>()
  const [imageSize, setImageSize] = useState<any>(false)
  const [data, setPurchaseReceiveData] = useState<any>()
  const {id} = useParams()
  const [page, setPage] = useState(1)

  const getData = async () => {
    try {
      const res = await getPurchaseReceiveById(id)
      setPurchaseReceiveData(res)
      setColor(res?.company?.procurementColor)
      if (res?.items?.length > 10) {
        const pages = Math.ceil((res?.items.length - 10) / 20)
        if ((res?.items.length - 10) / 20 > 10) {
          setPage(pages + 2)
        } else {
          setPage(pages + 1)
        }
      }
      if (res?.contactPerson !== '') {
        setContactPerson(
          res?.vendor?.contactPersons?.find((person) => person?._id === res?.contactPerson) || {}
        )
      } else {
        setContactPerson(res?.vendor?.primaryContact)
      }
      console.log('res', res)
    } catch (error) {
      console.error('Axios error:', error)
      throw error
    }
  }

  const handleOnLoad = (e) => {
    const img = e.target
    if (img.naturalWidth > img.naturalHeight) {
      setImageSize(true)
    } else {
      setImageSize(false)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })
    return formattedDate
  }

  const PF = 'https://qf-assets.s3.me-central-1.amazonaws.com/'

  const PageContent = Array.from({length: page}).map((_, idx) => (
    <div
      key={idx}
      id={`pdf-content-${idx}`}
      className='card overflow-hidden mx-auto !rounded-none'
      style={{
        height: '297mm',
        width: '210mm',
      }}
    >
      <div id='pdfHeader' className='p-2 mx-3 mt-2'>
        <div className='d-flex justify-content-between align-items-center flex-column flex-sm-row'>
          {/*end::Logo*/}
          <div className='text-sm-end'>
            {/*begin::Logo*/}
            <a href='#' className={`d-block ${imageSize ? 'mw-275px' : 'mw-150px'} ms-sm-auto`}>
              {companyLogo ? (
                <img
                  alt='Logo'
                  src={URL.createObjectURL(companyLogo)}
                  className={imageSize ? 'w-100' : 'w-50'}
                  onLoad={handleOnLoad}
                />
              ) : (
                <img
                  alt='thumbnail'
                  src={PF + data?.company?.companyLogo}
                  className={imageSize ? 'w-100' : 'w-50'}
                  onLoad={handleOnLoad}
                />
              )}
            </a>
            {/*end::Logo*/}
          </div>
          <h4 className='fw-bold text-gray-800 xl:fs-1 d-flex flex-column text-right header-text'>
            <span
              className='mb-1 text-right fs-1'
              style={{
                // fontFamily: 'Rubik',
                fontFamily: 'Scheherazade New',
                fontOpticalSizing: 'auto',
                fontStyle: 'normal',
                wordSpacing: '10px',
              }}
            >
              {data?.company?.arabicName}
            </span>
            <span
              className='mb-1 text-right fs-3 tracking-wider'
              style={{
                color: color,
              }}
            >
              {data?.company?.name}
            </span>
            <span className='fs-6 text-muted'>
              VAT {data?.company?.vat} <span style={{color: color}}>|</span> C.R.{' '}
              {data?.company?.cr}
            </span>
          </h4>
        </div>
      </div>
      {/*end::Header*/}
      <div className='border-b-black border-b mx-9'></div>

      {/* begin::Body*/}
      <div className='card-body px-lg-15'>
        {/* begin::Wrapper*/}
        <div className='mw-lg-1050px w-100'>
          {/* begin::Header*/}
          {/*begin::Body*/}

          {idx === 0 && (
            <div className='mb-6 fs-6 fw-bold text-gray-800 uppercase'>Purchase Receive</div>
          )}
          <div className='pb-2 mt-5'>
            {/*begin::Wrapper*/}
            <div className='d-flex flex-column gap-7 gap-md-10'>
              {idx === 0 && (
                <div
                  className='d-flex justify-content-between flex-column flex-sm-row fs-8'
                  style={{
                    textTransform: 'uppercase',
                  }}
                >
                  <div className=' mb-5'>
                    <div className='d-flex  flex-column mb-5'>
                      <span className='fw-bold'>PO Number</span>
                      <span className=''>{data?.purchaseOrder?.id}</span>
                    </div>
                    <div className='d-flex flex-column mb-5'>
                      <span className='fw-bold'>Customer</span>

                      <span className=''>{data?.vendor?.companyName}</span>
                    </div>
                    <div className='d-flex flex-md-root flex-column mb-5'>
                      <span className='fw-bold mb-1'>Customer Address</span>
                      <span className=' mw-200px'>
                        {data?.company?.name}
                        <br />
                        {data?.company?.companyAddress}{' '}
                      </span>
                    </div>
                  </div>
                  <div className='  mb-5 '>
                    <div className='d-flex  flex-column mb-5'>
                      <span className='fw-bold'>Attention</span>
                      <span className=''>
                        {contactPerson?.salutation} {contactPerson?.firstName}{' '}
                        {contactPerson?.lastName}
                      </span>
                    </div>
                    <div className=' d-flex flex-column mb-5'>
                      <span className='fw-bold'>Designation</span>
                      <span className=''>{contactPerson?.designation}</span>
                    </div>
                    <div className=' d-flex flex-column  '>
                      <span className='fw-bold'>Email ID</span>
                      <span className=''>{contactPerson?.emailAddress}</span>
                    </div>
                  </div>
                  <div className='text-end  mb-5 '>
                    <div className=' d-flex flex-column mb-5 '>
                      <span className='fw-bold'>Status</span>

                      <div className=''>
                        <span
                          className='badge badge-success fs-5'
                          style={{
                            padding: '0.5rem',
                          }}
                        >
                          {data?.status}
                        </span>
                      </div>
                    </div>
                    <div className=' d-flex flex-column  mb-5'>
                      <span className='fw-bold'>PR Number</span>

                      <span className=''>{data?.id}</span>
                    </div>
                    <div className=' d-flex flex-column  '>
                      <span className='fw-bold'>Receive Date</span>

                      <span className=''>{formatCreatedAt(data?.receivedDate)}</span>
                    </div>
                  </div>
                </div>
              )}

              {/*end::Order details*/}
              {/*begin::Billing & shipping*/}

              {/*end::Billing & shipping*/}
              {/*begin:Order summary*/}
              <div className='flex-grow-1'>
                {/*begin::Table*/}
                <div className='table-responsive border-bottom border-dashed mb-9'>
                  <table className='table table-bordered m-0 border-gray-700'>
                    <thead>
                      <tr
                        className='fs-8 fw-bold uppercase'
                        style={{
                          backgroundColor: color,
                        }}
                      >
                        <th className='min-w-25px text-white text-center pb-3 '>
                          <span>
                            <span className='arabic-font'>رقم</span>
                            <br />
                            S. No.
                          </span>
                        </th>
                        <th className='min-w-150px text-white text-center pb-3 '>
                          <span className='arabic-font'>وصف</span>
                          <br />
                          Description
                        </th>
                        <th className='min-w-70px text-white text-center pb-3'>
                          <span className='arabic-font'>كمية</span>
                          <br />
                          QTY
                        </th>
                        <th className='min-w-80px text-white text-center pb-3'>
                          <span className='arabic-font'>تلقى</span>
                          <br />
                          Received
                        </th>
                        <th className='min-w-80px text-white text-center pb-3'>
                          <span className='arabic-font'>أمر</span>
                          <br />
                          Ordered
                        </th>
                        <th className='min-w-80px text-white text-center pb-3'>
                          <span className='arabic-font'>في مرحلة انتقالية</span>
                          <br />
                          In Transit
                        </th>
                      </tr>
                    </thead>
                    <tbody className=''>
                      {data?.items
                        ?.slice(
                          idx === 1 || idx === 0 ? idx * 10 : idx * 20 - 10,
                          idx === 0 ? 10 : idx * 20 + 10
                        )
                        .map((item, index) => (
                          <tr
                            key={index + idx}
                            className='fw-semibold text-gray-600 fs-9 text-center'
                          >
                            <td className=''>
                              {idx === 0
                                ? index + 1
                                : idx === 1
                                ? index + 11
                                : index + idx * 20 - 9}
                            </td>
                            <td className='text-justify'>{item?.productName}</td>
                            <td className=''>{item?.quantity}</td>
                            <td>{item?.received}</td>
                            <td>{item?.quantity}</td>
                            <td>{item?.balance}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                {/*end::Table*/}
                {/*begin::Container*/}

                {/*end::Container*/}
              </div>
              {/*end:Order summary*/}
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Body*/}
          {/* begin::Footer*/}

          {/* end::Footer*/}
        </div>
        {/* end::Wrapper*/}
      </div>
      {/* end::Body*/}
      <div id='pdfFooter' className='card-footer text-center py-2 px-0 fw-bold mb-1'>
        <div className={`bg-[#515560] ${!minFooter && '-skew-x-12'} text-white`}>
          <div className={`${!minFooter && 'skew-x-12'} py-1`}>
            {!minFooter &&
              `Mobile :${' '}
        ${data?.company?.mobileNumber} | `}
            <MdOutlineMailOutline
              style={{
                color,
              }}
              className='inline mr-2'
            />
            {data?.company?.companyEmail}
            <GoGlobe
              className='inline mr-2 ml-4'
              style={{
                color,
              }}
            />
            {data?.company?.webURL}
          </div>
        </div>
        <div
          className={`${!minFooter && '-skew-x-12'} text-white`}
          style={{
            backgroundColor: `${color}`,
          }}
        >
          <div className={`${!minFooter && 'skew-x-12'} py-1`}>
            P.O. Box : {data?.company?.pOBox} | Address: {data?.company?.companyAddress}
          </div>
        </div>
      </div>
      <div className='d-flex justify-center mb-4'>
        <p className='fs-8 serif-font'>
          Page {idx + 1} of {page}
        </p>
      </div>
    </div>
  ))

  return (
    <>
      <div className='app-content flex-column-fluid '>
        {/*begin::Content container*/}
        <div id='kt_app_content_container' className='print-content container-xxl'>
          {/* begin::Invoice 3*/}
          {PageContent}
          {/* end::Invoice 1*/}{' '}
        </div>
        {/*end::Content container*/}
      </div>
    </>
  )
}

export default PurchaseReceive
