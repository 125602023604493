import {combineReducers} from '@reduxjs/toolkit'
import projectsReducer from './projectsSlice'
import hrpDepartmentsReducer from './hrpDepartmentsSlice'
import hrpEmployeesReducer from './hrpEmployeesSlice'
import hrpEmployeeGroupReducer from './hrpEmployeeGroupSlice'
import campsReducer from './campsSlice'
import hrpLeaveManagementReducer from './hrpLeaveManagementSlice'

const rootReducer = combineReducers({
  projects: projectsReducer,
  departments: hrpDepartmentsReducer,
  employees: hrpEmployeesReducer,
  employeeGroups: hrpEmployeeGroupReducer,
  camps: campsReducer,
  leaveManagement: hrpLeaveManagementReducer,
})

export default rootReducer
