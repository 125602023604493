import React, {useState, useEffect} from 'react'
import {useParams, Link} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'
import {getParentAccountByOrg, getParentAccountsExcelData} from '../core/_requests'
import * as XLSX from 'xlsx'
import {KTIcon} from '../../../../_metronic/helpers'

const ParentAccounts: React.FC = () => {
  const {accountType} = useParams<{accountType: string}>()
  const {currentUser} = useAuth()
  const [accounts, setAccounts] = useState<any[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchAccounts = async () => {
      try {
        setLoading(true)
        const response = await getParentAccountByOrg(currentUser?.organization, accountType)
        setAccounts(response)
      } catch (error) {
        console.error('Error fetching parent accounts:', error)
      } finally {
        setLoading(false)
      }
    }

    if (currentUser?.organization && accountType) {
      fetchAccounts()
    }
  }, [currentUser, accountType])

  const formatAmount = (amount: number) => {
    return `${currentUser?.baseCurrency} ${amount?.toLocaleString('en-US', {
      minimumFractionDigits: 2,
    })}`
  }

  const getAccountTypeLabel = (type: string) => {
    return (
      type.charAt(0).toUpperCase() +
      type
        .slice(1)
        .replace(/([A-Z])/g, ' $1')
        .trim()
    )
  }

  const handleDownloadExcel = async () => {
    try {
      const data = await getParentAccountsExcelData(currentUser?.organization, accountType)
      // Create a new workbook
      const wb = XLSX.utils.book_new()
      // Create a worksheet for parent accounts
      const parentWs = XLSX.utils.json_to_sheet(
        data.parentAccounts.map((parent: any) => ({
          'Account Name': parent.accountName,
          'Account Code': parent.accountCode,
          'Total Amount': parent.childAccounts.reduce(
            (sum: number, child: any) => sum + child.amount,
            0
          ),
        }))
      )
      XLSX.utils.book_append_sheet(wb, parentWs, 'Parent Accounts')
      // Create a worksheet for all child accounts
      const allChildAccounts = data.parentAccounts.flatMap((parent: any) =>
        parent.childAccounts.map((child: any) => ({
          'Parent Account': parent.accountName,
          'Account Name': child.accountName,
          'Account Code': child.accountCode,
          Amount: child.amount,
        }))
      )
      const childWs = XLSX.utils.json_to_sheet(allChildAccounts)
      XLSX.utils.book_append_sheet(wb, childWs, 'Child Accounts')
      // Generate Excel file
      XLSX.writeFile(wb, `${getAccountTypeLabel(accountType)}_accounts.xlsx`)
    } catch (error) {
      console.error('Error downloading Excel data:', error)
    }
  }

  return (
    <div className='card'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>
            {getAccountTypeLabel(accountType || '')} Parent Accounts
          </span>
          <span className='text-muted mt-1 fw-semibold fs-7'>
            List of parent accounts for {getAccountTypeLabel(accountType || '')}
          </span>
        </h3>
        <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-light-primary me-3'
            onClick={handleDownloadExcel}
          >
            <KTIcon iconName='document' className='fs-2' />
            Download Excel
          </button>
        </div>
      </div>
      <div className='card-body py-3'>
        {loading ? (
          <div className='d-flex align-items-center justify-center'>
            <div className='loader'></div>
          </div>
        ) : accounts.length > 0 ? (
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-300px rounded-start'>Account Name</th>
                  <th className='min-w-125px'>Account Code</th>
                  <th className='min-w-125px'>Total Amount</th>
                  <th className='min-w-125px text-center'>Child Accounts</th>
                </tr>
              </thead>
              <tbody>
                {accounts.map((account) => (
                  <tr key={account._id}>
                    <td>
                      <div className='ps-4 d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <Link
                            to={`${account._id}`}
                            className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                          >
                            {account.accountName}
                          </Link>
                        </div>
                      </div>
                    </td>
                    <td>{account.accountCode}</td>
                    <td>{formatAmount(account.totalAmount)}</td>
                    <td className='text-center'>{account.childAccounts?.length || 0}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className='text-center'>
            <div className='mt-5'>No parent accounts found for this type.</div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ParentAccounts
