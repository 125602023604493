import React, {useEffect, useState} from 'react'
import {useAuth} from '../../../modules/auth'
import {getAllDepartment} from '../../hrm/core/_requests'
import {Link} from 'react-router-dom'
import {Modal, Button, Form} from 'react-bootstrap'
import toast from 'react-hot-toast'
import profile from '../../../../_metronic/assets/profile.png'
import {getCRMAgents, postCRMAgent} from '../core/_requests'
import {useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../../redux/store'
import {setCRMAgents, setCRMAgentsSelectedDepartments} from '../../../redux/crm/agents/agentsSlice'

const CRMAgentsPage = () => {
  const {currentUser} = useAuth()
  const agents = useSelector((state: RootState) => state.crm.agents.agents)
  const selectedDepartments = useSelector(
    (state: RootState) => state.crm.agents.selectedDepartments
  )
  const crmAgentsFetch = useSelector((state: RootState) => state.crm.agents.fetch)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [availableDepartments, setAvailableDepartments] = useState([])
  const [loading, setLoading] = useState<boolean>(false)
  const [permissions, setPermissions] = useState<any>({})
  const dispatch = useDispatch()

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const fetchData = async () => {
    const res = await getAllDepartment(currentUser?.organization)
    setAvailableDepartments(res)
  }

  const fetchCRMAgents = async () => {
    setLoading(true)
    const res = await getCRMAgents(currentUser?.organization)
    dispatch(setCRMAgents(res))
    setLoading(false)
  }

  useEffect(() => {
    if (currentUser?.organization) {
      if (crmAgentsFetch) fetchCRMAgents()
      fetchData()
    }
  }, [currentUser?.organization])

  const handleDepartmentSelect = (departmentId) => {
    const temp = [...selectedDepartments]
    if (temp.includes(departmentId)) {
      const filteredTemp = temp.filter((id) => id !== departmentId)
      dispatch(setCRMAgentsSelectedDepartments(filteredTemp))
    } else {
      temp.push(departmentId)
      dispatch(setCRMAgentsSelectedDepartments(temp))
    }
  }

  const handleSubmit = async () => {
    try {
      if (selectedDepartments.length === 0) {
        toast.error('Please select at least one department')
        return
      }
      const data = {
        department: selectedDepartments,
        company: currentUser?.company,
        organization: currentUser?.organization,
      }
      await postCRMAgent(data).then((res) => {
        toast.success('Departments added successfully')
        setIsModalOpen(false)
        fetchCRMAgents()
      })
    } catch (error) {
      console.error('Error submitting departments:', error)
    }
  }

  return (
    <div className='p-6 md:m-4 card card-xxl-stretch'>
      <div className='d-flex justify-between card-label fw-bold fs-3 mb-1s'>
        Agents
        {(permissions?.crmagents?.update || currentUser?.profileType === 'superadmin') && (
          <Button variant='primary' onClick={() => setIsModalOpen(true)}>
            Select Departments
          </Button>
        )}
      </div>

      <div className='card-body py-3'>
        {loading ? (
          <div className='d-flex align-items-center justify-center'>
            <div className='loader'></div>
          </div>
        ) : (
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-200px rounded-start'>Employees</th>
                  <th className='min-w-125px'>Department</th>
                  <th className='min-w-125px'>Roles</th>
                  <th className='min-w-125px'>Employee Id</th>
                  <th className='min-w-150px'>Contract</th>
                  <th className='min-w-100px'>Status</th>
                </tr>
              </thead>
              <tbody>
                {agents &&
                  agents.map((item, i) => (
                    <tr key={i}>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='symbol symbol-50px me-5'>
                            <img src={`${profile}`} className='img-fluid rounded-circle' alt='' />
                          </div>
                          <div className='d-flex justify-content-start flex-column'>
                            <Link
                              to={`/hrm/view-employee/${item._id}`}
                              className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                            >
                              {item.firstName} {item.lastName}
                            </Link>
                          </div>
                        </div>
                      </td>
                      <td>
                        <a
                          href='#'
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          {item?.department?.name}
                        </a>
                      </td>
                      <td>
                        <a
                          href='#'
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          {item?.role}
                        </a>
                      </td>
                      <td>
                        <a
                          href='#'
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          {item.employeeId}
                        </a>
                      </td>
                      <td>
                        <a
                          href='#'
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 capitalize'
                        >
                          {item.contractType}
                        </a>
                      </td>
                      <td>
                        <span className='badge badge-light-primary fs-7 fw-semibold'>
                          {item.employeeStatus}
                        </span>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Select Departments</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div style={{maxHeight: '60vh', overflowY: 'auto'}}>
              {availableDepartments.map((department) => (
                <Form.Group key={department._id} className='mb-3'>
                  <Form.Check
                    type='checkbox'
                    id={`department-${department._id}`}
                    label={department?.name}
                    checked={selectedDepartments.includes(department._id)}
                    onChange={() => handleDepartmentSelect(department._id)}
                  />
                </Form.Group>
              ))}
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setIsModalOpen(false)}>
            Close
          </Button>
          <Button variant='primary' onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default CRMAgentsPage
