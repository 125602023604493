import React, {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'

import {getLeavesByMonth} from '../../core/_requests'
import {useAuth} from '../../../../modules/auth'

const EmployeeLeavePage = () => {
  const {currentUser} = useAuth()
  const {month} = useParams()
  const [permissions, setPermissions] = useState<any>({})
  const [employeeList, setEmployeeList] = useState([])

  useEffect(() => {
    if (currentUser) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      // day: '2-digit',
      // month: 'short',
      // year: 'numeric',
    })
    return formattedDate
  }
  const fetchData = async () => {
    try {
      const response = await getLeavesByMonth(month, currentUser?.organization)
      console.log('response', response)
      // const leaveTypeId = response.data.leaveType;
      // console.log(leaveTypeId);
      setEmployeeList(response)
      console.log(response)
    } catch {
      console.log('error')
    }
  }
  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div className='card card-flush'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>
            Employee Leaves of <span className='capitalize'>{month}</span>
          </span>
        </h3>
      </div>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table align-middle gs-0 gy-4 table-row-bordered'>
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-50px rounded-start'>No</th>
                <th className='min-w-150px'>Employee</th>
                <th className='min-w-150px'>Department</th>
                <th className='min-w-100px'>Leave Type</th>
                <th className='min-w-100px'>Start Date</th>
                <th className='min-w-100px'>End Date</th>
                <th className='min-w-100px'>Reason</th>
              </tr>
            </thead>
            <tbody>
              {/* Map through the employee leave list and render the rows */}
              {Array.isArray(employeeList) &&
                employeeList.map((employee: any, index) => (
                  <tr key={employee?._id?.toString()}>
                    <td>
                      <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {index + 1}
                      </div>
                    </td>
                    <td>
                      <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {employee?.employeeId?.firstName + ' ' + employee?.employeeId?.lastName}
                      </div>
                    </td>
                    <td>
                      <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {employee?.departmentId?.name}
                      </div>
                    </td>
                    <td>
                      <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {employee?.leaveType?.leaveType}
                      </div>
                    </td>
                    <td>
                      <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {formatCreatedAt(employee?.startDate)}
                      </div>
                    </td>
                    <td>
                      <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {formatCreatedAt(employee?.endDate)}
                      </div>
                    </td>
                    <td>
                      <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {employee?.reason}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default EmployeeLeavePage
