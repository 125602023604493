import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  materialsData: [],
  loading: true,
  fetch: true,
}

const materialsSlice = createSlice({
  name: 'materials',
  initialState,
  reducers: {
    setMaterialsData(state, action) {
      state.materialsData = action.payload
      state.loading = false
      state.fetch = false
    },
    setLoading(state) {
      state.loading = true
    },
    addMaterials(state, action) {
      state.materialsData.push(action.payload)
    },
    setMaterialsDataFetch(state) {
      state.fetch = true
    },
  },
})

export const {setMaterialsData, setLoading, addMaterials, setMaterialsDataFetch} =
  materialsSlice.actions
export default materialsSlice.reducer
