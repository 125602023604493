import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {MenuComponent} from '../../../../_metronic/assets/ts/components'

import {SearchFilter} from '../Core/_model'
import {getVendorType} from '../Core/_requests'
import {useAuth} from '../../../modules/auth'

type Props = {
  searchFilters: SearchFilter
  setSearchFilter: (updateValues: SearchFilter) => void
  setStatusFilter: (isActive: boolean) => void
  getDocsList: () => void
  handlereset: () => void
}

const Filter: React.FC<Props> = ({
  handlereset,
  setSearchFilter,
  searchFilters,
  setStatusFilter,
  getDocsList,
}) => {
  const {currentUser} = useAuth()
  const [status, setstatus] = useState('active')
  const [vendorType, setvendorType] = useState('')
  const [vendorTypeOptions, setVendorTypeOptions] = useState([])

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  useEffect(() => {
    const fetchVendorType = async () => {
      try {
        const res = await getVendorType(currentUser?.organization)
        if (!res || !Array.isArray(res.vendorType)) {
          console.error('Invalid response format from getIncoTerms:', res)
          return
        }
        const filteredVendorType = res.vendorType.filter((item) => item !== null)
        const data = filteredVendorType.map((item) => ({value: item, label: item}))
        setVendorTypeOptions(data)
      } catch (error) {
        console.error('Error fetching vendortype:', error)
      }
    }

    if (currentUser.organization !== undefined) fetchVendorType()
  }, [currentUser])

  useEffect(() => {
    setSearchFilter({
      ...searchFilters,
      filter: {
        ...searchFilters.filter,
        isActive: status === 'active',
        vendorType: vendorType,
      },
    })
  }, [status, setSearchFilter, searchFilters, vendorType])

  const handleStatusChange = (e) => {
    const isActive = e.target.value === 'active'
    setstatus(isActive ? 'active' : 'inactive')
  }

  return (
    <>
      {/* begin::Filter Button */}
      <div
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        data-kt-menu-flip='top-end'
      >
        <KTIcon iconName='filter' className='fs-2' />
        Filter
      </div>
      <div
        className='menu menu-sub menu-sub-dropdown w-400px w-md-400px p-5'
        data-kt-menu='true'
        style={{zIndex: 1000000}}
      >
        {/* begin::Header */}
        <div className='px-7 py-2'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          {/* begin::Input group */}
          <div className='mb-5 d-flex flex-row align-content-center justify-content-center'>
            <label className='form-label fs-6 fw-bold mt-2 w-100px me-5'>Status:</label>
            <select
              className='form-select form-select-solid fw-bolder w-75'
              // onChange={(e) => setstatus(e.target.value)}
              onChange={handleStatusChange}
              value={status}
            >
              <option value='active'>Active</option>
              <option value='inactive'>Inactive</option>
            </select>
          </div>
          <div className='mb-5 d-flex flex-row align-content-center justify-content-center'>
            <label className='form-label fs-6 fw-bold mt-2 w-100px me-5'>Vendor Type:</label>
            <select
              className='form-select form-select-solid fw-bolder w-75'
              // onChange={(e) => setstatus(e.target.value)}
              onChange={(e) => setvendorType(e.target.value)}
              value={vendorType}
            >
              <option></option>
              {vendorTypeOptions?.map((item, index) => (
                <option value={item?.value} key={item?.value}>
                  {item?.label}
                </option>
              ))}
            </select>
          </div>

          {/* end::Input group */}

          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              // disabled={isLoading}
              // onClick={filterData}
              className='btn btn-light btn-light-danger fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
              onClick={handlereset}
            >
              Reset
            </button>
            <button
              type='button'
              // disabled={isLoading}
              // onClick={filterData}
              className='btn btn-light btn-light-primary fw-bold me-2 px-6'
              onClick={getDocsList}
            >
              Apply
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
    </>
  )
}

export default Filter
