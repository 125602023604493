import React from 'react'
import {Modal, Form, Button} from 'react-bootstrap'
import {Contact} from '../core/model'

interface ContactModalProps {
  isEdit: boolean
  isOpen: boolean
  onClose: () => void
  onSubmit: (data: any) => void
  formData: Contact
  setFormData: (data: Contact) => void
}

const CRMContactsModal = ({
  isOpen,
  onClose,
  onSubmit,
  formData,
  setFormData,
  isEdit,
}: ContactModalProps) => {
  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{isEdit ? 'Edit Contact' : 'Add Contact'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          <div className='row mb-3'>
            <div className='col-6'>
              <Form.Group controlId='firstName'>
                <Form.Label className='required'>First Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='First Name'
                  value={formData.firstName}
                  onChange={(e) => setFormData({...formData, firstName: e.target.value})}
                />
              </Form.Group>
            </div>
            <div className='col-6'>
              <Form.Group controlId='lastName'>
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Last Name'
                  value={formData.lastName}
                  onChange={(e) => setFormData({...formData, lastName: e.target.value})}
                />
              </Form.Group>
            </div>
          </div>
          <div className='mb-3'>
            <Form.Group controlId='displayName'>
              <Form.Label className='required'>Display Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='Display Name'
                value={formData.displayName}
                onChange={(e) => setFormData({...formData, displayName: e.target.value})}
              />
            </Form.Group>
          </div>
          <div className='mb-3'>
            <Form.Group controlId='email'>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type='email'
                placeholder='Email'
                value={formData.email}
                onChange={(e) => setFormData({...formData, email: e.target.value})}
              />
            </Form.Group>
          </div>
          <div className='mb-3'>
            <Form.Group controlId='phone'>
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type='tel'
                placeholder='Phone'
                value={formData.phone}
                onChange={(e) => setFormData({...formData, phone: e.target.value})}
              />
            </Form.Group>
          </div>
          <div className='mb-3'>
            <Form.Group controlId='companyName'>
              <Form.Label className='required'>Company Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='Company Name'
                value={formData.companyName}
                onChange={(e) => setFormData({...formData, companyName: e.target.value})}
              />
            </Form.Group>
          </div>
          <div className='mb-3'>
            <Form.Group controlId='customerType'>
              <Form.Label className='required'>Customer Type</Form.Label>
              <Form.Select
                value={formData.customerType}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    customerType: e.target.value as 'individual' | 'business',
                  })
                }
              >
                <option value='individual'>Individual</option>
                <option value='business'>Business</option>
              </Form.Select>
            </Form.Group>
          </div>
          <Button variant={isEdit ? 'warning' : 'primary'} type='submit'>
            {isEdit ? 'Update' : 'Add'} Contact
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default CRMContactsModal
