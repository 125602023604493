import React, {useEffect, useState} from 'react'
import DashboardStats from '../Components/DashboardStats'
import {getPurchaseOrderDashboardData} from '../Core/_requests'
import {useAuth} from '../../../modules/auth'
import PaymentPendingPO from '../Components/PaymentPendingPO'

interface stats {
  count: string | number
  total: number
}

interface DashboardData {
  purchaseorder: stats
  advancepaid: stats
  partiallypaid: stats
  paymentcompleted: stats
  purchasereceive: stats
  bills: stats
  paymentmade: stats
}

const ProcurementDashboard = () => {
  const {currentUser} = useAuth()
  const [dashboardData, setDashboardData] = useState<DashboardData>({
    purchaseorder: {count: 'Loading...', total: 0},
    advancepaid: {count: 'Loading...', total: 0},
    partiallypaid: {count: 'Loading...', total: 0},
    paymentcompleted: {count: 'Loading...', total: 0},
    purchasereceive: {count: 'Loading...', total: 0},
    bills: {count: 'Loading...', total: 0},
    paymentmade: {count: 'Loading...', total: 0},
  })
  useEffect(() => {
    const fetchData = async () => {
      const res = await getPurchaseOrderDashboardData(currentUser.organization)
      const payment = res?.purchaseorderpayment
      const advancepaid = payment?.find((item: any) => item._id === 'advance')
      const partiallypaid = payment?.find((item: any) => item._id === 'partial')
      const paymentcompleted = payment?.find((item: any) => item._id === 'paid')
      setDashboardData({
        ...res,
        advancepaid: advancepaid || {count: 0, total: 0},
        partiallypaid: partiallypaid || {count: 0, total: 0},
        paymentcompleted: paymentcompleted || {count: 0, total: 0},
      })
    }
    if (currentUser.organization !== undefined) {
      fetchData()
    }
  }, [currentUser.organization])

  return (
    <div>
      <div className='row gx-10 mb-10'>
        <div className='col-lg-3'>
          <DashboardStats
            title='Total Purchase Order'
            number={dashboardData?.purchaseorder.count}
            amount={dashboardData?.purchaseorder.total}
            className='bg-warning-subtle border-warning border-1'
            loading={dashboardData.purchaseorder.count === 'Loading...'}
          />
        </div>
        <div className='col-lg-3'>
          <DashboardStats
            title='Total Advance Paid'
            number={dashboardData?.advancepaid.count}
            amount={dashboardData?.advancepaid.total}
            className='bg-primary-subtle border-primary border-1'
            loading={dashboardData.advancepaid.count === 'Loading...'}
          />
        </div>
        <div className='col-lg-3'>
          <DashboardStats
            title='Total Partially Paid'
            number={dashboardData?.partiallypaid.count}
            amount={dashboardData?.partiallypaid.total}
            className='bg-primary-subtle border-primary border-1'
            loading={dashboardData.partiallypaid.count === 'Loading...'}
          />
        </div>
        <div className='col-lg-3'>
          <DashboardStats
            title='Total Payment Completed'
            number={dashboardData?.paymentcompleted.count}
            amount={dashboardData?.paymentcompleted.total}
            className='bg-primary-subtle border-primary border-1'
            loading={dashboardData.paymentcompleted.count === 'Loading...'}
          />
        </div>
      </div>
      <div className='row gx-10'>
        <div className='col-lg-3'>
          <DashboardStats
            title='Total Purchase Receive'
            number={dashboardData?.purchasereceive.count}
            amount={dashboardData?.purchasereceive.total}
            className='bg-warning-subtle border-warning border-1 mb-10'
            loading={dashboardData.purchasereceive.count === 'Loading...'}
          />
          <DashboardStats
            title='Total Bills'
            number={dashboardData?.bills.count}
            amount={dashboardData?.bills.total}
            className='bg-warning-subtle border-warning border-1 mb-10'
            loading={dashboardData.bills.count === 'Loading...'}
          />
          <DashboardStats
            title='Total Payment Made'
            number={dashboardData?.paymentmade.count}
            amount={dashboardData?.paymentmade.total}
            className='bg-warning-subtle border-warning border-1 mb-10'
            loading={dashboardData.paymentmade.count === 'Loading...'}
          />
        </div>
        <div className='col-lg-9'>
          <PaymentPendingPO />
        </div>
      </div>
    </div>
  )
}

export default ProcurementDashboard
