import React, {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {getRFPByProjectId} from '../../purchaseOrders/Core/_requests'

const PurchaseRequestList = () => {
  const {id} = useParams()
  const [loading, setLoading] = useState(true)
  const [prList, setPrList] = useState([])

  useEffect(() => {
    const fetchPrList = async () => {
      const response = await getRFPByProjectId(id)
      setPrList(response)
      setLoading(false)
    }

    if (id !== undefined) fetchPrList()
  }, [id])

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {})
    return formattedDate
  }

  return (
    <div className='card'>
      <div className='card-header'>
        <div className='card-title'>
          <h2>Purchase Requests</h2>
        </div>
      </div>

      <div className='card-body py-3'>
        {loading ? (
          <div className='d-flex align-items-center justify-center'>
            <div className='loader'></div>
          </div>
        ) : (
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4 table-row-bordered'>
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-100px rounded-start'>ID</th>
                  <th className='min-w-200px'>Employee</th>
                  <th className='min-w-200px'>Vendor</th>
                  <th className='min-w-200px'>Created On</th>
                </tr>
              </thead>
              <tbody>
                {prList.map((pr: any) => (
                  <tr key={pr?._id}>
                    <td className='ps-4'>
                      <Link to={`/procurement/purchaserequest/${pr?._id}`}>{pr?.id}</Link>
                    </td>
                    <td>
                      <Link
                        to={`/procurement/purchaserequest/${pr?._id}`}
                        className='ps-4 text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {pr?.employee?.firstName}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/procurement/purchaserequest/${pr?._id}`}
                        className='ps-4 text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {pr?.vendor?.displayName}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/procurement/purchaserequest/${pr?._id}`}
                        className='ps-4 text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {formatCreatedAt(pr?.createdAt)}
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  )
}

export default PurchaseRequestList
