import React, {useEffect, useRef, useState} from 'react'
import {KTIcon, KTSVG} from '../../../../../_metronic/helpers'
// import {linkAgentToClient, searchAgent} from '../core/_requests'
import {debounce} from 'lodash'
import {useAuth} from '../../../../modules/auth'
import {linkUserToCustomer, searchUseragent} from '../../Core/_requests'

function AgentSearch({customerId, handleTrigger}) {
  const [users, setUsers] = useState<any[]>([])
  const modalRef = useRef<HTMLDivElement | null>(null)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const PF: any = 'https://qf-assets.s3.me-central-1.amazonaws.com/'
  const {currentUser} = useAuth()

  
  useEffect(() => {
    const handleSearch = async () => {
      try {
        if (searchTerm !== '') {
          const response = await searchUseragent(searchTerm, currentUser?.organization)
          setUsers(response)
        } else {
          setUsers([])
        }
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
  
    const debouncedSearch = debounce(handleSearch, 500)
    
    debouncedSearch()

    return () => debouncedSearch.cancel()
  }, [searchTerm, currentUser?.organization])

  const handleClickList = (e: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
      setUsers([])
      setSearchTerm('')
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickList)
    return () => {
      document.removeEventListener('mousedown', handleClickList)
    }
  }, [])

  const onAddAgent = async (agentId, index) => {
    console.log(agentId)
    const ids = {
      customerId,
      agentId,
    }

    const res = await linkUserToCustomer(ids)
    const tuser = [...users]
    tuser[index] = res
    setUsers(tuser)
    handleTrigger()
  }

  return (
    <div className='modal fade' tabIndex={-1} id='kt_modal_4'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>Assign User</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>

          <div className='modal-body'>
            <div className='mb-10'>
              <label className='form-label'>Search User</label>
              <input
                type='text'
                className='form-control'
                placeholder='Search Name'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />

              <div ref={modalRef} className='serach'>
                <div className='search-list'>
                  <div className='line'></div>

                  <div className='card card-xl-stretch mb-xl-8'>
                    {/* begin::Body */}
                    {users?.map((user, index) => (
                      <div className='card-body pt-3' key={index}>
                        {/* begin::Item */}
                        <div className='d-flex align-items-sm-center mb-7'>
                          {/* begin::Symbol */}
                          <div className='symbol symbol-60px symbol-2by3 me-4'>
                            <div
                              className='symbol-label'
                              style={{backgroundImage: `url(${PF + user.profilePic})`}}
                            ></div>
                          </div>
                          <div className='d-flex flex-row-fluid flex-wrap align-items-center'>
                            <div className='flex-grow-1 me-2'>
                              <a href='#' className='text-gray-800 fw-bold text-hover-primary fs-6'>
                                {user?.userName}
                              </a>
                            </div>

                            <div
                              className='card-toolbar'
                              onClick={() => onAddAgent(user._id, index)}
                            >
                              <button
                                className={`${
                                  !user.customer.includes(customerId)
                                    ? 'btn-light-primary'
                                    : 'btn-light-danger'
                                } btn btn-sm `}
                              >
                                {/* <KTIcon iconName='plus' className='fs-2' /> */}
                                {user?.customer?.includes(customerId) ? (
                                  <>
                                    <KTIcon iconName='minus' className='fs-2 me-2' />
                                    Remove
                                  </>
                                ) : (
                                  <>
                                    <KTIcon iconName='plus' className='fs-2 me-2' />
                                    Assign
                                  </>
                                )}
                                {/* {!user.client.includes(customerId) ? 'Assign' : 'Remove'} */}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {/* <img
        className='postProfilePic'
        src={PF+user.profilePic}
        alt={PF + 'person/noAvatar.png'}
      />
    <Link  className="search-link" to={`/activity/${user._id}`} onClick={() => setSearchTerm("")}>{user.userName}</Link>
    <button onClick={()=>onAddAgent(user._id,index)} className= {!user.client.includes(customerId) ? "btn btn-primary": "btn btn-danger"}> 
    {
        !user.client.includes(customerId) ? "Add": "Remove"
      }
     </button>
    <div className='line'></div> */}
            </div>
          </div>

          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-light'
              data-bs-dismiss='modal'
              id='closeModalButtondel'
            >
              Close
            </button>
            <button
              type='button'
              className='btn btn-light'
              data-bs-dismiss='modal'
              id='closeModalButtondel'
            >
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AgentSearch
