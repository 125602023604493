import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  accounts: [],
  loading: true,
  fetch: true,
}

const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    setCRMAccounts: (state, action) => {
      state.accounts = action.payload
      state.loading = false
      state.fetch = false
    },
    setCRMAccountsLoading: (state, action) => {
      state.loading = action.payload
    },
    setCRMAccountsFetch: (state, action) => {
      state.fetch = action.payload
    },
  },
})

export const {setCRMAccounts, setCRMAccountsLoading, setCRMAccountsFetch} = accountsSlice.actions
export default accountsSlice.reducer
