import React, {useEffect, useRef, useState} from 'react'
import Select from 'react-select'
import 'react-datepicker/dist/react-datepicker.css'
import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/themes/material_blue.css'
import 'flatpickr/dist/flatpickr.min.css'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd'
import {useAuth} from '../../../modules/auth/core/Auth'
import {KTIcon, KTSVG} from '../../../../_metronic/helpers'
import {useNavigate} from 'react-router-dom'
import {
  addDeliveryMethod,
  getCustomer,
  getCustomerByStatusForAgent,
  getDeliveryMethods,
  getFilteredStocks,
  getItemsByType,
  getQuotationsByCustomerId,
  getSalesOrderLastId,
  getStocksForSales,
  postSalesOrder,
} from '../Core/_requests'
import {uploaddocsinqf} from '../../Inventory/Core/_requests'
import {getEmployeeByStatus} from '../../hrm/core/_requests'
import {getNotes, getTerms} from '../../account_settings/core/_request'
import {useSelector, useDispatch} from 'react-redux'
import {setSalesStock} from '../../../redux/inventory/products/stockSlice'
import {setServices} from '../../../redux/inventory/products/servicesSlice'
import {RootState} from '../../../redux/store'
import {setGoodsData} from '../../../redux/inventory/products/goodsSlice'
import {setRentalsData} from '../../../redux/inventory/products/rentalsSlice'

function CreateInvoice() {
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const salesStock = useSelector((state: RootState) => state.inventory.stock.salesStock)
  const servicesList = useSelector((state: RootState) => state.inventory.services.services)
  const goodsList = useSelector((state: RootState) => state.inventory.goods.goodsData)
  const rentalsList = useSelector((state: RootState) => state.inventory.rentals.rentalsData)
  const [categoryType, setCategoryType] = useState('')
  const [loading, setLoading] = useState(false)
  const [isLoading, setisLoading] = useState(false)
  const [filename, setfilename] = useState('')
  const [excelFile, setExcelFile] = useState(null)
  const fileInputRef = useRef(null)
  const [permissions, setPermissions] = useState<any>({})
  const [customerList, setCustomerList] = useState<any>([])
  const [employeeList, setEmployeeList] = useState<any>([])
  const [productList, setProductList] = useState<any>([])
  const [selectedBillFrom, setselectedBillFrom] = useState(null)
  const [selectedBillTo, setselectedBillTo] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [salesOrderNumber, setSalesOrderNumber] = useState('')
  const [selectedOption, setSelectedOption] = useState('autoGenerate') // Default selected option
  const [deliveryMethodOption, setDeliveryMethodOption] = useState([])
  const [name, setName] = useState('')
  const [prefix, setPrefix] = useState('')
  const [nextNumber, setNextNumber] = useState('')
  const [customID, setCustomID] = useState('')
  const [selectYearAndMonth, setSelectYearAndMonth] = useState(new Date())
  const [isEditingNotes, setIsEditingNotes] = useState(false)
  const [isEditingTerms, setIsEditingTerms] = useState(false)
  const [termsTextareaHeight, setTermsTextareaHeight] = useState('6rem')
  const [notesTextareaHeight, setNotesTextareaHeight] = useState('6rem')
  const [customerName, setCustomerName] = useState(null)
  const [quotationList, setQuotationList] = useState<any>([])
  const [selectedQuotationId, setSelectedQuotationId] = useState(null)
  const [selectedQuotationLabel, setSelectedQuotationLabel] = useState(null)
  const [selectedCustomerCurrency, setSelectedCustomerCurrency] = useState('')
  const [selectFromInventory, setSelectFromInventory] = useState(true)
  const [contactPersonList, setContactPersonList] = useState<any>([])

  const [newItem, setNewItem] = useState<any>([
    {
      productName: '',
      quantity: 0,
      price: 0,
      unit: '',
      discount: 0,
      amount: 0,
    },
  ])

  const termsOption = [
    {label: 'Net 15', value: 'net15'},
    {label: 'Net 30', value: 'net30'},
    {label: 'Net 45', value: 'net45'},
    {label: 'Net 60', value: 'net60'},
    {label: 'Due on Receipt', value: 'receipt'},
    {label: 'Due on End of Month', value: 'endofMonth'},
    {label: 'Due on End of Next Month', value: 'endOfNextMonth'},
    {label: 'Custom', value: 'custom'},
  ]

  const [salesOrder, setSalesOrder] = useState({
    salesOrderDate: new Date(),
    id: '',
    employee: '',
    customer: '',
    contactPerson: '',
    reference: '',
    expectedShipmentDate: new Date(),
    paymentTerms: '',
    deliveryMethod: '',
    subtotal: 0,
    tax: 0,
    total: 0,
    notes: ' ',
    termsNCondition: ' ',
    document: '',
    docAttached: '',
  })
  const [items, setItems] = useState([
    {
      itemId: '',
      price: '',
      quantity: 0,
      discount: 0,
      amount: '',
      selectedOption: {
        value: '',
        label: '',
        inWarehouseQuantity: 0,
      },
      type: 'product',
      productName: '',
    },
  ])

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const getQuotationByCustomer = async (customerId) => {
    try {
      const response = await getQuotationsByCustomerId(customerId)
      console.log(response)
      setQuotationList(response)
    } catch (error) {
      console.error('Error fetching quotations:', error)
    }
  }

  // add a fixed label as none and value as null
  const quotationOptions = quotationList?.quotations
    ?.filter((quotation) => quotation.status === 'pending')
    ?.map((quotation) => ({
      value: quotation._id,
      label: quotation.id,
    }))
    ?.concat({value: '', label: 'None'})

  const handleDragEnd = (result) => {
    if (!result.destination) return // dropped outside the list

    if (items.length > 1) {
      const itemsCopy = [...items]
      const [reorderedItem] = itemsCopy.splice(result.source.index, 1)
      itemsCopy.splice(result.destination.index, 0, reorderedItem)

      // Update your state with the reordered items
      setItems(itemsCopy)
    } else if (newItem.length > 1) {
      const itemsCopy = [...newItem]
      const [reorderedItem] = itemsCopy.splice(result.source.index, 1)
      itemsCopy.splice(result.destination.index, 0, reorderedItem)

      // Update your state with the reordered items
      setNewItem(itemsCopy)
    }
  }

  const handleQuotationChange = (selectedOption) => {
    if (!selectedOption) {
      return
    }
    if (selectedOption.value === null) {
      setItems([
        {
          itemId: '',
          price: '',
          quantity: 0,
          discount: 0,
          amount: '',
          selectedOption: {
            value: '',
            label: '',
            inWarehouseQuantity: 0,
          },
          type: 'product',
          productName: '',
        },
      ])
      return
    }
    setSelectedQuotationId(selectedOption.value)
    setSelectedQuotationLabel(selectedOption.label)
    setNewItem([])

    // Find the selected quotation and set its items to the state
    const selectedQuotation = quotationList?.quotations.find(
      (quotation) => quotation._id === selectedOption.value
    )

    if (selectedQuotation) {
      let amount = 0
      const selectedItems = selectedQuotation.items.map((item) => {
        amount += Number(item.price) * Number(item.quantity)

        return {
          productName: item.productName,
          quantity: item.quantity,
          itemId: item.itemId,
          itemsId: item.itemsId,
          price: item.price,
          type: item.type,
          discount: 0,
          amount: Number(item.price) * Number(item.quantity),
          selectedOption: {
            value: item.itemId || item.itemsId,
            label: item.productName,
            inWarehouseQuantity: item.quantity,
            type: item.type,
          },
        }
      })
      setItems(selectedItems)
      setSelectFromInventory(true)
      setSalesOrder((prevInvoice) => ({
        ...prevInvoice,
        subtotal: amount,
      }))
    }
  }

  useEffect(() => {
    getQuotationByCustomer(customerName?.value)
    setSelectedQuotationId(null)
    setSelectedQuotationLabel(null)
    // setSelectedQuotationItems([])
  }, [customerName])

  const fetchDeliveryMethods = async () => {
    const res = await getDeliveryMethods(currentUser?.organization)
    const data = res.map((item) => ({value: item, label: item}))
    setDeliveryMethodOption(data)
  }

  const handleDeliveryMethodSubmit = () => {
    const data = {
      deliveryMethod: name,
    }
    const res = addDeliveryMethod(currentUser?.organization, data)
    if (res) {
      setName('')
      setDeliveryMethodOption([...deliveryMethodOption, {value: name, label: name}])
      const closeModalButton1 = document.getElementById('closeModalButton1')
      closeModalButton1.click()
    }
  }

  useEffect(() => {
    if (salesOrder.termsNCondition === '') {
      setTermsTextareaHeight('3rem')
    }
  }, [salesOrder.termsNCondition])

  useEffect(() => {
    if (salesOrder.notes === '') {
      setNotesTextareaHeight('6rem')
    }
  }, [salesOrder.notes])

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    setfilename(event.target.files[0].name)
    setExcelFile(file)
  }

  const handleUpload = async () => {
    if (!excelFile) {
      console.error('No file selected')
      return
    }

    const timestamp = Date.now()

    const formData = new FormData()
    formData.append('file', excelFile)
    formData.append('name', salesOrder.reference)
    formData.append('filename', timestamp + filename)
    try {
      setisLoading(true)
      const response = await uploaddocsinqf(formData)

      if (response === 'success') {
        console.log('File uploaded successfully')
        alert('File uploaded successfully')
        setSalesOrder((prev) => {
          return {
            ...prev,
            docAttached: timestamp + filename,
          }
        })
        // Optionally, update UI to indicate successful upload
      } else {
        alert('failed to upload please try again')
        // Optionally, update UI to indicate failure
      }
    } catch (error) {
      console.error('Error:', error)
      setisLoading(false)
      // Opti
    }
  }

  useEffect(() => {
    const fetchSalesOrders = async () => {
      try {
        const QuotesNotes = await getNotes(currentUser?.organization, 'Sales Orders')
        const QuotesTerms = await getTerms(currentUser?.organization, 'Sales Orders')

        setSalesOrder({
          ...salesOrder,
          notes: QuotesNotes[0]?.notes || '',
          termsNCondition: QuotesTerms?.terms || '',
        })
      } catch (error) {
        console.error('Error fetching delivery notes:', error)
      }
    }

    fetchSalesOrders()
  }, [])

  const handleEditClick = (e, field) => {
    e.preventDefault()
    if (field === 'notes') {
      setIsEditingNotes(!isEditingNotes)
      setIsEditingTerms(false) // Make sure only one is in edit mode at a time
    } else if (field === 'termsNCondition') {
      setIsEditingTerms(!isEditingTerms)
      setIsEditingNotes(false) // Make sure only one is in edit mode at a time
    }
  }

  const getCustomerList = async () => {
    try {
      const res = await getCustomer('', currentUser?.organization)
      console.log(res)
      setCustomerList(
        res.map((r) => ({
          value: r?._id,
          label: r?.displayName,
          currency: r?.currency,
          contactPersons: r?.contactPersons,
        }))
      )
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  const getEmployeeList = async () => {
    try {
      const queryString = 'search=&agent=&filter_status=true'
      console.log(queryString)
      const res = await getEmployeeByStatus(queryString, currentUser?.organization)
      console.log(res)
      setEmployeeList(
        res.map((r) => ({
          value: r._id,
          label: r.firstName, // Corrected the typo here from "labe" to "label"
        }))
      )
      const defaultAgent = res?.find(
        (option) => option?.firstName + ' ' + option?.lastName === currentUser?.fullName
      )
      if (defaultAgent) {
        setselectedBillFrom({
          value: defaultAgent?._id,
          label: currentUser?.fullName,
        })
        setSalesOrder((prev) => ({
          ...prev,
          employee: defaultAgent?._id,
        }))
      }
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  const getProductlist = async () => {
    try {
      let salesStockData = []
      if (salesStock.length === 0) {
        const res = await getStocksForSales(currentUser?.organization)
        console.log(res)
        salesStockData = res.map((r) => ({
          value: r._id,
          label: r.productName,
          price: r.price,
          inWarehouseQuantity: r.inWarehouseQuantity,
          type: 'product',
        }))
        dispatch(setSalesStock(res))
      } else {
        salesStockData = salesStock.map((r) => ({
          value: r._id,
          label: r.productName,
          price: r.price,
          inWarehouseQuantity: r.inWarehouseQuantity,
          type: 'product',
        }))
      }

      let serviceData = []
      if (servicesList.length === 0) {
        const resp = await getItemsByType('service', currentUser?.organization)
        serviceData = resp.map((r) => ({
          value: r._id,
          label: r.name,
          price: r.price,
          inWarehouseQuantity: 1,
          type: 'item',
        }))
        dispatch(setServices(resp))
      } else {
        serviceData = servicesList.map((r) => ({
          value: r._id,
          label: r.name,
          price: r.price,
          inWarehouseQuantity: 1,
          type: 'item',
        }))
      }
      setProductList((prev) => {
        const updatedData = [...salesStockData, ...serviceData]
        return updatedData
      })
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  useEffect(() => {
    if (customID) {
      setSalesOrderNumber(customID)
    }
  }, [customID])

  const getSalesOrderID = async () => {
    try {
      const res = await getSalesOrderLastId(currentUser?.organization)
      setNextNumber(res.lastId + 1)
      const lastPrefix = res?.prefix.slice(0, res?.prefix?.trim()?.length - 7)
      setPrefix(lastPrefix)
      setSalesOrderNumber(`${res?.prefix}${res?.lastId + 1}`)
      console.log(res)
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  const getCustomerForAgent = async () => {
    try {
      const res = await getCustomerByStatusForAgent('', currentUser?._id)
      console.log(res)
      setCustomerList(
        res.map((r) => ({
          value: r?._id,
          label: r?.displayName,
          currency: r?.currency,
          contactPersons: r?.contactPersons,
        }))
      )
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  useEffect(() => {
    if (permissions?.salesorder?.admin === false) {
      getCustomerForAgent()
    } else if (
      permissions?.salesorder?.admin === true ||
      currentUser?.profileType === 'superadmin'
    ) {
      getCustomerList()
    }
  }, [permissions])

  useEffect(() => {
    getEmployeeList()
    getProductlist()
    getSalesOrderID()
    fetchDeliveryMethods()
  }, [])

  const handleChange = (e) => {
    e.preventDefault()
    const {name, value} = e.target
    if (name === 'notes' && value.trim() === '') {
      setSalesOrder((prev) => ({
        ...prev,
        notes: '• ' + value,
      }))
    } else {
      setSalesOrder((prevInvoice) => ({
        ...prevInvoice,
        [name]: value,
        // You can add other properties if needed
      }))
    }
    if (name === 'termsNCondition') {
      setTermsTextareaHeight(`${e.target.scrollHeight}px`)
    } else if (name === 'notes') {
      setNotesTextareaHeight(`${e.target.scrollHeight}px`)
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      setSalesOrder((prev) => ({
        ...prev,
        notes: prev.notes + '\n• ',
      }))

      setNotesTextareaHeight(`${event.target.scrollHeight}px`)
    }
  }

  const handleChangerows = (index, e) => {
    const {name, value} = e.target
    if (name === 'quantity' && items[index].type !== 'item') {
      if (value > items[index].selectedOption.inWarehouseQuantity) {
        return
      }
    }
    setItems((prevItems) => {
      const updatedItems = [...prevItems]
      updatedItems[index] = {
        ...updatedItems[index],
        [name]: value,
      }
      return updatedItems
    })
    setItems((prevItems) => {
      const updatedItems = [...prevItems]
      const data: any = (
        Number(prevItems[index].price) * Number(prevItems[index].quantity) -
        Number(prevItems[index].discount)
      ).toFixed(2)

      updatedItems[index] = {
        ...updatedItems[index],
        amount: data,
      }
      return updatedItems
    })
  }

  const handleNewItemChange = (index, e) => {
    const {name, value} = e.target
    setNewItem((prevItems) => {
      const updatedItems = [...prevItems]
      updatedItems[index] = {
        ...updatedItems[index],
        [name]: value,
      }
      const total =
        updatedItems[index].price * updatedItems[index].quantity - updatedItems[index].discount
      updatedItems[index] = {
        ...updatedItems[index],
        amount: total,
      }
      return updatedItems
    })
  }

  const handleSelectChangeInRows = (index, selectedOption) => {
    console.log(selectedOption)
    setItems((prevItems) => {
      const updatedItems = [...prevItems]
      updatedItems[index] = {
        ...updatedItems[index],
        itemId: selectedOption.value,
        selectedOption,
        price: selectedOption.price,
        amount: selectedOption.price,
        quantity: 1,
        type: selectedOption.type,
        productName: selectedOption.label,
      }
      return updatedItems
    })
  }

  useEffect(() => {
    let sum = items.reduce((acc, item) => {
      return acc + parseFloat(item.amount || '0')
    }, 0)
    sum += newItem.reduce((acc, item) => {
      return acc + parseFloat(item.amount || '0')
    }, 0)
    setSalesOrder((prevInvoice) => ({
      ...prevInvoice,
      subtotal: sum,
      // You can add other properties if needed
    }))
  }, [items, newItem])

  useEffect(() => {
    setSalesOrder((prevInvoice) => {
      const subtotal = prevInvoice.subtotal || 0
      const tax = prevInvoice.tax || 0

      const total = (subtotal * (1 + tax / 100)).toFixed(2)

      return {
        ...prevInvoice,
        total: parseFloat(total),
        // You can add other properties if needed
      }
    })
  }, [items, salesOrder.tax, newItem])

  const handleBillToChange = (selectedOption) => {
    setselectedBillTo(selectedOption)
    setCustomerName(selectedOption)
    setSalesOrder((prevInvoice) => ({
      ...prevInvoice,
      customer: selectedOption.value,
    }))

    const selectedCustomer = customerList.find(
      (customer) => customer.value === selectedOption.value
    )
    if (selectedCustomer) {
      setContactPersonList(
        selectedCustomer?.contactPersons?.map((person) => ({
          value: person._id,
          label: person.firstName,
        }))
      )
      setSalesOrder((prevInvoice) => ({
        ...prevInvoice,
        contactPerson: selectedCustomer.contactPersons[0]?._id,
      }))
      setSelectedCustomerCurrency(selectedCustomer.currency)
    }
  }

  const handleBillFromChange = (selectedOption) => {
    console.log(selectedOption)
    setselectedBillFrom(selectedOption)
    setSalesOrder((prevInvoice) => ({
      ...prevInvoice,
      employee: selectedOption.value,
    }))
  }

  const handleDateChange = (selectedDate) => {
    setSalesOrder((prevInvoice) => ({
      ...prevInvoice,
      salesOrderDate: new Date(selectedDate),
    }))
  }

  const handleExpectedDateChange = (selectedDate) => {
    setSalesOrder((prevInvoice) => ({
      ...prevInvoice,
      expectedShipmentDate: selectedDate[0], // Flatpickr returns an array of selected dates
    }))
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleSaveChanges = () => {
    let newSalesOrderNumber = ''
    if (selectedOption === 'autoGenerate') {
      setPrefix(prefix)
      console.log(prefix, nextNumber)
      setNextNumber(nextNumber)
      setCustomID('')
      const year = String(selectYearAndMonth.getFullYear() - 2000).padStart(2, '0')
      const month = String(selectYearAndMonth.getMonth() + 1).padStart(2, '0')
      const dNM = `${year}-${month}`
      newSalesOrderNumber = `${prefix}-${dNM}-${nextNumber}`
    } else if (selectedOption === 'manualInput') {
      setCustomID(salesOrderNumber)
      setPrefix('')
      setNextNumber('')
      newSalesOrderNumber = salesOrderNumber
    }
    setSalesOrderNumber(newSalesOrderNumber)
    setShowModal(false)
  }

  const handleSubmit = async () => {
    if (!excelFile) {
      setSalesOrder({...salesOrder, reference: ''})
    }
    if (salesOrder.customer === '') {
      alert('Please select a customer')
      return
    } else if (salesOrder.employee === '') {
      alert('Please select a sales person')
      return
    } else if (selectFromInventory && items[0].itemId === '') {
      alert('Please select an item')
      return
    } else if (!selectFromInventory && newItem[0].productName === '') {
      alert('Please enter an item')
      return
    }
    setLoading(true)
    const itemsList = items.map((item) => ({
      itemId: item.type === 'product' ? item.itemId : null,
      itemsId: item.type === 'item' ? item.itemId : null,
      productName: item.productName,
      price: item.price,
      quantity: item.quantity,
      discount: item.discount,
      amount: item.amount,
      type: item.type,
    }))

    const year = String(selectYearAndMonth.getFullYear() - 2000).padStart(2, '0')
    const month = String(selectYearAndMonth.getMonth() + 1).padStart(2, '0')
    const dNM = `${prefix}-${year}-${month}-`

    const data = {
      ...salesOrder,
      items: selectFromInventory ? itemsList : newItem,
      prefix: dNM,
      customID: customID,
      id: nextNumber,
      quotation: selectedQuotationId
        ? selectedQuotationId !== ''
          ? selectedQuotationId
          : null
        : null,
      approval: permissions?.salesorder?.enable ? 'accepted' : 'pending',
      company: currentUser?.company,
      organization: currentUser?.organization,
      agent: currentUser?._id,
    }
    console.log(data)
    try {
      await postSalesOrder(data, '0').then((res) => {
        console.log('Submitted', res)
        navigate(`/sales/salesorders`)
      })
    } catch (error) {
      console.error('Error creating invoice:', error)
    }
  }

  const handleButtonClick = () => {
    fileInputRef.current.click()
  }

  const additem = () => {
    setItems([
      ...items,
      {
        itemId: '',
        price: '',
        quantity: 0,
        discount: 0,
        amount: '',
        selectedOption: {
          value: '',
          label: '',
          inWarehouseQuantity: 0,
        },
        type: 'product',
        productName: '',
      },
    ])
  }

  const removeitem = (index) => {
    if (items.length === 1) {
      setItems([
        {
          itemId: '',
          price: '',
          quantity: 0,
          discount: 0,
          amount: '',
          selectedOption: {
            value: '',
            label: '',
            inWarehouseQuantity: 0,
          },
          type: 'product',
          productName: '',
        },
      ])
      return
    }
    const temp = [...items]
    temp.splice(index, 1)
    setItems(temp)
  }

  const handleCategoryChange = async (e) => {
    setCategoryType(e.target.value)
    if (e.target.value === 'goods' || e.target.value === 'rentals') {
      if (e.target.value === 'goods') {
        if (goodsList.length === 0) {
          const res = await getFilteredStocks(currentUser?.organization, e.target.value)
          const data = res.map((r) => ({
            value: r._id,
            label: r.productName,
            price: r.price,
            inWarehouseQuantity: r.inWarehouseQuantity,
            type: 'product',
          }))
          setProductList(data)
          dispatch(setGoodsData(res))
        } else {
          const data = goodsList.map((r) => ({
            value: r._id,
            label: r.productName,
            price: r.price,
            inWarehouseQuantity: r.inWarehouseQuantity,
            type: 'product',
          }))
          setProductList(data)
        }
      } else {
        if (rentalsList.length === 0) {
          const res = await getFilteredStocks(currentUser?.organization, e.target.value)
          const data = res.map((r) => ({
            value: r._id,
            label: r.productName,
            price: r.price,
            inWarehouseQuantity: r.inWarehouseQuantity,
            type: 'product',
          }))
          setProductList(data)
          dispatch(setRentalsData(res))
        } else {
          const data = rentalsList.map((r) => ({
            value: r._id,
            label: r.productName,
            price: r.price,
            inWarehouseQuantity: r.inWarehouseQuantity,
            type: 'product',
          }))
          setProductList(data)
        }
      }
    } else {
      if (servicesList.length === 0) {
        const res = await getItemsByType('service', currentUser?.organization)
        const data = res.map((r) => ({
          value: r._id,
          label: r.name,
          price: r.price,
          inWarehouseQuantity: 1,
          type: 'item',
        }))
        setProductList(data)
        dispatch(setServices(res))
      } else {
        const data = servicesList.map((r) => ({
          value: r._id,
          label: r.name,
          price: r.price,
          inWarehouseQuantity: 1,
          type: 'item',
        }))
        setProductList(data)
      }
    }
  }

  return (
    <div id='kt_app_content' className='app-content flex-column-fluid'>
      {/* begin::Content container */}
      <div id='kt_app_content_container' className='app-container container-xxl'>
        {/* begin::Layout */}
        <div className='d-flex flex-column flex-lg-row'>
          {/* begin::Content */}
          <div className='flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10'>
            {/* begin::Card */}
            <div className='d-flex justify-end mb-2'>
              <button
                className='btn btn-light-primary me-3'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
              >
                <KTIcon iconName='filter' className='fs-2' />
                Filter
              </button>
              <div
                className='menu menu-sub menu-sub-dropdown w-400px w-md-400px p-5'
                data-kt-menu='true'
                style={{zIndex: 1000000}}
              >
                <div className='px-7 py-2'>
                  <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
                </div>
                <div className='separator border-gray-200'></div>
                <div className='px-7 py-5' data-kt-user-table-filter='form'>
                  <div className='mb-5 d-flex flex-row align-content-center justify-content-center'>
                    <label className='form-label fs-6 fw-bold mt-2 w-100px me-5'>Category:</label>
                    <select
                      className='form-select form-select-solid fw-bolder w-75'
                      onChange={handleCategoryChange}
                      value={categoryType}
                    >
                      <option value=''>Select an option</option>
                      {currentUser?.inventoryFeatures?.goods && (
                        <option value='goods'>Goods</option>
                      )}
                      {currentUser?.inventoryFeatures?.rentals && (
                        <option value='rentals'>Equipments</option>
                      )}
                      {currentUser?.inventoryFeatures?.service && (
                        <option value='service'>Services</option>
                      )}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className='card'>
              {/* begin::Card body */}
              <div className='card-body p-12'>
                {/* begin::Form */}
                <form action='' id='kt_quote_form'>
                  {/* begin::Wrapper */}
                  <div className='d-flex flex-column align-items-start flex-xxl-row'>
                    {/* begin::Input group */}
                    <div
                      className='d-flex align-items-center flex-equal fw-row me-4 order-2'
                      data-bs-toggle='tooltip'
                      data-bs-trigger='hover'
                      title='Specify date'
                    >
                      {/* begin::Date */}
                      <div className='fs-6 fw-bold text-gray-700 text-nowrap'>Date:</div>
                      {/* Date */}
                      {/* begin::Input */}
                      <div className='position-relative d-flex align-items-center w-150px'>
                        {/* begin::Datepicker */}
                        <Flatpickr
                          value={salesOrder.salesOrderDate}
                          onChange={handleDateChange}
                          className='form-control form-control-solid'
                          placeholder='Pick date'
                          options={{
                            dateFormat: 'd-m-Y',
                          }}
                        />
                        {/* Datepicker */}
                      </div>
                      {/* Input */}
                    </div>
                    {/* Input group */}
                    {/* begin::Input group */}
                    <div
                      className='d-flex flex-center flex-equal fw-row text-nowrap order-1 order-xxl-2 me-4'
                      data-bs-toggle='tooltip'
                      data-bs-trigger='hover'
                      title='Enter Sales Order number'
                    >
                      <span className='fs-2x fw-bold text-gray-800'>Sales Order #</span>
                      <input
                        type='text'
                        className='form-control form-control-flush fw-bold text-muted fs-3 w-200px'
                        value={salesOrderNumber}
                        onChange={(e) => setSalesOrderNumber(e.target.value)}
                        placeholder='Enter Sales Order #'
                        readOnly
                      />
                      <button
                        className='btn btn-light'
                        onClick={(e) => {
                          e.preventDefault()
                          setShowModal(true)
                        }}
                      >
                        <KTIcon iconName='gear' />
                      </button>
                      {showModal && (
                        <div
                          className='modal'
                          tabIndex={-1}
                          role='dialog'
                          style={{display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)'}}
                        >
                          <div className='modal-dialog modal-dialog-centered' role='document'>
                            <div className='modal-content'>
                              <div className='modal-header'>
                                <h5 className='modal-title'>Customize Sales Order</h5>
                                <button
                                  type='button'
                                  className='btn-close'
                                  onClick={handleCloseModal}
                                ></button>
                              </div>
                              <div className='modal-body'>
                                {/* Your modal body content goes here */}
                                <p>How would you like to manage sales order numbers?</p>
                                <div className='form-check'>
                                  <input
                                    className='form-check-input'
                                    type='radio'
                                    name='salesOrderOption'
                                    id='autoGenerate'
                                    value='autoGenerate'
                                    checked={selectedOption === 'autoGenerate'}
                                    onChange={() => setSelectedOption('autoGenerate')}
                                  />
                                  <label className='form-check-label' htmlFor='autoGenerate'>
                                    Continue auto-generating sales order numbers
                                  </label>
                                </div>
                                <div className='form-check'>
                                  <input
                                    className='form-check-input'
                                    type='radio'
                                    name='salesOrderOption'
                                    id='manualInput'
                                    value='manualInput'
                                    checked={selectedOption === 'manualInput'}
                                    onChange={() => setSelectedOption('manualInput')}
                                  />
                                  <label className='form-check-label' htmlFor='manualInput'>
                                    Enter sales order numbers manually
                                  </label>
                                </div>

                                {/* Additional input fields for manual input */}
                                {selectedOption === 'autoGenerate' && (
                                  <div className='mt-3'>
                                    <div className='mb-3'>
                                      <label className='form-label'>Prefix:</label>
                                      <input
                                        type='text'
                                        className='form-control'
                                        value={prefix}
                                        onChange={(e) => setPrefix(e.target.value)}
                                        placeholder='Enter Prefix'
                                      />
                                    </div>
                                    <div className='mb-3'>
                                      <label className='form-label'>Month & Year:</label>
                                      <br />
                                      <DatePicker
                                        selected={selectYearAndMonth}
                                        onChange={(date) => setSelectYearAndMonth(date)}
                                        dateFormat='yyyy/MM'
                                        showMonthYearPicker
                                        className='form-control'
                                      />
                                    </div>
                                    <div className='mb-3'>
                                      <label className='form-label'>Next Number:</label>
                                      <input
                                        type='number'
                                        className='form-control'
                                        value={nextNumber}
                                        onChange={(e) => setNextNumber(e.target.value)}
                                        placeholder='Enter Next Number'
                                      />
                                    </div>
                                  </div>
                                )}
                                {selectedOption === 'manualInput' && (
                                  <div className='mt-3'>
                                    <div className='mb-3'>
                                      <label className='form-label'>SALES ORDER NO:</label>
                                      <input
                                        type='text'
                                        className='form-control'
                                        value={salesOrderNumber}
                                        onChange={(e) => setSalesOrderNumber(e.target.value)}
                                        placeholder='Enter Sales Order Number'
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className='modal-footer'>
                                <button
                                  type='button'
                                  className='btn btn-secondary'
                                  onClick={handleCloseModal}
                                >
                                  Close
                                </button>
                                <button
                                  type='button'
                                  className='btn btn-primary'
                                  onClick={handleSaveChanges}
                                >
                                  Save changes
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* End of modal content */}
                    </div>

                    {/* Input group */}
                    {/* begin::Input group */}
                    <div
                      className='d-flex align-items-center justify-content-end flex-equal order-3 fw-row'
                      data-bs-toggle='tooltip'
                      data-bs-trigger='hover'
                      title='Specify invoice due date'
                    ></div>
                    {/* Input group */}
                  </div>
                  {/* Top */}
                  {/* begin::Separator */}
                  <div className='separator separator-dashed my-10'></div>
                  {/* Separator */}
                  {/* begin::Wrapper */}
                  <div className='mb-0'>
                    {/* begin::Row */}
                    <div className='row gx-10 mb-5'>
                      {/* begin::Col */}
                      <div className='col-lg-6'>
                        <label className='form-label required fs-6 fw-bold text-gray-700 mb-3'>
                          Sales Person
                        </label>
                        {/* begin::Input group */}
                        <Select
                          name='employee'
                          aria-label='Select a Employee'
                          placeholder='Select Employee'
                          options={employeeList}
                          defaultValue={{value: currentUser?._id, label: currentUser?.fullName}}
                          isSearchable={true}
                          className='form-control form-control-solid'
                          onChange={handleBillFromChange}
                          value={selectedBillFrom}
                        />
                        {/* Input group */}
                      </div>
                      {/* Col */}
                      {/* begin::Col */}
                      <div className='col-lg-6'>
                        <label className='form-label required fs-6 fw-bold text-gray-700 mb-3'>
                          Customer Name
                        </label>
                        {/* begin::Input group */}
                        <Select
                          name='customer'
                          aria-label='Select a Customer'
                          placeholder='Select a Customer'
                          options={customerList}
                          isSearchable={true}
                          className='form-control form-control-solid'
                          onChange={handleBillToChange}
                          value={selectedBillTo}
                        />
                      </div>
                      {/* Col */}
                    </div>
                    {/* Row */}
                    <div className='row gx-10 mb-5'>
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                          Reference (Optional)
                        </label>
                        <input
                          type='text'
                          name='reference'
                          value={salesOrder.reference}
                          onChange={handleChange}
                          className='form-control form-control-solid'
                          placeholder='Enter Reference'
                        />
                      </div>
                      <div className='col-lg-3'>
                        <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                          Quotation# (Optional)
                        </label>
                        <Select
                          name='quoteId'
                          aria-label='Select a Quotation'
                          placeholder='Select a Quotation'
                          options={quotationOptions}
                          isSearchable={true}
                          className='form-control form-control-solid'
                          onChange={handleQuotationChange}
                          value={
                            selectedQuotationLabel
                              ? {
                                  value: selectedQuotationId,
                                  label: selectedQuotationLabel,
                                }
                              : null
                          }
                        />
                      </div>
                      <div className='col-lg-3'>
                        <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                          Contact Person
                        </label>
                        <Select
                          name='category'
                          aria-label='Select a Category'
                          placeholder='Select a Person'
                          options={contactPersonList}
                          isSearchable={true}
                          className='form-control form-control-solid'
                          onChange={(selectedOption) => {
                            setSalesOrder((prevInvoice) => ({
                              ...prevInvoice,
                              contactPerson: selectedOption.value,
                            }))
                          }}
                          value={contactPersonList.find(
                            (option) => option.value === salesOrder.contactPerson
                          )}
                        />
                      </div>
                    </div>
                    <div className='row gx-10 mb-5'>
                      {/* begin::Col */}
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                          Expected Shipment Date
                        </label>
                        <Flatpickr
                          value={salesOrder.expectedShipmentDate}
                          onChange={handleExpectedDateChange}
                          className='form-control form-control-solid'
                          placeholder='Pick date'
                          options={{
                            dateFormat: 'd-m-Y',
                          }}
                        />

                        {/* Input group */}
                      </div>
                      {/* Col */}
                      {/* begin::Col */}
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                          Payment Terms
                        </label>
                        {/* begin::Input group */}
                        <Select
                          name='terms'
                          aria-label='Select a Terms'
                          placeholder='Select a Terms'
                          options={termsOption}
                          // className='form-control'
                          onChange={(selectedOption) => {
                            setSalesOrder((prevInvoice) => ({
                              ...prevInvoice,
                              paymentTerms: selectedOption.value,
                            }))
                          }}
                        />
                      </div>
                      <div className='mb-10'>
                        <label className='form-label fw-bold fs-6 text-gray-700'>
                          Delivery Method
                        </label>
                        <Select
                          name='deliveryMethod'
                          aria-label='Select a Delivery Method'
                          placeholder='Select a Delivery Method'
                          options={deliveryMethodOption}
                          // defaultValue={defaultDeliveryMethod}
                          isSearchable={true}
                          className=''
                          onChange={(selectedOption) => {
                            setSalesOrder((prevInvoice) => ({
                              ...prevInvoice,
                              deliveryMethod: selectedOption.value,
                            }))
                          }}
                        />
                        <div className='card-toolbar mt-5'>
                          <div
                            className='btn btn-sm btn-light-warning mb-5'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_1'
                          >
                            <i className='ki-duotone ki-plus fs-2'></i>Create New Delivery Method
                          </div>
                        </div>
                        <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
                          <div className='modal-dialog'>
                            <div className='modal-content'>
                              <div className='modal-header'>
                                <h5 className='modal-title'>Add New Delivery Method</h5>
                                <div
                                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                                  data-bs-dismiss='modal'
                                  aria-label='Close'
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/arrows/arr061.svg'
                                    className='svg-icon svg-icon-2x'
                                  />
                                </div>
                              </div>

                              <div className='modal-body'>
                                <form>
                                  <div className='row rowwidthclient'>
                                    <div className='form-group mb-3 col-md-12'>
                                      <label>Delivery Method Name</label>
                                      <input
                                        type='text'
                                        name='name'
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        className='form-control'
                                      />
                                    </div>
                                  </div>
                                </form>
                              </div>

                              <div className='modal-footer'>
                                <button
                                  type='button'
                                  className='btn btn-light'
                                  data-bs-dismiss='modal'
                                  id='closeModalButton1'
                                >
                                  Close
                                </button>
                                <button
                                  type='button'
                                  className='btn btn-primary'
                                  onClick={handleDeliveryMethodSubmit}
                                >
                                  Save changes
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='d-flex align-items-center justify-content-start gap-5'>
                      <button
                        type='button'
                        className={`btn btn-light-success ${selectFromInventory && 'active'}`}
                        onClick={() => {
                          setSelectFromInventory(true)
                          setNewItem([])
                          setItems([
                            {
                              itemId: '',
                              price: '',
                              quantity: 0,
                              discount: 0,
                              amount: '',
                              selectedOption: {
                                value: '',
                                label: '',
                                inWarehouseQuantity: 0,
                              },
                              type: 'product',
                              productName: '',
                            },
                          ])
                        }}
                      >
                        Select from Inventory
                      </button>
                      <button
                        type='button'
                        className={`btn btn-light-warning ${!selectFromInventory && 'active'}`}
                        onClick={() => {
                          setSelectFromInventory(false)
                          setItems([])
                          setNewItem([
                            {
                              productName: '',
                              price: '',
                              quantity: 0,
                              discount: 0,
                              amount: '',
                            },
                          ])
                        }}
                      >
                        Add New Item
                      </button>
                    </div>

                    <div className='fs-6 fw-bold text-gray-700 mb-3 mt-5'>Item Table</div>
                    <div className='table-responsive mb-10'>
                      <table
                        className='table g-5 gs-0 mb-0 fw-bold text-gray-700'
                        data-kt-element='items'
                      >
                        <thead>
                          <tr className='border-bottom fs-7 fw-bold text-gray-700 text-uppercase'>
                            <th className='min-w-100px w-500px'>Item</th>
                            {!selectFromInventory && <th className='min-w-30px w-30px'>Unit</th>}
                            <th className='min-w-150px w-250px'>Quantity</th>
                            <th className='min-w-50px w-250px'>Rate</th>
                            <th className='min-w-70px w-150px'>VAT</th>
                            <th className='min-w-70px w-250px'>Amount</th>
                            <th className='min-w-70px w-200px'>Action</th>
                          </tr>
                        </thead>

                        <DragDropContext onDragEnd={handleDragEnd}>
                          <Droppable droppableId='droppable'>
                            {(provided) => (
                              <tbody ref={provided.innerRef} {...provided.droppableProps}>
                                {selectFromInventory &&
                                  items.map((item, index) => (
                                    <Draggable
                                      key={index}
                                      draggableId={`item-${index}`}
                                      index={index}
                                    >
                                      {(provider) => (
                                        <tr
                                          className='border-bottom border-bottom-dashed'
                                          data-kt-element='item'
                                          key={index}
                                          ref={provider.innerRef}
                                          {...provider.draggableProps}
                                        >
                                          <td className='absolute w-[300px] pe-7'>
                                            {/* begin::Input group */}
                                            <Select
                                              name='itemId'
                                              aria-label='Select a Product'
                                              placeholder='Select a Product'
                                              options={productList}
                                              isSearchable={true}
                                              // disabling when null
                                              // isDisabled={selectedQuotationId !== ""  && selectedQuotationId !== null}
                                              className='form-control form-control-solid'
                                              onChange={(e) => handleSelectChangeInRows(index, e)}
                                              value={item.selectedOption}
                                              required
                                            />
                                          </td>
                                          <td className='pe-7'>
                                            {/* begin::Input group */}

                                            <input
                                              type='number'
                                              className='form-control form-control-solid'
                                              name='quantity'
                                              placeholder='0.00'
                                              max={
                                                item?.type === 'product' &&
                                                item?.selectedOption?.inWarehouseQuantity
                                              }
                                              min={0}
                                              value={item.quantity}
                                              onChange={(e) => handleChangerows(index, e)}
                                            />
                                          </td>

                                          <td className='pe-7'>
                                            {/* begin::Input group */}

                                            <input
                                              type='number'
                                              className='form-control form-control-solid'
                                              name='price'
                                              placeholder='0.00'
                                              value={item.price}
                                              onChange={(e) => handleChangerows(index, e)}
                                            />
                                          </td>

                                          <td className='pe-7'>
                                            {/* begin::Input group */}

                                            <input
                                              type='number'
                                              className='form-control form-control-solid'
                                              name='discount'
                                              placeholder='0.00'
                                              value={item.discount}
                                              onChange={(e) => handleChangerows(index, e)}
                                            />
                                          </td>

                                          <td>
                                            <input
                                              type='number'
                                              className='form-control form-control-solid text-end'
                                              name='amount'
                                              placeholder='0.00'
                                              value={item.amount}
                                              disabled
                                              // onChange={(e) => handleChangerows(index, e)}
                                            />
                                          </td>
                                          <td className='flex justify-evenly md:flex-nowrap w-fit'>
                                            <div
                                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3'
                                              onClick={additem}
                                            >
                                              <KTIcon iconName='plus-square' className='fs-3' />
                                            </div>
                                            <div
                                              className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm '
                                              onClick={() => removeitem(index)}
                                            >
                                              <KTIcon iconName='trash' className='fs-3' />
                                            </div>
                                            <div
                                              {...provider.dragHandleProps}
                                              className='fs-3 fw-bold text-gray-400 ms-3 d-flex justify-center align-items-center'
                                            >
                                              .{index + 1}.
                                            </div>
                                          </td>
                                        </tr>
                                      )}
                                    </Draggable>
                                  ))}
                                {provided.placeholder}
                                {!selectFromInventory &&
                                  newItem.map((item, index) => (
                                    <Draggable
                                      key={index}
                                      draggableId={`item-${index}`}
                                      index={index}
                                    >
                                      {(provider) => (
                                        <tr
                                          className='border-bottom border-bottom-dashed'
                                          data-kt-element='item'
                                          key={index}
                                          ref={provider.innerRef}
                                          {...provider.draggableProps}
                                        >
                                          <td className='absolute w-[300px] pe-7'>
                                            {/* begin::Input group */}
                                            <input
                                              type='text'
                                              className='form-control form-control-solid'
                                              name='productName'
                                              placeholder='Enter Product Name'
                                              value={item.productName}
                                              onChange={(e) => handleNewItemChange(index, e)}
                                            />
                                          </td>
                                          <td className='pe-7'>
                                            <select
                                              name='unit'
                                              id=''
                                              className='ms-2 form-control form-control-solid'
                                              value={item.unit}
                                              onChange={(e) => handleNewItemChange(index, e)}
                                            >
                                              <option value=''>Select Unit</option>
                                              {currentUser?.units?.map((unit) => (
                                                <option value={unit.name}>{unit.name}</option>
                                              ))}
                                            </select>
                                          </td>
                                          <td className='pe-7'>
                                            {/* begin::Input group */}

                                            <input
                                              type='number'
                                              className='form-control form-control-solid'
                                              name='quantity'
                                              placeholder='0.00'
                                              max={
                                                item?.type === 'product' &&
                                                item?.selectedOption?.inWarehouseQuantity
                                              }
                                              min={0}
                                              value={item.quantity}
                                              onChange={(e) => handleNewItemChange(index, e)}
                                            />
                                          </td>

                                          <td className='pe-7'>
                                            {/* begin::Input group */}

                                            <input
                                              type='number'
                                              className='form-control form-control-solid'
                                              name='price'
                                              placeholder='0.00'
                                              value={item.price}
                                              onChange={(e) => handleNewItemChange(index, e)}
                                            />
                                          </td>

                                          <td className='pe-7'>
                                            {/* begin::Input group */}

                                            <input
                                              type='number'
                                              className='form-control form-control-solid'
                                              name='discount'
                                              placeholder='0.00'
                                              value={item.discount}
                                              onChange={(e) => handleNewItemChange(index, e)}
                                            />
                                          </td>

                                          <td>
                                            <input
                                              type='number'
                                              className='form-control form-control-solid text-end'
                                              name='amount'
                                              placeholder='0.00'
                                              value={item.amount}
                                              disabled
                                              // onChange={(e) => handleChangerows(index, e)}
                                            />
                                          </td>
                                          <td>
                                            <div
                                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3'
                                              onClick={() => {
                                                setNewItem([
                                                  ...newItem,
                                                  {
                                                    productName: '',
                                                    quantity: 0,
                                                    price: 0,
                                                    discount: 0,
                                                    amount: 0,
                                                  },
                                                ])
                                              }}
                                            >
                                              <KTIcon iconName='plus-square' className='fs-3' />
                                            </div>
                                            <div
                                              className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm '
                                              onClick={() => {
                                                if (newItem.length === 1) {
                                                  setNewItem([
                                                    {
                                                      productName: '',
                                                      quantity: 0,
                                                      price: 0,
                                                      discount: 0,
                                                      amount: 0,
                                                    },
                                                  ])
                                                  return
                                                }
                                                const temp = [...newItem]
                                                temp.splice(index, 1)
                                                setNewItem(temp)
                                              }}
                                            >
                                              <KTIcon iconName='trash' className='fs-3' />
                                            </div>
                                            <div
                                              {...provider.dragHandleProps}
                                              className='fs-3 fw-bold text-gray-400 ms-3 d-flex justify-center align-items-center'
                                            >
                                              .{index + 1}.
                                            </div>
                                          </td>
                                        </tr>
                                      )}
                                    </Draggable>
                                  ))}
                                {/* button for adding new Item */}
                              </tbody>
                            )}
                          </Droppable>
                        </DragDropContext>
                        {/* Table body */}
                        {/* begin::Table foot */}
                        <tfoot>
                          <tr className='border-top border-top-dashed align-top fs-6 fw-bold text-gray-700'>
                            <th className='text-primary'></th>
                            <th className='text-primary'></th>
                            <th className='text-primary'></th>
                            <th className='border-bottom border-bottom-dashed ps-0'>
                              <div className='d-flex flex-column align-items-center'>
                                <div className='fs-5'>Subtotal</div>
                                {/* <button
                                  className='btn btn-link '
                                  data-bs-toggle='tooltip'
                                  data-bs-trigger='hover'
                                  title='Tax in percentage'
                                >
                                  VAT %
                                </button> */}
                              </div>
                            </th>
                            <th className='border-bottom border-bottom-dashed text-center'>
                              <span data-kt-element='sub-total' className='text-end'>
                                {salesOrder.subtotal.toLocaleString('en-US', {
                                  minimumFractionDigits: 2,
                                }) || 0}{' '}
                                {selectedCustomerCurrency}
                              </span>
                              {/* <input
                                type='number'
                                className='form-control form-control-solid text-center '
                                name='tax'
                                placeholder='0.00'
                                value={salesOrder.tax}
                                onChange={handleChange}
                              /> */}
                            </th>
                          </tr>
                          <tr className='align-top fw-bold text-gray-700'>
                            <th className='min-w-100px w-100px'></th>
                            <th className='min-w-150px w-150px'></th>
                            <th className='min-w-150px w-150px'></th>
                            <th className='fs-4 ps-0 min-w-50px w-50px text-center'>Total</th>
                            <th className=' fs-4 text-nowrap min-w-70px w-70px text-center'>
                              <span data-kt-element='grand-total text-end'>
                                {salesOrder.total.toLocaleString('en-US', {
                                  minimumFractionDigits: 2,
                                }) || 0}{' '}
                                {selectedCustomerCurrency}
                              </span>
                            </th>
                          </tr>
                        </tfoot>
                        {/* Table foot */}
                      </table>
                    </div>
                    {/* Table */}
                    {/* begin::Item template */}

                    <table className='table d-none' data-kt-element='empty-template'>
                      <tr data-kt-element='empty'>
                        <th colSpan={5} className='text-muted text-center py-10'>
                          No items
                        </th>
                      </tr>
                    </table>
                    {/* Item template */}
                    {/* begin::Notes */}
                    <div className='mb-0 position-relative'>
                      <label className='form-label fs-6 fw-bold text-gray-700'>Notes</label>
                      <textarea
                        name='notes'
                        className={`form-control form-control-solid mb-0 h-10 ${
                          isEditingNotes ? 'border border-dark-subtle' : ''
                        }`}
                        placeholder='Enter customer notes'
                        value={salesOrder.notes}
                        onChange={handleChange}
                        readOnly={!isEditingNotes}
                        onKeyDown={handleKeyPress}
                        style={{
                          minHeight: '6rem',
                          height: notesTextareaHeight,
                          resize: 'none',
                        }}
                      />
                      <button onClick={(e) => handleEditClick(e, 'notes')}>
                        <KTIcon
                          iconName='pencil'
                          className='position-absolute text-black bg-gray-100 right-10 top-[3rem] fs-3'
                        />
                      </button>
                    </div>
                    <div className='mb-10 position-relative'>
                      <label className='form-label fw-bold fs-6 text-gray-700'>
                        Terms and Conditions
                      </label>
                      <textarea
                        name='termsNCondition'
                        className={`  form-control form-control-solid mb-0 h-10 ${
                          isEditingTerms ? 'border border-dark-subtle' : ''
                        }`}
                        placeholder='Enter terms and conditions'
                        value={salesOrder.termsNCondition}
                        onChange={handleChange}
                        readOnly={!isEditingTerms}
                        style={{
                          minHeight: '6rem',
                          height: termsTextareaHeight,
                          resize: 'none',
                        }}
                      />
                      <button onClick={(e) => handleEditClick(e, 'termsNCondition')}>
                        <KTIcon
                          iconName='pencil'
                          className='absolute text-black bg-gray-100 right-10 fs-3 top-[3rem]'
                        />
                      </button>
                    </div>
                    <div className='row gx-10 mb-5'>
                      <div
                        className='card-toolbar me-5'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_11'
                      >
                        <a className='btn btn-sm btn-light-primary'>
                          <KTIcon iconName='plus' className='fs-2' />
                          Upload File
                        </a>
                      </div>
                    </div>
                    <button
                      className='btn btn-primary w-30'
                      type='button'
                      id='kt_quote_submit_button'
                      disabled={loading}
                      onClick={handleSubmit}
                    >
                      <i className='ki-duotone ki-triangle fs-3'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>
                      Create Sales Order
                    </button>
                    {/* Notes */}
                  </div>
                  {/* Wrapper */}
                </form>
                {/* Form */}
              </div>
              {/* Card body */}
            </div>
            {/* Card */}
          </div>
          {/* Content */}
          {/* begin::Sidebar */}

          {/* <!--::Sidebar--> */}
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_modal_11'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Add File</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>

            <div className='modal-body'>
              <form>
                <div className='row rowwidthclient'>
                  <div className='card-toolbar'>
                    <a className='btn btn-sm btn-light-danger' onClick={handleButtonClick}>
                      <KTIcon iconName='plus' className='fs-2' />
                      Upload File
                    </a>
                  </div>

                  <input
                    type='file'
                    accept='.pdf, .jpg, .jpeg, .png, .gif, .doc, .docx, .xlsx, .xls'
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    style={{display: 'none'}}
                  />
                  {excelFile && <p>Selected file: {excelFile.name}</p>}
                </div>
              </form>
            </div>

            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                id='closeModalButton'
              >
                Close
              </button>
              <button
                type='button'
                className='btn btn-primary'
                disabled={isLoading}
                onClick={handleUpload}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <!--::Content container--> */}
    </div>
  )
}

export default CreateInvoice
