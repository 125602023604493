import {createSlice} from '@reduxjs/toolkit'

interface DateState {
  startDate: string
  endDate: string
}

interface BillsState {
  bills: any[]
  loading: boolean
  totalPages: number
  dateState: DateState
  error: string | null
  fetch: boolean
}

const initialState: BillsState = {
  bills: [],
  totalPages: 1,
  dateState: {
    startDate: null,
    endDate: null,
  },
  loading: false,
  error: null,
  fetch: true,
}

const billsSlice = createSlice({
  name: 'bills',
  initialState,
  reducers: {
    setBills(state, action) {
      state.bills = action.payload.bills
      state.totalPages = action.payload.totalPages
      state.loading = false
      state.error = null
      state.fetch = false
    },
    setLoading(state) {
      state.loading = true
      state.error = null
    },
    setError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    setBillsDataFetch(state) {
      state.fetch = true
    },
  },
})

export const {setBills, setLoading, setError, setBillsDataFetch} = billsSlice.actions
export default billsSlice.reducer
