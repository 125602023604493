import axios, {AxiosResponse} from 'axios'
const API_URL = process.env.REACT_APP_THEME_API_URL
const EXCEL_IMPORT_URL = process.env.REACT_APP_EXCEL_IMPORT_URL
const SALES_URL = `${API_URL}/sales`
const EMPLOYEE_URL = `${API_URL}/employee`
const QUOTES_URL = `${API_URL}/sales/quote`
const INVOICE_URL = `${API_URL}/sales/invoice`
const PAYMENT_RECEIVED = `${API_URL}/sales/paymentReceived`
const ORDER_ID = `${API_URL}/lastInsertedId`
const SALES_ORDER = `${SALES_URL}/salesOrder`
const ORDER_MANAGEMENT_URL = `${API_URL}/sales/orderManagement`
const DELIVERY_NOTE_URL = `${SALES_URL}/deliveryNote`
const ACCOUNTS_URL = `${API_URL}/accounts`
const ITEMS_URL = `${API_URL}/items`
const SEARCH_USERS_URL = `${API_URL}/agent/search`
const GET_NOTI = `${API_URL}/taskNotification/`

const REPORTS_URL = `${API_URL}/reports`
const SALES_PERFORMANCE_URL = `${REPORTS_URL}/salesperformance`
const NOTES_URL = `${API_URL}/notes`
const TERMS_URL = `${API_URL}/terms`

export async function bulkUploadCustomer(data, company, organization) {
  try {
    const response = await axios.post(
      `${EXCEL_IMPORT_URL}/bulk-upload/customer/${company}/${organization}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  } catch (error) {
    console.error('Bulk Upload Customer error:', error)
    throw error
  }
}

export const getCustomer = async (query: string, orgid: string) => {
  try {
    const response = await axios.get(`${SALES_URL}/customer/getcustomer/${orgid}?${query}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getCustomersByAgentId = async (query: string, agentid: string) => {
  try {
    const response = await axios.get(`${SALES_URL}/customer/getcustomer/agent/${agentid}?${query}`)
    console.log(response.data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getCustomerByStatus = async (query: string, orgid) => {
  try {
    const response = await axios.get(`${SALES_URL}/customer/getcustomer/${orgid}?${query}`)
    console.log(response.data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}
export const getNotes = async (name: string) => {
  try {
    const response = await axios.get(`${NOTES_URL}/${name}`)
    console.log(response.data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}
export const getTerms = async (name: string) => {
  try {
    const response = await axios.get(`${TERMS_URL}/${name}`)
    console.log(response.data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getCustomerByStatusForAgent = async (query: string, agentid) => {
  try {
    const response = await axios.get(`${SALES_URL}/customer/getcustomerbyagent/${agentid}?${query}`)
    console.log(response.data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

console.log('sales', `${SALES_URL}/customer/`)
export const getCustomerByID = async (id) => {
  try {
    const response = await axios.get(`${SALES_URL}/customer/getcustomerbyid/${id}`)
    console.log(response.data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}
export const deactivateCustomer = async (id) => {
  try {
    const response = await axios.put(`${SALES_URL}/customer/deactivate/${id}`)
    console.log(response.data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}
export const getSalesOrder = async (orgid: string) => {
  try {
    const response = await axios.get(`${SALES_ORDER}/${orgid}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}
export const getFilteredSalesOrder = async (
  query: string,
  orgid: string,
  page = 1,
  limit = 25,
  date = null
) => {
  try {
    const response = await axios.get(
      `${SALES_ORDER}/filter/${orgid}?${query}&page=${page}&limit=${limit}&startDate=${date?.startDate}&endDate=${date?.endDate}`
    )
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}
export const getSalesOrderByAgentId = async (agentid: string) => {
  try {
    const response = await axios.get(`${SALES_ORDER}/agent/${agentid}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}
export const getFilteredSalesOrderByAgentId = async (
  query: string,
  agentid: string,
  page = 1,
  limit = 25,
  date = null
) => {
  try {
    const response = await axios.get(
      `${SALES_ORDER}/agent/filter/${agentid}?${query}&page=${page}&limit=${limit}&startDate=${date?.startDate}&endDate=${date?.endDate}`
    )
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}
export const getSalesOrderDetailById = async (id) => {
  try {
    const response = await axios.get(`${SALES_ORDER}/salesorderbyid/${id}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}
export const postSalesOrder = async (data, orderId: string) => {
  try {
    const response = await axios.post(`${SALES_ORDER}/${orderId}`, data)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const editSalesOrder = async (data, id: string) => {
  try {
    const response = await axios.put(`${SALES_ORDER}/${id}`, data)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const revisedSalesOrder = async (data, id: string) => {
  try {
    const response = await axios.put(`${SALES_ORDER}/revised/${id}`, data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const postCustomer = async (data) => {
  try {
    const response = await axios.post(`${SALES_URL}/customer`, data)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const postCustomerFromLead = async (data) => {
  try {
    const response = await axios.post(`${SALES_URL}/customer/create/leads`, data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const postCustomerFromContact = async (data) => {
  try {
    const response = await axios.post(`${SALES_URL}/customer/create/crmcontact`, data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const editCustomer = async (data, id) => {
  try {
    const response = await axios.put(`${SALES_URL}/customer/${id}`, data)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const postCustomerById = async (data, id) => {
  try {
    const response = await axios.put(`${SALES_URL}/customer/${id}`, data)
    console.log(response.data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getCustomerDetails = async (id) => {
  try {
    const response = await axios.get(`${SALES_URL}/customer/getcustomerbyid/${id}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getEmployee = async (orgid: string) => {
  try {
    const response = await axios.get(`${EMPLOYEE_URL}/${orgid}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getStocks = async (orgid) => {
  try {
    const response = await axios.get(`${API_URL}/products/stocked/${orgid}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getStocksForSales = async (orgid) => {
  try {
    const response = await axios.get(`${API_URL}/products/goodsnrentals/${orgid}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getFilteredStocks = async (orgid, type) => {
  try {
    const response = await axios.get(`${API_URL}/products/filtered/${orgid}/${type}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export async function getQuoteLength(): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${QUOTES_URL}/quotelength`)
    return response.data + 1
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function getInvoiceLength(): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${INVOICE_URL}/invoicelength`)
    return response.data + 1
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}
export async function getSalesOrderLastId(orgid: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${ORDER_ID}/${orgid}/SalesOrder`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}
export async function getInvoiceLastId(orgid: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${ORDER_ID}/${orgid}/Invoice`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function getQuoteLastId(orgid: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${ORDER_ID}/${orgid}/Quotes`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function getPaymentReceivedLastId(orgid: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${ORDER_ID}/${orgid}/PaymentReceived`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}
export async function getDeliveryNoteLastId(orgid: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${ORDER_ID}/${orgid}/DeliveryNote`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function getPaymentReceived(orgid: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${PAYMENT_RECEIVED}/${orgid}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function getPaymentReceivedByAgentId(agentid: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${PAYMENT_RECEIVED}/agent/${agentid}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}
export const getFilteredPaymentReceived = async (
  query: string,
  orgid: string,
  page = 1,
  limit = 25,
  date = null
) => {
  try {
    const response = await axios.get(
      `${PAYMENT_RECEIVED}/filter/${orgid}?${query}&page=${page}&limit=${limit}&startDate=${date?.startDate}&endDate=${date?.endDate}`
    )
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}
export const getFilteredPaymentReceivedByAgentId = async (
  query: string,
  agentid: string,
  page = 1,
  limit = 25,
  date = null
) => {
  try {
    const response = await axios.get(
      `${PAYMENT_RECEIVED}/agent/filter/${agentid}?${query}&page=${page}&limit=${limit}&startDate=${date?.startDate}&endDate=${date?.endDate}`
    )
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export async function getPaymentReceivedDetailById(id): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${PAYMENT_RECEIVED}/getpaymentreceivedbyid/${id}`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function postPaymentReceived(data, orderId: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.post(`${PAYMENT_RECEIVED}/${orderId}`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Post Payment Received error:', error)
    throw error
  }
}

export async function editPaymentReceived(data, id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.put(`${PAYMENT_RECEIVED}/${id}`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Post Payment Received error:', error)
    throw error
  }
}

export async function revisedPaymentReceived(data, id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.put(`${PAYMENT_RECEIVED}/revised/${id}`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Post Payment Received error:', error)
    throw error
  }
}

export async function getPaymentReceivedLength(): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${PAYMENT_RECEIVED}/PaymentReceivedLength`
    )
    return response.data + 1
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export const postQuote = async (quote: any, orderId: string): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.post(`${QUOTES_URL}/${orderId}`, quote)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export const editQuote = async (data: any, quoteid: string): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.put(`${QUOTES_URL}/${quoteid}`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export const revisedQuote = async (data: any, quoteid: string): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.put(`${QUOTES_URL}/revised/${quoteid}`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export const postInvoice = async (invoice: any, orderId: string): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.post(`${INVOICE_URL}/${orderId}`, invoice)
    console.log('data', response.data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function getOrdersWithProject(orgid: string): Promise<Array<any>> {
  try {
    const response: AxiosResponse<any[]> = await axios.get(
      `${ORDER_MANAGEMENT_URL}/getorders/with-project/${orgid}`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export const editInvoice = async (invoice: any, invoiceid: string): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.put(`${INVOICE_URL}/${invoiceid}`, invoice)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export const revisedInvoice = async (invoice: any, invoiceid: string): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.put(
      `${INVOICE_URL}/revised/${invoiceid}`,
      invoice
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export const postInvoiceWithSalesOrder = async (invoice: any, orderId: string): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.post(
      `${INVOICE_URL}/salesorder/${orderId}`,
      invoice
    )
    console.log('data', response.data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

console.log('QUOTES_URL', QUOTES_URL)
export async function getQuotebyids(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${QUOTES_URL}/quotebyid/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function updateQuoteStatus(id: string, data: any): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.put(`${QUOTES_URL}/edit-status/${id}`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function getQuotebyQuoteid(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${QUOTES_URL}/quotebyquoteid/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function getInvoicebyids(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${INVOICE_URL}/invoicebyid/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function getInvoiceQrCodebyid(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${INVOICE_URL}/getqrcodeforinvoice/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function getGroupInvoiceById(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${INVOICE_URL}/groupinvoice/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function getInvoiceByIdWithGroupInfo(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${INVOICE_URL}/getInvoiceById/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function getQuotes(orgid): Promise<Array<any>> {
  try {
    const response: AxiosResponse<any[]> = await axios.get(`${QUOTES_URL}/${orgid}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function getFilteredQuotes(
  query: string,
  orgid,
  page = 1,
  limit = 25,
  date = null
): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${QUOTES_URL}/filter/${orgid}?${query}&page=${page}&limit=${limit}&startDate=${date?.startDate}&endDate=${date?.endDate}`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function getQuotesByAgentId(agentId): Promise<Array<any>> {
  try {
    const response: AxiosResponse<any[]> = await axios.get(`${QUOTES_URL}/agent/${agentId}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function getFilteredQuotesByAgentId(
  query: string,
  agentId,
  page = 1,
  limit = 25,
  date = null
): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${QUOTES_URL}/agent/filter/${agentId}?${query}&page=${page}&limit=${limit}&startDate=${date?.startDate}&endDate=${date?.endDate}`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function getInvoices(orgid: string): Promise<Array<any>> {
  try {
    const response: AxiosResponse<any[]> = await axios.get(`${INVOICE_URL}/${orgid}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function getInvoicesByAgentId(agentid: string): Promise<Array<any>> {
  try {
    const response: AxiosResponse<any[]> = await axios.get(`${INVOICE_URL}/agent/${agentid}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export const getFilteredInvoices = async (
  query: string,
  orgid: string,
  page = 1,
  limit = 25,
  date = null
) => {
  try {
    const response = await axios.get(
      `${INVOICE_URL}/filter/${orgid}?${query}&page=${page}&limit=${limit}&startDate=${date?.startDate}&endDate=${date?.endDate}`
    )
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}
export const getFilteredInvoicesByAgentId = async (
  query: string,
  agentid: string,
  page = 1,
  limit = 25,
  date = null
) => {
  try {
    const response = await axios.get(
      `${INVOICE_URL}/agent/filter/${agentid}?${query}&page=${page}&limit=${limit}&startDate=${date?.startDate}&endDate=${date?.endDate}`
    )
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export async function getOrders(orgid: string): Promise<Array<any>> {
  try {
    const response: AxiosResponse<any[]> = await axios.get(
      `${ORDER_MANAGEMENT_URL}/getorders/${orgid}`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function getOrdersByAgentId(agentid: string): Promise<Array<any>> {
  try {
    const response: AxiosResponse<any[]> = await axios.get(
      `${ORDER_MANAGEMENT_URL}/getorders/agent/${agentid}`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export const postorder = async (data: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.post(`${ORDER_MANAGEMENT_URL}`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export const editorder = async (id: string, data: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.put(`${ORDER_MANAGEMENT_URL}/${id}`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function getOrderById(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${ORDER_MANAGEMENT_URL}/orderdetails/${id}`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function getOrderDetailsById(companyid: string, orderId: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${ORDER_MANAGEMENT_URL}/${orderId}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function changeOrderStatus(past, present, orderId) {
  const body = {past, present, orderId}
  try {
    const response = await axios.put(`${ORDER_MANAGEMENT_URL}/changeorderstatus`, body)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Company error:', error)
    throw error
  }
}

export const getDeliveryNotes = async (orgid: string) => {
  try {
    const response = await axios.get(`${DELIVERY_NOTE_URL}/${orgid}`)
    console.log('Delivery notes fetched', response.data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getFilteredDeliveryNotes = async (
  query: string,
  orgid: string,
  page = 1,
  limit = 25,
  date = null
) => {
  try {
    const response = await axios.get(
      `${DELIVERY_NOTE_URL}/filter/${orgid}?${query}&page=${page}&limit=${limit}&startDate=${date?.startDate}&endDate=${date?.endDate}`
    )
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getDeliveryNotesByAgentId = async (agentid: string) => {
  try {
    const response = await axios.get(`${DELIVERY_NOTE_URL}/agent/${agentid}`)
    console.log('Delivery notes fetched', response.data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getFilteredDeliveryNotesByAgentId = async (
  query: string,
  agentid: string,
  page = 1,
  limit = 25,
  date = null
) => {
  try {
    const response = await axios.get(
      `${DELIVERY_NOTE_URL}/agent/filter/${agentid}?${query}&page=${page}&limit=${limit}&startDate=${date?.startDate}&endDate=${date?.endDate}`
    )
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const postDeliveryNotes = async (data: any, orderId: string): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.post(`${DELIVERY_NOTE_URL}/${orderId}`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Post delivery notes error:', error)
    throw error
  }
}

export const postPartialDeliveryNote = async (data: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.post(`${DELIVERY_NOTE_URL}/partial`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Post delivery notes error:', error)
    throw error
  }
}

export const editDeliveryNotes = async (data: any, id: string): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.put(`${DELIVERY_NOTE_URL}/${id}`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Post delivery notes error:', error)
    throw error
  }
}

export const revisedDeliveryNotes = async (data: any, id: string): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.put(`${DELIVERY_NOTE_URL}/revised/${id}`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Post delivery notes error:', error)
    throw error
  }
}

export const getDeliveryNoteLength = async () => {
  try {
    const response = await axios.get(`${DELIVERY_NOTE_URL}/deliveryNoteLength`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}
export const getDeliveryNotesDetailById = async (id) => {
  try {
    const response = await axios.get(`${DELIVERY_NOTE_URL}/deliveryNoteById/${id}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getDeliveryNotesDetailBySalesOrderId = async (id) => {
  try {
    const response = await axios.get(`${DELIVERY_NOTE_URL}/deliveryNoteBySalesOrder/${id}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getAllCustomer = async (orgid) => {
  try {
    const response = await axios.get(`${SALES_URL}/customer/getcustomer/${orgid}`)
    console.log('Customers fetched', response.data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export async function setQuoteStatusApprove(id: string) {
  try {
    const response = await axios.put(`${SALES_URL}/quote/statusapprove/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Account error:', error)
    throw error
  }
}

export async function setQuoteStatusReject(id: string) {
  try {
    const response = await axios.put(`${SALES_URL}/quote/statusreject/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Account error:', error)
    throw error
  }
}

export async function setInvoiceStatusApprove(id: string) {
  try {
    const response = await axios.put(`${SALES_URL}/invoice/statusapprove/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Account error:', error)
    throw error
  }
}

export async function setInvoiceStatusReject(id: string) {
  try {
    const response = await axios.put(`${SALES_URL}/invoice/statusreject/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Account error:', error)
    throw error
  }
}

export async function setSalesOrderStatusApprove(id: string) {
  try {
    const response = await axios.put(`${SALES_URL}/salesOrder/statusapprove/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Account error:', error)
    throw error
  }
}

export async function setSalesOrderStatusReject(id: string) {
  try {
    const response = await axios.put(`${SALES_URL}/salesOrder/statusreject/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Account error:', error)
    throw error
  }
}

export async function setDeliveryNoteStatusApprove(id: string) {
  try {
    const response = await axios.put(`${SALES_URL}/deliveryNote/statusapprove/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Account error:', error)
    throw error
  }
}

export async function setDeliveryNoteStatusReject(id: string) {
  try {
    const response = await axios.put(`${SALES_URL}/deliveryNote/statusreject/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Account error:', error)
    throw error
  }
}

export async function setPaymentReceivedStatusApprove(id: string) {
  try {
    const response = await axios.put(`${SALES_URL}/paymentReceived/statusapprove/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Account error:', error)
    throw error
  }
}

export async function setPaymentReceivedStatusReject(id: string) {
  try {
    const response = await axios.put(`${SALES_URL}/paymentReceived/statusreject/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Account error:', error)
    throw error
  }
}

export async function getAccountsNameForPaymentReceivedInvoice(orgid: string) {
  try {
    const response = await axios.get(
      `${ACCOUNTS_URL}/getaccounts/accountsforinvoicepaymentreceived/${orgid}`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Account error:', error)
    throw error
  }
}

export const getItemsByType = async (type: string, orgid: string) => {
  try {
    // const response = await axios.get(`${ITEMS_URL}/type/${orgid}/${type}`)
    const response = await axios.get(`${ITEMS_URL}/${orgid}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export async function searchUseragent(searchTerm: string, orgid: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${SEARCH_USERS_URL}/${orgid}?search=${searchTerm}`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('search agent error:', error)
    throw error
  }
}

export async function linkUserToCustomer(ids: any): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.put(`${SALES_URL}/customer/userassign`, ids)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('link agent error:', error)
    throw error
  }
}

export async function getUsersByOrgId(orgid: any): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${API_URL}/agent/agentsbyorgid/${orgid}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('link agent error:', error)
    throw error
  }
}

export async function getQuotationsByCustomerId(customerid: any): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${DELIVERY_NOTE_URL}/quotation/${customerid}`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('link agent error:', error)
    throw error
  }
}

export async function getQuotesByCustomerId(customerid: any): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${DELIVERY_NOTE_URL}/quote/${customerid}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('link agent error:', error)
    throw error
  }
}

export async function getSalesOrdersByCustomerId(customerid: any): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${INVOICE_URL}/salesorder/${customerid}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('link agent error:', error)
    throw error
  }
}

export async function getDeliveryNotesByCustomerId(customerid: any): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${INVOICE_URL}/deliverynote/${customerid}`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('link agent error:', error)
    throw error
  }
}

export async function updateValidationStatus(id: string, data: any): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.put(
      `${QUOTES_URL}/changevalidation/${id}`,
      data
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Quote update error:', error)
    throw error
  }
}

export async function invalidateInvoice(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.put(`${INVOICE_URL}/invalidate/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Quote update error:', error)
    throw error
  }
}

export async function invalidatePaymentReceived(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.put(`${PAYMENT_RECEIVED}/invalidate/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Quote update error:', error)
    throw error
  }
}

export async function updateSalesOrderValidationStatus(id: string, data: any): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.put(
      `${SALES_ORDER}/changevalidation/${id}`,
      data
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Quote update error:', error)
    throw error
  }
}

export async function updateDeliveryChallanValidationStatus(id: string, data: any): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.put(
      `${DELIVERY_NOTE_URL}/changevalidation/${id}`,
      data
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Quote update error:', error)
    throw error
  }
}

export async function getSalesOrdersForDeliveryNote(customerid: any): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${DELIVERY_NOTE_URL}/salesorder/${customerid}`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('link agent error:', error)
    throw error
  }
}

export async function getInvoiceByCustomerId(customerid: any): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${PAYMENT_RECEIVED}/invoice/${customerid}`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('link agent error:', error)
    throw error
  }
}

//get Sales Performance
export const getSalesPerformance = async (orgid: string) => {
  try {
    const response = await axios.get(`${SALES_PERFORMANCE_URL}/customerreports/${orgid}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

//send mail by ID INVOICE
export const sendInvoiceMailByID = async (id, data) => {
  try {
    const response = await axios.post(`${API_URL}/sendPDF/invoice/${id}`, data)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const sendGroupInvoiceMailByID = async (id, data) => {
  try {
    const response = await axios.post(`${API_URL}/sendPDF/group-invoice/${id}`, data)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getDeliveryMethods = async (orgid: string) => {
  try {
    const response = await axios.get(`${SALES_ORDER}/get-delivery-methods/${orgid}`)
    console.log('Response of sales performance', response.data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const addDeliveryMethod = async (orgid: string, data) => {
  try {
    const response = await axios.put(`${SALES_ORDER}/add-delivery-method/${orgid}`, data)
    console.log('Response of sales performance', response.data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

//send mail by ID Sales Order
export const sendSalesOrderMailByID = async (id, data) => {
  try {
    const response = await axios.post(`${API_URL}/sendPDF/salesorder/${id}`, data)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

//send mail by ID Quotation
export const sendQuotationMailByID = async (id, data) => {
  try {
    const response = await axios.post(`${API_URL}/sendPDF/quotation/${id}`, data)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

//send mail by ID Payment Received
export const sendPaymentReceivedMailByID = async (id, data) => {
  try {
    const response = await axios.post(`${API_URL}/sendPDF/paymentreceipt/${id}`, data)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

//send mail by ID Delivery Note
export const sendDeliveryNoteMailByID = async (id, data) => {
  try {
    const response = await axios.post(`${API_URL}/sendPDF/deliverynote/${id}`, data)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

//send mail by ID Customer Statement
export const sendCustomerStatementMailByID = async (id, data) => {
  try {
    const response = await axios.post(`${API_URL}/sendPDF/customerStatement/${id}`, data)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getTransactionsByCustomerId = async (id, date, orgid) => {
  try {
    const response = await axios.get(
      `${ACCOUNTS_URL}/statementofaccounts/customertransaction/${id}/${orgid}?date=${date}`
    )
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getStatementsByCustomerId = async (id, date, orgid) => {
  try {
    const response = await axios.get(
      `${ACCOUNTS_URL}/statementofaccounts/customer/${id}/${orgid}?date=${date}`
    )
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getmailsByCustomerId = async (id) => {
  try {
    const response = await axios.get(`${SALES_URL}/customer/mails/${id}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getnotification = async (id: string) => {
  try {
    const response: AxiosResponse<any> = await axios.get(`${GET_NOTI}${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export const getAlerts = async (id: string) => {
  try {
    const response: AxiosResponse<any> = await axios.get(`${GET_NOTI}alert/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}
