import React, {useEffect, useState} from 'react'
import Select from 'react-select'
import {getAllDepartment, postLeaveType} from '../core/_requests'
import {KTIcon, KTSVG} from '../../../../_metronic/helpers'
import {useAuth} from '../../../modules/auth'
import {useNavigate} from 'react-router-dom'
import {addLeaveTypeToOrg, getLeaveTypeByOrgId} from '../core/_requests'
import {getHRPDepartments} from '../../hrp/core/_requests'
import {useSelector, useDispatch} from 'react-redux'
import {setDepartments} from '../../../redux/hrp/hrpDepartmentsSlice'
import {RootState} from '../../../redux/store'

export const AddLeave = ({isHRP = false}) => {
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const hrpDepartmentFetch = useSelector((state: RootState) => state.hrp.departments.fetch)
  const hrpDepartmentData = useSelector((state: RootState) => state.hrp.departments.departments)
  const [name, setName] = useState('')
  const [loading, setLoading] = useState(false)
  const [leaveTypeList, setLeaveTypeList] = useState([])
  const [leaveData, setLeaveData] = useState({
    departmentId: [],
    leaveType: '',
    maxDuration: '',
    approvalRequired: false,
    description: '',
  })

  const [departmentList, setDepartmentList] = useState([])

  useEffect(() => {
    async function fetchDepartments() {
      try {
        if (isHRP) {
          if (hrpDepartmentFetch) {
            const departments = await getHRPDepartments(currentUser?.organization)
            setDepartmentList(
              departments.map((department) => ({
                value: department._id,
                label: department?.department?.name,
              }))
            )
            dispatch(setDepartments(departments))
          } else {
            const temp = hrpDepartmentData.map((department) => ({
              value: department._id,
              label: department?.department?.name,
            }))
            setDepartmentList(temp)
          }
        } else {
          const departments = await getAllDepartment(currentUser?.organization)
          setDepartmentList(
            departments.map((department) => ({
              value: department._id,
              label: department.name,
            }))
          )
        }
      } catch (error) {
        console.error('Error fetching departments:', error)
      }
    }

    fetchDepartments()
  }, [])

  const handleDepartmentChange = (selectedOption: any) => {
    setLeaveData((prevData) => ({
      ...prevData,
      departmentId: selectedOption.map((item: any) => item.value),
    }))
  }

  const handleChange = (e) => {
    const {name, value} = e.target
    setLeaveData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleCheckboxChange = (e) => {
    const {checked} = e.target
    setLeaveData((prevData) => ({
      ...prevData,
      approvalRequired: checked,
    }))
  }

  const fetchLeaveTypes = async () => {
    const res = await getLeaveTypeByOrgId(currentUser?.organization)
    const data = res.map((tag) => ({value: tag, label: tag}))
    setLeaveTypeList(data)
  }

  useEffect(() => {
    fetchLeaveTypes()
  }, [])

  const handleSubmitLeaveType = () => {
    setLoading(true)
    const data = {
      leaveType: name,
    }
    const res = addLeaveTypeToOrg(data, currentUser?.organization)
    if (res) {
      setName('')
      setLeaveTypeList([...leaveTypeList, {value: name, label: name}])
      const closeModalButton = document.getElementById('closeModalButton')
      closeModalButton.click()
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!leaveData.departmentId.length) {
      alert('Please select a department')
      return
    }
    if (!leaveData.leaveType) {
      alert('Please select a leave type')
      return
    }
    if (!leaveData.maxDuration || Number(leaveData.maxDuration) < 1) {
      alert('Please enter maximum duration')
      return
    }
    if (!leaveData.description) {
      alert('Please enter description')
      return
    }
    setLoading(true)
    try {
      const data = {
        ...leaveData,
        company: currentUser?.company,
        organization: currentUser?.organization,
      }
      const response = await postLeaveType(data)
      if (response) {
        navigate('/hrm/leaveManagement')
      }
      // Reset form after successful submission if needed
      // setLeaveData({
      //   departmentId: '',
      //   leaveType: '',
      //   maxDuration: '',
      //   approvalRequired: false,
      //   description: ''
      // });
    } catch (error) {
      console.error('Error submitting leave application:', error)
    }
  }

  return (
    <div className='container-lg'>
      <div className='row justify-content-center'>
        <div className='col-lg-6'>
          <div className='card card-custom card-stretch'>
            <div className='card-body'>
              <h2 className='text-center mb-4'>ADD NEW LEAVE</h2>
              <form onSubmit={handleSubmit}>
                {/* Form fields */}
                {/* Department selection */}
                <div className='mb-3'>
                  <label htmlFor='departmentId' className='form-label required'>
                    Department
                  </label>
                  {/* Add your department selection component */}
                  <Select
                    name='departmetId'
                    options={departmentList}
                    isMulti
                    onChange={handleDepartmentChange}
                  />
                </div>
                {/* Leave type input */}
                <div className='mb-3'>
                  <label htmlFor='leaveType' className='form-label required'>
                    Leave type
                  </label>
                  <Select
                    name='leaveType'
                    options={leaveTypeList}
                    onChange={(selectedOption) =>
                      setLeaveData((prevData) => ({
                        ...prevData,
                        leaveType: selectedOption.value,
                      }))
                    }
                  />
                  <div className='card-toolbar mt-3'>
                    <div
                      className='btn btn-sm btn-light-warning mb-10'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_modal_1'
                    >
                      <i className='ki-duotone ki-plus fs-2'></i>Create New Leave Type
                    </div>
                  </div>
                  <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
                    <div className='modal-dialog'>
                      <div className='modal-content'>
                        <div className='modal-header'>
                          <h5 className='modal-title'>Add New Leave Type</h5>
                          <div
                            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                          >
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr061.svg'
                              className='svg-icon svg-icon-2x'
                            />
                          </div>
                        </div>

                        <div className='modal-body'>
                          <form>
                            <div className='row rowwidthclient'>
                              <div className='form-group mb-3 col-md-12'>
                                <label>Leave Type</label>
                                <input
                                  type='text'
                                  name='name'
                                  value={name}
                                  onChange={(e) => setName(e.target.value)}
                                  className='form-control'
                                />
                              </div>
                            </div>
                          </form>
                        </div>

                        <div className='modal-footer'>
                          <button
                            type='button'
                            className='btn btn-light'
                            data-bs-dismiss='modal'
                            id='closeModalButton'
                          >
                            Close
                          </button>
                          <button
                            type='button'
                            className='btn btn-primary'
                            onClick={handleSubmitLeaveType}
                          >
                            Save changes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Maximum leave duration input */}
                <div className='mb-3'>
                  <label htmlFor='maxDuration' className='form-label'>
                    Maximum Duration (in days)
                  </label>
                  <input
                    type='number'
                    className='form-control'
                    id='maxDuration'
                    name='maxDuration'
                    min='1'
                    max='365'
                    value={leaveData.maxDuration}
                    onChange={handleChange}
                  />
                </div>
                {/* Approval required checkbox */}
                <div className='mb-3'>
                  <label htmlFor='approvalRequired' className='form-label'>
                    Approval Required
                  </label>
                  <div className='form-switch'>
                    <input
                      type='checkbox'
                      id='approvalRequired'
                      className='form-check-input'
                      checked={leaveData.approvalRequired}
                      onChange={handleCheckboxChange}
                    />
                    <label className='form-check-label' htmlFor='approvalRequired'>
                      {leaveData.approvalRequired ? 'Yes' : 'No'}
                    </label>
                  </div>
                </div>
                {/* Description input */}
                <div className='mb-3'>
                  <label htmlFor='description' className='form-label required'>
                    Description
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='description'
                    name='description'
                    value={leaveData.description}
                    onChange={handleChange}
                  />
                </div>
                {/* Submit button */}
                <div className='text-center'>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    disabled={loading}
                    onSubmit={handleSubmit}
                  >
                    <span className='indicator-label'>
                      Submit <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddLeave
