import React from 'react'
import {Modal, Form, Button} from 'react-bootstrap'

const CRMAccountsModal = ({isOpen, onClose, onSubmit, formData, setFormData, isEdit}: any) => {
  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{isEdit ? 'Edit Account' : 'Add Account'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          <div className='mb-3'>
            <Form.Group controlId='name'>
              <Form.Label className='required'>Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='Name'
                value={formData.name}
                onChange={(e) => setFormData({...formData, name: e.target.value})}
              />
            </Form.Group>
          </div>
          <Button variant={isEdit ? 'warning' : 'primary'} type='submit'>
            {isEdit ? 'Update' : 'Add'} Account
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default CRMAccountsModal
