import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import '../print.css'
import {getRFQById} from '../../Core/_requests'
import {MdOutlineMailOutline} from 'react-icons/md'
import {GoGlobe} from 'react-icons/go'

function RFQMultiLang({companyLogo, color, setColor}) {
  const NUMBERS = new Map([
    [1e9, 'Billion'],
    [1e6, 'Million'],
    [1e3, 'Thousand'],
    [1e2, 'Hundred'],
    [90, 'Ninety'],
    [80, 'Eighty'],
    [70, 'Seventy'],
    [60, 'Sixty'],
    [50, 'Fifty'],
    [40, 'Forty'],
    [30, 'Thirty'],
    [20, 'Twenty'],
    [19, 'Nineteen'],
    [18, 'Eighteen'],
    [17, 'Seventeen'],
    [16, 'Sixteen'],
    [15, 'Fifteen'],
    [14, 'Fourteen'],
    [13, 'Thirteen'],
    [12, 'Twelve'],
    [11, 'Eleven'],
    [10, 'Ten'],
    [9, 'Nine'],
    [8, 'Eight'],
    [7, 'Seven'],
    [6, 'Six'],
    [5, 'Five'],
    [4, 'Four'],
    [3, 'Three'],
    [2, 'Two'],
    [1, 'One'],
    [0, 'Zero'],
  ])

  const numberToWords = (num) => {
    for (const [intValue, textValue] of NUMBERS as any) {
      if (num >= intValue) {
        const prefix = num >= 100 ? numberToWords(Math.trunc(num / intValue)) : ''
        const suffix = num % intValue > 0 ? numberToWords(num % intValue) : ''
        return `${prefix} ${textValue} ${suffix}`.trim()
      }
    }
    return ''
  }

  const {id} = useParams()
  const [data, setRFQData] = useState<any>()
  const [page, setPage] = useState(1)
  const [contactPerson, setContactPerson] = useState<any>({})
  const [imageSize, setImageSize] = useState(false)

  const handleOnLoad = (e) => {
    const img = e.target
    if (img.naturalWidth > img.naturalHeight) {
      setImageSize(true)
    } else {
      setImageSize(false)
    }
  }

  const getData = async () => {
    try {
      const res = await getRFQById(id)
      console.log('res', res)
      setRFQData(res)
      setColor(res?.company?.procurementColor)
      if (res?.items?.length > 9) {
        const pages = Math.ceil((res?.items.length - 9) / 20)
        if ((res?.items.length - 10) / 20 > 16) {
          setPage(pages + 2)
        } else {
          setPage(pages + 1)
        }
      }
      if (res?.contactPerson !== '') {
        setContactPerson(
          res?.vendor?.contactPersons?.find((person) => person?._id === res?.contactPerson) || {}
        )
      } else {
        setContactPerson(res?.vendor?.primaryContact)
      }
    } catch (error) {
      console.error('Axios error:', error)
      throw error
    }
  }
  useEffect(() => {
    getData()
  }, [])

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })
    return formattedDate
  }

  const PF = 'https://qf-assets.s3.me-central-1.amazonaws.com/'

  const PageContent = Array.from({length: page}).map((_, idx) => (
    <div
      key={idx}
      id={`pdf-content-${idx}`}
      className='card overflow-hidden mx-auto !rounded-none'
      style={{
        height: '297mm',
        width: '210mm',
      }}
    >
      {/* begin::Body */}
      <div id='pdfHeader' className='p-2 mx-3 mt-2'>
        <div className='d-flex justify-content-between align-items-center flex-column flex-sm-row'>
          {/*end::Logo*/}
          <div className='text-sm-end'>
            {/*begin::Logo*/}
            <a href='#' className={`d-block ${imageSize ? 'mw-275px' : 'mw-150px'} ms-sm-auto`}>
              {companyLogo ? (
                <img
                  alt='Logo'
                  src={URL.createObjectURL(companyLogo)}
                  className={imageSize ? 'w-100' : 'w-50'}
                  onLoad={handleOnLoad}
                />
              ) : (
                <img
                  alt='thumbnail'
                  src={PF + data?.company?.companyLogo}
                  className={imageSize ? 'w-100' : 'w-50'}
                  onLoad={handleOnLoad}
                />
              )}
            </a>
            {/*end::Logo*/}
          </div>
          <h4 className='fw-bold text-gray-800 xl:fs-1 d-flex flex-column text-right header-text'>
            <span
              className='mb-1 text-right fs-1 tracking-wider '
              style={{
                fontFamily: 'Rubik',
                fontOpticalSizing: 'auto',
                fontStyle: 'normal',
                wordSpacing: '10px',
              }}
            >
              {data?.company?.arabicName}
            </span>
            <span className='mb-1 text-right fs-3 tracking-wider'>{data?.company?.name}</span>
            <span className='fs-6 text-muted'>
              VAT:{data?.company?.vat} | C.R : {data?.company?.cr}
            </span>
          </h4>
        </div>
      </div>
      <div className='separator'></div>
      <div className='card-body px-lg-15'>
        {/* begin::Layout */}

        <div className='d-flex flex-column '>
          {/* begin::Content */}
          <div className='flex-lg-row-fluid mb-10 mb-xl-0'>
            {/* begin::Invoice 1 content */}
            <div className='mt-n1'>
              <div className=''>
                {/*begin::Top*/}

                {idx === 0 && (
                  <div className=' mb-6 fs-3 fw-bold text-gray-800 text-center'>
                    <div className='text-center'>
                      طلب عرض أسعار
                      <br />
                      Request for Quotation
                    </div>
                  </div>
                )}
                {/*end::Top*/}
                {/*begin::Wrapper*/}
                <div className='m-0'>
                  {idx === 0 && (
                    <div className='d-flex justify-content-between flex-column flex-sm-row'>
                      <div className=' mb-5 fw-bold'>
                        <div className='d-flex  flex-column mb-5'>
                          <span className='text-muted'>
                            طلب رقم عرض الأسعار
                            <br />
                            RFQ NO
                          </span>
                          <span className='fs-7'>{data?.id}</span>
                        </div>
                        <div className='d-flex flex-column mb-5'>
                          <span className='text-muted'>
                            عميل
                            <br />
                            Customer
                          </span>

                          <span className='fs-7'>{data?.vendor?.companyName}</span>
                        </div>
                        <div className='d-flex flex-md-root flex-column fw-bold mb-5'>
                          <span className='text-muted mb-1'>
                            عنوان العميل
                            <br />
                            Customer Address
                          </span>
                          <span className='fs-7 mw-200px'>
                            {data?.vendor?.billingAddress.addressLine1}{' '}
                            {data?.vendor?.billingAddress.addressLine2}
                            <br />
                            {data?.vendor?.billingAddress.city}{' '}
                            {data?.vendor?.billingAddress.postalCode}
                            <br />
                            {data?.vendor?.billingAddress.country}
                          </span>
                        </div>
                      </div>
                      <div className='  mb-5  fw-bold'>
                        <div className='d-flex  flex-column mb-5'>
                          <span className='text-muted'>
                            انتباه
                            <br />
                            Attention
                          </span>
                          <span className='fs-7'>
                            {contactPerson?.salutation} {contactPerson?.firstName}{' '}
                            {contactPerson?.lastName}
                          </span>
                        </div>
                        <div className=' d-flex flex-column mb-5'>
                          <span className='text-muted'>
                            تعيين
                            <br />
                            Designation
                          </span>
                          <span className='fs-7'>{contactPerson?.designation}</span>
                        </div>
                        <div className=' d-flex flex-column  '>
                          <span className='text-muted'>
                            عنوان الايميل
                            <br />
                            Email ID
                          </span>
                          <span className='fs-7'>{contactPerson?.emailAddress}</span>
                        </div>
                      </div>
                      <div className='text-end  mb-5  fw-bold'>
                        <div className=' d-flex flex-column  '>
                          <span className='text-muted'>
                            رقم المورد
                            <br />
                            Vendor ID
                          </span>

                          <span className='fs-7'></span>
                        </div>
                        <div className=' d-flex flex-column  '>
                          <span className='text-muted'>
                            رقم الاتصال
                            <br />
                            Contact Number
                          </span>

                          <span className='fs-7'>{data?.vendor?.contactNumbers?.mobilePhone}</span>
                        </div>
                      </div>
                    </div>
                  )}
                  {/*end::Row*/}
                  {/*begin::Content*/}
                  <div className='flex-grow-1'>
                    {/*begin::Table*/}
                    <div className='table-responsive'>
                      <table className='table table-bordered m-0'>
                        <thead>
                          <tr
                            className='fs-9 fw-bold'
                            style={{
                              backgroundColor: color,
                            }}
                          >
                            <th className='min-w-50px text-white text-center pb-3 '>
                              رقم سري
                              <br />
                              <span>Sr. No.</span>
                            </th>
                            <th className='min-w-80px text-white text-center pb-3'>
                              غرض
                              <br />
                              Item
                            </th>
                            <th className='min-w-70px text-white text-center pb-3'>
                              كمية
                              <br />
                              Quantity
                            </th>
                            <th className='min-w-80px text-white text-center pb-3'>
                              وحدة قياس
                              <br />
                              UOM
                            </th>
                          </tr>
                        </thead>
                        <tbody className='' style={{height: '250px'}}>
                          {data?.items
                            ?.slice(
                              idx === 1 || idx === 0 ? idx * 12 : idx * 20 - 8,
                              idx === 0 ? 12 : idx * 20 + 12
                            )
                            .map((item, index) => (
                              <tr
                                key={index}
                                className='border fw-semibold text-gray-600 fs-7 text-center'
                              >
                                <td className=' text-center'>
                                  {idx === 0
                                    ? index + 1
                                    : idx === 1
                                    ? index + 13
                                    : index + idx * 20 - 7}
                                </td>
                                <td>{item?.productName}</td>
                                <td className=''>{item.quantity}</td>
                                <td className=''>UOM</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                    {/*end::Table*/}
                    {/*begin::Container*/}
                    {/*end::Container*/}
                  </div>
                  {/*end::Content*/}
                </div>
                {/*end::Wrapper*/}
              </div>

              {/* end::Wrapper */}
            </div>
            {/* end::Invoice 2 content */}
          </div>
          {/* end::Content */}
          {/* begin::Sidebar */}
          {(idx + 1 === page || page === 1) && (
            <div className={`m-0  px-10 py-2 `}>
              <div className='d-flex  flex-column '>
                <h6 className=' fw-bold text-danger'>
                  ملحوظات
                  <br />
                  Notes
                </h6>
                <p
                  className='min-h-40px'
                  style={{
                    lineHeight: '8px',
                  }}
                >
                  {data?.notes?.split('\n').map((line, index) => (
                    <p key={index}>{line}</p>
                  ))}
                </p>

                <h6 className=' fw-bold text-danger'>
                  الأحكام والشروط
                  <br />
                  Terms and Conditions
                </h6>
                <p
                  className='min-h-40px'
                  style={{
                    lineHeight: '8px',
                  }}
                >
                  {data?.termsNCondition?.split('\n').map((line, index) => (
                    <p key={index}>{line}</p>
                  ))}
                </p>
              </div>
              {/* end::Invoice 2 sidebar */}
            </div>
          )}
          {/* end::Sidebar */}
        </div>
        {/* end::Layout */}
      </div>
      {/* end::Body */}
      <div id='pdfFooter' className='card-footer text-center p-2 fw-bold'>
        <div className='bg-secondary -skew-x-12 text-gray-800'>
          <div className='skew-x-12 py-1'>
            {true &&
              `Mobile :${' '}
        ${data?.company?.mobileNumber} | `}
            <MdOutlineMailOutline
              style={{
                color,
              }}
              className='inline mr-2'
            />
            E-mail : {data?.company?.companyEmail}
            <GoGlobe
              className='inline mr-2 ml-4'
              style={{
                color,
              }}
            />
            Website : {data?.company?.webURL}
          </div>
        </div>
        <div
          className='-skew-x-12 text-white '
          style={{
            backgroundColor: `${color}`,
          }}
        >
          <div className='skew-x-12 py-1'>
            P.O. Box : {data?.company?.pOBox} | Address: {data?.company?.companyAddress}
          </div>
        </div>
      </div>
      <div className='d-flex justify-center mb-4'>
        <p className='fs-8 serif-font'>
          Page {idx + 1} of {page}
        </p>
      </div>
    </div>
  ))

  return (
    <>
      <div className='app-content flex-column-fluid '>
        {/* begin::Content container */}

        <div id='kt_app_content_container' className='print-content container-xxl'>
          {/* begin::Invoice 2 main */}
          {PageContent}
          {/* end::Invoice 2 main */}
        </div>
        {/* end::Content container */}
      </div>
    </>
  )
}

export default RFQMultiLang
