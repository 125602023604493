import React, {FC, useEffect} from 'react'
import {Field, ErrorMessage, useFormikContext} from 'formik'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {CustomerInterface} from '../../../Core/_model'

// Extract form fields into a reusable component
const FormField = ({
  label,
  name,
  type = 'text',
  required = false,
  placeholder,
}: {
  label: string
  name: string
  type?: string
  required?: boolean
  placeholder?: string
}) => (
  <div className='fv-row mb-10'>
    <label className={`form-label ${required ? 'required' : ''}`}>{label}</label>
    <div className='d-flex align-items-center'>
      <Field
        type={type}
        name={name}
        className='form-control form-control-lg form-control-solid'
        placeholder={placeholder || label}
      />
    </div>
    <div className='text-danger mt-2'>
      <ErrorMessage name={name} />
    </div>
  </div>
)

const Step2: FC = () => {
  const {setFieldValue, values} = useFormikContext<CustomerInterface>()

  // Combine phone number handling into a single useEffect
  useEffect(() => {
    const workPhone = String(values?.contactNumbers?.workPhone || '')
    const mobilePhone = String(values?.contactNumbers?.mobilePhone || '')

    setFieldValue('contactNumbers.workPhone', workPhone)
    setFieldValue('contactNumbers.mobilePhone', mobilePhone)
  }, [values?.contactNumbers, setFieldValue])

  // Phone number change handlers
  const handlePhoneChange = (type: 'work' | 'mobile') => (phone: string) => {
    setFieldValue(`contactNumbers.${type}Phone`, phone)
  }

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-12'>
        <h2 className='fw-bolder text-dark'>Basic Details</h2>
      </div>

      {/* Primary Contact Section */}
      <div className='fv-row mb-10'>
        <label className='form-label required '>Primary Contact</label>
        <div className='d-flex align-items-center space-x-3'>
          <Field
            as='select'
            name='primaryContact.salutation'
            className='form-select form-select-lg form-select-solid '
          >
            <option value=''>Select </option>
            <option value='Mr'>Mr</option>
            <option value='Mrs'>Mrs</option>
            <option value='Ms'>Ms</option>
          </Field>
          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='primaryContact.firstName'
            placeholder='First Name'
          />

          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='primaryContact.lastName'
            placeholder='Last Name'
          />
        </div>
        <div className='d-flex align-items-center justify-between'>
          <div className='text-danger mt-2'>
            <ErrorMessage name='primaryContact.salutation' />
          </div>
          <div className='text-danger mt-2'>
            <ErrorMessage name='primaryContact.firstName' />
          </div>
          <div className='text-danger mt-2'>
            <ErrorMessage name='primaryContact.lastName' />
          </div>
        </div>
      </div>

      {/* Use the new FormField component for repeated fields */}
      <FormField label='Company Name' name='companyName' required placeholder='Company Name' />

      <FormField
        label='Customer Display Name'
        name='displayName'
        required
        placeholder='Customer Display Name'
      />

      <FormField
        label='Customer VAT Number'
        name='vatNumber'
        required
        placeholder='Customer VAT Number'
      />

      <FormField
        label='Customer Email'
        name='emailAddress'
        type='email'
        required
        placeholder='Email Address'
      />

      {/* Phone number section */}
      <div className='fv-row mb-10'>
        <label className='form-label'>Customer Phone</label>
        <div className='d-flex align-items-center space-x-3'>
          <div className='form-control form-control-lg form-control-solid'>
            <label className='mb-3 form-label text-muted'>Landline Number</label>
            <PhoneInput
              country={'sa'}
              value={values?.contactNumbers?.workPhone}
              onChange={handlePhoneChange('work')}
            />
          </div>

          <div className='form-control form-control-lg form-control-solid'>
            <label className='mb-3 form-label required text-muted'>Mobile Number</label>
            <PhoneInput
              country={'sa'}
              value={values?.contactNumbers?.mobilePhone}
              onChange={handlePhoneChange('mobile')}
            />
          </div>
        </div>
        {!values?.contactNumbers?.mobilePhone && (
          <div className='text-danger mt-2 fs-6 font-normal'>Phone number is required</div>
        )}
      </div>
    </div>
  )
}

export {Step2}
