import {lazy} from 'react'
import {Route, Routes} from 'react-router-dom'
import {SuspensedView} from '../../routing/PrivateRoutes'

export default function HRPEmployeeReportsRoutes() {
  const SubmittedReports = lazy(() => import('./components/SubmittedReports'))
  const AddReportSubmission = lazy(() => import('./components/AddReportSubmission'))
  const ViewHRPSubmittedReport = lazy(() => import('./components/ViewHRPSubmittedReport'))
  const ViewReportDetails = lazy(() => import('./components/ViewReportDetails'))

  return (
    <Routes>
      <Route
        path='/'
        element={
          <SuspensedView>
            <SubmittedReports />
          </SuspensedView>
        }
      />
      <Route
        path='/add'
        element={
          <SuspensedView>
            <AddReportSubmission />
          </SuspensedView>
        }
      />
      <Route
        path='/viewSubmissions/:id'
        element={
          <SuspensedView>
            <ViewHRPSubmittedReport />
          </SuspensedView>
        }
      />
      <Route
        path='/viewSubmissionDetails/:id'
        element={
          <SuspensedView>
            <ViewReportDetails />
          </SuspensedView>
        }
      />
    </Routes>
  )
}
