import React, {useState, useEffect} from 'react'
import {useParams, Link} from 'react-router-dom'
import {
  addChildAccount,
  getAccounts,
  getParentAccountDetail,
  removeChildAccount,
} from '../core/_requests'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Modal} from 'react-bootstrap'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useAuth} from '../../../modules/auth'
import Swal from 'sweetalert2'

interface ChildAccount {
  _id: string
  accountName: string
  accountCode: string
  amount: number
}

interface ParentAccountData {
  _id: string
  accountName: string
  accountType: string
  accountCode: string
  description: string
  amount: number
  childAccounts: ChildAccount[]
}

interface AvailableChildAccount {
  _id: string
  accountName: string
  accountCode: string
}

const ParentAccountDetail: React.FC = () => {
  const {id, accountType} = useParams<{id: string; accountType: string}>()
  const {currentUser} = useAuth()
  const [accountData, setAccountData] = useState<ParentAccountData | null>(null)
  const [loading, setLoading] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [availableChildAccounts, setAvailableChildAccounts] = useState<AvailableChildAccount[]>([])

  const fetchAccountDetail = async () => {
    if (id) {
      try {
        setLoading(true)
        const response = await getParentAccountDetail(id)
        setAccountData({
          ...response,
          amount: response?.childAccounts?.reduce((acc, curr) => acc + curr?.amount, 0),
        })
        const availableAccounts = await getAccounts(accountType, currentUser?.organization)
        setAvailableChildAccounts(availableAccounts)
      } catch (error) {
        console.error('Error fetching parent account details:', error)
      } finally {
        setLoading(false)
      }
    }
  }
  useEffect(() => {
    fetchAccountDetail()
  }, [id])

  const formatAmount = (amount: number) => {
    return `${currentUser?.baseCurrency} ${amount?.toLocaleString('en-US', {
      minimumFractionDigits: 2,
    })}`
  }

  const handleRemoveChildAccount = async (childId: string) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to remove this child account?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await removeChildAccount(id!, childId)
          const updatedAccount = await getParentAccountDetail(id!)
          setAccountData({
            ...updatedAccount,
            amount: updatedAccount?.childAccounts?.reduce((acc, curr) => acc + curr?.amount, 0),
          })
          Swal.fire('Removed!', 'The child account has been removed.', 'success')
        } catch (error) {
          console.error('Error removing child account:', error)
          Swal.fire('Error', 'An error occurred while removing the child account.', 'error')
        }
      }
    })
  }

  const formik = useFormik({
    initialValues: {
      childAccountId: '',
    },
    validationSchema: Yup.object({
      childAccountId: Yup.string().required('Please select a sub account'),
    }),
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      try {
        if (accountData.childAccounts.some((account) => account._id === values.childAccountId)) {
          Swal.fire('Error', 'This sub account is already linked to the parent account.', 'error')
          return
        }

        await addChildAccount(id!, values.childAccountId)
        const updatedAccount = await getParentAccountDetail(id!)
        setAccountData({
          ...updatedAccount,
          amount: updatedAccount?.childAccounts?.reduce((acc, curr) => acc + curr?.amount, 0),
        })
        setShowModal(false)
        resetForm()
      } catch (error) {
        console.error('Error adding child account:', error)
      } finally {
        setSubmitting(false)
      }
    },
  })

  if (loading) {
    return (
      <div className='d-flex justify-content-center align-items-center' style={{height: '400px'}}>
        <div className='d-flex align-items-center justify-center'>
          <div className='loader'></div>
        </div>
      </div>
    )
  }

  if (!accountData) {
    return (
      <div className='card'>
        <div className='card-body py-3'>
          <div className='text-center'>
            <img
              src={toAbsoluteUrl('/media/illustrations/sketchy-1/5.png')}
              alt='Account not found'
              className='mw-100 mh-300px'
            />
            <div className='mt-5'>Account not found.</div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='card'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{accountData.accountName}</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Parent Account Details</span>
        </h3>
      </div>
      <div className='card-body py-3'>
        <div className='row mb-7'>
          <div className='col-lg-6 mb-4 mb-lg-0'>
            <div className='fw-bold text-gray-600 fs-7'>Account Type</div>
            <div className='fw-bolder fs-6 text-gray-800'>{accountData.accountType}</div>
          </div>
          <div className='col-lg-6'>
            <div className='fw-bold text-gray-600 fs-7'>Account Code</div>
            <div className='fw-bolder fs-6 text-gray-800'>{accountData.accountCode}</div>
          </div>
        </div>
        <div className='row mb-7'>
          <div className='col-lg-6'>
            <div className='fw-bold text-gray-600 fs-7'>Amount</div>
            <div className='fw-bolder fs-6 text-gray-800'>{formatAmount(accountData.amount)}</div>
          </div>
        </div>
        <div className='row mb-7'>
          <div className='col-lg-12'>
            <div className='fw-bold text-gray-600 fs-7'>Description</div>
            <div className='fw-bolder fs-6 text-gray-800'>{accountData.description || ''}</div>
          </div>
        </div>

        <div className='separator separator-dashed my-8'></div>

        <div className='d-flex justify-content-between align-items-center mb-5'>
          <h4>Sub Accounts</h4>
          <button
            type='button'
            className='btn btn-sm btn-light-primary'
            onClick={() => setShowModal(true)}
          >
            <KTIcon iconName='plus' className='fs-2' />
            Add Sub Account
          </button>
        </div>

        {accountData.childAccounts.length > 0 ? (
          <div className='table-responsive'>
            <table className='table align-middle table-row-dashed fs-6 gy-5'>
              <thead>
                <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                  <th className='min-w-125px'>Account Name</th>
                  <th className='min-w-125px'>Account Code</th>
                  <th className='min-w-125px'>Amount</th>
                  <th className='text-end min-w-100px'>Actions</th>
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-semibold'>
                {accountData.childAccounts.map((childAccount) => (
                  <tr key={childAccount._id}>
                    <td>
                      <Link
                        to={`/account/chartofaccounts/${childAccount._id}`}
                        className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                      >
                        {childAccount.accountName}
                      </Link>
                    </td>
                    <td>{childAccount.accountCode}</td>
                    <td>{formatAmount(childAccount.amount)}</td>
                    <td className='text-end'>
                      <button
                        type='button'
                        className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                        onClick={() => handleRemoveChildAccount(childAccount._id)}
                      >
                        <KTIcon iconName='trash' className='fs-3' />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className='text-center'>
            <div className='mt-5'>No child accounts linked to this parent account.</div>
          </div>
        )}
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Sub Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit}>
            <div className='mb-3'>
              <label htmlFor='childAccountId' className='form-label'>
                Select Sub Account
              </label>
              <select
                id='childAccountId'
                className={`form-select ${
                  formik.touched.childAccountId && formik.errors.childAccountId ? 'is-invalid' : ''
                }`}
                {...formik.getFieldProps('childAccountId')}
              >
                <option value=''>Select a sub account</option>
                {availableChildAccounts.map((account) => (
                  <option key={account._id} value={account._id}>
                    {account.accountName} ({account.accountCode})
                  </option>
                ))}
              </select>
              {formik.touched.childAccountId && formik.errors.childAccountId && (
                <div className='invalid-feedback'>{formik.errors.childAccountId}</div>
              )}
            </div>
            <div className='text-end'>
              <button
                type='button'
                className='btn btn-light me-3'
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <button type='submit' className='btn btn-primary' disabled={formik.isSubmitting}>
                {formik.isSubmitting ? 'Adding...' : 'Add'}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default ParentAccountDetail
