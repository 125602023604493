import React, {useEffect, useState} from 'react'
import {Card} from 'react-bootstrap'
import {DragDropContext, Droppable, Draggable, DropResult} from 'react-beautiful-dnd'
import {pipelineStatus} from '../core/model'
import {getContacts, changeContactStatus} from '../core/_requests'
import {useAuth} from '../../../modules/auth'
import {toast} from 'react-hot-toast'
import {useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../../redux/store'
import {setCRMContacts, setCRMContactsFetch} from '../../../redux/crm/contacts/contactsSlice'

interface PipelineTask {
  _id: string
  displayName: string
  pipelineStatus: string
  companyName: string
  phone: string
  customerType: 'business' | 'individual'
}

const ContactsPipeline: React.FC = () => {
  const {currentUser} = useAuth()
  const [loading, setLoading] = useState(false)
  const contacts = useSelector((state: RootState) => state.crm.contacts.contacts)
  const crmContactsFetch = useSelector((state: RootState) => state.crm.contacts.fetch)
  const [data, setData] = useState<{[key: string]: PipelineTask[]}>({
    new: [],
    prospect: [],
    proposal: [],
    closed: [],
    rejected: [],
  })
  const dispatch = useDispatch()

  useEffect(() => {
    const fetchPipelineTasks = async () => {
      setLoading(true)
      const tasks = await getContacts(currentUser.organization)
      dispatch(setCRMContacts(tasks))
      const groupedTasks = pipelineStatus.reduce((acc, stage) => {
        acc[stage.status] = tasks.filter(
          (task: PipelineTask) => task.pipelineStatus === stage.status
        )
        return acc
      }, {} as {[key: string]: PipelineTask[]})
      setData(groupedTasks)
      setLoading(false)
    }
    if (currentUser.organization !== undefined) {
      if (crmContactsFetch) fetchPipelineTasks()
      else {
        const groupedTasks = pipelineStatus.reduce((acc, stage) => {
          acc[stage.status] = contacts.filter(
            (task: PipelineTask) => task.pipelineStatus === stage.status
          )
          return acc
        }, {} as {[key: string]: PipelineTask[]})
        setData(groupedTasks)
      }
    }
  }, [currentUser.organization])

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return

    const {source, destination} = result

    if (source.droppableId === destination.droppableId) {
      // Reorder items within the same stage
      const stageTasks = [...data[source.droppableId]]
      const [movedTask] = stageTasks.splice(source.index, 1)
      stageTasks.splice(destination.index, 0, movedTask)

      setData({
        ...data,
        [source.droppableId]: stageTasks,
      })
    } else {
      // Move task to a different stage
      const sourceTasks = [...data[source.droppableId]]
      const destTasks = [...data[destination.droppableId]]
      const [movedTask] = sourceTasks.splice(source.index, 1)
      destTasks.splice(destination.index, 0, movedTask)

      setData({
        ...data,
        [source.droppableId]: sourceTasks,
        [destination.droppableId]: destTasks,
      })
      dispatch(setCRMContactsFetch())
      changeContactStatus(movedTask._id, destination.droppableId).then((res) => {
        toast.success('Contact status changed successfully')
      })
    }
  }

  return (
    <div className='m-4 card'>
      {loading ? (
        <div className='d-flex align-items-center justify-center'>
          <div className='loader'></div>
        </div>
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          <div className='card-body row g-5 g-xl-10 mb-5 w-100 d-flex justify-content-between'>
            {pipelineStatus.map((stage) => (
              <div className='col mb-4 px-2' key={stage.status}>
                <Card className={`bg-light-${stage.color} w-100`} style={{minWidth: '200px'}}>
                  <div className={`p-2 d-flex justify-content-between m-2 bg-light-${stage.color}`}>
                    <div className={`capitalize font-semibold text-3xl text-dark`}>
                      {stage.status.charAt(0).toUpperCase() + stage.status.slice(1)}
                    </div>
                    <span className='badge badge-light-secondary text-dark fs-4 fw-semibold'>
                      {data[stage.status]?.length}
                    </span>
                  </div>
                  <Droppable droppableId={stage.status}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className='min-h-[50px]'
                      >
                        {data[stage.status]?.map((task, index) => (
                          <Draggable key={task._id} draggableId={task._id.toString()} index={index}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className='m-3 my-5 bg-white rounded-lg p-3'
                              >
                                <h4 className='text-black capitalize'>{task.displayName}</h4>
                                <p className='text-md'>{task.companyName}</p>
                                <p className='text-sm'>{task.phone}</p>
                                <p
                                  className={`badge badge-light-${
                                    task.customerType === 'business' ? 'success' : 'info'
                                  }`}
                                >
                                  {task.customerType}
                                </p>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </Card>
              </div>
            ))}
          </div>
        </DragDropContext>
      )}
    </div>
  )
}

export default ContactsPipeline
