import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  products: [],
  loading: true,
  fetch: true,
}

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setCRMProducts: (state, action) => {
      state.products = action.payload
      state.loading = false
      state.fetch = false
    },
    setCRMProductsLoading: (state, action) => {
      state.loading = action.payload
    },
    setCRMProductsFetch: (state, action) => {
      state.fetch = action.payload
    },
  },
})

export const {setCRMProducts, setCRMProductsLoading, setCRMProductsFetch} = productsSlice.actions
export default productsSlice.reducer
