import React, {useEffect, useRef, useState} from 'react'
import {useParams} from 'react-router-dom'
import '../print.css'
import {getPurchaseQuotationById} from '../../Core/_requests'
import {getTerms} from '../../../account_settings/core/_request'
import {useAuth} from '../../../../modules/auth'
import {GoGlobe} from 'react-icons/go'
import {MdOutlineMailOutline} from 'react-icons/md'
import {convertAmountToWords} from '../../../../utils/utils'

function PurchaseOrder({companyLogo, color, setColor, minFooter, checkedBy}) {
  const {currentUser} = useAuth()
  const heightRef = useRef<HTMLDivElement>(null)
  const [dynamicHeight, setDynamicHeight] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(13)
  const [maxItemsPerPage, setMaxItemsPerPage] = useState(20)

  useEffect(() => {
    const updateHeight = () => {
      if (heightRef.current) {
        setDynamicHeight(heightRef.current.offsetHeight)
      }
    }

    updateHeight() // Set initial height
    window.addEventListener('resize', updateHeight) // Update height on window resize

    return () => {
      window.removeEventListener('resize', updateHeight) // Cleanup event listener
    }
  }, [])

  const NUMBERS = new Map([
    [1e9, 'Billion'],
    [1e6, 'Million'],
    [1e3, 'Thousand'],
    [1e2, 'Hundred'],
    [90, 'Ninety'],
    [80, 'Eighty'],
    [70, 'Seventy'],
    [60, 'Sixty'],
    [50, 'Fifty'],
    [40, 'Forty'],
    [30, 'Thirty'],
    [20, 'Twenty'],
    [19, 'Nineteen'],
    [18, 'Eighteen'],
    [17, 'Seventeen'],
    [16, 'Sixteen'],
    [15, 'Fifteen'],
    [14, 'Fourteen'],
    [13, 'Thirteen'],
    [12, 'Twelve'],
    [11, 'Eleven'],
    [10, 'Ten'],
    [9, 'Nine'],
    [8, 'Eight'],
    [7, 'Seven'],
    [6, 'Six'],
    [5, 'Five'],
    [4, 'Four'],
    [3, 'Three'],
    [2, 'Two'],
    [1, 'One'],
    [0, 'Zero'],
  ])

  const numberToWords = (num) => {
    for (const [intValue, textValue] of NUMBERS as any) {
      if (num >= intValue) {
        const prefix = num >= 100 ? numberToWords(Math.trunc(num / intValue)) : ''
        const suffix = num % intValue > 0 ? numberToWords(num % intValue) : ''
        return `${prefix} ${textValue} ${suffix}`.trim()
      }
    }
    return ''
  }

  const convertSARAmountToWords = (amount) => {
    const [riyals, halalas] = amount.toString().split('.').map(Number)
    const riyalsInWords = numberToWords(riyals)
    const halalasInWords = halalas ? numberToWords(halalas.toString().padEnd(2, '0')) : ''

    return `${riyalsInWords} Riyals${halalasInWords ? ` and ${halalasInWords} Halalas` : ''} Only`
  }

  const [vat, setVat] = useState(0)
  const [data, setPurchsaseOrderData] = useState<any>()
  const [contactPerson, setContactPerson] = useState<any>()
  const [imageSize, setImageSize] = useState<any>(false)
  const [annexure, setAnnexure] = useState<any>('')
  const [page, setPage] = useState(2)
  const [extraPages, setExtraPages] = useState(0)
  const {id} = useParams()

  const handleOnLoad = (e) => {
    const img = e.target
    if (img.naturalWidth > img.naturalHeight) {
      setImageSize(true)
    } else {
      setImageSize(false)
    }
  }

  useEffect(() => {
    const getData = async () => {
      const res = await getPurchaseQuotationById(id)
      setPurchsaseOrderData(res)
      console.log('res', res)
      setColor(res?.company?.procurementColor)

      let itemsPerPageTemp = 13
      let maxItemsPerPageTemp = 20

      if (res?.items?.some((item) => item?.productName?.length > 120)) {
        itemsPerPageTemp = 7
        maxItemsPerPageTemp = 10
      } else if (res?.items?.some((item) => item?.productName?.length > 70)) {
        itemsPerPageTemp = 10
        maxItemsPerPageTemp = 15
      }

      let pagesTemp = 2

      if (res?.items?.length > 10) {
        pagesTemp = Math.ceil((res?.items.length - 10) / maxItemsPerPageTemp)
        if ((res?.items.length - itemsPerPageTemp) / maxItemsPerPageTemp > 17) {
          setPage(pagesTemp + 3)
        } else {
          setPage(pagesTemp + 2)
        }
      }
      setItemsPerPage(itemsPerPageTemp)
      setMaxItemsPerPage(maxItemsPerPageTemp)
      if (res?.contactPerson !== '') {
        setContactPerson(
          res?.vendor?.contactPersons?.find((person) => person?._id === res?.contactPerson) || {}
        )
      } else {
        setContactPerson(res?.vendor?.primaryContact)
      }
      const annexureRes = await getTerms(currentUser?.organization, 'Purchase Orders')
      console.log('annexure', annexureRes)
      setAnnexure(annexureRes?.purchaseOrderTerms?.annexure)
      // if (res?.termsCondition?.length > 2200) {
      //   setExtraPages(Math.ceil(res?.termsCondition?.length / 2200))
      // }
      if (annexureRes?.purchaseOrderTerms?.annexure?.length > 3100) {
        setExtraPages(Math.ceil(annexureRes?.purchaseOrderTerms?.annexure?.length / 3100))
      }
    }
    getData()
  }, [])

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })
    return formattedDate
  }

  useEffect(() => {
    if (data?.tax) {
      const tax = (data?.subtotal * data?.tax) / 100
      setVat(tax)
    }
  }, [data])

  const findSplitPoint = (maxLength) => {
    if (annexure.length <= maxLength) return annexure.length
    let closest = annexure.lastIndexOf('•', maxLength)
    if (closest === -1) closest = annexure.indexOf('•', maxLength)
    return closest === -1 ? maxLength : closest
  }

  const PF = 'https://qf-assets.s3.me-central-1.amazonaws.com/'

  const PageContent = Array.from({length: extraPages === 0 ? page : page + extraPages - 1}).map(
    (_, idx) => (
      <div
        key={idx}
        id={`pdf-content-${idx}`}
        className='card overflow-hidden mx-auto !rounded-none'
        style={{
          height: '297mm',
          width: '210mm',
        }}
      >
        <div id='pdfHeader' className='px-2 mx-3 mt-2'>
          <div className='d-flex justify-content-between align-items-center flex-column flex-sm-row'>
            {/*end::Logo*/}
            <div className='text-sm-end'>
              {/*begin::Logo*/}
              <a href='#' className={`d-block ${imageSize ? 'mw-275px' : 'mw-150px'} ms-sm-auto`}>
                {companyLogo ? (
                  <img
                    alt='Logo'
                    src={URL.createObjectURL(companyLogo)}
                    className={imageSize ? 'w-100' : 'w-50'}
                    onLoad={handleOnLoad}
                  />
                ) : (
                  <img
                    alt='thumbnail'
                    src={PF + data?.company?.companyLogo}
                    className={imageSize ? 'w-100' : 'w-50'}
                    onLoad={handleOnLoad}
                  />
                )}
              </a>
              {/*end::Logo*/}
            </div>
            <h4 className='fw-bold text-gray-800 xl:fs-1 d-flex flex-column text-right header-text'>
              <span
                className='mb-1 text-right fs-1 tracking-[1px]'
                style={{
                  // fontFamily: 'Rubik',
                  fontFamily: 'Scheherazade New',
                  fontOpticalSizing: 'auto',
                  fontStyle: 'normal',
                  wordSpacing: '10px',
                }}
              >
                {data?.company?.arabicName}
              </span>
              <span
                className='mb-1 text-right fs-2 tracking-wider'
                style={{
                  color: color,
                }}
              >
                {data?.company?.name}
              </span>
              <span className='fs-5 text-muted'>
                VAT {data?.company?.vat} <span style={{color: color}}>|</span> C.R.{' '}
                {data?.company?.cr}
              </span>
            </h4>
          </div>
        </div>
        {/* end::Top */}

        {/* begin::Wrapper */}
        <div className='border-b-gray-700 border-b mx-9'></div>
        {/* begin::Body */}
        <div className='card-body px-lg-15 !pb-4'>
          {/* begin::Layout */}

          <div className='d-flex flex-column h-100'>
            {/* begin::Content */}
            {idx < page - 1 && (
              <div className='flex-lg-row-fluid mb-10 mb-xl-0'>
                {/* begin::Invoice 1 content */}
                <div className='mt-n1'>
                  {/* begin::Top */}
                  <div className=''>
                    {idx === 0 && (
                      <div className='mb-6 fs-1 fw-bold text-gray-800'>
                        <div className='uppercase fs-5'>Purchase Order</div>
                      </div>
                    )}
                    {idx === 0 && (
                      <div
                        className='d-flex justify-content-between flex-column flex-sm-row fs-7 text-black'
                        style={{textTransform: 'uppercase'}}
                      >
                        <div className=' mb-5 '>
                          <div className='d-flex  flex-column mb-5'>
                            <span className='fw-bold '>Vendor Name</span>
                            <span className=''>
                              {/* {contactPerson?.salutation} {contactPerson?.firstName}{' '}
                              {contactPerson?.lastName} */}
                              {data?.vendor?.companyName}
                            </span>
                          </div>
                          {/* <div className='d-flex flex-column mb-5'>
                          <span className='fw-bold'>Delivery Address</span>

                          <span className='fs-6 mw-200px'>
                            {data?.company?.name}
                            <br />
                            {data?.company?.companyAddress}{' '}
                          </span>
                        </div> */}
                          <div className=' d-flex flex-column mb-5'>
                            <span className='fw-bold'>Vendor Number</span>
                            <span className=''>{data?.vendor?.vendorSupplierNo}</span>
                          </div>
                          {/* <div className='d-flex flex-md-root flex-column  mb-5'>
                          <span className='fw-bold mb-1'>Payment Terms</span>
                          <span className='fs-6 mw-200px'>{data?.vendor?.paymentTerms}</span>
                        </div> */}
                          <div className='d-flex flex-md-root flex-column  mb-5'>
                            <span className='fw-bold mb-1'>Vendor Address</span>
                            <span className='mw-200px'>
                              {data?.vendor?.shippingAddress?.addressLine1}{' '}
                              {data?.vendor?.shippingAddress?.addressLine2}
                              {/* <br /> */}
                              {`\n`}
                              {data?.vendor?.shippingAddress?.city}{' '}
                              {data?.vendor?.shippingAddress?.postalCode}
                              <br />
                              {data?.vendor?.shippingAddress?.country}
                            </span>
                          </div>
                        </div>
                        <div className='  mb-5 '>
                          <div className='d-flex  flex-column mb-5'>
                            <span className='fw-bold'>PO Number</span>
                            <span className=''>{data?.id}</span>
                          </div>
                          <div className=' d-flex flex-column  '>
                            <span className='fw-bold'>Inco Terms</span>
                            <span className=''>{data?.incoTerms}</span>
                          </div>
                        </div>
                        <div className='text-start  mb-5  '>
                          <div className='d-flex flex-column mb-5'>
                            <span className='fw-bold'>PO Date</span>

                            <span className=''>{formatCreatedAt(data?.date)}</span>
                          </div>
                          <div className='d-flex flex-column mb-5'>
                            <span className='fw-bold'>Quotation Ref.</span>

                            <span className=''>{data?.reference}</span>
                          </div>
                        </div>
                      </div>
                    )}
                    {/*end::Row*/}
                    {/*begin::Content*/}
                    <div className='flex-grow-1 -mx-5'>
                      {/*begin::Table*/}
                      <div className='table-responsive'>
                        {idx + 1 < page && (
                          <table
                            className='table table-bordered m-0'
                            style={{
                              borderColor: '#020617',
                            }}
                          >
                            <thead>
                              <tr
                                className='fs-7 fw-bold uppercase'
                                style={{
                                  backgroundColor: color,
                                }}
                              >
                                <th className='min-w-20px text-white text-center pb-3 px-2'>
                                  <span className='arabic-font'>رقم</span>
                                  <br />
                                  <span>S.No.</span>
                                </th>

                                <th className='min-w-300px text-white text-center pb-3 '>
                                  <span className='arabic-font'>وصف</span>
                                  <br />
                                  Description
                                </th>

                                <th className='min-w-60px text-white text-center pb-3'>
                                  <span className='arabic-font'>كمية</span>
                                  <br />
                                  QTY
                                </th>
                                <th className='min-w-60px text-white text-center pb-3'>
                                  <span className='arabic-font'>وحدة</span>
                                  <br />
                                  UOM
                                </th>

                                <th className='min-w-100px text-white text-center pb-3'>
                                  <span className='arabic-font'>سعر الوحدة</span>
                                  <br />
                                  Unit Price
                                </th>

                                <th className='min-w-100px text-white text-center pb-3'>
                                  <span className='arabic-font'>المجموع</span>
                                  <br />
                                  Total
                                </th>
                              </tr>
                            </thead>
                            <tbody className=''>
                              {data?.items
                                ?.slice(
                                  idx === 1 || idx === 0
                                    ? idx * itemsPerPage
                                    : idx * maxItemsPerPage - (maxItemsPerPage - itemsPerPage),
                                  idx === 0 ? itemsPerPage : idx * maxItemsPerPage + itemsPerPage
                                )
                                .map((item, index) => (
                                  <tr
                                    key={index}
                                    className='fw-semibold text-black fs-8 text-center'
                                  >
                                    <td className='text-black'>
                                      {idx === 0
                                        ? index + 1
                                        : idx === 1
                                        ? index + itemsPerPage + 1
                                        : index +
                                          idx * maxItemsPerPage -
                                          (maxItemsPerPage - itemsPerPage) +
                                          1}
                                    </td>
                                    <td className='text-justify text-black'>
                                      <span className='fw-bold'>{item?.productName}</span>
                                      {item?.description && item?.description?.trim() !== '' && (
                                        <>
                                          <br />
                                          <span>{item?.description}</span>
                                        </>
                                      )}
                                    </td>
                                    <td className='text-black'>{item.quantity}</td>
                                    <td className='uppercase text-black'>
                                      {item?.unit ||
                                        item?.itemId?.unit ||
                                        item?.itemsId?.unit ||
                                        'units'}
                                    </td>
                                    <td className='text-end text-black'>
                                      {Number(item?.price).toLocaleString('en-US', {
                                        minimumFractionDigits: 2,
                                      })}
                                    </td>
                                    <td className='text-end text-black'>
                                      {Number(item?.amount).toLocaleString('en-US', {
                                        minimumFractionDigits: 2,
                                      })}{' '}
                                    </td>
                                  </tr>
                                ))}
                              {((page === 2 && idx === 0) || idx + 2 === page) && (
                                <>
                                  <tr className='h-100px'>
                                    <td colSpan={2} className='p-0'>
                                      <table className='table w-full m-0 border-transparent fs-8'>
                                        <tbody
                                          className='min-w-80px'
                                          style={{display: 'flex', flexDirection: 'column'}}
                                        >
                                          <tr className=''>
                                            {/* <div className='d-flex flex-column justify-between w-100 h-100 uppercase fs-8 m-0'> */}
                                            <div
                                              className='d-flex flex-column border-t border-transparent px-3 text-black'
                                              style={{
                                                // height: heightRef?.current?.offsetHeight * 2 + 'px',
                                                height: dynamicHeight * 3 - 9.5 + 'px',
                                                // borderBottomColor: '#020617',
                                              }}
                                            >
                                              <span className='fw-bold mt-6'>Delivery Address</span>

                                              <span className='fs-8 mw-200px'>
                                                {data?.company?.name}
                                                <br />
                                                {data?.company?.companyAddress}
                                              </span>
                                            </div>
                                            {/* <div
                                              className='d-flex flex-md-root border-t flex-column !border-b-0 fs-8 px-3 py-2 text-black'
                                              style={{
                                                height: dynamicHeight + 'px',
                                                borderTopColor: '#020617',
                                              }}
                                            >
                                              <span className='fw-bold'>Payment Terms</span>
                                              <span className='leading-[7px]'>
                                                {data?.paymentTerms}
                                              </span>
                                            </div> */}
                                            {/* </div> */}
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td colSpan={2} className='p-0 text-sm'>
                                      <div className='d-flex flex-column justify-between w-100 h-100 uppercase fs-7 text-black'>
                                        <div
                                          className='border-b px-3 py-4 text-right'
                                          ref={heightRef}
                                          style={{
                                            borderColor: '#020617',
                                          }}
                                        >
                                          <p className='mb-0'>
                                            {/* <br /> */}
                                            Total Amount
                                          </p>
                                        </div>
                                        <div
                                          className='border-b px-3 py-4 text-right'
                                          style={{
                                            borderColor: '#020617',
                                          }}
                                        >
                                          <p className='mb-0'>
                                            {/* <br /> */}
                                            Freight Charges
                                          </p>
                                        </div>
                                        <div
                                          className='border-b px-3 py-4 text-right'
                                          style={{
                                            borderColor: '#020617',
                                          }}
                                        >
                                          <p className='mb-0'>
                                            {/* <br /> */}
                                            Discount
                                          </p>
                                        </div>
                                        <div className='px-3 py-4 text-right'>
                                          <p className='mb-0'>
                                            {/* <br /> */}
                                            VAT ({data?.tax}%)
                                          </p>
                                        </div>
                                      </div>
                                    </td>

                                    <td colSpan={2} className='p-0 text-sm text-black'>
                                      <div className='d-flex flex-column justify-between w-100 h-100 fs-7'>
                                        <div
                                          className='border-b px-3 py-4  d-flex justify-between'
                                          style={{
                                            borderColor: '#020617',
                                          }}
                                        >
                                          <p className='pe-0 mb-0'>
                                            {}
                                            {/* <br /> */}
                                            {data?.vendor?.currency}
                                          </p>
                                          <p className='text-end ps-0 mb-0'>
                                            {}
                                            {/* <br /> */}
                                            {data?.subtotal.toLocaleString('en-US', {
                                              minimumFractionDigits: 2,
                                            })}{' '}
                                          </p>
                                        </div>
                                        <div
                                          className='border-b px-3 py-4  d-flex justify-between'
                                          style={{
                                            borderColor: '#020617',
                                          }}
                                        >
                                          <p className='pe-0 mb-0'>
                                            {/* <br /> */}
                                            {data?.vendor?.currency}
                                          </p>
                                          <p className='text-end ps-0 mb-0'>
                                            {/* <br /> */}
                                            {data?.freightCharges?.toLocaleString('en-US', {
                                              minimumFractionDigits: 2,
                                            }) || '0.00'}
                                          </p>
                                        </div>
                                        <div
                                          className='border-b px-3 py-4  d-flex justify-between'
                                          style={{
                                            borderColor: '#020617',
                                          }}
                                        >
                                          <p className='pe-0 mb-0'>
                                            {/* <br /> */}
                                            {data?.vendor?.currency}
                                          </p>
                                          <p className='text-end ps-0 mb-0'>
                                            {/* <br /> */}
                                            {data?.discount?.toLocaleString('en-US', {
                                              minimumFractionDigits: 2,
                                            }) || '0.00'}
                                          </p>
                                        </div>
                                        <div className='px-3 py-4  d-flex justify-between'>
                                          <p className='pe-0 mb-0'>
                                            {/* <br /> */}

                                            {data?.vendor?.currency}
                                          </p>
                                          <p className='text-end ps-0 mb-0'>
                                            {/* <br /> */}
                                            {vat?.toLocaleString('en-US', {
                                              minimumFractionDigits: 2,
                                            })}
                                          </p>
                                        </div>
                                      </div>
                                    </td>
                                    {/* Empty cell for design consistency */}
                                  </tr>
                                  <tr className='py-1 h-50px text-end fs-7'>
                                    <td colSpan={2} className='text-start text-black'>
                                      <span className='uppercase fw-bold'>Total in words</span>
                                      <br />
                                      <span className='fw-bold'>{data?.vendor?.currency}</span>{' '}
                                      {/* {numberToWords(data?.total)} */}
                                      {/* {numberToWords(testAmount)} */}
                                      {/* {data?.vendor?.currency === 'SAR' && ' Riyals'} Only. */}
                                      {data?.vendor?.currency === 'SAR'
                                        ? convertSARAmountToWords(data?.total)
                                        : data?.vendor?.currency === 'USD' ||
                                          data?.vendor?.currency === 'EUR' ||
                                          data?.vendor?.currency === 'INR' ||
                                          data?.vendor?.currency === 'QAR' ||
                                          data?.vendor?.currency === 'AED'
                                        ? convertAmountToWords(data?.total, data?.vendor?.currency)
                                        : `${numberToWords(data?.total)} Only.`}
                                    </td>
                                    <td colSpan={2} className='uppercase pt-5 text-black'>
                                      Total Amount
                                    </td>
                                    <td colSpan={2} className='text-black'>
                                      <div className='d-flex justify-between pt-2'>
                                        <span className='text-start'>{data?.vendor?.currency}</span>
                                        {/* </td> */}
                                        {/* <td className='text-end fw-bold'> */}
                                        <span className='text-end'>
                                          {data?.total?.toLocaleString('en-US', {
                                            minimumFractionDigits: 2,
                                          })}{' '}
                                        </span>
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              )}
                            </tbody>
                          </table>
                        )}
                      </div>
                      {/*end::Table*/}
                      {/*begin::Container*/}
                      {/*end::Container*/}
                    </div>
                    {/*end::Content*/}
                  </div>

                  {/* end::Wrapper */}
                </div>
                {/* end::Order 2 content */}
              </div>
            )}
            {/* end::Content */}
            {/* begin::Sidebar */}
            <div className={`m-0 ${idx !== page + extraPages - 2 && 'h-100'}`}>
              {/* begin::Invoice 2 sidebar */}
              {/* d-print-none */}
              <div className='p-4 pt-0 bg-lighten h-100'>
                {/* begin::Labels */}

                {((page === 2 && idx === 0) || (page !== 2 && idx + 1 === page + extraPages)) && (
                  <div className={`m-0 p-2 mt-5`}>
                    <div className='d-flex  flex-column fs-7'>
                      <h6 className=' fw-bold text-danger'>Note:</h6>
                      <p
                        className='min-h-40px text-black'
                        style={{
                          lineHeight: '8px',
                        }}
                      >
                        {data?.notes?.split('\n').map((line, index) => (
                          <p key={index}>{line}</p>
                        ))}
                      </p>
                    </div>
                  </div>
                )}
                {idx + 1 >= page && (
                  <div className={`m-0 py-2 ${idx !== page + extraPages - 2 && 'h-100'}`}>
                    <div className='d-flex flex-column h-100'>
                      {idx === page - 1 && (
                        <>
                          <h6 className='fw-semibold text-center text-black'>Annexure (1)</h6>
                          <p
                            className='min-h-40px text-justify fs-7 mb-8 text-black'
                            style={{lineHeight: '15px'}}
                          >
                            {data?.termsCondition?.split('\n').map((line, index) => {
                              const [firstSentence, secondSentence] = line.split(':')
                              return (
                                <div
                                  key={index}
                                  style={{display: 'flex', width: '100%', marginBottom: '5px'}}
                                >
                                  <span
                                    style={{
                                      width: '100px',
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      textTransform: 'uppercase',
                                    }}
                                    className='fw-semibold mb-2'
                                  >
                                    {firstSentence}
                                  </span>
                                  <span style={{flex: 1, marginLeft: '5px'}}>
                                    :{secondSentence}
                                  </span>
                                </div>
                              )
                            })}
                          </p>
                        </>
                      )}
                      <p
                        className='min-h-40px text-justify fs-7 f-100 text-black'
                        style={{
                          lineHeight: '15px',
                          display: 'flex',
                          flexDirection: 'column',
                          flex: 1,
                          justifyContent: 'space-around',
                        }}
                      >
                        {annexure
                          ?.slice(
                            findSplitPoint(3100 * (idx - page + 1)),
                            findSplitPoint(3100 * (idx - page + 2))
                          )
                          ?.split('\n')
                          .map((line, index) => (
                            <div
                              key={index}
                              style={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                marginTop: line.trim().startsWith('•') ? '10px' : '0px',
                              }}
                            >
                              {line.trim().startsWith('•') && (
                                <span style={{width: '20px', display: 'inline-block'}}>•</span>
                              )}
                              <span
                                style={{
                                  marginLeft: line.trim().startsWith('•') ? '5px' : '25px',
                                  flex: 1,
                                  marginBottom: line.trim().startsWith('◦') ? '0px' : '5px',
                                }}
                              >
                                {line.trim().startsWith('•')
                                  ? line.slice(1).trim()
                                  : subBullerPoint(line)}
                              </span>
                            </div>
                          ))}
                      </p>
                    </div>
                  </div>
                )}
                {idx + 1 === (extraPages === 0 ? page : page + extraPages - 1) && (
                  <div className={`m-0 pt-8 px-10 py-5 `}>
                    <div className='d-flex  justify-content-between'>
                      <h6 className='mb-8 text-black text-center fw-bold fs-7'>
                        <span className='fs-8 arabic-font fw-normal'>أُعدت بواسطة</span>
                        <br />
                        Prepared By
                        {data?.agent && data?.agent?.signature && data?.agent?.signature !== '' && (
                          <img
                            src={PF + data?.agent?.signature}
                            alt='signature'
                            className='w-80px h-80px mt-3'
                          />
                        )}
                      </h6>

                      {checkedBy && (
                        <h6 className='mb-4 text-black relative text-center fw-bold fs-7'>
                          <span className='fs-8 arabic-font fw-normal'>تم التحقق من قبل</span>
                          <br />
                          Verified By
                        </h6>
                      )}

                      <h6 className='mb-4 text-black relative text-center fw-bold fs-7'>
                        <span className='fs-8 arabic-font fw-normal'>اعتمدت بواسة</span>
                        <br />
                        Approved By
                        {data?.approval === 'accepted' &&
                          data?.company?.companySeal &&
                          data?.company?.companySeal !== '' && (
                            <img
                              src={PF + data?.company?.companySeal}
                              alt='company seal'
                              className='w-80px mt-3 h-80px aboslute top-5 right-5 z-1'
                            />
                          )}
                        {data?.approval === 'accepted' &&
                          data?.company?.companySignature &&
                          data?.company?.companySignature !== '' && (
                            <img
                              src={PF + data?.company?.companySignature}
                              alt='company signature'
                              className='w-80px h-80px absolute top-20 right-10 z-10'
                            />
                          )}
                      </h6>

                      <h6 className='mb-4 text-black text-center fs-7 fw-bold'>
                        <span className='fs-8 arabic-font fw-normal'>استلمت من قبل</span>
                        <br />
                        Received By
                      </h6>
                    </div>
                    {/* end::Invoice 2 sidebar */}
                  </div>
                )}
                {/* end::Item */}
                {/* begin::Item */}

                {/* end::Item */}
                {/* begin::Title */}

                {/* end::Item */}
              </div>
              {/* end::Invoice 2 sidebar */}
            </div>
            {/* end::Sidebar */}
          </div>
          {/* end::Layout */}
        </div>
        {/* end::Body */}
        <div id='pdfFooter' className='card-footer text-center py-2 px-0 fw-bold mb-1'>
          <div className={`bg-[#515560] ${!minFooter && '-skew-x-12'} text-white`}>
            <div className={`${!minFooter && 'skew-x-12'} py-1`}>
              {!minFooter &&
                `Mobile :${' '}
        ${data?.company?.mobileNumber} | `}
              <MdOutlineMailOutline
                style={{
                  color,
                }}
                className='inline mr-2'
              />
              {data?.company?.companyEmail}
              <GoGlobe
                className='inline mr-2 ml-4'
                style={{
                  color,
                }}
              />
              {data?.company?.webURL}
            </div>
          </div>
          <div
            className={`${!minFooter && '-skew-x-12'} text-white`}
            style={{
              backgroundColor: `${color}`,
            }}
          >
            <div className={`${!minFooter && 'skew-x-12'} py-1`}>
              P.O. Box : {data?.company?.pOBox} | Address: {data?.company?.companyAddress}
            </div>
          </div>
        </div>
        <div className='d-flex justify-center'>
          <p className='fs-7 serif-font mb-1'>
            Page {idx + 1} of {extraPages === 0 ? page : page + extraPages - 1}
          </p>
        </div>
      </div>
    )
  )

  return (
    <>
      <div className='app-content flex-column-fluid'>
        {/* begin::Content container */}

        <div id='kt_app_content_container' className='print-content container-xxl serif-font'>
          {/* begin::Invoice 2 main */}
          {PageContent}
          {/* end::Invoice 2 main */}
        </div>
        {/* end::Content container */}
      </div>
    </>
  )
}

const subBullerPoint = (line) => {
  if (line?.indexOf('◦') === -1) return line
  const [firstSentence, secondSentence] = line.split('◦')
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        // marginBottom: '10px',
        // marginTop: line.trim().startsWith('•') ? '10px' : '0px',
      }}
    >
      <span
        style={{
          width: '12px', // Adjust this width as needed
          display: 'inline-block',
        }}
      >
        •
      </span>
      <span
        style={{
          flex: 1,
        }}
      >
        {secondSentence}
      </span>
    </div>
  )
}

// const subBullerPoint = (line) => {
//   if (line?.indexOf('◦') === -1) return line
//   const [firstSentence, secondSentence] = line.split('◦')
//   return (
//     <div>
//       <span>•</span>
//       <span>{secondSentence}</span>
//     </div>
//   )
// }

export default PurchaseOrder
