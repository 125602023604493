import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'
import {getTimesheetPayrollByOrg} from '../../projects/core/_requests'

function CategoryPayrolls() {
  const {currentUser} = useAuth()
  const [loading, setLoading] = useState(true)
  const [payrolls, setPayrolls] = useState([])

  useEffect(() => {
    const fetchpayrollList = async () => {
      const res = await getTimesheetPayrollByOrg(currentUser?.organization)
      setLoading(false)
      setPayrolls(res)
    }
    if (currentUser.organization !== undefined) fetchpayrollList()
  }, [])

  return (
    <div className='card card-flush'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Payroll</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        {loading ? (
          <div className='d-flex align-items-center justify-center'>
            <div className='loader'></div>
          </div>
        ) : (
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-4 table-row-bordered'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-25px rounded-start'>Sr. No.</th>
                  <th className='min-w-150px text-center'>Project</th>
                  <th className='min-w-150px text-center'>Total Payrolls</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {payrolls?.map((item: any, index) => (
                  <tr key={item?.projectId}>
                    <td>
                      <Link
                        to={`${item?.projectId}`}
                        className='ps-4 text-dark fw-bold text-hover-primary d-block mb-1 fs-6 capitalize'
                      >
                        {index + 1}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`${item?.projectId}`}
                        className='text-center text-dark fw-bold text-hover-primary d-block mb-1 fs-6 capitalize'
                      >
                        {item?.projectName}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`${item?.projectId}`}
                        className='text-center text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {item?.totalPayroll}
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
        )}
        {/* end::Table container */}
      </div>
      {/* begin::Body */}

      {/* Modal for add client */}
    </div>
  )
}

export default CategoryPayrolls
