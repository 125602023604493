import {useCallback} from 'react'
import * as XLSX from 'xlsx'

const useExcelDownload = () => {
  const handleDownloadExcel = useCallback(async (data, filename = 'download.xlsx', setLoading) => {
    if (data?.length < 1) return

    setLoading(true)
    const ws = XLSX.utils.json_to_sheet(data)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Sales by Customer')
    const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'})

    const blob = new Blob([excelBuffer], {type: 'application/octet-stream'})

    // Create download link
    const downloadLink = document.createElement('a')
    downloadLink.href = URL.createObjectURL(blob)
    downloadLink.setAttribute('download', filename)

    // Trigger download
    downloadLink.click()
    setLoading(false)
  }, [])

  return handleDownloadExcel
}

export default useExcelDownload
