export interface SearchFilter {
  search?: string
  agent?: string
  filter?: Filtertype
}
export interface Filtertype {
  isActive?: boolean
  dept?: string;
  role?: string;
  contract?: string;
}

export const initialSearchFilter: SearchFilter = {
  search: '',
  agent: '',
  filter: {
    isActive: true,
    dept:'',
    role: '',
    contract: ''
  },
}
