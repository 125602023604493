import React, {useEffect, useRef, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {KTIcon} from '../../../../_metronic/helpers'
import {
  bulkUploadEmployees,
  deactivateEmployee,
  getAllDepartmentWithRoles,
  getEmployeeByStatus,
} from '../core/_requests'
import profile from '../../../../_metronic/assets/profile.png'
import {
  StatisticsWidget5,
  StatisticsWidget6,
  StatisticsWidget7,
} from '../../../../_metronic/partials/widgets'
import {Filtertype, SearchFilter, initialSearchFilter} from '../core/_model'
import Filter from '../Components/Filter'
import qs from 'qs'
import Search from '../Components/Search'
import {useAuth} from '../../../modules/auth'
import {useSelector, useDispatch} from 'react-redux'
import {setEmployeeData} from '../../../redux/hrm/employee/employeeSlice'
import {RiArrowDropDownLine} from 'react-icons/ri'
import useExcelImport from '../../account/components/useExcelImport'
import {Spinner} from 'react-bootstrap'

const EmployeeWrapper: React.FC = () => {
  const {currentUser} = useAuth()
  const excelfileRef = useRef<HTMLInputElement>(null)
  const [permissions, setPermissions] = useState<any>({})
  // const [employeeData, setEmployeeData] = useState<Array<any>>([])
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [selectedIndex, setSelectedIndex] = useState<any>(null)
  const [deptOption, setDeptOption] = useState([])
  const [dropdown, setDropdown] = useState([])
  const [excelImportLoading, setExcelImportLoading] = useState(false)
  const handleUploadExcel = useExcelImport()
  const dispatch = useDispatch()
  const employeeData = useSelector((state: any) => state.hrm.employee.employeeData)
  const [departmentData, setDepartmentData] = useState([])

  const fetchDeptData = async () => {
    const res = await getAllDepartmentWithRoles(currentUser?.organization)
    setDepartmentData(res)
    const updatedDeptOption = res.map((data) => ({
      value: data._id,
      label: data.name,
    }))
    setDeptOption(updatedDeptOption)
  }

  useEffect(() => {
    if (currentUser) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const handleDropdownClick = (index) => {
    const newDropdownState = dropdown.map((_, i) => (i === index ? !dropdown[i] : false))
    setDropdown(newDropdownState)
  }

  const navigate = useNavigate()
  const handleClick = () => {
    navigate('/hrm/employee/newMember')
  }

  let searchFilters: SearchFilter = initialSearchFilter

  const setSearchFilter = (updateValues: {
    search?: string
    agent?: string
    filter?: Partial<Filtertype>
  }): void => {
    searchFilters = {
      ...searchFilters,
      ...(updateValues.search && {search: updateValues.search}),
      ...(updateValues.agent && {agent: updateValues.agent}),
      ...(updateValues.filter && {
        filter: {
          ...searchFilters.filter,
          ...updateValues.filter,
        },
      }),
    }
  }

  const setStatusFilter = (isActive: boolean) => {
    setSearchFilter({
      filter: {
        isActive,
      },
    })
  }

  const handleDeleteToggle = (i) => {
    setSelectedIndex(i)
  }

  const handleDelete = async (i) => {
    try {
      await deactivateEmployee(i)
      getEmployeelist()
    } catch (error) {
      console.error('Error deleting employee:', error)
    }
  }

  const stringifyObject = (obj: SearchFilter): string => {
    const queryString = qs.stringify(
      {
        search: obj.search,
        agent: obj.agent,
        filter_status: obj.filter.isActive,
        filter_dept: obj.filter.dept,
        filter_role: obj.filter.role,
        filter_contract: obj.filter.contract,
      },
      {encodeValuesOnly: true, skipNulls: true, delimiter: '&'}
    )
    return queryString
  }

  const getEmployeelist = async () => {
    setSearchFilter({
      search: searchTerm,
    })

    try {
      const queryString = stringifyObject(searchFilters)
      const res = await getEmployeeByStatus(queryString, currentUser?.organization)
      const sortedData = res.sort((a: any, b: any) => {
        const dateA: any = new Date(a.createdAt)
        const dateB: any = new Date(b.createdAt)
        return dateB - dateA
      })
      // setEmployeeData(sortedData)
      dispatch(setEmployeeData(sortedData))
      setDropdown(Array(sortedData?.length)?.fill(false))
    } catch (error) {
      console.error('Error fetching Customer list:', error)
    }
  }
  useEffect(() => {
    getEmployeelist()
  }, [searchTerm])

  useEffect(() => {
    fetchDeptData()
  }, [])

  const handlereset = async () => {
    setSearchFilter(initialSearchFilter)
    getEmployeelist()
  }

  return (
    <>
      <div className='d-md-flex g-5 g-xl-8 mb-5 mb-xl-8 gap-5 px-8 justify-content-center'></div>

      <div className='card'>
        <span className=' card-label fw-bold fs-3 ps-15 pt-8 '>Employee List</span>
        <div className='card-header border-0 pt-5 '>
          <div className=' d-flex flex-row  card-title align-items-start'>
            <h3 className='d-flex flex-row card-title align-items-start '></h3>
            <Search searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
            <Filter
              searchFilters={searchFilters}
              setSearchFilter={setSearchFilter}
              handlereset={handlereset}
              getDocsList={getEmployeelist}
              setStatusFilter={setStatusFilter}
              showDeptFilter={true}
              deptOptions={deptOption}
              showContractFilter={true}
              departmentList={departmentData}
            />
          </div>
          {(currentUser?.hierarchy === 1 || currentUser?.profileType === 'superadmin') && (
            <div className='!ml-auto !mr-3 card-toolbar'>
              <button
                className='btn btn-danger btn-sm'
                onClick={() => {
                  excelfileRef.current.click()
                }}
              >
                {excelImportLoading ? (
                  <>
                    <Spinner animation='border' role='status' />
                  </>
                ) : (
                  'Import Employees'
                )}
              </button>
              <input
                type='file'
                accept='.xlsx, .xls'
                onChange={(e) =>
                  handleUploadExcel(e, bulkUploadEmployees, setExcelImportLoading, 'employees')
                }
                ref={excelfileRef}
                className='hidden'
              />
            </div>
          )}
          {(permissions?.employee?.create || currentUser?.profileType === 'superadmin') && (
            <div className='card-toolbar'>
              <div onClick={handleClick} className='btn btn-sm btn-light-primary'>
                <KTIcon iconName='plus' className='fs-2' />
                New Employee
              </div>
            </div>
          )}
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        {/* <div className='d-flex px-12 justify-content-between '>
        <div className='col-2 card bg-light-success  justify-content-around align text-center align-items-center py-2 '>
          <div className='d-flex align-items-center '>
            <KTIcon iconName='check-circle' className='text-success opacity-100' />
            <div className='badge badge-icon'>Present</div>
          </div>
          <div className='text-success fw-bold fs-3 card-label'>480/500</div>
        </div>
        <div className='col-2 card bg-light-warning justify-content-center  text-center items-center'>
          <div className='d-flex items-center'>
            <KTIcon iconName='check-circle' className='text-warning opacity-100 ' />
            <div className='badge badge-icon'>Absent</div>
          </div>
          <div className='text-warning fw-bold fs-3 card-label'>480/500</div>
        </div>
        <div className='col-2  card bg-light-danger justify-content-center text-center items-center '>
          <div className='d-flex items-center'>
            <KTIcon iconName='cross-circle' className='text-danger opacity-100 ' />
            <div className='badge badge-icon'>Absent</div>
          </div>
          <div className='text-danger fw-bold fs-3 card-label'>480/500</div>
        </div>
        <div className='col-2  card bg-light-primary justify-content-center text-center items-center '>
          <div className='d-flex items-center'>
            <KTIcon iconName='geolocation-home' className='text-primary opacity-100 ' />
            <div className='badge badge-icon'>Onsite</div>
          </div>
          <div className='text-primary fw-bold fs-3 card-label'>480/500</div>
        </div>
        <div className='col-2 card bg-light-info justify-content-center text-center items-center '>
          <div className='d-flex items-center'>
            <KTIcon iconName='check-circle' className='text-info opacity-100 ' />
            <div className='badge badge-icon'>Remote</div>
          </div>
          <div className='text-info fw-bold fs-3 card-label'>480/500</div>
        </div>
      </div> */}
        <div className='card-body py-3 '>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-200px rounded-start'>Employees</th>
                  <th className='min-w-125px'>Department</th>
                  <th className='min-w-125px'>Roles</th>
                  <th className='min-w-125px'>Employee Id</th>
                  <th className='min-w-150px'>Contract</th>
                  <th className='min-w-100px'>Status</th>
                  {(permissions?.employee?.update || currentUser?.profileType === 'superadmin') && (
                    <th className='min-w-100px text-center'>Action</th>
                  )}
                  {/* <th className='min-w-200px text-end rounded-end'></th> */}
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {employeeData &&
                  employeeData
                    // .filter((item) => item.isActivated === true)
                    .map((item, i) => (
                      <tr key={i}>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='symbol symbol-50px me-5'>
                              <img src={`${profile}`} className='img-fluid rounded-circle' alt='' />
                            </div>
                            <div className='d-flex justify-content-start flex-column'>
                              <Link
                                to={`view-employee/${item._id}`}
                                className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                              >
                                {item.firstName} {item.lastName}
                              </Link>
                              {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        HTML, JS, ReactJS
                      </span> */}
                            </div>
                          </div>
                        </td>
                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                          >
                            {item?.department?.name}
                          </a>
                          {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>Paid</span> */}
                        </td>
                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                          >
                            {item?.role}
                          </a>
                          {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>Paid</span> */}
                        </td>
                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                          >
                            {item.employeeId}
                          </a>
                          {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>Rejected</span> */}
                        </td>
                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 capitalize'
                          >
                            {item.contractType}
                          </a>
                          {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>Insurance</span> */}
                        </td>
                        <td>
                          <span className='badge badge-light-primary fs-7 fw-semibold'>
                            {item.employeeStatus}
                          </span>
                        </td>
                        {(permissions?.employee?.update ||
                          currentUser?.profileType === 'superadmin') && (
                          <td className='relative'>
                            <div className='d-flex justify-content-center flex-shrink-0'>
                              <button
                                onClick={() => handleDropdownClick(i)}
                                className=' bg-gray-100 hover:bg-lime-50 px-6 py-2 flex items-center rounded self-end'
                              >
                                Actions <RiArrowDropDownLine />
                              </button>
                            </div>
                            {dropdown[i] && (
                              <div className='absolute flex flex-col top-16 right-[0px] z-50  bg-white  w-40 shadow-[0_16px_16px_6px_rgba(128,128,128,0.1)] rounded p-3 space-y-2'>
                                <Link to={`edit-employee/${item._id}`}>
                                  <button className='hover:bg-lime-50 p-2  text-start rounded'>
                                    Edit
                                  </button>
                                </Link>
                                <button
                                  onClick={() => handleDeleteToggle(item._id)}
                                  data-bs-toggle='modal'
                                  data-bs-target='#myModal'
                                  className='hover:bg-lime-50 p-2 text-start rounded'
                                  data-kt-users-table-filter='delete_row'
                                >
                                  {item?.isActivated ? 'Invalid' : 'Valid'}
                                </button>
                              </div>
                            )}
                          </td>
                        )}
                        {/* <td className='text-end'>
                  <a
                    href='#'
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                  >
                    <KTIcon iconName='switch' className='fs-3' />
                  </a>
                  <a
                    href='#'
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                  >
                    <KTIcon iconName='pencil' className='fs-3' />
                  </a>
                  <a href='#' className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'>
                    <KTIcon iconName='trash' className='fs-3' />
                  </a>
                </td> */}
                      </tr>
                    ))}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>

        <div className='modal' id='myModal'>
          <div className='modal-dialog modal-dialog-centered '>
            <div className='modal-content'>
              <div className='modal-header'>
                <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
              </div>

              <div className='modal-body text-center fs-6'>
                Are you sure you want to {''}
                {employeeData.find((employee) => employee._id === selectedIndex)?.isActivated
                  ? 'Invalidate'
                  : 'Validate'}{' '}
                {employeeData.find((employee) => employee._id === selectedIndex)?.firstName} ?
              </div>

              <div className='modal-footer text-center mx-auto'>
                <button
                  type='button'
                  className='btn btn-danger '
                  data-bs-dismiss='modal'
                  onClick={() => handleDelete(selectedIndex)}
                >
                  {employeeData.find((employee) => employee._id === selectedIndex)?.isActivated
                    ? 'Invalid'
                    : 'Valid'}
                </button>
                <button
                  type='button'
                  className='btn btn-light '
                  data-bs-dismiss='modal'
                  id='closeModalButtondelete'
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {EmployeeWrapper}
