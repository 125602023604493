/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon} from '../../../helpers'

type Props = {
  className: string
  color: string
  svgIcon?: string
  iconColor?: string
  header: string
  title: string
  titleColor?: string
  badgeColor?: string
  description?: string
  descriptionColor?: string
  borderColor?: string
}

const StatisticsWidget7: React.FC<Props> = ({
  className,
  color,
  svgIcon,
  iconColor,
  header,
  title,
  titleColor,
  badgeColor,
  description,
  descriptionColor,
  borderColor,
}) => {
  return (
    <a href='#' className={`card bg-${color} hoverable ${className} border-${borderColor}`}>
      <div className='card-body'>
        <div className={`text-${titleColor} fw-bold fs-3`}>{header}</div>
        <div className='separator separator-dashed my-3 border-gray-700' />
        <div className={`text-${titleColor} fw-bold fs-2 mb-2 mt-5`}>{title}</div>
        {/* <div className="d-flex">
          <div className={`badge badge-light-${badgeColor} rounded me-2 fw-thin`}>10,25%</div>
          <div className={`fw-thin fs-7 text-muted text-${descriptionColor}`}>{description}</div>
        </div> */}
      </div>
    </a>
  )
}

export {StatisticsWidget7}
