import React, {useCallback, useEffect, useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'
import {KTIcon} from '../../../../_metronic/helpers'
import {bulkUploadJournalEntries, getJournalsWithDate} from '../core/_requests'
import {useSelector, useDispatch} from 'react-redux'
import {
  setDateRange,
  setJournalEntryData,
  setLoading,
} from '../../../redux/accounts/accountant/journalEntry/journalEntrySlice'
import {RootState} from '../../../redux/store'
import DateFilter from '../components/DateFilter'
import debounce from 'lodash/debounce'
import useExcelImport from '../components/useExcelImport'
import {Spinner} from 'react-bootstrap'

function JournalPage() {
  const {currentUser} = useAuth()
  const excelfileRef = useRef<HTMLInputElement>(null)
  const [permissions, setPermissions] = useState<any>({})
  const initialRef = useRef(true)
  const dispatch = useDispatch()
  const journalList = useSelector((state: RootState) => state.account.journalEntry.journalEntryData)
  const dateRange = useSelector((state: RootState) => state.account.journalEntry.dateState)
  const loading = useSelector((state: RootState) => state.account.journalEntry.loading)
  const [searchquery, setSearchquery] = useState('')
  const [dateState, setDateState] = useState<any>(dateRange)
  const [excelImportLoading, setExcelImportLoading] = useState(false)
  const handleUploadExcel = useExcelImport()

  useEffect(() => {
    if (currentUser) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const fetchJournalList = useCallback(async () => {
    dispatch(setLoading())
    try {
      const res = await getJournalsWithDate(
        currentUser?.organization,
        dateState.startDate,
        dateState.endDate,
        searchquery
      )
      dispatch(setJournalEntryData(res))
      // dispatch(
      //   setDateRange({
      //     startDate: new Date(dateState.startDate).toISOString(),
      //     endDate: new Date(dateState.endDate).toISOString(),
      //   })
      // )
    } catch (error) {
      console.log(error)
    }
  }, [currentUser?.organization, dispatch, dateState, searchquery])

  useEffect(() => {
    if (loading) fetchJournalList()
  }, [currentUser.organization])

  const debounceSearch = debounce(fetchJournalList, 500)

  useEffect(() => {
    if (initialRef.current) {
      initialRef.current = false
      return
    }

    if (dateState.startDate && dateState.endDate) {
      debounceSearch()
    }

    return () => debounceSearch?.cancel()
  }, [dateState.endDate, fetchJournalList, searchquery])

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {})
    return formattedDate
  }

  return (
    <div className='card card-flush'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-center d-flex gap-4'>
          <span className='card-label fw-bold fs-3 mb-1 whitespace-nowrap'>Manual Journals</span>
          <input
            type='text'
            className='form-control form-control-solid me-3'
            value={searchquery}
            placeholder='Search Reference'
            onChange={(e) => setSearchquery(e.target.value)}
          />
          <DateFilter dateState={dateState} setDateState={setDateState} />
        </h3>

        {(currentUser?.hierarchy === 1 || currentUser?.profileType === 'superadmin') && (
          <div className='!ml-auto !mr-3 card-toolbar'>
            <button
              className='btn btn-danger btn-sm'
              onClick={() => {
                excelfileRef.current.click()
              }}
            >
              {excelImportLoading ? (
                <>
                  <Spinner animation='border' role='status' />
                </>
              ) : (
                'Import Journal Entries'
              )}
            </button>
            <input
              type='file'
              accept='.xlsx, .xls'
              onChange={(e) =>
                handleUploadExcel(
                  e,
                  bulkUploadJournalEntries,
                  setExcelImportLoading,
                  'journal entries'
                )
              }
              ref={excelfileRef}
              className='hidden'
            />
          </div>
        )}

        {(currentUser?.profileType === 'superadmin' || permissions?.journal?.create) && (
          <div className='card-toolbar'>
            <Link to={'/account/journals/add'} className='btn btn-sm btn-light-primary'>
              <KTIcon iconName='plus' className='fs-2' />
              Add Journal
            </Link>
          </div>
        )}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        {loading ? (
          <div className='d-flex align-items-center justify-content-center'>
            <div className='loader'></div>
          </div>
        ) : (
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-4 table-row-bordered'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-50px rounded-start'>Date</th>
                  <th className='min-w-150px'>Journal#</th>
                  <th className='min-w-150px'>Reference</th>
                  <th className='min-w-100px'>Amount</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {journalList?.map((item: any, index) => (
                  <tr key={item?._id?.toString()}>
                    <td>
                      <Link
                        to={`${item?._id}`}
                        className='ps-4 text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {formatCreatedAt(item?.date)}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`${item?._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 capitalize'
                      >
                        {item?.id}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`${item?._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {item?.reference}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`${item?._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {item?.subtotal.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                        })}
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
        )}
        {/* end::Table container */}
      </div>
      {/* begin::Body */}

      {/* Modal for add client */}
    </div>
  )
}

export default JournalPage
