import {ErrorMessage, Field, useFormikContext} from 'formik'
import {FC, useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers/AssetHelpers'
import {KTIcon} from '../../../../../_metronic/helpers/components/KTIcon'
import {useParams} from 'react-router-dom'
import {getProjectById} from '../../core/_requests'
import {ICreateAccount} from '../AddProjectHelper'

const AddBudget: FC = () => {
  const {setFieldValue, values} = useFormikContext<ICreateAccount>()
  console.log('values', values)
  const {projectId} = useParams()
  const [projectData, setProjectData] = useState({
    projectType: '',
    customer: '',
    projectName: '',
    progress: 0,
    projectDescription: '',
    startDate: ' ',
    releaseDate: '',
    notification: {
      email: false,
      phone: false,
    },
    budgetAmount: 0,
    changeInBudget: false,
    status: 'In progress',
    team: [],
    target: [],
    isDeleted: false,
  })

  const handleChange = () => {}

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const response = await getProjectById(projectId)
        setProjectData(response.data) // Assuming the response contains the project data
      } catch (error) {
        console.error('Error fetching project data:', error)
      }
    }

    fetchProjectData()
  }, [projectId])

  const handleDateChange = (date, form) => {
    // Update the form values with the new date
    form.setFieldValue('releaseDate', date)
  }

  const handleFormikChange = (e, setFieldValue) => {
    const {name, value} = e.target
    const rawValue = value.replace(/,/g, '') // Remove commas before storing the value

    setFieldValue(name, rawValue) // Update Formik's field with the raw value
  }

  const formatNumber = (num) => {
    if (!num) return ''
    return Number(num).toLocaleString('en-US')
  }

  const handleBlur = (e, setFieldValue) => {
    const {name, value} = e.target
    const rawValue = value.replace(/,/g, '') // Ensure raw value is stored
    setFieldValue(name, rawValue)
  }

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>Budget Details</h2>

        {/* <div className='text-gray-400 fw-bold fs-6'>
          If you need more info, please check out
          <a href='/dashboard' className='text-primary fw-bolder'>
            {' '}
            Help Page
          </a>
          .
        </div> */}
      </div>

      <div className='d-flex flex-column mb-7 fv-row'>
        <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
          <span className='required'>Budget Amount</span>
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Specify a  Budget Amount'
          ></i>
        </label>

        {/* <Field
          type='number'
          className='form-control form-control-solid'
          placeholder=''
          name='budgetAmount'
          // value={projectData.budgetAmount}
        /> */}
        <Field
          type='text' // Change to text to allow formatted numbers
          className='form-control form-control-solid'
          placeholder=''
          name='budgetAmount'
          value={formatNumber(values.budgetAmount)} // Format the budgetAmount for display
          onChange={(e) => handleFormikChange(e, setFieldValue)}
          onBlur={(e) => handleBlur(e, setFieldValue)}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='budgetAmount' />
        </div>
      </div>

      <div className='d-flex flex-column mb-7 fv-row'>
        <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
          <span>Budget Notes</span>
        </label>

        <Field
          as='textarea'
          className='form-control form-control-solid'
          placeholder=''
          name='budgetNotes'
          rows={3}
          // value={projectData.budgetAmount}
        />
      </div>

      {/* <div className='d-flex flex-stack'>
        <div className='d-flex flex-stack mb-15'>
          <div className='me-5 fw-semibold'>
            <label className='fs-6'>Allow changes in Budget</label>
            <div className='fs-7 text-muted'>
              If you need more info, please check budget planning
            </div>
          </div>

          <label className='form-check form-switch form-check-custom form-check-solid'>
            <input
              className='form-check-input'
              type='checkbox'
              name='changeInBudget'
              // checked={values.changeInBudget}
              onChange={() => setFieldValue('changeInBudget', !values.changeInBudget)}
            />

            <span className='form-check-label fw-bold text-gray-400'>Allowed</span>
          </label>
        </div>
      </div> */}
    </div>
  )
}

export {AddBudget}
