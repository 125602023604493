import React, {useEffect, useState} from 'react'
import {useAuth} from '../../../modules/auth'
import {
  getEquipmentsList,
  getIssuesByProductId,
  getVehiclesList,
  getWorkOrderById,
  editWorkOrder,
} from '../core/_requests'
import Select from 'react-select'
import Datetime from 'react-datetime'
import 'react-datetime/css/react-datetime.css'
import {getVendor} from '../../purchaseOrders/Core/_requests'
import {useNavigate, useParams} from 'react-router-dom'
import {getEmployeeByStatus} from '../../hrm/core/_requests'

const EditWorkOrder = () => {
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const {id} = useParams()
  const [loading, setLoading] = useState<boolean>(false)
  const [issuesList, setIssuesList] = useState<any>([])
  const [selectedIssues, setSelectedIssues] = useState<any>([])
  const [vendorList, setVendorList] = useState<any>([])
  const [products, setProducts] = useState<any>([])
  const [productType, setProductType] = useState<any>([])
  const [isVehicle, setIsVehicle] = useState<any>(true)
  const [employees, setEmployees] = useState<any>([])
  const [prevOdoMeter, setPrevOdoMeter] = useState<any>({
    value: 0,
    date: new Date(),
  })
  const [formData, setFormData] = useState({
    rentalType: 'vehicle',
    product: '',
    status: 'open',
    priority: 'scheduled',
    issueDate: new Date(),
    scheduledStartDate: new Date(),
    startDate: null,
    expectedCompletionDate: new Date(),
    completionDate: new Date(),
    vendor: null,
    assignedTo: null,
    invoiceNo: '',
    poNo: '',
    comments: '',
    notes: '',
    odometer: 0,
    laborCost: 0,
    partsCost: 0,
    subtotal: 0,
    tax: 0,
    discount: 0,
    total: 0,
  })

  const fetchData = async () => {
    const res = await getWorkOrderById(id)
    console.log(res)
    setFormData(() => {
      return {
        ...res,
        issueDate: new Date(res.issueDate),
        scheduledStartDate: new Date(res.scheduledStartDate) || new Date(),
        startDate: new Date(res.startDate) || new Date(),
        expectedCompletionDate: new Date(res.expectedCompletionDate) || new Date(),
        completionDate: new Date(res.completionDate) || new Date(),
        assignedTo: res.assignedTo?._id,
        vendor: res.vendor?._id,
        product: res.item?._id,
      }
    })
    setSelectedIssues(() => {
      return res?.issues?.map((issue: any) => issue._id)
    })
    setIsVehicle(res?.item?.rentalType === 'vehicle')
  }

  useEffect(() => {
    if (id !== undefined) fetchData()
  }, [id])

  const fetchProducts = async () => {
    if (formData?.rentalType === 'vehicle') {
      const res = await getVehiclesList(currentUser?.organization)
      console.log(res)
      const data = res?.map((item: any) => ({
        label: item.productName,
        value: item._id,
      }))
      setProducts(data)
      const data1 = res?.map((product) => {
        return {
          label: product._id,
          rentalType: product?.rentalType,
          odometerReading: product?.odometerReading,
          odometerReadingDate: product?.odometerReadingDate,
        }
      })
      setProductType(data1)
      setFormData({...formData, product: data[0]?.value})
    } else {
      const res = await getEquipmentsList(currentUser?.organization)
      console.log(res)
      const data = res?.map((item: any) => ({
        label: item.productName,
        value: item._id,
      }))
      setProducts(data)
      setFormData({...formData, product: data[0]?.value})
    }
  }

  const fetchEmployeeList = async () => {
    const res = await getEmployeeByStatus('filter_status=true', currentUser?.organization)
    const data = res?.map((item: any) => ({
      label: item.firstName + ' ' + item.lastName,
      value: item._id,
    }))
    setEmployees(data)
  }

  const fetchVendors = async () => {
    const res = await getVendor('', currentUser?.organization)
    console.log(res)
    const data = res?.map((item: any) => ({
      label: item.displayName,
      value: item._id,
    }))
    setVendorList(data)
  }

  useEffect(() => {
    fetchVendors()
    fetchEmployeeList()
  }, [])

  useEffect(() => {
    fetchProducts()
  }, [formData.rentalType])

  const fetchIssuesByProduct = async () => {
    const res = await getIssuesByProductId(formData.product)
    console.log(res)
    setIssuesList(res)
  }

  const formatDaysAgo = (date: Date) => {
    const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000)
    let interval = Math.floor(seconds / 31536000)

    if (interval > 1) {
      return `${interval} years ago`
    }
    interval = Math.floor(seconds / 2592000)
    if (interval > 1) {
      return `${interval} months ago`
    }
    interval = Math.floor(seconds / 86400)
    if (interval > 1) {
      return `${interval} days ago`
    }
    interval = Math.floor(seconds / 3600)
    if (interval > 1) {
      return `${interval} hours ago`
    }
    interval = Math.floor(seconds / 60)
    if (interval > 1) {
      return `${interval} minutes ago`
    }
    return `${Math.floor(seconds) || 0} seconds ago`
  }

  useEffect(() => {
    if (formData.product !== '') {
      fetchIssuesByProduct()
    }
    // setSelectedIssues([])
    const selectedproduct = productType.find((item: any) => item.label === formData.product)
    if (selectedproduct) {
      setPrevOdoMeter({
        value: selectedproduct?.odometerReading || 0,
        date: new Date(selectedproduct?.odometerReadingDate),
      })
      // setFormData({...formData, odometer: 0})
    }
  }, [formData.product])

  useEffect(() => {
    // discount and tax are in percentage
    const subtotal = formData.laborCost + formData.partsCost
    const discount = (subtotal * formData.discount) / 100
    const tax = (subtotal * formData.tax) / 100
    const total = subtotal + tax - discount
    setFormData({...formData, subtotal, total})
  }, [formData.laborCost, formData.partsCost, formData.tax, formData.discount])

  const handleEdit = async (e) => {
    e.preventDefault()
    if (selectedIssues.length === 0) {
      alert('Please select at least one issue')
      return
    }
    if (isVehicle && formData.odometer <= 0) {
      alert('Please enter a valid odometer reading')
      return
    }
    setLoading(true)
    const data = {
      ...formData,
    }
    console.log(data)
    const res = await editWorkOrder(data, id)
    if (res) {
      navigate('/maintenance/work-order')
    }
  }

  return (
    <div className='container'>
      <div className='card'>
        <div className='card-header d-flex justify-between align-items-center'>
          <h4>Edit Work Order</h4>
        </div>
        <div className='card-body'>
          <label className='form-label'>Rental Type</label>
          <select
            className='form-select'
            value={formData.rentalType}
            onChange={(e) => {
              setFormData({...formData, rentalType: e.target.value})
              setIsVehicle(e.target.value === 'vehicle')
            }}
          >
            <option value='vehicle'>Vehicle</option>
            <option value='equipment'>Equipment</option>
          </select>
          <label className='form-label mt-3 required'>Product</label>
          <Select
            options={products}
            value={products.find((item: any) => item.value === formData.product)}
            onChange={(e) => setFormData({...formData, product: e.value})}
          />
          <label className='form-label mt-3'>Status</label>
          <select
            className='form-select'
            value={formData.status}
            onChange={(e) => setFormData({...formData, status: e.target.value})}
          >
            <option value='open'>Open</option>
            <option value='pending'>Pending</option>
            <option value='completed'>Completed</option>
          </select>
          <label className='form-label mt-3'>Repair Priority Class</label>
          <select
            className='form-select'
            value={formData.priority}
            onChange={(e) => setFormData({...formData, priority: e.target.value})}
          >
            <option value='scheduled'>Scheduled</option>
            <option value='non-scheduled'>Non-scheduled</option>
            <option value='emergency'>Emergency</option>
          </select>
          {isVehicle && (
            <>
              <label className='form-label mt-3 required'>Start Odometer</label>
              <input
                type='number'
                className='form-control'
                value={formData.odometer}
                onChange={(e) => setFormData({...formData, odometer: +e.target.value})}
              />
              <div className=''>
                <span className='text-muted'>
                  Last updated: {prevOdoMeter?.value} mi ({formatDaysAgo(prevOdoMeter?.date)})
                </span>
              </div>
            </>
          )}
          <label className='form-label mt-3 required'>Issue Date</label>
          <Datetime
            value={formData.issueDate}
            dateFormat='DD/MM/YYYY'
            onChange={(e: any) => setFormData({...formData, issueDate: e.toDate()})}
          />
          <div className='row gx-10 mt-3'>
            <div className='col-6'>
              <label className='form-label '>Scheduled Start Date</label>
              <Datetime
                value={formData.scheduledStartDate}
                dateFormat='DD/MM/YYYY'
                onChange={(e: any) => setFormData({...formData, scheduledStartDate: e.toDate()})}
              />
            </div>
            <div className='col-6'>
              <label className='form-label '>Actual start Date</label>
              <Datetime
                value={formData.startDate}
                dateFormat='DD/MM/YYYY'
                onChange={(e: any) => setFormData({...formData, startDate: e.toDate()})}
              />
            </div>
          </div>
          <div className='row gx-10 mt-3'>
            <div className='col-6'>
              <label className='form-label'>Expected Completion Date</label>
              <Datetime
                value={formData.expectedCompletionDate}
                dateFormat='DD/MM/YYYY'
                onChange={(e: any) =>
                  setFormData({...formData, expectedCompletionDate: e.toDate()})
                }
              />
            </div>
            <div className='col-6'>
              <label className='form-label'>Actual Completion Date</label>
              <Datetime
                value={formData.completionDate}
                dateFormat='DD/MM/YYYY'
                onChange={(e: any) => setFormData({...formData, completionDate: e.toDate()})}
              />
            </div>
          </div>
          <label className='form-label mt-3'>Assigned To</label>
          <Select
            options={employees}
            value={employees.find((item: any) => item.value === formData.assignedTo)}
            onChange={(e) => setFormData({...formData, assignedTo: e.value})}
          />
          <label className='form-label mt-3'>Vendor</label>
          <Select
            options={vendorList}
            value={vendorList.find((item: any) => item.value === formData.vendor)}
            onChange={(e) => setFormData({...formData, vendor: e.value})}
          />
          <div className='row gx-10 mt-3'>
            <div className='col-6'>
              <label className='form-label'>Invoice Number</label>
              <input
                type='text'
                className='form-control'
                value={formData.invoiceNo}
                onChange={(e) => setFormData({...formData, invoiceNo: e.target.value})}
              />
            </div>
            <div className='col-6'>
              <label className='form-label'>PO Number</label>
              <input
                type='text'
                className='form-control'
                value={formData.poNo}
                onChange={(e) => setFormData({...formData, poNo: e.target.value})}
              />
            </div>
          </div>
          {/* <div className='card mt-3'>
          <div className='card-body'>
            <h5>Issues</h5>
            <table className='table table-striped'>
              <thead>
                <tr className='bg-primary text-white fw-semibold'>
                  <th className='ps-2'>
                    <input
                      type='checkbox'
                      className='form-check-input'
                      checked={selectedIssues.length === issuesList.length}
                      onChange={(e) =>
                        setSelectedIssues(
                          e.target.checked ? issuesList.map((issue: any) => issue._id) : []
                        )
                      }
                    />
                  </th>
                  <th>Priority</th>
                  <th>Issue</th>
                  <th>Summary</th>
                </tr>
              </thead>
              <tbody>
                {issuesList?.map((issue: any) => (
                  <tr key={issue._id}>
                    <td className='ps-2'>
                      <input
                        type='checkbox'
                        className='form-check-input'
                        checked={selectedIssues.includes(issue._id)}
                        onChange={(e) =>
                          setSelectedIssues(
                            e.target.checked
                              ? [...selectedIssues, issue._id]
                              : selectedIssues.filter((id: string) => id !== issue._id)
                          )
                        }
                      />
                    </td>
                    <td className='uppercase'>{issue?.priority}</td>
                    <td>#{issue.issueId}</td>
                    <td>{issue.summary}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div> */}
          <div className='card mt-3'>
            <div className='card-body d-flex gap-2 flex-column'>
              <h5>Cost Summary</h5>
              {/* labor parts subtotal discount tax total */}
              <div className='d-flex gap-2 justify-between'>
                <label className='form-label mt-3'>Labor</label>
                <input
                  type='number'
                  className='form-control w-75'
                  value={formData.laborCost}
                  onChange={(e) => setFormData({...formData, laborCost: +e.target.value})}
                />
              </div>
              <div className='d-flex gap-2 justify-between'>
                <label className='form-label mt-3'>Parts</label>
                <input
                  type='number'
                  className='form-control w-75'
                  value={formData.partsCost}
                  onChange={(e) => setFormData({...formData, partsCost: +e.target.value})}
                />
              </div>
              <div className='d-flex gap-2 justify-between'>
                <label className='form-label mt-3'>Subtotal</label>
                <input
                  type='number'
                  className='form-control w-75'
                  value={formData.subtotal}
                  disabled
                />
              </div>
              <div className='d-flex gap-2 justify-between'>
                <label className='form-label mt-3'>Tax</label>
                <input
                  type='number'
                  className='form-control w-75'
                  value={formData.tax}
                  onChange={(e) => setFormData({...formData, tax: +e.target.value})}
                />
              </div>
              <div className='d-flex gap-2 justify-between'>
                <label className='form-label mt-3'>Discount</label>
                <input
                  type='number'
                  className='form-control w-75'
                  value={formData.discount}
                  onChange={(e) => setFormData({...formData, discount: +e.target.value})}
                />
              </div>
              <div className='d-flex gap-2 justify-between'>
                <label className='form-label mt-3 fw-semibold'>Total</label>
                <input
                  type='number'
                  className='form-control fw-semibold w-75'
                  value={formData.total}
                  disabled
                />
              </div>
            </div>
          </div>
          <label className='form-label mt-3'>Comments</label>
          <textarea
            className='form-control'
            placeholder='Add an optional comment'
            value={formData.comments}
            onChange={(e) => setFormData({...formData, comments: e.target.value})}
          />
          {/* submit button */}
          <div className='my-3'>
            <button className='btn btn-warning' disabled={loading} onClick={handleEdit}>
              Edit Work Order
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditWorkOrder
