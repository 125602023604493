import React, {useEffect, useState} from 'react'
import Select from 'react-select'
import 'react-datepicker/dist/react-datepicker.css'
import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/themes/material_blue.css' // Choose a theme from the flatpickr themes folder
import 'flatpickr/dist/flatpickr.min.css'
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd'
import {useAuth} from '../../../../modules/auth/core/Auth'
import {KTIcon} from '../../../../../_metronic/helpers'
import {useNavigate, useParams} from 'react-router-dom'
import {
  editQuote,
  getCustomer,
  getCustomerByStatusForAgent,
  getItemsByType,
  getQuotebyQuoteid,
  getQuotes,
  getStocksForSales,
} from '../../Core/_requests'
import {useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../../../redux/store'
import {setServices} from '../../../../redux/inventory/products/servicesSlice'
import {setSalesStock} from '../../../../redux/inventory/products/stockSlice'
import {getEmployeeByStatus} from '../../../hrm/core/_requests'
import {editCRMQuote, getCRMQuotebyQuoteid} from '../../../crm/core/_requests'

function EditQuote({isCRM = false}: {isCRM?: boolean}) {
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const salesStock = useSelector((state: RootState) => state.inventory.stock.salesStock)
  const servicesList = useSelector((state: RootState) => state.inventory.services.services)
  const quoteid = useParams().id
  const [QuoteId, setQuoteId] = useState('')
  const [orderId, setOrderId] = useState<any>()
  const [permissions, setPermissions] = useState<any>({})
  const [customerList, setCustomerList] = useState<any>([])
  const [employeeList, setEmployeeList] = useState<any>([])
  const [productList, setProductList] = useState<any>([])
  const [selectedBillFrom, setselectedBillFrom] = useState(null)
  const [selectedBillTo, setselectedBillTo] = useState(null)
  const [isEditingNotes, setIsEditingNotes] = useState(false)
  const [isEditingTerms, setIsEditingTerms] = useState(false)
  const [termsTextareaHeight, setTermsTextareaHeight] = useState('3rem')
  const [notesTextareaHeight, setNotesTextareaHeight] = useState('3rem')
  const [loading, setLoading] = useState(false)
  const [newItem, setNewItem] = useState<any>([])

  //useState for Custom Invoive no
  const [textHeight, setTextHeight] = useState('6rem')

  const [quote, setQuote] = useState({
    date: new Date(),
    employee: '',
    customer: '',
    rfqNumber: '',
    description: '',
    id: '',
    subtotal: 0,
    tax: 0,
    total: 0,
    notes: ' ',
    termsNCondition: ' ',
  })
  const [items, setItems] = useState([
    {
      itemId: '',
      price: '',
      quantity: 0,
      discount: 0,
      amount: '',
      selectedOption: {
        value: '',
        label: '',
        inWarehouseQuantity: 0,
      },
      type: 'product',
      productName: '',
      rfqDescription: '',
    },
  ])

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  useEffect(() => {
    if (quote.termsNCondition === '') {
      setTermsTextareaHeight('3rem')
    }
  }, [quote.termsNCondition])

  useEffect(() => {
    if (quote.notes === '') {
      setNotesTextareaHeight('3rem')
    }
  }, [quote.notes])

  useEffect(() => {
    if (quote.description === '') {
      setTextHeight('6rem')
    }
  }, [quote.description])

  useEffect(() => {
    const fetchQuote = async () => {
      try {
        const QuotesList = await getQuotes(currentUser?.organization)

        if (QuotesList && QuotesList.length > 0) {
          const firstQuote = QuotesList[0]

          setQuote((prev) => ({
            ...prev,
            notes: firstQuote.notes || '',
            termsNCondition: firstQuote.termsNCondition || '',
          }))
        }
      } catch (error) {
        console.error('Error fetching delivery notes:', error)
      }
    }

    fetchQuote()
  }, [currentUser?.organization])

  const getCustomerList = async () => {
    try {
      const res = await getCustomer('', currentUser?.organization)
      setCustomerList(
        res.map((r) => ({
          value: r?._id,
          label: r?.displayName,
        }))
      )
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  const getCustomerForAgent = async () => {
    try {
      const res = await getCustomerByStatusForAgent('', currentUser?._id)
      setCustomerList(
        res.map((r) => ({
          value: r?._id,
          label: r?.displayName,
        }))
      )
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  const getEmployeeList = async () => {
    try {
      const res = await getEmployeeByStatus('filter_status=true', currentUser?.organization)
      setEmployeeList(
        res.map((r) => ({
          value: r._id,
          label: r.firstName, // Corrected the typo here from "labe" to "label"
        }))
      )
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  const handleDragEnd = (result) => {
    if (!result.destination) return // dropped outside the list

    if (items.length > 1) {
      const itemsCopy = [...items]
      const [reorderedItem] = itemsCopy.splice(result.source.index, 1)
      itemsCopy.splice(result.destination.index, 0, reorderedItem)

      // Update your state with the reordered items
      setItems(itemsCopy)
    } else if (newItem.length > 1) {
      const itemsCopy = [...newItem]
      const [reorderedItem] = itemsCopy.splice(result.source.index, 1)
      itemsCopy.splice(result.destination.index, 0, reorderedItem)

      // Update your state with the reordered items
      setNewItem(itemsCopy)
    }
  }

  const getProductlist = async () => {
    try {
      let salesStockData = []
      if (salesStock.length === 0) {
        const res = await getStocksForSales(currentUser?.organization)
        console.log(res)
        salesStockData = res.map((r) => ({
          value: r._id,
          label: r.productName,
          price: r.price,
          inWarehouseQuantity: r.inWarehouseQuantity,
          type: 'product',
        }))
        dispatch(setSalesStock(res))
      } else {
        salesStockData = salesStock.map((r) => ({
          value: r._id,
          label: r.productName,
          price: r.price,
          inWarehouseQuantity: r.inWarehouseQuantity,
          type: 'product',
        }))
      }

      let serviceData = []
      if (servicesList.length === 0) {
        const resp = await getItemsByType('service', currentUser?.organization)
        serviceData = resp.map((r) => ({
          value: r._id,
          label: r.name,
          price: r.price,
          inWarehouseQuantity: 1,
          type: 'item',
        }))
        dispatch(setServices(resp))
      } else {
        serviceData = servicesList.map((r) => ({
          value: r._id,
          label: r.name,
          price: r.price,
          inWarehouseQuantity: 1,
          type: 'item',
        }))
      }
      setProductList((prev) => {
        const updatedData = [...salesStockData, ...serviceData]
        return updatedData
      })
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  const handleEditClick = (e, field) => {
    e.preventDefault()
    if (field === 'notes') {
      setIsEditingNotes(!isEditingNotes)
      setIsEditingTerms(false) // Make sure only one is in edit mode at a time
    } else if (field === 'termsNCondition') {
      setIsEditingTerms(!isEditingTerms)
      setIsEditingNotes(false) // Make sure only one is in edit mode at a time
    }
  }

  useEffect(() => {
    if (permissions?.quotation?.admin === false) {
      getCustomerForAgent()
    } else if (
      permissions?.quotation?.admin === true ||
      currentUser?.profileType === 'superadmin'
    ) {
      getCustomerList()
    }
  }, [permissions])

  const fetchQuoteData = async () => {
    const res = await getQuotebyQuoteid(quoteid)
    console.log(res)
    setQuote(() => {
      return {
        ...res,
        date: new Date(res?.date),
        employee: res.employee?._id,
        customer: res.customer?._id,
      }
    })
    setselectedBillTo({
      value: res.customer?._id,
      label: res.customer?.displayName,
    })
    setselectedBillFrom({
      value: res.employee?._id,
      label: res.employee?.firstName,
    })
    setQuoteId(res.id)
    setOrderId(res.order)
    const data = res.items?.filter((item) => !item.itemId && !item.itemsId)
    setNewItem(data)
    setItems(
      res.items
        .filter((item) => item.itemId || item.itemsId)
        .map((item) => {
          return {
            itemId: item.itemId?._id || item.itemsId?._id,
            productName: item?.productName,
            price: item.price,
            quantity: item.quantity,
            discount: item.discount,
            amount: item.amount,
            selectedOption: {
              value: item?.itemId?._id || item?.itemsId?._id,
              label: item?.itemId ? item?.itemId?.productName : item?.itemsId?.name,
              inWarehouseQuantity: item?.itemId ? item?.itemId?.inWarehouseQuantity : 1,
              type: item?.type,
            },
            type: item.type,
          }
        })
    )
  }

  const fetchQuoteDataCRM = async () => {
    const res = await getCRMQuotebyQuoteid(quoteid)
    setQuote(() => {
      return {
        ...res,
        date: new Date(res.date),
        employee: res.employee?._id,
        customer: res.customer?._id,
      }
    })
    setselectedBillTo({
      value: res.customer?._id,
      label: res.customer?.displayName,
    })
    setselectedBillFrom({
      value: res.employee?._id,
      label: res.employee?.firstName,
    })
    setQuoteId(res.id)
    setOrderId(res.order)
    const data = res.items?.filter((item) => !item.itemId && !item.itemsId)
    setNewItem(data)
    setItems(
      res.items
        .filter((item) => item.itemId || item.itemsId)
        .map((item) => {
          return {
            itemId: item.itemId?._id || item.itemsId?._id,
            productName: item?.productName,
            price: item.price,
            quantity: item.quantity,
            discount: item.discount,
            amount: item.amount,
            selectedOption: {
              value: item?.itemId?._id || item?.itemsId?._id,
              label: item?.itemId ? item?.itemId?.productName : item?.itemsId?.name,
              inWarehouseQuantity: item?.itemId ? item?.itemId?.inWarehouseQuantity : 1,
              type: item?.type,
            },
            type: item.type,
          }
        })
    )
  }

  useEffect(() => {
    if (isCRM) {
      fetchQuoteDataCRM()
    } else {
      fetchQuoteData()
    }
    getEmployeeList()
    getProductlist()
  }, [])

  const handleChange = (e) => {
    e.preventDefault()
    const {name, value} = e.target
    if (name === 'notes' && value.trim() === '') {
      setQuote((prev) => ({
        ...prev,
        notes: '• ' + value,
      }))
    } else {
      setQuote((prevInvoice) => ({
        ...prevInvoice,
        [name]: value,
      }))
    }
    if (name === 'termsNCondition') {
      setTermsTextareaHeight(`${e.target.scrollHeight}px`)
    } else if (name === 'notes') {
      setNotesTextareaHeight(`${e.target.scrollHeight}px`)
    } else if (name == 'description') {
      setTextHeight(`${e.target.scrollHeight}px`)
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      setQuote((prev) => ({
        ...prev,
        notes: prev.notes + '\n• ',
      }))

      setNotesTextareaHeight(`${event.target.scrollHeight}px`)
    }
  }

  const handleChangerows = (index, e) => {
    const {name, value} = e.target
    if (name === 'quantity' && items[index].type !== 'item') {
      console.log(value, items[index].selectedOption.inWarehouseQuantity)
      if (value > items[index].selectedOption.inWarehouseQuantity) {
        return
      }
    }
    setItems((prevItems) => {
      const updatedItems = [...prevItems]
      updatedItems[index] = {
        ...updatedItems[index],
        [name]: value,
      }
      return updatedItems
    })
    setItems((prevItems) => {
      const updatedItems = [...prevItems]
      const data: any = (
        Number(prevItems[index].price) * Number(prevItems[index].quantity) -
        Number(prevItems[index].discount)
      ).toFixed(2)

      updatedItems[index] = {
        ...updatedItems[index],
        amount: data,
      }
      return updatedItems
    })
  }

  const handleSelectChangeInRows = (index, selectedOption) => {
    console.log(selectedOption)
    setItems((prevItems) => {
      const updatedItems = [...prevItems]
      updatedItems[index] = {
        ...updatedItems[index],
        itemId: selectedOption.value,
        productName: selectedOption.label,
        selectedOption,
        price: selectedOption.price,
        amount: selectedOption.price,
        quantity: 1,
        type: selectedOption.type,
      }
      return updatedItems
    })
  }

  useEffect(() => {
    setQuote((prevInvoice) => {
      let sum = items.reduce((acc, item) => {
        return acc + parseFloat(item.amount) || 0
      }, 0)
      sum += newItem.reduce((acc, item) => {
        return acc + parseFloat(item.amount) || 0
      }, 0)
      return {
        ...prevInvoice,
        subtotal: sum,
      }
    })
  }, [items, newItem])

  useEffect(() => {
    setQuote((prevInvoice) => {
      const subtotal = prevInvoice.subtotal || 0
      const tax = prevInvoice.tax || 0

      const total = (subtotal * (1 + tax / 100)).toFixed(2)

      return {
        ...prevInvoice,
        total: parseFloat(total),
        // You can add other properties if needed
      }
    })
  }, [items, quote.tax, newItem])

  const handleBillToChange = (selectedOption) => {
    setselectedBillTo(selectedOption)

    setQuote((prevInvoice) => ({
      ...prevInvoice,
      customer: selectedOption.value,
    }))
  }

  const handleBillFromChange = (selectedOption) => {
    console.log(selectedOption)
    setselectedBillFrom(selectedOption)
    setQuote((prevInvoice) => ({
      ...prevInvoice,
      employee: selectedOption.value,
    }))
  }

  const handleDateChange = (selectedDate) => {
    setQuote((prevInvoice) => ({
      ...prevInvoice,
      date: selectedDate[0], // Flatpickr returns an array of selected dates
    }))
  }

  const handleEdit = async () => {
    if (quote.employee === '') {
      alert('Please select employee')
      return
    } else if (quote.customer === '') {
      alert('Please select customer')
      return
    } else if (
      (items.length < 1 && newItem.length < 1) ||
      (items?.[0]?.itemId === '' && newItem?.[0]?.productName === '')
    ) {
      alert('Please select item')
      return
    }

    setLoading(true)

    const itemsList = items.map((item) => {
      return {
        itemId: item.type === 'product' ? item.itemId : null,
        itemsId: item.type === 'item' ? item.itemId : null,
        productName: item.productName,
        price: item.price,
        quantity: item.quantity,
        discount: item.discount,
        amount: item.amount,
        type: item.type,
      }
    })

    const data = {
      ...quote,
      order: orderId,
      id: QuoteId,
      status: 'pending',
      approval: permissions?.quotation?.enable ? 'accepted' : 'pending',
      items: [...itemsList, ...newItem],
      organization: currentUser?.organization,
      company: currentUser?.company,
      agent: currentUser?._id,
    }
    console.log(data)
    try {
      if (!isCRM) {
        await editQuote(data, quoteid).then((res) => {
          console.log(res)
          navigate(`/sales/quotes/${quoteid}`)
        })
      } else {
        await editCRMQuote(data, quoteid).then((res) => {
          console.log(res)
          navigate(`/crm/quotes/${quoteid}`)
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const additem = () => {
    setItems([
      ...items,
      {
        itemId: '',
        price: '',
        quantity: 0,
        discount: 0,
        amount: '',
        selectedOption: {
          value: '',
          label: '',
          inWarehouseQuantity: 0,
        },
        type: 'product',
        productName: '',
        rfqDescription: '',
      },
    ])
  }

  const removeitem = (index) => {
    if (items.length === 1) {
      setItems([
        {
          itemId: '',
          price: '',
          quantity: 0,
          discount: 0,
          amount: '',
          selectedOption: {
            value: '',
            label: '',
            inWarehouseQuantity: 0,
          },
          type: 'product',
          productName: '',
          rfqDescription: '',
        },
      ])
      return
    }
    const temp = [...items]
    temp.splice(index, 1)
    setItems(temp)
  }

  const handleChangeinNewItem = (index, e) => {
    const {name, value} = e.target
    setNewItem((prevItems) => {
      const updatedItems = [...prevItems]
      updatedItems[index] = {
        ...updatedItems[index],
        [name]: value,
      }

      const total =
        updatedItems[index].price * updatedItems[index].quantity - updatedItems[index].discount
      updatedItems[index] = {
        ...updatedItems[index],
        amount: total,
      }

      return updatedItems
    })
  }

  return (
    <div id='kt_app_content' className='app-content flex-column-fluid'>
      {/* begin::Content container */}
      <div id='kt_app_content_container' className='app-container container-xxl'>
        {/* begin::Layout */}
        <div className='d-flex flex-column flex-lg-row'>
          {/* begin::Content */}
          <div className='flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10'>
            {/* begin::Card */}
            <div className='card'>
              {/* begin::Card body */}
              <div className='card-body p-12'>
                {/* begin::Form */}
                <form action='' id='kt_quote_form'>
                  {/* begin::Wrapper */}
                  <div className='d-flex flex-column align-items-start flex-xxl-row'>
                    {/* begin::Input group */}
                    <div
                      className='d-flex align-items-center flex-equal fw-row me-4 order-2'
                      data-bs-toggle='tooltip'
                      data-bs-trigger='hover'
                      title='Specify quote date'
                    >
                      {/* begin::Date */}
                      <div className='fs-6 fw-bold text-gray-700 text-nowrap'>Date:</div>
                      {/* Date */}
                      {/* begin::Input */}
                      <div className='position-relative d-flex align-items-center w-150px'>
                        {/* begin::Datepicker */}
                        <Flatpickr
                          value={quote.date}
                          onChange={handleDateChange}
                          className='form-control form-control-solid'
                          placeholder='Pick date'
                          options={{
                            dateFormat: 'd-m-Y',
                          }}
                        />
                        {/* Datepicker */}
                        {/* begin::Icon */}
                        {/* <i className='ki-duotone ki-down fs-4 position-absolute ms-4 end-0'></i> */}
                        {/* Icon */}
                      </div>
                      {/* Input */}
                    </div>
                    {/* Input group */}
                    {/* begin::Input group */}
                    <div className='d-flex align-items-center flex-equal fw-row me-4 order-2'>
                      <span className='fs-2x fw-bold text-gray-800'>Quotation #</span>
                      <span className='fs-2x fw-bold text-gray-800'>{QuoteId}</span>
                    </div>
                    {/* Input group */}
                    {/* begin::Input group */}
                    {/* Input group */}
                  </div>
                  {/* Top */}
                  {/* begin::Separator */}
                  <div className='separator separator-dashed my-10'></div>
                  {/* Separator */}
                  {/* begin::Wrapper */}
                  <div className='mb-0'>
                    {/* begin::Row */}
                    <div className='row gx-10 mb-5'>
                      {/* begin::Col */}
                      <div className='col-lg-6'>
                        <label className='form-label required fs-6 fw-bold text-gray-700 mb-3'>
                          From
                        </label>
                        {/* begin::Input group */}
                        <Select
                          name='employee'
                          aria-label='Select a Employee'
                          placeholder='Select Employee'
                          options={employeeList}
                          defaultValue={{value: currentUser?._id, label: currentUser?.fullName}}
                          isSearchable={true}
                          className='form-control form-control-solid'
                          onChange={handleBillFromChange}
                          value={selectedBillFrom}
                        />
                        {/* Input group */}
                      </div>
                      {/* Col */}
                      {/* begin::Col */}
                      <div className='col-lg-6'>
                        <label className='form-label required fs-6 fw-bold text-gray-700 mb-3'>
                          To
                        </label>
                        {/* begin::Input group */}
                        <Select
                          name='customer'
                          aria-label='Select a Customer'
                          placeholder='Select a Customer'
                          options={customerList}
                          isSearchable={true}
                          className='form-control form-control-solid'
                          onChange={handleBillToChange}
                          value={selectedBillTo}
                        />
                      </div>
                      <div className='col-lg-6 mt-5'>
                        <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                          RFQ No.
                        </label>
                        <input
                          type='text'
                          className='form-control form-control-solid '
                          name='rfqNumber'
                          placeholder=' RFQ Number'
                          value={quote.rfqNumber}
                          onChange={handleChange}
                        />

                        {/* Input group */}
                      </div>

                      <div className='mb-0 mt-5'>
                        <label className='form-label fs-6 fw-bold text-gray-700'>Description</label>
                        <textarea
                          name='description'
                          className='position-relative  form-control form-control-solid mb-0 h-10'
                          placeholder='Enter Description'
                          value={quote.description}
                          onChange={handleChange}
                          style={{
                            minHeight: '6rem',
                            height: textHeight,
                            resize: 'none',
                          }}
                        />
                      </div>
                      {/* Col */}
                    </div>
                    {/* Row */}
                    {/* begin::Table wrapper */}
                    <div className='fs-6 fw-bold text-gray-700 mb-3'>Item Table</div>
                    <div className='table-responsive mb-10'>
                      {/* begin::Table */}
                      <table
                        className='table g-5 gs-0 mb-0 fw-bold text-gray-700'
                        data-kt-element='items'
                      >
                        {/* begin::Table head */}
                        <thead>
                          <tr className='border-bottom fs-7 fw-bold text-gray-700 text-uppercase'>
                            <th className='min-w-15px w-15px'>No.</th>
                            <th className='min-w-100px w-500px'>Item</th>
                            <th className='min-w-150px w-250px'>Quantity</th>
                            <th className='min-w-50px w-250px'>Rate</th>
                            <th className='min-w-70px w-150px'>VAT</th>
                            <th className='min-w-70px w-250px'>Amount</th>
                            <th className='min-w-70px w-200px'>Action</th>
                          </tr>
                        </thead>
                        {/* Table head */}
                        {/* begin::Table body */}
                        <DragDropContext onDragEnd={handleDragEnd}>
                          <Droppable droppableId='droppable'>
                            {(provided) => (
                              <tbody ref={provided.innerRef} {...provided.droppableProps}>
                                {items.map((item, index) => (
                                  <Draggable
                                    key={index}
                                    draggableId={`item-${index}`}
                                    index={index}
                                  >
                                    {(provider) => (
                                      <>
                                        <tr
                                          className='border-bottom border-bottom-dashed'
                                          data-kt-element='item'
                                          key={`${index}-row-1`}
                                          ref={provider.innerRef}
                                          {...provider.draggableProps}
                                        >
                                          <td className='d-flex align-items-center ps-1'>
                                            {index + 1}
                                          </td>
                                          <td className='pe-7'>
                                            {/* begin::Input group */}
                                            <Select
                                              name='itemId'
                                              aria-label='Select a Product'
                                              placeholder='Select a Product'
                                              options={productList}
                                              isSearchable={true}
                                              className='form-control form-control-solid'
                                              onChange={(e) => handleSelectChangeInRows(index, e)}
                                              value={item.selectedOption}
                                              required
                                            />
                                          </td>
                                          <td className='pe-7'>
                                            {/* begin::Input group */}

                                            <input
                                              type='number'
                                              className='form-control form-control-solid'
                                              name='quantity'
                                              placeholder='0.00'
                                              max={
                                                item?.type === 'product' &&
                                                item?.selectedOption?.inWarehouseQuantity
                                              }
                                              min={0}
                                              value={item.quantity}
                                              onChange={(e) => handleChangerows(index, e)}
                                            />
                                          </td>

                                          <td className='pe-7'>
                                            {/* begin::Input group */}

                                            <input
                                              type='number'
                                              className='form-control form-control-solid'
                                              name='price'
                                              placeholder='0.00'
                                              value={item.price}
                                              onChange={(e) => handleChangerows(index, e)}
                                            />
                                          </td>

                                          <td className='pe-7'>
                                            {/* begin::Input group */}

                                            <input
                                              type='number'
                                              className='form-control form-control-solid'
                                              name='discount'
                                              placeholder='0.00'
                                              value={item.discount}
                                              onChange={(e) => handleChangerows(index, e)}
                                            />
                                          </td>

                                          <td>
                                            <input
                                              type='number'
                                              className='form-control form-control-solid text-end'
                                              name='amount'
                                              placeholder='0.00'
                                              value={item.amount}
                                              disabled
                                              // onChange={(e) => handleChangerows(index, e)}
                                            />
                                          </td>
                                          <td>
                                            <div
                                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3'
                                              onClick={additem}
                                            >
                                              <KTIcon iconName='plus-square' className='fs-3' />
                                            </div>
                                            <div
                                              className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm '
                                              onClick={() => removeitem(index)}
                                            >
                                              <KTIcon iconName='trash' className='fs-3' />
                                            </div>
                                            <div
                                              {...provider.dragHandleProps}
                                              className='fs-3 fw-bold text-gray-400 ms-3'
                                            >
                                              ...
                                            </div>
                                          </td>
                                        </tr>
                                        <tr
                                          className='border-bottom border-bottom-dashed'
                                          data-kt-element='item'
                                          key={`${index}-row-2`}
                                        >
                                          <td colSpan={7} className='pe-7'>
                                            <textarea
                                              name='rfqDescription'
                                              className='position-relative  form-control form-control-solid mb-0 h-10'
                                              placeholder='Enter RFQ Description'
                                              value={item.rfqDescription}
                                              style={{
                                                minHeight: '3rem',
                                                height: '3rem',
                                                resize: 'none',
                                              }}
                                              onChange={(e) => {
                                                const temp = [...items]
                                                temp[index].rfqDescription = e.target.value
                                                setItems(temp)
                                              }}
                                            />
                                          </td>
                                        </tr>
                                      </>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                                {newItem?.length > 0 &&
                                  newItem.map((item, index) => (
                                    <Draggable
                                      key={index}
                                      draggableId={`item-${items.length + index}`}
                                      index={index}
                                    >
                                      {(provider) => (
                                        <>
                                          <tr
                                            // className='border-bottom border-bottom-dashed'
                                            data-kt-element='item'
                                            key={`${items.length + index}-row-1`}
                                            ref={provider.innerRef}
                                            {...provider.draggableProps}
                                          >
                                            <td className='d-flex align-items-center ps-1'>
                                              {items.length + index + 1}
                                            </td>
                                            <td className='pe-7'>
                                              {/* begin::Input group */}
                                              <input
                                                type='text'
                                                placeholder='Enter Item Name'
                                                className='form-control form-control-solid'
                                                value={item?.productName}
                                                onChange={(e) => {
                                                  const temp = [...newItem]
                                                  temp[index].productName = e.target.value
                                                  setNewItem(temp)
                                                }}
                                              />
                                            </td>
                                            <td className='pe-7'>
                                              {/* begin::Input group */}

                                              <input
                                                type='number'
                                                className='form-control form-control-solid'
                                                name='quantity'
                                                placeholder='0.00'
                                                min={0}
                                                value={item.quantity}
                                                onChange={(e) => handleChangeinNewItem(index, e)}
                                              />
                                            </td>

                                            <td className='pe-7'>
                                              {/* begin::Input group */}

                                              <input
                                                type='number'
                                                className='form-control form-control-solid'
                                                name='price'
                                                placeholder='0.00'
                                                value={item.price}
                                                onChange={(e) => handleChangeinNewItem(index, e)}
                                              />
                                            </td>

                                            <td className='pe-7'>
                                              <input
                                                type='number'
                                                className='form-control form-control-solid'
                                                name='discount'
                                                placeholder='0.00'
                                                value={item.discount}
                                                onChange={(e) => handleChangeinNewItem(index, e)}
                                              />
                                            </td>

                                            <td>
                                              <input
                                                type='number'
                                                className='form-control form-control-solid text-end'
                                                name='amount'
                                                placeholder='0.00'
                                                value={item.amount}
                                                disabled
                                              />
                                            </td>
                                            <td>
                                              <div
                                                className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm '
                                                onClick={() => {
                                                  const temp = [...newItem]
                                                  temp.splice(index, 1)
                                                  setNewItem(temp)
                                                }}
                                              >
                                                <KTIcon iconName='trash' className='fs-3' />
                                              </div>
                                              <div
                                                {...provider.dragHandleProps}
                                                className='fs-3 fw-bold text-gray-400 ms-3'
                                              >
                                                ...
                                              </div>
                                            </td>
                                          </tr>
                                          <tr
                                            className='border-bottom border-bottom-dashed'
                                            data-kt-element='item'
                                            key={`${items.length + index}-row-2`}
                                          >
                                            <td colSpan={7} className='pe-7'>
                                              <textarea
                                                name='rfqDescription'
                                                className='position-relative  form-control form-control-solid mb-0 h-10'
                                                placeholder='Enter RFQ Description'
                                                value={item.rfqDescription}
                                                style={{
                                                  minHeight: '3rem',
                                                  height: '3rem',
                                                  resize: 'none',
                                                }}
                                                onChange={(e) => {
                                                  const temp = [...newItem]
                                                  temp[index].rfqDescription = e.target.value
                                                  setNewItem(temp)
                                                }}
                                              />
                                            </td>
                                          </tr>
                                        </>
                                      )}
                                    </Draggable>
                                  ))}
                                {/* button for adding new row in newItem */}
                                <tr>
                                  <td colSpan={6} className='pe-7 text-center'>
                                    <button
                                      className='btn btn-info w-50'
                                      type='button'
                                      onClick={() =>
                                        setNewItem([
                                          ...newItem,
                                          {
                                            productName: '',
                                            quantity: 0,
                                            price: 0,
                                            discount: 0,
                                            amount: 0,
                                          },
                                        ])
                                      }
                                    >
                                      Add New Item
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            )}
                          </Droppable>
                        </DragDropContext>

                        {/* Table body */}
                        {/* begin::Table foot */}
                        <tfoot>
                          <tr className='border-top border-top-dashed align-top fs-6 fw-bold text-gray-700'>
                            <th className='text-primary'></th>
                            <th className='text-primary'></th>
                            <th className='text-primary'></th>
                            <th className='border-bottom border-bottom-dashed ps-0'>
                              <div className='d-flex flex-column align-items-end'>
                                <div className='fs-5'>Subtotal</div>
                                <button
                                  className='btn btn-link '
                                  data-bs-toggle='tooltip'
                                  data-bs-trigger='hover'
                                  title='Tax in percentage'
                                >
                                  VAT %
                                </button>
                              </div>
                            </th>
                            <th className='border-bottom border-bottom-dashed text-center'>
                              <span data-kt-element='sub-total' className=' '>
                                {quote.subtotal?.toLocaleString('en-US', {
                                  minimumFractionDigits: 2,
                                }) || 0}
                              </span>
                              <input
                                type='number'
                                className='form-control form-control-solid text-center'
                                name='tax'
                                placeholder='0.00'
                                value={quote.tax}
                                onChange={handleChange}
                              />
                            </th>
                          </tr>
                          <tr className='align-top fw-bold text-gray-700'>
                            <th className='min-w-100px w-100px'></th>
                            <th className='min-w-150px w-150px'></th>
                            <th className='min-w-150px w-150px'></th>
                            <th className='fs-4 ps-0 min-w-50px w-50px text-end'>Total</th>
                            <th className='text-end fs-4 text-nowrap min-w-70px w-70px'>
                              {currentUser?.baseCurrency}
                              <span data-kt-element='grand-total text-center'>
                                {' '}
                                {quote.total?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                              </span>
                            </th>
                          </tr>
                        </tfoot>
                        {/* Table foot */}
                      </table>
                    </div>
                    {/* Table */}
                    {/* begin::Item template */}

                    <table className='table d-none' data-kt-element='empty-template'>
                      <tr data-kt-element='empty'>
                        <th colSpan={5} className='text-muted text-center py-10'>
                          No items
                        </th>
                      </tr>
                    </table>
                    {/* Item template */}
                    {/* begin::Notes */}
                    <div className='mb-0'>
                      <label className='form-label fs-6 fw-bold text-gray-700'>Notes</label>
                      <textarea
                        name='notes'
                        className={`position-relative  form-control form-control-solid mb-0 h-10 ${
                          isEditingNotes ? 'border border-dark-subtle' : ''
                        }`}
                        placeholder='Enter customer notes'
                        value={quote.notes}
                        onChange={handleChange}
                        readOnly={!isEditingNotes}
                        onKeyDown={handleKeyPress}
                        style={{
                          minHeight: '3rem',
                          height: notesTextareaHeight,
                          resize: 'none',
                        }}
                      />
                      <button onClick={(e) => handleEditClick(e, 'notes')}>
                        <KTIcon
                          iconName='pencil'
                          className='position-absolute text-black bg-gray-100 right-20 bottom-72 fs-3'
                        />
                      </button>
                    </div>
                    <div className='mb-10 h-100'>
                      <label className='form-label fw-bold fs-6 text-gray-700'>
                        Terms and Conditions
                      </label>
                      <textarea
                        name='termsNCondition'
                        style={{
                          minHeight: '3rem',
                          height: termsTextareaHeight,
                          resize: 'none',
                        }}
                        className={`position-relative  form-control form-control-solid mb-0 ${
                          isEditingTerms ? 'border border-dark-subtle' : ''
                        }`}
                        placeholder='Enter terms and conditions'
                        value={quote.termsNCondition}
                        onChange={handleChange}
                        readOnly={!isEditingTerms}
                      />
                      <button onClick={(e) => handleEditClick(e, 'termsNCondition')}>
                        <KTIcon
                          iconName='pencil'
                          className='absolute text-black bg-gray-100 right-20 bottom-44 fs-3'
                        />
                      </button>
                    </div>

                    <div className='mb-0'>
                      {/* <!--begin::Row--> */}

                      {/* <!--::Row--> */}
                      <button
                        type='button'
                        className='btn btn-primary w-30'
                        id='kt_quote_submit_button'
                        onClick={handleEdit}
                        disabled={loading}
                      >
                        <i className='ki-duotone ki-triangle fs-3'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                          <span className='path3'></span>
                        </i>
                        Edit Quotation
                      </button>
                    </div>
                    {/* Notes */}
                  </div>
                  {/* Wrapper */}
                </form>
                {/* Form */}
              </div>
              {/* Card body */}
            </div>
            {/* Card */}
          </div>
        </div>
        {/* <!--::Layout--> */}
      </div>
      {/* <!--::Content container--> */}
    </div>
  )
}

export default EditQuote
