import React, {useEffect, useState} from 'react'
import {useAuth} from '../../../modules/auth'
import {Link} from 'react-router-dom'
import {getHRPEmployees} from '../core/_requests'

const HRMDocument = () => {
  const {currentUser} = useAuth()
  const [data, setData] = useState([])

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    // format date in dd/mm/yyyy 20/03/2024
    const date = new Date(createdAt)
    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()
    const formattedDate = `${day}/${month}/${year}`
    return formattedDate
  }

  useEffect(() => {
    const fetchData = async () => {
      const res = await getHRPEmployees(currentUser?.organization)
      setData(res)
    }

    if (currentUser.organization !== undefined) fetchData()
  }, [currentUser])

  return (
    // <div className='container'>
    <div className='card'>
      <div className='card-header d-flex align-items-center'>
        <h3>HRP Documents</h3>
      </div>
      <div className='card-body'>
        <div className='table-responsive'>
          <table className='table align-middle gs-0 gy-4 table-row-bordered'>
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 rounded-start'>Employee Name</th>
                <th>Department</th>
                <th>Role</th>
                <th>Date of Joining</th>
                <th>Total Docs</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((employee: any) => (
                <tr key={employee._id}>
                  <td>
                    <Link
                      to={`${employee._id}`}
                      className='ps-4 text-dark fw-bold text-hover-primary mb-1 fs-6 capitalize'
                    >
                      {employee?.firstName || ''} {employee?.lastName || ''}
                    </Link>
                  </td>
                  <td className='text-dark fw-bold text-hover-primary mb-1 fs-6 capitalize'>
                    {employee?.department?.name || 'N/A'}
                  </td>
                  <td className='text-dark fw-bold text-hover-primary mb-1 fs-6 capitalize'>
                    {employee?.role || 'N/A'}
                  </td>
                  <td className='text-dark fw-bold text-hover-primary mb-1 fs-6 capitalize'>
                    {formatCreatedAt(employee?.dateOfJoining)}
                  </td>
                  <td className='text-dark fw-bold text-hover-primary mb-1 fs-6 capitalize'>
                    {employee?.hrpFiles?.length || 0}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    // </div>
  )
}

export default HRMDocument
