import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import profile from '../../../../_metronic/assets/profile.png'
import {useAuth} from '../../../modules/auth'
import {getHRPEmployees} from '../core/_requests'
import {useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../../redux/store'
import {setEmployees} from '../../../redux/hrp/hrpEmployeesSlice'

const HRPEmployeePage: React.FC<{link: string}> = ({link = 'view-employee'}) => {
  const {currentUser} = useAuth()
  const dispatch = useDispatch()
  const employeesData = useSelector((state: RootState) => state.hrp.employees.employees)
  const hrpEmployeesFetch = useSelector((state: RootState) => state.hrp.employees.fetch)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    const getEmployeelist = async () => {
      try {
        setLoading(true)
        const res = await getHRPEmployees(currentUser?.organization)
        dispatch(setEmployees(res))
      } catch (error) {
        console.error('Error fetching Customer list:', error)
      } finally {
        setLoading(false)
      }
    }

    if (currentUser?.organization !== undefined && hrpEmployeesFetch) getEmployeelist()
  }, [currentUser?.organization])

  return (
    <>
      <div className='card'>
        <span className=' card-label fw-bold fs-3 ps-15 pt-8 '>Employee List</span>
        <div className='card-body py-3 '>
          {loading ? (
            <div className='d-flex align-items-center justify-center'>
              <div className='loader'></div>
            </div>
          ) : (
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted bg-light'>
                    <th className='ps-4 min-w-200px rounded-start'>Employees</th>
                    <th className='min-w-125px'>Department</th>
                    <th className='min-w-125px'>Roles</th>
                    <th className='min-w-125px'>Employee Id</th>
                    <th className='min-w-150px'>Contract</th>
                    <th className='min-w-100px'>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {employeesData &&
                    employeesData.map((item, i) => (
                      <tr key={i}>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='symbol symbol-50px me-5'>
                              <img src={`${profile}`} className='img-fluid rounded-circle' alt='' />
                            </div>
                            <div className='d-flex justify-content-start flex-column'>
                              <Link
                                to={`${link}/${item._id}`}
                                className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                              >
                                {item.firstName} {item.lastName}
                              </Link>
                            </div>
                          </div>
                        </td>
                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                          >
                            {item?.department?.name}
                          </a>
                        </td>
                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                          >
                            {item?.role}
                          </a>
                        </td>
                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                          >
                            {item.employeeId}
                          </a>
                        </td>
                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 capitalize'
                          >
                            {item.contractType}
                          </a>
                        </td>
                        <td>
                          <span className='badge badge-light-primary fs-7 fw-semibold'>
                            {item.employeeStatus}
                          </span>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default HRPEmployeePage
