import {createSlice} from '@reduxjs/toolkit'

interface DateState {
  startDate: string
  endDate: string
}

interface PurchaseReceiveState {
  purchaseReceiveData: any[]
  loading: boolean
  totalPages: number
  dateState: DateState
  error: string | null
  fetch: boolean
}

const initialState: PurchaseReceiveState = {
  purchaseReceiveData: [],
  totalPages: 1,
  dateState: {
    startDate: null,
    endDate: null,
  },
  loading: false,
  error: null,
  fetch: true,
}

const purchaseReceiveSlice = createSlice({
  name: 'purchaseReceive',
  initialState,
  reducers: {
    setPurchaseReceiveDataList(state, action) {
      state.purchaseReceiveData = action.payload.purchaseReceiveds
      state.totalPages = action.payload.totalPages
      state.loading = false
      state.error = null
      state.fetch = false
    },
    setLoading(state) {
      state.loading = true
      state.error = null
    },
    setError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    setPurchaseReceiveDataFetch(state) {
      state.fetch = true
    },
  },
})

export const {setPurchaseReceiveDataList, setLoading, setError, setPurchaseReceiveDataFetch} =
  purchaseReceiveSlice.actions
export default purchaseReceiveSlice.reducer
