import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface DateState {
  startDate: string
  endDate: string
}

interface ExpenseState {
  expenseData: any[]
  loading: boolean
  dateState: DateState
  error: string | null
}

const initialState: ExpenseState = {
  expenseData: [],
  loading: true,
  dateState: {
    startDate: null,
    endDate: null,
  },
  error: null,
}

const expenseSlice = createSlice({
  name: 'expense',
  initialState,
  reducers: {
    setExpenseData(state, action: PayloadAction<any[]>) {
      state.expenseData = action.payload
      state.loading = false
      state.error = null
    },
    setDateRange(state, action: PayloadAction<DateState>) {
      state.dateState = action.payload
    },
    setLoading(state) {
      state.loading = true
      state.error = null
    },
    setError(state, action: PayloadAction<string>) {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {setExpenseData, setLoading, setError, setDateRange} = expenseSlice.actions
export default expenseSlice.reducer
