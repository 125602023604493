import React, {useEffect, useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import {KTIcon} from '../../../../_metronic/helpers'
import {
  getFilteredPurchaseReceive,
  getFilteredPurchaseReceiveByAgentId,
  invalidatePurchaseReceive,
} from '../Core/_requests'
import {useAuth} from '../../../modules/auth'
import Filter2 from '../Components/Filter2'
import {Filtertype2, ValidFilter} from '../Core/_model'
import qs from 'qs'
import {useSelector, useDispatch} from 'react-redux'
import {setPurchaseReceiveDataList} from '../../../redux/procurement/purchaseReceive/purchaseReceiveSlice'
import {RootState} from '../../../redux/store'
import {setPurchaseOrderDataFetch} from '../../../redux/procurement/purchaseOrder/purchaseOrderSlice'
import debounce from 'lodash/debounce'
import Pagination from '../Components/Pagination'
import {RiArrowDropDownLine} from 'react-icons/ri'
import DateFilter from '../../account/components/DateFilter'

const PurchaseReceiveList = () => {
  const {currentUser} = useAuth()
  const initialRef = useRef(true)
  const [permissions, setPermissions] = useState<any>({})
  const [dropdown, setDropdown] = useState([])
  const [filteredpurchaseReceiveList, setFilteredPurchaseReceiveList] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [vendorName, setVendorName] = useState('')
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const purchaseReceiveList = useSelector(
    (state: RootState) => state.procurement.purchaseReceive.purchaseReceiveData
  )
  const totalPagesStore = useSelector(
    (state: RootState) => state.procurement.purchaseReceive.totalPages
  )
  const dateRange = useSelector((state: RootState) => state.procurement.purchaseReceive.dateState)
  const [dateState, setDateState] = useState<any>(dateRange)
  const reFetch = useSelector((state: RootState) => state.procurement.purchaseReceive.fetch)
  // Pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const stringifyObject = (obj: ValidFilter): string => {
    const queryString = qs.stringify(
      {
        filter_validity: obj.filter.isValid,
        filter_approval: obj.filter.approval,
        filter_status: obj.filter.status,
        filter_vendor: obj.filter.vendor,
        search_query: searchQuery,
        filter_vendorName: vendorName,
      },
      {encodeValuesOnly: true, skipNulls: true, delimiter: '&'}
    )

    return queryString
  }

  const getList = async () => {
    setIsLoading(true)
    const queryString = stringifyObject(searchFilters)
    try {
      // const data = await getPurchaseReceive(currentUser?.organization)
      const data = await getFilteredPurchaseReceive(
        queryString,
        currentUser?.organization,
        currentPage,
        25,
        dateState
      )
      setFilteredPurchaseReceiveList(data?.purchaseReceiveds)
      setTotalPages(data?.totalPages)
      setDropdown(Array(data?.purchaseReceiveds?.length).fill(false))
      return data
    } catch (error) {
      console.error('Axios error:', error)
      throw error
    } finally {
      setIsLoading(false)
    }
  }

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      // day: '2-digit',
      // month: 'short',
      // year: 'numeric',
    })
    return formattedDate
  }

  // useEffect(() => {
  //   const filteredData = receiveList.filter(
  //     (item) =>
  //       item.vendor?.displayName.toLowerCase().includes(searchQuery.toLowerCase()) ||
  //       item?.id?.toLowerCase().includes(searchQuery.toLowerCase()) ||
  //       item?.purchaseOrder?.id?.toLowerCase().includes(searchQuery.toLowerCase())
  //   )
  //   setFilteredPurchaseReceiveList(filteredData)
  //   setDropdown(Array(filteredData.length)?.fill(false))
  // }, [searchQuery, receiveList])

  // useEffect(() => {
  //   // setReceiveList(dummyData);
  //   getList()
  // }, [])

  const getQuotesbyAgent = async () => {
    setIsLoading(true)
    const queryString = stringifyObject(searchFilters)
    try {
      const res = await getFilteredPurchaseReceiveByAgentId(
        queryString,
        currentUser?._id,
        currentPage,
        25,
        dateState
      )
      setFilteredPurchaseReceiveList(res?.purchaseReceiveds)
      setTotalPages(res?.totalPages)
      setDropdown(Array(res?.purchaseReceiveds?.length).fill(false))
      return res
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const fetchPurchaseReceives = () => {
    if (dateState.startDate !== undefined && dateState.endDate !== undefined) {
      if (permissions?.purchasereceives?.admin === false) {
        getQuotesbyAgent()
      } else if (
        permissions?.purchasereceives?.admin === true ||
        currentUser?.profileType === 'superadmin'
      ) {
        getList()
      }
    }
  }

  const fetchPurchaseReceivesData = () => {
    if (permissions?.purchasereceives?.admin === false) {
      getQuotesbyAgent().then((res) => dispatch(setPurchaseReceiveDataList(res)))
    } else if (
      permissions?.purchasereceives?.admin === true ||
      currentUser?.profileType === 'superadmin'
    ) {
      getList().then((res) => dispatch(setPurchaseReceiveDataList(res)))
    }
  }

  useEffect(() => {
    if (purchaseReceiveList.length === 0 || reFetch) {
      fetchPurchaseReceivesData()
    } else {
      setFilteredPurchaseReceiveList(purchaseReceiveList)
      setDropdown(Array(purchaseReceiveList.length).fill(false))
      setTotalPages(totalPagesStore)
    }
  }, [permissions])

  const debounceSearch = debounce(fetchPurchaseReceives, 500)

  useEffect(() => {
    if (initialRef.current) {
      initialRef.current = false
      return
    }

    debounceSearch()

    return () => debounceSearch?.cancel()
  }, [searchQuery, currentPage, dateState.endDate, vendorName])

  const handleClick = (index) => {
    const newDropdownState = dropdown.map((_, i) => (i === index ? !dropdown[i] : false))
    setDropdown(newDropdownState)
  }

  let searchFilters: ValidFilter = {
    filter: {
      isValid: true,
      approval: '',
    },
  }
  const setSearchFilter = (updateValues: {filter?: Partial<Filtertype2>}): void => {
    searchFilters = {
      ...searchFilters,
      ...(updateValues.filter && {
        filter: {
          ...searchFilters.filter,
          ...updateValues.filter,
        },
      }),
    }
  }
  const setValidityFilter = (isValid: boolean) => {
    setSearchFilter({
      filter: {
        isValid,
      },
    })
  }

  const handlereset = async () => {
    setSearchFilter({
      filter: {
        isValid: true,
        approval: '',
      },
    })
    setFilteredPurchaseReceiveList(purchaseReceiveList)
    setCurrentPage(1)
    setDropdown(Array(purchaseReceiveList.length)?.fill(false))
  }
  const setApprovalFilter = (approval: string) => {
    setSearchFilter({
      filter: {
        isValid: searchFilters.filter.isValid,
        status: searchFilters.filter.status,
        approval: approval, // Update approval filter value
      },
    })
  }

  const setStatusFilter = (status: string) => {
    setSearchFilter({
      filter: {
        isValid: searchFilters.filter.isValid,
        approval: searchFilters.filter.approval,
        status: status, // Update status filter value
      },
    })
  }

  const handleActivation = async (id) => {
    const res = await invalidatePurchaseReceive(id)

    if (res) {
      fetchPurchaseReceivesData()
      dispatch(setPurchaseOrderDataFetch())
    }
  }

  return (
    <div className='card card-flush'>
      <span className='card-label fw-bold fs-3 ps-15 pt-8 '>Received Items</span>
      <div className='card-header border-0 pt-5'>
        <div className='flex gap-5'>
          <div className='d-flex flex-row card-title align-items-center gap-4'>
            <form
              data-kt-search-element='form'
              className='w-100 position-relative '
              autoComplete='off'
            >
              <KTIcon
                iconName='magnifier'
                className='fs-2 text-lg-1 text-gray-500 position-absolute top-50 translate-middle-y ms-2'
              />
              <input
                type='text'
                className='form-control form-control-solid w-250px ps-14'
                name='search'
                placeholder='Search (Purchase Receive Id)'
                data-kt-search-element='input'
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </form>
            {/* input field for vendor display name */}
            <form
              data-kt-search-element='form'
              className='w-100 position-relative '
              autoComplete='off'
            >
              <KTIcon
                iconName='magnifier'
                className='fs-2 text-lg-1 text-gray-500 position-absolute top-50 translate-middle-y ms-2'
              />
              <input
                type='text'
                className='form-control form-control-solid w-250px ps-14'
                name='search'
                placeholder='Search (Vendor Name)'
                value={vendorName}
                onChange={(e) => setVendorName(e.target.value)}
              />
            </form>
            <DateFilter dateState={dateState} setDateState={setDateState} />
          </div>

          <div className=' d-flex flex-row  card-title align-items-center'>
            <Filter2
              searchFilters={searchFilters}
              setSearchFilter={setSearchFilter}
              handlereset={handlereset}
              getDocsList={fetchPurchaseReceives}
              setValidityFilter={setValidityFilter}
              statusOptions={[
                {value: 'billed', label: 'Billed'},
                {value: 'pending', label: 'Pending'},
                {value: 'partial', label: 'Partial'},
              ]}
              setApprovalFilter={setApprovalFilter}
              setStatusFilter={setStatusFilter}
            />
          </div>
        </div>

        {(permissions?.purchasereceives?.create || currentUser?.profileType === 'superadmin') && (
          <div className='card-toolbar'>
            <Link
              to={'/procurement/purchaseReceiveList/add'}
              className='btn btn-sm btn-light-primary'
            >
              <KTIcon iconName='plus' className='fs-2' />
              Add Purchase Receives
            </Link>
          </div>
        )}
      </div>
      <div className='card-body py-3'>
        {isLoading ? (
          <div className='d-flex align-items-center justify-center'>
            <div className='loader'></div>
          </div>
        ) : (
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4 table-row-bordered'>
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-50px rounded-start'>Date</th>
                  <th className='min-w-150px'>Vendor Name</th>
                  <th className='min-w-150px'>Purchase Receive</th>
                  <th className='min-w-150px'>Purchase Order</th>
                  <th className='min-w-150px'>Status</th>
                  {/* <th className='min-w-100px'>Quantity</th> */}
                  <th className='min-w-100px'>Approval</th>
                  {(permissions?.purchasereceives?.update ||
                    currentUser?.profileType === 'superadmin') && (
                    <th className='min-w-100px text-center'>Actions</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {filteredpurchaseReceiveList &&
                  filteredpurchaseReceiveList.map((item, index) => (
                    <tr key={item?._id?.toString()}>
                      <td>
                        <Link
                          to={`/procurement/purchaseReceiveList/${item._id}`}
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          {formatCreatedAt(item?.receivedDate)}
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/procurement/purchaseReceiveList/${item._id}`}
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          {item?.vendor?.displayName}
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/procurement/purchaseReceiveList/${item._id}`}
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          {item?.id}
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/procurement/purchaseReceiveList/${item._id}`}
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          {item?.purchaseOrder?.id}
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/procurement/purchaseReceiveList/${item._id}`}
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          {item?.status}
                        </Link>
                      </td>
                      {/* <td>
                        <Link
                          to={`/procurement/purchaseReceiveList/${item._id}`}
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          {item?.purchaseOrder?.items[0]?.quantity?.toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                          })}
                        </Link>
                      </td> */}
                      <td>
                        <Link
                          to={`/procurement/purchaseReceiveList/${item._id}`}
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          <span
                            className={`badge  fs-7 fw-semibold ${
                              item?.approval === 'pending'
                                ? 'badge-light-primary'
                                : item?.approval === 'accepted'
                                ? 'badge-light-success'
                                : 'badge-light-danger'
                            }`}
                          >
                            {item?.approval}
                          </span>
                        </Link>
                      </td>
                      {/* {item?.status === 'partial' && (
                      <td className='relative '>
                        <div className='d-flex justify-content-center flex-shrink-0'>
                          <button
                            onClick={() => handleClick(index)}
                            className=' bg-gray-100 hover:bg-lime-50 px-6 py-2 flex items-center rounded self-end'
                          >
                            Actions <RiArrowDropDownLine />
                          </button>
                        </div>
                        {dropdown[index] && (
                          <div className='absolute flex flex-col bg-white  w-40 shadow-[0_16px_16px_6px_rgba(128,128,128,0.1)] top-16 rounded p-3 z-50 space-y-2'>
                            {item?.status === 'partial' && (
                              <Link to={`add-partial/${item?.purchaseOrder?._id}`}>
                                <button className='hover:bg-lime-50 p-2  text-start rounded'>
                                  Add Partial
                                </button>
                              </Link>
                            )}
                          </div>
                        )}
                      </td>
                    )} */}

                      {(permissions?.purchasereceives?.update ||
                        currentUser?.profileType === 'superadmin') && (
                        <td className='relative'>
                          <div className='d-flex justify-content-center flex-shrink-0'>
                            <button
                              onClick={() => handleClick(index)}
                              className=' bg-gray-100 hover:bg-lime-50 px-6 py-2 flex items-center rounded self-end'
                            >
                              Actions <RiArrowDropDownLine />
                            </button>
                          </div>

                          {dropdown[index] && (
                            <div className='absolute flex flex-col top-16 right-[0px] z-50  bg-white  w-40 shadow-[0_16px_16px_6px_rgba(128,128,128,0.1)] rounded p-3 space-y-2'>
                              {/* <div className='menu-item px-3'>
                          <a href='#' className='menu-link px-3'>
                            Edit
                          </a>
                        </div> */}

                              {item?.status === 'partial' && item?.valid && (
                                <>
                                  <Link to={`add-partial/${item?.purchaseOrder?._id}`}>
                                    <button className='hover:bg-lime-50 p-2  text-start rounded'>
                                      Add Partial
                                    </button>
                                  </Link>
                                </>
                              )}

                              {(item?.status === 'pending' || item?.status === 'partial') &&
                                item?.valid && (
                                  <button
                                    className='hover:bg-lime-50 p-2 text-start rounded'
                                    onClick={() => handleActivation(item._id)}
                                  >
                                    Invalid
                                  </button>
                                )}
                            </div>
                          )}
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {/* Pagination */}
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
      />
    </div>
  )
}

export default PurchaseReceiveList
